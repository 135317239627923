import api from '../api';

const createPain = ({ project, product, segment, ...pain }) =>
  api.post(`api/projects/${project}/products/${product}/segments/${segment}/pains/`, { ...pain });

const deletePain = ({ project, product, segment, painId }) =>
  api.delete(`api/projects/${project}/products/${product}/segments/${segment}/pains/${painId}/`);

const fetchPain = ({ project, product, segment, id }) =>
  api.get(`api/projects/${project}/products/${product}/segments/${segment}/pains/${id}`);

const listPains = ({ project, product, segment }) =>
  api.get(`api/projects/${project}/products/${product}/segments/${segment}/pains/`);

const updatePain = ({ project, product, segment, painId, ...pain }) =>
  api.patch(`api/projects/${project}/products/${product}/segments/${segment}/pains/${painId}/`, {
    ...pain,
  });

export default {
  createPain,
  deletePain,
  fetchPain,
  listPains,
  updatePain,
};
