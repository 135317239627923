export default {
  INTERESTSHIP_CLEAR_ERROR: 'bfb/matching/interestship/INTERESTSHIP_CLEAR_ERROR',
  INTERESTSHIPS_LIST: 'bfb/matching/interestship/INTERESTSHIPS_LIST',
  INTERESTSHIPS_LIST_SUCCEEDED: 'bfb/matching/interestship/INTERESTSHIPS_LIST_SUCCEEDED',
  INTERESTSHIPS_LIST_PENDING: 'bfb/matching/interestship/INTERESTSHIPS_LIST_PENDING',
  INTERESTSHIPS_LIST_FAILED: 'bfb/matching/interestship/INTERESTSHIPS_LIST_FAILED',
  INTERESTSHIPS_LIST_MP: 'bfb/matching/interestship/INTERESTSHIPS_LIST_MP',
  INTERESTSHIPS_LIST_MP_SUCCEEDED: 'bfb/matching/interestship/INTERESTSHIPS_LIST_MP_SUCCEEDED',
  INTERESTSHIPS_LIST_MP_PENDING: 'bfb/matching/interestship/INTERESTSHIPS_LIST_MP_PENDING',
  INTERESTSHIPS_LIST_MP_FAILED: 'bfb/matching/interestship/INTERESTSHIPS_LIST_MP_FAILED',
  INTERESTSHIPS_UPDATE_MP: 'bfb/matching/interestship/INTERESTSHIPS_UPDATE_MP',
  INTERESTSHIPS_UPDATE_MP_SUCCEEDED: 'bfb/matching/interestship/INTERESTSHIPS_UPDATE_MP_SUCCEEDED',
  INTERESTSHIPS_UPDATE_MP_PENDING: 'bfb/matching/interestship/INTERESTSHIPS_UPDATE_MP_PENDING',
  INTERESTSHIPS_UPDATE_MP_FAILED: 'bfb/matching/interestship/INTERESTSHIPS_UPDATE_MP_FAILED',
  INTERESTSHIP_CREATE: 'bfb/matching/interestship/INTERESTSHIP_CREATE',
  INTERESTSHIP_CREATE_SUCCEEDED: 'bfb/matching/interestship/INTERESTSHIP_CREATE_SUCCEEDED',
  INTERESTSHIP_CREATE_FAILED: 'bfb/matching/interestship/INTERESTSHIP_CREATE_FAILED',
  INTERESTSHIP_DELETE: 'bfb/matching/interestship/INTERESTSHIP_DELETE',
  INTERESTSHIP_DELETE_SUCCEEDED: 'bfb/matching/interestship/INTERESTSHIP_DELETE_SUCCEEDED',
  INTERESTSHIP_DELETE_FAILED: 'bfb/matching/interestship/INTERESTSHIP_DELETE_FAILED',
  INTERESTSHIP_FETCH: 'bfb/matching/interestship/INTERESTSHIP_FETCH',
  INTERESTSHIP_FETCH_SUCCEEDED: 'bfb/matching/interestship/INTERESTSHIP_FETCH_SUCCEEDED',
  INTERESTSHIP_FETCH_FAILED: 'bfb/matching/interestship/INTERESTSHIP_FETCH_FAILED',
  INTERESTSHIP_UPDATE: 'bfb/matching/interestship/INTERESTSHIP_UPDATE',
  INTERESTSHIP_UPDATE_SUCCEEDED: 'bfb/matching/interestship/INTERESTSHIP_UPDATE_SUCCEEDED',
  INTERESTSHIP_UPDATE_FAILED: 'bfb/matching/interestship/INTERESTSHIP_UPDATE_FAILED',
};
