import React from 'react';
import PropTypes from 'prop-types';
import { Select as DefaultSelect } from 'components/elements';
import styled, { css } from 'styled-components';
import { ToolInputContainer } from 'components/Tools/';
import InputStyle from '../ToolInput/common';

const containerColor = ({ colorIndex, theme, useTheme }) => {
  if (useTheme) return theme.primaryColor;
  return colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#B3B3B3';
};

const RawSelect = props => {
  const { showComments, ...rest } = props;
  return showComments ? (
    <ToolInputContainer showSelect={false} showDelete={false} {...rest}>
      <DefaultSelect {...rest} />
    </ToolInputContainer>
  ) : (
    <DefaultSelect {...rest} />
  );
};

RawSelect.propTypes = {
  noborder: PropTypes.bool,
  $semibold: PropTypes.bool,
  usebackground: PropTypes.bool,
  showComments: PropTypes.bool,
  softFilled: PropTypes.bool,
};

RawSelect.defaultProps = {
  noborder: true,
  $semibold: false,
  showComments: false,
  usebackground: true,
  softFilled: false,
};

const getBackgroundColor = ({ colorIndex, opacityBackground, useTheme, theme }) => {
  switch (true) {
    case opacityBackground && colorIndex !== undefined:
      return `${theme.getToolColor(colorIndex)}19`; // Fourth HEX position is used for opacity
    case useTheme:
      return theme.primaryColor;
    default:
      return '#FFF';
  }
};

const getBorderColor = ({ colorIndex, noborder, useTheme, theme }) => {
  switch (true) {
    case noborder:
      return 'none';
    case colorIndex !== undefined:
      return theme.getToolColor(colorIndex);
    case useTheme:
      return theme.primaryColor;
    default:
      return '#B3B3B3';
  }
};

const ToolSelect = styled(RawSelect)`
  &&&.ant-select {
    width: 100%;
    background-color: transparent;
    max-height: 49px;

    &.ant-select-in-form-item {
      height: 43px;
    }

    .ant-select-selector {
      ${InputStyle}
      background-color: ${getBackgroundColor};
      border-width: ${({ noborder }) => (noborder ? '0px' : '1px')};
      border-style: ${({ empty }) => (empty ? 'dashed' : 'solid')};
      border-color: ${getBorderColor};
      font-size: 14px;
      line-height: 24px;
      ${({ size }) => size === 'large' && 'padding: 10px'};

      .ant-select-selector__placeholder {
        line-height: normal;
        vertical-align: middle;
      }
      .ant-select-selector__rendered {
        line-height: 22px;
        margin-right: 3px;
      }
      .ant-select-arrow {
        right: 6px;
        & > * {
          line-height: 1;
        }
      }
      &:hover,
      &:focus {
        background-color: ${getBackgroundColor};
        border-color: ${getBorderColor};
        border-right-width: 1px !important;
      }
    }
    ${props =>
      props.softFilled &&
      css`
        .ant-select-selector {
          position: relative;
          border: 0;
          background-color: ${getBackgroundColor};
        }
        .ant-select-selector,
        .ant-select-arrow {
          color: ${containerColor};
          font-weight: 600;
          background-color: transparent;
        }
        .ant-select-selector {
          border-color: transparent;
        }
        .ant-select-selector:hover {
          border-color: transparent !important;
        }
      `}
  }
`;

export default ToolSelect;
