// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

// Dependencies
import * as Sentry from '@sentry/react';
import { mainTheme } from 'styles/themes';
import getErrorMessages from 'helper/ErrorComponent/getErrorMessages';
import TranslationsContext from 'providers/translationsProvider';

// Components
import { Button } from 'components/elements';

// Assets
import bfbLogoNoTagline from 'static/images/B4Blogo-notagline.png';
import maintenanceImage from 'static/images/illustration_construction.png';
// import { ReactComponent as ErrorFace } from './assets/error_face.svg';
// import mapBackground from './assets/map-background_full.png';

import { DEFAULT_ERROR, CONNECTIVITY_ERROR, SERVER_ERROR } from './constants';
import queryString from 'utils/queryString';

const ErrorComponent = ({ errorType = DEFAULT_ERROR, ...props }) => {
  const translationsFn = React.useContext(TranslationsContext);
  // When there is a React crash, useContext will return undefined
  const { format, t } = translationsFn || { format: val => val, t: val => val };

  const onClick = () => {
    Sentry.showReportDialog();
  };

  const onClose = () => {
    window.location.href = '/';
  };

  const handleRefreshClick = () => {
    /* 
      refresh the page adding a query param to force a refresh without cache 
    */

    // get the current URL and query params
    const url = window.location.href;
    const queryParams = queryString.parse(window.location.search);

    // add a random query param to force a refresh
    queryParams.refresh = Math.random().toString(36).slice(2, 12);

    // update the URL
    window.location.href = url.split('?')[0] + queryString.format(queryParams);
  };

  const errorMessage = getErrorMessages(errorType, t, format);

  const renderButtons = () => {
    switch (errorType) {
      case CONNECTIVITY_ERROR:
        return (
          <div className="buttons">
            <Button size="small" type="primary" onClick={handleRefreshClick}>
              {t('REFRESH PAGE')}
            </Button>
          </div>
        );
      case SERVER_ERROR:
        return null;
      default:
        return (
          <div className="buttons">
            <Button size="small" type="primary" onClick={onClick}>
              {t('REPORT BUG')}
            </Button>
            <Button size="small" type="primary" onClick={onClose}>
              {t('GO TO DASHBOARD')}
            </Button>
          </div>
        );
    }
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Wrapper data-test="component-error">
        <div className="error-message">
          <img className="hero-image" src={maintenanceImage} alt="maintenance-illustration" />
          <div className="container">
            <div className="title">{errorMessage.title}</div>
            <p className="description">{errorMessage.description}</p>
            {renderButtons()}
            <div className="spacing" />
            <img className="logo" src={bfbLogoNoTagline} alt="bfb-logo-no-tagline" />
          </div>
        </div>
        {/* <div className="error-illustration">
          <ErrorFace />
        </div> */}
      </Wrapper>
    </ThemeProvider>
  );
};

ErrorComponent.propTypes = {
  errorType: PropTypes.number,
};

const Wrapper = styled.div`
  background: #FFF;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .error-message,
  .error-illustration {
    min-height: 100vh;
  }
  .error-message {
    width: 55%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 36px 0;
    color: #000000;
    .hero-image {
      max-width: 560px;
      object-fit: contain;
      margin: 0 auto 40px;
      pointer-events: none;
    }
    }
    .container {
      display: flex;
      flex-flow: column;
      flex: 1;
      margin: 0 auto;
      max-width: 560px;
      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
        color: ${({ theme: { primaryColor } }) => primaryColor};
      }
      .description {
        font-size: 16px;
        margin-bottom: 18px;
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        margin: 0 auto;
        margin: 0 -12px 0;
        button {
          margin: 12px;
          font-size: 15px;
          padding: 10px 15px;
        }
      }
    }
    .spacing {
      flex: 1;
    }
    .logo {
      display: block;
      object-fit: contain;
      max-width: 200px;
      margin: 56px auto 0;
      padding: 24px;
    }
  }
  /* .error-illustration {
    width: 45%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0 0 0 40px;
    background-color: ${({ theme: { primaryColor } }) => primaryColor};
    background-image: url({mapBackground});
    svg {
      height: auto;
      max-width: 100%;
      overflow: visible;
    }
  } */
  @media (max-width: ${({ theme: { screenMdMin } }) => screenMdMin}) {
  .error-message {
    width: 100%;
    padding: 36px 0 0;
    .logo {
      max-width: 100%;
    }
  }
  /* .error-illustration {
    display: none;
  } */
`;

export default ErrorComponent;
