import { types } from 'store/modules/ImpactPlan/result';

const clearError = () => ({
  type: types.RESULT_CLEAR_ERROR,
});

const createResult = ({ ...result }) => ({
  type: types.RESULT_CREATE,
  payload: { ...result },
});

const createResultSucceeded = ({ id, ...result }) => ({
  type: types.RESULT_CREATE_SUCCEEDED,
  payload: { id, ...result },
});

const createResultFailed = ({ message }) => ({
  type: types.RESULT_CREATE_FAILED,
  payload: { message },
});

const deleteResult = ({ id, system }) => ({
  type: types.RESULT_DELETE,
  payload: { id, system },
});

const deleteResultSucceeded = ({ id, system }) => ({
  type: types.RESULT_DELETE_SUCCEEDED,
  payload: { id, system },
});

const deleteResultFailed = ({ message }) => ({
  type: types.RESULT_DELETE_FAILED,
  payload: { message },
});

const fetchResult = ({ id }) => ({
  type: types.RESULT_FETCH,
  payload: { id },
});

const fetchResultSucceeded = ({ id, ...result }) => ({
  type: types.RESULT_FETCH_SUCCEEDED,
  payload: { id, ...result },
});

const fetchResultFailed = ({ message }) => ({
  type: types.RESULT_FETCH_FAILED,
  payload: { message },
});

const listResults = ({ system }) => ({
  type: types.RESULTS_LIST,
  payload: { system },
});

const listResultsSucceeded = payload => ({
  type: types.RESULTS_LIST_SUCCEEDED,
  payload,
});

const listResultsFailed = error => ({
  type: types.RESULTS_LIST_FAILED,
  payload: error,
});

const updateResult = ({ id, ...result }) => ({
  type: types.RESULT_UPDATE,
  payload: { id, ...result },
});

const updateResultSucceeded = ({ id, ...result }) => ({
  type: types.RESULT_UPDATE_SUCCEEDED,
  payload: { id, ...result },
});

const updateResultFailed = ({ message }) => ({
  type: types.RESULT_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createResult,
  createResultFailed,
  createResultSucceeded,
  deleteResult,
  deleteResultFailed,
  deleteResultSucceeded,
  fetchResult,
  fetchResultFailed,
  fetchResultSucceeded,
  listResults,
  listResultsFailed,
  listResultsSucceeded,
  updateResult,
  updateResultSucceeded,
  updateResultFailed,
};
