import { Record } from 'immutable';
import ContentType from '../ContentType';

const InvestmentRecord = Record({
  id: null,
  contentType: ContentType.INVESTMENT,
  value: null,
  name: '',
  scenario: null,
  type: null,
  comments: null,
  created: null,
  updated: null,
  paid: null,
});

class Investment extends InvestmentRecord {
  static contentType = ContentType.INVESTMENT;

  static TYPE_REQUIRED_INVENTORY = 0;
  static TYPE_UTILITY_DEPOSIT = 1;
  static TYPE_INITIAL_ADVERTISING = 2;
  static TYPE_PRE_LAUNCH_SALARIES = 3;
  static TYPE_RENT_DEPOSIT = 4;
  static TYPE_FACTORY_SUPPLIES = 5;
  static TYPE_REQUIRED_CASH = 6;
  static TYPE_LEGAL = 7;
  static TYPE_LICENSES = 8;
  static TYPE_OTHER_EXPENSES = 9;

  static getTypeNames = t => ({
    [Investment.TYPE_REQUIRED_INVENTORY]: t('Required Inventory'),
    [Investment.TYPE_UTILITY_DEPOSIT]: t('Utility Deposits'),
    [Investment.TYPE_INITIAL_ADVERTISING]: t('Initial Advertising & Promotions'),
    [Investment.TYPE_PRE_LAUNCH_SALARIES]: t('Pre-Launch Salaries'),
    [Investment.TYPE_RENT_DEPOSIT]: t('Rent Deposits'),
    [Investment.TYPE_FACTORY_SUPPLIES]: t('Factory & Office Supplies'),
    [Investment.TYPE_REQUIRED_CASH]: t('Required Cash on Hand'),
    [Investment.TYPE_LEGAL]: t('Legal & Accounting Fees'),
    [Investment.TYPE_LICENSES]: t('Licenses'),
    [Investment.TYPE_OTHER_EXPENSES]: t('Other Initial Expenses'),
  });

  static getTypesOptions = t =>
    Object.entries(Investment.getTypeNames(t)).map(([type, typeName]) => ({
      label: typeName,
      value: parseInt(type, 10),
    }));

  get getId() {
    return this.get('id');
  }
}

export default Investment;
