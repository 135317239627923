// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// Dependencies
import { CELL_ALIGNMENT, TOTAL_KEY, TOTAL_RENDER_COMPONENT } from '../dependencies';

// Components
import BodyCell from '../BodyCell';
import TableRow from '../TableRow';

const TotalRow = props => {
  const {
    totalRow,
    totalValuesPerRow,
    totalValuesPerColumn,
    tableColumns,
    columnKeys,
    isFixedColumn,
    fixedLeftColumns,
  } = props;
  const {
    [TOTAL_KEY]: totalCell, // Total of totals per column.
    [TOTAL_RENDER_COMPONENT]: totalComponent,
  } = totalRow;

  const rightmostFixedColumn = fixedLeftColumns && fixedLeftColumns[fixedLeftColumns.length - 1];

  const render = useCallback(
    (respectiveCell, totalValue) => {
      switch (true) {
        case respectiveCell && typeof respectiveCell.children === 'function':
          return respectiveCell.children(totalValue);
        case Boolean(respectiveCell && respectiveCell.children):
          return respectiveCell.children;
        case totalComponent && typeof totalComponent === 'function':
          return totalComponent(totalValue);
        default:
          return totalValue;
      }
    },
    [totalComponent]
  );

  return (
    <TableRow rowKey="total" className="table-totals-row">
      {columnKeys.map(key => {
        const isFixed = isFixedColumn
          ? tableColumns[key] && !tableColumns[key].fixed
          : tableColumns[key] && tableColumns[key].fixed;
        const isRightmost = key === (rightmostFixedColumn && rightmostFixedColumn[0]);
        if (key === TOTAL_KEY) {
          const totalsArray = totalValuesPerRow.current && Object.values(totalValuesPerRow.current);
          const total = totalsArray.reduce((accum, val) => accum + val, 0);
          return (
            <BodyCell
              tableColumns={tableColumns}
              key={key}
              head={false}
              align={CELL_ALIGNMENT.RIGHT}
              className={`table-absolute-total-cell${isRightmost ? ' rightmost-cell' : ''}`}
              fixed={isFixed}
            >
              {render(totalCell, total)}
            </BodyCell>
          );
        }
        const totalColumnValue =
          totalValuesPerColumn.current &&
          totalValuesPerColumn.current[key] &&
          Object.values(totalValuesPerColumn.current[key]).reduce(
            (total, value) => total + value,
            0
          );
        const cell = totalRow[key];
        return (
          <BodyCell
            tableColumns={tableColumns}
            key={key}
            head={false}
            align={totalColumnValue ? CELL_ALIGNMENT.RIGHT : undefined}
            className={`table-total-cell${isRightmost ? ' rightmost-cell' : ''}`}
            fixed={isFixed}
            {...cell}
          >
            {render(cell, totalColumnValue)}
          </BodyCell>
        );
      })}
    </TableRow>
  );
};

TotalRow.propTypes = {
  totalRow: PropTypes.instanceOf(Object).isRequired,
  totalValuesPerRow: PropTypes.instanceOf(Object).isRequired,
  totalValuesPerColumn: PropTypes.instanceOf(Object).isRequired,
  tableColumns: PropTypes.instanceOf(Object).isRequired,
  columnKeys: PropTypes.instanceOf(Array).isRequired,
  isFixedColumn: PropTypes.bool,
  fixedLeftColumns: PropTypes.instanceOf(Array),
};

TotalRow.defaultProps = {
  isFixedColumn: undefined,
  fixedLeftColumns: undefined,
};

export default React.memo(TotalRow);
