// Libraries
import styled, { css } from 'styled-components';

// Components
import { Tag } from 'antd';

const getBackgroundColor = props => {
  const { color, toolkit, theme, tool } = props;
  if (color) return `${color}1a`;
  if (toolkit) return theme.hyperlinkBgFade;

  switch (tool) {
    case 1:
      return '#C330001A';
    case 2:
      return '#89043D1A';
    case 3:
      return '#8407E51A';
    case 4:
      return '#2121CE1A';
    case 5:
      return '#227EA31A';
    case 6:
      return '#1A754E1A';
    case 7:
      return '#707d281A';
    case 8:
      return '#B1740F1A';
    default:
      return theme.hyperlinkBgFade;
  }
};

const getTextColor = props => {
  const { color, toolkit, theme, tool } = props;
  if (color) return color;
  if (toolkit) return theme.hyperlink;

  switch (tool) {
    case 1:
      return '#C33000';
    case 2:
      return '#89043D';
    case 3:
      return '#8407E5';
    case 4:
      return '#2121CE';
    case 5:
      return '#227EA3';
    case 6:
      return '#1A754E';
    case 7:
      return '#707d28';
    case 8:
      return '#B1740F';
    default:
      return theme.hyperlink;
  }
};

const StyledTag = styled(Tag)`
  align-items: center;
  background-color: ${getBackgroundColor} !important;
  border-radius: ${({ type }) => (type === 'squared' ? '5px' : '20px')};
  border: none;
  color: ${getTextColor} !important;
  cursor: initial;
  font-size: 14px;
  display: inline-flex;
  padding: ${({ size }) => (size === 'small' ? '0px 5px' : '2px 15px')};
  ${({ size }) =>
    size === 'small'
      ? css`
          &&& {
            line-height: 20px;
          }
        `
      : css`
          &&& {
            line-height: 25px;
          }
        `};
  transition: all ease 300ms;
`;

export default StyledTag;
