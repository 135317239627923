import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// Generates an access token. Currently used to troubleshoot a user's account.

export const userTokenAPI = createApi({
  reducerPath: 'userTokenAPI',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/troubleshoot/tokens/',
  }),
  endpoints: builder => ({
    createToken: builder.mutation({
      query: ({ userId }) => ({
        url: '',
        method: 'POST',
        params: { user_id: userId },
      }),
    }),
  }),
});

export const { useCreateTokenMutation } = userTokenAPI;
