import { createSelector } from 'reselect';

const getState = state => state.programSetup;

const getRawPrograms = createSelector([getState], state => state.data);

const getProgramsById = createSelector([getRawPrograms], state => state.byId);

const getSelectedProgram = createSelector([getState], state => state.selectedProgram);

export default {
  getProgramsById,
  getSelectedProgram,
};
