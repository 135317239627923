import { call, put, all, takeLatest } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import { actions, types } from 'store/modules/Tools/tool/';
import { actions as stepActions } from 'store/modules/Tools/step';
import { serviceTool, serviceProjectTool } from 'services/Tools';
import { normalizeTools } from 'store/normalizers/toolNormalizer';

export function* listTools(action) {
  const { program, project } = action.payload;
  try {
    const response = yield call(serviceTool.listTools, { program, project });
    const { tools, steps } = yield call(normalizeTools, response.data);
    yield put(actions.listToolsSucceeded({ results: tools }));
    yield steps && put(stepActions.listStepsSucceeded({ results: steps }));
  } catch (e) {
    yield put(actions.listToolsFailed({ message: e.message }));
  }
}

export function* updateTool(action) {
  const { id, programToolId, ...tool } = action.payload;
  try {
    const updated = yield call(serviceProjectTool.updateProjectTool, {
      id,
      ...tool,
    });
    yield put(
      actions.updateToolSucceeded({
        programToolId,
        ...updated.data,
      })
    );
  } catch (e) {
    yield put(actions.updateToolFailed({ message: e.message }));
  }
}

function* toolSaga() {
  yield all([takeLatest(types.TOOLS_LIST, listTools), takeLatest(types.TOOL_UPDATE, updateTool)]);
}

export default toolSaga;
