import dayjs from 'dayjs';

// eslint-disable-next-line import/prefer-default-export
export const getFormattedDate = (date, t) => dayjs(date).format(t('MMM DD'));

/**
 * Check if a given Moment.js date object matches the specified date and time components.
 * @param {dayjs} date - The Moment.js date object to check.
 * @param {object} options - An object containing named properties for date and time components (year, month, day, hour, minute, second).
 * @returns {boolean} True if the date matches the specified components, false otherwise.
 */

export const isDateMatch = (date, options) => {
  if (options.year !== undefined && date.year() !== options.year) {
    return false;
  }
  if (options.month !== undefined && date.month() !== options.month) {
    return false;
  }
  if (options.day !== undefined && date.date() !== options.day) {
    return false;
  }
  if (options.hour !== undefined && date.hour() !== options.hour) {
    return false;
  }
  if (options.minute !== undefined && date.minute() !== options.minute) {
    return false;
  }
  if (options.second !== undefined && date.second() !== options.second) {
    return false;
  }
  return true;
};
