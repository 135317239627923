import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions as chainActions } from 'store/modules/StakeholderMap/chain';
import { actions as channelActions } from 'store/modules/StakeholderMap/channel';
import chainService from 'services/StakeholderMap/chain';
import actions from './actions';
import types from './types';

export function* createChainAndChannel(action) {
  const { chainName, product, ...channel } = action.payload;
  try {
    const chain = yield call(chainService.createChain, {
      product,
      name: chainName,
    });
    yield put(chainActions.createChainSucceeded(chain.data));
    yield put(
      channelActions.createChannel({
        ...channel,
        new_chain: chain.data.id,
        product,
      })
    );
  } catch (e) {
    yield put(
      actions.fetchDependenciesFailed({
        message: e.message || e.response.data.detail,
      })
    );
  }
}

function* distributionChannelCombiner() {
  yield all([takeLatest(types.CREATE_CHAIN_AND_CHANNEL, createChainAndChannel)]);
}

export default distributionChannelCombiner;
