import api from './api';

const listMemberProgramStats = query =>
  api.get('/api/management-panel/members/stats', { params: query });
const listMentorProgramStats = query =>
  api.get('/api/management-panel/mentors/stats', { params: query });
const getListMemberIncubatingProgramStats = query =>
  api.get('/api/management-panel/incubating/stats', { params: query });
const getListMemberAlumniProgramStats = query =>
  api.get('/api/management-panel/members/alumni/stats', { params: query });
const getListMentorAlumniProgramStats = query =>
  api.get('/api/management-panel/mentors/alumni/stats', { params: query });
const getListMemberRejectedProgramStats = query =>
  api.get('/api/management-panel/members/rejected/stats', { params: query });
const getListMentorRejectedProgramStats = query =>
  api.get('/api/management-panel/mentors/rejected/stats', { params: query });
const getListMemberDroppedProgramStats = query =>
  api.get('/api/management-panel/members/dropped/stats', { params: query });
const getListMentorDroppedProgramStats = query =>
  api.get('/api/management-panel/mentors/dropped/stats', { params: query });

export default {
  listMemberProgramStats,
  listMentorProgramStats,
  getListMemberIncubatingProgramStats,
  getListMemberAlumniProgramStats,
  getListMentorAlumniProgramStats,
  getListMemberRejectedProgramStats,
  getListMentorRejectedProgramStats,
  getListMemberDroppedProgramStats,
  getListMentorDroppedProgramStats,
};
