import { Record } from 'immutable';

const MentorRecord = Record({
  id: null,
  is_admin: false,
  admin_type: null,
  additional_languages: [],
  application_created: null,
  application_id: null,
  application_max_projects: null,
  application_open_to_comentor: null,
  application_ranking_submitted: null,
  application_rejected_reason: null,
  application_rejected: null,
  application_status: null,
  areas_expertise: [],
  areas_innovation: [],
  biography: '',
  birthday: '',
  check_commitment: null,
  check_confidential: null,
  check_online: null,
  city: '',
  country_sort: '',
  country: '',
  education: [],
  email: '',
  emails: null,
  entrepreneur_before: null,
  entrepreneur_challenges: '',
  expectations: '',
  expertise_type: '',
  first_name: '',
  GDPR: null,
  gender: '',
  had_first_visit_workspace: false,
  had_tools_tutorial: false,
  interests: [],
  interestships: [],
  invest_updates: null,
  invites_sent: 0,
  isMember: false,
  isMentor: true,
  languages: [],
  last_login: null,
  last_name: '',
  linkedin: '',
  mentoring_experience: '',
  motivation: '',
  notes: null,
  origin_program_type: null,
  origin: '',
  phone_number: '',
  picture: null,
  profile_completion: 0,
  progress: null,
  rating: null,
  recommended_program_referrer: null,
  recommended_program: null,
  sectors: [],
  signed_invites_count: 0,
  startup_investor: null,
  status: null,
  tag: null,
  tagline: '',
  timezone: '',
  user: null,
  video_url: null,
  why_me: '',
  work_experience: [],
  // Project properties
  affiliation: '',
  department: '',
  expertises: [],
  geographic_preferences: [],
  industry: null,
  language: 'en_us',
  skills: [],
  stages: [],
  projects: [],
});

// Possible project statuses fetched from the backend.
export const MENTOR_APP_STATUS_APPLICATION = 'application';
export const MENTOR_APP_STATUS_SELECTION = 'selection';
export const MENTOR_APP_STATUS_APPROVED = 'approved';
export const MENTOR_APP_STATUS_MATCHING = 'matching';
export const MENTOR_APP_STATUS_INBUCATION = 'incubation';
export const MENTOR_APP_STATUSES = [
  MENTOR_APP_STATUS_APPLICATION,
  MENTOR_APP_STATUS_SELECTION,
  MENTOR_APP_STATUS_APPROVED,
  MENTOR_APP_STATUS_MATCHING,
  MENTOR_APP_STATUS_INBUCATION,
];

// New Statuses PM Panel
export const REJECTED = 'rejected';

// Admin types
const BRIDGE_STAFF = 'bridge_staff'; // Program Manager
const EXTERNAL = 'external'; // Incubation Manager
export const ADMIN_TYPES = { BRIDGE_STAFF, EXTERNAL };

class Mentor extends MentorRecord {
  static STATUS_APPLICANT = 0;
  static STATUS_PENDING = 1;
  static STATUS_AVAILABLE = 2;
  static STATUS_MENTORING = 3;
  static STATUS_UNAVAILABLE = 4;
  static STATUS_REJECTED = 5;
  static STATUS_APP_INCOMPLETE = 10;
  static STATUS_APP_PENDING = 11;
  static STATUS_APP_APPROVED = 12;
  static STATUS_READY_TO_MENTOR = 13;
  static STATUS_HAS_MENTORED = 14;
  static isMentor = true;
  static isMember = false;
  static ORIGIN_PROGRAM_B2B = 0;
  static ORIGIN_PROGRAM_B2C = 1;

  isAdmin() {
    return this.get('is_admin');
  }
}

export default Mentor;
