import { createSelector } from 'reselect';

const getState = state => state.memberApplication;

const getRawApplications = createSelector([getState], state => state.get('data'));

const getApplicationsById = createSelector([getRawApplications], state => state.get('byId'));

const getApplications = createSelector([getApplicationsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const updatePending = createSelector([getState], state => state.get('updatePending'));

const getIsLoading = createSelector([getState], state => state.get('isLoading'));

export default {
  getApplications,
  getApplicationsById,
  getErrorMessage,
  updatePending,
  getIsLoading,
};
