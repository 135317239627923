import { types } from 'store/modules/translations';

const translationsFetchRequested = ({ translationData }) => ({
  type: types.TRANSLATION_FETCH_REQUESTED,
  payload: translationData,
});

const translationsFetchSucceeded = ({ translationData }) => ({
  type: types.TRANSLATION_FETCH_SUCCEEDED,
  payload: translationData,
});

const translationsFetchFailed = ({ translationData }) => ({
  type: types.TRANSLATION_FETCH_FAILED,
  payload: translationData,
});

const translationsChange = ({ selectedLanguage }) => ({
  type: types.TRANSLATION_CHANGE,
  payload: { selectedLanguage },
});

export default {
  translationsFetchRequested,
  translationsFetchSucceeded,
  translationsFetchFailed,
  translationsChange,
};
