/* eslint-disable indent */
// Libraries
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Dependencies
import CommentsRefContext from 'providers/commentsHighlightProvider/context';

// Components
import { ToolContainer, ToolCommentIcon, ToolSelectIcon } from '../index';
import ToolDeleteIcon from '../ToolIcon/ToolDeleteIcon';

class ToolInputContainerComponent extends Component {
  static propTypes = {
    bold: PropTypes.bool,
    children: PropTypes.node.isRequired,
    colorIndex: PropTypes.number,
    empty: PropTypes.bool,
    filled: PropTypes.bool,
    highlightComments: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    onSelectClick: PropTypes.func,
    selected: PropTypes.bool,
    showComments: PropTypes.bool,
    showDelete: PropTypes.bool,
    showSelect: PropTypes.bool,
    softFilled: PropTypes.bool,
    star: PropTypes.bool,
    textAreaMinHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    bold: false,
    colorIndex: undefined,
    empty: false,
    filled: false,
    highlightComments: true,
    onDeleteClick: null,
    onSelectClick: null,
    selected: false,
    showComments: true,
    showDelete: true,
    showSelect: true,
    softFilled: false,
    star: false,
    textAreaMinHeight: undefined,
  };

  static contextType = CommentsRefContext;

  constructor(props) {
    super(props);
    this.myCommentIconId = `tooltip-comment-icon-id-${new Date().getTime()}`;
  }

  handleCommentClick = e => {
    // Stopping the click propagation to avoid unintended side-effects.
    if (e && e.stopPropagation) e.stopPropagation();
    const { setActiveNodeRect } = this.context;
    const { onCommentsClick } = this.props;
    if (onCommentsClick) onCommentsClick();
    const rect = document.getElementById(this.myCommentIconId).getBoundingClientRect();
    if (setActiveNodeRect) {
      setActiveNodeRect(rect, this.myCommentIconId);
    }
  };

  render() {
    const {
      bold,
      children,
      colorIndex,
      highlightComments,
      onDeleteClick,
      onSelectClick,
      selected,
      star,
      showComments,
      showDelete,
      showSelect,
      textAreaMinHeight,
      softFilled,
      empty,
      ...rest
    } = this.props;
    return (
      <ToolContainer
        bold={bold}
        colorIndex={colorIndex}
        selected={selected}
        textAreaMinHeight={textAreaMinHeight}
        softFilled={softFilled}
        empty={empty}
        // Stopping the click propagation to avoid unintended side-effects.
        onClick={e => {
          if (e && e.stopPropagation) e.stopPropagation();
        }}
        {...rest}
      >
        {showSelect && (
          <aside className="select-input">
            <ToolSelectIcon
              empty={empty}
              star={star}
              selected={selected}
              onSelectClick={onSelectClick}
            />
          </aside>
        )}
        <div className="center-column">{children}</div>
        {/* ICONS */}
        {(showComments || showDelete) && (
          <aside className="icon-row">
            {showComments ? (
              <i className="comment-icon" id={this.myCommentIconId}>
                <ToolCommentIcon active={highlightComments} onClick={this.handleCommentClick} />
              </i>
            ) : null}
            {showDelete ? <ToolDeleteIcon onClick={onDeleteClick} /> : null}
          </aside>
        )}
      </ToolContainer>
    );
  }
}

const containerColor = css`
  ${({ colorIndex, theme }) =>
    colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#D9D9D9'}
`;

const ToolInputContainer = styled(ToolInputContainerComponent)`
  &&& {
    height: ${props => props.height};
    div > .ant-input {
      min-height: ${props => props.textAreaMinHeight};
    }

    .center-column {
      flex: 1;
      max-width: ${({ showDelete }) => (showDelete ? 'calc(100% - 30px)' : '100%')};

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-label {
        display: none;
      }
    }

    .comment-icon {
      display: inline-flex;
      align-items: center;
    }

    .select-input {
      margin-inline: 4px;
      display: flex;

      > * {
        cursor: ${({ onSelectClick, empty }) => {
          switch (true) {
            case Boolean(onSelectClick):
              return 'pointer';
            case empty:
              return 'not-allowed';
            default:
              return 'default';
          }
        }};
        color: ${containerColor};
        vertical-align: bottom;
      }
    }

    .icon-row {
      display: flex;
      align-items: center;
      height: 35px;
      margin-right: 10px;
      & > * {
        font-size: 18px;
        margin: 0 3px;
        transition: all 0.3s ease;
        vertical-align: middle;
        color: ${({ theme }) => theme.greyColor};
        &.active,
        &:hover {
          color: ${({ theme }) => theme.secondaryColor};
        }
        &:first-child {
          margin-left: 6px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;

export default ToolInputContainer;
