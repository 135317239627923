import { Record } from 'immutable';

const DeepDiveRecord = new Record({
  id: null,
  title: null,
  default: null,
  description: null,
  description_en: null,
  description_es: null,
  description_pt: null,
  description_fr: null,
  links: [],
  mentor_material: null,
  tool_name: '',
  program_tools: [],
  step_name: '',
  step: null,
});

class DeepDive extends DeepDiveRecord {
  get id() {
    return this.get('id');
  }
}

export default DeepDive;
