const FETCH_MEMBER_INVITES = 'bfb/Invites/FETCH_MEMBER_INVITES';
const FETCH_MEMBER_INVITES_PENDING = 'bfb/Invites/FETCH_MEMBER_INVITES_PENDING';
const FETCH_MEMBER_INVITES_FAILED = 'bfb/Invites/FETCH_MEMBER_INVITES_FAILED';
const FETCH_MEMBER_INVITES_SUCCEEDED = 'bfb/Invites/FETCH_MEMBER_INVITES_SUCCEEDED';

export default {
  FETCH_MEMBER_INVITES,
  FETCH_MEMBER_INVITES_PENDING,
  FETCH_MEMBER_INVITES_SUCCEEDED,
  FETCH_MEMBER_INVITES_FAILED,
};
