import types from './types';

const clearError = () => ({
  type: types.PRODUCT_BUSINESS_MODEL_CLEAR_ERROR,
});

const listBusinessModels = ({ product }) => ({
  type: types.BUSINESS_MODELS_LIST,
  payload: { product },
});

const listBusinessModelsSucceeded = payload => ({
  type: types.BUSINESS_MODELS_LIST_SUCCEEDED,
  payload,
});

const listBusinessModelsFailed = ({ message }) => ({
  type: types.BUSINESS_MODELS_LIST_FAILED,
  payload: { message },
});

const listProductBusinessModels = ({ product }) => ({
  type: types.PRODUCT_BUSINESS_MODELS_LIST,
  payload: { product },
});

const listProductBusinessModelsSucceeded = payload => ({
  type: types.PRODUCT_BUSINESS_MODELS_LIST_SUCCEEDED,
  payload,
});

const listProductBusinessModelsFailed = ({ message }) => ({
  type: types.PRODUCT_BUSINESS_MODELS_LIST_FAILED,
  payload: { message },
});

const fetchProductBusinessModel = ({ product, id }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_FETCH,
  payload: { product, id },
});

const fetchProductBusinessModelSucceeded = ({ ...businessModel }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_FETCH_SUCCEEDED,
  payload: { ...businessModel },
});

const fetchProductBusinessModelFailed = ({ message }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_FETCH_FAILED,
  payload: { message },
});

const createProductBusinessModel = ({ product, ...businessModel }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_CREATE,
  payload: { product, ...businessModel },
});

const createProductBusinessModelSucceeded = ({ product, ...businessModel }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_CREATE_SUCCEEDED,
  payload: { product, ...businessModel },
});

const createProductBusinessModelFailed = ({ message }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_CREATE_FAILED,
  payload: { message },
});

const updateProductBusinessModel = ({ product, ...businessModel }, callback) => ({
  type: types.PRODUCT_BUSINESS_MODEL_UPDATE,
  payload: { product, ...businessModel },
  callback,
});

const updateProductBusinessModelSucceeded = ({ ...businessModel }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_UPDATE_SUCCEEDED,
  payload: { ...businessModel },
});

const updateProductBusinessModelFailed = ({ message }) => ({
  type: types.PRODUCT_BUSINESS_MODEL_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  listBusinessModels,
  listBusinessModelsFailed,
  listBusinessModelsSucceeded,
  createProductBusinessModel,
  createProductBusinessModelFailed,
  createProductBusinessModelSucceeded,
  fetchProductBusinessModel,
  fetchProductBusinessModelFailed,
  fetchProductBusinessModelSucceeded,
  listProductBusinessModels,
  listProductBusinessModelsSucceeded,
  listProductBusinessModelsFailed,
  updateProductBusinessModel,
  updateProductBusinessModelFailed,
  updateProductBusinessModelSucceeded,
};
