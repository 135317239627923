import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import InputStyle from './common';

const Input = styled(AntInput)`
  &&& {
    ${InputStyle}
  }
`;

const StyledGroup = styled(Input.target.Group)`
  &&& {
    margin-top: 9.75px;
  }
`;

const StyledTextArea = styled(Input.target.TextArea)`
  &&& {
    ${InputStyle}
    min-height: 27px;
  }
`;

Input.Group = StyledGroup;
Input.TextArea = StyledTextArea;

export default Input;
