import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/scenario';
import { types as commentTypes } from 'store/modules/comment';
import serviceScenario from 'services/scenario';
import Scenario from 'models/FinancialProjections/Scenario';

export function* createScenario(action) {
  const { project, ...scenario } = action.payload;
  try {
    const response = yield call(serviceScenario.createScenario, {
      project,
      ...scenario,
    });
    yield put(actions.createScenarioSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.createScenarioFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteScenario(action) {
  const { scenarioId } = action.payload;
  const { callback } = action;
  try {
    yield call(serviceScenario.deleteScenario, { scenarioId });
    if (callback) yield callback();
    yield put(actions.deleteScenarioSucceeded({ scenarioId }));
  } catch (e) {
    yield put(
      actions.deleteScenarioFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchScenario(action) {
  const { id, project } = action.payload;
  try {
    const scenario = yield call(serviceScenario.fetchScenario, { id, project });
    yield put(actions.fetchScenarioSucceeded(scenario.data));
  } catch (e) {
    yield put(actions.fetchScenarioFailed({ message: e.message }));
  }
}

export function* listScenarios(action) {
  const { project } = action.payload;
  try {
    const scenarios = yield call(serviceScenario.listScenarios, { project });
    yield put(actions.listScenariosSucceeded({ results: scenarios.data }));
  } catch (e) {
    yield put(actions.listScenariosFailed({ message: e.message }));
  }
}

export function* updateScenario(action) {
  const { scenarioId, ...scenario } = action.payload;
  try {
    const updated = yield call(serviceScenario.updateScenario, {
      scenarioId,
      ...scenario,
    });
    yield put(actions.updateScenarioSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateScenarioFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Scenario.contentType) {
    yield put(
      actions.fetchScenario({
        projectId: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.SCENARIO_CREATE, createScenario),
    takeLatest(types.SCENARIO_DELETE, deleteScenario),
    takeLatest(types.SCENARIO_FETCH, fetchScenario),
    takeEvery(types.SCENARIO_UPDATE, updateScenario),
    takeLatest(types.SCENARIOS_LIST, listScenarios),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default authSaga;
