// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Dependencies
import { getRadianFromValue, getTextAnchor, setGaugeDegreesByPercentage } from '../dependencies';

const Gauge = props => {
  const {
    id,
    range,
    radialGradientRanges,
    linearGradientRanges,
    radialLabels,
    width,
    height,
    filledPercentage,
    strokeWidth,
    shouldNotAngleText,
  } = props;
  const [minValue, maxValue] = range;
  /**
   * The diameter is the width, and the width is the diameter minus the stroke-width.
   */
  const radius = width / 2 - strokeWidth;
  const filledCircumference = setGaugeDegreesByPercentage(filledPercentage, radius);
  /**
   * Other circle parameters.
   */
  const cx = width / 2;
  const cy = height;
  const strokeId = `Speedometer_${id}_strokeId`;
  return (
    <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
      {radialLabels.map(label => {
        /**
         * The radial labels are, as the name implies, radial.
         * Calculations are done here to determine the position and angle of the text labels
         * depending on their value range.
         */
        const [labelValue, labelName] = label;
        const isValueRange = Array.isArray(labelValue);
        const [angleInDeegres, angleInRadians] = getRadianFromValue(
          isValueRange ? labelValue.reduce((a, b) => a + b) / labelValue.length : labelValue,
          range
        );
        const textAngle = shouldNotAngleText ? 0 : angleInRadians * (180 / Math.PI) + 90;
        const x = cx + (radius + strokeWidth) * Math.cos(angleInRadians);
        const y = cy + (radius + strokeWidth) * Math.sin(angleInRadians);
        const textAnchor = getTextAnchor(angleInDeegres, shouldNotAngleText);
        return (
          <text
            key={`${labelName}_${x}_${y})`}
            x={x}
            y={y}
            textAnchor={textAnchor}
            dominantBaseline="central"
            transform={`rotate(${textAngle}, ${x}, ${y})`}
          >
            {labelName}
          </text>
        );
      })}
      <defs>
        {radialGradientRanges.length ? (
          <radialGradient
            id={strokeId}
            cx="50%"
            cy="0.270453335%"
            fx="50%"
            fy="0.270453335%"
            r="67.1828698%"
            gradientTransform="translate(0.500000,0.002705),rotate(90.000000),scale(1.000000,1.001989),translate(-0.500000,-0.002705)"
          >
            {radialGradientRanges.map(([colorRange, color]) => (
              <stop key={`${colorRange}_${color}`} stopColor={color} offset={colorRange} />
            ))}
          </radialGradient>
        ) : (
          <linearGradient id={strokeId} gradientTransform="rotate(0)">
            {/* The `offset` percentages are RELATIVE to the `filledPercentage` */}
            {linearGradientRanges.map(([colorRange, color]) => {
              const colorRangeAverage = colorRange.reduce((a, b) => a + b) / colorRange.length;
              const offset = `${(colorRangeAverage / (maxValue - minValue)) * 100}%`;
              return <stop key={offset} offset={offset} stopColor={color} />;
            })}
          </linearGradient>
        )}
      </defs>
      <circle
        stroke={`url(#${strokeId})`}
        strokeWidth={strokeWidth}
        style={{
          strokeDasharray: `${filledCircumference} ${filledCircumference}`,
          strokeDashoffset: filledCircumference,
        }}
        fill="transparent"
        r={radius}
        // Center bottom positioned
        cx={cx}
        cy={cy}
      />
    </svg>
  );
};

Gauge.propTypes = {
  id: PropTypes.string,
  range: PropTypes.instanceOf(Array),
  radialGradientRanges: PropTypes.instanceOf(Array),
  linearGradientRanges: PropTypes.instanceOf(Array),
  radialLabels: PropTypes.instanceOf(Array),
  width: PropTypes.number,
  height: PropTypes.number,
  filledPercentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  shouldNotAngleText: PropTypes.bool,
};

Gauge.defaultProps = {
  id: undefined,
  range: undefined,
  radialGradientRanges: undefined,
  linearGradientRanges: undefined,
  radialLabels: undefined,
  width: undefined,
  height: undefined,
  filledPercentage: undefined,
  strokeWidth: undefined,
  shouldNotAngleText: false,
};

export default React.memo(Gauge);
