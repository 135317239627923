import { Record } from 'immutable';

const CustomizationRecord = new Record({
  id: null,
  name: null,
  program: null,
  signup_language: null,
  primary_color: null,
  secondary_color: null,
  main_logo: null,
  main_logo_layout: null,
  bottom_logo: null,
  bottom_logo_layout: null,
  background_logo: null,
  header_logo: null,
  additional_question: null,
  additional_answer_false: null,
  additional_answer_true: null,
  communication_question: null,
  communication_answer_false: null,
  communication_answer_true: null,
  presubmit_message: null,
  member_phone_number_required_on_signup: null,
  mentor_phone_number_required_on_signup: null,
  program_name: null,
});

class Customization extends CustomizationRecord {
  static LAYOUT_LIMIT_WIDTH = 0;
  static LAYOUT_LIMIT_HEIGHT = 1;

  get getId() {
    return this.get('id');
  }
}

export default Customization;
