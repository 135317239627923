import { createSelector } from 'reselect';

const getState = state => state.step;

const getRawSteps = createSelector([getState], state => state.get('data'));

const getStepsById = createSelector([getRawSteps], state => state.get('byId'));

const getSteps = createSelector([getStepsById], state =>
  state
    .valueSeq()
    .toJS()
    .sort((a, b) => a.position - b.position)
);

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getSteps,
  getStepsById,
  getErrorMessage,
};
