// Libraries
import PropTypes from 'prop-types';
import countriesService from 'services/countries';
import APIMultiSelect from 'components/elements/MultiSelect/APIMultiSelect/';
import TranslationsContext from 'providers/translationsProvider';

class RegionMultiSelect extends APIMultiSelect {
  static contextType = TranslationsContext;
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    t: PropTypes.func,
    isPublic: PropTypes.bool,
  };

  static defaultProps = {
    t(text) {
      return text;
    },
    isPublic: false,
  };

  getResults = () => countriesService.getRegions();
  getKey = region => region[0];
  getValue = region => region[0];
  getText = region => this.props.t(region[0]);
}

export default RegionMultiSelect;
