import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/executiveSummary/members';
import executiveSummaryService from 'services/executiveSummary';

export function* listMembersExecutiveSummary(action) {
  try {
    const response = yield call(
      executiveSummaryService.listMembersExecutiveSummary,
      action.payload
    );
    yield put(actions.listMembersExecutiveSummarySucceeded(response.data));
  } catch (e) {
    yield put(actions.listMembersExecutiveSummaryFailed({ message: e.message }));
  }
}

export function* createMemberExecutiveSummary(action) {
  const { payload } = action;
  try {
    const response = yield call(executiveSummaryService.createMemberExecutiveSummary, payload);
    yield put(actions.createMemberExecutiveSummarySucceeded(response.data));
  } catch (e) {
    yield put(actions.createMemberExecutiveSummaryFailed({ message: e.message }));
  }
}

export function* updateMemberExecutiveSummary(action) {
  const { id, ...memberExecutiveSummary } = action.payload;
  try {
    const response = yield call(executiveSummaryService.updateMemberExecutiveSummary, {
      id,
      ...memberExecutiveSummary,
    });
    yield put(actions.updateMemberExecutiveSummarySucceeded(response.data));
  } catch (e) {
    yield put(actions.updateMemberExecutiveSummaryFailed({ message: e.message }));
  }
}

export function* uploadExecutiveSummaryImage(action) {
  const { id, ...memberExecutiveSummary } = action.payload;
  try {
    const response = yield call(executiveSummaryService.uploadMemberExecutiveSummaryImage, {
      id,
      ...memberExecutiveSummary,
    });
    yield put(actions.updateMemberExecutiveSummarySucceeded(response.data));
  } catch (e) {
    yield put(actions.updateMemberExecutiveSummaryFailed({ message: e.message }));
  }
}

export function* deleteMembersExecutiveSummary(action) {
  const { id } = action.payload;
  try {
    const response = yield call(executiveSummaryService.deleteMemberExecutiveSummary, { id });
    yield put(actions.deleteMemberExecutiveSummarySucceeded({ id }, response));
  } catch (e) {
    yield put(actions.deleteMemberExecutiveSummaryFailed({ message: e.message }));
  }
}

function* membersExecutiveSummarySaga() {
  yield all([
    takeLatest(types.MEMBER_EXECUTIVE_SUMMARY_LIST, listMembersExecutiveSummary),
    takeLatest(types.MEMBER_EXECUTIVE_SUMMARY_CREATE, createMemberExecutiveSummary),
    takeLatest(types.MEMBER_EXECUTIVE_SUMMARY_UPDATE, updateMemberExecutiveSummary),
    takeLatest(types.MEMBER_EXECUTIVE_SUMMARY_UPLOAD_IMAGE, uploadExecutiveSummaryImage),
    takeLatest(types.MEMBER_EXECUTIVE_SUMMARY_DELETE, deleteMembersExecutiveSummary),
  ]);
}

export default membersExecutiveSummarySaga;
