import { Record } from 'immutable';

const PillRecord = Record({
  id: null,
  title: '',
  description: '',
  links: [],
  category: null,
  tool: null,
});

class Pill extends PillRecord {
  get tool() {
    return this.get('tool');
  }
}

export default Pill;
