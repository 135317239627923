import { call, put, all, takeLatest } from 'redux-saga/effects';
import memberApplicationService from 'services/memberApplication';
import types from './types';
import actions from './actions';

export function* fetchApplication(action) {
  const { id } = action.payload;
  try {
    const application = yield call(memberApplicationService.fetchApplication, {
      id,
    });
    yield put(actions.fetchApplicationSucceeded(application.data));
  } catch (e) {
    yield put(actions.fetchApplicationFailed({ message: e.message }));
  }
}

export function* listApplications() {
  try {
    const applications = yield call(memberApplicationService.listApplications);
    yield put(actions.listApplicationsSucceeded({ applicationsData: applications.data }));
  } catch (e) {
    yield put(actions.listApplicationsFailed({ message: e.message }));
  }
}

export function* createApplication(action) {
  const { program, ...application } = action.payload;
  try {
    const response = yield call(memberApplicationService.createApplication, {
      program,
      ...application,
    });
    yield put(actions.createApplicationSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.createApplicationFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateApplication(action) {
  const { id, ...application } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(memberApplicationService.updateApplication, {
      id,
      ...application,
    });
    yield put(actions.updateApplicationSucceeded(updated.data));
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.updateApplicationFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateApplicationBulk(action) {
  const { callback } = action;
  try {
    const updated = yield call(memberApplicationService.updateApplicationBulk, action.payload);
    yield put(actions.updateApplicationBulkSucceeded(updated.data));
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.updateApplicationBulkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* validateApplicationStatus(action) {
  const { projectId, programId } = action.payload;
  try {
    const application = yield call(memberApplicationService.validateApplicationStatus, {
      projectId,
      programId,
    });
    yield put(actions.validateApplicationStatusSucceeded(application.data));
  } catch (e) {
    yield put(actions.validateApplicationStatusFailed({ message: e.message }));
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.APPLICATIONS_LIST, listApplications),
    takeLatest(types.APPLICATION_FETCH, fetchApplication),
    takeLatest(types.APPLICATION_CREATE, createApplication),
    takeLatest(types.APPLICATION_UPDATE, updateApplication),
    takeLatest(types.APPLICATION_BULK_UPDATE, updateApplicationBulk),
    takeLatest(types.APPLICATION_STATUS, validateApplicationStatus),
  ]);
}

export default authSaga;
