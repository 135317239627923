import types from './types';

const fetchPillsCategories = payload => ({
  type: types.PILLS_CATEGORIES_FETCH,
  payload,
});

const fetchPillsCategoriesSucceeded = payload => ({
  type: types.PILLS_CATEGORIES_FETCH_SUCCEEDED,
  payload,
});

const fetchPillsCategoriesFailed = ({ message }) => ({
  type: types.PILLS_CATEGORIES_FETCH_FAILED,
  payload: { message },
});

export default {
  fetchPillsCategories,
  fetchPillsCategoriesSucceeded,
  fetchPillsCategoriesFailed,
};
