import { Record } from 'immutable';

const LinkRecord = new Record({
  id: null,
  material: null,
  title: null,
  url: null,

  title_en: null,
  url_en: null,
  file_en: null,

  title_es: null,
  url_es: null,
  file_es: null,

  title_fr: null,
  url_fr: null,
  file_fr: null,

  title_pt: null,
  url_pt: null,
  file_pt: null,
});

class DeepDiveLink extends LinkRecord {
  get id() {
    return this.get('id');
  }
}

export default DeepDiveLink;
