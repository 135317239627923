import { fromJS, List, Map } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byScenario: new Map() },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECITONS_LIST:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PROJECTIONS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            byScenario: action.payload.results.reduce(
              (obj, projection) =>
                obj.set(String(projection.scenario), new List(projection.projections)),
              state.get('data').get('byScenario')
            ),
          },
        })
      );

    case types.PROJECITONS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    case types.PROJECITON_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    default:
      return state;
  }
}
