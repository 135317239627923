import { fromJS, Map } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Mentor, { MENTOR_APP_STATUSES } from 'models/Mentor';
import types from './types';

const mentorsByStatus = MENTOR_APP_STATUSES.reduce(
  (prevObj, status) => ({
    ...prevObj,
    [status]: [],
    [`${status}Count`]: 0,
    [`${status}TotalCount`]: 0,
  }),
  {}
);

export const initialState = fromJS({
  ...reduxHelper.applyRequestInfo({
    data: {
      admin: new Map(),
      ...mentorsByStatus,
    },
    errorMessage: '',
    updatePending: false,
    loading: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROGRAM_MENTORS_LIST:
    case types.PROGRAM_MENTORS_LIST_BY_CATEGORY:
      return state.merge(
        reduxHelper.applyRequestInfo({
          isFetching: true,
        })
      );
    case types.PROGRAM_MENTORS_LIST_SUCCEEDED: {
      const { count, programMentors } = action.payload;
      let newMentors = new Map();
      Object.values(programMentors).forEach(programMentor => {
        newMentors = newMentors.set(programMentor.id, new Mentor(programMentor));
      });
      const data = state.get('data').set('admin', newMentors).set('count', count);
      return state.merge(reduxHelper.applyReceiveInfo({ data, loading: false }));
    }
    case types.PROGRAM_MENTORS_LIST_BY_CATEGORY_SUCCEEDED: {
      const { count, category, programMentors } = action.payload;
      const categoryCount = `${category}Count`;
      const categoryTotalCount = `${category}TotalCount`;
      const mentorsByCategory = fromJS(programMentors.map(project => new Mentor(project)));
      const data = state
        .get('data')
        // Setting mentors by category
        .set(category, mentorsByCategory)
        // Setting total amount of category
        .set(categoryCount, count)
        // Setting total amount of mentors
        .set(categoryTotalCount, Math.max(categoryTotalCount || 0, action.payload.count || 0));
      return state.merge(reduxHelper.applyReceiveInfo({ data, loading: false }));
    }
    case types.PROGRAM_MENTORS_LIST_FAILED:
    case types.PROGRAM_MENTORS_LIST_BY_CATEGORY_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
          loading: false,
        })
      );
    case types.PROGRAM_MENTORS_LIST_PENDING:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          loading: true,
        })
      );
    default:
      return state;
  }
}
