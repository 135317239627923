import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import { Step } from 'models/Tools';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.STEPS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, step) => ({ ...obj, [step.id]: new Step(step) }),
              {}
            ),
          },
        })
      );
    case types.STEP_FETCH_SUCCEEDED:
    case types.STEP_CREATE_SUCCEEDED:
    case types.STEP_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new Step(action.payload)),
          },
        })
      );
    case types.STEP_FETCH:
    case types.STEP_CREATE:
    case types.STEP_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.STEP_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
          },
        })
      );
    case types.STEP_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.STEP_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.STEP_FETCH_FAILED:
    case types.STEPS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
