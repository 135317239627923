import { Record } from 'immutable';
import ContentType from '../ContentType';

const ActivityRecord = new Record({
  id: null,
  contentType: ContentType.MARKETINGACTIVITY,
  name: '',
  segment: null,
  type: null,
  selected: false,
  comments: null,
  created: null,
  updated: null,
});

class Activity extends ActivityRecord {
  static contentType = ContentType.MARKETINGACTIVITY;

  static TYPE_TOFU = 0;
  static TYPE_MOFU = 1;
  static TYPE_BOFU = 2;

  get id() {
    return this.get('id');
  }
}

export default Activity;
