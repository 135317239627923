const POSITIONING_CLEAR_ERROR = 'bfb/competitionMap/positioning/POSITIONING_CLEAR_ERROR';
const POSITIONINGS_LIST = 'bfb/competitionMap/positioning/POSITIONINGS_LIST';
const POSITIONINGS_LIST_SUCCEEDED = 'bfb/competitionMap/positioning/POSITIONINGS_LIST_SUCCEEDED';
const POSITIONINGS_LIST_FAILED = 'bfb/competitionMap/positioning/POSITIONINGS_LIST_FAILED';
const POSITIONING_CREATE = 'bfb/competitionMap/positioning/POSITIONING_CREATE';
const POSITIONING_CREATE_SUCCEEDED = 'bfb/competitionMap/positioning/POSITIONING_CREATE_SUCCEEDED';
const POSITIONING_CREATE_FAILED = 'bfb/competitionMap/positioning/POSITIONING_CREATE_FAILED';
const POSITIONING_DELETE = 'bfb/competitionMap/positioning/POSITIONING_DELETE';
const POSITIONING_DELETE_SUCCEEDED = 'bfb/competitionMap/positioning/POSITIONING_DELETE_SUCCEEDED';
const POSITIONING_DELETE_FAILED = 'bfb/competitionMap/positioning/POSITIONING_DELETE_FAILED';
const POSITIONING_FETCH = 'bfb/competitionMap/positioning/POSITIONING_FETCH';
const POSITIONING_FETCH_SUCCEEDED = 'bfb/competitionMap/positioning/POSITIONING_FETCH_SUCCEEDED';
const POSITIONING_FETCH_FAILED = 'bfb/competitionMap/positioning/POSITIONING_FETCH_FAILED';
const POSITIONING_UPDATE = 'bfb/competitionMap/positioning/POSITIONING_UPDATE';
const POSITIONING_UPDATE_SUCCEEDED = 'bfb/competitionMap/positioning/POSITIONING_UPDATE_SUCCEEDED';
const POSITIONING_UPDATE_FAILED = 'bfb/competitionMap/positioning/POSITIONING_UPDATE_FAILED';

export default {
  POSITIONINGS_LIST,
  POSITIONINGS_LIST_SUCCEEDED,
  POSITIONINGS_LIST_FAILED,
  POSITIONING_CLEAR_ERROR,
  POSITIONING_CREATE,
  POSITIONING_CREATE_SUCCEEDED,
  POSITIONING_CREATE_FAILED,
  POSITIONING_DELETE,
  POSITIONING_DELETE_SUCCEEDED,
  POSITIONING_DELETE_FAILED,
  POSITIONING_FETCH,
  POSITIONING_FETCH_SUCCEEDED,
  POSITIONING_FETCH_FAILED,
  POSITIONING_UPDATE,
  POSITIONING_UPDATE_SUCCEEDED,
  POSITIONING_UPDATE_FAILED,
};
