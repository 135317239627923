import { Record } from 'immutable';
import ContentType from '../ContentType';

const GrowthRiskRecord = new Record({
  id: null,
  name: null,
  created: null,
  updated: null,
  contentType: ContentType.GROWTHRISK,
});

class GrowthRisk extends GrowthRiskRecord {
  static contentType = ContentType.GROWTHRISK;

  get id() {
    return this.get('id');
  }
}

export default GrowthRisk;
