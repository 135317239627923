import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Application from 'models/MentorApplication';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.APPLICATIONS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.reduce(
              (obj, application) => ({
                ...obj,
                [application.id]: new Application(application),
              }),
              {}
            ),
          },
        })
      );
    case types.APPLICATION_UPDATE_SUCCEEDED:
    case types.APPLICATION_FETCH_SUCCEEDED:
    case types.APPLICATION_CREATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new Application(action.payload)),
          },
        })
      );
    case types.APPLICATION_ADD_REQUESTED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.APPLICATION_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.APPLICATION_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.APPLICATIONS_LIST_FAILED:
    case types.APPLICATION_FETCH_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
