import languageService from 'services/languages';
import APIMultiSelect from 'components/elements/MultiSelect/APIMultiSelect/';
import TranslationsContext from 'providers/translationsProvider';

class LanguageMultiSelect extends APIMultiSelect {
  static contextType = TranslationsContext;

  getResults = () => languageService.listLanguages();

  getKey = language => language.id;

  getValue = language => language.id;

  getText = language => this.context.t(language.name);
}

export default LanguageMultiSelect;
