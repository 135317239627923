import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for listing ALL countries

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/countries/',
  }),
  endpoints: builder => ({
    // List ALL countries
    listCountries: builder.query({
      query: () => '',
    }),
  }),
});

export const publicCountriesApi = createApi({
  reducerPath: 'publicCountriesApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/public-countries/',
  }),
  endpoints: builder => ({
    // List ALL public countries
    listPublicCountries: builder.query({
      query: () => '',
    }),
  }),
});

export const { useListCountriesQuery } = countriesApi;
export const { useListPublicCountriesQuery } = publicCountriesApi;
