const RESULT_CLEAR_ERROR = 'bfb/RESULT/RESULT_CLEAR_ERROR';
const RESULTS_LIST = 'bfb/RESULT/RESULTS_LIST';
const RESULTS_LIST_SUCCEEDED = 'bfb/RESULT/RESULTS_LIST_SUCCEEDED';
const RESULTS_LIST_FAILED = 'bfb/RESULT/RESULTS_LIST_FAILED';
const RESULT_CREATE = 'bfb/RESULT/RESULT_CREATE';
const RESULT_CREATE_SUCCEEDED = 'bfb/RESULT/RESULT_CREATE_SUCCEEDED';
const RESULT_CREATE_FAILED = 'bfb/RESULT/RESULT_CREATE_FAILED';
const RESULT_DELETE = 'bfb/RESULT/RESULT_DELETE';
const RESULT_DELETE_SUCCEEDED = 'bfb/RESULT/RESULT_DELETE_SUCCEEDED';
const RESULT_DELETE_FAILED = 'bfb/RESULT/RESULT_DELETE_FAILED';
const RESULT_FETCH = 'bfb/RESULT/RESULT_FETCH';
const RESULT_FETCH_SUCCEEDED = 'bfb/RESULT/RESULT_FETCH_SUCCEEDED';
const RESULT_FETCH_FAILED = 'bfb/RESULT/RESULT_FETCH_FAILED';
const RESULT_UPDATE = 'bfb/RESULT/RESULT_UPDATE';
const RESULT_UPDATE_SUCCEEDED = 'bfb/RESULT/RESULT_UPDATE_SUCCEEDED';
const RESULT_UPDATE_FAILED = 'bfb/RESULT/RESULT_UPDATE_FAILED';

export default {
  RESULTS_LIST,
  RESULTS_LIST_SUCCEEDED,
  RESULTS_LIST_FAILED,
  RESULT_CLEAR_ERROR,
  RESULT_CREATE,
  RESULT_CREATE_SUCCEEDED,
  RESULT_CREATE_FAILED,
  RESULT_DELETE,
  RESULT_DELETE_SUCCEEDED,
  RESULT_DELETE_FAILED,
  RESULT_FETCH,
  RESULT_FETCH_SUCCEEDED,
  RESULT_FETCH_FAILED,
  RESULT_UPDATE,
  RESULT_UPDATE_SUCCEEDED,
  RESULT_UPDATE_FAILED,
};
