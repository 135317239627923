import { call, put, all, takeLatest } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import { actions, types } from 'store/modules/Tools/step';
import { serviceStep } from 'services/Tools';

export function* listSteps(action) {
  const { program, project } = action.payload;
  try {
    const response = yield call(serviceStep.listSteps, { program, project });
    yield put(actions.listStepsSucceeded({ results: response.data }));
  } catch (e) {
    yield put(actions.listStepsFailed({ message: e.message }));
  }
}

export function* updateStep(action) {
  const { id, project, program, ...step } = action.payload;
  try {
    const updated = yield call(serviceStep.updateStep, { id, project, program, ...step });
    yield put(actions.updateStepSucceeded(updated.data));
  } catch (e) {
    yield put(actions.updateStepFailed({ message: e.message }));
  }
}

export function* fetchStep(action) {
  const { id, ...step } = action.payload;
  try {
    const stepFetched = yield call(serviceStep.fetchStep, { id, ...step });
    yield put(actions.fetchStepSucceeded({ ...stepFetched.data }));
  } catch (e) {
    yield put(actions.fetchStepFailed({ message: e.message }));
  }
}

function* toolSaga() {
  yield all([
    takeLatest(types.STEPS_LIST, listSteps),
    takeLatest(types.STEP_UPDATE, updateStep),
    takeLatest(types.STEP_FETCH, fetchStep),
  ]);
}

export default toolSaga;
