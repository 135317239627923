import { createSelector } from 'reselect';

const getState = state => state.businessModel;

const getRawBusinessModels = createSelector([getState], state => state.get('data'));

const getBusinessModelsById = createSelector([getRawBusinessModels], state => state.get('byId'));

const getBusinessModelsByProduct = createSelector([getRawBusinessModels], state =>
  state.get('byProduct')
);

const getBusinessModels = createSelector([getBusinessModelsById], state => state.valueSeq().toJS());

const getProductBusinessModels = createSelector([getBusinessModelsByProduct], state =>
  state.toJS()
);

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const getProductBusinessModelByModelId = (businessModelsByProduct = [], id) => {
  if (!businessModelsByProduct) return undefined;
  const productBusinessModel = businessModelsByProduct.find(
    productModel => productModel.business_model === id
  );
  return productBusinessModel;
};

export default {
  getBusinessModelsByProduct,
  getBusinessModelsById,
  getBusinessModels,
  getProductBusinessModels,
  getErrorMessage,
  getProductBusinessModelByModelId,
};
