import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import ProjectSdg from 'models/ImpactPlan/ProjectSdg';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_SDGS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (projectSdgsById, projectSdg) =>
                projectSdgsById.set(String(projectSdg.id), new ProjectSdg(projectSdg)),
              state.get('data').get('byId')
            ),
          },
        })
      );
    case types.PROJECT_SDG_FETCH_SUCCEEDED:
    case types.PROJECT_SDG_CREATE_SUCCEEDED:
    case types.PROJECT_SDG_UPDATE_SUCCEEDED:
      return (() =>
        state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(String(action.payload.id), new ProjectSdg(action.payload)),
            },
          })
        ))();
    case types.PROJECT_SDG_FETCH:
    case types.PROJECT_SDG_CREATE:
    case types.PROJECT_SDG_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PROJECT_SDG_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(String(action.payload.id)),
          },
        })
      );
    case types.PROJECT_SDG_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.PROJECT_SDG_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.PROJECT_SDG_FETCH_FAILED:
    case types.PROJECT_SDGS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
