import { createSelector } from 'reselect';

const getState = state => state.pillsCategories;

const getPillsCategories = createSelector([getState], state => state.data);

const getPillsCategoriesList = createSelector([getPillsCategories], state => state.list);

const getPillsCategoriesByStatus = createSelector([getPillsCategories], state => state.byStatus);

export default {
  getPillsCategories,
  getPillsCategoriesByStatus,
  getPillsCategoriesList,
};
