import api from './api';

const editMentor = mentorData =>
  api.patch(`/api/mentors/${mentorData.id}/`, {
    ...mentorData,
  });

const getMentor = mentorData =>
  api.get(`/api/mentors/${mentorData.id}/`, {
    ...mentorData,
  });

const getPublicMentor = mentorData =>
  api.get(`/api/public_mentors/${mentorData.id}/`, {
    ...mentorData,
  });

export default {
  editMentor,
  getMentor,
  getPublicMentor,
};
