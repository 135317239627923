import { Record } from 'immutable';
import ContentType from '../ContentType';

const OrganizationalRoleRecord = new Record({
  id: null,
  comments: [],
  name: null,
  created: null,
  updated: null,
  initiatives: [],
  product: null,
  contentType: ContentType.PARTNER,
});

class OrganizationalRole extends OrganizationalRoleRecord {
  static contentType = ContentType.PARTNER;

  get id() {
    return this.get('id');
  }
}

export default OrganizationalRole;
