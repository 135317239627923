import * as reduxHelper from 'store/utils/reduxHelper';
import Link from 'models/Tools/DeepDiveLink';
import types from './types';
import { byId } from 'store/utils/sortFunctions';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byMaterial: {} },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.DEEP_DIVE_LINKS_LIST:
    case types.DEEP_DIVE_LINK_CREATE:
    case types.DEEP_DIVE_LINK_UPDATE:
    case types.DEEP_DIVE_LINK_DELETE:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.DEEP_DIVE_LINKS_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, link) => ({
                ...obj,
                [link.id]: new Link(link),
              }),
              {}
            ),
            byMaterial: action.payload.results.reduce((obj, link) => {
              const materialLinks = obj[link.material] || [];
              materialLinks.push(new Link(link));
              return {
                ...obj,
                [link.material]: materialLinks.sort(byId),
              };
            }, {}),
          },
        })
      );
    case types.DEEP_DIVE_LINK_CREATE_SUCCEEDED:
    case types.DEEP_DIVE_LINK_UPDATE_SUCCEEDED: {
      const link = action.payload;
      const linksById = { ...state.data.byId, [link.id]: new Link(link) };

      // Get previous links for material and remove current link
      const prevMaterialLinks = (state.data.byMaterial[link.material] || []).filter(
        l => l.id !== link.id
      );

      // Add new link to byMaterial
      const byMaterial = {
        ...state.data.byMaterial,
        [link.material]: [...prevMaterialLinks, new Link(link)].sort(byId),
      };

      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            byId: linksById,
            byMaterial,
          },
        })
      );
    }
    case types.DEEP_DIVE_LINK_DELETE_SUCCEEDED: {
      const link = action.payload;
      // Remove link by destructuring the obj:
      // source: https://stackoverflow.com/questions/34401098/remove-a-property-in-an-object-immutably
      const { [link.id]: removed, ...linksById } = state.data.byId;

      // Get previous links for material and remove current link
      const materialLinks = (state.data.byMaterial[link.material] || []).filter(
        l => l.id !== link.id
      );

      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            byId: linksById,
            byMaterial: {
              ...state.data.byMaterial,
              [link.material]: materialLinks,
            },
          },
        })
      );
    }
    case types.DEEP_DIVE_LINKS_LIST_FAILED:
    case types.DEEP_DIVE_LINK_CREATE_FAILED:
    case types.DEEP_DIVE_LINK_UPDATE_FAILED:
    case types.DEEP_DIVE_LINK_DELETE_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
