import { createSelector } from 'reselect';

const getState = state => state.activity;

const getRawActivities = createSelector([getState], state => state.get('data'));

const getActivitiesById = createSelector([getRawActivities], state => state.get('byId'));

const getActivitiesBySegment = createSelector([getRawActivities], state => state.get('bySegment'));

const getActivities = createSelector([getActivitiesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getActivities,
  getActivitiesBySegment,
  getActivitiesById,
  getErrorMessage,
};
