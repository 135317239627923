import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Assets
import { ReactComponent as emptyComment } from 'static/icons/07-in-tool/07-1-icons/icon_comment-empty.svg';
import { ReactComponent as filledComment } from 'static/icons/07-in-tool/07-1-icons/icon_comment-active.svg';
import { ReactComponent as infoIcon } from 'static/icons/00-general/00-1-icons/icon_question-mark-active.svg';

// Components
import Icon from '@ant-design/icons';
import queryString from 'utils/queryString';

function RawToolCommentIcon(props) {
  const { active, onClick, ...rest } = props;

  const getEmbed = () => {
    const queryParams = queryString.parse(window.location.search);
    return queryParams.embed;
  };

  const isEmbed = getEmbed();

  const getIconComponent = () => {
    if (isEmbed) {
      return active ? infoIcon : null;
    }
    return active ? filledComment : emptyComment;
  };
  return (
    <Icon
      component={getIconComponent()}
      onClick={!isEmbed && onClick}
      onMouseEnter={isEmbed && onClick}
      {...rest}
    />
  );
}

RawToolCommentIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

RawToolCommentIcon.defaultProps = {
  active: false,
};

const ToolCommentIcon = styled(RawToolCommentIcon)`
  &&& {
    color: ${({ theme }) => theme.greyColor};
    font-size: 18px;
    transition: all 0.3s ease;
    &.active,
    &:hover {
      color: ${({ theme }) => theme.secondaryColor};
    }
    ${({ active, theme }) =>
      active &&
      css`
        color: ${theme.secondaryColor};
      `};
  }
`;

export default ToolCommentIcon;
