import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import projectService from 'services/project';
import Project from 'models/Project';
import { types as commentTypes } from 'store/modules/comment';

import types from './types';
import actions from './actions';

export function* listProjects(action) {
  yield put(actions.listProjectsPending());
  try {
    if (action.payload && action.payload.explore) {
      const { program, explore, offset, ordering, limit, ...query } = action.payload;
      const projects = yield call(projectService.listExploreProjects, {
        program,
        offset,
        ordering,
        limit,
        ...query,
      });
      yield put(actions.listProjectsSucceeded({ ...projects.data, explore }));
    } else if (action.payload && action.payload.organization && action.payload.program) {
      const { admin, organization, program, ...query } = action.payload;
      const projects = yield call(projectService.listOrganizationProjects, {
        organization,
        program,
        ...query,
      });
      const projectStatus = query.project_status;
      yield put(
        actions.listProjectsSucceeded({
          admin,
          projectStatus,
          ...projects.data,
        })
      );
    } else {
      const projects = yield call(projectService.listProjects);
      yield put(actions.listProjectsSucceeded(projects.data));
    }
  } catch (e) {
    yield put(actions.listProjectsFailed({ message: e.message }));
  }
}

export function* fetchProject(action) {
  const { id } = action.payload;
  try {
    const project = yield call(projectService.fetchProject, { id });
    yield put(actions.fetchProjectSucceeded(project.data));
  } catch (e) {
    yield put(actions.fetchProjectFailed({ message: e.message }));
  }
}

export function* currentProject(action) {
  yield put(action.payload);
}

export function* updateProject(action) {
  const { id, ...project } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(projectService.updateProject, {
      id,
      ...project,
    });
    yield put(actions.updateProjectSucceeded(updated.data));
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateProjectFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchSelectedProject(action) {
  const { id } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(projectService.fetchProject, { id });
    yield put(actions.fetchSelectedProjectSucceeded(response.data));
    if (callback) yield callback();
  } catch (e) {
    yield put(actions.fetchSelectedProjectFailed({ message: e.message }));
    if (callback) yield callback(e.message);
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Project.contentType) {
    yield put(
      actions.fetchProject({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.SELECTED_PROJECT_FETCH, fetchSelectedProject),
    takeLatest(types.PROJECT_FETCH, fetchProject),
    takeEvery(types.PROJECTS_LIST, listProjects),
    takeLatest(types.BREADCRUMB_PROJECTS_LIST, listProjects),
    takeLatest(types.PROJECT_UPDATE, updateProject),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
    currentProject,
  ]);
}

export default authSaga;
