import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { types as commentTypes } from 'store/modules/comment';

import { actions, types } from 'store/modules/StakeholderMap/partner';
import servicePartner from 'services/StakeholderMap/partner';
import Partner from 'models/StakeholderMap/Partner';

export function* createPartner(action) {
  const { project, product, ...partner } = action.payload;
  try {
    const response = yield call(servicePartner.createPartner, {
      project,
      product,
      ...partner,
    });
    yield put(actions.createPartnerSucceeded({ product, ...response.data }));
  } catch (e) {
    yield put(
      actions.createPartnerFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deletePartner(action) {
  const { project, product, partnerId } = action.payload;
  try {
    yield call(servicePartner.deletePartner, { project, product, partnerId });
    yield put(actions.deletePartnerSucceeded({ id: partnerId, product }));
  } catch (e) {
    yield put(
      actions.deletePartnerFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchPartner(action) {
  const { project, product, partnerId } = action.payload;
  try {
    const response = yield call(servicePartner.fetchPartner, {
      project,
      product,
      partnerId,
    });
    yield put(actions.fetchPartnerSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(actions.fetchPartnerFailed({ message: e.message }));
  }
}

export function* listPartners(action) {
  const { project, product } = action.payload;
  try {
    const partners = product
      ? // Partners by product
        yield call(servicePartner.listPartners, { project, product })
      : // Partners of type team in project
        yield call(servicePartner.listOrganizationalRoles, { project });
    yield put(actions.listPartnersSucceeded({ results: partners.data, product }));
  } catch (e) {
    yield put(actions.listPartnersFailed({ message: e.message }));
  }
}

export function* updatePartner(action) {
  const { project, product, partnerId, ...partner } = action.payload;
  try {
    const updated = product
      ? yield call(servicePartner.updatePartner, {
          project,
          product,
          partnerId,
          ...partner,
        })
      : yield call(servicePartner.updateOrganizationalRole, {
          partnerId,
          ...partner,
        });
    yield put(actions.updatePartnerSucceeded({ ...updated.data, product }));
  } catch (e) {
    yield put(
      actions.updatePartnerFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Partner.contentType) {
    yield put(
      actions.fetchPartner({
        project: object.get('project'),
        product: object.get('product'),
        partnerId: object.get('id'),
      })
    );
  }
}

function* partnerSaga() {
  yield all([
    takeLatest(types.PARTNER_CREATE, createPartner),
    takeLatest(types.PARTNER_DELETE, deletePartner),
    takeLatest(types.PARTNER_FETCH, fetchPartner),
    takeLatest(types.PARTNER_UPDATE, updatePartner),
    takeEvery(types.PARTNERS_LIST, listPartners),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default partnerSaga;
