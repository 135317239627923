import { Record } from 'immutable';

const SectorRecord = Record({
  id: null,
  name: '',
  area_innovation: null,
});

class Sector extends SectorRecord {
  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get areaInnovation() {
    return this.get('area_innovation');
  }
}

export default Sector;
