import types from './types';

const clearError = () => ({
  type: types.FIXED_ASSET_CLEAR_ERROR,
});

const createFixedAsset = ({ project, scenario, ...fixedAsset }) => ({
  type: types.FIXED_ASSET_CREATE,
  payload: { project, scenario, ...fixedAsset },
});

const createFixedAssetSucceeded = ({ scenario, id, ...fixedAsset }) => ({
  type: types.FIXED_ASSET_CREATE_SUCCEEDED,
  payload: { scenario, id, ...fixedAsset },
});

const createFixedAssetFailed = ({ message }) => ({
  type: types.FIXED_ASSET_CREATE_FAILED,
  payload: { message },
});

const deleteFixedAsset = ({ project, scenario, id }) => ({
  type: types.FIXED_ASSET_DELETE,
  payload: { project, scenario, id },
});

const deleteFixedAssetSucceeded = ({ scenario, id }) => ({
  type: types.FIXED_ASSET_DELETE_SUCCEEDED,
  payload: { scenario, id },
});

const deleteFixedAssetFailed = ({ message }) => ({
  type: types.FIXED_ASSET_DELETE_FAILED,
  payload: message,
});

const fetchFixedAsset = ({ project, scenario, id }) => ({
  type: types.FIXED_ASSET_FETCH,
  payload: { project, scenario, id },
});

const fetchFixedAssetSucceeded = ({ ...fixedAsset }) => ({
  type: types.FIXED_ASSET_FETCH_SUCCEEDED,
  payload: { ...fixedAsset },
});

const fetchFixedAssetFailed = ({ message }) => ({
  type: types.FIXED_ASSET_FETCH_FAILED,
  payload: { message },
});

const listFixedAssets = ({ project, scenario }) => ({
  type: types.FIXED_ASSETS_LIST,
  payload: { project, scenario },
});

const listFixedAssetsSucceeded = payload => ({
  type: types.FIXED_ASSETS_LIST_SUCCEEDED,
  payload,
});

const listFixedAssetsFailed = ({ message }) => ({
  type: types.FIXED_ASSETS_LIST_FAILED,
  payload: { message },
});

const updateFixedAsset = ({ project, scenario, id, ...updatedFixedAsset }) => ({
  type: types.FIXED_ASSET_UPDATE,
  payload: { project, scenario, id, ...updatedFixedAsset },
});

const updateFixedAssetSucceeded = ({ ...fixedAsset }) => ({
  type: types.FIXED_ASSET_UPDATE_SUCCEEDED,
  payload: { ...fixedAsset },
});

const updateFixedAssetFailed = ({ message }) => ({
  type: types.FIXED_ASSET_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createFixedAsset,
  createFixedAssetFailed,
  createFixedAssetSucceeded,
  deleteFixedAsset,
  deleteFixedAssetFailed,
  deleteFixedAssetSucceeded,
  fetchFixedAsset,
  fetchFixedAssetFailed,
  fetchFixedAssetSucceeded,
  listFixedAssets,
  listFixedAssetsFailed,
  listFixedAssetsSucceeded,
  updateFixedAsset,
  updateFixedAssetFailed,
  updateFixedAssetSucceeded,
};
