import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Funding from 'models/FinancialProjections/Funding';
import serviceFunding from 'services/FinancialProjections/funding';
import types from './types';
import actions from './actions';

export function* createFunding(action) {
  const { scenario, ...funding } = action.payload;
  try {
    const response = yield call(serviceFunding.createFunding, {
      scenario,
      ...funding,
    });
    yield put(actions.createFundingSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(
      actions.createFundingFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteFunding(action) {
  const { scenario, id } = action.payload;
  try {
    yield call(serviceFunding.deleteFunding, { id });
    yield put(actions.deleteFundingSucceeded({ id, scenario }));
  } catch (e) {
    yield put(
      actions.deleteFundingFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchFunding(action) {
  const { scenario, id } = action.payload;
  try {
    const response = yield call(serviceFunding.fetchFunding, { scenario, id });
    yield put(actions.fetchFundingSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(actions.fetchFundingFailed({ message: e.message }));
  }
}

export function* listFundings(action) {
  const { scenario } = action.payload;
  try {
    const fundings = yield call(serviceFunding.listFundings, { scenario });
    yield put(actions.listFundingsSucceeded({ results: fundings.data, scenario }));
  } catch (e) {
    yield put(actions.listFundingsFailed({ message: e.message }));
  }
}

export function* updateFunding(action) {
  const { scenario, id, ...updatedFunding } = action.payload;
  try {
    const updated = yield call(serviceFunding.updateFunding, {
      scenario,
      id,
      ...updatedFunding,
    });
    yield put(actions.updateFundingSucceeded({ ...updated.data, scenario }));
  } catch (e) {
    yield put(
      actions.updateFundingFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Funding.contentType) {
    yield put(
      actions.fetchFunding({
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* fundingSaga() {
  yield all([
    takeLatest(types.FUNDING_CREATE, createFunding),
    takeLatest(types.FUNDING_DELETE, deleteFunding),
    takeLatest(types.FUNDING_FETCH, fetchFunding),
    takeLatest(types.FUNDING_UPDATE, updateFunding),
    takeEvery(types.FUNDINGS_LIST, listFundings),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default fundingSaga;
