import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// Parrobot Chat fetching and sending messages. Integration with AI assistant.
export const parrobotChatApi = createApi({
  reducerPath: 'parrobotChatApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/parrobot-chat/messages/',
  }),
  endpoints: builder => ({
    // Fetch messages from parrobot chat
    getMessages: builder.query({
      query: ({ tool, project, limit, offset }) => ({
        params: { tool, project, limit, offset },
      }),
    }),
    // Send a new message to the parrobot assistant
    sendMessage: builder.mutation({
      query: ({ project, tool, step, text }) => ({
        method: 'POST',
        data: { project, tool, step, text },
      }),
    }),
  }),
});

export const { useGetMessagesQuery, useSendMessageMutation } = parrobotChatApi;
