const ORGANIZATION_FETCH = 'bfb/organization/ORGANIZATION_FETCH';
const ORGANIZATION_FETCH_SUCCEEDED = 'bfb/organization/ORGANIZATION_FETCH_SUCCEEDED';
const ORGANIZATION_FETCH_FAILED = 'bfb/organization/ORGANIZATION_FETCH_FAILED';
const ORGANIZATIONS_LIST = 'bfb/organization/ORGANIZATIONS_LIST';
const ORGANIZATIONS_LIST_SUCCEEDED = 'bfb/organization/ORGANIZATIONS_LIST_SUCCEEDED';
const ORGANIZATIONS_LIST_FAILED = 'bfb/organization/ORGANIZATIONS_LIST_FAILED';
const ORGANIZATION_UPDATE = 'bfb/organization/ORGANIZATION_UPDATE';
const ORGANIZATION_UPDATE_SUCCEEDED = 'bfb/organization/ORGANIZATION_UPDATE_SUCCEEDED';
const ORGANIZATION_UPDATE_FAILED = 'bfb/organization/ORGANIZATION_UPDATE_FAILED';

export default {
  ORGANIZATION_FETCH,
  ORGANIZATION_FETCH_SUCCEEDED,
  ORGANIZATION_FETCH_FAILED,
  ORGANIZATIONS_LIST,
  ORGANIZATIONS_LIST_SUCCEEDED,
  ORGANIZATIONS_LIST_FAILED,
  ORGANIZATION_UPDATE,
  ORGANIZATION_UPDATE_SUCCEEDED,
  ORGANIZATION_UPDATE_FAILED,
};
