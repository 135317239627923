import types from './types';

const clearError = () => ({
  type: types.PAYROLL_CLEAR_ERROR,
});

const createPayroll = ({ scenario, ...payroll }, callback) => ({
  type: types.PAYROLL_CREATE,
  payload: { scenario, ...payroll },
  callback,
});

const createPayrollSucceeded = ({ scenario, id, ...payroll }) => ({
  type: types.PAYROLL_CREATE_SUCCEEDED,
  payload: { scenario, id, ...payroll },
});

const createPayrollFailed = ({ message }) => ({
  type: types.PAYROLL_CREATE_FAILED,
  payload: { message },
});

const deletePayroll = ({ scenario, payrollId }) => ({
  type: types.PAYROLL_DELETE,
  payload: { scenario, payrollId },
});

const deletePayrollSucceeded = ({ scenario, payrollId }) => ({
  type: types.PAYROLL_DELETE_SUCCEEDED,
  payload: { scenario, id: payrollId },
});

const deletePayrollFailed = ({ message }) => ({
  type: types.PAYROLL_DELETE_FAILED,
  payload: message,
});

const fetchPayroll = ({ scenario, id }) => ({
  type: types.PAYROLL_FETCH,
  payload: { scenario, id },
});

const fetchPayrollSucceeded = ({ ...payroll }) => ({
  type: types.PAYROLL_FETCH_SUCCEEDED,
  payload: { ...payroll },
});

const fetchPayrollFailed = ({ message }) => ({
  type: types.PAYROLL_FETCH_FAILED,
  payload: { message },
});

const listPayrolls = ({ scenario }) => ({
  type: types.PAYROLLS_LIST,
  payload: { scenario },
});

const listPayrollsSucceeded = payload => ({
  type: types.PAYROLLS_LIST_SUCCEEDED,
  payload,
});

const listPayrollsFailed = ({ message }) => ({
  type: types.PAYROLLS_LIST_FAILED,
  payload: { message },
});

const updatePayroll = ({ scenario, payrollId, ...updatedPayroll }, callback) => ({
  type: types.PAYROLL_UPDATE,
  payload: { scenario, payrollId, ...updatedPayroll },
  callback,
});

const updatePayrollSucceeded = ({ ...payroll }) => ({
  type: types.PAYROLL_UPDATE_SUCCEEDED,
  payload: { ...payroll },
});

const updatePayrollFailed = ({ message }) => ({
  type: types.PAYROLL_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createPayroll,
  createPayrollFailed,
  createPayrollSucceeded,
  deletePayroll,
  deletePayrollFailed,
  deletePayrollSucceeded,
  fetchPayroll,
  fetchPayrollFailed,
  fetchPayrollSucceeded,
  listPayrolls,
  listPayrollsFailed,
  listPayrollsSucceeded,
  updatePayroll,
  updatePayrollFailed,
  updatePayrollSucceeded,
};
