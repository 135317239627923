import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { count: 0, results: [] },
    detail: {},
    loading: false,
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MEMBER_EMAIL_DASHBOARD_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            results: { ...action.payload.results },
            count: action.payload.count,
          },
          loading: false,
        })
      );
    case types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          detail: action.payload,
          loading: false,
        })
      );
    case types.MEMBER_EMAIL_DASHBOARD_LIST_FAILED:
    case types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_FAILED:
    case types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_PENDING:
    case types.MEMBER_EMAIL_DASHBOARD_LIST_PENDING:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          loading: true,
        })
      );
    default:
      return state;
  }
}
