window.displayWarningOnClose = false;

export const removeOnCloseWarning = () => {
  window.displayWarningOnClose = false;
};

export const addOnCloseWarning = e => {
  const inputs = ['SELECT', 'INPUT', 'TEXTAREA'];
  if (inputs.indexOf(e.target.tagName) !== -1) {
    window.displayWarningOnClose = true;
  }
};

export const initBeforeUnload = () =>
  window.addEventListener('beforeunload', windowEvent => {
    window.focus();
    if (window.displayWarningOnClose) {
      const event = windowEvent || window.event;
      if (event) {
        event.returnValue = 'u sure?';
      }
      // For Safari
      return 'u suri?';
    }
    return null;
  });

window.onfocus = () => {
  /*
    When we are back to the window (from closing warning) we remove the warning and put the focus
    out of the input
  */
  setTimeout(() => {
    removeOnCloseWarning();
    window.focus();
  }, 1);
};
