import { Record } from 'immutable';
import ContentType from '../ContentType';

const STAGE_IDEA = 0;
const STAGE_CONCEPT = 1;
const STAGE_PROJECT = 2;
const STAGE_VALIDATION = 3;
const STAGE_GROWTH = 4;
const STAGE_SCALE_UP = 5;

const ExecutiveSummaryRecord = Record({
  id: null,
  project: '',
  tagline: null,
  venture_name: '',
  venture_logo: null,
  cover_photo: null,
  business_address: null,
  contact_email: '',
  company_website: '',
  linkedin: null,
  facebook: null,
  twitter: null,
  instagram: null,
  members: [
    {
      id: null,
      name: '',
      photo: null,
      position_title: '',
      description: '',
      created: new Date(),
      updated: new Date(),
    },
  ],
  achievement_1: null,
  achievement_2: null,
  achievement_3: null,
  achievement_4: null,
  entity_type: null,
  entity_type_description: null,
  entity_type_selected: null,
  hide_achievements: false,
  unit_economics: null,
  unit_economics_alt_title: null,
  company_stage: null,
  company_stage_selected: true,
  year_founded: null,
  year_founded_selected: true,
  number_of_employees: 0,
  number_of_employees_selected: false,
  number_of_volunteers: null,
  number_of_volunteers_selected: false,
  company_valuation: null,
  company_valuation_selected: false,
  investment_to_date: 0,
  investment_to_date_selected: false,
  contentType: ContentType.EXECUTIVESUMMARY,
  business_type: null,
  business_type_selected: false,
  business_model_definition: null,
  business_model_definition_other: null,
  business_model_definition_selected: false,
  revenue_to_date: null,
  revenue_to_date_selected: false,
  market_size_definition: null,
  market_size_definition_selected: false,
  other_aspect_1: null,
  other_aspect_1_title: null,
  other_aspect_1_selected: false,
  other_aspect_2: null,
  other_aspect_2_title: null,
  other_aspect_2_selected: false,
  other_aspect_3: null,
  other_aspect_3_title: null,
  other_aspect_3_selected: false,
  problem_statement: null,
  problem_statement_alt_title: null,
  problem_statement_image: null,
  solution: null,
  solution_alt_title: null,
  solution_image: null,
  asking_investment: false,
  asking_investment_amount: 0,
  asking_grant: false,
  asking_grant_amount: null,
  asking_smart_money: false,
  asking_mentoring: false,
  asking_board_member: false,
  asking_co_founder: false,
  asking_business_partner: false,
  asking_client_introductions: false,
  asking_consulting: false,
  asking_legal_support: false,
  asking_accounting_support: false,
  asking_visibility: false,
  asking_acceleration_program: false,
  asking_other: null,
  asking_other_text: null,
  asking_reasons: null,
  market_opportunity: null,
  market_opportunity_alt_title: null,
  market_opportunity_image: null,
  competitive_landscape: null,
  competitive_landscape_alt_title: null,
  competitive_landscape_image: null,
  business_models: null,
  business_models_alt_title: null,
  business_models_image: null,
  projections_year_1: null,
  projections_aspect_1: null,
  projections_aspect_1_values: [],
  projections_aspect_2: null,
  projections_aspect_2_values: [],
  projections_aspect_3: null,
  projections_aspect_3_values: [],
  projections_aspect_4: null,
  projections_aspect_4_values: [],
  projections_aspect_5: null,
  projections_aspect_5_values: [],
  projections_aspect_6: null,
  projections_aspect_6_values: [],
  projections_aspect_7: null,
  projections_aspect_7_values: [],
  projections_aspect_8: null,
  projections_aspect_8_values: [],
  projections_aspect_9: null,
  projections_aspect_9_values: [],
  theory_of_change: null,
  theory_of_change_alt_title: null,
  theory_of_change_sdg_1: null,
  theory_of_change_sdg_2: null,
  growth_plan: null,
  growth_plan_alt_title: null,
  growth_plan_milestone_1: null,
  growth_plan_milestone_2: null,
  growth_plan_milestone_3: null,
  growth_plan_milestone_4: null,
  growth_plan_milestone_5: null,
  growth_plan_milestone_6: null,
  color: '',
  comments: [],
  created: new Date(),
  updated: new Date(),
  venture_basics_status: null,
  our_founding_team_status: null,
  venture_status_status: null,
  problem_statement_status: null,
  solution_status: null,
  we_are_asking_for_status: null,
  market_opportunity_status: null,
  competitive_landscape_status: null,
  business_models_and_marketing_strategy_status: null,
  unit_economics_financial_forecasting_status: null,
  venture_projections_status: null,
  theory_of_change_status: null,
  growth_plan_status: null,
});

class ExecutiveSummary extends ExecutiveSummaryRecord {
  static STAGE_IDEA = STAGE_IDEA;
  static STAGE_CONCEPT = STAGE_CONCEPT;
  static STAGE_PROJECT = STAGE_PROJECT;
  static STAGE_VALIDATION = STAGE_VALIDATION;
  static STAGE_GROWTH = STAGE_GROWTH;
  static STAGE_SCALE_UP = STAGE_SCALE_UP;
}

export default ExecutiveSummary;
