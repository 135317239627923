// Libraries
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ExclamationCircleFilled } from '@ant-design/icons';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import isFloat from 'utils/isFloat';
import isInteger from 'utils/isInteger';

// Components
import { notification } from 'antd';
import Form from 'components/elements/Form';
import { ToolInputContainer, ToolInput } from 'components/Tools';

const INTEGER_ERROR_MESSAGE = 'You can only enter numbers';
const FLOAT_ERROR_MESSAGE = 'You can only enter numbers or "."';

const UncontrolledToolNumericInput = props => {
  const {
    autoSize,
    disabled,
    form,
    height,
    initialValue,
    name,
    onBlur,
    placeholder,
    rules,
    textAreaMinHeight,
    empty,
    shouldCheckInteger,
    ...rest
  } = props;

  const valueValidityHandler = shouldCheckInteger ? isInteger : isFloat;

  const [error, setError] = useState(false);
  const { t } = useContext(TranslationsContext);

  const validateNumericInputValue = event => {
    const { value } = event.target;
    if (value) {
      const isValid = valueValidityHandler(value);
      setError(!isValid);
      // If there is no value, but there is an error, close the notification.
      // But the red border-bottom warning will remain!
    } else if (error) {
      notification.destroy(name);
    }
  };

  useEffect(() => {
    if (error) {
      // Closing then reopening to "refresh" the duration
      // No extra animations are executed in the browser.
      notification.destroy(name);
      notification.error({
        message: <ErrorNotificationTitle>{t('Oops!')}</ErrorNotificationTitle>,
        description: t(shouldCheckInteger ? INTEGER_ERROR_MESSAGE : FLOAT_ERROR_MESSAGE),
        key: name,
        duration: 20,
        className: 'the-most-amazing-error-notification',
        icon: <StyledIcon />,
      });
      // Close the notification if it's valid.
    } else {
      notification.destroy(name);
    }
    // And also close the notification if the input unmounts.
    return () => {
      if (error) notification.destroy(name);
    };
  }, [error, t, name, shouldCheckInteger]);

  return (
    <ToolInputContainer
      empty={empty}
      height={height}
      textAreaMinHeight={textAreaMinHeight}
      {...rest}
    >
      <StyledFormItem initialValue={initialValue} name={name}>
        <ToolInput.TextArea
          className={error ? 'uncontrolled-input-error' : null}
          autoSize={autoSize}
          disabled={disabled}
          noborder
          placeholder={placeholder}
          onBlur={onBlur}
          onEnter={onBlur}
          usebackground
          onChange={validateNumericInputValue}
          autoComplete="off"
          autoCorrect="off"
        />
      </StyledFormItem>
    </ToolInputContainer>
  );
};

UncontrolledToolNumericInput.propTypes = {
  autoSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  disabled: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialValue: PropTypes.node,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  rules: PropTypes.instanceOf(Array),
  textAreaMinHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldNotDecorate: PropTypes.bool,
  empty: PropTypes.bool,
  shouldCheckInteger: PropTypes.bool,
  form: PropTypes.instanceOf(Object).isRequired,
};

UncontrolledToolNumericInput.defaultProps = {
  autoSize: true,
  disabled: false,
  height: undefined,
  initialValue: null,
  onBlur: null,
  placeholder: '',
  rules: [],
  textAreaMinHeight: undefined,
  shouldNotDecorate: false,
  empty: false,
  shouldCheckInteger: false,
};

const StyledFormItem = styled(Form.Item)`
  &&& {
    margin-bottom: 0;
    .ant-input {
      transition: all ease 300ms;
      box-shadow: 0px 1px transparent;
    }
    .uncontrolled-input-error {
      color: #f5222d;
      box-shadow: 0px 1px #f5222d;
      border-radius: 0;
    }
  }
`;

const ErrorNotificationTitle = styled.b`
  color: #f5222d;
`;

const StyledIcon = styled(ExclamationCircleFilled)`
  color: #f5222d;
  &,
  & svg {
    width: 21px;
    height: 21px;
  }
`;

export default UncontrolledToolNumericInput;
