// Libraries
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Dependencies
import {
  ABOUT_YOU,
  INPUT_BIRTHDAY,
  INPUT_PRODUCTS,
  VENTURE_BASICS,
} from 'containers/MemberApplication/constants';
import { entrepreneurApplicationApi } from 'services/apiSlices/applications/entrepreneurApplicationApi';

dayjs.extend(utc);

const initialState = {
  id: null,
  isLoading: true,
  isUpdating: false,
  data: {
    about_you: {
      first_name: null,
      last_name: null,
      gender: null,
      birthday: null,
      origin: null,
      country: null,
      city: null,
      languages: [],
      phone_number: null,
      biography: null,
      linkedin: null,
      has_regular_access_to_computer: null,
      can_commit_several_hours: null,
    },
    venture_basics: {
      language: null,
      name: null,
      products_names: [],
      area_innovation: null,
      summary: null,
      stages: [],
      website: null,
    },
    venture_details: {
      mission: null,
      competitive_advantage: null,
      business_model: null,
      milestones: null,
      motivation: null,
      expertise_needed: [],
      social_impact: null,
      video_url: null,
    },
    demographics: {
      demo_income: null,
      applied_to_other_programs: null,
    },
    final_checklist: {
      participate_in_another_program_if_rejected: null,
      team: null,
      team_description: null,
      referrer: null,
      recommended_program_referrer: null,
      additional_answer: null,
      additional_answer_1: null,
      additional_answer_2: null,
      additional_answer_3: null,
      additional_answer_4: null,
      additional_answer_5: null,
      additional_answer_6: null,
      additional_answer_7: null,
      refugee_document_check: null,
    },
  },
};

const applicationEntrepreneurSlice = createSlice({
  name: 'applicationEntrepreneur',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(
        entrepreneurApplicationApi.endpoints.getApplicationDraftById.matchFulfilled,
        (state, action) => {
          const { payload: entrepreneurApplication } = action;

          // Transform birthday string into a date object
          const birthdayDayjs = entrepreneurApplication[ABOUT_YOU][INPUT_BIRTHDAY]
            ? dayjs.utc(entrepreneurApplication[ABOUT_YOU][INPUT_BIRTHDAY])
            : null;

          const productNames = entrepreneurApplication[VENTURE_BASICS][INPUT_PRODUCTS];
          const products = productNames && productNames.length > 0 ? productNames : [null]; // If user has not added any products yet, render an empty product input

          const aboutYou = {
            [ABOUT_YOU]: { ...entrepreneurApplication[ABOUT_YOU], [INPUT_BIRTHDAY]: birthdayDayjs },
          };
          const ventureBasics = {
            [VENTURE_BASICS]: {
              ...entrepreneurApplication[VENTURE_BASICS],
              [INPUT_PRODUCTS]: products,
            },
          };

          const formattedApplication = {
            ...entrepreneurApplication,
            ...aboutYou,
            ...ventureBasics,
          };

          // Load fetched data into the state
          state.data = formattedApplication;
          state.isLoading = false;
          state.id = action.meta.arg.originalArgs;
        }
      )
      .addMatcher(entrepreneurApplicationApi.endpoints.updateApplication.matchPending, state => {
        state.isUpdating = true;
      })
      .addMatcher(
        entrepreneurApplicationApi.endpoints.updateApplication.matchFulfilled,
        (state, action) => {
          // Sync state with all values from form when backend updated
          state.data = action.meta.arg.originalArgs.allValues;
          state.isUpdating = false;
        }
      )
      .addMatcher(entrepreneurApplicationApi.endpoints.updateApplication.matchRejected, state => {
        state.isUpdating = false;
      });
  },
});

export const applicationEntrepreneurReducer = applicationEntrepreneurSlice.reducer;
