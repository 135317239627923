import { call, put, all, takeLatest } from 'redux-saga/effects';
import programStatsService from 'services/programStats';
import types from './types';
import actions from './actions';

export function* listMemberProgramStats(action) {
  try {
    const stats = yield call(programStatsService.listMemberProgramStats, action.payload);
    yield put(
      actions.listMemberProgramStatsSucceeded({
        statsData: stats.data,
      })
    );
  } catch (e) {
    yield put(actions.listMemberProgramStatsFailed({ message: e.message }));
  }
}

export function* listMentorProgramStats(action) {
  try {
    const stats = yield call(programStatsService.listMentorProgramStats, action.payload);
    yield put(
      actions.listMentorProgramStatsSucceeded({
        statsData: stats.data,
      })
    );
  } catch (e) {
    yield put(actions.listMentorProgramStatsFailed({ message: e.message }));
  }
}

function* programStatsSaga() {
  yield all([
    takeLatest(types.MEMBER_PROGRAM_STATS_LIST, listMemberProgramStats),
    takeLatest(types.MENTOR_PROGRAM_STATS_LIST, listMentorProgramStats),
  ]);
}
export default programStatsSaga;
