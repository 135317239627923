const FINANCIAL_PRODUCT_CLEAR_ERROR = 'bfb/financial-product/FINANCIAL_PRODUCT_CLEAR_ERROR';
const FINANCIAL_PRODUCTS_LIST = 'bfb/financial-product/FINANCIAL_PRODUCTS_LIST';
const FINANCIAL_PRODUCTS_LIST_SUCCEEDED = 'bfb/financial-product/FINANCIAL_PRODUCTS_LIST_SUCCEEDED';
const FINANCIAL_PRODUCTS_LIST_FAILED = 'bfb/financial-product/FINANCIAL_PRODUCTS_LIST_FAILED';
const FINANCIAL_PRODUCT_CREATE = 'bfb/financial-product/FINANCIAL_PRODUCT_CREATE';
const FINANCIAL_PRODUCT_CREATE_SUCCEEDED =
  'bfb/financial-product/FINANCIAL_PRODUCT_CREATE_SUCCEEDED';
const FINANCIAL_PRODUCT_CREATE_FAILED = 'bfb/financial-product/FINANCIAL_PRODUCT_CREATE_FAILED';
const FINANCIAL_PRODUCT_DELETE = 'bfb/financial-product/FINANCIAL_PRODUCT_DELETE';
const FINANCIAL_PRODUCT_DELETE_SUCCEEDED =
  'bfb/financial-product/FINANCIAL_PRODUCT_DELETE_SUCCEEDED';
const FINANCIAL_PRODUCT_DELETE_FAILED = 'bfb/financial-product/FINANCIAL_PRODUCT_DELETE_FAILED';
const FINANCIAL_PRODUCT_FETCH = 'bfb/financial-product/FINANCIAL_PRODUCT_FETCH';
const FINANCIAL_PRODUCT_FETCH_SUCCEEDED = 'bfb/financial-product/FINANCIAL_PRODUCT_FETCH_SUCCEEDED';
const FINANCIAL_PRODUCT_FETCH_FAILED = 'bfb/financial-product/FINANCIAL_PRODUCT_FETCH_FAILED';
const FINANCIAL_PRODUCT_UPDATE = 'bfb/financial-product/FINANCIAL_PRODUCT_UPDATE';
const FINANCIAL_PRODUCT_UPDATE_SUCCEEDED =
  'bfb/financial-product/FINANCIAL_PRODUCT_UPDATE_SUCCEEDED';
const FINANCIAL_PRODUCT_UPDATE_FAILED = 'bfb/financial-product/FINANCIAL_PRODUCT_UPDATE_FAILED';
const MONTHLY_UNITS_SOLD_UPDATE_SUCCEEDED =
  'bfb/financial-product/MONTHLY_UNITS_SOLD_UPDATE_SUCCEEDED';
const MONTHLY_UNITS_SOLD_UPDATE_FAILED = 'bfb/financial-product/MONTHLY_UNITS_SOLD_UPDATE_FAILED';
const MONTHLY_PRICE_UPDATE_SUCCEEDED = 'bfb/financial-product/MONTHLY_PRICE_UPDATE_SUCCEEDED';
const MONTHLY_PRICE_UPDATE_FAILED = 'bfb/financial-product/MONTHLY_PRICE_UPDATE_FAILED';
const MONTHLY_COGS_UPDATE_SUCCEEDED = 'bfb/financial-product/MONTHLY_COGS_UPDATE_SUCCEEDED';
const MONTHLY_COGS_UPDATE_FAILED = 'bfb/financial-product/MONTHLY_COGS_UPDATE_FAILED';

export default {
  FINANCIAL_PRODUCTS_LIST,
  FINANCIAL_PRODUCTS_LIST_SUCCEEDED,
  FINANCIAL_PRODUCTS_LIST_FAILED,
  FINANCIAL_PRODUCT_CLEAR_ERROR,
  FINANCIAL_PRODUCT_CREATE,
  FINANCIAL_PRODUCT_CREATE_SUCCEEDED,
  FINANCIAL_PRODUCT_CREATE_FAILED,
  FINANCIAL_PRODUCT_DELETE,
  FINANCIAL_PRODUCT_DELETE_SUCCEEDED,
  FINANCIAL_PRODUCT_DELETE_FAILED,
  FINANCIAL_PRODUCT_FETCH,
  FINANCIAL_PRODUCT_FETCH_SUCCEEDED,
  FINANCIAL_PRODUCT_FETCH_FAILED,
  FINANCIAL_PRODUCT_UPDATE,
  FINANCIAL_PRODUCT_UPDATE_SUCCEEDED,
  FINANCIAL_PRODUCT_UPDATE_FAILED,
  MONTHLY_UNITS_SOLD_UPDATE_SUCCEEDED,
  MONTHLY_UNITS_SOLD_UPDATE_FAILED,
  MONTHLY_PRICE_UPDATE_SUCCEEDED,
  MONTHLY_PRICE_UPDATE_FAILED,
  MONTHLY_COGS_UPDATE_SUCCEEDED,
  MONTHLY_COGS_UPDATE_FAILED,
};
