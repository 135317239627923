import types from './types';

const createWorkExperience = ({ ...workExperience }, callback) => ({
  type: types.WORK_EXPERIENCE_CREATE,
  payload: { ...workExperience },
  callback,
});

const createWorkExperienceSucceeded = ({ ...workExperience }) => ({
  type: types.WORK_EXPERIENCE_CREATE_SUCCEEDED,
  payload: { ...workExperience },
});

const createWorkExperienceFailed = ({ message }) => ({
  type: types.WORK_EXPERIENCE_CREATE_FAILED,
  payload: { message },
});

const fetchWorkExperience = query => ({
  type: types.WORK_EXPERIENCE_REQUEST,
  payload: query,
});

const fetchWorkExperienceSucceeded = ({ ...workExperienceData }) => ({
  type: types.WORK_EXPERIENCE_SUCCEEDED,
  payload: { ...workExperienceData },
});

const fetchWorkExperienceFailed = ({ message }) => ({
  type: types.WORK_EXPERIENCE_FAILED,
  payload: { message },
});

const updateWorkExperience = ({ id, ...workExperience }, callback) => ({
  type: types.WORK_EXPERIENCE_UPDATE,
  payload: { id, ...workExperience },
  callback,
});

const updateWorkExperienceSucceeded = ({ ...workExperienceData }) => ({
  type: types.WORK_EXPERIENCE_UPDATE_SUCCEEDED,
  payload: { ...workExperienceData },
});

const updateWorkExperienceFailed = ({ message }) => ({
  type: types.WORK_EXPERIENCE_UPDATE_FAILED,
  payload: message,
});

const deleteWorkExperience = (experienceId, callback) => ({
  type: types.WORK_EXPERIENCE_DELETE,
  payload: experienceId,
  callback,
});

const deleteWorkExperienceSucceeded = experienceId => ({
  type: types.WORK_EXPERIENCE_DELETE_SUCCEEDED,
  payload: experienceId,
});

const deleteWorkExperienceFailed = ({ message }) => ({
  type: types.WORK_EXPERIENCE_DELETE_FAILED,
  payload: message,
});

export default {
  createWorkExperience,
  createWorkExperienceSucceeded,
  createWorkExperienceFailed,
  fetchWorkExperience,
  fetchWorkExperienceSucceeded,
  fetchWorkExperienceFailed,
  updateWorkExperience,
  updateWorkExperienceSucceeded,
  updateWorkExperienceFailed,
  deleteWorkExperience,
  deleteWorkExperienceSucceeded,
  deleteWorkExperienceFailed,
};
