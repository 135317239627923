import { createSelector } from 'reselect';

const getState = state => state.result;

const getRawResults = createSelector([getState], state => state.get('data'));

const getResultsById = createSelector([getRawResults], state => state.get('byId'));

const getResultsBySystem = createSelector([getRawResults], state => state.get('bySystem'));

const getResults = createSelector([getResultsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getResults,
  getResultsById,
  getResultsBySystem,
  getErrorMessage,
};
