import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import programService from 'services/program';
import actions from './actions';
import types from './types';

export function* fetchProgramManagementProgram(action) {
  const { id } = action.payload;
  try {
    const program = yield call(programService.fetchProgramManagementProgram, {
      id,
    });
    yield put(actions.fetchProgramManagementProgramSucceeded(program.data));
  } catch (e) {
    yield put(actions.fetchProgramManagementProgramFailed({ message: e.message }));
  }
}

export function* updateProgramManagementProgram(action) {
  const { callback, payload } = action;
  try {
    const update = yield call(programService.updateProgramManagementProgram, payload);
    yield put(
      actions.updateProgramManagementProgramSucceeded({
        ...update.data,
        ids: payload.ids,
      })
    );
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.updateProgramManagementProgramFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* uploadProgramFile(action) {
  const { id, ...programPicture } = action.payload;
  try {
    const response = yield call(programService.uploadProgramFile, {
      id,
      ...programPicture,
    });
    yield put(actions.updateProgramManagementProgramSucceeded(response.data));
  } catch (e) {
    yield put(actions.updateProgramManagementProgramFailed({ message: e.message }));
  }
}

function* programSaga() {
  yield all([
    takeLatest(types.PROGRAM_MANAGEMENT_FETCH_PROGRAM, fetchProgramManagementProgram),
    takeLatest(types.PROGRAM_MANAGEMENT_UPDATE_PROGRAM, updateProgramManagementProgram),
    takeLatest(types.PROGRAM_MANAGEMENT_UPLOAD_IMAGE, uploadProgramFile),
  ]);
}

export default programSaga;
