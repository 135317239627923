import { Record } from 'immutable';

const ResourceRecord = new Record({
  id: null,
  contentType: 'api.resource',
  name: '',
  comments: [],
  type: null,
});

class Resource extends ResourceRecord {
  static contentType = 'api.resource';
  get id() {
    return this.get('id');
  }
}

export default Resource;
