import { createSelector } from 'reselect';

const getState = state => state.metric;

const getRawMetric = createSelector([getState], state => state.get('data'));

const getMetricsById = createSelector([getRawMetric], state => state.get('byId'));

const getMetricsByProject = createSelector([getRawMetric], state => state.get('byProject'));

const getMetrics = createSelector([getMetricsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getMetrics,
  getMetricsById,
  getMetricsByProject,
  getErrorMessage,
};
