import { Record } from 'immutable';
import ContentType from '../ContentType';

const MoonshotGoalRecord = Record({
  id: null,
  project: null,
  name: null,
  created: null,
  updated: null,
  comments: [],
  contentType: ContentType.MOONSHOTGOAL,
});

class MoonshotGoal extends MoonshotGoalRecord {
  static contentType = ContentType.MOONSHOTGOAL;

  get getId() {
    return this.get('id');
  }
}

export default MoonshotGoal;
