const MEMBER_EMAIL_DASHBOARD_LIST = 'bfb/ManagementPanel/MEMBER_EMAIL_DASHBOARD_LIST';
const MEMBER_EMAIL_DASHBOARD_LIST_PENDING =
  'bfb/ManagementPanel/MEMBER_EMAIL_DASHBOARD_LIST_PENDING';
const MEMBER_EMAIL_DASHBOARD_LIST_SUCCEEDED =
  'bfb/ManagementPanel/MEMBER_EMAIL_DASHBOARD_LIST_SUCCEEDED';
const MEMBER_EMAIL_DASHBOARD_LIST_FAILED = 'bfb/ManagementPanel/MEMBER_EMAIL_DASHBOARD_LIST_FAILED';

const MEMBER_DETAIL_EMAIL_DASHBOARD_LIST = 'bfb/ManagementPanel/MEMBER_DETAIL_EMAIL_DASHBOARD_LIST';
const MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_PENDING =
  'bfb/ManagementPanel/MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_PENDING';
const MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED =
  'bfb/ManagementPanel/MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED';
const MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_FAILED =
  'bfb/ManagementPanel/MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_FAILED';

export default {
  MEMBER_EMAIL_DASHBOARD_LIST,
  MEMBER_EMAIL_DASHBOARD_LIST_PENDING,
  MEMBER_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  MEMBER_EMAIL_DASHBOARD_LIST_FAILED,
  MEMBER_DETAIL_EMAIL_DASHBOARD_LIST,
  MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_PENDING,
  MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_FAILED,
};
