import { createSelector } from 'reselect';
import { List } from 'immutable';

const getState = state => state.program;

const getRawPrograms = createSelector([getState], state => state.get('data'));

const getProgramsById = createSelector([getRawPrograms], state => state.get('byId'));

const getPrograms = createSelector([getRawPrograms], state => state.get('list'));

const getPublicPrograms = createSelector([getRawPrograms], state =>
  state.get('public', new List())
);

const getProgramsSections = createSelector([getRawPrograms], state =>
  state.get('sections', new List())
);

const getProgramsUserRequest = createSelector([getRawPrograms], state => state.get('userType'));

const getSelectedProgram = createSelector([getState], state => state.get('selectedProgram'));

const updatePending = createSelector([getState], state => state.get('updatePending'));

export default {
  getPrograms,
  getProgramsById,
  getPublicPrograms,
  getProgramsSections,
  getProgramsUserRequest,
  getSelectedProgram,
  updatePending,
};
