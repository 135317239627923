// This service is DEPRECATED
// listOrganizationProjects is not used anywhere
// The rest has been migrated to RTK Query and can be found in services/apiSlices

import api from './api';

const listProjects = (query = {}) => {
  const params = { limit: query.limit || 20, ...query };
  return api.get('/api/projects/', { params });
};

const listOrganizationProjects = ({ organization, program, ...query }) =>
  api.get(`/api/organizations/${organization}/programs/${program}/projects/`, {
    params: query,
  });

const listExploreProjects = query => api.get('/api/explore_projects/', { params: query });
const fetchProject = ({ id }) => api.get(`/api/projects/${id}/`);
const fetchPublicProject = ({ id }) => api.get(`/api/public_projects/${id}/`);
const fetchExploreProject = ({ id }) => api.get(`/api/explore_projects/${id}/`);
const updateProject = project =>
  api.patch(`/api/projects/${project.id}/`, {
    ...project,
  });

export default {
  fetchProject,
  fetchPublicProject,
  fetchExploreProject,
  listExploreProjects,
  listOrganizationProjects,
  listProjects,
  updateProject,
};
