import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Resource from 'models/StakeholderMap/Resource';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byPartner: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RESOURCES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (resourcesById, resource) =>
                resourcesById.set(resource.id.toString(), new Resource(resource)),
              state.get('data').get('byId')
            ),
            byPartner: state
              .get('data')
              .get('byPartner', new Map())
              .set(
                action.payload.partner.toString(),
                action.payload.results
                  .reduce((arr, resource) => arr.push(new Resource(resource)), new List())
                  .sort(byId)
              ),
          },
        })
      );
    case types.RESOURCE_FETCH_SUCCEEDED:
    case types.RESOURCE_CREATE_SUCCEEDED:
    case types.RESOURCE_UPDATE_SUCCEEDED:
      return (() => {
        const partnerId = action.payload.partner.toString();
        const resourcesbyPartner = state
          .get('data')
          .get('byPartner')
          .get(partnerId, new List())
          .filter(resource => resource.id !== action.payload.id)
          .push(new Resource(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Resource(action.payload)),
              byPartner: state.get('data').get('byPartner').set(partnerId, resourcesbyPartner),
            },
          })
        );
      })();
    case types.RESOURCE_FETCH:
    case types.RESOURCE_CREATE:
    case types.RESOURCE_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.RESOURCE_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byPartner: state
              .get('data')
              .get('byPartner')
              .set(
                action.payload.partner.toString(),
                state
                  .get('data')
                  .get('byPartner')
                  .get(action.payload.partner.toString(), [])
                  .filter(resource => resource.id !== action.payload.id)
              )
              .sort(byId),
          },
        })
      );
    case types.RESOURCE_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.RESOURCE_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.RESOURCE_FETCH_FAILED:
    case types.RESOURCES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
