import { Col, Row } from 'antd';
import withTranslations from 'hocs/withTranslations';
import PropTypes from 'prop-types';
import Input from '../Input';
import Form from '../index';

const INPUT_FIRST_NAME = 'first_name';
const INPUT_LAST_NAME = 'last_name';

const Item = props => {
  const { form, initialFirstNameValue, initialLastNameValue, t, onChange, onBlur } = props;

  if (!form) return null;

  const { getFieldError, isFieldTouched } = form;
  const nameError = isFieldTouched(INPUT_FIRST_NAME) && getFieldError(INPUT_FIRST_NAME);
  const lastNameError = isFieldTouched(INPUT_LAST_NAME) && getFieldError(INPUT_LAST_NAME);

  const handleOnChange = event => {
    if (onChange) onChange(event);
  };

  const checkNamesInput = async (_, value) => {
    if (/[!@#$%^&123456789*()_+\=\[\]{};':"\\|,.<>\/?]/.test(value)) {
      return Promise.reject(new Error(t('Special characters are not valid')));
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={30} justify="space-around" align="bottom">
      <Col span={12}>
        <Form.Item
          label={t('Full name')}
          size="large"
          validateStatus={nameError.length ? 'error' : ''}
          rules={[
            {
              required: true,
              message: t('Enter your first name here'),
            },
            { validator: checkNamesInput },
          ]}
          initialValue={initialFirstNameValue}
          name={INPUT_FIRST_NAME}
        >
          <Input placeholder={t('First name')} onChange={handleOnChange} onBlur={onBlur} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          noAsterisk
          size="large"
          label=" "
          validateStatus={lastNameError.length ? 'error' : ''}
          rules={[
            {
              required: true,
              message: t('Enter your last name here'),
            },
            { validator: checkNamesInput },
          ]}
          initialValue={initialLastNameValue}
          name={INPUT_LAST_NAME}
        >
          <Input placeholder={t('Last name')} onChange={handleOnChange} onBlur={onBlur} />
        </Form.Item>
      </Col>
    </Row>
  );
};

Item.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  initialFirstNameValue: PropTypes.string,
  initialLastNameValue: PropTypes.string,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Item.defaultProps = {
  initialFirstNameValue: null,
  initialLastNameValue: null,
  onChange: null,
  onBlur: null,
};

const FullNameItem = withTranslations(Item);

export { FullNameItem, INPUT_FIRST_NAME, INPUT_LAST_NAME };
