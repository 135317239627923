import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Radio as AntRadio } from 'antd';
import bfbTheme from 'styles/b4b-theme';

const Radio = props => {
  const { children, ...rest } = props;
  return (
    <AntRadio vertical={vertical} {...rest}>
      {children}
    </AntRadio>
  );
};

const StyledRadio = styled(AntRadio)`
  &&& {
    .ant-radio-inner {
      height: 26px;
      width: 26px;
      background-color: white;

      &::after {
        background-color: ${bfbTheme.teal};
        transform: scale(0.5);
      }
    }

    &.ant-radio-wrapper {
      align-items: center;
    }

    &:not(.ant-radio-wrapper-disabled):hover .ant-radio-inner {
      border-color: ${bfbTheme.teal};
    }

    .ant-radio-checked {
      .ant-radio-inner {
        border-color: ${bfbTheme.teal};
      }

      &::after {
        border: 1px solid ${bfbTheme.teal};
      }
    }
  }
`;

Radio.propTypes = {
  children: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
};

Radio.defaultProps = {
  vertical: false,
};

export default StyledRadio;
