import types from './types';

const clearError = () => ({
  type: types.MILESTONE_CLEAR_ERROR,
});

const createMilestone = ({ project, ...milestone }) => ({
  type: types.MILESTONE_CREATE,
  payload: { project, ...milestone },
});

const createMilestoneSucceeded = ({ project, ...milestone }) => ({
  type: types.MILESTONE_CREATE_SUCCEEDED,
  payload: { project, ...milestone },
});

const createMilestoneFailed = ({ message }) => ({
  type: types.MILESTONE_CREATE_FAILED,
  payload: { message },
});

const deleteMilestone = ({ project, id }) => ({
  type: types.MILESTONE_DELETE,
  payload: { project, id },
});

const deleteMilestoneSucceeded = ({ project, id }) => ({
  type: types.MILESTONE_DELETE_SUCCEEDED,
  payload: { project, id },
});

const deleteMilestoneFailed = ({ message }) => ({
  type: types.MILESTONE_DELETE_FAILED,
  payload: message,
});

const fetchMilestone = ({ project, id }) => ({
  type: types.MILESTONE_FETCH,
  payload: { project, id },
});

const fetchMilestoneSucceeded = ({ project, ...milestone }) => ({
  type: types.MILESTONE_FETCH_SUCCEEDED,
  payload: { project, ...milestone },
});

const fetchMilestoneFailed = ({ message }) => ({
  type: types.MILESTONE_FETCH_FAILED,
  payload: { message },
});

const listMilestones = ({ project }) => ({
  type: types.MILESTONES_LIST,
  payload: { project },
});

const listMilestonesSucceeded = payload => ({
  type: types.MILESTONES_LIST_SUCCEEDED,
  payload,
});

const listMilestonesFailed = ({ message }) => ({
  type: types.MILESTONES_LIST_FAILED,
  payload: { message },
});

const updateMilestone = ({ id, project, ...updatedMilestone }) => ({
  type: types.MILESTONE_UPDATE,
  payload: { id, project, ...updatedMilestone },
});

const updateMilestoneSucceeded = ({ project, ...milestone }) => ({
  type: types.MILESTONE_UPDATE_SUCCEEDED,
  payload: { project, ...milestone },
});

const updateMilestoneFailed = ({ message }) => ({
  type: types.MILESTONE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createMilestone,
  createMilestoneFailed,
  createMilestoneSucceeded,
  deleteMilestone,
  deleteMilestoneFailed,
  deleteMilestoneSucceeded,
  fetchMilestone,
  fetchMilestoneFailed,
  fetchMilestoneSucceeded,
  listMilestones,
  listMilestonesFailed,
  listMilestonesSucceeded,
  updateMilestone,
  updateMilestoneFailed,
  updateMilestoneSucceeded,
};
