/* eslint-disable quote-props */
import mixpanel from 'mixpanel-browser';

// Change this to dev when testing
const ENV = 'production';
// const ENV = 'dev';

/**
 * Mixpanel event to track user signup
 */
function signupMixpanel(user) {
  if (window.env === ENV) {
    let userType = '';
    userType = user.member ? 'Entrepreneur' : 'Mentor';
    mixpanel.identify(user.id);
    const properties = {
      $email: user.username,
      $created: user.member ? user.member.created : user.mentor.created,
      'User Type': userType,
      'User ID': user.id,
    };
    if (user.member) {
      properties['Subscription Type'] = user.member.subscription_type;
      properties['Free Trial'] = user.member.free_trial;
    }
    mixpanel.track('User Signup', properties);
    mixpanel.people.set(properties);
  }
}

/**
 * Mixpanel event to track user login
 */
function loginMixpanel(member, mentor, isAdmin) {
  if (window.env === ENV) {
    const userId = member ? member.user : mentor.user;
    let userType = '';
    if (isAdmin) {
      userType = 'Admin';
    } else {
      userType = member ? 'Entrepreneur' : 'Mentor';
    }
    mixpanel.identify(userId);
    const properties = {
      $email: member ? member.email : mentor.email,
      $first_name: member ? member.first_name : mentor.first_name,
      $last_name: member ? member.last_name : mentor.last_name,
      'User Type': userType,
      'User ID': userId,
    };
    if (member) {
      properties['Subscription Type'] = member.subscription_type;
      properties['Free Trial'] = member.free_trial;
    }
    mixpanel.track('User Login', properties);
    mixpanel.people.set(properties);
  }
}

/**
 * Mixpanel event to track Member Application update
 */
function myVentureMixpanel(userId, projectName, programName) {
  if (window.env === ENV) {
    mixpanel.identify(userId);
    const properties = {
      project_name: projectName,
      program_name: programName,
    };
    mixpanel.people.set(properties);
  }
}

/**
 * Mixpanel event to track Member Application update
 */
function myProgramMixpanel(userId, projectName, programName) {
  if (window.env === ENV) {
    mixpanel.identify(userId);
    const properties = {
      project_name: projectName,
      program_name: programName,
    };
    mixpanel.people.set(properties);
  }
}

/**
 * Mixpanel event to track Member Application update
 */
function memberApplicationMixpanel(member, program) {
  if (window.env === ENV) {
    const userId = member.user;
    mixpanel.identify(userId);
    const properties = {
      $email: member.email,
      $first_name: member.first_name,
      $last_name: member.last_name,
      program_name: program.name,
    };
    mixpanel.track('Start Application', properties);
    mixpanel.people.set(properties);
  }
}

/**
 * Mixpanel event to track Member Application update
 */
function mentorApplicationMixpanel(mentor, program) {
  if (window.env === ENV) {
    const userId = mentor.user;
    mixpanel.identify(userId);
    const properties = {
      $email: mentor.email,
      $first_name: mentor.first_name,
      $last_name: mentor.last_name,
      program_name: program.name,
    };
    mixpanel.track('Start Application', properties);
    mixpanel.people.set(properties);
  }
}

/**
 * Mixpanel event to track user logout
 */
function logoutMixpanel() {
  if (window.env === ENV) {
    mixpanel.identify();
    mixpanel.track('Logout Click');
  }
}

/**
 * Mixpanel event to track user video
 */
function videoMixpanel() {
  if (window.env === ENV) {
    mixpanel.identify();
    mixpanel.track('Video Click');
  }
}

/**
 * Mixpanel event to track click on tool 6 table arrows to copy values
 */
function arrowsMixpanel() {
  if (window.env === ENV) {
    mixpanel.identify();
    mixpanel.track('T6 > Table Arrow Click');
  }
}

/**
 * Mixpanel event to track URL change
 */
function navigationChange() {
  if (window.env === ENV) {
    mixpanel.identify();
    mixpanel.track('Navigation Change', {
      URL: window.location.pathname,
    });
  }
}

/**
 * Mixpanel event to track Inputs Selected
 */
function inputSelectedMixpanel(name, step) {
  if (window.env === ENV) {
    mixpanel.identify();
    mixpanel.track('Input Visited', {
      InputName: name,
      Step: step,
    });
  }
}

/**
 * Mixpanel event to track Inputs Selected
 */
function stepSelectedMixpanel(step, wayOfReach, userType) {
  if (window.env === ENV) {
    mixpanel.identify();
    mixpanel.track('Input Visited', {
      Step: step,
      WayToReachIt: wayOfReach,
      UserType: userType,
    });
  }
}

export {
  arrowsMixpanel,
  loginMixpanel,
  signupMixpanel,
  logoutMixpanel,
  navigationChange,
  myVentureMixpanel,
  myProgramMixpanel,
  memberApplicationMixpanel,
  mentorApplicationMixpanel,
  videoMixpanel,
  inputSelectedMixpanel,
  stepSelectedMixpanel,
};
