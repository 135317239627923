// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Progress, Col, Popover } from 'antd';
import { Row } from 'components/layout';

const ProfileProgressStats = props => {
  const { data, t } = props;

  if (!data) return;

  const colorProfile = {
    regular: '#37CC82',
    fade: '#37CC821A',
  };

  const total = data.profile && data.profile.incomplete + data.profile.complete;

  return (
    <Wrapper color={colorProfile} singleCol>
      <Row type="flex" justify="space-between" gutter={30}>
        <Col>
          <div className="text">
            {t('Profile Incomplete')}
            <span data-test-id="profile-incomplete">{data.profile && data.profile.incomplete}</span>
          </div>
          <div className="text">
            {t('Profile Complete')}
            <span data-test-id="profile-complete">{data.profile && data.profile.complete}</span>
          </div>
        </Col>
      </Row>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              minWidth: '150px',
            }}
          >
            {t('Total Profile')}
            <span style={{ color: colorProfile.regular }}>
              {data.profile && data.profile.incomplete + data.profile.complete}
            </span>
          </div>
        }
        trigger="hover"
      >
        <Progress
          strokeColor={colorProfile.regular}
          percent={(data.profile.complete / total) * 100}
          showInfo={false}
        />
      </Popover>
    </Wrapper>
  );
};

ProfileProgressStats.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: ${props => (props.singleCol ? '180px' : '380px')};
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 ${({ color }) => color.fade};
    color: ${({ color }) => color.regular};
    .text {
      min-width: 120px;
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      justify-content: space-between;
      color: #000000;
      margin-bottom: 5px;
      span {
        color: ${({ color }) => color.regular};
        margin-left: 20px;
      }
    }
    .ant-progress-inner {
      background-color: ${({ color }) => color.fade};
    }
  }
`;

export default ProfileProgressStats;
