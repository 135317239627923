import api from '../api';

const createProblem = ({ ...problem }) => api.post('api/problems/', { ...problem });
const deleteProblem = ({ problemId }) => api.delete(`api/problems/${problemId}/`);
const fetchProblem = ({ id }) => api.get(`api/problems/${id}/`);
const listProblems = ({ projectId }) => api.get(`api/problems/?project=${projectId}`);
const updateProblem = ({ problemId, ...problem }) =>
  api.patch(`api/problems/${problemId}/`, { ...problem });

export default {
  createProblem,
  deleteProblem,
  fetchProblem,
  listProblems,
  updateProblem,
};
