import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/ImpactPlan/result';
import { types as commentTypes } from 'store/modules/comment';
import serviceResult from 'services/ImpactPlan/result';
import Result from 'models/ImpactPlan/Result';

export function* createResult(action) {
  const { system, ...result } = action.payload;
  try {
    const response = yield call(serviceResult.createResult, {
      system,
      ...result,
    });
    yield put(
      actions.createResultSucceeded({
        system,
        ...response.data,
      })
    );
  } catch (e) {
    yield put(
      actions.createResultFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteResult(action) {
  const { id, system } = action.payload;
  try {
    yield call(serviceResult.deleteResult, { id });
    yield put(actions.deleteResultSucceeded({ id, system }));
  } catch (e) {
    yield put(
      actions.deleteResultFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchResult(action) {
  const { id } = action.payload;
  try {
    const result = yield call(serviceResult.fetchResult, { id });
    yield put(actions.fetchResultSucceeded(result.data));
  } catch (e) {
    yield put(actions.fetchResultFailed({ message: e.message }));
  }
}

export function* listResults(action) {
  const { system } = action.payload;
  try {
    const results = yield call(serviceResult.listResults, { system });
    yield put(actions.listResultsSucceeded({ results: results.data, system }));
  } catch (e) {
    yield put(actions.listResultsFailed({ message: e.message }));
  }
}

export function* updateResult(action) {
  const { id, ...result } = action.payload;
  try {
    const updated = yield call(serviceResult.updateResult, { id, ...result });
    yield put(actions.updateResultSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateResultFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Result.contentType) {
    yield put(
      actions.fetchResult({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* resultSaga() {
  yield all([
    takeLatest(types.RESULT_CREATE, createResult),
    takeLatest(types.RESULT_DELETE, deleteResult),
    takeLatest(types.RESULT_FETCH, fetchResult),
    takeLatest(types.RESULT_UPDATE, updateResult),
    takeEvery(types.RESULTS_LIST, listResults),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default resultSaga;
