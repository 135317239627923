const PROJECT_SDG_CLEAR_ERROR = 'bfb/PROJECT_SDG/PROJECT_SDG_CLEAR_ERROR';
const PROJECT_SDGS_LIST = 'bfb/PROJECT_SDG/PROJECT_SDGS_LIST';
const PROJECT_SDGS_LIST_SUCCEEDED = 'bfb/PROJECT_SDG/PROJECT_SDGS_LIST_SUCCEEDED';
const PROJECT_SDGS_LIST_FAILED = 'bfb/PROJECT_SDG/PROJECT_SDGS_LIST_FAILED';
const PROJECT_SDG_CREATE = 'bfb/PROJECT_SDG/PROJECT_SDG_CREATE';
const PROJECT_SDG_CREATE_SUCCEEDED = 'bfb/PROJECT_SDG/PROJECT_SDG_CREATE_SUCCEEDED';
const PROJECT_SDG_CREATE_FAILED = 'bfb/PROJECT_SDG/PROJECT_SDG_CREATE_FAILED';
const PROJECT_SDG_DELETE = 'bfb/PROJECT_SDG/PROJECT_SDG_DELETE';
const PROJECT_SDG_DELETE_SUCCEEDED = 'bfb/PROJECT_SDG/PROJECT_SDG_DELETE_SUCCEEDED';
const PROJECT_SDG_DELETE_FAILED = 'bfb/PROJECT_SDG/PROJECT_SDG_DELETE_FAILED';
const PROJECT_SDG_FETCH = 'bfb/PROJECT_SDG/PROJECT_SDG_FETCH';
const PROJECT_SDG_FETCH_SUCCEEDED = 'bfb/PROJECT_SDG/PROJECT_SDG_FETCH_SUCCEEDED';
const PROJECT_SDG_FETCH_FAILED = 'bfb/PROJECT_SDG/PROJECT_SDG_FETCH_FAILED';
const PROJECT_SDG_UPDATE = 'bfb/PROJECT_SDG/PROJECT_SDG_UPDATE';
const PROJECT_SDG_UPDATE_SUCCEEDED = 'bfb/PROJECT_SDG/PROJECT_SDG_UPDATE_SUCCEEDED';
const PROJECT_SDG_UPDATE_FAILED = 'bfb/PROJECT_SDG/PROJECT_SDG_UPDATE_FAILED';

export default {
  PROJECT_SDGS_LIST,
  PROJECT_SDGS_LIST_SUCCEEDED,
  PROJECT_SDGS_LIST_FAILED,
  PROJECT_SDG_CLEAR_ERROR,
  PROJECT_SDG_CREATE,
  PROJECT_SDG_CREATE_SUCCEEDED,
  PROJECT_SDG_CREATE_FAILED,
  PROJECT_SDG_DELETE,
  PROJECT_SDG_DELETE_SUCCEEDED,
  PROJECT_SDG_DELETE_FAILED,
  PROJECT_SDG_FETCH,
  PROJECT_SDG_FETCH_SUCCEEDED,
  PROJECT_SDG_FETCH_FAILED,
  PROJECT_SDG_UPDATE,
  PROJECT_SDG_UPDATE_SUCCEEDED,
  PROJECT_SDG_UPDATE_FAILED,
};
