import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import GrowthRisk from 'models/GrowthPlan/GrowthRisk';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, flat: [] },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GROWTH_RISKS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results
              .reduce(
                (prevData, growthRisk) =>
                  prevData.set(growthRisk.id.toString(), new GrowthRisk(growthRisk)),
                state.get('data').get('byId')
              )
              .sort(byId),
            flat: action.payload.results
              .reduce((prevData, growthRisk) => [new GrowthRisk(growthRisk), ...prevData], [])
              .sort(byId),
          },
        })
      );
    case types.GROWTH_RISK_FETCH_SUCCEEDED:
    case types.GROWTH_RISK_CREATE_SUCCEEDED:
    case types.GROWTH_RISK_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new GrowthRisk(action.payload)),
            flat: state
              .get('data')
              .get('flat')
              .filter(risk => risk.id !== action.payload.id)
              .push(new GrowthRisk(action.payload))
              .sort(byId),
          },
        })
      );
    case types.GROWTH_RISK_FETCH:
    case types.GROWTH_RISK_CREATE:
    case types.GROWTH_RISK_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.GROWTH_RISK_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            flat: state
              .get('data')
              .get('flat')
              .filter(risk => risk.id !== action.payload.id),
          },
        })
      );
    case types.GROWTH_RISK_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.GROWTH_RISK_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.GROWTH_RISK_FETCH_FAILED:
    case types.GROWTH_RISKS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
