import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';
import bfbTheme from 'styles/b4b-theme';

const CardLink = ({ placeholder, href }) => (
  <Wrapper to={href} target="_blank">
    {placeholder}
    <RightOutlined />
  </Wrapper>
);

CardLink.propTypes = {
  placeholder: propTypes.string.isRequired,
  href: propTypes.string.isRequired,
};

const Wrapper = styled(Link)`
  width: 100%;
  margin: 20px 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 35px 10px 20px;
  display: flex;
  height: 45px;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  color: ${bfbTheme.mainColor};
`;

export default CardLink;
