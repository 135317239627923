// Libraries
import PropTypes from 'prop-types';
import { useContext } from 'react';
import styled from 'styled-components';

// Components
import PhoneInput from 'react-phone-input-2';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import bfbTheme from 'styles/b4b-theme';
import 'styles/phoneinput.css';

const PhoneNumberInput = ({ id, value, onChange }) => {
  const { t } = useContext(TranslationsContext);

  return (
    <div id={id}>
      <StyledPhoneInput
        placeholder={t('With international code (e.g. +49)')}
        searchPlaceholder={t('Search')}
        containerClass="phone-input-wrapper"
        buttonClass="phone-input-button"
        country="us"
        autoFormat
        enableSearch
        disableSearchIcon
        countryCodeEditable
        value={value}
        onChange={phone => onChange(`+${phone}`)}
      />
    </div>
  );
};

const StyledPhoneInput = styled(PhoneInput)`
  &&& {
    .phone-input-button {
      .selected-flag:hover,
      .selected-flag:focus {
        background-color: transparent;
      }
    }

    ::placeholder {
      font-size: 15px;
      font-style: italic;
      font-weight: 100;
      color: ${bfbTheme.grey};
    }
  }
`;

PhoneNumberInput.propTypes = {
  // id is coming from the Form.Item as a prop;
  // all custom inputs need this id so the form.scrollToField method works properly
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

PhoneNumberInput.defaultProps = {
  id: null,
  value: null,
  onChange: null,
};

export default PhoneNumberInput;
