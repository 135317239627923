import api from '../api';

const createBehavior = ({ ...behavior }) => api.post('api/behaviors/', { ...behavior });

const deleteBehavior = ({ id }) => api.delete(`api/behaviors/${id}`);

const fetchBehavior = ({ id }) => api.get(`api/behaviors/${id}/`);

const listBehaviors = query => api.get('/api/behaviors/', { params: query });

const updateBehavior = ({ id, ...updatedBehavior }) =>
  api.patch(`api/behaviors/${id}/`, { ...updatedBehavior });

export default {
  createBehavior,
  deleteBehavior,
  fetchBehavior,
  listBehaviors,
  updateBehavior,
};
