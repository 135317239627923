import { call, put, all, takeLatest } from 'redux-saga/effects';
import InviteServices from 'services/invite';
import types from './types';
import actions from './actions';

export function* fetchMemberInvites(action) {
  yield put(actions.fetchMemberInvitesPending());
  try {
    const response = yield call(InviteServices.fetchMemberInvites, action.payload);
    yield put(actions.fetchMemberInvitesSucceeded(response.data));
  } catch (e) {
    yield put(actions.fetchMemberInvitesFailed({ message: e.message }));
  }
}
function* inviteSaga() {
  yield all([takeLatest(types.FETCH_MEMBER_INVITES, fetchMemberInvites)]);
}

export default inviteSaga;
