import api from '../api';

const fetchInitiative = ({ id }) => api.get(`/api/initiatives/${id}/`);

const createInitiative = initiative => api.post('/api/initiatives/', initiative);

const deleteInitiative = ({ id }) => api.delete(`/api/initiatives/${id}/`);

const listInitiatives = query =>
  api.get('/api/initiatives/', {
    params: { ...query, objective__project: query.project },
  });

const updateInitiative = ({ id, ...initiative }) =>
  api.patch(`/api/initiatives/${id}/`, initiative);

export default {
  createInitiative,
  deleteInitiative,
  fetchInitiative,
  listInitiatives,
  updateInitiative,
};
