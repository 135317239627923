// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

// Dependencies
import { Tool } from 'models/Tools';
import withTranslations from 'hocs/withTranslations';
import { parrotTheme } from 'styles/themes';
import queryString from 'utils/queryString';

// Components
import { Col } from 'antd';
import { P, H2, H3 } from 'components/fontSystem';
import { Panel, Button, VideoIframe, VideoThumbnail } from 'components/elements';
import { Row } from 'components/layout';
import BfbModal from 'components/elements/Modal/BfbModal';
import IntroStep from './IntroStep';
import IntroHeader from './Header';

function ToolIntro(props) {
  const {
    backgroundImage,
    className,
    description,
    onButtonClick,
    steps,
    t,
    title,
    tool,
    tooltipContent,
  } = props;

  const [introVideoVisible, setIntroVideoVisible] = React.useState(false);

  const getEmbed = () => {
    const queryParams = queryString.parse(window.location.search);
    return queryParams.embed;
  };
  const isEmbed = getEmbed();

  return (
    <Panel className={className} size="large" $hardCorners>
      <IntroHeader title={title} tooltipContent={tooltipContent} />
      <Row className="content" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Col md={14} lg={12}>
          <P className="description">{description}</P>
          <H2>{t("In this tool you'll go step by step to:")}</H2>
          <div className="steps-container">
            {steps.map(step => (
              <IntroStep key={step} text={step} />
            ))}
          </div>
          {tool.intro_video_url && (
            <ThemeProvider theme={parrotTheme}>
              <H3 $semibold>{t('What will I learn?')}</H3>
              <VideoThumbnail
                className="video-thumbnail"
                src={tool.intro_video_url}
                onClick={() => setIntroVideoVisible(true)}
              />
              <BfbModal
                transparent
                open={introVideoVisible}
                onCancel={() => setIntroVideoVisible(false)}
                destroyOnClose
              >
                <VideoIframe src={tool.intro_video_url} height={430} />
              </BfbModal>
            </ThemeProvider>
          )}
        </Col>
      </Row>
      {!isEmbed && (
        <StyledDiv>
          <Button type="primary" effect="blink" onClick={onButtonClick}>
            {t("Let's go")}
          </Button>
        </StyledDiv>
      )}
    </Panel>
  );
}

ToolIntro.propTypes = {
  backgroundImage: PropTypes.string,
  className: PropTypes.string.isRequired,
  description: PropTypes.string,
  onButtonClick: PropTypes.func,
  steps: PropTypes.instanceOf(Array),
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  tool: PropTypes.instanceOf(Object),
  tooltipContent: PropTypes.instanceOf(Object),
};

ToolIntro.defaultProps = {
  backgroundImage: null,
  description: '',
  onButtonClick: null,
  steps: [],
  title: '',
  tool: new Tool(),
  tooltipContent: {},
};

const StyledDiv = styled.div`
  text-align: right;
  @media (max-width: ${props => props.theme.screenSmMin}) {
    text-align: left;
  }
`;

const StyledToolIntro = styled(ToolIntro)`
  h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .content {
    background-position: bottom right;
    background-size: 50%;
    background-repeat: no-repeat;
    min-height: 40vh;
    @media screen and (min-width: ${props => props.theme.screenMdMin}) {
      background-position: top right;
      background-size: 50%;
      min-height: 25vw;
    }
    margin-bottom: 30px;
  }

  .description {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 25px;
  }

  .steps-container {
    margin: 20px 0 30px;
    line-height: 26px;
  }

  @media (max-width: ${props => props.theme.screenSmMin}) {
    .content {
      background-image: none !important;
    }
    .video-thumbnail {
      max-width: 300px;
    }
  }
`;

export default withTranslations(StyledToolIntro);
