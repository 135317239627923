const EXPENSE_CLEAR_ERROR = 'bfb/expense/EXPENSE_CLEAR_ERROR';
const EXPENSES_LIST = 'bfb/expense/EXPENSES_LIST';
const EXPENSES_LIST_SUCCEEDED = 'bfb/expense/EXPENSES_LIST_SUCCEEDED';
const EXPENSES_LIST_FAILED = 'bfb/expense/EXPENSES_LIST_FAILED';
const EXPENSE_CREATE = 'bfb/expense/EXPENSE_CREATE';
const EXPENSE_CREATE_SUCCEEDED = 'bfb/expense/EXPENSE_CREATE_SUCCEEDED';
const EXPENSE_CREATE_FAILED = 'bfb/expense/EXPENSE_CREATE_FAILED';
const EXPENSE_DELETE = 'bfb/expense/EXPENSE_DELETE';
const EXPENSE_DELETE_SUCCEEDED = 'bfb/expense/EXPENSE_DELETE_SUCCEEDED';
const EXPENSE_DELETE_FAILED = 'bfb/expense/EXPENSE_DELETE_FAILED';
const EXPENSE_FETCH = 'bfb/expense/EXPENSE_FETCH';
const EXPENSE_FETCH_SUCCEEDED = 'bfb/expense/EXPENSE_FETCH_SUCCEEDED';
const EXPENSE_FETCH_FAILED = 'bfb/expense/EXPENSE_FETCH_FAILED';
const EXPENSE_UPDATE = 'bfb/expense/EXPENSE_UPDATE';
const EXPENSE_UPDATE_SUCCEEDED = 'bfb/expense/EXPENSE_UPDATE_SUCCEEDED';
const EXPENSE_UPDATE_FAILED = 'bfb/expense/EXPENSE_UPDATE_FAILED';
const MONTHLY_EXPENSE_UPDATE_SUCCEEDED = 'bfb/expense/MONTHLY_EXPENSE_UPDATE_SUCCEEDED';
const MONTHLY_EXPENSE_UPDATE_FAILED = 'bfb/expense/MONTHLY_EXPENSE_UPDATE_FAILED';

export default {
  EXPENSES_LIST,
  EXPENSES_LIST_SUCCEEDED,
  EXPENSES_LIST_FAILED,
  EXPENSE_CLEAR_ERROR,
  EXPENSE_CREATE,
  EXPENSE_CREATE_SUCCEEDED,
  EXPENSE_CREATE_FAILED,
  EXPENSE_DELETE,
  EXPENSE_DELETE_SUCCEEDED,
  EXPENSE_DELETE_FAILED,
  EXPENSE_FETCH,
  EXPENSE_FETCH_SUCCEEDED,
  EXPENSE_FETCH_FAILED,
  EXPENSE_UPDATE,
  EXPENSE_UPDATE_SUCCEEDED,
  EXPENSE_UPDATE_FAILED,
  MONTHLY_EXPENSE_UPDATE_SUCCEEDED,
  MONTHLY_EXPENSE_UPDATE_FAILED,
};
