// Assets
import maleProfilePicture from 'static/images/avatar_profile_male.png';
import femaleProfilePicture from 'static/images/avatar_profile_female.png';
import defaultProfilePicture from 'static/images/avatar_profile_neutral.png';

/**
 * Function that returns a default profile picture based on user gender.
 * @param {Member | Mentor} member - Instance of the user's member or mentor objects containing
 * the gender property.
 */
export default function getDefaultProfileByUserGender(member) {
  switch (member && member.id && member.gender) {
    case 'male':
      return maleProfilePicture;
    case 'female':
      return femaleProfilePicture;
    default:
      return defaultProfilePicture;
  }
}
