import api from '../api';

const getUserLinks = query =>
  api.get('/api/user-links/', { params: { user: query.user || query.id } });
const editUserLinksBulk = (user, data) => api.post(`/api/user-links/bulk/?user=${user.id}`, data);

export default {
  getUserLinks,
  editUserLinksBulk,
};
