import types from './types';

const clearError = () => ({
  type: types.FINANCIAL_PRODUCT_CLEAR_ERROR,
});

const createFinancialProduct = ({ scenario, ...financialProduct }, callback) => ({
  type: types.FINANCIAL_PRODUCT_CREATE,
  payload: { scenario, ...financialProduct },
  callback,
});

const createFinancialProductSucceeded = ({ scenario, id, ...financialProduct }) => ({
  type: types.FINANCIAL_PRODUCT_CREATE_SUCCEEDED,
  payload: { scenario, id, ...financialProduct },
});

const createFinancialProductFailed = ({ message }) => ({
  type: types.FINANCIAL_PRODUCT_CREATE_FAILED,
  payload: { message },
});

const deleteFinancialProduct = ({ scenarioId, financialProductId }) => ({
  type: types.FINANCIAL_PRODUCT_DELETE,
  payload: { scenarioId, financialProductId },
});

const deleteFinancialProductSucceeded = ({ scenario, financialProductId }) => ({
  type: types.FINANCIAL_PRODUCT_DELETE_SUCCEEDED,
  payload: { scenario, id: financialProductId },
});

const deleteFinancialProductFailed = ({ message }) => ({
  type: types.FINANCIAL_PRODUCT_DELETE_FAILED,
  payload: message,
});

const fetchFinancialProduct = ({ scenario, id }) => ({
  type: types.FINANCIAL_PRODUCT_FETCH,
  payload: { scenario, id },
});

const fetchFinancialProductSucceeded = ({ ...financialProduct }) => ({
  type: types.FINANCIAL_PRODUCT_FETCH_SUCCEEDED,
  payload: { ...financialProduct },
});

const fetchFinancialProductFailed = ({ message }) => ({
  type: types.FINANCIAL_PRODUCT_FETCH_FAILED,
  payload: { message },
});

const listFinancialProducts = payload => ({
  type: types.FINANCIAL_PRODUCTS_LIST,
  payload,
});

const listFinancialProductsSucceeded = payload => ({
  type: types.FINANCIAL_PRODUCTS_LIST_SUCCEEDED,
  payload,
});

const listFinancialProductsFailed = ({ message }) => ({
  type: types.FINANCIAL_PRODUCTS_LIST_FAILED,
  payload: { message },
});

const updateFinancialProduct = (
  { scenario, financialProductId, ...updatedFinancialProduct },
  callback
) => ({
  type: types.FINANCIAL_PRODUCT_UPDATE,
  payload: { scenario, financialProductId, ...updatedFinancialProduct },
  callback,
});

const updateFinancialProductSucceeded = ({ ...financialProduct }) => ({
  type: types.FINANCIAL_PRODUCT_UPDATE_SUCCEEDED,
  payload: { ...financialProduct },
});

const updateFinancialProductFailed = ({ message }) => ({
  type: types.FINANCIAL_PRODUCT_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createFinancialProduct,
  createFinancialProductFailed,
  createFinancialProductSucceeded,
  deleteFinancialProduct,
  deleteFinancialProductFailed,
  deleteFinancialProductSucceeded,
  fetchFinancialProduct,
  fetchFinancialProductFailed,
  fetchFinancialProductSucceeded,
  listFinancialProducts,
  listFinancialProductsFailed,
  listFinancialProductsSucceeded,
  updateFinancialProduct,
  updateFinancialProductFailed,
  updateFinancialProductSucceeded,
};
