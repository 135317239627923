import { createSelector } from 'reselect';

const getState = state => state.product;

const getRawProducts = createSelector([getState], state => state.get('data'));

const getProductsById = createSelector([getRawProducts], state => state.get('byId'));

const getProducts = createSelector([getProductsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getProducts,
  getProductsById,
  getErrorMessage,
};
