import api from '../api';

const fetchMilestone = ({ id }) => api.get(`/api/milestones/${id}`);

const createMilestone = milestone => api.post('/api/milestones/', milestone);

const deleteMilestone = ({ id }) => api.delete(`/api/milestones/${id}`);

const listMilestones = query => api.get('/api/milestones/', { params: query });

const updateMilestone = ({ id, ...milestone }) => api.patch(`/api/milestones/${id}/`, milestone);

export default {
  createMilestone,
  deleteMilestone,
  fetchMilestone,
  listMilestones,
  updateMilestone,
};
