import types from './types';

const fetchPillsToolkit = payload => ({
  type: types.PILLS_TOOLKIT_FETCH,
  payload,
});

const fetchPillsToolkitSucceeded = payload => ({
  type: types.PILLS_TOOLKIT_FETCH_SUCCEEDED,
  payload,
});

const fetchPillsToolkitFailed = ({ message }) => ({
  type: types.PILLS_TOOLKIT_FETCH_FAILED,
  payload: { message },
});

export default {
  fetchPillsToolkit,
  fetchPillsToolkitSucceeded,
  fetchPillsToolkitFailed,
};
