import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/// ////////////////////////////////////////////////////
// Component props:
// forWho: 'projects' -> green | 'mentors' -> blue
// $filled: to get a component with colo$red background
// $border: to get a component with $border,
// $red: to get a $red component,
// value: the value to display,
/// ////////////////////////////////////////////////////

function RankSquare({
  forWho,
  $filled = false,
  $border = false,
  $red = false,
  value = null,
  $margin = false,
}) {
  if (value === undefined || value === false) return null;

  const principalColor = forWho === 'project' ? '#37CC82' : '#5185E0';
  let content;

  if ($red) {
    content = 'X';
  } else if (value === null) {
    content = '-';
  } else {
    content = value;
  }

  return (
    <div style={{ backgroundColor: '#fff', $borderRadius: '4px' }}>
      <Square
        $principalColor={principalColor}
        $filled={$filled}
        $border={$border}
        $red={$red}
        $margin={$margin}
        $unranked={value === null}
      >
        {content}
      </Square>
    </div>
  );
}

RankSquare.propTypes = {
  forWho: PropTypes.string.isRequired,
  $filled: PropTypes.bool,
  $border: PropTypes.bool,
  $red: PropTypes.bool,
  value: PropTypes.number,
  $margin: PropTypes.bool,
};

const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  $margin: ${({ $margin }) => ($margin ? '0 10px 0 0' : '0')};
  $border-radius: 4px;
  $border: ${props => {
    if (props.$red && props.$border) {
      return '1px solid #F25A5A !important';
    }
    if (props.$border) {
      return `1px solid ${props.$principalColor} !important`;
    }
    return 'none';
  }};
  background-color: ${props => {
    if (props.$red) {
      return props.$filled ? '#F25A5A1A !important' : 'none';
    }
    if (props.$unranked) {
      return 'rgb(153, 153, 153, 0.1)';
    }
    return props.$filled ? `${props.$principalColor}1A !important` : 'none';
  }};
  color: ${props => {
    if (props.$red) {
      return props.$filled ? '#f25a5a' : 'unset';
    }
    if (props.$unranked) {
      return 'rgb(153, 153, 153)';
    }
    return props.$principalColor;
  }};
  font-weight: ${props => {
    if (props.$filled && !props.$border) return '600';
    return '400';
  }};
  cursor: initial;
  padding: 0 12px;
  white-space: normal;
`;

export default RankSquare;
