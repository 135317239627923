// Libraries
import styled from 'styled-components';

// Components
import { Progress as ProgressCirlce } from 'antd';

const StyledProgressCircle = styled(ProgressCirlce)`
  .ant-progress-inner {
    mask circle {
      stroke-linecap: round;
    }
    .ant-progress-text {
      color: ${props => props.color || props.theme.primaryColor};
      font-weight: 600;
      font-size: 20px;
    }
    .ant-progress-circle-path {
      stroke: ${props =>
        props.strokeColor || props.progressColor || props.theme.primaryColor} !important;
    }
    .ant-progress-circle-trail {
      stroke: ${props => props.opacity || '#f3f3f3'} !important;
    }
  }
  .value-container {
    display: flex;
    justify-content: space-between;
    height: 25px;
  }
`;

export default StyledProgressCircle;
