const KEY_RESULT_CLEAR_ERROR = 'bfb/KEY_RESULT/KEY_RESULT_CLEAR_ERROR';
const KEY_RESULTS_LIST = 'bfb/KEY_RESULT/KEY_RESULTS_LIST';
const KEY_RESULTS_LIST_SUCCEEDED = 'bfb/KEY_RESULT/KEY_RESULTS_LIST_SUCCEEDED';
const KEY_RESULTS_LIST_FAILED = 'bfb/KEY_RESULT/KEY_RESULTS_LIST_FAILED';
const KEY_RESULT_CREATE = 'bfb/KEY_RESULT/KEY_RESULT_CREATE';
const KEY_RESULT_CREATE_SUCCEEDED = 'bfb/KEY_RESULT/KEY_RESULT_CREATE_SUCCEEDED';
const KEY_RESULT_CREATE_FAILED = 'bfb/KEY_RESULT/KEY_RESULT_CREATE_FAILED';
const KEY_RESULT_DELETE = 'bfb/KEY_RESULT/KEY_RESULT_DELETE';
const KEY_RESULT_DELETE_SUCCEEDED = 'bfb/KEY_RESULT/KEY_RESULT_DELETE_SUCCEEDED';
const KEY_RESULT_DELETE_FAILED = 'bfb/KEY_RESULT/KEY_RESULT_DELETE_FAILED';
const KEY_RESULT_FETCH = 'bfb/KEY_RESULT/KEY_RESULT_FETCH';
const KEY_RESULT_FETCH_SUCCEEDED = 'bfb/KEY_RESULT/KEY_RESULT_FETCH_SUCCEEDED';
const KEY_RESULT_FETCH_FAILED = 'bfb/KEY_RESULT/KEY_RESULT_FETCH_FAILED';
const KEY_RESULT_UPDATE = 'bfb/KEY_RESULT/KEY_RESULT_UPDATE';
const KEY_RESULT_UPDATE_SUCCEEDED = 'bfb/KEY_RESULT/KEY_RESULT_UPDATE_SUCCEEDED';
const KEY_RESULT_UPDATE_FAILED = 'bfb/KEY_RESULT/KEY_RESULT_UPDATE_FAILED';

export default {
  KEY_RESULTS_LIST,
  KEY_RESULTS_LIST_SUCCEEDED,
  KEY_RESULTS_LIST_FAILED,
  KEY_RESULT_CLEAR_ERROR,
  KEY_RESULT_CREATE,
  KEY_RESULT_CREATE_SUCCEEDED,
  KEY_RESULT_CREATE_FAILED,
  KEY_RESULT_DELETE,
  KEY_RESULT_DELETE_SUCCEEDED,
  KEY_RESULT_DELETE_FAILED,
  KEY_RESULT_FETCH,
  KEY_RESULT_FETCH_SUCCEEDED,
  KEY_RESULT_FETCH_FAILED,
  KEY_RESULT_UPDATE,
  KEY_RESULT_UPDATE_SUCCEEDED,
  KEY_RESULT_UPDATE_FAILED,
};
