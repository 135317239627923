const PROGRAM_MANAGEMENT_FETCH_PROGRAM = 'bfb/programSetup/PROGRAM_MANAGEMENT_FETCH_PROGRAM';
const PROGRAM_MANAGEMENT_FETCH_PROGRAM_SUCCEEDED =
  'bfb/programSetup/PROGRAM_MANAGEMENT_FETCH_PROGRAM_SUCCEEDED';
const PROGRAM_MANAGEMENT_FETCH_PROGRAM_FAILED =
  'bfb/programSetup/PROGRAM_MANAGEMENT_FETCH_PROGRAM_FAILED';
const PROGRAM_MANAGEMENT_UPDATE_PROGRAM = 'bfb/programSetup/PROGRAM_MANAGEMENT_UPDATE_PROGRAM';
const PROGRAM_MANAGEMENT_UPDATE_PROGRAM_SUCCEEDED =
  'bfb/programSetup/PROGRAM_MANAGEMENT_UPDATE_PROGRAM_SUCCEEDED';
const PROGRAM_MANAGEMENT_UPDATE_PROGRAM_FAILED =
  'bfb/programSetup/PROGRAM_MANAGEMENT_UPDATE_PROGRAM_FAILED';
const PROGRAM_MANAGEMENT_UPLOAD_IMAGE = 'bfb/programSetup/PROGRAM_MANAGEMENT_UPLOAD_IMAGE';
const PROGRAM_MANAGEMENT_UPLOAD_IMAGE_SUCCEEDED =
  'bfb/programSetup/PROGRAM_MANAGEMENT_UPLOAD_IMAGE_SUCCEEDED';
const PROGRAM_MANAGEMENT_UPLOAD_IMAGE_FAILED =
  'bfb/programSetup/PROGRAM_MANAGEMENT_UPLOAD_IMAGE_FAILED';

export default {
  PROGRAM_MANAGEMENT_FETCH_PROGRAM,
  PROGRAM_MANAGEMENT_FETCH_PROGRAM_SUCCEEDED,
  PROGRAM_MANAGEMENT_FETCH_PROGRAM_FAILED,
  PROGRAM_MANAGEMENT_UPDATE_PROGRAM,
  PROGRAM_MANAGEMENT_UPDATE_PROGRAM_SUCCEEDED,
  PROGRAM_MANAGEMENT_UPDATE_PROGRAM_FAILED,
  PROGRAM_MANAGEMENT_UPLOAD_IMAGE,
  PROGRAM_MANAGEMENT_UPLOAD_IMAGE_SUCCEEDED,
  PROGRAM_MANAGEMENT_UPLOAD_IMAGE_FAILED,
};
