import { createSelector } from 'reselect';

const getState = state => state.invites;

const fetchMemberInvites = createSelector([getState], state => state.data);

const fetchMemberInvitesLoading = createSelector([getState], state => state.loading);

export default {
  getState,
  fetchMemberInvites,
  fetchMemberInvitesLoading,
};
