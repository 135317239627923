import { put, all, takeLatest } from 'redux-saga/effects';
// import service from 'services/toolsPdf';
import actions from './actions';
import types from './types';

// Tools actions
import { actions as vpActions } from '../ValueProposition';
import { actions as cmActions } from '../CompetitionMap';
import { actions as smActions } from '../StakeholderMap';
import { actions as bmmActions } from '../BusinessModelsMarketing';
import { actions as pbvActions } from '../PricingBusinessViability';
import { actions as fpActions } from '../FinancialProjections';
import { actions as ipActions } from '../ImpactPlan';
import { actions as gpActions } from '../GrowthPlan';

export function* fetchPdfTools(action) {
  const { payload, callback, shouldFetchObj } = action;
  try {
    // shouldFetchObj is an object of booleans for each tool, that
    // determines if it's necessary to fetch data of respective tools.
    // This will help us optimize the server requests by avoiding them
    // if any of the tools already had their data downloaded.
    yield all([
      shouldFetchObj.vpData && put(vpActions.fetchPdfTools(payload)),
      shouldFetchObj.cmData && put(cmActions.fetchPdfTools(payload)),
      shouldFetchObj.smData && put(smActions.fetchPdfTools(payload)),
      shouldFetchObj.bmmData && put(bmmActions.fetchPdfTools(payload)),
      shouldFetchObj.pbvData && put(pbvActions.fetchPdfTools(payload)),
      shouldFetchObj.fpData && put(fpActions.fetchPdfTools(payload)),
      shouldFetchObj.ipData && put(ipActions.fetchPdfTools(payload)),
      shouldFetchObj.gpData && put(gpActions.fetchPdfTools(payload)),
    ]);
    if (callback) yield callback();
  } catch (e) {
    yield put(
      actions.fetchPdfToolsFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* clearPdfTools(action) {
  const { payload, callback } = action;
  yield all([
    put(vpActions.clearPdfTools(payload)),
    put(cmActions.clearPdfTools(payload)),
    put(smActions.clearPdfTools(payload)),
    put(bmmActions.clearPdfTools(payload)),
    put(pbvActions.clearPdfTools(payload)),
    put(fpActions.clearPdfTools(payload)),
    put(ipActions.clearPdfTools(payload)),
    put(gpActions.clearPdfTools(payload)),
  ]);
  if (callback) yield callback();
}

function* pdfToolsSaga() {
  yield all([
    takeLatest(types.PDF_TOOL_FETCH, fetchPdfTools),
    takeLatest(types.PDF_TOOL_CLEAR, clearPdfTools),
  ]);
}

export default pdfToolsSaga;
