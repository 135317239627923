import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/FinancialProjections/expense';
import { types as commentTypes } from 'store/modules/comment';
import serviceExpense from 'services/FinancialProjections/expense';
import Expense from 'models/FinancialProjections/Expense';

export function* createExpense(action) {
  const { scenario, ...expense } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(serviceExpense.createExpense, {
      scenario,
      ...expense,
    });
    yield put(actions.createExpenseSucceeded({ ...response.data, scenario }));
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.createExpenseFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteExpense(action) {
  const { scenario, expenseId } = action.payload;
  const { callback } = action;
  try {
    yield call(serviceExpense.deleteExpense, { id: expenseId });
    if (callback) yield callback();
    yield put(actions.deleteExpenseSucceeded({ expenseId, scenario }));
  } catch (e) {
    yield put(
      actions.deleteExpenseFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchExpense(action) {
  const { scenario, id } = action.payload;
  try {
    const response = yield call(serviceExpense.fetchExpense, { scenario, id });
    yield put(actions.fetchExpenseSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(actions.fetchExpenseFailed({ message: e.message }));
  }
}

export function* listExpenses(action) {
  const { scenario } = action.payload;
  try {
    const expenses = yield call(serviceExpense.listExpenses, { scenario });
    yield put(actions.listExpensesSucceeded({ results: expenses.data, scenario }));
  } catch (e) {
    yield put(actions.listExpensesFailed({ message: e.message }));
  }
}

export function* updateExpense(action) {
  const { scenario, id, ...updatedExpense } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceExpense.updateExpense, {
      id,
      ...updatedExpense,
    });
    yield put(actions.updateExpenseSucceeded({ ...updated.data, scenario }));
    if (callback) yield callback();
  } catch (e) {
    yield put(
      actions.updateExpenseFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Expense.contentType) {
    yield put(
      actions.fetchExpense({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* expenseSaga() {
  yield all([
    takeLatest(types.EXPENSE_CREATE, createExpense),
    takeLatest(types.EXPENSE_DELETE, deleteExpense),
    takeLatest(types.EXPENSE_FETCH, fetchExpense),
    takeLatest(types.EXPENSE_UPDATE, updateExpense),
    takeEvery(types.EXPENSES_LIST, listExpenses),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default expenseSaga;
