// Libraries
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Drawer as AntDrawer } from 'antd';

export const zIndex = 10000000;

const RawDrawer = props => {
  const { headerColor, width, ...rest } = props;
  return <AntDrawer width={width} {...rest} />;
};

RawDrawer.propTypes = {
  headerColor: PropTypes.string,
  width: PropTypes.number,
};

RawDrawer.defaultProps = {
  headerColor: null,
  width: 480,
};

const Drawer = styled(RawDrawer)`
  & {
    z-index: ${zIndex} !important;
    max-width: 100%;
    .ant-drawer-header {
      min-height: ${({ theme }) => theme.appBarHeaderHeight};
      border: none;
      border-radius: 0;
      background-color: ${props => props.headerColor || props.theme.linkColor};
      padding: 16px 20px;
    }
    .ant-drawer-title {
      color: white;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
    }
    .ant-drawer-close {
      color: white;
      position: absolute;
      right: 0;
      &:hover {
        color: white;
      }
    }
    .ant-tabs-nav-container-scrolling {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export default Drawer;
