import { Record } from 'immutable';
import ContentType from '../ContentType';

const FeatureRecord = new Record({
  id: null,
  contentType: ContentType.FEATURE,
  name: '',
  product: null,
  comments: null,
  created: null,
  updated: null,
});

class Feature extends FeatureRecord {
  static contentType = ContentType.FEATURE;

  get id() {
    return this.get('id');
  }
}

export default Feature;
