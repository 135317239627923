import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RequiredWrapper = props => <span className={props.className}>{props.children}</span>;

RequiredWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const StyledWrapper = styled(RequiredWrapper)`
  &::after {
    content: '*';
    color: #f25a5a;
    display: inline-block;
  }
`;

export default StyledWrapper;
