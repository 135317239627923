/**
 * Helper function to sort an array of projects or other objects by their name property.
 * @function sortByName
 * @param {Member|Mentor} a - Project or other object with application_status property.
 * @param {Project} b - Project or other object with name property.
 * @returns {-1 | 0 | 1} - Sorting compare function results.
 */
export default function sortByField(field) {
  function sort(a, b) {
    const aField = a[field] || 0;
    const bField = b[field] || 0;
    if (typeof aField === 'string') {
      return aField.localeCompare(bField);
    }
    return aField - bField;
  }
  return sort;
}
