// Libraries
import styled from 'styled-components';

// Components
import { Tag } from 'antd';

// Dependencies
import theme from 'styles/b4b-theme';

const CustomTag = styled(Tag)`
  &&& {
    width: fit-content;
    height: 25px;
    line-height: 25px;

    padding-inline: 10px;
    font-size: 15px;
    border-radius: 4px;
    border: none;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props =>
      props.$expertise && {
        backgroundColor: '#0FC5D81A',
        color: '#0FC5D8',
      }}

    ${props =>
      props.$sectors && {
        backgroundColor: '#37CC821A',
        color: '#37CC82',
      }}

  ${props =>
      props.$mentoringLanguages && {
        backgroundColor: `${theme.mentorBlue}1A`,
        color: theme.mentorBlue,
      }}

  ${props =>
      props.$entrepreneurLanguages && {
        backgroundColor: '#37CC821A',
        color: '#37CC82',
      }}

  ${props =>
      props.$otherspokenlanguages && {
        backgroundColor: '#9999991A',
        color: '#000000',
      }}
  }
`;

export default CustomTag;
