import { Record } from 'immutable';
import ContentType from '../ContentType';

const BenefitRecord = new Record({
  id: null,
  contentType: ContentType.BENEFIT,
  name: '',
  segment: null,
  comments: null,
  selected: false,
  created: null,
  updated: null,
});

class Benefit extends BenefitRecord {
  static contentType = ContentType.BENEFIT;
  get id() {
    return this.get('id');
  }
}

export default Benefit;
