// Libraries
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Col } from 'antd';
import { Panel, Img } from 'components/elements';
import { H5 } from 'components/fontSystem';

const StyledCol = styled(Col)`
  .col-panel {
    padding: 0 3px;
    margin-bottom: 30px;
  }
  .col-image {
    height: 150px;
    width: auto;
    margin: 0 auto;
    display: block;
  }
  .col-title {
    padding: 20px 0 20px 0;
    margin: 0;
  }
`;

const ContentCol = props => {
  const { title, image, text, ...rest } = props;

  return (
    <StyledCol {...rest}>
      <Panel className="col-panel" $flex>
        {image && (
          <Img className="col-image" src={image} alt="Intro to product and visual business plan" />
        )}
        {title && <H5 className="col-title">{title}</H5>}
        <p bold>{text}</p>
      </Panel>
    </StyledCol>
  );
};

ContentCol.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
};
ContentCol.defaultProps = {
  title: '',
  text: '',
  image: null,
};

export default ContentCol;
