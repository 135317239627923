import { call, put, all, takeEvery } from 'redux-saga/effects';
import programMentorsService from 'services/programMentors';
import types from './types';
import actions from './actions';

export function* listProgramMentors(action) {
  yield put(actions.listProgramMentorsByCategoryPending());
  try {
    const { programId, organizationId, ...query } = action && action.payload;
    const programMentors = yield call(programMentorsService.listProgramMentors, {
      organizationId,
      programId,
      ...query,
    });
    yield put(
      actions.listProgramMentorsSucceeded(programMentors.data.results, programMentors.data.count)
    );
  } catch (e) {
    yield put(actions.listProgramMentorsFailed({ message: e.message }));
  }
}

export function* listProgramMentorsByCategory(action) {
  yield put(actions.listProgramMentorsByCategoryPending());
  try {
    const { programId, organizationId, category, ...query } = action && action.payload;
    const programMentors = yield call(programMentorsService.listProgramMentors, {
      organizationId,
      programId,
      mentor_status: category,
      ...query,
    });
    yield put(
      actions.listProgramMentorsByCategorySucceeded(
        programMentors.data.results,
        programMentors.data.count,
        category
      )
    );
  } catch (e) {
    yield put(actions.listProgramMentorsByCategoryFailed({ message: e.message }));
  }
}

function* programMentorsSaga() {
  yield all([
    takeEvery(types.PROGRAM_MENTORS_LIST, listProgramMentors),
    takeEvery(types.PROGRAM_MENTORS_LIST_BY_CATEGORY, listProgramMentorsByCategory),
  ]);
}

export default programMentorsSaga;
