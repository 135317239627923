import { call, put, all, takeLatest } from 'redux-saga/effects';
import linkService from 'services/deepDiveLink';
import types from './types';
import actions from './actions';

export function* listDeepDiveLinks(action) {
  try {
    const response = yield call(linkService.listDeepDiveLinks, action.payload);
    // eslint-disable-next-line max-len
    yield put(actions.listDeepDiveLinksSucceeded({ results: response.data }));
  } catch (e) {
    yield put(actions.listDeepDiveLinksFailed({ message: e.message }));
  }
}

export function* createDeepDiveLink(action) {
  const link = action.payload;
  try {
    const created = yield call(linkService.createDeepDiveLink, { ...link });
    yield put(actions.createDeepDiveLinkSucceeded(created.data));
    if (action.callback) {
      yield call(action.callback, String(created.data.id));
    }
  } catch (e) {
    yield put(
      actions.createDeepDiveLinkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateDeepDiveLinkSucceeded(action) {
  const { id, ...link } = action.payload;
  try {
    const updated = yield call(linkService.updateDeepDiveLink, { id, ...link });
    yield put(actions.updateDeepDiveLinkSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateDeepDiveLinkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteDeepDiveLink(action) {
  const { id, material } = action.payload;
  try {
    yield call(linkService.deleteDeepDiveLink, { id });
    yield put(actions.deleteDeepDiveLinkSucceeded({ id, material }));
  } catch (e) {
    yield put(
      actions.deleteDeepDiveLinkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* linkSaga() {
  yield all([
    takeLatest(types.DEEP_DIVE_LINK_CREATE, createDeepDiveLink),
    takeLatest(types.DEEP_DIVE_LINK_DELETE, deleteDeepDiveLink),
    takeLatest(types.DEEP_DIVE_LINKS_LIST, listDeepDiveLinks),
    takeLatest(types.DEEP_DIVE_LINK_UPDATE, updateDeepDiveLinkSucceeded),
  ]);
}
export default linkSaga;
