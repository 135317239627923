// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import { Modal } from 'components/elements';
import { H1 } from 'components/fontSystem';

const StyledModalBFB = styled(props => <Modal {...props} />)`
  z-index: 10000;
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    border-radius: 4px 4px 0 0;
    background-color: ${props => !props.hideHeaderBackground && props.theme.primaryColor};
  }
  .ant-modal-close {
    margin: 16px 8px 0 0;
  }
  .ant-modal-close-icon {
    color: ${props => (props.hideHeaderBackground ? props.theme.primaryColor : 'white')};
  }
  .ant-modal-content > div {
    padding: 30px;
  }

  .ant-modal-content .ant-modal-header {
    ${props => props.hideHeaderBackground && 'padding-bottom: 0px;'}
  }

  .ant-modal-body {
    overflow: hidden;
  }

  .description {
    margin-bottom: 30px;
  }
  .title {
    color: ${props => (props.hideHeaderBackground ? props.theme.primaryColor : 'white')};
  }

  ${({ transparent }) =>
    transparent &&
    css`
      .ant-modal-header,
      .ant-modal-body,
      .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
      }
      .ant-modal-content > .ant-modal-body {
        padding-top: 0;
      }
      .ant-modal-close {
        i {
          color: ${({ theme }) => theme.primaryColor};
        }
        margin-right: -12px;
      }
    `}
`;

const BfbModal = props => {
  const { children, title, hideHeaderBackground, ...rest } = props;
  return (
    <StyledModalBFB
      footer={null}
      overrideBodyStyle
      hideHeaderBackground={hideHeaderBackground}
      title={
        <H1 className="title" $mb0>
          {title}
        </H1>
      }
      {...rest}
    >
      {children}
    </StyledModalBFB>
  );
};

BfbModal.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.node,
  hideHeaderBackground: PropTypes.bool,
};
BfbModal.defaultProps = {
  size: 'xlarge',
  children: null,
  title: '',
  hideHeaderBackground: false,
};

export default BfbModal;
