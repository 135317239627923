import types from './types';

const clearError = () => ({
  type: types.OUTCOME_INDICATOR_CLEAR_ERROR,
});

const createOutcomeIndicator = ({ project, ...outcomeIndicator }) => ({
  type: types.OUTCOME_INDICATOR_CREATE,
  payload: { project, ...outcomeIndicator },
});

const createOutcomeIndicatorsucceeded = ({ ...outcomeIndicator }) => ({
  type: types.OUTCOME_INDICATOR_CREATE_SUCCEEDED,
  payload: { ...outcomeIndicator },
});

const createOutcomeIndicatorFailed = ({ message }) => ({
  type: types.OUTCOME_INDICATOR_CREATE_FAILED,
  payload: { message },
});

const deleteOutcomeIndicator = ({ project, id }) => ({
  type: types.OUTCOME_INDICATOR_DELETE,
  payload: { project, id },
});

const deleteOutcomeIndicatorsSucceeded = payload => ({
  type: types.OUTCOME_INDICATOR_DELETE_SUCCEEDED,
  payload,
});

const deleteOutcomeIndicatorFailed = ({ message }) => ({
  type: types.OUTCOME_INDICATOR_DELETE_FAILED,
  payload: { message },
});

const fetchOutcomeIndicator = ({ project, id }) => ({
  type: types.OUTCOME_INDICATOR_FETCH,
  payload: { project, id },
});

const fetchOutcomeIndicatorsucceeded = ({ ...outcomeIndicator }) => ({
  type: types.OUTCOME_INDICATOR_FETCH_SUCCEEDED,
  payload: { ...outcomeIndicator },
});

const fetchOutcomeIndicatorFailed = ({ message }) => ({
  type: types.OUTCOME_INDICATOR_FETCH_FAILED,
  payload: { message },
});

const listOutcomeIndicators = ({ project }) => ({
  type: types.OUTCOME_INDICATORS_LIST,
  payload: { project },
});

const listOutcomeIndicatorsSucceeded = payload => ({
  type: types.OUTCOME_INDICATORS_LIST_SUCCEEDED,
  payload,
});

const listOutcomeIndicatorsFailed = ({ message }) => ({
  type: types.OUTCOME_INDICATORS_LIST_FAILED,
  payload: { message },
});

const updateOutcomeIndicator = ({ project, id, ...outcomeIndicator }, callback) => ({
  type: types.OUTCOME_INDICATOR_UPDATE,
  payload: { project, id, ...outcomeIndicator },
  callback,
});

// const updateOutcomeIndicator = ({ project, id, ...outcomeIndicator }, callback) => {
//   console.log('project: ', project);
//   console.log('id: ', id);
//   console.log('outcomeIndicator: ', outcomeIndicator);
//   console.log('callback: ', callback);
// };

const updateOutcomeIndicatorsucceeded = ({ ...outcomeIndicator }) => ({
  type: types.OUTCOME_INDICATOR_UPDATE_SUCCEEDED,
  payload: { ...outcomeIndicator },
});

const updateOutcomeIndicatorFailed = ({ message }) => ({
  type: types.OUTCOME_INDICATOR_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createOutcomeIndicator,
  createOutcomeIndicatorFailed,
  createOutcomeIndicatorsucceeded,
  deleteOutcomeIndicator,
  deleteOutcomeIndicatorFailed,
  deleteOutcomeIndicatorsSucceeded,
  fetchOutcomeIndicator,
  fetchOutcomeIndicatorFailed,
  fetchOutcomeIndicatorsucceeded,
  listOutcomeIndicators,
  listOutcomeIndicatorsFailed,
  listOutcomeIndicatorsSucceeded,
  updateOutcomeIndicator,
  updateOutcomeIndicatorFailed,
  updateOutcomeIndicatorsucceeded,
};
