import { all, fork } from 'redux-saga/effects';

import { sagas as activitySaga } from './modules/BusinessModelsMarketing/activity';
import { sagas as areasOfExpertiseSaga } from './modules/areasOfExpertise';
import { sagas as attributesSaga } from './modules/CompetitionMap/attribute';
import { sagas as behaviorSaga } from './modules/GrowthPlan/behavior';
import { sagas as BeneficiarySaga } from './modules/ImpactPlan/beneficiary';
import { sagas as beneficiaryTypesSaga } from './modules/ImpactPlan/beneficiaryTypes';
import { sagas as benefitSaga } from './modules/ValueProposition/benefit';
import { sagas as businessModelSaga } from './modules/BusinessModelsMarketing/businessModel';
import { sagas as chainSaga } from './modules/StakeholderMap/chain';
import { sagas as channelSaga } from './modules/StakeholderMap/channel';
import { sagas as commentSaga } from './modules/comment';
import { sagas as competitorSaga } from './modules/CompetitionMap/competitor';
import { sagas as customizationSaga } from './modules/customization';
import { sagas as deepdiveSaga } from './modules/DeepDive';
import { sagas as deepDiveLinkSaga } from './modules/DeepDive/link';
import { sagas as demoExampleSaga } from './modules/demoExamples';
import { sagas as distributionChannelCombinerSaga } from './modules/StakeholderMap/combiners/DistributionChannels';
import { sagas as educationExperienceSaga } from './modules/educationExperience';
import { sagas as emailDashboardMemberSaga } from './modules/EmailDashboardMember';
import { sagas as emailDashboardMentorSaga } from './modules/EmailDashboardMentor';
import { sagas as expenseSaga } from './modules/FinancialProjections/expense';
import { sagas as executiveSummarySaga } from './modules/executiveSummary';
import { sagas as membersExecutiveSummarySaga } from './modules/executiveSummary/members';
import { sagas as featuresSaga } from './modules/CompetitionMap/feature';
import { sagas as financialProductSaga } from './modules/FinancialProjections/financialProduct';
import { sagas as financialProjectionSaga } from './modules/FinancialProjections/financialProjection';
import { sagas as fixedAssetsSaga } from './modules/FinancialProjections/fixedAsset';
import { sagas as fundingsSaga } from './modules/FinancialProjections/funding';
import { sagas as growthRiskSaga } from './modules/GrowthPlan/growthRisk';
import { sagas as initiativeSaga } from './modules/GrowthPlan/initiative';
import { sagas as interestshipSaga } from './modules/Matching/interestship';
import { sagas as invitesSagas } from './modules/Invites';
import { sagas as investmentsSaga } from './modules/FinancialProjections/investment';
import { sagas as IPresourceSaga } from './modules/ImpactPlan/resource';
import { sagas as keyResultSaga } from './modules/GrowthPlan/keyResult';
import { sagas as memberApplicationSaga } from './modules/memberApplication';
import { sagas as memberSaga } from './modules/member';
import { sagas as memberSurveysSaga } from './modules/memberSurveys';
import { sagas as surveysResponsesSaga } from './modules/surveyResponse';
import { sagas as mentorApplicationSaga } from './modules/mentorApplication';
import { sagas as mentorMatchingCombinerSaga } from './combiners/mentorMatching';
import { sagas as mentorSaga } from './modules/mentor';
import { sagas as metricSaga } from './modules/GrowthPlan/metric';
import { sagas as milestoneSaga } from './modules/GrowthPlan/milestone';
import { sagas as monthlyCogsSaga } from './modules/FinancialProjections/monthlyCogs';
import { sagas as monthlyExpenseSaga } from './modules/FinancialProjections/monthlyExpense';
import { sagas as monthlyPayrollSaga } from './modules/FinancialProjections/monthlyPayroll';
import { sagas as monthlyPriceSaga } from './modules/FinancialProjections/monthlyPrice';
import { sagas as monthlyUnitsSoldSaga } from './modules/FinancialProjections/monthlyUnitsSold';
import { sagas as moonshotGoalSaga } from './modules/GrowthPlan/moonshotGoal';
import { sagas as notificationsSaga } from './modules/notifications';
import { sagas as objectiveSaga } from './modules/GrowthPlan/objective';
import { sagas as organizationalRoleSaga } from './modules/GrowthPlan/organizationalRole';
import { sagas as organizationSaga } from './modules/organization';
import { sagas as outcomeSaga } from './modules/ValueProposition/outcome';
import { sagas as painSaga } from './modules/ValueProposition/pain';
import { sagas as parrobotSaga } from './modules/parrobot';
import { sagas as partnerSaga } from './modules/StakeholderMap/partner';
import { sagas as payrollSaga } from './modules/FinancialProjections/payroll';
import { sagas as pillsSaga } from './modules/Pills';
import { sagas as pillsCategoriesSaga } from './modules/PillsCategories';
import { sagas as pillsToolkitSaga } from './modules/PillsToolkit';
import { sagas as positioningSaga } from './modules/CompetitionMap/positioning';
import { sagas as pricingModelSaga } from './modules/PricingBusinessViability/pricingModel';
import { sagas as problemSaga } from './modules/ValueProposition/problem';
import { sagas as productSaga } from './modules/product';
import { sagas as profilePictureSaga } from './modules/profilePicture';
import { sagas as programCombinerSaga } from './combiners/myProgram';
import { sagas as programMembersSaga } from './modules/programMembers';
import { sagas as programMentorsSaga } from './modules/programMentors';
import { sagas as programSaga } from './modules/program';
import { sagas as programSetupSaga } from './modules/programSetup';
import { sagas as programToolsSaga } from './modules/programTools';
import { sagas as programStatsSaga } from './modules/programStats';
import { sagas as programToolsStep } from './modules/programStep';
import { sagas as projectLinkSaga } from './modules/projectLink';
import { sagas as projectSaga } from './modules/project';
import { sagas as projectSdgSaga } from './modules/ImpactPlan/projectSdg';
import { sagas as reasonSaga } from './modules/ValueProposition/reason';
import { sagas as RelationshipSaga } from './modules/ImpactPlan/relationship';
import { sagas as resourceSaga } from './modules/StakeholderMap/resources';
import { sagas as resultSaga } from './modules/ImpactPlan/result';
import { sagas as retentionActivitySaga } from './modules/PricingBusinessViability/retentionActivity';
import { sagas as riskCategorySaga } from './modules/GrowthPlan/riskCategory';
import { sagas as roleSaga } from './modules/ImpactPlan/role';
import { sagas as ruleSaga } from './modules/ImpactPlan/rule';
import { sagas as scenarioSaga } from './modules/scenario';
import { sagas as sdgOutcomeIndicatorSaga } from './modules/ImpactPlan/outcomeIndicator';
import { sagas as sdgOutcomeSaga } from './modules/ImpactPlan/outcome';
import { sagas as sdgSaga } from './modules/ImpactPlan/sdg';
import { sagas as sdgTargetSaga } from './modules/ImpactPlan/sdgTarget';
import { sagas as sectorsSaga } from './modules/sector';
import { sagas as segmentsSaga } from './modules/ValueProposition/segment';
import { sagas as statusSummarySaga } from './modules/statusSummary';
import { sagas as stepSaga } from './modules/Tools/step';
import { sagas as surveysSaga } from './modules/surveys';
import { sagas as systemSaga } from './modules/ImpactPlan/system';
import { sagas as toolSaga } from './modules/Tools/tool';
import { sagas as userLinkSaga } from './modules/userLink';
import { sagas as userSaga } from './modules/user';
import { sagas as valueSaga } from './modules/GrowthPlan/value';
import { sagas as workExperienceSaga } from './modules/workExperience';
import { sagas as managementPanelMemberSaga } from './modules/ManagementPanelMember';
import { sagas as managementPanelMentorSaga } from './modules/ManagementPanelMentor';
import { sagas as glossarySagas } from './modules/glossary';

// Tool PDF Sagas
import { sagas as valuePropositionPdfSaga } from './modules/toolsPdf/ValueProposition';
import { sagas as stakeholderMapPdfSaga } from './modules/toolsPdf/StakeholderMap';
import { sagas as competitionMapPdfSaga } from './modules/toolsPdf/CompetitionMap';
import { sagas as businessModelsMarketingPdfSaga } from './modules/toolsPdf/BusinessModelsMarketing';
import { sagas as pricingBusinessViabilityPdfSaga } from './modules/toolsPdf/PricingBusinessViability';
import { sagas as financialProjectionsPdfSaga } from './modules/toolsPdf/FinancialProjections';
import { sagas as impactPlanPdfSaga } from './modules/toolsPdf/ImpactPlan';
import { sagas as growthPlanPdfSaga } from './modules/toolsPdf/GrowthPlan';
import { sagas as completePdfSaga } from './modules/toolsPdf/CompletePdf';

export default function* rootSaga() {
  yield all([
    fork(activitySaga),
    fork(areasOfExpertiseSaga),
    fork(attributesSaga),
    fork(behaviorSaga),
    fork(BeneficiarySaga),
    fork(beneficiaryTypesSaga),
    fork(benefitSaga),
    fork(businessModelSaga),
    fork(chainSaga),
    fork(channelSaga),
    fork(commentSaga),
    fork(competitorSaga),
    fork(customizationSaga),
    fork(deepdiveSaga),
    fork(deepDiveLinkSaga),
    fork(demoExampleSaga),
    fork(distributionChannelCombinerSaga),
    fork(educationExperienceSaga),
    fork(emailDashboardMemberSaga),
    fork(emailDashboardMentorSaga),
    fork(expenseSaga),
    fork(executiveSummarySaga),
    fork(membersExecutiveSummarySaga),
    fork(featuresSaga),
    fork(financialProductSaga),
    fork(financialProjectionSaga),
    fork(fixedAssetsSaga),
    fork(fundingsSaga),
    fork(growthRiskSaga),
    fork(initiativeSaga),
    fork(interestshipSaga),
    fork(investmentsSaga),
    fork(invitesSagas),
    fork(IPresourceSaga),
    fork(keyResultSaga),
    fork(memberApplicationSaga),
    fork(memberSaga),
    fork(memberSurveysSaga),
    fork(surveysResponsesSaga),
    fork(mentorApplicationSaga),
    fork(mentorMatchingCombinerSaga),
    fork(mentorSaga),
    fork(metricSaga),
    fork(milestoneSaga),
    fork(monthlyCogsSaga),
    fork(monthlyExpenseSaga),
    fork(monthlyPayrollSaga),
    fork(monthlyPriceSaga),
    fork(monthlyUnitsSoldSaga),
    fork(moonshotGoalSaga),
    fork(notificationsSaga),
    fork(objectiveSaga),
    fork(organizationalRoleSaga),
    fork(organizationSaga),
    fork(outcomeSaga),
    fork(painSaga),
    fork(parrobotSaga),
    fork(partnerSaga),
    fork(payrollSaga),
    fork(pillsSaga),
    fork(pillsCategoriesSaga),
    fork(pillsToolkitSaga),
    fork(positioningSaga),
    fork(pricingModelSaga),
    fork(problemSaga),
    fork(productSaga),
    fork(profilePictureSaga),
    fork(programCombinerSaga),
    fork(programMembersSaga),
    fork(programMentorsSaga),
    fork(programSaga),
    fork(programSetupSaga),
    fork(programStatsSaga),
    fork(programToolsSaga),
    fork(programToolsStep),
    fork(projectLinkSaga),
    fork(projectSaga),
    fork(projectSdgSaga),
    fork(reasonSaga),
    fork(RelationshipSaga),
    fork(resourceSaga),
    fork(resultSaga),
    fork(retentionActivitySaga),
    fork(riskCategorySaga),
    fork(roleSaga),
    fork(ruleSaga),
    fork(scenarioSaga),
    fork(sdgOutcomeIndicatorSaga),
    fork(sdgOutcomeSaga),
    fork(sdgSaga),
    fork(sdgTargetSaga),
    fork(sectorsSaga),
    fork(segmentsSaga),
    fork(statusSummarySaga),
    fork(stepSaga),
    fork(surveysSaga),
    fork(systemSaga),
    fork(toolSaga),
    fork(userLinkSaga),
    fork(userSaga),
    fork(valueSaga),
    fork(workExperienceSaga),
    fork(managementPanelMemberSaga),
    fork(managementPanelMentorSaga),
    fork(glossarySagas),
    // Tool PDF Sagas
    fork(valuePropositionPdfSaga),
    fork(stakeholderMapPdfSaga),
    fork(competitionMapPdfSaga),
    fork(businessModelsMarketingPdfSaga),
    fork(pricingBusinessViabilityPdfSaga),
    fork(financialProjectionsPdfSaga),
    fork(impactPlanPdfSaga),
    fork(growthPlanPdfSaga),
    fork(completePdfSaga),
  ]);
}
