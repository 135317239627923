import { createSelector } from 'reselect';

const getState = state => state.value;

const getRawValue = createSelector([getState], state => state.get('data'));

const getValuesById = createSelector([getRawValue], state => state.get('byId'));

const getValuesByProject = createSelector([getRawValue], state => state.get('byProject'));

const getValues = createSelector([getValuesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getValues,
  getValuesById,
  getValuesByProject,
  getErrorMessage,
};
