import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import TranslationsContext from 'providers/translationsProvider';
import { Rate as AntRate } from 'antd';

class RateComponent extends Component {
  static propTypes = {
    colorIndex: PropTypes.number,
  };

  static defaultProps = {
    colorIndex: 0,
  };

  static contextType = TranslationsContext;

  render() {
    const { ...rest } = this.props;
    return <AntRate {...rest} />;
  }
}

const containerColor = css`
  ${({ colorIndex, theme, useTheme, mainColor }) => {
    if (useTheme) {
      return theme.primaryColor;
    }
    if (mainColor) {
      return mainColor;
    }
    return colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#D9D9D9';
  }}
`;

const Rate = styled(RateComponent)`
  &&& {
    .ant-rate-star-second {
      font-size: 21px;
    }

    .ant-rate-star-zero .ant-rate-star-second {
      color: #d9d9d9;
    }

    .ant-rate-star-full {
      color: ${containerColor};
    }
  }
`;

export default Rate;
