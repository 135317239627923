import { createSelector } from 'reselect';

const getState = state => state.parrobot;

const getData = createSelector([getState], state => state.data);

const getShowDrawer = createSelector([getData], state => state.showDrawer);

export default {
  getShowDrawer,
};
