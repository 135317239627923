import { createSelector } from 'reselect';

const getState = state => state.emailDashboardMentor;

const getRawMentorEmails = createSelector([getState], state => state.data);

const emailsListLoading = createSelector([getRawMentorEmails], state => state.loading);

const getMentorDetailEmail = createSelector([getState], state => state.detail);

export default {
  emailsListLoading,
  getRawMentorEmails,
  getMentorDetailEmail,
};
