import { createSelector } from 'reselect';

const getState = state => state.channel;

const getRawChannel = createSelector([getState], state => state.get('data'));

const getChannelsById = createSelector([getRawChannel], state => state.get('byId'));

const getChannelsByChain = createSelector([getRawChannel], state => state.get('byChain'));

const getChannels = createSelector([getChannelsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getChannels,
  getChannelsById,
  getChannelsByChain,
  getErrorMessage,
};
