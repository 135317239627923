import types from './types';

const fetchExecutiveSummary = ({ projectId, step }) => ({
  type: types.EXECUTIVE_SUMMARY_FETCH,
  payload: { projectId, step },
});

const fetchExecutiveSummarySucceeded = ({ projectId, ...execSum }) => ({
  type: types.EXECUTIVE_SUMMARY_FETCH_SUCCEEDED,
  payload: { projectId, ...execSum },
});

const fetchExecutiveSummaryFailed = ({ message }) => ({
  type: types.EXECUTIVE_SUMMARY_FETCH_FAILED,
  payload: { message },
});

const updateExecutiveSummary = ({ id, ...executiveSummary }, callback) => ({
  type: types.EXECUTIVE_SUMMARY_UPDATE,
  payload: { id, ...executiveSummary },
  callback,
});

const uploadExecutiveSummaryImage = ({ id, ...executiveSummary }) => ({
  type: types.EXECUTIVE_SUMMARY_UPLOAD_IMAGE,
  payload: { id, ...executiveSummary },
});

const updateExecutiveSummarySucceeded = payload => ({
  type: types.EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED,
  payload,
});

const updateExecutiveSummaryFailed = ({ message }) => ({
  type: types.EXECUTIVE_SUMMARY_UPDATE_FAILED,
  payload: { message },
});

export default {
  fetchExecutiveSummary,
  fetchExecutiveSummarySucceeded,
  fetchExecutiveSummaryFailed,
  updateExecutiveSummary,
  uploadExecutiveSummaryImage,
  updateExecutiveSummarySucceeded,
  updateExecutiveSummaryFailed,
};
