import { Record } from 'immutable';

const TYPE_IMPACT_AUTOMATIC = 0;
const TYPE_PTI_ENT = 1;
const TYPE_TOOLKIT_E_END_OF_TOOL = 2;
const TYPE_PULSE_CHECK_ENTREPRENEURS = 3;
const TYPE_PULSE_CHECK_MENTORS = 4;
const TYPE_IMPACT_END_OF_INCUBATION_B2C = 5;
const TYPE_IMPACT_END_OF_INCUBATION_B2B = 6;
const TYPE_TOOLKIT_M_END_OF_TOOL = 7;
const TYPE_IMPACT_M_END_OF_INCUBATION_B2C = 8;
const TYPE_IMPACT_M_END_OF_INCUBATION_B2B = 9;
const TYPE_IMPACT_POST = 10;

const STATUS_PENDING = 0;
const STATUS_SENT = 1;

const SurveysRecord = new Record({
  survey_id: null,
  typeform_en: '',
  typeform_es: '',
  typeform_fr: '',
  typeform_pt: '',
  typeform_pt_br: '',
  typeform_hy: '',
  typeform_it: '',
  typeform_sk: '',
  typeform_lv: '',
  typeform_th: '',
  typeform_vi: '',
  typeform_km: '',
  typeform_lo: '',
  id: null,
  name: '',
  type: null,
  pti_survey_status: STATUS_PENDING,
  complete_surveys: [],
  offset: null,
  trigger: null,
});

class Surveys extends SurveysRecord {
  static TYPE_IMPACT_AUTOMATIC = TYPE_IMPACT_AUTOMATIC;

  static TYPE_PTI_ENT = TYPE_PTI_ENT;

  static TYPE_TOOLKIT_E_END_OF_TOOL = TYPE_TOOLKIT_E_END_OF_TOOL;

  static TYPE_PULSE_CHECK_ENTREPRENEURS = TYPE_PULSE_CHECK_ENTREPRENEURS;

  static TYPE_PULSE_CHECK_MENTORS = TYPE_PULSE_CHECK_MENTORS;

  static TYPE_IMPACT_END_OF_INCUBATION_B2C = TYPE_IMPACT_END_OF_INCUBATION_B2C;

  static TYPE_IMPACT_END_OF_INCUBATION_B2B = TYPE_IMPACT_END_OF_INCUBATION_B2B;

  static TYPE_TOOLKIT_M_END_OF_TOOL = TYPE_TOOLKIT_M_END_OF_TOOL;

  static TYPE_IMPACT_M_END_OF_INCUBATION_B2C = TYPE_IMPACT_M_END_OF_INCUBATION_B2C;

  static TYPE_IMPACT_M_END_OF_INCUBATION_B2B = TYPE_IMPACT_M_END_OF_INCUBATION_B2B;

  static TYPE_IMPACT_POST = TYPE_IMPACT_POST;

  static STATUS_PENDING = STATUS_PENDING;

  static STATUS_SENT = STATUS_SENT;
}

export default Surveys;
