import api from './api';

const listNotifications = query => api.get('/api/notifications/', { params: query });

const updateNotification = notification =>
  api.patch(`/api/notifications/${notification.id}/`, { ...notification });

export default {
  listNotifications,
  updateNotification,
};
