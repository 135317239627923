import React from 'react';

const TableContext = React.createContext({
  backgroundColor: undefined,
  elevationBoxShadow: undefined,
  fixedLeftBoxShadow: undefined,
  hoveredRow: undefined,
  onRowHover: undefined,
});

export default TableContext;
