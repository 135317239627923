import types from './types';

const fetchProgramManagementProgram = ({ id }) => ({
  type: types.PROGRAM_MANAGEMENT_FETCH_PROGRAM,
  payload: { id },
});

const fetchProgramManagementProgramSucceeded = ({ id, ...program }) => ({
  type: types.PROGRAM_MANAGEMENT_FETCH_PROGRAM_SUCCEEDED,
  payload: { id, ...program },
});

const fetchProgramManagementProgramFailed = ({ message }) => ({
  type: types.PROGRAM_MANAGEMENT_FETCH_PROGRAM_FAILED,
  payload: { message },
});

const updateProgramManagementProgram = (payload, callback) => ({
  type: types.PROGRAM_MANAGEMENT_UPDATE_PROGRAM,
  payload,
  callback,
});

const updateProgramManagementProgramSucceeded = payload => ({
  type: types.PROGRAM_MANAGEMENT_UPDATE_PROGRAM_SUCCEEDED,
  payload,
});

const updateProgramManagementProgramFailed = ({ message }) => ({
  type: types.PROGRAM_MANAGEMENT_UPDATE_PROGRAM_FAILED,
  payload: { message },
});

const uploadProgramFile = ({ id, ...programFile }) => ({
  type: types.PROGRAM_MANAGEMENT_UPLOAD_IMAGE,
  payload: { id, ...programFile },
});

const uploadProgramFileSucceeded = payload => ({
  type: types.PROGRAM_MANAGEMENT_UPLOAD_IMAGE_SUCCEEDED,
  payload,
});

const uploadProgramFileFailed = ({ message }) => ({
  type: types.PROGRAM_MANAGEMENT_UPLOAD_IMAGE_FAILED,
  payload: { message },
});

export default {
  fetchProgramManagementProgram,
  fetchProgramManagementProgramSucceeded,
  fetchProgramManagementProgramFailed,
  updateProgramManagementProgram,
  updateProgramManagementProgramSucceeded,
  updateProgramManagementProgramFailed,
  uploadProgramFile,
  uploadProgramFileSucceeded,
  uploadProgramFileFailed,
};
