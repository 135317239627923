import { createSelector } from 'reselect';

const getState = state => state.moonshotGoal;

const getRawMoonshotGoal = createSelector([getState], state => state.get('data'));

const getMoonshotGoalsById = createSelector([getRawMoonshotGoal], state => state.get('byId'));

const getMoonshotGoalsByProject = createSelector([getRawMoonshotGoal], state =>
  state.get('byProject')
);

const getMoonshotGoals = createSelector([getMoonshotGoalsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getMoonshotGoals,
  getMoonshotGoalsById,
  getMoonshotGoalsByProject,
  getErrorMessage,
};
