// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Assets
// Check icons
import { ReactComponent as filledCheck } from 'static/icons/00-general/00-1-icons/icon_complete.svg';
import { ReactComponent as emptyDashedCheck } from 'static/icons/00-general/00-1-icons/icon_empty-dotted.svg';
import { ReactComponent as emptyCheck } from 'static/icons/07-in-tool/07-1-icons/icon_check-in-circle-empty.svg';
// Star icons
import { ReactComponent as filledStar } from 'static/icons/07-in-tool/07-1-icons/icon_star_filled.svg';
import { ReactComponent as emptyStar } from 'static/icons/07-in-tool/07-1-icons/icon_star_empty.svg';
import { ToolIcon } from '../index';

const ToolSelectIcon = props => {
  const { empty, selected, star, onSelectClick } = props;

  const getIcons = () => {
    switch (true) {
      case star:
        return [filledStar, emptyStar];
      case !star && empty:
        return [filledCheck, emptyDashedCheck];
      default:
        return [filledCheck, emptyCheck];
    }
  };

  const [filledIcon, emptyIcon] = getIcons();

  return (
    <ToolIcon star={star} component={selected ? filledIcon : emptyIcon} onClick={onSelectClick} />
  );
};

ToolSelectIcon.propTypes = {
  empty: PropTypes.bool,
  selected: PropTypes.bool,
  star: PropTypes.bool,
  onSelectClick: PropTypes.func,
};
ToolSelectIcon.defaultProps = {
  empty: false,
  selected: false,
  star: false,
  onSelectClick: null,
};

export default ToolSelectIcon;
