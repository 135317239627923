import { fontWeightStyle } from 'components/fontSystem';
import styled, { css } from 'styled-components';

const getToolColor = ({ colorIndex, theme }, defaultColor) =>
  colorIndex !== undefined ? theme.getToolColor(colorIndex) : defaultColor;

const Div = styled.div`
  ${fontWeightStyle}

  color: ${props => props.color || 'black'};
  font-size: 15px;

  ${({ tip, theme }) =>
    tip &&
    css`
      color: ${props => getToolColor(props, props.color || 'white')};
      background-color: ${theme.primaryColor};
      border-radius: 4px;
      padding: 1em;
    `};

  ${({ colorIndex }) =>
    colorIndex !== undefined &&
    css`
      color: ${({ theme }) => theme.getToolColor(colorIndex)};
    `};

  ${props =>
    props.primary &&
    css`
      color: ${props.theme.primaryColor};
    `}

  ${props =>
    props.tacenter &&
    css`
      text-align: center;
    `}

  ${props =>
    props.taright &&
    css`
      text-align: right;
    `}

  ${({ help }) =>
    help &&
    css`
      color: #37cc82;
      font-size: ${props => props.size || '12px'};
      font-style: italic;
      line-height: 20px;
    `}
`;

export default Div;
