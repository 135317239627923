import { Record } from 'immutable';
import ContentType from '../ContentType';

const SystemRecord = Record({
  id: null,
  name: null,
});

class System extends SystemRecord {
  // TODO: Add ContentType
  static contentType = ContentType.SCENARIO;

  get getId() {
    return this.get('id');
  }
}

export default System;
