import { createSelector } from 'reselect';

const getState = state => state.sdgTarget;

const getRawSdgTargets = createSelector([getState], state => state.get('data'));

const getSdgTargetsById = createSelector([getRawSdgTargets], state => state.get('byId'));

const getSdgTargetsbySdg = createSelector([getRawSdgTargets], state => state.get('bySdg'));

const getSdgTargetsOfSdg = createSelector([getRawSdgTargets], state => {
  const sdgTargetsBySdg = state.get('bySdg');
  return sdgId => {
    const targets = sdgTargetsBySdg.get(String(sdgId));
    return targets ? targets.toJS() : [];
  };
});

const getSdgTargets = createSelector([getSdgTargetsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getSdgTargets,
  getSdgTargetsById,
  getSdgTargetsbySdg,
  getSdgTargetsOfSdg,
  getErrorMessage,
};
