import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import serviceMonthlyUnitsSold from 'services/FinancialProjections/monthlyUnitsSold';
import MonthlyUnitsSold from 'models/FinancialProjections/MonthlyUnitsSold';
import { actions, types } from 'store/modules/FinancialProjections/monthlyUnitsSold';
import { types as commentTypes } from 'store/modules/comment';

export function* updateMonthlyUnitsSold(action) {
  const { scenario, id, ...updatedMonthlyUnitsSold } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceMonthlyUnitsSold.updateMonthlyUnitsSold, {
      id,
      ...updatedMonthlyUnitsSold,
    });
    yield put(
      actions.updateMonthlyUnitsSoldSucceeded({
        ...updatedMonthlyUnitsSold, // To keep the year property.
        ...updated.data, // New data.
        scenario, // And to keep the scenario property.
      })
    );
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateMonthlyUnitsSoldFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === MonthlyUnitsSold.contentType) {
    yield put(
      actions.fetchMonthlyUnitsSold({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* monthlyUnitsSoldSaga() {
  yield all([
    takeEvery(types.MONTHLY_UNITS_SOLD_UPDATE, updateMonthlyUnitsSold),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default monthlyUnitsSoldSaga;
