import { createSelector } from 'reselect';

const getState = state => state.customization;

const getCustomization = createSelector([getState], state => state.data);

const isFetching = createSelector([getState], state => state.isFetching);

export default {
  getCustomization,
  isFetching,
};
