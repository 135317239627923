// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { baseClamp, TOP_INDICATOR_SPACING } from '../dependencies';

const LineComponent = props => {
  const { children, ...rest } = props;
  return (
    <StyledLine {...rest}>
      <LineAnchor>{children}</LineAnchor>
    </StyledLine>
  );
};

LineComponent.propTypes = {
  children: PropTypes.node,
};

LineComponent.defaultProps = {
  children: null,
};

const StyledLine = styled.div`
  position: absolute;
  bottom: calc(
    ${({ heightPercentage }) => {
      const height = heightPercentage ? `${baseClamp(heightPercentage, 0, 100)}%` : '0%';
      const spacingInNumber = TOP_INDICATOR_SPACING.replace('px', '');
      const lineSpacing = spacingInNumber * (heightPercentage / 100);
      return css`
        ${height} - ${lineSpacing}px
      `;
    }}
  );
  left: 0;
  opacity: ${({ shouldHideWhenEmpty, value }) => {
    if (shouldHideWhenEmpty) {
      if (value) {
        return 1;
      }
      return 0;
    }
    return 1;
  }};
  transition:
    bottom ease 200ms,
    opacity ease 200ms;
  will-change: bottom;
  width: 100%;
  height: 2px;
  color: ${({ theme, colorIndex }) =>
    colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#999999'};
  border-bottom: 2px ${({ dashed }) => (dashed ? 'dashed' : 'solid')}
    ${({ theme, colorIndex }) =>
      colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#999999'};
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
`;

const LineAnchor = styled.div`
  position: relative;
  height: 100%;
  width: 130px;
  max-width: 130px;
  min-width: 130px;
  flex: 130px;
`;

export default LineComponent;
