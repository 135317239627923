import { createSelector } from 'reselect';

const getState = state => state.member;

const getMember = createSelector([getState], state => state.get('data'));

const updatePending = createSelector([getState], state => state.get('updatePending'));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getMember,
  updatePending,
  getErrorMessage,
};
