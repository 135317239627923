import React from 'react';
import styled from 'styled-components';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import withTranslations from 'hocs/withTranslations';

const { Dragger } = Upload;

const StyledDragger = styled(Dragger)`
  &&& {
    .ant-upload.ant-upload-drag {
      padding: 40px;
    }
    .ant-upload-drag-icon .anticon.anticon-inbox {
      color: ${props => props.theme.primaryColor};
    }
  }
`;

const FileDragger = props => {
  const { t } = props;
  return (
    <StyledDragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
      <p className="ant-upload-hint">{t('(Size: 900px x 500px)')}</p>
      <p className="ant-upload-hint">{t('(Max file size: 1MB / Required format: .jpg, .png)')}</p>
    </StyledDragger>
  );
};

FileDragger.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslations(FileDragger);
