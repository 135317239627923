const ACTIVITIES_CLEAR = 'bfb/businessModels/ACTIVITY/ACTIVITIES_CLEAR';
const ACTIVITY_CLEAR_ERROR = 'bfb/businessModels/ACTIVITY/ACTIVITY_CLEAR_ERROR';
const ACTIVITIES_LIST = 'bfb/businessModels/ACTIVITY/ACTIVITIES_LIST';
const ACTIVITIES_LIST_SUCCEEDED = 'bfb/businessModels/ACTIVITY/ACTIVITIES_LIST_SUCCEEDED';
const ACTIVITIES_LIST_FAILED = 'bfb/businessModels/ACTIVITY/ACTIVITIES_LIST_FAILED';
const ACTIVITY_CREATE = 'bfb/businessModels/ACTIVITY/ACTIVITY_CREATE';
const ACTIVITY_CREATE_SUCCEEDED = 'bfb/businessModels/ACTIVITY/ACTIVITY_CREATE_SUCCEEDED';
const ACTIVITY_CREATE_FAILED = 'bfb/businessModels/ACTIVITY/ACTIVITY_CREATE_FAILED';
const ACTIVITY_DELETE = 'bfb/businessModels/ACTIVITY/ACTIVITY_DELETE';
const ACTIVITY_DELETE_SUCCEEDED = 'bfb/businessModels/ACTIVITY/ACTIVITY_DELETE_SUCCEEDED';
const ACTIVITY_DELETE_FAILED = 'bfb/businessModels/ACTIVITY/ACTIVITY_DELETE_FAILED';
const ACTIVITY_FETCH = 'bfb/businessModels/ACTIVITY/ACTIVITY_FETCH';
const ACTIVITY_FETCH_SUCCEEDED = 'bfb/businessModels/ACTIVITY/ACTIVITY_FETCH_SUCCEEDED';
const ACTIVITY_FETCH_FAILED = 'bfb/businessModels/ACTIVITY/ACTIVITY_FETCH_FAILED';
const ACTIVITY_UPDATE = 'bfb/businessModels/ACTIVITY/ACTIVITY_UPDATE';
const ACTIVITY_UPDATE_SUCCEEDED = 'bfb/businessModels/ACTIVITY/ACTIVITY_UPDATE_SUCCEEDED';
const ACTIVITY_UPDATE_FAILED = 'bfb/businessModels/ACTIVITY/ACTIVITY_UPDATE_FAILED';

export default {
  ACTIVITIES_CLEAR,
  ACTIVITIES_LIST,
  ACTIVITIES_LIST_SUCCEEDED,
  ACTIVITIES_LIST_FAILED,
  ACTIVITY_CLEAR_ERROR,
  ACTIVITY_CREATE,
  ACTIVITY_CREATE_SUCCEEDED,
  ACTIVITY_CREATE_FAILED,
  ACTIVITY_DELETE,
  ACTIVITY_DELETE_SUCCEEDED,
  ACTIVITY_DELETE_FAILED,
  ACTIVITY_FETCH,
  ACTIVITY_FETCH_SUCCEEDED,
  ACTIVITY_FETCH_FAILED,
  ACTIVITY_UPDATE,
  ACTIVITY_UPDATE_SUCCEEDED,
  ACTIVITY_UPDATE_FAILED,
};
