import { types } from 'store/modules/statusSummary';

const summaryFetch = () => ({
  type: types.SUMMARY_FETCH,
});

const summaryFetchSucceeded = ({ summaryData }) => ({
  type: types.SUMMARY_FETCH_SUCCEEDED,
  payload: summaryData,
});

const summaryFetchFailed = ({ message }) => ({
  type: types.SUMMARY_FETCH_FAILED,
  payload: message,
});

export default {
  summaryFetch,
  summaryFetchSucceeded,
  summaryFetchFailed,
};
