import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import styled from 'styled-components';

import TranslationsContext from 'providers/translationsProvider';
import queryString from 'utils/queryString';

import { Button, Link } from 'components/elements';

function ToolNextStepButton(props) {
  const { linkTo, onClick, execSum, disabled } = props;
  const { t } = useContext(TranslationsContext);

  const getEmbed = () => {
    const queryParams = queryString.parse(window.location.search);
    return queryParams.embed;
  };
  const isEmbed = getEmbed();
  if (isEmbed) return null;

  const to = disabled ? '' : `../${linkTo}`;

  return (
    <StyledDiv>
      <Link to={to}>
        <Button icon={<CheckOutlined />} type="primary" onClick={onClick} disabled={disabled}>
          {execSum ? t('Next Section') : t('Next step')}
        </Button>
      </Link>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  text-align: right;
  margin-top: 1rem;
  @media (max-width: ${props => props.theme.screenSmMin}) {
    text-align: left;
  }
`;

ToolNextStepButton.propTypes = {
  linkTo: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  execSum: PropTypes.bool,
  disabled: PropTypes.bool,
};

ToolNextStepButton.defaultProps = {
  execSum: false,
  onClick: undefined,
  disabled: false,
};

export default ToolNextStepButton;
