import api from '../api';

const fetchRiskCategory = ({ id }) => api.get(`api/riskcategories/${id}/`);

const listRiskCategories = query => api.get('/api/riskcategories/', { params: query });

export default {
  fetchRiskCategory,
  listRiskCategories,
};
