import { types } from 'store/modules/member';

const memberFetch = ({ memberData }) => ({
  type: types.MEMBER_FETCH,
  payload: memberData,
});

const memberFetchSucceeded = ({ memberData }) => ({
  type: types.MEMBER_FETCH_SUCCEEDED,
  payload: memberData,
});

const memberFetchFailed = ({ message }) => ({
  type: types.MEMBER_FETCH_FAILED,
  payload: message,
});

const memberEdit = ({ id, ...memberData }, callback) => ({
  type: types.MEMBER_EDIT,
  payload: { id, ...memberData },
  callback,
});

const memberEditSucceeded = data => ({
  type: types.MEMBER_EDIT_SUCCEEDED,
  payload: data,
});

const memberEditFailed = ({ message }) => ({
  type: types.MEMBER_EDIT_FAILED,
  payload: message,
});

export default {
  memberFetch,
  memberFetchSucceeded,
  memberFetchFailed,
  memberEdit,
  memberEditSucceeded,
  memberEditFailed,
};
