import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import MoonshotGoal from 'models/GrowthPlan/MoonshotGoal';
import serviceMoonshotGoal from 'services/GrowthPlan/moonshotGoal';
import types from './types';
import actions from './actions';

export function* createMoonshotGoal(action) {
  const { project, ...moonshotGoal } = action.payload;
  try {
    const response = yield call(serviceMoonshotGoal.createMoonshotGoal, {
      project,
      ...moonshotGoal,
    });
    yield put(actions.createMoonshotGoalSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(
      actions.createMoonshotGoalFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteMoonshotGoal(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceMoonshotGoal.deleteMoonshotGoal, { id });
    yield put(actions.deleteMoonshotGoalSucceeded({ project, id }));
  } catch (e) {
    yield put(
      actions.deleteMoonshotGoalFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchMoonshotGoal(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceMoonshotGoal.fetchMoonshotGoal, { id });
    yield put(actions.fetchMoonshotGoalSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(actions.fetchMoonshotGoalFailed({ message: e.message }));
  }
}

export function* listMoonshotGoals(action) {
  const { project } = action.payload;
  try {
    const moonshotGoals = yield call(serviceMoonshotGoal.listMoonshotGoals, {
      project,
    });
    yield put(
      actions.listMoonshotGoalsSucceeded({
        project,
        results: moonshotGoals.data,
      })
    );
  } catch (e) {
    yield put(actions.listMoonshotGoalsFailed({ message: e.message }));
  }
}

export function* updateMoonshotGoal(action) {
  const { id, project, ...updatedMoonshotGoal } = action.payload;
  try {
    const updated = yield call(serviceMoonshotGoal.updateMoonshotGoal, {
      id,
      ...updatedMoonshotGoal,
    });
    yield put(actions.updateMoonshotGoalSucceeded({ project, ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateMoonshotGoalFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === MoonshotGoal.contentType) {
    yield put(
      actions.fetchMoonshotGoal({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* moonshotGoalSaga() {
  yield all([
    takeLatest(types.MOONSHOT_GOAL_CREATE, createMoonshotGoal),
    takeLatest(types.MOONSHOT_GOAL_DELETE, deleteMoonshotGoal),
    takeLatest(types.MOONSHOT_GOAL_FETCH, fetchMoonshotGoal),
    takeLatest(types.MOONSHOT_GOAL_UPDATE, updateMoonshotGoal),
    takeEvery(types.MOONSHOT_GOALS_LIST, listMoonshotGoals),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default moonshotGoalSaga;
