import { call, put, all, takeLatest } from 'redux-saga/effects';
import emailDashboardService from 'services/emailDashboard';
import types from './types';
import actions from './actions';

export function* listMentorEmailDashboard(action) {
  yield put(actions.listMentorEmailDashboardPending());
  try {
    const emails = yield call(emailDashboardService.listEmails, action.payload);
    yield put(actions.listMentorEmailDashboardSucceeded({ emailsData: emails.data }));
  } catch (e) {
    yield put(actions.listMentorEmailDashboardFailed({ message: e.message }));
  }
}

export function* listMentorDetailEmailDashboard(action) {
  yield put(actions.listMentorDetailEmailDashboardPending());
  try {
    const email = yield call(emailDashboardService.listEmailsDetail, action.payload);
    yield put(actions.listMentorDetailEmailDashboardSucceeded({ emailData: email.data }));
  } catch (e) {
    yield put(actions.listMentorDetailEmailDashboardFailed({ message: e.message }));
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.MENTOR_EMAIL_DASHBOARD_LIST, listMentorEmailDashboard),
    takeLatest(types.MENTOR_DETAIL_EMAIL_DASHBOARD_LIST, listMentorDetailEmailDashboard),
  ]);
}

export default authSaga;
