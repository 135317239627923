import { Record } from 'immutable';

const WorkExperienceRecord = Record({
  id: null,
  title: '',
  company: '',
  years: null,
  // months: null
});

class WorkExperience extends WorkExperienceRecord {
  get title() {
    return this.get('title');
  }

  get company() {
    return this.get('company');
  }

  get years() {
    return this.get('years');
  }

  // get months() {
  //   return this.get('months');
  // }
}

export default WorkExperience;
