import { call, put, all, takeLatest } from 'redux-saga/effects';

import serviceEducationExperience from 'services/educationExperience';
import actions from './actions';
import types from './types';

export function* fetchEducationExperience(action) {
  try {
    const educations = yield call(serviceEducationExperience.getEducation, action.payload);
    yield put(
      actions.fetchEducationExperienceSucceeded({
        educationExperienceData: educations.data,
      })
    );
  } catch (e) {
    yield put(
      actions.fetchEducationExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateEducationExperience(action) {
  const {
    payload: { ...educationExperience },
    callback,
  } = action;
  try {
    const updated = yield call(serviceEducationExperience.updateEducationExperience, {
      ...educationExperience,
    });
    yield put(actions.updateEducationExperienceSucceeded(updated.data));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.updateEducationExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* createEducationExperience(action) {
  const {
    payload: { ...educationExperience },
    callback,
  } = action;
  try {
    const created = yield call(serviceEducationExperience.createEducationExperience, {
      ...educationExperience,
    });
    yield put(actions.createEducationExperienceSucceeded(created.data));
    if (callback) callback(created.data);
  } catch (e) {
    yield put(
      actions.createEducationExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteEducationExperience(action) {
  const { payload, callback } = action;
  const experienceId = payload;
  try {
    yield call(serviceEducationExperience.deleteEducationExperience, experienceId);
    yield put(actions.deleteEducationExperienceSucceeded(experienceId));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.deleteEducationExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* educationSaga() {
  yield all([
    takeLatest(types.EDUCATION_EXPERIENCE_REQUEST, fetchEducationExperience),
    takeLatest(types.EDUCATION_EXPERIENCE_UPDATE, updateEducationExperience),
    takeLatest(types.EDUCATION_EXPERIENCE_CREATE, createEducationExperience),
    takeLatest(types.EDUCATION_EXPERIENCE_DELETE, deleteEducationExperience),
  ]);
}

export default educationSaga;
