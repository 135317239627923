const MOONSHOT_GOAL_CLEAR_ERROR = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_CLEAR_ERROR';
const MOONSHOT_GOALS_LIST = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOALS_LIST';
const MOONSHOT_GOALS_LIST_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOALS_LIST_SUCCEEDED';
const MOONSHOT_GOALS_LIST_FAILED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOALS_LIST_FAILED';
const MOONSHOT_GOAL_CREATE = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_CREATE';
const MOONSHOT_GOAL_CREATE_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_CREATE_SUCCEEDED';
const MOONSHOT_GOAL_CREATE_FAILED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_CREATE_FAILED';
const MOONSHOT_GOAL_DELETE = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_DELETE';
const MOONSHOT_GOAL_DELETE_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_DELETE_SUCCEEDED';
const MOONSHOT_GOAL_DELETE_FAILED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_DELETE_FAILED';
const MOONSHOT_GOAL_FETCH = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_FETCH';
const MOONSHOT_GOAL_FETCH_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_FETCH_SUCCEEDED';
const MOONSHOT_GOAL_FETCH_FAILED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_FETCH_FAILED';
const MOONSHOT_GOAL_UPDATE = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_UPDATE';
const MOONSHOT_GOAL_UPDATE_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_UPDATE_SUCCEEDED';
const MOONSHOT_GOAL_UPDATE_FAILED = 'bfb/growthPlan/moonshotGoal/MOONSHOT_GOAL_UPDATE_FAILED';

export default {
  MOONSHOT_GOALS_LIST,
  MOONSHOT_GOALS_LIST_SUCCEEDED,
  MOONSHOT_GOALS_LIST_FAILED,
  MOONSHOT_GOAL_CLEAR_ERROR,
  MOONSHOT_GOAL_CREATE,
  MOONSHOT_GOAL_CREATE_SUCCEEDED,
  MOONSHOT_GOAL_CREATE_FAILED,
  MOONSHOT_GOAL_DELETE,
  MOONSHOT_GOAL_DELETE_SUCCEEDED,
  MOONSHOT_GOAL_DELETE_FAILED,
  MOONSHOT_GOAL_FETCH,
  MOONSHOT_GOAL_FETCH_SUCCEEDED,
  MOONSHOT_GOAL_FETCH_FAILED,
  MOONSHOT_GOAL_UPDATE,
  MOONSHOT_GOAL_UPDATE_SUCCEEDED,
  MOONSHOT_GOAL_UPDATE_FAILED,
};
