import React from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'components/elements';

const RadioGroupItem = props => {
  const {
    className,
    extra,
    helptext,
    infotext,
    initialValue,
    value,
    label,
    name,
    rules,
    options,
    onChange,
    style,
    displayBlock,
    ...rest
  } = props;

  return (
    <Form.Item
      label={label}
      className={className}
      extra={extra}
      helptext={helptext}
      infotext={infotext}
      rules={rules}
      initialValue={initialValue}
      name={name}
      style={style}
      displayBlock={displayBlock}
    >
      <Radio.Group {...rest} value={value} onChange={onChange}>
        {options.map(option => (
          <Radio value={option.value}>{option.label}</Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

RadioGroupItem.propTypes = {
  className: PropTypes.string.isRequired,
  extra: PropTypes.string,
  helptext: PropTypes.string,
  infotext: PropTypes.string,
  initialValue: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  size: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  rules: PropTypes.instanceOf(Array),
  displayBlock: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
};

RadioGroupItem.defaultProps = {
  initialValue: null,
  extra: null,
  size: 'large',
  helptext: null,
  infotext: null,
  onChange: null,
  rules: null,
  displayBlock: false,
  style: null,
};

export default RadioGroupItem;
