import { Record } from 'immutable';
import ContentType from '../ContentType';

const ProductPricingModelRecord = new Record({
  id: null,
  product: null,
  selected: null,
  summary: null,
  pricing_model: null,
  segments: null,
});

class ProductPricingModel extends ProductPricingModelRecord {
  static contentType = ContentType.PRODUCTPRICINGMODEL;
  get id() {
    return this.get('id');
  }
}

export default ProductPricingModel;
