import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/ValueProposition/pain';
import { types as commentTypes } from 'store/modules/comment';
import servicePain from 'services/ValueProposition/pain';
import Pain from 'models/ValueProposition/Pain';

export function* createPain(action) {
  const { project, product, segment, ...pain } = action.payload;
  try {
    const response = yield call(servicePain.createPain, {
      project,
      product,
      segment,
      ...pain,
    });
    yield put(actions.createPainSucceeded({ ...response.data, segment }));
  } catch (e) {
    yield put(
      actions.createPainFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deletePain(action) {
  const { project, product, segment, painId } = action.payload;
  try {
    yield call(servicePain.deletePain, { project, product, segment, painId });
    yield put(actions.deletePainSucceeded({ id: painId, segment }));
  } catch (e) {
    yield put(
      actions.deletePainFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchPain(action) {
  const { project, product, segment, id } = action.payload;
  try {
    const response = yield call(servicePain.fetchPain, {
      project,
      product,
      segment,
      id,
    });
    yield put(actions.fetchPainSucceeded({ ...response.data, segment }));
  } catch (e) {
    yield put(actions.fetchPainFailed({ message: e.message }));
  }
}

export function* listPains(action) {
  const { project, product, segment } = action.payload;
  try {
    const pains = yield call(servicePain.listPains, {
      project,
      product,
      segment,
    });
    yield put(actions.listPainsSucceeded({ results: pains.data, segment }));
  } catch (e) {
    yield put(actions.listPainsFailed({ message: e.message }));
  }
}

export function* updatePain(action) {
  const { project, product, segment, painId, ...pain } = action.payload;
  try {
    const updated = yield call(servicePain.updatePain, {
      project,
      product,
      segment,
      painId,
      ...pain,
    });
    yield put(actions.updatePainSucceeded({ ...updated.data, segment }));
  } catch (e) {
    yield put(
      actions.updatePainFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Pain.contentType) {
    yield put(
      actions.fetchPain({
        project: object.get('project'),
        product: object.get('product'),
        segment: object.get('segment'),
        id: object.get('id'),
      })
    );
  }
}

function* painSaga() {
  yield all([
    takeLatest(types.PAIN_CREATE, createPain),
    takeLatest(types.PAIN_DELETE, deletePain),
    takeLatest(types.PAIN_FETCH, fetchPain),
    takeLatest(types.PAIN_UPDATE, updatePain),
    takeEvery(types.PAINS_LIST, listPains),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default painSaga;
