import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/PillsToolkit';
import pillsToolkitService from 'services/pillsToolkit';

export function* fetchPillsToolkitSaga(action) {
  try {
    const response = yield call(pillsToolkitService.listPillsToolkit, action.payload);
    yield put(actions.fetchPillsToolkitSucceeded(response.data));
  } catch (e) {
    yield put(actions.fetchPillsToolkitFailed({ message: e.message }));
  }
}

function* pillsToolkitSaga() {
  yield all([takeLatest(types.PILLS_TOOLKIT_FETCH, fetchPillsToolkitSaga)]);
}

export default pillsToolkitSaga;
