const PROJECT_LINKS_LIST = 'bfb/projectLink/PROJECT_LINKS_LIST';
const PROJECT_LINKS_LIST_SUCCEEDED = 'bfb/projectLink/PROJECT_LINKS_LIST_SUCCEEDED';
const PROJECT_LINKS_LIST_FAILED = 'bfb/projectLink/PROJECT_LINKS_LIST_FAILED';
const PROJECT_LINK_UPDATE_REQUEST = 'bfb/projectLink/PROJECT_LINK_UPDATE_REQUEST';
const PROJECT_LINK_UPDATE_SUCCEEDED = 'bfb/projectLink/PROJECT_LINK_UPDATE_SUCCEEDED';
const PROJECT_LINK_UPDATE_FAILED = 'bfb/projectLink/PROJECT_LINK_UPDATE_FAILED';

export default {
  PROJECT_LINKS_LIST,
  PROJECT_LINKS_LIST_SUCCEEDED,
  PROJECT_LINKS_LIST_FAILED,
  PROJECT_LINK_UPDATE_REQUEST,
  PROJECT_LINK_UPDATE_SUCCEEDED,
  PROJECT_LINK_UPDATE_FAILED,
};
