import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Product from 'models/Product';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PRODUCTS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, product) => ({
                ...obj,
                [product.id]: new Product(product),
              }),
              {}
            ),
          },
        })
      );
    case types.PRODUCT_FETCH_SUCCEEDED:
    case types.PRODUCT_CREATE_SUCCEEDED:
    case types.PRODUCT_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new Product(action.payload)),
          },
        })
      );
    case types.PRODUCT_FETCH:
    case types.PRODUCT_CREATE:
    case types.PRODUCT_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PRODUCT_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
          },
        })
      );
    case types.PRODUCT_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.PRODUCT_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.PRODUCT_FETCH_FAILED:
    case types.PRODUCTS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
