import { createSelector } from 'reselect';

const getState = state => state.relationships;

const getRawRelationships = createSelector([getState], state => state.get('data'));

const getRelationshipsById = createSelector([getRawRelationships], state => state.get('byId'));

const getRelationshipsBySystem = createSelector([getRawRelationships], state =>
  state.get('bySystem')
);

const getRelationships = createSelector([getRelationshipsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getRelationships,
  getRelationshipsById,
  getRelationshipsBySystem,
  getErrorMessage,
};
