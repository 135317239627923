const CUSTOMIZATION_FETCH = 'bfb/customization/CUSTOMIZATION_FETCH';
const CUSTOMIZATION_FETCH_SUCCEEDED = 'bfb/customization/CUSTOMIZATION_FETCH_SUCCEEDED';
const CUSTOMIZATION_FETCH_FAILED = 'bfb/customization/CUSTOMIZATION_FETCH_FAILED';
const CUSTOMIZATION_UPDATE = 'bfb/customization/CUSTOMIZATION_UPDATE';
const CUSTOMIZATION_UPDATE_SUCCEEDED = 'bfb/customization/CUSTOMIZATION_UPDATE_SUCCEEDED';
const CUSTOMIZATION_UPDATE_FAILED = 'bfb/customization/CUSTOMIZATION_UPDATE_FAILED';

export default {
  CUSTOMIZATION_FETCH,
  CUSTOMIZATION_FETCH_SUCCEEDED,
  CUSTOMIZATION_FETCH_FAILED,
  CUSTOMIZATION_UPDATE,
  CUSTOMIZATION_UPDATE_SUCCEEDED,
  CUSTOMIZATION_UPDATE_FAILED,
};
