import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H1 } from 'components/fontSystem';

const Header = props => {
  const { title, className } = props;
  return (
    <div className={className}>
      <H1 $mb="20px">{title}</H1>
      {props.children}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const StyledHeader = styled(Header)`
  background-image: none;
  border-radius: 4px;
  color: white;
  background-color: ${({ theme }) => theme.primaryColor};
  margin: 0 0 20px;
  padding: 30px;

  > * {
    color: white;
  }
`;

export default StyledHeader;
