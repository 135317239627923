import types from './types';

const clearError = () => ({
  type: types.CHANNEL_CLEAR_ERROR,
});

const createChannel = ({ new_chain, ...channel }) => ({
  //eslint-disable-line
  type: types.CHANNEL_CREATE,
  payload: { chain: new_chain, new_chain, ...channel },
});

const createChannelSucceeded = ({ product, id, ...channel }) => ({
  type: types.CHANNEL_CREATE_SUCCEEDED,
  payload: { product, id, ...channel },
});

const createChannelFailed = ({ message }) => ({
  type: types.CHANNEL_CREATE_FAILED,
  payload: { message },
});

const deleteChannel = ({ chain, id }) => ({
  type: types.CHANNEL_DELETE,
  payload: { chain, id },
});

const deleteChannelSucceeded = ({ chain, id }) => ({
  type: types.CHANNEL_DELETE_SUCCEEDED,
  payload: { chain, id },
});

const deleteChannelFailed = ({ message }) => ({
  type: types.CHANNEL_DELETE_FAILED,
  payload: message,
});

const fetchChannel = ({ project, product, id }) => ({
  type: types.CHANNEL_FETCH,
  payload: { project, product, id },
});

const fetchChannelSucceeded = ({ new_chain, ...channel }) => ({
  //eslint-disable-line
  type: types.CHANNEL_FETCH_SUCCEEDED,
  payload: { chain: new_chain, new_chain, ...channel },
});

const fetchChannelFailed = ({ message }) => ({
  type: types.CHANNEL_FETCH_FAILED,
  payload: { message },
});

const listChannels = ({ project, product }) => ({
  type: types.CHANNELS_LIST,
  payload: { project, product },
});

const listChannelsSucceeded = payload => ({
  type: types.CHANNELS_LIST_SUCCEEDED,
  payload,
});

const listChannelsFailed = ({ message }) => ({
  type: types.CHANNELS_LIST_FAILED,
  payload: { message },
});

const updateChannel = ({ project, product, new_chain, id, ...updatedChannel }) => ({
  //eslint-disable-line
  type: types.CHANNEL_UPDATE,
  payload: {
    project,
    product,
    chain: new_chain,
    new_chain,
    id,
    ...updatedChannel,
  },
});

const updateChannelSucceeded = ({ ...channel }) => ({
  type: types.CHANNEL_UPDATE_SUCCEEDED,
  payload: { ...channel },
});

const updateChannelFailed = ({ message }) => ({
  type: types.CHANNEL_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createChannel,
  createChannelFailed,
  createChannelSucceeded,
  deleteChannel,
  deleteChannelFailed,
  deleteChannelSucceeded,
  fetchChannel,
  fetchChannelFailed,
  fetchChannelSucceeded,
  listChannels,
  listChannelsFailed,
  listChannelsSucceeded,
  updateChannel,
  updateChannelFailed,
  updateChannelSucceeded,
};
