const currencies = [
  { code: 'USD', prefix: '$' },
  { code: 'EUR', suffix: ' €' },
  { code: 'GBP', prefix: '£' },
  { code: 'AED', prefix: 'د.إ' },
  { code: 'AFN', suffix: '؋' },
  { code: 'ALL', prefix: 'L' },
  { code: 'AMD', prefix: 'Դ' },
  { code: 'ANG', prefix: 'ƒ' },
  { code: 'AOA', prefix: 'Kz' },
  { code: 'ARS', prefix: 'ARS$' },
  { code: 'AUD', prefix: 'A$' },
  { code: 'AWG', prefix: 'ƒ' },
  { code: 'BAM', prefix: 'КМ' },
  { code: 'BBD', prefix: 'Bds$' },
  { code: 'BDT', prefix: '৳' },
  { code: 'BGN', prefix: 'лв' },
  { code: 'BHD', prefix: '.د.ب' },
  { code: 'BIF', prefix: 'FBu' },
  { code: 'BMD', prefix: 'BD$' },
  { code: 'BND', prefix: 'B$' },
  { code: 'BOB', prefix: 'Bs.' },
  { code: 'BOV', prefix: 'Bs.' },
  { code: 'BRL', prefix: 'R$' },
  { code: 'BSD', prefix: 'B$' },
  { code: 'BTN', prefix: 'Nu.' },
  { code: 'BWP', prefix: 'P' },
  { code: 'BYN', prefix: 'Br' },
  { code: 'BYR', prefix: 'Br' },
  { code: 'BZD', prefix: 'BZ$' },
  { code: 'CAD', prefix: 'C$' },
  { code: 'CDF', prefix: 'C₣' },
  { code: 'CHE', prefix: 'CHE' },
  { code: 'CHF', prefix: 'Fr.' },
  { code: 'CHW', prefix: 'CHW' },
  { code: 'CLF', prefix: 'UF' },
  { code: 'CLP', prefix: 'CLP$' },
  { code: 'CNY', prefix: '¥' },
  { code: 'COP', prefix: 'COL$' },
  { code: 'COU', prefix: 'COU' },
  { code: 'CRC', prefix: '₡' },
  { code: 'CUC', prefix: 'CUC$' },
  { code: 'CUP', prefix: '$MN' },
  { code: 'CVE', prefix: 'Esc' },
  { code: 'CZK', suffix: ' Kč' },
  { code: 'DJF', prefix: 'D₣' },
  { code: 'DKK', suffix: ' Dkr' },
  { code: 'DOP', prefix: 'RD$' },
  { code: 'DZD', prefix: 'دج' },
  { code: 'EGP', prefix: 'ج.م.' },
  { code: 'ERN', prefix: 'Nfk' },
  { code: 'ETB', prefix: 'Br' },
  { code: 'FJD', prefix: 'FJ$' },
  { code: 'FKP', prefix: 'FK£' },
  { code: 'GEL', prefix: 'ლ' },
  { code: 'GHS', prefix: '₵' },
  { code: 'GIP', prefix: 'GIP£' },
  { code: 'GMD', prefix: 'D' },
  { code: 'GNF', prefix: 'G₣' },
  { code: 'GTQ', prefix: 'Q' },
  { code: 'GYD', prefix: 'G$' },
  { code: 'HKD', prefix: 'HK$' },
  { code: 'HNL', prefix: 'L' },
  { code: 'HRK', suffix: ' kn' },
  { code: 'HTG', prefix: 'G' },
  { code: 'HUF', prefix: 'Ft' },
  { code: 'IDR', prefix: 'Rp' },
  { code: 'ILS', prefix: '₪' },
  { code: 'IMP', prefix: 'IM£' },
  { code: 'INR', prefix: '₹' },
  { code: 'IQD', prefix: 'ع.د' },
  { code: 'IRR', prefix: 'ریال' },
  { code: 'ISK', suffix: ' Íkr' },
  { code: 'JMD', prefix: 'J$' },
  { code: 'JOD', prefix: 'JD' },
  { code: 'JPY', prefix: '¥' },
  { code: 'KES', prefix: 'Ksh' },
  { code: 'KGS', prefix: 'лв' },
  { code: 'KHR', prefix: '៛' },
  { code: 'KMF', prefix: 'C₣' },
  { code: 'KPW', prefix: '₩' },
  { code: 'KRW', prefix: '₩' },
  { code: 'KWD', prefix: 'د.ك' },
  { code: 'KYD', prefix: 'CI$' },
  { code: 'LAK', prefix: '₭' },
  { code: 'LBP', prefix: 'LL' },
  { code: 'LKR', prefix: '₨' },
  { code: 'LRD', prefix: 'LD$' },
  { code: 'LSL', prefix: 'M' },
  { code: 'LTL', prefix: 'Lt' },
  { code: 'LVL', prefix: 'Ls' },
  { code: 'LYD', prefix: 'ل.د' },
  { code: 'MAD', prefix: 'د.م.' },
  { code: 'MGA', prefix: 'Ar' },
  { code: 'MKD', prefix: 'ден' },
  { code: 'MMK', prefix: 'K' },
  { code: 'MNT', prefix: '₮' },
  { code: 'MOP', prefix: 'MOP$' },
  { code: 'MRO', prefix: 'UM' },
  { code: 'MUR', prefix: '₨' },
  { code: 'MVR', prefix: 'Rf.' },
  { code: 'MWK', prefix: 'MK' },
  { code: 'MXN', prefix: 'Mex$' },
  { code: 'MXV', prefix: 'UDI' },
  { code: 'MYR', prefix: 'RM' },
  { code: 'MZN', prefix: 'MT' },
  { code: 'NAD', prefix: 'N$' },
  { code: 'NGN', prefix: '₦' },
  { code: 'NIO', prefix: 'C$' },
  { code: 'NOK', prefix: 'kr ' },
  { code: 'NPR', prefix: '₨' },
  { code: 'NZD', prefix: 'NZ$' },
  { code: 'OMR', prefix: 'ر.ع.' },
  { code: 'PAB', prefix: 'B/.' },
  { code: 'PEN', prefix: 'S/.' },
  { code: 'PGK', prefix: 'K' },
  { code: 'PHP', prefix: '₱' },
  { code: 'PKR', prefix: '₨' },
  { code: 'PLN', suffix: ' zł' },
  { code: 'PYG', prefix: '₲' },
  { code: 'QAR', prefix: 'ر.ق' },
  { code: 'RSD', prefix: 'RSD ' },
  { code: 'RUB', suffix: ' руб.' },
  { code: 'RWF', prefix: 'FRw' },
  { code: 'SAR', prefix: 'ر.س' },
  { code: 'SBD', prefix: 'SI$' },
  { code: 'SCR', prefix: 'SRe' },
  { code: 'SDG', prefix: 'S£' },
  { code: 'SEK', prefix: 'kr ' },
  { code: 'SGD', prefix: 'S$' },
  { code: 'SHP', prefix: 'SH£' },
  { code: 'SLL', prefix: 'Le' },
  { code: 'SOS', prefix: 'Sh.So.' },
  { code: 'SRD', prefix: 'SRD$' },
  { code: 'SSP', prefix: '£' },
  { code: 'STD', prefix: 'Db' },
  { code: 'SVC', prefix: '₡' },
  { code: 'SYP', prefix: '£S' },
  { code: 'SZL', prefix: 'E' },
  { code: 'THB', prefix: '฿' },
  { code: 'TMT', prefix: 'm' },
  { code: 'TND', prefix: 'د.ت' },
  { code: 'TOP', prefix: 'TOP$' },
  { code: 'TRY', prefix: '₺' },
  { code: 'TTD', prefix: 'TT$' },
  { code: 'TVD', prefix: '$T' },
  { code: 'TWD', prefix: 'NT$' },
  { code: 'UAH', prefix: '₴' },
  { code: 'UGX', prefix: 'USh' },
  { code: 'USN', prefix: 'USN' },
  { code: 'UYI', prefix: '$U' },
  { code: 'UYU', prefix: '$U' },
  { code: 'VEF', prefix: 'Bs.' },
  { code: 'VND', prefix: '₫' },
  { code: 'VUV', prefix: 'VT' },
  { code: 'WST', prefix: 'WS$' },
  { code: 'XAF', prefix: 'FCFA' },
  { code: 'XCD', prefix: 'EC$' },
  { code: 'XDR', prefix: 'SDR' },
  { code: 'XOF', prefix: 'CFA' },
  { code: 'XSU', prefix: 'Sucre' },
  { code: 'XUA', prefix: 'XUA' },
  { code: 'XXX', prefix: 'XXX' },
  { code: 'ZAR', prefix: 'R' },
  { code: 'ZMK', prefix: 'ZK' },
  { code: 'ZMW', prefix: 'ZK' },
  { code: 'ZWL', prefix: 'Z$' },
];

const currenciesByCode = currencies.reduce(
  (obj, currency) => ({
    ...obj,
    [currency.code]: currency,
  }),
  {}
);

const formatCurrency = (currency, value) =>
  `${currency.prefix || ''}${value || ''}${currency.suffix || ''}`;

export default currencies;
export { formatCurrency, currenciesByCode };
