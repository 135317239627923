import { Record } from 'immutable';

const OutcomeRecord = new Record({
  id: null,
  contentType: 'api.outcome',
  name: '',
  selected: false,
  segment: null,
  importance: null,
  dissatisfaction: null,
  category: null,
  comments: null,
});

class Outcome extends OutcomeRecord {
  static FUNCTIONAL = 0;
  static PERSONAL = 1;
  static SOCIAL = 2;

  static contentType = 'api.outcome';

  get id() {
    return this.get('id');
  }
}

export default Outcome;
