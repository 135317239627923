const MEMBER_EXECUTIVE_SUMMARY_LIST = 'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_LIST';
const MEMBER_EXECUTIVE_SUMMARY_LIST_SUCCEEDED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_LIST_SUCCEEDED';
const MEMBER_EXECUTIVE_SUMMARY_LIST_FAILED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_LIST_FAILED';
const MEMBER_EXECUTIVE_SUMMARY_UPDATE = 'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_UPDATE';
const MEMBER_EXECUTIVE_SUMMARY_UPLOAD_IMAGE =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_UPLOAD_IMAGE';
const MEMBER_EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED';
const MEMBER_EXECUTIVE_SUMMARY_UPDATE_FAILED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_UPDATE_FAILED';
const MEMBER_EXECUTIVE_SUMMARY_CREATE = 'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_CREATE';
const MEMBER_EXECUTIVE_SUMMARY_CREATE_SUCCEEDED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_CREATE_SUCCEEDED';
const MEMBER_EXECUTIVE_SUMMARY_CREATE_FAILED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_CREATE_FAILED';
const MEMBER_EXECUTIVE_SUMMARY_DELETE = 'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_DELETE';
const MEMBER_EXECUTIVE_SUMMARY_DELETE_SUCCEEDED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_DELETE_SUCCEEDED';
const MEMBER_EXECUTIVE_SUMMARY_DELETE_FAILED =
  'bfb/executive-summary/MEMBER_EXECUTIVE_SUMMARY_DELETE_FAILED';

export default {
  MEMBER_EXECUTIVE_SUMMARY_LIST_FAILED,
  MEMBER_EXECUTIVE_SUMMARY_LIST_SUCCEEDED,
  MEMBER_EXECUTIVE_SUMMARY_LIST,
  MEMBER_EXECUTIVE_SUMMARY_UPDATE_FAILED,
  MEMBER_EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED,
  MEMBER_EXECUTIVE_SUMMARY_UPDATE,
  MEMBER_EXECUTIVE_SUMMARY_UPLOAD_IMAGE,
  MEMBER_EXECUTIVE_SUMMARY_CREATE_FAILED,
  MEMBER_EXECUTIVE_SUMMARY_CREATE_SUCCEEDED,
  MEMBER_EXECUTIVE_SUMMARY_CREATE,
  MEMBER_EXECUTIVE_SUMMARY_DELETE_FAILED,
  MEMBER_EXECUTIVE_SUMMARY_DELETE_SUCCEEDED,
  MEMBER_EXECUTIVE_SUMMARY_DELETE,
};
