import unitsSoldTypes from '../financialProduct/types';
import monthlyMonthlyUnitsSoldTypes from './types';

const updateMonthlyUnitsSold = ({ scenario, ...updatedMonthlyUnitsSold }, callback) => ({
  type: monthlyMonthlyUnitsSoldTypes.MONTHLY_UNITS_SOLD_UPDATE,
  payload: { scenario, ...updatedMonthlyUnitsSold },
  callback,
});

const updateMonthlyUnitsSoldSucceeded = payload => ({
  type: unitsSoldTypes.MONTHLY_UNITS_SOLD_UPDATE_SUCCEEDED,
  payload,
});

const updateMonthlyUnitsSoldFailed = ({ message }) => ({
  type: unitsSoldTypes.MONTHLY_UNITS_SOLD_UPDATE_FAILED,
  payload: { message },
});

export default {
  updateMonthlyUnitsSold,
  updateMonthlyUnitsSoldFailed,
  updateMonthlyUnitsSoldSucceeded,
};
