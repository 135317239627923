import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const entrepreneursApi = createApi({
  reducerPath: 'entrepreneurApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/members/',
  }),
  tagTypes: ['Entrepreneur'],
  endpoints: builder => ({
    // Edit an entrepreneur
    updateEntrepreneur: builder.mutation({
      query: entrepreneurData => ({
        url: `${entrepreneurData.id}/`,
        method: 'PATCH',
        data: entrepreneurData,
        invalidatesTags: ['Entrepreneur'],
      }),
    }),
    // Get an entrepreneur by id
    getEntrepreneur: builder.query({
      query: entrepreneurId => entrepreneurId,
      providesTags: ['Entrepreneur'],
    }),
    // Get a public entrepreneur by id
    getPublicEntrepreneur: builder.query({
      query: entrepreneurId => `public_members/${entrepreneurId}/`,
      providesTags: ['Entrepreneur'],
    }),
  }),
});

export const {
  useUpdateEntrepreneurMutation,
  useGetEntrepreneurQuery,
  useGetPublicEntrepreneurQuery,
} = entrepreneursApi;
