// Libraries
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Dependencies
import CommentsRefContext from 'providers/commentsHighlightProvider/context';

// Components
import { Div } from 'components/elements';
import ToolCommentIcon from '../ToolCommentIcon';
import ToolContainer from '../ToolContainer/index';
import ToolDeleteIcon from '../ToolIcon/ToolDeleteIcon';
import ToolSelectIcon from '../ToolSelectIcon/index';

class ToolOutputSelectComponent extends Component {
  static propTypes = {
    children: PropTypes.node,
    colorIndex: PropTypes.number,
    content: PropTypes.node,
    disabled: PropTypes.bool,
    empty: PropTypes.bool,
    highlightComments: PropTypes.bool,
    onCommentsClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onDisabledSelectClick: PropTypes.func,
    onSelectClick: PropTypes.func,
    selected: PropTypes.bool,
    showComments: PropTypes.bool,
    showDelete: PropTypes.bool,
    disableSelectIcon: PropTypes.bool,
    showSelect: PropTypes.bool,
    star: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    colorIndex: undefined,
    content: null,
    empty: false,
    highlightComments: true,
    onCommentsClick: null,
    onDeleteClick: undefined,
    onDisabledSelectClick: null,
    onSelectClick: null,
    selected: false,
    disabled: false, // disabled prop disables the whole container
    star: false,
    showComments: true,
    showDelete: false,
    disableSelectIcon: false, // disableSelectIcon prop disables the ONLY the icon
    showSelect: true,
  };

  static contextType = CommentsRefContext;

  constructor(props) {
    super(props);
    this.myCommentIconId = `tooltip-comment-icon-id-${new Date().getTime()}`;
  }

  handleCommentClick = () => {
    const { setActiveNodeRect } = this.context;
    const { onCommentsClick } = this.props;
    if (onCommentsClick) onCommentsClick();
    const rect = document.getElementById(this.myCommentIconId).getBoundingClientRect();
    if (setActiveNodeRect) {
      setActiveNodeRect(rect, this.myCommentIconId);
    }
  };

  handleOnSelect = () => {
    const { disabled, onSelectClick, onDisabledSelectClick, disableSelectIcon } = this.props;
    if (disabled || disableSelectIcon) {
      return onDisabledSelectClick && onDisabledSelectClick();
    }
    return onSelectClick && onSelectClick();
  };

  render() {
    const {
      children,
      colorIndex,
      highlightComments,
      selected,
      disabled,
      star,
      showComments,
      showSelect,
      content,
      empty,
      showDelete,
      onDeleteClick,
      ...rest
    } = this.props;
    return (
      <ToolContainer
        colorIndex={colorIndex}
        selected={selected}
        disabled={disabled}
        content={content}
        empty={empty}
        {...rest}
      >
        {showSelect ? (
          <aside className="select-input">
            <ToolSelectIcon
              empty={empty}
              star={star}
              selected={selected}
              onSelectClick={this.handleOnSelect}
            />
          </aside>
        ) : null}
        <div className="center-column" role="button" onClick={this.handleOnSelect}>
          <Div className="center-children" colorIndex={colorIndex} $semibold={selected}>
            {children}
          </Div>
        </div>
        {/* ICONS */}
        {(showComments || showDelete) && (
          <aside className="icon-row">
            {showComments ? (
              <i className="comment-icon" id={this.myCommentIconId}>
                <ToolCommentIcon active={highlightComments} onClick={this.handleCommentClick} />
              </i>
            ) : null}
            {showDelete ? <ToolDeleteIcon onClick={onDeleteClick} colorIndex={colorIndex} /> : null}
          </aside>
        )}
      </ToolContainer>
    );
  }
}

const containerColor = ({ colorIndex, theme, useTheme }) => {
  if (useTheme) return theme.primaryColor;
  return colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#D9D9D9';
};

const ToolOutputSelect = styled(ToolOutputSelectComponent)`
  &&& {
    padding: 6px;
    padding-left: 10px;
    background-color: ${({ colorIndex, theme }) =>
      colorIndex ? `${theme.getToolColor(colorIndex)}1A` : 'white'};
    .center-column {
      ${({ onSelectClick }) => onSelectClick && 'cursor: pointer'};
      flex: 1;
    }

    .select-input {
      margin-right: 10px;
      > * {
        color: ${containerColor};
        cursor: pointer;
        font-size: 25px;
        vertical-align: bottom;
      }
    }

    .center-children {
      ${props => props.selected && props.selectedBackground && `color: ${containerColor(props)};`}
      font-size: 15px;
      line-height: 26px;
    }

    .icon-row {
      align-items: center;
      display: flex;
      line-height: 1;
      margin-right: 10px;
      max-height: 33px;
      & > * {
        color: ${({ theme }) => theme.greyColor};
        font-size: 18px;
        margin: 0 3px;
        transition: all 0.3s ease;
        vertical-align: middle;
        &.active,
        &:hover {
          color: ${({ theme }) => theme.secondaryColor};
        }
        &:first-child {
          margin-left: 6px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    ${({ disableSelectIcon }) =>
      disableSelectIcon &&
      css`
        .select-input {
          cursor: not-allowed;
          * {
            cursor: not-allowed;
          }
        }
      `}
  }
`;

export default ToolOutputSelect;
