import types from './types';

const fetchProject = ({ id }) => ({
  type: types.PROJECT_FETCH,
  payload: { id },
});

const fetchProjectSucceeded = ({ id, ...project }) => ({
  type: types.PROJECT_FETCH_SUCCEEDED,
  payload: { id, ...project },
});

const fetchProjectFailed = ({ id }) => ({
  type: types.PROJECT_FETCH_FAILED,
  payload: { id },
});

const listProjects = payload => {
  if (payload) {
    const { id, offset, ordering, limit = 10, ...rest } = payload;
    return {
      type: types.PROJECTS_LIST,
      payload: { id, offset, ordering, limit, ...rest },
    };
  }
  return {
    type: types.PROJECTS_LIST,
  };
};

const currentProject = payload => ({
  type: types.CURRENT_PROJECT,
  payload,
});

const listOrganizationProjects = ({ organization, program, ...query }) => ({
  type: types.PROJECTS_LIST,
  payload: { organization, program, ...query },
});

const listProjectsPending = () => ({
  type: types.PROJECTS_LIST_PENDING,
});

const listProjectsSucceeded = payload => ({
  type: types.PROJECTS_LIST_SUCCEEDED,
  payload,
});

const listProjectsFailed = ({ message }) => ({
  type: types.PROJECTS_LIST_FAILED,
  payload: { message },
});

const updateProject = ({ id, ...project }, callback) => ({
  type: types.PROJECT_UPDATE,
  payload: { id, ...project },
  callback,
});

const updateProjectSucceeded = ({ id, ...project }) => ({
  type: types.PROJECT_UPDATE_SUCCEEDED,
  payload: { id, ...project },
});

const updateProjectFailed = ({ message }) => ({
  type: types.PROJECT_UPDATE_FAILED,
  payload: message,
});

const fetchSelectedProject = ({ id }, callback) => ({
  type: types.SELECTED_PROJECT_FETCH,
  payload: { id },
  callback,
});

const fetchSelectedProjectSucceeded = project => ({
  type: types.SELECTED_PROJECT_FETCH_SUCCEEDED,
  payload: project,
});

const fetchSelectedProjectFailed = ({ message }) => ({
  type: types.SELECTED_PROJECT_FETCH_FAILED,
  payload: { message },
});

const clearSelectedProject = () => ({
  type: types.SELECTED_PROJECT_CLEAR,
});

const clearError = () => ({
  type: types.PROJECT_CLEAR_ERROR,
});

export default {
  fetchProject,
  fetchProjectSucceeded,
  fetchProjectFailed,
  updateProject,
  updateProjectSucceeded,
  updateProjectFailed,
  listOrganizationProjects,
  listProjects,
  listProjectsFailed,
  listProjectsSucceeded,
  fetchSelectedProject,
  fetchSelectedProjectSucceeded,
  fetchSelectedProjectFailed,
  clearSelectedProject,
  clearError,
  currentProject,
  listProjectsPending,
};
