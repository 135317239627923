import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Organization from 'models/Organization';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ORGANIZATIONS_LIST_SUCCEEDED:
      return (() => {
        const organizations = action.payload.reduce(
          (data, organization) => data.set(String(organization.id), new Organization(organization)),
          state.get('data').get('byId')
        );
        return state.merge(
          reduxHelper.applyRequestInfo({
            data: state.get('data').set('byId', organizations),
          })
        );
      })();
    case types.ORGANIZATION_FETCH_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(String(action.payload.id), new Organization(action.payload)),
          },
        })
      );
    case types.ORGANIZATION_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.ORGANIZATIONS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
