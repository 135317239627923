import api from '../api';

const createRole = ({ ...role }) => api.post('/api/roles/', { ...role });

const deleteRole = ({ id }) => api.delete(`/api/roles/${id}/`);

const fetchRole = ({ id }) => api.get(`/api/roles/${id}/`);

const listRoles = ({ system }) => api.get(`/api/roles/?system=${system}`);

const updateRole = ({ id, ...role }) => api.patch(`/api/roles/${id}/`, { ...role });

export default {
  createRole,
  deleteRole,
  fetchRole,
  listRoles,
  updateRole,
};
