import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, css } from 'styled-components';
import { ExclamationOutlined } from '@ant-design/icons';

import { Popconfirm as AntPopconfirm } from 'antd';
import { zIndex } from 'components/elements/Drawer';

const OVERLAY_CLASSNAME = 'poponfirm-overlay';

const Popconfirm = props => (
  <>
    <AntPopconfirm
      overlayClassName={OVERLAY_CLASSNAME}
      icon={<ExclamationOutlined />}
      placement="bottomRight"
      {...props}
    />
    <PopupStyle />
  </>
);

const PopupStyle = createGlobalStyle`
  .${OVERLAY_CLASSNAME} {
    z-index: ${zIndex + 1};
    max-width: 350px;

    .ant-popover-message-title {
      padding-left: 34px;
      & > p {
        margin-bottom: 0;
      }
    }

    .ant-popconfirm-message-icon .anticon.anticon-exclamation {
      background-color: ${({ theme }) => theme.warning};
      color: white;
      border-radius: 100%;
      padding: 5px;
    }

    .ant-btn-primary {
      background-color: ${({ theme }) => theme.primaryColor};
      color: white;
    }

    .ant-btn-default{
      box-shadow: none;
    }

    .ant-btn-sm {
      height: 35px;
      border: none;
      padding-inline: 10px;
    }

    .ant-popover-message {
      padding: 4px 0 0px;
    }
    
    .ant-popover-buttons {
      ${props =>
        props.commentConfirm &&
        css`
          margin-bottom: 4px;
          display: flex;
          justify-content: flex-end;
          text-align: right;
          margin-top: 10px;
        `}
    }

    .ant-popover-buttons button {
      ${props =>
        props.commentConfirm &&
        css`
          margin-left: 20px;
          font-weight: 600;
        `}
    }
  }
`;

Popconfirm.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
};

Popconfirm.defaultProps = {
  children: null,
  text: '',
};

export default Popconfirm;
