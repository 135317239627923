import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import serviceOutcome from 'services/ImpactPlan/outcome';
import Outcome from 'models/ImpactPlan/Outcome';
import { types as commentTypes } from 'store/modules/comment';
import types from './types';
import actions from './actions';

export function* createOutcome(action) {
  const { project, ...outcome } = action.payload;
  try {
    const response = yield call(serviceOutcome.createOutcome, {
      project,
      ...outcome,
    });
    yield put(actions.createOutcomesucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.createOutcomeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteOutcome(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceOutcome.deleteOutcome, { project, id });
    yield put(actions.deleteOutcomesSucceeded({ id }));
  } catch (e) {
    yield put(
      actions.deleteOutcomeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchOutcome(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceOutcome.fetchOutcome, { project, id });
    yield put(actions.fetchOutcomesucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchOutcomeFailed({ message: e.message }));
  }
}

export function* listOutcomes(action) {
  const { project } = action.payload;
  try {
    // project_sdg__project fetches outcomes by project
    const outcomes = yield call(serviceOutcome.listOutcomes, {
      project_sdg__project: project,
    });
    yield put(actions.listOutcomesSucceeded({ results: outcomes.data }));
  } catch (e) {
    yield put(actions.listOutcomesFailed({ message: e.message }));
  }
}

export function* updateOutcome(action) {
  const { project, id, ...outcome } = action.payload;
  try {
    const updated = yield call(serviceOutcome.updateOutcome, {
      project,
      id,
      ...outcome,
    });
    yield put(actions.updateOutcomesucceeded({ ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateOutcomeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Outcome.contentType) {
    yield put(
      actions.fetchOutcome({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* saga() {
  yield all([
    takeLatest(types.OUTCOME_CREATE, createOutcome),
    takeLatest(types.OUTCOME_DELETE, deleteOutcome),
    takeLatest(types.OUTCOME_FETCH, fetchOutcome),
    takeLatest(types.OUTCOME_UPDATE, updateOutcome),
    takeEvery(types.OUTCOMES_LIST, listOutcomes),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default saga;
