import { call, put, all, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/PricingBusinessViability/retentionActivity';
import serviceRetentionActivity from 'services/PricingBusinessViability/retentionActivity';

export function* listRetentionActivities() {
  try {
    const retentionActivities = yield call(serviceRetentionActivity.listRetentionActivities);
    yield put(
      actions.listRetentionActivitiesSucceeded({
        results: retentionActivities.data,
      })
    );
  } catch (e) {
    yield put(actions.listRetentionActivitiesFailed({ message: e.message }));
  }
}

export function* createRetentionActivity(action) {
  const { ...retentionActivity } = action.payload;
  try {
    const response = yield call(serviceRetentionActivity.createRetentionActivity, {
      ...retentionActivity,
    });
    yield put(actions.createRetentionActivitySucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.createRetentionActivityFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateRetentionActivity(action) {
  const { ...retentionActivity } = action.payload;
  try {
    const updated = yield call(serviceRetentionActivity.updateRetentionActivity, {
      ...retentionActivity,
    });
    yield put(actions.updateRetentionActivitySucceeded({ ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateRetentionActivityFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteRetentionActivity(action) {
  const { retentionActivityId } = action.payload;
  try {
    yield call(serviceRetentionActivity.deleteRetentionActivity, {
      retentionActivityId,
    });
    yield put(actions.deleteRetentionActivitySucceeded({ retentionActivityId }));
  } catch (e) {
    yield put(
      actions.deleteRetentionActivityFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* pricingModelSaga() {
  yield all([
    takeEvery(types.RETENTION_ACTIVITY_CREATE, createRetentionActivity),
    takeEvery(types.RETENTION_ACTIVITY_DELETE, deleteRetentionActivity),
    takeEvery(types.RETENTION_ACTIVITY_UPDATE, updateRetentionActivity),
    takeEvery(types.RETENTION_ACTIVITIES_LIST, listRetentionActivities),
  ]);
}

export default pricingModelSaga;
