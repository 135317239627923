import { createSelector } from 'reselect';

const getState = state => state.positioning;

const getRawPositioning = createSelector([getState], state => state.get('data'));

const getPositioningsById = createSelector([getRawPositioning], state => state.get('byId'));

const getPositioningsByCompetitor = createSelector([getRawPositioning], state =>
  state.get('byCompetitor')
);

const getPositionings = createSelector([getPositioningsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getPositionings,
  getPositioningsById,
  getPositioningsByCompetitor,
  getErrorMessage,
};
