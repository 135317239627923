import toolPalete from './toolColors';
import b4bTheme from './b4b-theme';
import variables from './variables';

function getToolColor(index = 0, offset = 0) {
  const position = (index + offset) % toolPalete.length;
  return toolPalete[position];
}

function createTheme(theme) {
  return {
    getToolColor,
    ...variables,
    ...theme,
  };
}

const mergeTheme = (base, customization) => ({
  ...base,
  primaryColor: customization.primary_color || base.primaryColor,
  secondaryColor: customization.secondary_color || base.primaryColor,
});

const memberb4bTheme = {
  primaryColor: b4bTheme.memberGreen,
  primaryColorHover: b4bTheme.memberGreenHover,
  primaryColorFade: b4bTheme.memberGreenFade,
  primaryColorBgFade: b4bTheme.memberGreenBgFade,
};
const mentorb4bTheme = {
  primaryColor: b4bTheme.mentorBlue,
  primaryColorHover: b4bTheme.mentorBlueHover,
  primaryColorFade: b4bTheme.mentorBlueFade,
  primaryColorBgFade: b4bTheme.mentorBlueBgFade,
};

const parrotb4bTheme = {
  primaryColor: b4bTheme.parrotPink,
  primaryColorHover: b4bTheme.parrotPinkHover,
  primaryColorFade: b4bTheme.parrotPinkFade,
  primaryColorBgFade: b4bTheme.parrotPinkBgFade,
};

const parrotChatTheme = {
  primaryColor: b4bTheme.primaryWaitlistColor,
  primaryColorHover: b4bTheme.primaryWaitlistColorBg,
  primaryColorFade: b4bTheme.primaryWaitlistColorBg,
  primaryColorBgFade: b4bTheme.primaryWaitlistColorBg,
};

const errorb4bTheme = {
  primaryColor: b4bTheme.error,
  primaryColorHover: b4bTheme.errorFade,
  primaryColorFade: b4bTheme.errorFade,
  primaryColorBgFade: b4bTheme.errorFade,
};

const TYPE_MAIN = 'main';
const TYPE_MEMBER = 'member';
const TYPE_MENTOR = 'mentor';
const TYPE_PARROT = 'parrot';
const TYPE_PARROBOT_CHAT = 'parrobot_chat';
const TYPE_ERROR = 'error';

const mainTheme = { ...createTheme(b4bTheme), type: TYPE_MAIN };
const memberTheme = { ...createTheme(memberb4bTheme), type: TYPE_MEMBER };
const mentorTheme = { ...createTheme(mentorb4bTheme), type: TYPE_MENTOR };
const parrotTheme = { ...createTheme(parrotb4bTheme), type: TYPE_PARROT };
const parrobotChatTheme = { ...createTheme(parrotChatTheme), type: TYPE_PARROBOT_CHAT };
const errorTheme = { ...createTheme(errorb4bTheme), type: TYPE_ERROR };

export {
  getToolColor,
  mainTheme,
  memberTheme,
  mentorTheme,
  mergeTheme,
  parrotTheme,
  parrobotChatTheme,
  errorTheme,
  TYPE_MAIN,
  TYPE_MEMBER,
  TYPE_MENTOR,
};
