import types from './types';

const listProgramMembers = ({ organizationId, programId, ...query }) => ({
  type: types.PROGRAM_MEMBERS_LIST,
  payload: { organizationId, programId, ...query },
});

const listProgramMembersSucceeded = (programMembers, count) => ({
  type: types.PROGRAM_MEMBERS_LIST_SUCCEEDED,
  payload: {
    programMembers,
    count,
  },
});

const listProgramMembersFailed = ({ message }) => ({
  type: types.PROGRAM_MEMBERS_LIST_FAILED,
  payload: { message },
});

export default {
  listProgramMembers,
  listProgramMembersSucceeded,
  listProgramMembersFailed,
};
