import { call, put, all, takeLatest } from 'redux-saga/effects';
import serviceDeepDive from 'services/deepdive';
import { normalizeMaterials } from 'store/normalizers/deepDiveNormalizer';
import types from './types';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import { actions as linkActions } from './link';

export function* deepdiveList(action) {
  try {
    const response = yield call(serviceDeepDive.getDeepDive, action.payload);
    const { materials, links } = normalizeMaterials(response.data.results);
    yield put(
      actions.deepdiveListSucceeded({
        ...action.payload,
        stepSlug: action.payload.slug,
        results: materials,
        count: response.data.count,
      })
    );
    yield put(linkActions.listDeepDiveLinksSucceeded({ results: links }));
  } catch (e) {
    yield put(actions.deepdiveListFailed({ message: e.message }));
  }
}

export function* createDeepDive(action) {
  try {
    const response = yield call(serviceDeepDive.createDeepDive, action.payload);
    yield put(
      actions.createDeepDiveSucceeded({
        ...response.data,
        program: action.payload.program,
      })
    );
    if (action.callback) {
      yield call(action.callback, response.data.id);
    }
  } catch (e) {
    yield put(
      actions.createDeepDiveFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* editDeepDive(action) {
  try {
    const response = yield call(serviceDeepDive.editDeepDive, action.payload);
    yield put(actions.editDeepDiveSucceeded(response.data));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      actions.createDeepDiveFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteDeepDive(action) {
  try {
    yield call(serviceDeepDive.deleteDeepDive, action.payload);
    yield put(actions.deleteDeepDiveSucceeded(action.payload));
    if (action.callback) yield call(action.callback);
  } catch (e) {
    yield put(
      actions.deleteDeepDiveFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* deepdiveSaga() {
  yield all([
    takeLatest(types.DEEPDIVE_LIST, deepdiveList),
    takeLatest(types.DEEPDIVE_CREATE, createDeepDive),
    takeLatest(types.DEEPDIVE_EDIT, editDeepDive),
    takeLatest(types.DEEPDIVE_DELETE, deleteDeepDive),
  ]);
}
export default deepdiveSaga;
