import types from './types';

const clearError = () => ({
  type: types.OUTCOME_CLEAR_ERROR,
});

const createOutcome = ({ project, product, segment, ...outcome }) => ({
  type: types.OUTCOME_CREATE,
  payload: { project, product, segment, ...outcome },
});

const createOutcomeSucceeded = ({ ...outcome }) => ({
  type: types.OUTCOME_CREATE_SUCCEEDED,
  payload: { ...outcome },
});

const createOutcomeFailed = ({ message }) => ({
  type: types.OUTCOME_CREATE_FAILED,
  payload: { message },
});

const deleteOutcome = ({ project, product, segment, outcomeId }) => ({
  type: types.OUTCOME_DELETE,
  payload: { project, product, segment, outcomeId },
});

const deleteOutcomeSucceeded = payload => ({
  type: types.OUTCOME_DELETE_SUCCEEDED,
  payload,
});

const deleteOutcomeFailed = ({ message }) => ({
  type: types.OUTCOME_DELETE_FAILED,
  payload: message,
});

const fetchOutcome = ({ project, product, segment, id }) => ({
  type: types.OUTCOME_FETCH,
  payload: { project, product, segment, id },
});

const fetchOutcomeSucceeded = ({ ...outcome }) => ({
  type: types.OUTCOME_FETCH_SUCCEEDED,
  payload: { ...outcome },
});

const fetchOutcomeFailed = ({ message }) => ({
  type: types.OUTCOME_FETCH_FAILED,
  payload: { message },
});

const listOutcomes = ({ project, product, segment }) => ({
  type: types.OUTCOMES_LIST,
  payload: { project, product, segment },
});

const listOutcomesSucceeded = payload => ({
  type: types.OUTCOMES_LIST_SUCCEEDED,
  payload,
});

const listOutcomesFailed = ({ message }) => ({
  type: types.OUTCOMES_LIST_FAILED,
  payload: { message },
});

const updateOutcome = ({ project, product, segment, outcomeId, ...outcome }) => ({
  type: types.OUTCOME_UPDATE,
  payload: { project, product, segment, outcomeId, ...outcome },
});

const updateOutcomeSucceeded = ({ ...outcome }) => ({
  type: types.OUTCOME_UPDATE_SUCCEEDED,
  payload: { ...outcome },
});

const updateOutcomeFailed = ({ message }) => ({
  type: types.OUTCOME_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createOutcome,
  createOutcomeFailed,
  createOutcomeSucceeded,
  deleteOutcome,
  deleteOutcomeFailed,
  deleteOutcomeSucceeded,
  fetchOutcome,
  fetchOutcomeFailed,
  fetchOutcomeSucceeded,
  listOutcomes,
  listOutcomesFailed,
  listOutcomesSucceeded,
  updateOutcome,
  updateOutcomeFailed,
  updateOutcomeSucceeded,
};
