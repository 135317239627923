import { Record } from 'immutable';
import ContentType from '../ContentType';

const BeneficiaryRecord = new Record({
  id: null,
  contentType: ContentType.PARTNER,
  name: '',
  project: null,
  beneficiary_types: [],
  comments: [],
  selected: false,
});

class Beneficiary extends BeneficiaryRecord {
  static contentType = ContentType.PARTNER;

  get id() {
    return this.get('id');
  }
}

export default Beneficiary;
