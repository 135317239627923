import types from './types';

const fetchMemberInvites = payload => ({
  type: types.FETCH_MEMBER_INVITES,
  payload,
});

const fetchMemberInvitesPending = payload => ({
  type: types.FETCH_MEMBER_INVITES_PENDING,
  payload,
});

const fetchMemberInvitesSucceeded = payload => ({
  type: types.FETCH_MEMBER_INVITES_SUCCEEDED,
  payload,
});

const fetchMemberInvitesFailed = ({ message }) => ({
  type: types.FETCH_MEMBER_INVITES_FAILED,
  payload: { message },
});

export default {
  fetchMemberInvites,
  fetchMemberInvitesPending,
  fetchMemberInvitesSucceeded,
  fetchMemberInvitesFailed,
};
