import { call, put, all, takeLatest } from 'redux-saga/effects';

import serviceMentor from 'services/mentor';
import actions from './actions';
import types from './types';

export function* mentorFetch() {
  try {
    const mentor = yield call(serviceMentor.getMentor);
    yield put(actions.mentorFetch({ userData: mentor.data }));
  } catch (e) {
    yield put(actions.mentorFetchFailed({ message: e.message }));
  }
}

export function* mentorEdit(action) {
  const {
    callback,
    payload: { id, ...mentorData },
  } = action;
  try {
    const response = yield call(serviceMentor.editMentor, {
      id,
      ...mentorData,
    });
    yield put(actions.mentorEditSucceeded(response.data));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.mentorEditFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* publicMentorFetch(action) {
  const { payload } = action;
  try {
    const response = yield call(serviceMentor.getPublicMentor, {
      id: payload.id,
    });
    yield put(actions.mentorPublicFetchSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.mentorPublicFetchFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* mentorSaga() {
  yield all([
    takeLatest(types.MENTOR_FETCH, mentorFetch),
    takeLatest(types.MENTOR_EDIT, mentorEdit),
    takeLatest(types.MENTOR_PUBLIC_FETCH, publicMentorFetch),
  ]);
}

export default mentorSaga;
