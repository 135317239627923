import types from './types';

const listMemberProgramStats = payload => ({
  type: types.MEMBER_PROGRAM_STATS_LIST,
  payload,
});

const listMemberProgramStatsSucceeded = ({ statsData }) => ({
  type: types.MEMBER_PROGRAM_STATS_LIST_SUCCEEDED,
  payload: statsData,
});

const listMemberProgramStatsFailed = ({ message }) => ({
  type: types.MEMBER_PROGRAM_STATS_LIST_FAILED,
  payload: { message },
});

const listMentorProgramStats = payload => ({
  type: types.MENTOR_PROGRAM_STATS_LIST,
  payload,
});

const listMentorProgramStatsSucceeded = ({ statsData }) => ({
  type: types.MENTOR_PROGRAM_STATS_LIST_SUCCEEDED,
  payload: statsData,
});

const listMentorProgramStatsFailed = ({ message }) => ({
  type: types.MENTOR_PROGRAM_STATS_LIST_FAILED,
  payload: { message },
});

export default {
  listMemberProgramStatsSucceeded,
  listMemberProgramStatsFailed,
  listMemberProgramStats,
  listMentorProgramStatsSucceeded,
  listMentorProgramStatsFailed,
  listMentorProgramStats,
};
