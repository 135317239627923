const VALUE_CLEAR_ERROR = 'bfb/growthPlan/value/VALUE_CLEAR_ERROR';
const VALUES_LIST = 'bfb/growthPlan/value/VALUES_LIST';
const VALUES_LIST_SUCCEEDED = 'bfb/growthPlan/value/VALUES_LIST_SUCCEEDED';
const VALUES_LIST_FAILED = 'bfb/growthPlan/value/VALUES_LIST_FAILED';
const VALUE_CREATE = 'bfb/growthPlan/value/VALUE_CREATE';
const VALUE_CREATE_SUCCEEDED = 'bfb/growthPlan/value/VALUE_CREATE_SUCCEEDED';
const VALUE_CREATE_FAILED = 'bfb/growthPlan/value/VALUE_CREATE_FAILED';
const VALUE_DELETE = 'bfb/growthPlan/value/VALUE_DELETE';
const VALUE_DELETE_SUCCEEDED = 'bfb/growthPlan/value/VALUE_DELETE_SUCCEEDED';
const VALUE_DELETE_FAILED = 'bfb/growthPlan/value/VALUE_DELETE_FAILED';
const VALUE_FETCH = 'bfb/growthPlan/value/VALUE_FETCH';
const VALUE_FETCH_SUCCEEDED = 'bfb/growthPlan/value/VALUE_FETCH_SUCCEEDED';
const VALUE_FETCH_FAILED = 'bfb/growthPlan/value/VALUE_FETCH_FAILED';
const VALUE_UPDATE = 'bfb/growthPlan/value/VALUE_UPDATE';
const VALUE_UPDATE_SUCCEEDED = 'bfb/growthPlan/value/VALUE_UPDATE_SUCCEEDED';
const VALUE_UPDATE_FAILED = 'bfb/growthPlan/value/VALUE_UPDATE_FAILED';

export default {
  VALUES_LIST,
  VALUES_LIST_SUCCEEDED,
  VALUES_LIST_FAILED,
  VALUE_CLEAR_ERROR,
  VALUE_CREATE,
  VALUE_CREATE_SUCCEEDED,
  VALUE_CREATE_FAILED,
  VALUE_DELETE,
  VALUE_DELETE_SUCCEEDED,
  VALUE_DELETE_FAILED,
  VALUE_FETCH,
  VALUE_FETCH_SUCCEEDED,
  VALUE_FETCH_FAILED,
  VALUE_UPDATE,
  VALUE_UPDATE_SUCCEEDED,
  VALUE_UPDATE_FAILED,
};
