import { createSelector } from 'reselect';

const getState = state => state.programTools;

const getRawProgramTools = createSelector([getState], state => state.data);

const getProgramToolsById = createSelector([getRawProgramTools], state => state.byId);

const getProgramTools = createSelector([getProgramToolsById], state => Object.values(state));

export default {
  getProgramTools,
};
