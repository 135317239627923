import api from './api';

const getEducation = query => api.get(`/api/education_experiences/?user=${query.user}`);

const updateEducationExperience = educationExperienceData =>
  api.patch(`/api/education_experiences/${educationExperienceData.id}/`, {
    ...educationExperienceData,
  });

const createEducationExperience = educationExperienceData =>
  api.post('/api/education_experiences/', { ...educationExperienceData });

const deleteEducationExperience = educationExperienceId =>
  api.delete(`/api/education_experiences/${educationExperienceId}/`);

export default {
  getEducation,
  updateEducationExperience,
  createEducationExperience,
  deleteEducationExperience,
};
