const USER_LINK_REQUEST = 'bfb/userLink/USER_LINK_REQUEST';
const USER_LINK_SUCCEEDED = 'bfb/userLink/USER_LINK_SUCCEEDED';
const USER_LINK_FAILED = 'bfb/userLink/USER_LINK_FAILED';
const USER_LINK_BULK_EDIT = 'bfb/userLink/USER_LINK_BULK_EDIT';
const USER_LINK_BULK_EDIT_SUCCEEDED = 'bfb/userLink/USER_LINK_BULK_EDIT_SUCCEEDED';
const USER_LINK_BULK_EDIT_FAILED = 'bfb/userLink/USER_LINK_BULK_EDIT_FAILED';

export default {
  USER_LINK_REQUEST,
  USER_LINK_SUCCEEDED,
  USER_LINK_FAILED,
  USER_LINK_BULK_EDIT,
  USER_LINK_BULK_EDIT_SUCCEEDED,
  USER_LINK_BULK_EDIT_FAILED,
};
