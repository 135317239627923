import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';

const Checkbox = ({ onChange, ...rest }) => {
  const handleOnChange = event => {
    const data = { ...event };
    data.target.value = data.target.checked;
    if (onChange) onChange(data);
  };

  return <StyledCheckbox onChange={handleOnChange} {...rest} />;
};

const checkboxStyles = ({ backgroundColor, theme }) => `
  &&& {
    .ant-checkbox-inner {
      border-radius: 4px;
      width: ${props => (props.size === 'small' ? '17.5px' : '25px')};
      height: ${props => (props.size === 'small' ? '17.5px' : '25px')};
    }

    .ant-checkbox {
      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner::after {
          background-color: ${backgroundColor || theme.secondaryColor};
          height: 12px;
          width: 12px;
        }
      }

      &.ant-checkbox-checked {
        &::after {
          visibility: hidden;
        }

        .ant-checkbox-inner {
          background-color: ${backgroundColor || theme.secondaryColor};
          border: none;

          &:after {
            height: 66%;
            left: 21%;
            top: 45%;
            width: 33%;
          }
        }
      }
    }
  }
`;

const StyledCheckbox = styled(AntCheckbox)`
  ${checkboxStyles};
`;

const CheckboxGroup = styled(AntCheckbox.Group)`
  ${checkboxStyles};
`;

Checkbox.propTypes = {
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  onChange: null,
};

Checkbox.Group = CheckboxGroup;

export default Checkbox;
