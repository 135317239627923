import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Relationship from 'models/ImpactPlan/Relationship';
import { byId } from 'store/utils/sortFunctions';

import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, bySystem: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RELATIONSHIPS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, relationship) => ({
                ...obj,
                [relationship.id]: new Relationship(relationship),
              }),
              state.get('data').get('byId')
            ),
            bySystem: state
              .get('data')
              .get('bySystem')
              .set(
                String(action.payload.system),
                fromJS(
                  action.payload.results
                    .map(relationship => new Relationship(relationship))
                    .sort(byId)
                )
              ),
          },
        })
      );
    case types.RELATIONSHIP_FETCH_SUCCEEDED:
    case types.RELATIONSHIP_CREATE_SUCCEEDED:
    case types.RELATIONSHIP_UPDATE_SUCCEEDED:
      return (() => {
        const systemId = String(action.payload.system);
        const systemRelationships = state
          .get('data')
          .get('bySystem')
          .get(systemId, new List())
          .filter(relationship => relationship.id !== action.payload.id)
          .push(new Relationship(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyReceiveInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(String(action.payload.id), new Relationship(action.payload)),
              bySystem: state.get('data').get('bySystem').set(systemId, systemRelationships),
            },
          })
        );
      })();
    case types.RELATIONSHIP_FETCH:
    case types.RELATIONSHIP_CREATE:
    case types.RELATIONSHIP_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.RELATIONSHIP_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(String(action.payload.id)),
            bySystem: state
              .get('data')
              .get('bySystem')
              .set(
                String(action.payload.system),
                state
                  .get('data')
                  .get('bySystem')
                  .get(String(action.payload.system), new List())
                  .filter(relationship => relationship.id !== action.payload.id)
              ),
          },
        })
      );
    case types.RELATIONSHIP_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.RELATIONSHIP_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.RELATIONSHIP_FETCH_FAILED:
    case types.RELATIONSHIPS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
