import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import serviceMonthlyPayroll from 'services/FinancialProjections/monthlyPayroll';
import MonthlyPayroll from 'models/FinancialProjections/MonthlyPayroll';
import { actions, types } from 'store/modules/FinancialProjections/monthlyPayroll';
import { types as commentTypes } from 'store/modules/comment';

export function* updateMonthlyPayroll(action) {
  const { scenario, id, ...updatedMonthlyPayroll } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceMonthlyPayroll.updateMonthlyPayroll, {
      id,
      ...updatedMonthlyPayroll,
    });
    yield put(
      actions.updateMonthlyPayrollSucceeded({
        ...updatedMonthlyPayroll, // To keep the year property.
        ...updated.data, // New data.
        scenario, // And to keep the scenario property.
      })
    );
    // yield put(payrollActions.listPayrolls({ scenario }));
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateMonthlyPayrollFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === MonthlyPayroll.contentType) {
    yield put(
      actions.fetchMonthlyPayroll({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* monthlyPayrollSaga() {
  yield all([
    takeEvery(types.MONTHLY_PAYROLL_UPDATE, updateMonthlyPayroll),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default monthlyPayrollSaga;
