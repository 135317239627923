import { Record } from 'immutable';
import ContentType from '../ContentType';

const KeyResultRecord = new Record({
  id: null,
  comments: [],
  name: null,
  created: null,
  updated: null,
  objective: null,
  contentType: ContentType.KEYRESULT,
});

class KeyResult extends KeyResultRecord {
  static contentType = ContentType.KEYRESULT;

  get id() {
    return this.get('id');
  }
}

export default KeyResult;
