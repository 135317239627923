import React from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { createGlobalStyle } from 'styled-components';

const NOTIFICATION_ID = 'no-connection-message';

const GlobalStyle = createGlobalStyle`
  .${NOTIFICATION_ID} {
    .ant-notification-notice-close{
      display: none;
    }
    .ant-notification-notice-message, .ant-notification-close-x {
      color: ${({ theme }) => theme.primaryColor};
    }
    .ant-notification-notice-message {
      font-size: 15px;
      font-weight: 700;
    }
  }
`;

export default function Notification({ message, t }) {
  const args = {
    className: NOTIFICATION_ID,
    closeIcon: null,
    description: t(message),
    duration: 0,
    key: NOTIFICATION_ID,
    message: t('Oops! Something happened…'),
    onClick: () => window.location.reload(),
  };

  const notificationIsAlreadyOnDOM = document.getElementsByClassName(NOTIFICATION_ID).length > 0;

  if (notificationIsAlreadyOnDOM) notification.destroy(NOTIFICATION_ID);
  notification.warning(args);

  // Returning a function in useEffect is the equivalent to componentWillUnmount
  React.useEffect(() => () => notification.destroy(NOTIFICATION_ID), []);

  return <GlobalStyle />;
}

Notification.propTypes = {
  message: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  message: '',
};
