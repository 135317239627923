// Libraries
import styled from 'styled-components';

// Dependencies
import Member from 'models/Member';
import { languages } from 'utils/translationHandlers';

// Assets
import eWelcome1 from 'static/images/welcome_popup/e-welcome_1-profile.png';
import eWelcome2 from 'static/images/welcome_popup/e-welcome_2-apply.png';
import eWelcome3 from 'static/images/welcome_popup/e-welcome_3-matching.png';
import eWelcome4 from 'static/images/welcome_popup/e-welcome_4-work.png';
import eWelcome5 from 'static/images/welcome_popup/e-welcome_5-vbp.png';

// Components
import RawModal from 'components/elements/Modal';
import { Accordion as Collapse } from 'components/elements';

/**
 * Create Profile constants.
 * @param {Function} t - Translate function.
 */
export const getWelcomeConstants = t => [
  {
    key: 'create',
    title: t('Create your profile'),
    src: eWelcome1,
    disabled: false,
    step: '1',
  },
  {
    key: 'apply',
    title: t('Apply to a program'),
    src: eWelcome2,
    disabled: true,
    step: '2',
  },
  {
    key: 'match',
    title: t('Match with a mentor'),
    src: eWelcome3,
    disabled: true,
    step: '3',
  },
  {
    key: 'work',
    title: t('Work on your venture'),
    src: eWelcome4,
    disabled: true,
    step: '4',
  },
  {
    key: 'celebrate',
    title: t('Get your Business Plan & Celebrate!'),
    src: eWelcome5,
    disabled: true,
    step: '5',
  },
];

export const Modal = styled(RawModal)`
  &&& {
    width: auto !important;
    max-width: 900px !important;

    .ant-modal-header {
      padding-top: 30px;
      padding-left: 30px;
    }

    .ant-modal-close {
      margin: 16px 8px 0 0;
    }

    .ant-modal-content {
      margin: 40px 20px;
      padding: 0px;
      .ant-modal-footer {
        text-align: left;
      }
    }
    .ant-modal-body {
      padding: 35px 30px;
      /* Images grid */
      .ant-row-flex {
        margin-bottom: 30px;
        .image-col {
          padding: 12px;
          display: flex;
          flex-flow: column;
          align-items: center;
          img {
            height: 105px;
            width: 144px;
          }
          h2 {
            font-size: 15px;
            margin: 0px auto 0px;
            text-align: center;
            &.disabled {
              color: ${({ theme: { greyColor } }) => greyColor};
              font-weight: normal;
            }
          }
          /* Using 20% width instead of 16.66%, cause Ant Design sucks 🤷 */
          @media (min-width: 992px) {
            &.ant-col-lg-4 {
              width: 20%;
            }
          }
        }
        .step-col {
          padding: 5px 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          h2 {
            font-size: 15px;
            text-align: left;
            &.disabled {
              color: ${({ theme: { greyColor } }) => greyColor};
              font-weight: normal;
            }
          }
          .step {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            font-weight: 600 !important;
            text-align: center;
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 4px;
            cursor: default;
            color: ${({ theme: { primaryColor } }) => primaryColor};
            background-color: ${({ theme: { primaryColor } }) => primaryColor}1A;
          }
          .step.disabled {
            color: ${({ theme: { greyColor } }) => greyColor};
            background-color: ${({ theme: { greyColor } }) => greyColor}1A;
          }
          /* Using 20% width instead of 16.66%, cause Ant Design sucks 🤷 */
          @media (min-width: 992px) {
            &.ant-col-lg-4 {
              width: 20%;
            }
          }
        }
      }
    }
  }
`;

export const Accordion = styled(Collapse.SeeMore)`
  &&& {
    padding: 0px;
    /* Header title and arrow icon styling */
    .ant-collapse-header {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme: { primaryColor } }) => primaryColor};
      min-height: 90px;
      .see-more {
        color: #ffffff;
        display: block;
        order: -1;
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        margin: 0;
        height: initial;
        padding: 20px 20px 0;
        text-align: center;
      }
      .anticon {
        color: #ffffff;
        position: relative;
        display: block;
        line-height: normal;
        font-size: 20px;
        margin: 10px 0 0;
        padding-bottom: 10px;
        transform: none;
      }
    }
    /* Anticon direction when collapsed or expanded */
    .ant-collapse-item {
      .ant-collapse-header {
        .anticon svg {
          transform: rotate(90deg) !important;
        }
      }
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
        .anticon svg {
          transform: rotate(90deg) !important;
          transform: rotate(270deg) !important;
        }
      }
    }
    /* Footer */
    .ant-collapse-content-box {
      padding: 0;
    }
  }
`;

export const QuestionAccordion = styled(Collapse.SeeMore)`
  &&& {
    padding: 0px;
    /* Header title and arrow icon styling */
    .ant-collapse-item.ant-collapse-item-active {
      .ant-collapse-header {
        .see-more {
          font-weight: bold;
        }
        .anticon {
          color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
    .ant-collapse-header {
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: unset;
      min-height: unset;
      .see-more {
        color: #000000;
        display: block;
        font-size: 15px;
        line-height: 30px;
        font-weight: normal;
        margin: 0;
        height: initial;
        padding: 0;
        text-align: left;
        transition: font-weight ease-in-out 250ms;
      }
      .anticon {
        color: #000000;
        position: relative;
        display: block;
        line-height: normal;
        font-size: 12px;
        padding-bottom: 0;
        margin: 0;
        margin-right: 14px;
        margin-top: 10px;
        transform: none;
        order: -1;
        transition: color ease-in-out 250ms;
      }
    }
    /* Anticon direction when collapsed or expanded */
    .ant-collapse-item {
      .ant-collapse-header {
        .anticon svg {
          transform: rotate(90deg) !important;
        }
      }
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
        .anticon svg {
          transform: rotate(90deg) !important;
          transform: rotate(270deg) !important;
        }
      }
    }
    /* Footer */
    .ant-collapse-content-box {
      padding: 0;
    }
  }
`;

/**
 * Determines if the current user is a member with status of profile incomplete.
 * If true, then a welcome popup will render, otherwise, returns null.
 * @param {object} user - User object.
 * @param {object} member - Member object.
 * @return {boolean}
 */
export function shouldRenderWelcomePopup(user = {}, member = {}) {
  const path = window.location.pathname.split('/');
  let sectionPath = path[path.length - 1];
  if (sectionPath === '') {
    // In case location ends in '/'
    sectionPath = path[path.length - 2];
  }
  switch (true) {
    case user.member && member.status === Member.STATUS_PROFILE_INCOMPLETE:
      return true;
    case user.member && member.status === Member.STATUS_INCUBATING && sectionPath === 'workspace':
      return true;
    default:
      return false;
  }
}

/**
 * Gets the PDF respective to a specific language.
 * @param {String} selectedLanguage - Selected language setting.
 * @param {Object} pdfFilesByLanguages - Object of the PDF files by language key.
 */
function pdfByLanguage(selectedLanguage, { EN, ES, FR, PT }) {
  switch (selectedLanguage) {
    case languages.ES:
      return ES;
    case languages.FR:
      return FR;
    case languages.PT:
      return PT;
    default:
      return EN;
  }
}

/**
 * Static QA copies.
 * @param {Function} t - Translate function.
 * @param {String} selectedLanguage - Selected language setting.
 */
export const getAdditionalInfo = (t, selectedLanguage) => [
  {
    key: '1',
    title: t('The Incubation Program'),
    questions: [
      {
        key: '1.1',
        question: t('What is the incubation program like?'),
        answer: t(
          'Over the 4-month online program you will work through 8 business tools to build your own personal business plan, structure and challenge your ideas, and connect with a personal mentor once a week. You’ll also have access to our founders community for advice and tips, and group calls throughout the program. Get the full Program Description.'
        ),
        hyperlinkText: t('Get the full Program Description.'),
        hyperLink: pdfByLanguage(selectedLanguage, {
          EN: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/HANDBOOK-ENTREPRENEURS-ENG.pdf',
          ES: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/HANDBOOK-ENTREPRENEURS-ESP.pdf',
          FR: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/HANDBOOK-ENTREPRENEURS_FR.pdf',
          PT: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/HANDBOOK-ENTREPRENEURS-PT.pdf',
          HY: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/HANDBOOK-ENTREPRENEURS-HY.pdf',
        }),
      },
      {
        key: '1.2',
        question: t('How do I build my business plan?'),
        answer: t(
          'We believe people learn best by doing, working together to challenge ideas is better than working alone, and that customer discovery is essential to building a good business. With our 8 interactive tools you will go step by step to bring your ideas down to earth, choose the best approach, and tie it all together into a visual PDF business plan. See the tools and business plan.'
        ),
        hyperlinkText: t('See the tools and business plan.'),
        hyperLink: pdfByLanguage(selectedLanguage, {
          EN: 'https://bridgeforbillions.org/business-tools/',
          ES: 'https://bridgeforbillions.org/es/herramientas-interactivas/',
          FR: 'https://bridgeforbillions.org/business-tools/', // same as above (for now)
        }),
      },
      {
        key: '1.3',
        question: t('Is this program for me?'),
        answer: t(
          'We support early stage entrepreneurs from all over the globe that have either: a solid idea they’re passionate about, an MVP/prototype with little business structure, or an early-stage company. It doesn’t matter if you’re working full time, studying, or focused solely on your project, on your own or with a team. All you need is an internet connection and at least 7 hours a week to dedicate to your project.'
        ),
        hyperlinkText: null,
        hyperLink: null,
      },
    ],
  },
  {
    key: '2',
    title: t('Your Personal Mentor'),
    questions: [
      {
        key: '2.1',
        question: t('Who are the mentors?'),
        answer: t(
          'Our 530+ volunteer mentors come from a variety of backgrounds, countries, and sectors. Some are business professionals, others are entrepreneurs themselves, some are professional mentors, others are sector experts… but all of them are trained to the Bridge for Billions methodology and share our passion for helping early stage entrepreneurs.'
        ),
        hyperlinkText: null,
        hyperLink: null,
      },
      {
        key: '2.2',
        question: t('How will I work with my mentor?'),
        answer: t(
          'Your mentor is your guide throughout the program, someone who can understand the ins and outs of your business and, week after week, help you validate your strategy for each critical stage. Mentors support you through collaboration and commenting on the platform as well as video calls once a week. See what mentoring is like at Bridge.'
        ),
        hyperlinkText: t('See what mentoring is like at Bridge.'),
        hyperLink: pdfByLanguage(selectedLanguage, {
          EN: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors/E+Welcome+Popups_Mentorship+(EN).pdf',
          ES: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors/E+Welcome+Popups_Mentorship+(ES).pdf',
          FR: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors/E+Welcome+Popups_Mentorship+(FR).pdf',
          PT: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors/E+Welcome+Popups_Mentorship+(PT).pdf',
          HY: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors/E+Welcome+Popups_Mentorship+(HY).pdf',
        }),
      },
      {
        key: '2.3',
        question: t('How do I choose a mentor match?'),
        answer: t(
          'Once you’re accepted to the program you will enter the “matching” phase. During matching, basic information about you and your project will be accessible to our community of mentors and they will invite you to meet virtually. You can attend as many meetings as you want, but eventually you will have to choose your preferred mentor. Learn how matching works.'
        ),
        hyperlinkText: t('Learn how matching works.'),
        hyperLink: pdfByLanguage(selectedLanguage, {
          EN: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors_matching/E+Welcome+Popups_Mentor+Matching+(EN).pdf',
          ES: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors_matching/E+Welcome+Popups_Mentor+Matching+(ES).pdf',
          FR: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors_matching/E+Welcome+Popups_Mentor+Matching+(FR).pdf',
          PT: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors_matching/E+Welcome+Popups_Mentor+Matching+(PT).pdf',
          HY: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/mentors_matching/E+Welcome+Popups_Mentor+Matching+(HY).pdf',
        }),
      },
    ],
  },
  {
    key: '3',
    title: t('About our pricing policy'),
    questions: [
      /* { This question was disabled due to MKT needs -> Will enable once it can be fully dynamic
        key: '3.1',
        question: t('How much does the program cost?'),
        answer: t('The online incubation program has a fee of $700 (or €600) per venture,
        paid in four monthly installments ($175 or €150 each month for 4 months) starting
        on the first day of incubation. All about our pricing options and financial aid.'),
        hyperLink: pdfByLanguage(selectedLanguage, {
          EN: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/pricing/E+Welcome+Popups_Pricing+(EN).pdf',
          ES: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/pricing/E+Welcome+Popups_Pricing+(ES).pdf',
          FR: 'https://s3.amazonaws.com/assets.bridgeforbillions.org/docs/login/pricing/Financial%2BAid%2BInformation_TheLeap_FR.pdf',
        }),
      }, */
      {
        key: '3.1',
        question: t('Why is there a program fee?'),
        answer: t(
          'Creating and implementing entrepreneurship programs like The Leap costs (a lot of) money – much more than what we charge. We subsidize the cost to entrepreneurs as much as possible, but what’s left, your contribution, is what enables us to provide you with a tailored program, direct support, and a top-notch online platform.'
        ),
        hyperlinkText: null,
        hyperLink: null,
      },
      /* {
        key: '3.2',
        question: t('I heard you have need-based financial aid?'),
        answer: t('That’s right! We allocate a limited number of partially-funded financial
        aid offers that are exclusively given for need-based, economic reasons. This corresponds
        to a reduction in the program fee - the financial aid is not funding. To apply for financial
        aid, there is a separate application process after your regular one and you will be asked
        to provide documentary evidence demonstrating your personal circumstances.'),
        hyperlinkText: null,
        hyperLink: null,
      }, */
    ],
  },
];
