// Libraries
import styled, { css } from 'styled-components';

// Components
import { Tag } from 'antd';

import MemberApplication from 'models/MemberApplication';
import {
  STATUS_INCOMPLETE,
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_READY_TO_MENTOR,
  STATUS_MATCHED,
  STATUS_INCUBATING,
} from 'models/MentorApplication';
import bfbTheme from 'styles/b4b-theme';

const getBackgroundColor = props => {
  const { status } = props;

  switch (status) {
    case MemberApplication.STATUS_IN_PROGRESS:
    case MemberApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return 'rgb(253,184,51, 0.1)';
    case MemberApplication.STATUS_PENDING_DECISION:
    case MemberApplication.STATUS_APPROVED_NO_EMAIL:
      return '#123DC610';
    case MemberApplication.STATUS_MATCHED:
    case MemberApplication.STATUS_INCUBATING:
      return '#37CC821A';
    default:
      return 'rgb(153,153,153, 0.1)';
  }
};

const getBackgroundMentorColor = props => {
  const { status } = props;

  switch (status) {
    case STATUS_INCOMPLETE:
      return 'rgb(253,184,51, 0.1)';
    case STATUS_PENDING:
      return bfbTheme.crimsonBgFade;
    case STATUS_APPROVED:
    case STATUS_READY_TO_MENTOR:
      return '#123DC610';
    case STATUS_MATCHED:
    case STATUS_INCUBATING:
      return '#37CC821A';
    default:
      return 'rgb(153,153,153, 0.1)';
  }
};

const getTextColor = props => {
  const { status } = props;

  switch (status) {
    case MemberApplication.STATUS_IN_PROGRESS:
    case MemberApplication.STATUS_APPROVED_ONBOARDING_SESSION:
      return '#FDB833';
    case MemberApplication.STATUS_PENDING_DECISION:
    case MemberApplication.STATUS_APPROVED_NO_EMAIL:
    case MemberApplication.STATUS_MATCHED:
    case MemberApplication.STATUS_INCUBATING:
      return '#37CC82';
    default:
      return '#999999';
  }
};

const getTextMentorColor = props => {
  const { status } = props;

  switch (status) {
    case STATUS_INCOMPLETE:
      return '#FDB833';
    case STATUS_PENDING:
      return bfbTheme.crimson;
    case STATUS_APPROVED:
    case STATUS_READY_TO_MENTOR:
      return '#123DC6';
    case STATUS_MATCHED:
    case STATUS_INCUBATING:
      return '#37CC82';
    default:
      return '#999999';
  }
};

export const StyledAppMemberStatusTag = styled(Tag)`
  align-items: center;
  background-color: ${getBackgroundColor} !important;
  border-radius: 4px;
  border: ${({ status }) =>
    status === MemberApplication.STATUS_INCUBATING ? '1px solid #37CC82' : 'none'};
  color: ${getTextColor} !important;
  cursor: initial;
  display: inline-flex;
  font-size: 15px;
  padding: ${({ size }) => (size === 'small' ? '0 5px' : '2px 12px')};
  ${({ size }) =>
    size === 'small'
      ? css`
          &&& {
            line-height: 20px;
          }
        `
      : undefined};
  white-space: normal;
  // transition: all ease 300ms;
`;

export const StyledAppMentorStatusTag = styled(Tag)`
  align-items: center;
  background-color: ${getBackgroundMentorColor} !important;
  border-radius: 4px;
  border: ${({ status }) => (status === STATUS_INCUBATING ? '1px solid #37CC82' : 'none')};
  color: ${getTextMentorColor} !important;
  cursor: initial;
  display: inline-flex;
  font-size: 15px;
  padding: ${({ size }) => (size === 'small' ? '0 5px' : '2px 12px')};
  ${({ size }) =>
    size === 'small'
      ? css`
          &&& {
            line-height: 20px;
          }
        `
      : undefined};
  white-space: normal;
  // transition: all ease 300ms;
`;
