import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/ValueProposition/problem';
import { types as commentTypes } from 'store/modules/comment';
import serviceProblem from 'services/ValueProposition/problem';
import Problem from 'models/ValueProposition/Problem';

export function* createProblem(action) {
  const { project, ...problem } = action.payload;
  try {
    const response = yield call(serviceProblem.createProblem, {
      project,
      ...problem,
    });
    yield put(actions.createProblemSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.createProblemFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteProblem(action) {
  const { project, problemId } = action.payload;
  try {
    yield call(serviceProblem.deleteProblem, { project, problemId });
    yield put(actions.deleteProblemSucceeded({ problemId }));
  } catch (e) {
    yield put(
      actions.deleteProblemFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchProblem(action) {
  const { id } = action.payload;
  try {
    const response = yield call(serviceProblem.fetchProblem, { id });
    yield put(actions.fetchProblemSucceeded(response.data));
  } catch (e) {
    yield put(actions.fetchProblemFailed({ message: e.message }));
  }
}

export function* listProblems(action) {
  const { projectId } = action.payload;
  try {
    const problems = yield call(serviceProblem.listProblems, { projectId });
    yield put(actions.listProblemsSucceeded({ results: problems.data }));
  } catch (e) {
    yield put(actions.listProblemsFailed({ message: e.message }));
  }
}

export function* updateProblem(action) {
  const { problemId, ...problem } = action.payload;
  try {
    const updated = yield call(serviceProblem.updateProblem, {
      problemId,
      ...problem,
    });
    yield put(actions.updateProblemSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateProblemFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}
export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Problem.contentType) {
    yield put(actions.fetchProblem({ id: object.get('id') }));
  }
}

export function* problemSaga() {
  yield all([
    takeLatest(types.PROBLEM_CREATE, createProblem),
    takeLatest(types.PROBLEM_DELETE, deleteProblem),
    takeLatest(types.PROBLEM_FETCH, fetchProblem),
    takeLatest(types.PROBLEM_UPDATE, updateProblem),
    takeLatest(types.PROBLEMS_LIST, listProblems),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default problemSaga;
