import api from '../api';

const updateMonthlyCogs = ({ id, ...updatedMonthlyCogs }) =>
  api({
    method: 'patch',
    url: `api/monthly-cogs/${id}/`,
    data: updatedMonthlyCogs,
  });

export default {
  updateMonthlyCogs,
};
