import types from './types';

const listMemberEmailDashboard = payload => ({
  type: types.MEMBER_EMAIL_DASHBOARD_LIST,
  payload,
});

const listMemberEmailDashboardPending = () => ({
  type: types.MEMBER_EMAIL_DASHBOARD_LIST_PENDING,
});

const listMemberEmailDashboardSucceeded = ({ emailsData }) => ({
  type: types.MEMBER_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  payload: emailsData,
});

const listMemberEmailDashboardFailed = ({ message }) => ({
  type: types.MEMBER_EMAIL_DASHBOARD_LIST_FAILED,
  payload: { message },
});

const listMemberDetailEmailDashboard = payload => ({
  type: types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST,
  payload,
});

const listMemberDetailEmailDashboardPending = () => ({
  type: types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_PENDING,
});

const listMemberDetailEmailDashboardSucceeded = ({ emailData }) => ({
  type: types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  payload: emailData,
});

const listMemberDetailEmailDashboardFailed = ({ message }) => ({
  type: types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST_FAILED,
  payload: { message },
});

export default {
  listMemberEmailDashboard,
  listMemberEmailDashboardPending,
  listMemberEmailDashboardSucceeded,
  listMemberEmailDashboardFailed,
  listMemberDetailEmailDashboard,
  listMemberDetailEmailDashboardPending,
  listMemberDetailEmailDashboardSucceeded,
  listMemberDetailEmailDashboardFailed,
};
