const COMPETITOR_CLEAR_ERROR = 'bfb/competitor/COMPETITOR_CLEAR_ERROR';
const COMPETITORS_LIST = 'bfb/competitor/COMPETITORS_LIST';
const COMPETITORS_LIST_SUCCEEDED = 'bfb/competitor/COMPETITORS_LIST_SUCCEEDED';
const COMPETITORS_LIST_FAILED = 'bfb/competitor/COMPETITORS_LIST_FAILED';
const COMPETITOR_CREATE = 'bfb/competitor/COMPETITOR_CREATE';
const COMPETITOR_CREATE_SUCCEEDED = 'bfb/competitor/COMPETITOR_CREATE_SUCCEEDED';
const COMPETITOR_CREATE_FAILED = 'bfb/competitor/COMPETITOR_CREATE_FAILED';
const COMPETITOR_DELETE = 'bfb/competitor/COMPETITOR_DELETE';
const COMPETITOR_DELETE_SUCCEEDED = 'bfb/competitor/COMPETITOR_DELETE_SUCCEEDED';
const COMPETITOR_DELETE_FAILED = 'bfb/competitor/COMPETITOR_DELETE_FAILED';
const COMPETITOR_FETCH = 'bfb/competitor/COMPETITOR_FETCH';
const COMPETITOR_FETCH_SUCCEEDED = 'bfb/competitor/COMPETITOR_FETCH_SUCCEEDED';
const COMPETITOR_FETCH_FAILED = 'bfb/competitor/COMPETITOR_FETCH_FAILED';
const COMPETITOR_UPDATE = 'bfb/competitor/COMPETITOR_UPDATE';
const COMPETITOR_UPDATE_SUCCEEDED = 'bfb/competitor/COMPETITOR_UPDATE_SUCCEEDED';
const COMPETITOR_UPDATE_FAILED = 'bfb/competitor/COMPETITOR_UPDATE_FAILED';

export default {
  COMPETITORS_LIST,
  COMPETITORS_LIST_SUCCEEDED,
  COMPETITORS_LIST_FAILED,
  COMPETITOR_CLEAR_ERROR,
  COMPETITOR_CREATE,
  COMPETITOR_CREATE_SUCCEEDED,
  COMPETITOR_CREATE_FAILED,
  COMPETITOR_DELETE,
  COMPETITOR_DELETE_SUCCEEDED,
  COMPETITOR_DELETE_FAILED,
  COMPETITOR_FETCH,
  COMPETITOR_FETCH_SUCCEEDED,
  COMPETITOR_FETCH_FAILED,
  COMPETITOR_UPDATE,
  COMPETITOR_UPDATE_SUCCEEDED,
  COMPETITOR_UPDATE_FAILED,
};
