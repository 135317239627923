const OUTCOME_CLEAR_ERROR = 'bfb/outcome/OUTCOME_CLEAR_ERROR';
const OUTCOMES_LIST = 'bfb/outcome/OUTCOMES_LIST';
const OUTCOMES_LIST_SUCCEEDED = 'bfb/outcome/OUTCOMES_LIST_SUCCEEDED';
const OUTCOMES_LIST_FAILED = 'bfb/outcome/OUTCOMES_LIST_FAILED';
const OUTCOME_CREATE = 'bfb/outcome/OUTCOME_CREATE';
const OUTCOME_CREATE_SUCCEEDED = 'bfb/outcome/OUTCOME_CREATE_SUCCEEDED';
const OUTCOME_CREATE_FAILED = 'bfb/outcome/OUTCOME_CREATE_FAILED';
const OUTCOME_DELETE = 'bfb/outcome/OUTCOME_DELETE';
const OUTCOME_DELETE_SUCCEEDED = 'bfb/outcome/OUTCOME_DELETE_SUCCEEDED';
const OUTCOME_DELETE_FAILED = 'bfb/outcome/OUTCOME_DELETE_FAILED';
const OUTCOME_FETCH = 'bfb/outcome/OUTCOME_FETCH';
const OUTCOME_FETCH_SUCCEEDED = 'bfb/outcome/OUTCOME_FETCH_SUCCEEDED';
const OUTCOME_FETCH_FAILED = 'bfb/outcome/OUTCOME_FETCH_FAILED';
const OUTCOME_UPDATE = 'bfb/outcome/OUTCOME_UPDATE';
const OUTCOME_UPDATE_SUCCEEDED = 'bfb/outcome/OUTCOME_UPDATE_SUCCEEDED';
const OUTCOME_UPDATE_FAILED = 'bfb/outcome/OUTCOME_UPDATE_FAILED';

export default {
  OUTCOMES_LIST,
  OUTCOMES_LIST_SUCCEEDED,
  OUTCOMES_LIST_FAILED,
  OUTCOME_CLEAR_ERROR,
  OUTCOME_CREATE,
  OUTCOME_CREATE_SUCCEEDED,
  OUTCOME_CREATE_FAILED,
  OUTCOME_DELETE,
  OUTCOME_DELETE_SUCCEEDED,
  OUTCOME_DELETE_FAILED,
  OUTCOME_FETCH,
  OUTCOME_FETCH_SUCCEEDED,
  OUTCOME_FETCH_FAILED,
  OUTCOME_UPDATE,
  OUTCOME_UPDATE_SUCCEEDED,
  OUTCOME_UPDATE_FAILED,
};
