import { call, put, all, takeLatest } from 'redux-saga/effects';
import customizationService from 'services/customization';

import actions from './actions';
import types from './types';

export function* fetchCustomization(action) {
  const { id, program } = action.payload;
  try {
    let response;
    if (id) {
      response = yield call(customizationService.fetchCustomization, { id });
    } else if (program) {
      const customizations = yield call(customizationService.listCustomizations, { program });
      response = { data: { ...customizations.data } };
    }
    yield put(actions.fetchCustomizationSucceeded(response.data));
  } catch (e) {
    yield put(actions.fetchCustomizationFailed({ message: e.message }));
  }
}

export function* updateCustomization(action) {
  const { id, customization } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(customizationService.updateCustomization, {
      id,
      customization,
    });
    yield put(actions.updateCustomizationSucceeded(updated.data));
    if (callback) yield callback();
  } catch (e) {
    yield put(actions.updateCustomizationFailed({ message: e.message }));
  }
}

function* customizationSaga() {
  yield all([
    takeLatest(types.CUSTOMIZATION_FETCH, fetchCustomization),
    takeLatest(types.CUSTOMIZATION_UPDATE, updateCustomization),
  ]);
}

export default customizationSaga;
