import api from './api';

const createProfilePicture = data => {
  const formData = new FormData();
  formData.append('user', data.user);
  formData.append('photo', data.photo);

  return api.post('/api/profile_pictures/', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const getProfilePictureByPictureId = id => api.get(`/api/profile_pictures/${id}`);

const getProfilePicture = query => {
  const user = typeof query.user === 'object' ? query.user.id : query.user;
  return api.get(`/api/profile_pictures/?user=${user}`);
};

const getDefaultPictures = () => api.get('/api/profile_pictures/?defaults=1');

const editProfilePicture = id => api.post(`/api/profile_pictures/${id}`);

export default {
  createProfilePicture,
  getProfilePictureByPictureId,
  getProfilePicture,
  getDefaultPictures,
  editProfilePicture,
};
