import { types } from 'store/modules/areasOfExpertise';

const fetchAreasOfExpertise = query => ({
  type: types.AREASOFEXPERTISE_FETCH,
  payload: query,
});

const fetchAreasOfExpertiseSucceeded = ({ areasOfExpertiseData }) => ({
  type: types.AREASOFEXPERTISE_FETCH_SUCCEEDED,
  payload: areasOfExpertiseData,
});

const fetchAreasOfExpertiseFailed = ({ message }) => ({
  type: types.AREASOFEXPERTISE_FETCH_FAILED,
  payload: message,
});

export default {
  fetchAreasOfExpertise,
  fetchAreasOfExpertiseSucceeded,
  fetchAreasOfExpertiseFailed,
};
