import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const adminDashboardProgramsApi = createApi({
  reducerPath: 'adminDashboardProgramsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/admin-dashboard-programs/',
  }),
  endpoints: builder => ({
    // -- PROGRAMS --
    // List all programs
    getPrograms: builder.query({
      query: () => ({
        url: '',
        params: { limit: 500 }, // TODO: Add filter by organization and remove limit when BE has finished DEV-8090 task
      }),
    }),
    // Create a new program
    createProgram: builder.mutation({
      query: programData => ({
        url: '',
        method: 'POST',
        data: programData,
      }),
    }),
    // Update a program
    updateProgram: builder.mutation({
      query: ({ programId, programData }) => ({
        url: `${programId}/`,
        method: 'PATCH',
        data: programData, // program id, changed fields
      }),
    }),
  }),
});

export const {
  // Programs
  useGetProgramsQuery,
  useCreateProgramMutation,
  useUpdateProgramMutation,
} = adminDashboardProgramsApi;
