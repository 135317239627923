import api from '../api';

const createRelationship = ({ ...relationship }) =>
  api.post('/api/relationships/', { ...relationship });

const deleteRelationship = ({ id }) => api.delete(`/api/relationships/${id}/`);

const fetchRelationship = ({ id }) => api.get(`/api/relationships/${id}/`);

const listRelationships = ({ system }) => api.get(`/api/relationships/?system=${system}`);

const updateRelationship = ({ id, ...relationship }) =>
  api.patch(`/api/relationships/${id}/`, { ...relationship });

export default {
  createRelationship,
  deleteRelationship,
  fetchRelationship,
  listRelationships,
  updateRelationship,
};
