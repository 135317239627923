import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Milestone from 'models/GrowthPlan/Milestone';
import serviceMilestone from 'services/GrowthPlan/milestone';
import types from './types';
import actions from './actions';

export function* createMilestone(action) {
  const { project, ...milestone } = action.payload;
  try {
    const response = yield call(serviceMilestone.createMilestone, {
      project,
      ...milestone,
    });
    yield put(actions.createMilestoneSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(
      actions.createMilestoneFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteMilestone(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceMilestone.deleteMilestone, { id });
    yield put(actions.deleteMilestoneSucceeded({ project, id }));
  } catch (e) {
    yield put(
      actions.deleteMilestoneFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchMilestone(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceMilestone.fetchMilestone, { id });
    yield put(actions.fetchMilestoneSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(actions.fetchMilestoneFailed({ message: e.message }));
  }
}

export function* listMilestones(action) {
  const { project } = action.payload;
  try {
    const milestones = yield call(serviceMilestone.listMilestones, { project });
    yield put(actions.listMilestonesSucceeded({ project, results: milestones.data }));
  } catch (e) {
    yield put(actions.listMilestonesFailed({ message: e.message }));
  }
}

export function* updateMilestone(action) {
  const { id, project, ...updatedMilestone } = action.payload;
  try {
    const updated = yield call(serviceMilestone.updateMilestone, {
      id,
      ...updatedMilestone,
    });
    yield put(actions.updateMilestoneSucceeded({ project, ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateMilestoneFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Milestone.contentType) {
    yield put(
      actions.fetchMilestone({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* milestoneSaga() {
  yield all([
    takeLatest(types.MILESTONE_CREATE, createMilestone),
    takeLatest(types.MILESTONE_DELETE, deleteMilestone),
    takeLatest(types.MILESTONE_FETCH, fetchMilestone),
    takeLatest(types.MILESTONE_UPDATE, updateMilestone),
    takeEvery(types.MILESTONES_LIST, listMilestones),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default milestoneSaga;
