import types from './types';

const clearError = () => ({
  type: types.SEGMENT_CLEAR_ERROR,
});

const createSegment = ({ project, product, ...segment }) => ({
  type: types.SEGMENT_CREATE,
  payload: { project, product, ...segment },
});

const createSegmentSucceeded = ({ product, id, ...segment }) => ({
  type: types.SEGMENT_CREATE_SUCCEEDED,
  payload: { product, id, ...segment },
});

const createSegmentFailed = ({ message }) => ({
  type: types.SEGMENT_CREATE_FAILED,
  payload: { message },
});

const deleteSegment = ({ project, product, segmentId }) => ({
  type: types.SEGMENT_DELETE,
  payload: { project, product, segmentId },
});

const deleteSegmentSucceeded = ({ product, segmentId }) => ({
  type: types.SEGMENT_DELETE_SUCCEEDED,
  payload: { product, id: segmentId },
});

const deleteSegmentFailed = ({ message }) => ({
  type: types.SEGMENT_DELETE_FAILED,
  payload: message,
});

const fetchSegment = ({ project, product, id }) => ({
  type: types.SEGMENT_FETCH,
  payload: { project, product, id },
});

const fetchSegmentSucceeded = ({ ...segment }) => ({
  type: types.SEGMENT_FETCH_SUCCEEDED,
  payload: { ...segment },
});

const fetchSegmentFailed = ({ message }) => ({
  type: types.SEGMENT_FETCH_FAILED,
  payload: { message },
});

const listSegments = ({ project, product }) => ({
  type: types.SEGMENTS_LIST,
  payload: { project, product },
});

const listAllSegments = ({ project }) => ({
  type: types.SEGMENTS_LIST_ALL,
  payload: { project },
});

const listSegmentsSucceeded = payload => ({
  type: types.SEGMENTS_LIST_SUCCEEDED,
  payload,
});

const listSegmentsFailed = ({ message }) => ({
  type: types.SEGMENTS_LIST_FAILED,
  payload: { message },
});

const updateSegment = ({ project, product, segmentId, ...updatedSegment }, callback) => ({
  type: types.SEGMENT_UPDATE,
  payload: { project, product, segmentId, ...updatedSegment },
  callback,
});

const updateSegmentSucceeded = ({ ...segment }) => ({
  type: types.SEGMENT_UPDATE_SUCCEEDED,
  payload: { ...segment },
});

const updateSegmentFailed = ({ message }) => ({
  type: types.SEGMENT_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createSegment,
  createSegmentFailed,
  createSegmentSucceeded,
  deleteSegment,
  deleteSegmentFailed,
  deleteSegmentSucceeded,
  fetchSegment,
  fetchSegmentFailed,
  fetchSegmentSucceeded,
  listSegments,
  listAllSegments,
  listSegmentsFailed,
  listSegmentsSucceeded,
  updateSegment,
  updateSegmentFailed,
  updateSegmentSucceeded,
};
