import types from './types';

const clearError = () => ({
  type: types.BENEFICIARY_CLEAR_ERROR,
});

const createBeneficiary = ({ project, ...beneficiary }) => ({
  type: types.BENEFICIARY_CREATE,
  payload: { project, ...beneficiary },
});

const createBeneficiariesucceeded = ({ ...beneficiary }) => ({
  type: types.BENEFICIARY_CREATE_SUCCEEDED,
  payload: { ...beneficiary },
});

const createBeneficiaryFailed = ({ message }) => ({
  type: types.BENEFICIARY_CREATE_FAILED,
  payload: { message },
});

const deleteBeneficiary = ({ project, beneficiaryId }) => ({
  type: types.BENEFICIARY_DELETE,
  payload: { project, beneficiaryId },
});

const deleteBeneficiariesucceeded = payload => ({
  type: types.BENEFICIARY_DELETE_SUCCEEDED,
  payload,
});

const deleteBeneficiaryFailed = ({ message }) => ({
  type: types.BENEFICIARY_DELETE_FAILED,
  payload: { message },
});

const fetchBeneficiary = ({ project, beneficiaryId }) => ({
  type: types.BENEFICIARY_FETCH,
  payload: { project, beneficiaryId },
});

const fetchBeneficiariesucceeded = ({ ...beneficiary }) => ({
  type: types.BENEFICIARY_FETCH_SUCCEEDED,
  payload: { ...beneficiary },
});

const fetchBeneficiaryFailed = ({ message }) => ({
  type: types.BENEFICIARY_FETCH_FAILED,
  payload: { message },
});

const listBeneficiaries = ({ project }) => ({
  type: types.BENEFICIARIES_LIST,
  payload: { project },
});

const listBeneficiariesSucceeded = payload => ({
  type: types.BENEFICIARIES_LIST_SUCCEEDED,
  payload,
});

const listBeneficiariesFailed = ({ message }) => ({
  type: types.BENEFICIARIES_LIST_FAILED,
  payload: { message },
});

const updateBeneficiary = ({ project, beneficiaryId, ...beneficiary }) => ({
  type: types.BENEFICIARY_UPDATE,
  payload: { project, beneficiaryId, ...beneficiary },
});

const updateBeneficiariesucceeded = ({ ...beneficiary }) => ({
  type: types.BENEFICIARY_UPDATE_SUCCEEDED,
  payload: { ...beneficiary },
});

const updateBeneficiaryFailed = ({ message }) => ({
  type: types.BENEFICIARY_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createBeneficiary,
  createBeneficiaryFailed,
  createBeneficiariesucceeded,
  deleteBeneficiary,
  deleteBeneficiaryFailed,
  deleteBeneficiariesucceeded,
  fetchBeneficiary,
  fetchBeneficiaryFailed,
  fetchBeneficiariesucceeded,
  listBeneficiaries,
  listBeneficiariesFailed,
  listBeneficiariesSucceeded,
  updateBeneficiary,
  updateBeneficiaryFailed,
  updateBeneficiariesucceeded,
};
