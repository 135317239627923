import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/FinancialProjections/financialProduct';
import { types as commentTypes } from 'store/modules/comment';
import serviceFinancialProduct from 'services/FinancialProjections/financialProduct';
import FinancialProduct from 'models/FinancialProjections/FinancialProduct';

export function* createFinancialProduct(action) {
  const { scenario, ...financialProduct } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(serviceFinancialProduct.createFinancialProduct, {
      scenario,
      ...financialProduct,
    });
    yield put(actions.createFinancialProductSucceeded({ ...response.data, scenario }));
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.createFinancialProductFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteFinancialProduct(action) {
  const { scenarioId, financialProductId } = action.payload;
  try {
    yield call(serviceFinancialProduct.deleteFinancialProduct, {
      id: financialProductId,
    });
    yield put(
      actions.deleteFinancialProductSucceeded({
        scenario: scenarioId,
        financialProductId,
      })
    );
  } catch (e) {
    yield put(
      actions.deleteFinancialProductFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchFinancialProduct(action) {
  const { scenario, id } = action.payload;
  try {
    const response = yield call(serviceFinancialProduct.fetchFinancialProduct, {
      scenario,
      id,
    });
    yield put(actions.fetchFinancialProductSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(actions.fetchFinancialProductFailed({ message: e.message }));
  }
}

export function* listFinancialProducts(action) {
  try {
    const financialProducts = yield call(
      serviceFinancialProduct.listFinancialProducts,
      action.payload
    );
    yield put(
      actions.listFinancialProductsSucceeded({
        results: financialProducts.data,
        ...action.payload,
      })
    );
  } catch (e) {
    yield put(actions.listFinancialProductsFailed({ message: e.message }));
  }
}

export function* updateFinancialProduct(action) {
  const { id, ...updatedFinancialProduct } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceFinancialProduct.updateFinancialProduct, {
      id,
      ...updatedFinancialProduct,
    });
    yield put(actions.updateFinancialProductSucceeded({ ...updated.data }));
    if (callback) yield callback();
  } catch (e) {
    yield put(
      actions.updateFinancialProductFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === FinancialProduct.contentType) {
    yield put(
      actions.fetchFinancialProduct({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* financialProductSaga() {
  yield all([
    takeLatest(types.FINANCIAL_PRODUCT_CREATE, createFinancialProduct),
    takeLatest(types.FINANCIAL_PRODUCT_DELETE, deleteFinancialProduct),
    takeLatest(types.FINANCIAL_PRODUCT_FETCH, fetchFinancialProduct),
    takeLatest(types.FINANCIAL_PRODUCT_UPDATE, updateFinancialProduct),
    takeEvery(types.FINANCIAL_PRODUCTS_LIST, listFinancialProducts),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default financialProductSaga;
