import { Record } from 'immutable';
import ContentType from '../ContentType';

const ValueRecord = new Record({
  id: null,
  comments: [],
  contentType: ContentType.VALUE,
  name: null,
  description: null,
  created: null,
  updated: null,
  project: null,
});

class Value extends ValueRecord {
  static contentType = ContentType.VALUE;

  get id() {
    return this.get('id');
  }
}

export default Value;
