import payrollTypes from '../payroll/types';
import monthlyMonthlyPayrollTypes from './types';

const updateMonthlyPayroll = ({ scenario, ...updatedMonthlyPayroll }, callback) => ({
  type: monthlyMonthlyPayrollTypes.MONTHLY_PAYROLL_UPDATE,
  payload: { scenario, ...updatedMonthlyPayroll },
  callback,
});

const updateMonthlyPayrollSucceeded = payload => ({
  type: payrollTypes.MONTHLY_PAYROLL_UPDATE_SUCCEEDED,
  payload,
});

const updateMonthlyPayrollFailed = ({ message }) => ({
  type: payrollTypes.MONTHLY_PAYROLL_UPDATE_FAILED,
  payload: { message },
});

export default {
  updateMonthlyPayroll,
  updateMonthlyPayrollFailed,
  updateMonthlyPayrollSucceeded,
};
