import api from '../api';

const createResource = ({ project, product, partner, ...resource }) =>
  api.post(`/api/projects/${project}/products/${product}/partners/${partner}/resources/`, {
    ...resource,
  });

const deleteResource = ({ project, product, partner, resourceId }) =>
  api.delete(
    `/api/projects/${project}/products/${product}/partners/${partner}/resources/${resourceId}`
  );

const fetchResource = ({ project, product, partner, resourceId }) =>
  api.get(
    `/api/projects/${project}/products/${product}/partners/${partner}/resources/${resourceId}`
  );

const listResources = ({ project, product, partner }) =>
  api.get(`/api/projects/${project}/products/${product}/partners/${partner}/resources`);

const updateResource = ({ project, product, partner, resourceId, ...resource }) =>
  api.patch(
    `/api/projects/${project}/products/${product}/partners/${partner}/resources/${resourceId}/`,
    { ...resource }
  );

export default {
  createResource,
  deleteResource,
  fetchResource,
  listResources,
  updateResource,
};
