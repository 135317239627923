import { Record } from 'immutable';
import ContentType from '../ContentType';

const MemberExecutiveSummaryRecord = Record({
  id: null,
  executive_summary: null,
  name: '',
  photo: null,
  linkedin: '',
  position_title: '',
  description: '',
  created: new Date(),
  updated: new Date(),
});

class MemberExecutiveSummary extends MemberExecutiveSummaryRecord {
  static contentType = ContentType.MEMBEREXECUTIVESUMMARY;
}

export default MemberExecutiveSummary;
