// Move in the same folder as sectors

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const areasOfInnovationApi = createApi({
  reducerPath: 'areasOfInnovationApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/areas_innovation/',
  }),
  endpoints: builder => ({
    // List ALL areas of innovation
    listAreasOfInnovation: builder.query({
      query: query => ({ params: query }),
    }),
  }),
});

export const { useListAreasOfInnovationQuery } = areasOfInnovationApi;
