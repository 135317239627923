import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'components/elements';
import {
  TYPE_INSTAGRAM,
  TYPE_FACEBOOK,
  TYPE_TWITTER,
  TYPE_WEBSITE,
  TYPE_LINKEDIN,
} from 'components/SocialNetworksInput/constants';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GlobalOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';

const IconRow = props => {
  const { className, linkedin, links } = props;
  const iconMap = new Map([
    [TYPE_INSTAGRAM, <InstagramOutlined />],
    [TYPE_FACEBOOK, <FacebookOutlined />],
    [TYPE_TWITTER, <TwitterOutlined />],
    [TYPE_WEBSITE, <GlobalOutlined />],
    [TYPE_LINKEDIN, <LinkedinOutlined />],
  ]);
  const getSocialNetworkUrl = link => {
    const { url, type } = link;
    if (type === TYPE_INSTAGRAM) {
      return 'https://www.instagram.com/' + url.replace('@', ''); //eslint-disable-line
    }
    if (type === TYPE_TWITTER) {
      return 'https://www.twitter.com/' + url.replace('@', ''); //eslint-disable-line
    }
    if ((url && url.includes('http')) || (link && url && url.includes('https'))) {
      return url;
    }
    return 'https://' + url; //eslint-disable-line
  };
  const linkedinExists = links && links.find(link => link.type === TYPE_LINKEDIN);
  return (
    <div className={className}>
      {linkedinExists === undefined && linkedin && linkedin !== '' && (
        <a
          key="linkedin"
          href={
            linkedin.includes('http') || linkedin.includes('https')
              ? linkedin
              : `https://${linkedin}`
          }
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn Profile"
        >
          <Button shape="circle" icon={iconMap.get(1)} type="primary" size="small" />
        </a>
      )}
      {links &&
        links.map(link => (
          <a
            key={link.type}
            href={getSocialNetworkUrl(link)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Social Network Link"
          >
            <Button shape="circle" icon={iconMap.get(link.type)} type="primary" size="small" />
          </a>
        ))}
    </div>
  );
};

const StyledIconRow = styled(IconRow)`
  margin: ${({ noMargin }) => (noMargin ? 0 : '20px 0')};
  button.ant-btn.ant-btn-sm.ant-btn-icon-only {
    margin-right: 10px;
    width: 22px;
    margin-bottom: 5px;
  }
  &&& .anticon {
    font-size: 16px;
  }
`;

IconRow.propTypes = {
  className: PropTypes.string.isRequired,
  links: PropTypes.node,
  linkedin: PropTypes.string,
};

IconRow.defaultProps = {
  linkedin: '',
  links: [],
};

export default StyledIconRow;
