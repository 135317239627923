// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// components
import { Input as DefaultInput } from 'components/elements';

// Dependencies
import InputStyle, { USEBACKGROUND_HORIZONTAL_PADDING } from './common';

function TextArea(props) {
  const { autoSize, onEnter, ...rest } = props;
  const handleOnKeyDown = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      return onEnter && onEnter(event);
    }
    return null;
  };

  return <DefaultInput.TextArea {...rest} onKeyDown={handleOnKeyDown} autoSize={autoSize} />;
}

TextArea.propTypes = {
  autoSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  onEnter: PropTypes.func,
};

TextArea.defaultProps = {
  autoSize: true,
  onEnter: null,
};

const StyledTextArea = styled(TextArea)`
  &&& {
    ${InputStyle}
    margin-bottom: 0;
    min-height: 0;
    overflow: visible !important;
    overflow-y: visible !important;

    ${({ dynamicPadding }) =>
      dynamicPadding &&
      css`
        padding-left: 0;

        &:hover,
        &:focus {
          padding-left: ${USEBACKGROUND_HORIZONTAL_PADDING}px;
        }
      `}

    /* Text counter */
      &.ant-input-textarea-show-count {
      padding: 0;

      textarea {
        padding: 3px 5px;
      }
    }
  }
`;

export default StyledTextArea;
