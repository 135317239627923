const PRICING_MODELS_LIST = 'bfb/PRICING_MODEL/PRICING_MODELS_LIST';
const PRICING_MODELS_LIST_SUCCEEDED = 'bfb/PRICING_MODEL/PRICING_MODELS_LIST_SUCCEEDED';
const PRICING_MODELS_LIST_FAILED = 'bfb/PRICING_MODEL/PRICING_MODELS_LIST_FAILED';

const PRODUCT_PRICING_MODEL_CLEAR_ERROR =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_CLEAR_ERROR';
const PRODUCT_PRICING_MODELS_LIST = 'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODELS_LIST';
const PRODUCT_PRICING_MODELS_LIST_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODELS_LIST_SUCCEEDED';
const PRODUCT_PRICING_MODELS_LIST_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODELS_LIST_FAILED';
const PRODUCT_PRICING_MODEL_CREATE = 'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_CREATE';
const PRODUCT_PRICING_MODEL_CREATE_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_CREATE_SUCCEEDED';
const PRODUCT_PRICING_MODEL_CREATE_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_CREATE_FAILED';
const PRODUCT_PRICING_MODEL_DELETE = 'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_DELETE';
const PRODUCT_PRICING_MODEL_DELETE_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_DELETE_SUCCEEDED';
const PRODUCT_PRICING_MODEL_DELETE_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_DELETE_FAILED';
const PRODUCT_PRICING_MODEL_FETCH = 'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_FETCH';
const PRODUCT_PRICING_MODEL_FETCH_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_FETCH_SUCCEEDED';
const PRODUCT_PRICING_MODEL_FETCH_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_FETCH_FAILED';
const PRODUCT_PRICING_MODEL_UPDATE = 'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_UPDATE';
const PRODUCT_PRICING_MODEL_UPDATE_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_UPDATE_SUCCEEDED';
const PRODUCT_PRICING_MODEL_UPDATE_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/PRODUCT_PRICING_MODEL_UPDATE_FAILED';

export default {
  PRICING_MODELS_LIST,
  PRICING_MODELS_LIST_SUCCEEDED,
  PRICING_MODELS_LIST_FAILED,
  PRODUCT_PRICING_MODELS_LIST,
  PRODUCT_PRICING_MODELS_LIST_SUCCEEDED,
  PRODUCT_PRICING_MODELS_LIST_FAILED,
  PRODUCT_PRICING_MODEL_CLEAR_ERROR,
  PRODUCT_PRICING_MODEL_CREATE,
  PRODUCT_PRICING_MODEL_CREATE_SUCCEEDED,
  PRODUCT_PRICING_MODEL_CREATE_FAILED,
  PRODUCT_PRICING_MODEL_DELETE,
  PRODUCT_PRICING_MODEL_DELETE_SUCCEEDED,
  PRODUCT_PRICING_MODEL_DELETE_FAILED,
  PRODUCT_PRICING_MODEL_FETCH,
  PRODUCT_PRICING_MODEL_FETCH_SUCCEEDED,
  PRODUCT_PRICING_MODEL_FETCH_FAILED,
  PRODUCT_PRICING_MODEL_UPDATE,
  PRODUCT_PRICING_MODEL_UPDATE_SUCCEEDED,
  PRODUCT_PRICING_MODEL_UPDATE_FAILED,
};
