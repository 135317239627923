import { createSelector } from 'reselect';

const getState = state => state.deepDiveLink;

const getData = createSelector([getState], state => state.data);

const getLinksByMaterial = createSelector([getData], state => state.byMaterial);

const getLinksById = createSelector([getData], state => state.byId);
export default {
  getLinksById,
  getLinksByMaterial,
};
