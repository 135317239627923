import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/ImpactPlan/role';
import { types as commentTypes } from 'store/modules/comment';
import serviceRole from 'services/ImpactPlan/role';
import Role from 'models/ImpactPlan/Role';

export function* createRole(action) {
  const { ...role } = action.payload;
  try {
    const response = yield call(serviceRole.createRole, { ...role });
    yield put(actions.createRoleSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.createRoleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteRole(action) {
  const { id, system } = action.payload;
  try {
    yield call(serviceRole.deleteRole, { id });
    yield put(actions.deleteRoleSucceeded({ id, system }));
  } catch (e) {
    yield put(
      actions.deleteRoleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchRole(action) {
  const { id } = action.payload;
  try {
    const role = yield call(serviceRole.fetchRole, { id });
    yield put(actions.fetchRoleSucceeded(role.data));
  } catch (e) {
    yield put(actions.fetchRoleFailed({ message: e.message }));
  }
}

export function* listRoles(action) {
  const { system } = action.payload;
  try {
    const roles = yield call(serviceRole.listRoles, { system });
    yield put(actions.listRolesSucceeded({ results: roles.data, system }));
  } catch (e) {
    yield put(actions.listRolesFailed({ message: e.message }));
  }
}

export function* updateRole(action) {
  const { id, ...role } = action.payload;
  try {
    const updated = yield call(serviceRole.updateRole, { id, ...role });
    yield put(actions.updateRoleSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateRoleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Role.contentType) {
    yield put(
      actions.fetchRole({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* roleSaga() {
  yield all([
    takeLatest(types.ROLE_CREATE, createRole),
    takeLatest(types.ROLE_DELETE, deleteRole),
    takeLatest(types.ROLE_FETCH, fetchRole),
    takeLatest(types.ROLE_UPDATE, updateRole),
    takeEvery(types.ROLES_LIST, listRoles),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default roleSaga;
