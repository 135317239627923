import { normalize, schema } from 'normalizr';

const stepSchema = new schema.Entity('step');
const toolSchema = new schema.Entity('tool', {
  steps: [stepSchema],
});

const normalizeTool = data => {
  // https://redux.js.org/recipes/structuringreducers/normalizingstateshape#relationships-and-tables
  const { result, entities } = normalize(data, toolSchema);
  return {
    tool: entities.tool[result],
    steps: entities.step,
  };
};

const normalizeTools = data => {
  const {
    entities: { tool, step },
  } = normalize(data, [toolSchema]);
  return {
    tools: Object.values(tool),
    steps: Object.values(step),
  };
};

export { normalizeTool, normalizeTools };
