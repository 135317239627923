import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/mentorApplication';
import mentorApplicationService from 'services/mentorApplication';

export function* fetchApplication(action) {
  const { id } = action.payload;
  try {
    const applications = yield call(mentorApplicationService.fetchApplication, {
      id,
    });
    yield put(actions.fetchApplicationSucceeded({ results: applications.data }));
  } catch (e) {
    yield put(actions.fetchApplicationFailed({ message: e.message }));
  }
}

export function* listApplications() {
  try {
    const applications = yield call(mentorApplicationService.listApplications);
    yield put(actions.listApplicationsSucceeded({ applicationsData: applications.data }));
  } catch (e) {
    yield put(actions.listApplicationsFailed({ message: e.message }));
  }
}

export function* createApplication(action) {
  const { mentorId, program, ...application } = action.payload;
  try {
    const response = yield call(mentorApplicationService.createApplication, {
      mentorId,
      program,
      ...application,
    });
    yield put(actions.createApplicationSucceeded(response.data));
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(
      actions.createApplicationFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateApplication(action) {
  const { id, ...application } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(mentorApplicationService.updateApplication, {
      id,
      ...application,
    });
    yield put(actions.updateApplicationSucceeded({ applicationData: updated.data }));
    if (callback) yield callback();
  } catch (e) {
    yield put(
      actions.updateApplicationFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateApplicationBulk(action) {
  const { callback } = action;
  try {
    const updated = yield call(mentorApplicationService.updateApplicationBulk, action.payload);
    yield put(actions.updateApplicationBulkSucceeded(updated.data));
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.updateApplicationBulkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* validateApplicationStatus(action) {
  const { applicationId } = action.payload;
  try {
    const application = yield call(mentorApplicationService.validateApplicationStatus, {
      applicationId,
    });
    yield put(actions.validateApplicationStatusSucceeded(application.data));
  } catch (e) {
    yield put(actions.validateApplicationStatusFailed({ message: e.message }));
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.APPLICATIONS_LIST, listApplications),
    takeLatest(types.APPLICATION_FETCH, fetchApplication),
    takeLatest(types.APPLICATION_CREATE, createApplication),
    takeLatest(types.APPLICATION_UPDATE, updateApplication),
    takeLatest(types.APPLICATION_BULK_UPDATE, updateApplicationBulk),
    takeLatest(types.APPLICATION_STATUS, validateApplicationStatus),
  ]);
}

export default authSaga;
