import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/PricingBusinessViability/pricingModel';
import servicePricingModel from 'services/PricingBusinessViability/pricingModel';

export function* listPricingModels(action) {
  const { product } = action.payload;
  try {
    const pricingModels = yield call(servicePricingModel.listPricingModels, {
      product,
    });
    yield put(
      actions.listPricingModelsSucceeded({
        results: pricingModels.data,
        product,
      })
    );
  } catch (e) {
    yield put(actions.listPricingModelsFailed({ message: e.message }));
  }
}

export function* listProductPricingModels(action) {
  const { product } = action.payload;
  try {
    const pricingModels = yield call(servicePricingModel.listProductPricingModels, { product });
    yield put(
      actions.listProductPricingModelsSucceeded({
        results: pricingModels.data,
        product,
      })
    );
  } catch (e) {
    yield put(actions.listProductPricingModelsFailed({ message: e.message }));
  }
}

export function* fetchProductPricingModel(action) {
  const { product, pricingModelId } = action.payload;
  try {
    const response = yield call(servicePricingModel.fetchProductPricingModel, {
      product,
      pricingModelId,
    });
    yield put(actions.fetchProductPricingModelSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(actions.fetchProductPricingModelFailed({ message: e.message }));
  }
}

export function* createProductPricingModel(action) {
  const { product, selected, ...pricingModel } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(servicePricingModel.createProductPricingModel, {
      product,
      selected,
      ...pricingModel,
    });
    yield put(actions.createProductPricingModelSucceeded({ product, ...response.data }));
    if (callback) yield callback(response.data.id);
  } catch (e) {
    yield put(
      actions.createProductPricingModelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateProductPricingModel(action) {
  const { product, ...pricingModel } = action.payload;
  try {
    const updated = yield call(servicePricingModel.updateProductPricingModel, {
      product,
      ...pricingModel,
    });
    yield put(actions.updateProductPricingModelSucceeded({ ...updated.data, product }));
  } catch (e) {
    yield put(
      actions.updateProductPricingModelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteProductPricingModel(action) {
  const { product, id } = action.payload;
  try {
    yield call(servicePricingModel.deleteProductPricingModel, { id });
    yield put(actions.deleteProductPricingModelSucceeded({ id, product }));
  } catch (e) {
    yield put(
      actions.deleteProductPricingModelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* pricingModelSaga() {
  yield all([
    takeEvery(types.PRODUCT_PRICING_MODEL_CREATE, createProductPricingModel),
    takeEvery(types.PRODUCT_PRICING_MODEL_FETCH, fetchProductPricingModel),
    takeEvery(types.PRODUCT_PRICING_MODEL_UPDATE, updateProductPricingModel),
    takeEvery(types.PRODUCT_PRICING_MODEL_DELETE, deleteProductPricingModel),
    takeEvery(types.PRODUCT_PRICING_MODELS_LIST, listProductPricingModels),
    takeLatest(types.PRICING_MODELS_LIST, listPricingModels),
  ]);
}

export default pricingModelSaga;
