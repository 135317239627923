import types from './types';

const createMemberExecutiveSummary = data => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_CREATE,
  payload: data,
});

const createMemberExecutiveSummarySucceeded = ({ executiveSummaryId, ...memberES }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_CREATE_SUCCEEDED,
  payload: { executiveSummaryId, ...memberES },
});

const createMemberExecutiveSummaryFailed = ({ message }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_CREATE_FAILED,
  payload: { message },
});

const listMembersExecutiveSummary = payload => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_LIST,
  payload,
});

const listMembersExecutiveSummarySucceeded = payload => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_LIST_SUCCEEDED,
  payload,
});

const listMembersExecutiveSummaryFailed = ({ message }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_LIST_FAILED,
  payload: { message },
});

const deleteMemberExecutiveSummary = ({ id }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_DELETE,
  payload: { id },
});

const deleteMemberExecutiveSummarySucceeded = payload => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_DELETE_SUCCEEDED,
  payload,
});

const deleteMemberExecutiveSummaryFailed = ({ message }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_DELETE_FAILED,
  payload: { message },
});

const updateMemberExecutiveSummary = ({ id, ...memberExecutiveSummary }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_UPDATE,
  payload: { id, ...memberExecutiveSummary },
});

const uploadMemberExecutiveSummaryImage = ({ id, ...memberExecutiveSummary }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_UPLOAD_IMAGE,
  payload: { id, ...memberExecutiveSummary },
});

const updateMemberExecutiveSummarySucceeded = payload => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED,
  payload,
});

const updateMemberExecutiveSummaryFailed = ({ message }) => ({
  type: types.MEMBER_EXECUTIVE_SUMMARY_UPDATE_FAILED,
  payload: { message },
});

export default {
  listMembersExecutiveSummarySucceeded,
  listMembersExecutiveSummaryFailed,
  listMembersExecutiveSummary,
  updateMemberExecutiveSummary,
  uploadMemberExecutiveSummaryImage,
  updateMemberExecutiveSummarySucceeded,
  updateMemberExecutiveSummaryFailed,
  deleteMemberExecutiveSummary,
  deleteMemberExecutiveSummarySucceeded,
  deleteMemberExecutiveSummaryFailed,
  createMemberExecutiveSummary,
  createMemberExecutiveSummarySucceeded,
  createMemberExecutiveSummaryFailed,
};
