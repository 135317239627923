const BENEFIT_CLEAR_ERROR = 'bfb/pain/BENEFIT_CLEAR_ERROR';
const BENEFITS_LIST = 'bfb/pain/BENEFITS_LIST';
const BENEFITS_LIST_SUCCEEDED = 'bfb/pain/BENEFITS_LIST_SUCCEEDED';
const BENEFITS_LIST_FAILED = 'bfb/pain/BENEFITS_LIST_FAILED';
const BENEFIT_CREATE = 'bfb/pain/BENEFIT_CREATE';
const BENEFIT_CREATE_SUCCEEDED = 'bfb/pain/BENEFIT_CREATE_SUCCEEDED';
const BENEFIT_CREATE_FAILED = 'bfb/pain/BENEFIT_CREATE_FAILED';
const BENEFIT_DELETE = 'bfb/pain/BENEFIT_DELETE';
const BENEFIT_DELETE_SUCCEEDED = 'bfb/pain/BENEFIT_DELETE_SUCCEEDED';
const BENEFIT_DELETE_FAILED = 'bfb/pain/BENEFIT_DELETE_FAILED';
const BENEFIT_FETCH = 'bfb/pain/BENEFIT_FETCH';
const BENEFIT_FETCH_SUCCEEDED = 'bfb/pain/BENEFIT_FETCH_SUCCEEDED';
const BENEFIT_FETCH_FAILED = 'bfb/pain/BENEFIT_FETCH_FAILED';
const BENEFIT_UPDATE = 'bfb/pain/BENEFIT_UPDATE';
const BENEFIT_UPDATE_SUCCEEDED = 'bfb/pain/BENEFIT_UPDATE_SUCCEEDED';
const BENEFIT_UPDATE_FAILED = 'bfb/pain/BENEFIT_UPDATE_FAILED';

export default {
  BENEFITS_LIST,
  BENEFITS_LIST_SUCCEEDED,
  BENEFITS_LIST_FAILED,
  BENEFIT_CLEAR_ERROR,
  BENEFIT_CREATE,
  BENEFIT_CREATE_SUCCEEDED,
  BENEFIT_CREATE_FAILED,
  BENEFIT_DELETE,
  BENEFIT_DELETE_SUCCEEDED,
  BENEFIT_DELETE_FAILED,
  BENEFIT_FETCH,
  BENEFIT_FETCH_SUCCEEDED,
  BENEFIT_FETCH_FAILED,
  BENEFIT_UPDATE,
  BENEFIT_UPDATE_SUCCEEDED,
  BENEFIT_UPDATE_FAILED,
};
