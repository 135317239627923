import { createSelector } from 'reselect';

const getState = state => state.tool;

const getRawTools = createSelector([getState], state => state.get('data'));

const getToolsById = createSelector([getRawTools], state => state.get('byId'));

const getTools = createSelector([getToolsById], state => state.valueSeq().toJS());

const getToolsSorted = createSelector([getRawTools], state => state.get('sorted'));

const getSortedTools = createSelector([getToolsSorted], state => state.toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getTools,
  getToolsById,
  getSortedTools,
  getErrorMessage,
};
