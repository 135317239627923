import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Channel from 'models/StakeholderMap/Channel';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byChain: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.CHANNELS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, channel) =>
                prevData.set(channel.id.toString(), new Channel(channel)).sort(byId),
              state.get('data').get('byId')
            ),
            byChain: action.payload.results.reduce((prevData, channel) => {
              const chainChannels = prevData.get(channel.new_chain.toString(), new List());
              return prevData.set(
                channel.new_chain.toString(),
                chainChannels
                  .filter(prevChannel => prevChannel.id !== channel.id)
                  .push(new Channel(channel))
                  .sort(byId)
              );
            }, state.get('data').get('byChain')),
          },
        })
      );
    case types.CHANNEL_FETCH_SUCCEEDED:
    case types.CHANNEL_CREATE_SUCCEEDED:
    case types.CHANNEL_UPDATE_SUCCEEDED:
      return (() => {
        const chainId = action.payload.chain.toString();
        const chainChannels = state
          .get('data')
          .get('byChain')
          .get(chainId, new List())
          .filter(channel => channel.id !== action.payload.id)
          .push(new Channel(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Channel(action.payload)),
              byChain: state.get('data').get('byChain').set(chainId, chainChannels),
            },
          })
        );
      })();
    case types.CHANNEL_FETCH:
    case types.CHANNEL_CREATE:
    case types.CHANNEL_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.CHANNEL_DELETE_SUCCEEDED:
      return (() => {
        const chainId = action.payload.chain.toString();
        const prevData = state.get('data').get('byChain');
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state.get('data').get('byId').delete(action.payload.id.toString()),
              byChain: prevData.set(
                chainId,
                prevData
                  .get(chainId, new List())
                  .filter(channel => channel.id !== action.payload.id)
              ),
            },
          })
        );
      })();
    case types.CHANNEL_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.CHANNEL_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.CHANNEL_FETCH_FAILED:
    case types.CHANNELS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
