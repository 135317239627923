// Libraries
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Dependencies
import InputStyle from './common';
import TranslationsContext from 'providers/translationsProvider';

// Components
import { Popover, Progress, Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { P } from 'components/fontSystem';

const minRequiredLength = 8;

const PasswordInput = ({ hidePopover = false, onChange = null, ...rest }) => {
  const { t } = useContext(TranslationsContext);

  const [status, setStatus] = useState('success');
  const [percent, setPercent] = useState(0);
  const [showPopover, setShowPopover] = useState(false);

  const handleOnChange = event => {
    const percentage = (100 / minRequiredLength) * event.target.value.length;
    setPercent(percentage);

    if (!hidePopover && percentage > 0 && percentage < 100) setShowPopover(true);
    else setShowPopover(false);

    const currentPassStatus = status !== 0 && percentage < 100 ? 'exception' : 'success';
    setStatus(currentPassStatus);

    if (onChange) onChange(event);
  };

  const getProgressStatus = progressStatus =>
    ({
      exception: { message: t('TOO SHORT'), color: '#F25A5A' },
      success: { message: t('PERFECT!'), color: '#37CC82' },
    })[progressStatus];

  const { statusMessage, statusColor } = getProgressStatus(status);

  const popoverContent = (
    <>
      <P $semibold>
        {t('Password Strength:')} <span style={{ color: statusColor }}>{statusMessage}</span>
      </P>
      <Progress status={status} percent={percent} showInfo={false} />
      <P style={{ width: '200px' }}>{t('Your password should be at least 8 characters long.')}</P>
    </>
  );

  const popoverAlignment = {
    points: ['tr', 'br'],
    offset: [18, 20],
    targetOffset: ['30%', '40%'],
  };

  return (
    <>
      <StyledPasswordInput
        iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
        onChange={handleOnChange}
        {...rest}
      />

      <Popover
        placement="bottomRight"
        content={popoverContent}
        open={showPopover}
        align={popoverAlignment}
      />
    </>
  );
};

const StyledPasswordInput = styled(Input.Password)`
  &&& {
    ${InputStyle}

    .ant-input-password-icon {
      font-size: 120%;
    }
  }
`;

PasswordInput.propTypes = {
  hidePopover: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PasswordInput;
