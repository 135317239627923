import { createSelector } from 'reselect';

const getState = state => state.objective;

const getRawObjective = createSelector([getState], state => state.get('data'));

const getObjectivesById = createSelector([getRawObjective], state => state.get('byId'));

const getObjectivesByProject = createSelector([getRawObjective], state => state.get('byProject'));

const getObjectives = createSelector([getObjectivesById], state => state.valueSeq().toJS());

const getObjectivesOfYearByProject = year =>
  createSelector([getObjectivesByProject], state => ({
    get: (projectId, defaultVal) =>
      state.get(projectId, defaultVal).filter(objective => objective.year === year),
  }));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getObjectives,
  getObjectivesById,
  getObjectivesByProject,
  getObjectivesOfYearByProject,
  getErrorMessage,
};
