import { css } from 'styled-components';
import { semiBoldStyle } from 'components/fontSystem';

const getInputBackgroundColor = theme => theme.lightGrey;
const USEBACKGROUND_HORIZONTAL_PADDING = 6;
const getInputPadding = usebackground => {
  return usebackground ? `4px ${USEBACKGROUND_HORIZONTAL_PADDING}px` : '10px';
};

const InputStyle = css`
  border: ${({ noborder }) => (noborder ? 'none' : '1px solid #B3B3B3')};
  border-radius: 4px;
  font-size: 15px;
  line-height: 25px;
  padding: ${({ usebackground }) => getInputPadding(usebackground)};
  ${({ size }) => size === 'large' && 'padding: 10px'};
  resize: none;

  ${props => props.$semibold && semiBoldStyle};

  &:focus,
  &:hover {
    background: ${({ theme, usebackground }) =>
      usebackground ? getInputBackgroundColor(theme) : 'transparent'};
    border: ${({ theme, noborder }) => (noborder ? 'none' : `1px solid ${theme.primaryColor}`)};
  }
`;

export default InputStyle;
export { getInputBackgroundColor, getInputPadding, USEBACKGROUND_HORIZONTAL_PADDING };
