import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import serviceRiskCategory from 'services/GrowthPlan/riskCategory';
import types from './types';
import actions from './actions';

export function* fetchRiskCategory(action) {
  const { id } = action.payload;
  try {
    const response = yield call(serviceRiskCategory.fetchRiskCategory, { id });
    yield put(actions.fetchRiskCategoriesucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchRiskCategoryFailed({ message: e.message }));
  }
}

export function* listRiskCategories(action) {
  const { project } = action.payload;
  try {
    const growthRisks = yield call(serviceRiskCategory.listRiskCategories, {
      project,
    });
    yield put(actions.listRiskCategoriesSucceeded({ results: growthRisks.data }));
  } catch (e) {
    yield put(actions.listRiskCategoriesFailed({ message: e.message }));
  }
}

function* growthRiskSaga() {
  yield all([
    takeLatest(types.RISK_CATEGORY_FETCH, fetchRiskCategory),
    takeEvery(types.RISK_CATEGORIES_LIST, listRiskCategories),
  ]);
}

export default growthRiskSaga;
