import { createSelector } from 'reselect';

const getState = state => state.retentionActivity;

const getRawRetentionActivities = createSelector([getState], state => state.get('data'));

const getRetentionActivitiesById = createSelector([getRawRetentionActivities], state =>
  state.get('byId')
);

const getRetentionActivities = createSelector([getRetentionActivitiesById], state =>
  state.valueSeq().toJS()
);

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getRetentionActivitiesById,
  getRetentionActivities,
  getErrorMessage,
};
