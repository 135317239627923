import types from './types';

const listProgramTools = query => ({
  type: types.PROGRAM_TOOLS_LIST,
  payload: query,
});

const listProgramToolsSucceeded = payload => ({
  type: types.PROGRAM_TOOLS_LIST_SUCCEEDED,
  payload,
});

const listProgramToolsFailed = ({ message }) => ({
  type: types.PROGRAM_TOOLS_LIST_FAILED,
  payload: { message },
});

export default {
  listProgramTools,
  listProgramToolsSucceeded,
  listProgramToolsFailed,
};
