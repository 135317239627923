import types from './types';

const clearError = () => ({
  type: types.ATTRIBUTE_CLEAR_ERROR,
});

const createAttribute = ({ project, product, ...attribute }) => ({
  type: types.ATTRIBUTE_CREATE,
  payload: { project, product, ...attribute },
});

const createAttributeSucceeded = ({ product, id, ...attribute }) => ({
  type: types.ATTRIBUTE_CREATE_SUCCEEDED,
  payload: { product, id, ...attribute },
});

const createAttributeFailed = ({ message }) => ({
  type: types.ATTRIBUTE_CREATE_FAILED,
  payload: { message },
});

const deleteAttribute = ({ project, product, attributeId }) => ({
  type: types.ATTRIBUTE_DELETE,
  payload: { project, product, attributeId },
});

const deleteAttributeSucceeded = ({ product, attributeId }) => ({
  type: types.ATTRIBUTE_DELETE_SUCCEEDED,
  payload: { product, id: attributeId },
});

const deleteAttributeFailed = ({ message }) => ({
  type: types.ATTRIBUTE_DELETE_FAILED,
  payload: message,
});

const fetchAttribute = ({ project, product, id }) => ({
  type: types.ATTRIBUTE_FETCH,
  payload: { project, product, id },
});

const fetchAttributeSucceeded = ({ ...attribute }) => ({
  type: types.ATTRIBUTE_FETCH_SUCCEEDED,
  payload: { ...attribute },
});

const fetchAttributeFailed = ({ message }) => ({
  type: types.ATTRIBUTE_FETCH_FAILED,
  payload: { message },
});

const listAttributes = ({ project, product }) => ({
  type: types.ATTRIBUTES_LIST,
  payload: { project, product },
});

const listAttributesSucceeded = payload => ({
  type: types.ATTRIBUTES_LIST_SUCCEEDED,
  payload,
});

const listAttributesFailed = ({ message }) => ({
  type: types.ATTRIBUTES_LIST_FAILED,
  payload: { message },
});

const updateAttribute = ({ project, product, attributeId, ...updatedAttribute }) => ({
  type: types.ATTRIBUTE_UPDATE,
  payload: { project, product, attributeId, ...updatedAttribute },
});

const updateAttributeSucceeded = ({ ...attribute }) => ({
  type: types.ATTRIBUTE_UPDATE_SUCCEEDED,
  payload: { ...attribute },
});

const updateAttributeFailed = ({ message }) => ({
  type: types.ATTRIBUTE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createAttribute,
  createAttributeFailed,
  createAttributeSucceeded,
  deleteAttribute,
  deleteAttributeFailed,
  deleteAttributeSucceeded,
  fetchAttribute,
  fetchAttributeFailed,
  fetchAttributeSucceeded,
  listAttributes,
  listAttributesFailed,
  listAttributesSucceeded,
  updateAttribute,
  updateAttributeFailed,
  updateAttributeSucceeded,
};
