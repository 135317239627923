import axios from 'axios';
import { KEY_DEMO_PROJECT, KEY_SHOULD_BLOCK_ACCESS } from 'containers/Tools/utils';
import { isMaintenanceModeEnabled } from 'utils/maintenance';
import queryString from 'utils/queryString';

// You can change the REACT_APP_BASE_URL using your .env.local file:
// After changing it, please remember to stop your development server and start it again.
const baseURL = process.env.REACT_APP_BASE_URL
const exportImageURL = window.env !== 'dev' ? '' : baseURL;

const instance = axios.create({
  baseURL,
});

const setAuth = accessToken => {
  instance.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

instance.defaults.headers.common = {
  ...instance.defaults.headers.common,
  'Bfb-React-Version': '1.0',
};

const isEmbed = () => {
  const queryParams = queryString.parse(window.location.search);
  return queryParams.embed;
};

// Add a response interceptor for maintenance page
instance.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status >= 502) {
      window.location.href = '/maintenance';
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// Add a response interceptor for demo projects
instance.interceptors.request.use(request => {
  // If maintenance mode is on, don't do any requests
  if (isMaintenanceModeEnabled()) {
    return Promise.reject(new Error('Maintenance mode is on'));
  }
  if (!request.url.includes('/oauth/') && (sessionStorage.getItem(KEY_DEMO_PROJECT) || isEmbed())) {
    request.params = request.params || {};
    request.params.demo = true;
  }
  if (sessionStorage.getItem(KEY_SHOULD_BLOCK_ACCESS)) {
    request.params = request.params || {};
    request.params[KEY_SHOULD_BLOCK_ACCESS] = true;
  }

  if (isEmbed()) {
    request.params = request.params || {};
    request.params.embedded = true;
  }

  const demoToken = sessionStorage.getItem('demo_token');
  const isDemoToken = window.location.search.includes('demoModalExample');
  if (isDemoToken) {
    request.headers.Authorization = `Bearer ${demoToken}`;
  }
  return request;
});

const axiosBaseQuery =
  ({ apiPath } = { apiPath: '' }) =>
    async ({ method, data, params, headers, url }) => {
      try {
        const result = await instance({
          url: baseURL + apiPath + (url || ''),
          method,
          data,
          params,
          headers,
        });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };

export { baseURL, exportImageURL, setAuth, axiosBaseQuery };

export default instance;
