import { createSelector } from 'reselect';

const getState = state => state.feature;

const getRawFeature = createSelector([getState], state => state.get('data'));

const getFeaturesById = createSelector([getRawFeature], state => state.get('byId'));

const getFeaturesByProduct = createSelector([getRawFeature], state => state.get('byProduct'));

const getFeatures = createSelector([getFeaturesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getFeatures,
  getFeaturesById,
  getFeaturesByProduct,
  getErrorMessage,
};
