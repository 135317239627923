import types from './types';

const clearError = () => ({
  type: types.COMPETITOR_CLEAR_ERROR,
});

const createCompetitor = ({ project, product, outcome, ...competitor }) => ({
  type: types.COMPETITOR_CREATE,
  payload: { project, product, outcome, ...competitor },
});

const createCompetitorSucceeded = ({ ...competitor }) => ({
  type: types.COMPETITOR_CREATE_SUCCEEDED,
  payload: { ...competitor },
});

const createCompetitorFailed = ({ message }) => ({
  type: types.COMPETITOR_CREATE_FAILED,
  payload: { message },
});

const deleteCompetitor = ({ project, product, outcome, id }) => ({
  type: types.COMPETITOR_DELETE,
  payload: { project, product, outcome, id },
});

const deleteCompetitorSucceeded = payload => ({
  type: types.COMPETITOR_DELETE_SUCCEEDED,
  payload,
});

const deleteCompetitorFailed = ({ message }) => ({
  type: types.COMPETITOR_DELETE_FAILED,
  payload: message,
});

const fetchCompetitor = ({ project, product, outcome, id }) => ({
  type: types.COMPETITOR_FETCH,
  payload: { project, product, outcome, id },
});

const fetchCompetitorSucceeded = ({ outcome, product, ...competitor }) => ({
  type: types.COMPETITOR_FETCH_SUCCEEDED,
  payload: { outcome, product, ...competitor },
});

const fetchCompetitorFailed = ({ message }) => ({
  type: types.COMPETITOR_FETCH_FAILED,
  payload: { message },
});

const listCompetitors = ({ project, product }) => ({
  type: types.COMPETITORS_LIST,
  payload: { project, product },
});

const listCompetitorsSucceeded = payload => ({
  type: types.COMPETITORS_LIST_SUCCEEDED,
  payload,
});

const listCompetitorsFailed = ({ message }) => ({
  type: types.COMPETITORS_LIST_FAILED,
  payload: { message },
});

const updateCompetitor = ({ project, product, outcome, competitorId, ...competitor }) => ({
  type: types.COMPETITOR_UPDATE,
  payload: { project, product, outcome, competitorId, ...competitor },
});

const updateCompetitorSucceeded = ({ ...competitor }) => ({
  type: types.COMPETITOR_UPDATE_SUCCEEDED,
  payload: { ...competitor },
});

const updateCompetitorFailed = ({ message }) => ({
  type: types.COMPETITOR_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createCompetitor,
  createCompetitorFailed,
  createCompetitorSucceeded,
  deleteCompetitor,
  deleteCompetitorFailed,
  deleteCompetitorSucceeded,
  fetchCompetitor,
  fetchCompetitorFailed,
  fetchCompetitorSucceeded,
  listCompetitors,
  listCompetitorsFailed,
  listCompetitorsSucceeded,
  updateCompetitor,
  updateCompetitorFailed,
  updateCompetitorSucceeded,
};
