import types from './types';

const clearError = () => ({
  type: types.PROGRAM_STEP_CLEAR_ERROR,
});

const listProgramSteps = ({ program, project }) => ({
  type: types.PROGRAM_STEPS_LIST,
  payload: { program, project },
});

const listProgramStepsSucceeded = payload => ({
  type: types.PROGRAM_STEPS_LIST_SUCCEEDED,
  payload,
});

const listProgramStepsFailed = ({ message }) => ({
  type: types.PROGRAM_STEPS_LIST_FAILED,
  payload: { message },
});

export default {
  clearError,
  listProgramSteps,
  listProgramStepsFailed,
  listProgramStepsSucceeded,
};
