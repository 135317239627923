export const CELL_ALIGNMENT = {
  RIGHT: 'right',
  LEFT: 'left',
  MIDDLE: 'middle',
};

export const FIXED_ALIGNMENT = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const AVERAGE_KEY = 'average';
export const TOTAL_KEY = 'total';
export const TOTAL_RENDER_COMPONENT = 'renderComponent';

/**
 * `fromEntries` ponyfill.
 * `https://github.com/feross/fromentries`.
 */
export function fromEntries(iterable) {
  return [...iterable].reduce((obj, { 0: key, 1: val }) => Object.assign(obj, { [key]: val }), {});
}
