import * as reduxHelper from 'store/utils/reduxHelper';
import DeepDive from 'models/Tools/DeepDive';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byProgram: {}, byStep: {}, count: 0 },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.DEEPDIVE_LIST:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.DEEPDIVE_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, mat) => ({
                ...obj,
                [mat.id]: new DeepDive(mat),
              }),
              state.data.byId
            ),
            byStep: {
              [action.payload.stepSlug]: action.payload.results.map(mat => new DeepDive(mat)),
            },
            byProgram: {
              [action.payload.program]: action.payload.results.map(
                program => new DeepDive(program)
              ),
            },
            count: action.payload.count,
          },
        })
      );
    case types.DEEPDIVE_EDIT_SUCCEEDED:
    case types.DEEPDIVE_CREATE_SUCCEEDED: {
      const material = action.payload;
      const materialById = {
        ...state.data.byId,
        [material.id]: new DeepDive(material),
      };

      // Get prevMat by program
      const prevProgramMaterials = state.data.byProgram[material.program];
      const programMaterials = prevProgramMaterials ? [...prevProgramMaterials] : [];
      // extrat Material ID
      const materialId = material.id;
      // check the material index
      const matIndex = programMaterials.findIndex(mat => mat.id === materialId);
      // if we have index we must delete the material from the array
      if (matIndex >= 0) {
        programMaterials.splice(matIndex, 1);
      }
      // push the new material to the array
      programMaterials.push(new DeepDive(material));

      // Add new material by program
      const byProgram = {
        ...state.data.byProgram,
        [material.program]: programMaterials.sort(byId),
      };

      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            ...state.data,
            byId: materialById,
            byProgram,
          },
        })
      );
    }
    case types.DEEPDIVE_DELETE_SUCCEEDED: {
      const material = action.payload;
      const deepDivesById = { ...state.data.byId };
      delete deepDivesById[material.id];
      const deepDivesByProgram = { ...state.data.byProgram };
      // eslint-disable-next-line max-len
      const programDeepDives = deepDivesByProgram[material.program].filter(
        mat => mat.id !== material.id
      );
      return {
        data: {
          ...state.data,
          byId: deepDivesById,
          byProgram: {
            ...deepDivesByProgram,
            [material.program]: programDeepDives,
          },
        },
      };
    }
    case types.DEEPDIVE_LIST_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
