import { types } from 'store/modules/profilePicture';

const profilePictureRequest = query => ({
  type: types.PROFILE_PICTURE_REQUEST,
  payload: query,
});

const profilePictureSucceeded = ({ profilePictureData, defaultProfilePictureData }) => ({
  type: types.PROFILE_PICTURE_SUCCEEDED,
  payload: { profilePictureData, defaultProfilePictureData },
});

const profilePictureFailed = ({ message }) => ({
  type: types.PROFILE_PICTURE_FAILED,
  payload: message,
});

const profilePictureEdit = ({ id, ...mentorData }) => ({
  type: types.PROFILE_PICTURE_EDIT,
  payload: { id, ...mentorData },
});

const profilePictureEditSucceeded = () => ({
  type: types.PROFILE_PICTURE_EDIT_SUCCEEDED,
});

const profilePictureEditFailed = ({ message }) => ({
  type: types.PROFILE_PICTURE_EDIT_FAILED,
  payload: message,
});

const profilePictureCreateRequest = ({ ...profilePictureData }) => ({
  type: types.PROFILE_PICTURE_CREATE,
  payload: { ...profilePictureData },
});

const profilePictureCreateSucceeded = ({ ...data }) => ({
  type: types.PROFILE_PICTURE_CREATE_SUCCEEDED,
  payload: { ...data },
});

const profilePictureCreateFailed = ({ message }) => ({
  type: types.PROFILE_PICTURE_CREATE_FAILED,
  payload: message,
});

export default {
  profilePictureRequest,
  profilePictureSucceeded,
  profilePictureFailed,
  profilePictureEdit,
  profilePictureEditSucceeded,
  profilePictureEditFailed,
  profilePictureCreateRequest,
  profilePictureCreateSucceeded,
  profilePictureCreateFailed,
};
