import { call, put, all, takeEvery } from 'redux-saga/effects';
import programToolsService from 'services/programTools';
import { actions as stepActions } from 'store/modules/programStep';
import { normalizeTools } from 'store/normalizers/toolNormalizer';
import actions from './actions';
import types from './types';

export function* listProgramTools(action) {
  try {
    const programTools = yield call(programToolsService.fetchProgramTools, action.payload);
    const { tools, steps } = yield call(normalizeTools, programTools.data);
    yield put(actions.listProgramToolsSucceeded(tools));
    yield steps && put(stepActions.listProgramStepsSucceeded({ results: steps }));
  } catch (e) {
    yield put(actions.listProgramToolsFailed({ message: e.message }));
  }
}

function* programSaga() {
  yield all([takeEvery(types.PROGRAM_TOOLS_LIST, listProgramTools)]);
}

export default programSaga;
