const PAYROLL_CLEAR_ERROR = 'bfb/payroll/PAYROLL_CLEAR_ERROR';
const PAYROLLS_LIST = 'bfb/payroll/PAYROLLS_LIST';
const PAYROLLS_LIST_SUCCEEDED = 'bfb/payroll/PAYROLLS_LIST_SUCCEEDED';
const PAYROLLS_LIST_FAILED = 'bfb/payroll/PAYROLLS_LIST_FAILED';
const PAYROLL_CREATE = 'bfb/payroll/PAYROLL_CREATE';
const PAYROLL_CREATE_SUCCEEDED = 'bfb/payroll/PAYROLL_CREATE_SUCCEEDED';
const PAYROLL_CREATE_FAILED = 'bfb/payroll/PAYROLL_CREATE_FAILED';
const PAYROLL_DELETE = 'bfb/payroll/PAYROLL_DELETE';
const PAYROLL_DELETE_SUCCEEDED = 'bfb/payroll/PAYROLL_DELETE_SUCCEEDED';
const PAYROLL_DELETE_FAILED = 'bfb/payroll/PAYROLL_DELETE_FAILED';
const PAYROLL_FETCH = 'bfb/payroll/PAYROLL_FETCH';
const PAYROLL_FETCH_SUCCEEDED = 'bfb/payroll/PAYROLL_FETCH_SUCCEEDED';
const PAYROLL_FETCH_FAILED = 'bfb/payroll/PAYROLL_FETCH_FAILED';
const PAYROLL_UPDATE = 'bfb/payroll/PAYROLL_UPDATE';
const PAYROLL_UPDATE_SUCCEEDED = 'bfb/payroll/PAYROLL_UPDATE_SUCCEEDED';
const PAYROLL_UPDATE_FAILED = 'bfb/payroll/PAYROLL_UPDATE_FAILED';
const MONTHLY_PAYROLL_UPDATE_SUCCEEDED = 'bfb/payroll/MONTHLY_PAYROLL_UPDATE_SUCCEEDED';
const MONTHLY_PAYROLL_UPDATE_FAILED = 'bfb/payroll/MONTHLY_PAYROLL_UPDATE_FAILED';

export default {
  PAYROLLS_LIST,
  PAYROLLS_LIST_SUCCEEDED,
  PAYROLLS_LIST_FAILED,
  PAYROLL_CLEAR_ERROR,
  PAYROLL_CREATE,
  PAYROLL_CREATE_SUCCEEDED,
  PAYROLL_CREATE_FAILED,
  PAYROLL_DELETE,
  PAYROLL_DELETE_SUCCEEDED,
  PAYROLL_DELETE_FAILED,
  PAYROLL_FETCH,
  PAYROLL_FETCH_SUCCEEDED,
  PAYROLL_FETCH_FAILED,
  PAYROLL_UPDATE,
  PAYROLL_UPDATE_SUCCEEDED,
  PAYROLL_UPDATE_FAILED,
  MONTHLY_PAYROLL_UPDATE_SUCCEEDED,
  MONTHLY_PAYROLL_UPDATE_FAILED,
};
