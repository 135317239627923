import { createSelector } from 'reselect';

const getState = state => state.profilePicture;

const getProfilePicture = createSelector([getState], state => state.get('profilePictureData'));

const getDefaultProfilePicture = createSelector([getState], state =>
  state.get('defaultProfilePictureData')
);

const updatePending = createSelector([getState], state => state.get('updatePending'));

const errorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getProfilePicture,
  getDefaultProfilePicture,
  updatePending,
  errorMessage,
};
