import types from './types';

const listSurveys = payload => ({
  type: types.SURVEYS_LIST,
  payload,
});

const listSurveysSucceeded = payload => ({
  type: types.SURVEYS_LIST_SUCCEEDED,
  payload,
});

const listSurveysFailed = ({ message }) => ({
  type: types.SURVEYS_LIST_FAILED,
  payload: { message },
});

export default {
  listSurveysSucceeded,
  listSurveysFailed,
  listSurveys,
};
