import { createSelector } from 'reselect';

const getState = state => state.programStats;

const getAllMemberStats = createSelector([getState], state => state.data);

const getMemberStatsOverview = createSelector([getAllMemberStats], state => state.stats);

const getAllMentorStats = createSelector([getState], state => state.data);

const getMentorStatsOverview = createSelector([getAllMentorStats], state => state.stats);

export default {
  getAllMemberStats,
  getMemberStatsOverview,
  getAllMentorStats,
  getMentorStatsOverview,
};
