export const formatAsQueryParams = (obj, removeEmpty = false) => {
  if (obj === undefined || obj === null) {
    return '';
  }
  return Object.entries(obj)
    .reduce((acc, [key, value]) => {
      if (removeEmpty && (value === undefined || value === null)) return acc;
      const formattedValue = Array.isArray(value) ? value.join('-') : value;

      return `${acc}${key}=${encodeURIComponent(formattedValue)}&`;
    }, '')
    .slice(0, -1);
};

export default {
  format: formatAsQueryParams,
  parse: function parse(queryString) {
    if (queryString === undefined || queryString === null || queryString === '') {
      return {};
    }
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i];
      const [key, val] = pair.split('=');
      query[decodeURIComponent(key)] = decodeURIComponent(val || '');
    }
    return query;
  },
};
