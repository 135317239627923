import types from './types';

const listMentorEmailDashboard = payload => ({
  type: types.MENTOR_EMAIL_DASHBOARD_LIST,
  payload,
});

const listMentorEmailDashboardPending = () => ({
  type: types.MENTOR_EMAIL_DASHBOARD_LIST_PENDING,
});

const listMentorEmailDashboardSucceeded = ({ emailsData }) => ({
  type: types.MENTOR_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  payload: emailsData,
});

const listMentorEmailDashboardFailed = ({ message }) => ({
  type: types.MENTOR_EMAIL_DASHBOARD_LIST_FAILED,
  payload: { message },
});

const listMentorDetailEmailDashboard = payload => ({
  type: types.MENTOR_DETAIL_EMAIL_DASHBOARD_LIST,
  payload,
});

const listMentorDetailEmailDashboardPending = () => ({
  type: types.MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_PENDING,
});

const listMentorDetailEmailDashboardSucceeded = ({ emailData }) => ({
  type: types.MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  payload: emailData,
});

const listMentorDetailEmailDashboardFailed = ({ message }) => ({
  type: types.MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_FAILED,
  payload: { message },
});

export default {
  listMentorEmailDashboard,
  listMentorEmailDashboardPending,
  listMentorEmailDashboardSucceeded,
  listMentorEmailDashboardFailed,
  listMentorDetailEmailDashboard,
  listMentorDetailEmailDashboardPending,
  listMentorDetailEmailDashboardSucceeded,
  listMentorDetailEmailDashboardFailed,
};
