import { Record } from 'immutable';
import ContentType from '../ContentType';

const ChannelRecord = new Record({
  id: null,
  contentType: ContentType.CHANNEL,
  name: '',
  segment: null,
  new_chain: null,
  comments: [],
  // chain: null, ANGULAR
});

class Channel extends ChannelRecord {
  static contentType = ContentType.CHANNEL;
  get id() {
    return this.get('id');
  }
}

export default Channel;
