import api from '../api';

const createFunding = ({ ...funding }) => api.post('api/fundings/', { ...funding });

const deleteFunding = ({ id }) => api.delete(`api/fundings/${id}/`);

const fetchFunding = ({ id }) => api.get(`api/fundings/${id}/`);

const listFundings = ({ ...query }) => api.get('api/fundings/', { params: query });

const updateFunding = ({ id, ...updatedFunding }) =>
  api.patch(`api/fundings/${id}/`, { ...updatedFunding });

export default {
  createFunding,
  deleteFunding,
  fetchFunding,
  listFundings,
  updateFunding,
};
