import { types } from 'store/modules/ImpactPlan/role';

const clearError = () => ({
  type: types.ROLE_CLEAR_ERROR,
});

const createRole = ({ ...role }) => ({
  type: types.ROLE_CREATE,
  payload: { ...role },
});

const createRoleSucceeded = ({ id, ...role }) => ({
  type: types.ROLE_CREATE_SUCCEEDED,
  payload: { id, ...role },
});

const createRoleFailed = ({ message }) => ({
  type: types.ROLE_CREATE_FAILED,
  payload: { message },
});

const deleteRole = ({ id, system }) => ({
  type: types.ROLE_DELETE,
  payload: { id, system },
});

const deleteRoleSucceeded = ({ id, system }) => ({
  type: types.ROLE_DELETE_SUCCEEDED,
  payload: { id, system },
});

const deleteRoleFailed = ({ message }) => ({
  type: types.ROLE_DELETE_FAILED,
  payload: { message },
});

const fetchRole = ({ id }) => ({
  type: types.ROLE_FETCH,
  payload: { id },
});

const fetchRoleSucceeded = ({ id, ...role }) => ({
  type: types.ROLE_FETCH_SUCCEEDED,
  payload: { id, ...role },
});

const fetchRoleFailed = ({ message }) => ({
  type: types.ROLE_FETCH_FAILED,
  payload: { message },
});

const listRoles = ({ system }) => ({
  type: types.ROLES_LIST,
  payload: { system },
});

const listRolesSucceeded = payload => ({
  type: types.ROLES_LIST_SUCCEEDED,
  payload,
});

const listRolesFailed = error => ({
  type: types.ROLES_LIST_FAILED,
  payload: error,
});

const updateRole = ({ id, ...role }) => ({
  type: types.ROLE_UPDATE,
  payload: { id, ...role },
});

const updateRoleSucceeded = ({ id, ...role }) => ({
  type: types.ROLE_UPDATE_SUCCEEDED,
  payload: { id, ...role },
});

const updateRoleFailed = ({ message }) => ({
  type: types.ROLE_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createRole,
  createRoleFailed,
  createRoleSucceeded,
  deleteRole,
  deleteRoleFailed,
  deleteRoleSucceeded,
  fetchRole,
  fetchRoleFailed,
  fetchRoleSucceeded,
  listRoles,
  listRolesFailed,
  listRolesSucceeded,
  updateRole,
  updateRoleSucceeded,
  updateRoleFailed,
};
