import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import serviceActivity from 'services/BusinessModelMarketing/activity';
import Activity from 'models/BusinessModelsMarketing/Activity';
import { types as commentTypes } from 'store/modules/comment';

import actions from './actions';
import types from './types';

export function* createActivity(action) {
  const { segment, ...activity } = action.payload;
  try {
    const response = yield call(serviceActivity.createActivity, {
      segment,
      ...activity,
    });
    yield put(actions.createActivitySucceeded({ segment, ...response.data }));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      actions.createActivityFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* listActivities(action) {
  const { productId, ...query } = action.payload;
  try {
    const response = yield call(serviceActivity.listActivities, {
      product: productId,
      ...query,
    });
    yield put(
      actions.listActivitiesSucceeded({
        results: response.data,
        product: productId,
      })
    );
  } catch (e) {
    yield put(actions.listActivitiesFailed({ message: e.message }));
  }
}

export function* fetchActivity(action) {
  const { segment, id, ...activity } = action.payload;
  try {
    const response = yield call(serviceActivity.fetchActivity, {
      id,
      ...activity,
    });
    yield put(actions.fetchActivitySucceeded({ ...response.data, segment }));
  } catch (e) {
    yield put(
      actions.fetchActivityFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateActivity(action) {
  const { segment, id, ...activity } = action.payload;
  try {
    const updated = yield call(serviceActivity.updateActivity, {
      id,
      ...activity,
    });
    yield put(actions.updateActivitySucceeded({ ...updated.data, segment }));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      actions.updateActivityFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteActivity(action) {
  const { segment, id } = action.payload;
  try {
    yield call(serviceActivity.deleteActivity, { id });
    yield put(actions.deleteActivitySucceeded({ id, segment }));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      actions.deleteteActivityFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Activity.contentType) {
    yield put(
      actions.fetchActivity({
        segment: object.get('segment'),
        id: object.get('id'),
      })
    );
  }
}

function* activitySaga() {
  yield all([
    takeLatest(types.ACTIVITY_CREATE, createActivity),
    takeLatest(types.ACTIVITY_FETCH, fetchActivity),
    takeLatest(types.ACTIVITY_UPDATE, updateActivity),
    takeEvery(types.ACTIVITIES_LIST, listActivities),
    takeEvery(types.ACTIVITY_DELETE, deleteActivity),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default activitySaga;
