import types from './types';

const clearError = () => ({
  type: types.TOOL_CLEAR_ERROR,
});

const listTools = ({ program, project }) => ({
  type: types.TOOLS_LIST,
  payload: { program, project },
});

const listToolsSucceeded = payload => ({
  type: types.TOOLS_LIST_SUCCEEDED,
  payload,
});

const listToolsFailed = ({ message }) => ({
  type: types.TOOLS_LIST_FAILED,
  payload: { message },
});

const updateTool = ({ id, ...tool }) => ({
  type: types.TOOL_UPDATE,
  payload: { id, ...tool },
});

const updateToolSucceeded = payload => ({
  type: types.TOOL_UPDATE_SUCCEEDED,
  payload,
});

const updateToolFailed = ({ message }) => ({
  type: types.TOOL_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  listTools,
  listToolsFailed,
  listToolsSucceeded,
  updateTool,
  updateToolSucceeded,
  updateToolFailed,
};
