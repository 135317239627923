import api from '../api';

const createFinancialProduct = ({ scenario, ...financialProduct }) =>
  api({
    method: 'post',
    url: 'api/financial-products/',
    data: {
      scenario,
      ...financialProduct,
    },
    params: {
      scenario,
    },
  });

const deleteFinancialProduct = ({ id }) => api.delete(`api/financial-products/${id}`);

const fetchFinancialProduct = ({ id }) => api.get(`api/financial-products/${id}/`);

// Every parameter is optional to filter the data, if no parameter is provided will fetch all.
const listFinancialProducts = ({ scenario, project, product, program } = {}) =>
  api.get('api/financial-products/', {
    params: {
      scenario,
      project,
      product,
      program,
    },
  });

const updateFinancialProduct = ({ id, ...updatedFinancialProduct }) =>
  api({
    method: 'patch',
    url: `api/financial-products/${id}/`,
    data: updatedFinancialProduct,
  });

export default {
  createFinancialProduct,
  deleteFinancialProduct,
  fetchFinancialProduct,
  listFinancialProducts,
  updateFinancialProduct,
};
