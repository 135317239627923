// Libraries
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Select as AntSelect } from 'antd';

// Dependecies
import bfbTheme from 'styles/b4b-theme';

// This component is more modern copy of 'components/elements/SelectOld'.
// The idea is stop using the other component and start using this one
// since Antd doesnt use children anymore to render the Select options.
const Select = ({
  onBlur = null,
  onChange = null,
  options = null,
  name = null,
  className = null,
  ...rest
}) => {
  /* 
  If you plan to use onBlur or onChange, along with Form validation, passing `name` prop 
  will make it easier.
  */

  const onChangeHandler = value => {
    const event = {
      target: { name, value },
    };

    if (onChange) onChange(event);
    if (onBlur) onBlur(event);
  };

  return (
    <StyledSelect
      options={options}
      className={className}
      onBlur={onBlur}
      onChange={onChangeHandler}
      {...rest}
    />
  );
};

const StyledSelect = styled(AntSelect)`
  &&& {
    &.languages-select {
      .ant-select-selector {
        background-color: rgba(0, 255, 175, 0.2);
        border: 0;
      }

      .ant-select-item-option-selected {
        background-color: rgba(0, 255, 175, 0.2);
      }
    }

    .ant-select-selector {
      ${props =>
        !props.bordered &&
        css`
          padding: 0;
          border: 0;
          border-bottom: 1px solid ${bfbTheme.placeholderGrey};
          border-radius: 0;

          .ant-select-selection-placeholder {
            inset-inline-start: 0;
            inset-inline-end: 0;
          }
        `}
    }

    .ant-select-arrow {
      color: ${bfbTheme.placeholderGrey};
    }

    .ant-select-selection-placeholder {
      font-style: italic;
      font-weight: 100;
    }
  }
`;

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array),
};

Select.Option = AntSelect.Option;

export default Select;
