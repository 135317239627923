import types from './types';

const clearError = () => ({
  type: types.FUNDING_CLEAR_ERROR,
});

const createFunding = ({ project, scenario, ...funding }) => ({
  type: types.FUNDING_CREATE,
  payload: { project, scenario, ...funding },
});

const createFundingSucceeded = ({ scenario, id, ...funding }) => ({
  type: types.FUNDING_CREATE_SUCCEEDED,
  payload: { scenario, id, ...funding },
});

const createFundingFailed = ({ message }) => ({
  type: types.FUNDING_CREATE_FAILED,
  payload: { message },
});

const deleteFunding = ({ project, scenario, id }) => ({
  type: types.FUNDING_DELETE,
  payload: { project, scenario, id },
});

const deleteFundingSucceeded = ({ scenario, id }) => ({
  type: types.FUNDING_DELETE_SUCCEEDED,
  payload: { scenario, id },
});

const deleteFundingFailed = ({ message }) => ({
  type: types.FUNDING_DELETE_FAILED,
  payload: message,
});

const fetchFunding = ({ project, scenario, id }) => ({
  type: types.FUNDING_FETCH,
  payload: { project, scenario, id },
});

const fetchFundingSucceeded = ({ ...funding }) => ({
  type: types.FUNDING_FETCH_SUCCEEDED,
  payload: { ...funding },
});

const fetchFundingFailed = ({ message }) => ({
  type: types.FUNDING_FETCH_FAILED,
  payload: { message },
});

const listFundings = ({ project, scenario }) => ({
  type: types.FUNDINGS_LIST,
  payload: { project, scenario },
});

const listFundingsSucceeded = payload => ({
  type: types.FUNDINGS_LIST_SUCCEEDED,
  payload,
});

const listFundingsFailed = ({ message }) => ({
  type: types.FUNDINGS_LIST_FAILED,
  payload: { message },
});

const updateFunding = ({ project, scenario, id, ...updatedFunding }) => ({
  type: types.FUNDING_UPDATE,
  payload: { project, scenario, id, ...updatedFunding },
});

const updateFundingSucceeded = ({ ...funding }) => ({
  type: types.FUNDING_UPDATE_SUCCEEDED,
  payload: { ...funding },
});

const updateFundingFailed = ({ message }) => ({
  type: types.FUNDING_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createFunding,
  createFundingFailed,
  createFundingSucceeded,
  deleteFunding,
  deleteFundingFailed,
  deleteFundingSucceeded,
  fetchFunding,
  fetchFundingFailed,
  fetchFundingSucceeded,
  listFundings,
  listFundingsFailed,
  listFundingsSucceeded,
  updateFunding,
  updateFundingFailed,
  updateFundingSucceeded,
};
