const SYSTEM_CLEAR_ERROR = 'bfb/SYSTEM/SYSTEM_CLEAR_ERROR';
const SYSTEMS_LIST = 'bfb/SYSTEM/SYSTEMS_LIST';
const SYSTEMS_LIST_SUCCEEDED = 'bfb/SYSTEM/SYSTEMS_LIST_SUCCEEDED';
const SYSTEMS_LIST_FAILED = 'bfb/SYSTEM/SYSTEMS_LIST_FAILED';
const SYSTEM_CREATE = 'bfb/SYSTEM/SYSTEM_CREATE';
const SYSTEM_CREATE_SUCCEEDED = 'bfb/SYSTEM/SYSTEM_CREATE_SUCCEEDED';
const SYSTEM_CREATE_FAILED = 'bfb/SYSTEM/SYSTEM_CREATE_FAILED';
const SYSTEM_DELETE = 'bfb/SYSTEM/SYSTEM_DELETE';
const SYSTEM_DELETE_SUCCEEDED = 'bfb/SYSTEM/SYSTEM_DELETE_SUCCEEDED';
const SYSTEM_DELETE_FAILED = 'bfb/SYSTEM/SYSTEM_DELETE_FAILED';
const SYSTEM_FETCH = 'bfb/SYSTEM/SYSTEM_FETCH';
const SYSTEM_FETCH_SUCCEEDED = 'bfb/SYSTEM/SYSTEM_FETCH_SUCCEEDED';
const SYSTEM_FETCH_FAILED = 'bfb/SYSTEM/SYSTEM_FETCH_FAILED';
const SYSTEM_UPDATE = 'bfb/SYSTEM/SYSTEM_UPDATE';
const SYSTEM_UPDATE_SUCCEEDED = 'bfb/SYSTEM/SYSTEM_UPDATE_SUCCEEDED';
const SYSTEM_UPDATE_FAILED = 'bfb/SYSTEM/SYSTEM_UPDATE_FAILED';

export default {
  SYSTEMS_LIST,
  SYSTEMS_LIST_SUCCEEDED,
  SYSTEMS_LIST_FAILED,
  SYSTEM_CLEAR_ERROR,
  SYSTEM_CREATE,
  SYSTEM_CREATE_SUCCEEDED,
  SYSTEM_CREATE_FAILED,
  SYSTEM_DELETE,
  SYSTEM_DELETE_SUCCEEDED,
  SYSTEM_DELETE_FAILED,
  SYSTEM_FETCH,
  SYSTEM_FETCH_SUCCEEDED,
  SYSTEM_FETCH_FAILED,
  SYSTEM_UPDATE,
  SYSTEM_UPDATE_SUCCEEDED,
  SYSTEM_UPDATE_FAILED,
};
