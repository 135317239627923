import { createSelector } from 'reselect';

const getState = state => state.keyResult;

const getRawKeyResults = createSelector([getState], state => state.get('data'));

const getKeyResultsById = createSelector([getRawKeyResults], state => state.get('byId'));

const getKeyResultOfId = createSelector([getKeyResultsById], state => id => state.toJS()[id]);

const getKeyResults = createSelector([getKeyResultsById], state => state.valueSeq().toJS());

const getKeyResultsOfYear = year =>
  createSelector([getKeyResultsById], state =>
    state
      .valueSeq()
      .toJS()
      .filter(keyResult => keyResult.year === year)
  );

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getKeyResults,
  getKeyResultsOfYear,
  getKeyResultsById,
  getKeyResultOfId,
  getErrorMessage,
};
