// eslint-disable-next-line import/no-cycle
import { types } from 'store/modules/DeepDive';

const createDeepDive = (payload, callback) => ({
  type: types.DEEPDIVE_CREATE,
  payload,
  callback,
});

const createDeepDiveSucceeded = payload => ({
  type: types.DEEPDIVE_CREATE_SUCCEEDED,
  payload,
});

const createDeepDiveFailed = ({ message }) => ({
  type: types.DEEPDIVE_CREATE_FAILED,
  payload: message,
});

const editDeepDive = (payload, callback) => ({
  type: types.DEEPDIVE_EDIT,
  payload,
  callback,
});

const editDeepDiveSucceeded = payload => ({
  type: types.DEEPDIVE_EDIT_SUCCEEDED,
  payload,
});

const editDeepDiveFailed = ({ message }) => ({
  type: types.DEEPDIVE_EDIT_FAILED,
  payload: message,
});

const deleteDeepDive = payload => ({
  type: types.DEEPDIVE_DELETE,
  payload,
});

const deleteDeepDiveSucceeded = payload => ({
  type: types.DEEPDIVE_DELETE_SUCCEEDED,
  payload,
});

const deleteDeepDiveFailed = ({ message }) => ({
  type: types.DEEPDIVE_DELETE_FAILED,
  payload: message,
});

const deepdiveList = payload => ({
  type: types.DEEPDIVE_LIST,
  payload,
});

const deepdiveListSucceeded = payload => ({
  type: types.DEEPDIVE_LIST_SUCCEEDED,
  payload,
});

const deepdiveListFailed = payload => ({
  type: types.DEEPDIVE_LIST_FAILED,
  payload,
});

export default {
  deepdiveList,
  deepdiveListSucceeded,
  deepdiveListFailed,
  createDeepDive,
  createDeepDiveSucceeded,
  createDeepDiveFailed,
  editDeepDive,
  editDeepDiveSucceeded,
  editDeepDiveFailed,
  deleteDeepDive,
  deleteDeepDiveSucceeded,
  deleteDeepDiveFailed,
};
