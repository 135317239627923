import api from '../api';

const createFeature = ({ project, product, ...feature }) =>
  api.post(`api/projects/${project}/products/${product}/features/`, {
    ...feature,
  });

const deleteFeature = ({ project, product, featureId }) =>
  api.delete(`api/projects/${project}/products/${product}/features/${featureId}/`);

const fetchFeature = ({ project, product, id }) =>
  api.get(`api/projects/${project}/products/${product}/features/${id}/`);

const listFeatures = ({ project, product }) =>
  api.get(`api/projects/${project}/products/${product}/features/`);

const updateFeature = ({ project, product, featureId, ...updatedFeature }) =>
  api.patch(`api/projects/${project}/products/${product}/features/${featureId}/`, {
    ...updatedFeature,
  });

export default {
  createFeature,
  deleteFeature,
  fetchFeature,
  listFeatures,
  updateFeature,
};
