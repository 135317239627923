const NOTIFICATIONS_LIST = 'bfb/notifications/NOTIFICATIONS_LIST';
const NOTIFICATIONS_LIST_SUCCEEDED = 'bfb/notifications/NOTIFICATIONS_LIST_SUCCEEDED';
const NOTIFICATIONS_LIST_FAILED = 'bfb/notifications/NOTIFICATIONS_LIST_FAILED';
const NOTIFICATION_UPDATE = 'bfb/notifications/NOTIFICATION_UPDATE';
const NOTIFICATION_UPDATE_SUCCEEDED = 'bfb/notifications/NOTIFICATION_UPDATE_SUCCEEDED';
const NOTIFICATION_UPDATE_FAILED = 'bfb/notifications/NOTIFICATION_UPDATE_FAILED';

export default {
  NOTIFICATIONS_LIST_FAILED,
  NOTIFICATIONS_LIST_SUCCEEDED,
  NOTIFICATIONS_LIST,
  NOTIFICATION_UPDATE_FAILED,
  NOTIFICATION_UPDATE_SUCCEEDED,
  NOTIFICATION_UPDATE,
};
