import { types } from 'store/modules/ImpactPlan/system';

const clearError = () => ({
  type: types.SYSTEM_CLEAR_ERROR,
});

const createSystem = ({ ...system }) => ({
  type: types.SYSTEM_CREATE,
  payload: { ...system },
});

const createSystemSucceeded = ({ id, ...system }) => ({
  type: types.SYSTEM_CREATE_SUCCEEDED,
  payload: { id, ...system },
});

const createSystemFailed = ({ message }) => ({
  type: types.SYSTEM_CREATE_FAILED,
  payload: { message },
});

const deleteSystem = ({ id }) => ({
  type: types.SYSTEM_DELETE,
  payload: { id },
});

const deleteSystemSucceeded = ({ id }) => ({
  type: types.SYSTEM_DELETE_SUCCEEDED,
  payload: { id },
});

const deleteSystemFailed = ({ message }) => ({
  type: types.SYSTEM_DELETE_FAILED,
  payload: { message },
});

const fetchSystem = ({ id }) => ({
  type: types.SYSTEM_FETCH,
  payload: { id },
});

const fetchSystemSucceeded = ({ id, ...system }) => ({
  type: types.SYSTEM_FETCH_SUCCEEDED,
  payload: { id, ...system },
});

const fetchSystemFailed = ({ message }) => ({
  type: types.SYSTEM_FETCH_FAILED,
  payload: { message },
});

const listSystems = ({ project }) => ({
  type: types.SYSTEMS_LIST,
  payload: { project },
});

const listSystemsSucceeded = payload => ({
  type: types.SYSTEMS_LIST_SUCCEEDED,
  payload,
});

const listSystemsFailed = error => ({
  type: types.SYSTEMS_LIST_FAILED,
  payload: error,
});

const updateSystem = ({ id, ...system }) => ({
  type: types.SYSTEM_UPDATE,
  payload: { id, ...system },
});

const updateSystemSucceeded = ({ id, ...system }) => ({
  type: types.SYSTEM_UPDATE_SUCCEEDED,
  payload: { id, ...system },
});

const updateSystemFailed = ({ message }) => ({
  type: types.SYSTEM_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createSystem,
  createSystemFailed,
  createSystemSucceeded,
  deleteSystem,
  deleteSystemFailed,
  deleteSystemSucceeded,
  fetchSystem,
  fetchSystemFailed,
  fetchSystemSucceeded,
  listSystems,
  listSystemsFailed,
  listSystemsSucceeded,
  updateSystem,
  updateSystemSucceeded,
  updateSystemFailed,
};
