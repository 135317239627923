import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Segment from 'models/ValueProposition/Segment';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byProduct: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SEGMENTS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, segment) =>
                prevData.set(segment.id.toString(), new Segment(segment)).sort(byId),
              state.get('data').get('byId')
            ),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                action.payload.product.toString(),
                fromJS(action.payload.results.map(segment => new Segment(segment)).sort(byId))
              ),
          },
        })
      );
    case types.SEGMENT_FETCH_SUCCEEDED:
    case types.SEGMENT_CREATE_SUCCEEDED:
    case types.SEGMENT_UPDATE_SUCCEEDED:
      return (() => {
        const productId = action.payload.product.toString();
        const productSegments = state
          .get('data')
          .get('byProduct')
          .get(productId, new List())
          .filter(segment => segment.id !== action.payload.id)
          .push(new Segment(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Segment(action.payload)),
              byProduct: state.get('data').get('byProduct').set(productId, productSegments),
            },
          })
        );
      })();
    case types.SEGMENT_FETCH:
    case types.SEGMENT_CREATE:
    case types.SEGMENT_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.SEGMENT_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                action.payload.product.toString(),
                state
                  .get('data')
                  .get('byProduct')
                  .get(action.payload.product.toString(), new List())
                  .filter(segment => segment.id !== action.payload.id)
              ),
          },
        })
      );
    case types.SEGMENT_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.SEGMENT_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.SEGMENT_FETCH_FAILED:
    case types.SEGMENTS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
