import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/Pills';
import pillsService from 'services/pills';

export function* fetchPills(action) {
  try {
    const { category, tool, ...rest } = action.payload;
    const response = yield call(pillsService.listPills, {
      category,
      tool,
      ...rest,
    });
    yield put(
      actions.fetchPillsSucceeded({
        data: response.data,
        category,
        tool,
      })
    );
  } catch (e) {
    yield put(actions.fetchPillsFailed({ message: e.message }));
  }
}

function* pillsSaga() {
  yield all([takeLatest(types.PILLS_FETCH, fetchPills)]);
}

export default pillsSaga;
