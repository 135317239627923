// Libraries
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// Components
import { TableRow as Row } from '../styled-components';
import TableContext from '../Context/context';

const TableRow = props => {
  const { rowKey } = props;

  const { hoveredRow, onRowHover, hoveredRowBackgroundColor } = useContext(TableContext);

  const isHovered = hoveredRow === rowKey;

  return useMemo(
    () => (
      <Row
        {...props}
        onMouseEnter={() => onRowHover(rowKey)}
        onMouseLeave={() => onRowHover(undefined)}
        isHovered={isHovered}
        hoveredRowBackgroundColor={hoveredRowBackgroundColor}
      />
    ),
    [hoveredRowBackgroundColor, isHovered, onRowHover, props, rowKey]
  );
};

TableRow.propTypes = {
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default React.memo(TableRow);
