import { call, put, all, takeLatest } from 'redux-saga/effects';
import surveysResponsesService from 'services/surveyResponses';
import types from './types';
import actions from './actions';

export function* createSurveyResponse(action) {
  try {
    const response = yield call(surveysResponsesService.createSurveyResponses, action.payload);
    yield put(
      actions.createSurveyResponseSucceeded({
        ...response.data,
        ...action.payload,
      })
    );
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      actions.createSurveyResponseFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* surveysResponsesSaga() {
  yield all([takeLatest(types.SURVEYS_RESPONSE_CREATE, createSurveyResponse)]);
}
export default surveysResponsesSaga;
