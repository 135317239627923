import api from '../api';

const fetchOutcomeIndicator = ({ id }) => api.get(`/api/indicators/${id}`);

const createOutcomeIndicator = outcomeIndicator => api.post('/api/indicators/', outcomeIndicator);

const deleteOutcomeIndicator = ({ id }) => api.delete(`/api/indicators/${id}`);

const listOutcomeIndicators = query => api.get('/api/indicators/', { params: query });

const updateOutcomeIndicator = ({ id, ...outcomeIndicator }) =>
  api.patch(`/api/indicators/${id}/`, outcomeIndicator);

export default {
  createOutcomeIndicator,
  deleteOutcomeIndicator,
  fetchOutcomeIndicator,
  listOutcomeIndicators,
  updateOutcomeIndicator,
};
