import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

const getState = state => state.expense;

const getRawExpense = createSelector([getState], state => state.get('data'));

const getExpensesById = createSelector([getRawExpense], state => state.get('byId'));

const getExpensesByScenario = createSelector([getRawExpense], state => state.get('byScenario'));

const getScenarioExpenses = createSelector(
  [getExpensesByScenario],
  state => scenarioId => state.get(String(scenarioId), new List())
);

const getSelectedScenarioExpenses = createSelector([getExpensesByScenario], state => scenarioId => {
  const expenses = state.get(String(scenarioId), new List());
  return expenses.filter(expense => expense.isSelected);
});

const getSelectedScenarioExpensesById = createSelector(
  [getSelectedScenarioExpenses],
  getExpenses => scenarioId =>
    getExpenses(scenarioId).reduce(
      (prevData, expense) => prevData.set(String(expense.id), expense),
      new Map()
    )
);

const getExpenses = createSelector([getExpensesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getErrorMessage,
  getExpenses,
  getExpensesById,
  getExpensesByScenario,
  getScenarioExpenses,
  getSelectedScenarioExpenses,
  getSelectedScenarioExpensesById,
  isFetching,
};
