import types from './types';

const listBeneficiaries = () => ({
  type: types.BENEFICIARIES_LIST,
});

const listBeneficiariesSucceeded = payload => ({
  type: types.BENEFICIARIES_LIST_SUCCEEDED,
  payload,
});

export default {
  listBeneficiaries,
  listBeneficiariesSucceeded,
};
