// Libraries
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

// Dependencies
import { parrotTheme } from 'styles/themes';

// Assets
import { ReactComponent as ActivitySVG } from 'static/icons/07-in-tool/07-1-icons/icon_activity.svg';
import { ReactComponent as OutcomesSVG } from 'static/icons/07-in-tool/07-1-icons/icon_outcomes.svg';
import { ReactComponent as PillsSVG } from 'static/icons/07-in-tool/07-1-icons/icon_pills.svg';
import { ReactComponent as TimeSVG } from 'static/icons/07-in-tool/07-1-icons/icon_time.svg';

// Components
import { Col } from 'antd';
import { H1 } from 'components/fontSystem';
import { DEFAULT_GUTTER, Row } from 'components/layout';
import IconPopover from './IconPopover';
import PopoverContent from './PopoverContent';

const TOOLTIP_PILLS = 'pills';
const TOOLTIP_OUTCOMES = 'outcomes';
const TOOLTIP_TIME = 'time';
const TOOLTIP_ACTIVITY = 'activity';

const icons = {
  [TOOLTIP_PILLS]: PillsSVG,
  [TOOLTIP_OUTCOMES]: OutcomesSVG,
  [TOOLTIP_TIME]: TimeSVG,
  [TOOLTIP_ACTIVITY]: ActivitySVG,
};

function ToolIntroHeader(props) {
  const { className, tooltipContent, title } = props;

  return (
    <Row className={className} type="flex" justify="end">
      <Col xs={12}>
        <H1>{title}</H1>
      </Col>
      <ThemeProvider theme={parrotTheme}>
        <Col xs={12} style={{ textAlign: 'right' }}>
          {Object.entries(tooltipContent).map(([type, content]) => (
            <IconPopover
              key={type + content}
              component={icons[type]}
              content={content}
              placement="bottomRight"
            />
          ))}
        </Col>
      </ThemeProvider>
    </Row>
  );
}

ToolIntroHeader.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string,
  tooltipContent: PropTypes.instanceOf(Object),
};

ToolIntroHeader.defaultProps = {
  title: '',
  tooltipContent: {},
};

const StyledHeader = styled(ToolIntroHeader)`
  margin-bottom: 29px;
  h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 18px;
  }

  &:after {
    background-image: ${({ theme }) =>
      `linear-gradient(315deg, ${theme.secondaryColor} 0%, ${theme.primaryColor} 100%)`};
    content: ' ';
    display: block;
    height: 1px;
    margin: 0 ${DEFAULT_GUTTER / 2}px;
    width: 100%;
  }

  @media (max-width: 1200px) {
    padding-right: 60px;
  }
`;

export default StyledHeader;
export { PopoverContent, TOOLTIP_ACTIVITY, TOOLTIP_OUTCOMES, TOOLTIP_PILLS, TOOLTIP_TIME };
