// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Toggle } from 'components/elements';
import { InfoText } from 'components/fontSystem';

export default function ToolToggle(props) {
  const { colorIndex, children, className, toggleName, onChange, textStyle, ...rest } = props;

  function onChangeHandler(checked, event) {
    if (onChange) {
      onChange(checked, event, toggleName);
    }
  }

  return (
    <Wrapper data-test="component-tool-toggle" className={className}>
      <Toggle data-test="toggle" colorIndex={colorIndex} onChange={onChangeHandler} {...rest} />
      <InfoText
        data-test="info-text"
        $colorIndex={colorIndex}
        className="tool-toggle-info-text"
        style={textStyle}
      >
        {children}
      </InfoText>
    </Wrapper>
  );
}

ToolToggle.propTypes = {
  colorIndex: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  toggleName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  textStyle: PropTypes.instanceOf(Object),
};

ToolToggle.defaultProps = {
  className: null,
  toggleName: null,
  onChange: null,
  textStyle: null,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .tool-toggle-info-text {
    margin-left: 10px;
    margin-bottom: 0;
  }
`;
