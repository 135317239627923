// Libraries
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { P } from 'components/fontSystem';

const PeriodInfo = props => {
  const { title, startDate, endDate, subtext } = props;
  return (
    <Wrapper>
      <P $mb0>{title}</P>
      <P $primary $semibold>
        {startDate} - {endDate}
      </P>
      <Subtitle>{subtext}</Subtitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&& {
    padding: 15px;
    max-width: 220px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(18, 61, 198, 0.2);
  }
`;

const Subtitle = styled.p`
  &&& {
    color: #999999;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
  }
`;

PeriodInfo.propTypes = {
  title: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  subtext: PropTypes.string,
};

PeriodInfo.defaultProps = {
  title: null,
  startDate: null,
  endDate: null,
  subtext: null,
};

export default PeriodInfo;
