import { Row as AntRow } from 'antd';
import { getToolColor } from 'components/fontSystem';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const DEFAULT_GUTTER = 24;

const Row = ({ showDivider = false, gutter = DEFAULT_GUTTER, ...rest }) => (
  <StyledRow $showDivider={showDivider} gutter={gutter} {...rest} />
);

const StyledRow = styled(AntRow)`
  ${({ $showDivider }) =>
    $showDivider &&
    css`
      & > div {
        border-left: 2px solid ${props => getToolColor(props, '#B3B3B3')};
        &:first-child {
          border-left: none;
        }
      }
    `}
`;

Row.propTypes = {
  gutter: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.number]),
  showDivider: PropTypes.bool,
};

export default Row;
export { DEFAULT_GUTTER };
