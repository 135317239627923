import { createSelector } from 'reselect';

const getState = state => state.demoExamples;

const getDemoExamples = createSelector([getState], state => state.data);

const getDemoExamplesList = createSelector([getDemoExamples], state => state.list);

export default {
  getDemoExamples,
  getDemoExamplesList,
};
