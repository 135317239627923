import { Record } from 'immutable';
import ContentType from '../ContentType';

const PartnerRecord = new Record({
  id: null,
  contentType: ContentType.PARTNER,
  name: '',
  product: null,
  project: null,
  type: null,
  resources: [],
  comments: [],
  selected: false,
  initiatives: [],
});

class Partner extends PartnerRecord {
  static contentType = ContentType.PARTNER;
  static TYPE_SUPPLIER = 0;
  static TYPE_STRATEGIC = 1;
  static TYPE_ECOSYSTEM = 2;
  static TYPE_TEAM = 3;

  get id() {
    return this.get('id');
  }
}

export default Partner;
