import api from '../api';

const createGrowthRisk = ({ ...growthRisk }) => api.post('api/growth-risks/', { ...growthRisk });

const deleteGrowthRisk = ({ id }) => api.delete(`api/growth-risks/${id}`);

const fetchGrowthRisk = ({ id }) => api.get(`api/growth-risks/${id}/`);

const listGrowthRisks = query => api.get('/api/growth-risks/', { params: query });

const updateGrowthRisk = ({ id, ...updatedGrowthRisk }) =>
  api.patch(`api/growth-risks/${id}/`, { ...updatedGrowthRisk });

export default {
  createGrowthRisk,
  deleteGrowthRisk,
  fetchGrowthRisk,
  listGrowthRisks,
  updateGrowthRisk,
};
