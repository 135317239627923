import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

const getState = state => state.financialProduct;

const getRawFinancialProduct = createSelector([getState], state => state.get('data'));

const getFinancialProductsById = createSelector([getRawFinancialProduct], state =>
  state.get('byId')
);

const getFinancialProductsByScenario = createSelector([getRawFinancialProduct], state =>
  state.get('byScenario')
);

const getScenarioFinancialProducts = createSelector(
  [getFinancialProductsByScenario],
  state => scenarioId => state.get(String(scenarioId), new List())
);

const getSelectedScenarioFinancialProducts = createSelector(
  [getFinancialProductsByScenario],
  state => scenarioId => {
    const financialProducts = state.get(String(scenarioId), new List());
    return financialProducts.filter(financialProduct => financialProduct.isSelected);
  }
);

const getSelectedScenarioFinancialProductsById = createSelector(
  [getSelectedScenarioFinancialProducts],
  getFinancialProducts => scenarioId =>
    getFinancialProducts(scenarioId).reduce(
      (prevData, financialProduct) => prevData.set(String(financialProduct.id), financialProduct),
      new Map()
    )
);

const getFinancialProducts = createSelector([getFinancialProductsById], state =>
  state.valueSeq().toJS()
);

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getErrorMessage,
  getFinancialProducts,
  getFinancialProductsById,
  getFinancialProductsByScenario,
  getScenarioFinancialProducts,
  getSelectedScenarioFinancialProducts,
  getSelectedScenarioFinancialProductsById,
  isFetching,
};
