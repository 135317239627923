import { call, put, all, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/FinancialProjections/financialProjection';
import serviceProjection from 'services/FinancialProjections/financialProjection';

export function* listProjections(action) {
  try {
    const response = yield call(serviceProjection.listFinancialProjections, action.payload);
    yield put(
      actions.listFinancialProjectionsSucceeded({
        results: response.data,
        ...action.payload,
      })
    );
  } catch (e) {
    yield put(actions.listFinancialProjectionsFailed({ message: e.message }));
  }
}

function* projectionSaga() {
  yield all([takeEvery(types.PROJECTIONS_LIST, listProjections)]);
}

export default projectionSaga;
