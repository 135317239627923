import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Attribute from 'models/CompetitionMap/Attribute';
import serviceAttribute from 'services/CompetitionMap/attribute';
import types from './types';
import actions from './actions';

export function* createAttribute(action) {
  const { project, product, ...attribute } = action.payload;
  try {
    const response = yield call(serviceAttribute.createAttribute, {
      project,
      product,
      ...attribute,
    });
    yield put(actions.createAttributeSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(
      actions.createAttributeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteAttribute(action) {
  const { project, product, attributeId } = action.payload;
  try {
    yield call(serviceAttribute.deleteAttribute, {
      project,
      product,
      attributeId,
    });
    yield put(actions.deleteAttributeSucceeded({ attributeId, product }));
  } catch (e) {
    yield put(
      actions.deleteAttributeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchAttribute(action) {
  const { project, product, id } = action.payload;
  try {
    const response = yield call(serviceAttribute.fetchAttribute, {
      project,
      product,
      id,
    });
    yield put(actions.fetchAttributeSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(actions.fetchAttributeFailed({ message: e.message }));
  }
}

export function* listAttributes(action) {
  const { project, product } = action.payload;
  try {
    const attributes = yield call(serviceAttribute.listAttributes, {
      project,
      product,
    });
    yield put(actions.listAttributesSucceeded({ results: attributes.data, product }));
  } catch (e) {
    yield put(actions.listAttributesFailed({ message: e.message }));
  }
}

export function* updateAttribute(action) {
  const { project, product, attributeId, ...updatedAttribute } = action.payload;
  try {
    const updated = yield call(serviceAttribute.updateAttribute, {
      project,
      product,
      attributeId,
      ...updatedAttribute,
    });
    yield put(actions.updateAttributeSucceeded({ ...updated.data, product }));
  } catch (e) {
    yield put(
      actions.updateAttributeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Attribute.contentType) {
    yield put(
      actions.fetchAttribute({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* attributeSaga() {
  yield all([
    takeLatest(types.ATTRIBUTE_CREATE, createAttribute),
    takeLatest(types.ATTRIBUTE_DELETE, deleteAttribute),
    takeLatest(types.ATTRIBUTE_FETCH, fetchAttribute),
    takeLatest(types.ATTRIBUTE_UPDATE, updateAttribute),
    takeEvery(types.ATTRIBUTES_LIST, listAttributes),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default attributeSaga;
