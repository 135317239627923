import types from './types';

const clearError = () => ({
  type: types.BEHAVIOR_CLEAR_ERROR,
});

const createBehavior = ({ project, ...behavior }) => ({
  type: types.BEHAVIOR_CREATE,
  payload: { project, ...behavior },
});

const createBehaviorSucceeded = ({ project, ...behavior }) => ({
  type: types.BEHAVIOR_CREATE_SUCCEEDED,
  payload: { project, ...behavior },
});

const createBehaviorFailed = ({ message }) => ({
  type: types.BEHAVIOR_CREATE_FAILED,
  payload: { message },
});

const deleteBehavior = ({ project, id }) => ({
  type: types.BEHAVIOR_DELETE,
  payload: { project, id },
});

const deleteBehaviorSucceeded = ({ project, id }) => ({
  type: types.BEHAVIOR_DELETE_SUCCEEDED,
  payload: { project, id },
});

const deleteBehaviorFailed = ({ message }) => ({
  type: types.BEHAVIOR_DELETE_FAILED,
  payload: message,
});

const fetchBehavior = ({ project, id }) => ({
  type: types.BEHAVIOR_FETCH,
  payload: { project, id },
});

const fetchBehaviorSucceeded = ({ project, ...behavior }) => ({
  type: types.BEHAVIOR_FETCH_SUCCEEDED,
  payload: { project, ...behavior },
});

const fetchBehaviorFailed = ({ message }) => ({
  type: types.BEHAVIOR_FETCH_FAILED,
  payload: { message },
});

const listBehaviors = ({ project }) => ({
  type: types.BEHAVIORS_LIST,
  payload: { project },
});

const listBehaviorsSucceeded = payload => ({
  type: types.BEHAVIORS_LIST_SUCCEEDED,
  payload,
});

const listBehaviorsFailed = ({ message }) => ({
  type: types.BEHAVIORS_LIST_FAILED,
  payload: { message },
});

const updateBehavior = ({ id, project, ...updatedBehavior }) => ({
  type: types.BEHAVIOR_UPDATE,
  payload: { id, project, ...updatedBehavior },
});

const updateBehaviorSucceeded = ({ project, ...behavior }) => ({
  type: types.BEHAVIOR_UPDATE_SUCCEEDED,
  payload: { project, ...behavior },
});

const updateBehaviorFailed = ({ message }) => ({
  type: types.BEHAVIOR_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createBehavior,
  createBehaviorFailed,
  createBehaviorSucceeded,
  deleteBehavior,
  deleteBehaviorFailed,
  deleteBehaviorSucceeded,
  fetchBehavior,
  fetchBehaviorFailed,
  fetchBehaviorSucceeded,
  listBehaviors,
  listBehaviorsFailed,
  listBehaviorsSucceeded,
  updateBehavior,
  updateBehaviorFailed,
  updateBehaviorSucceeded,
};
