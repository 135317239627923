import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import servicePositioning from 'services/CompetitionMap/positioning';
import types from './types';
import actions from './actions';

export function* createPositioning(action) {
  const { project, product, competitor, ...positioning } = action.payload;
  try {
    const response = yield call(servicePositioning.createPositioning, {
      project,
      product,
      competitor,
      ...positioning,
    });
    yield put(
      actions.createPositioningSucceeded({
        ...response.data,
        competitor,
        product,
      })
    );
  } catch (e) {
    yield put(
      actions.createPositioningFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deletePositioning(action) {
  const { project, product, competitor, id } = action.payload;
  try {
    yield call(servicePositioning.deletePositioning, {
      project,
      product,
      competitor,
      id,
    });
    yield put(actions.deletePositioningSucceeded({ id, competitor }));
  } catch (e) {
    yield put(
      actions.deletePositioningFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchPositioning(action) {
  const { project, product, competitor, id } = action.payload;
  try {
    const response = yield call(servicePositioning.fetchPositioning, {
      project,
      product,
      competitor,
      id,
    });
    yield put(actions.fetchPositioningSucceeded({ ...response.data, competitor }));
  } catch (e) {
    yield put(actions.fetchPositioningFailed({ message: e.message }));
  }
}

export function* listPositionings(action) {
  const { project, product, competitor } = action.payload;
  try {
    const positionings = yield call(servicePositioning.listPositionings, {
      project,
      product,
      competitor,
    });
    yield put(
      actions.listPositioningsSucceeded({
        results: positionings.data,
        competitor,
      })
    );
  } catch (e) {
    yield put(actions.listPositioningsFailed({ message: e.message }));
  }
}

export function* updatePositioning(action) {
  const { project, product, competitor, id, ...updatedPositioning } = action.payload;
  try {
    const updated = yield call(servicePositioning.updatePositioning, {
      project,
      product,
      competitor,
      id,
      ...updatedPositioning,
    });
    yield put(actions.updatePositioningSucceeded({ ...updated.data, competitor }));
  } catch (e) {
    yield put(
      actions.updatePositioningFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* positioningSaga() {
  yield all([
    takeLatest(types.POSITIONING_CREATE, createPositioning),
    takeLatest(types.POSITIONING_DELETE, deletePositioning),
    takeLatest(types.POSITIONING_FETCH, fetchPositioning),
    takeLatest(types.POSITIONING_UPDATE, updatePositioning),
    takeEvery(types.POSITIONINGS_LIST, listPositionings),
  ]);
}

export default positioningSaga;
