const REASON_CLEAR_ERROR = 'bfb/REASON/REASON_CLEAR_ERROR';
const REASONS_LIST = 'bfb/REASON/REASONS_LIST';
const REASONS_LIST_SUCCEEDED = 'bfb/REASON/REASONS_LIST_SUCCEEDED';
const REASONS_LIST_FAILED = 'bfb/REASON/REASONS_LIST_FAILED';
const REASON_CREATE = 'bfb/REASON/REASON_CREATE';
const REASON_CREATE_SUCCEEDED = 'bfb/REASON/REASON_CREATE_SUCCEEDED';
const REASON_CREATE_FAILED = 'bfb/REASON/REASON_CREATE_FAILED';
const REASON_DELETE = 'bfb/REASON/REASON_DELETE';
const REASON_DELETE_SUCCEEDED = 'bfb/REASON/REASON_DELETE_SUCCEEDED';
const REASON_DELETE_FAILED = 'bfb/REASON/REASON_DELETE_FAILED';
const REASON_FETCH = 'bfb/REASON/REASON_FETCH';
const REASON_FETCH_SUCCEEDED = 'bfb/REASON/REASON_FETCH_SUCCEEDED';
const REASON_FETCH_FAILED = 'bfb/REASON/REASON_FETCH_FAILED';
const REASON_UPDATE = 'bfb/REASON/REASON_UPDATE';
const REASON_UPDATE_SUCCEEDED = 'bfb/REASON/REASON_UPDATE_SUCCEEDED';
const REASON_UPDATE_FAILED = 'bfb/REASON/REASON_UPDATE_FAILED';

export default {
  REASONS_LIST,
  REASONS_LIST_SUCCEEDED,
  REASONS_LIST_FAILED,
  REASON_CLEAR_ERROR,
  REASON_CREATE,
  REASON_CREATE_SUCCEEDED,
  REASON_CREATE_FAILED,
  REASON_DELETE,
  REASON_DELETE_SUCCEEDED,
  REASON_DELETE_FAILED,
  REASON_FETCH,
  REASON_FETCH_SUCCEEDED,
  REASON_FETCH_FAILED,
  REASON_UPDATE,
  REASON_UPDATE_SUCCEEDED,
  REASON_UPDATE_FAILED,
};
