import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Partner from 'models/StakeholderMap/Partner';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byProduct: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PARTNERS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (partnersById, partner) => partnersById.set(String(partner.id), new Partner(partner)),
              state.get('data').get('byId')
            ),
            byProduct: action.payload.product
              ? state
                  .get('data')
                  .get('byProduct', new Map())
                  .set(
                    String(action.payload.product),
                    action.payload.results
                      .reduce((arr, partner) => arr.push(new Partner(partner)), new List())
                      .sort(byId)
                  )
              : {},
          },
        })
      );
    case types.PARTNER_FETCH_SUCCEEDED:
    case types.PARTNER_CREATE_SUCCEEDED:
    case types.PARTNER_UPDATE_SUCCEEDED:
      return (() => {
        const productId = String(action.payload.product);
        const partnersByProduct = state
          .get('data')
          .get('byProduct')
          .get(productId, new List())
          .filter(partner => partner.id !== action.payload.id)
          .push(new Partner(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Partner(action.payload)),
              byProduct: action.payload.product
                ? state.get('data').get('byProduct').set(productId, partnersByProduct)
                : state.get('data').get('byProduct'),
            },
          })
        );
      })();
    case types.PARTNER_FETCH:
    case types.PARTNER_CREATE:
    case types.PARTNER_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PARTNER_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                action.payload.product.toString(),
                state
                  .get('data')
                  .get('byProduct')
                  .get(action.payload.product.toString(), [])
                  .filter(partner => partner.id !== action.payload.id)
              )
              .sort(byId),
          },
        })
      );
    case types.PARTNER_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.PARTNER_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.PARTNER_FETCH_FAILED:
    case types.PARTNERS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
