import { Record } from 'immutable';

const EducationRecord = Record({
  id: null,
  institution: '',
  degree: '',
  field: '',
});

class Education extends EducationRecord {
  get institution() {
    return this.get('institution');
  }

  get degree() {
    return this.get('degree');
  }

  get field() {
    return this.get('field');
  }
}

export default Education;
