import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions as programToolsActions } from 'store/modules/programTools';
import programToolsService from 'services/programTools';
import programService from 'services/program';
import actions from './actions';
import types from './types';

export function* listPrograms(action) {
  try {
    let programs;
    const publicPrograms = action.payload && action.payload.public;
    const organizationPrograms = action.payload && action.payload.organization;
    if (publicPrograms) {
      programs = yield call(programService.listPublicPrograms);
    } else if (organizationPrograms) {
      programs = yield call(programService.listOrganizationPrograms, {
        organization: action.payload.organization,
      });
    } else {
      programs = yield call(programService.listPrograms);
    }

    yield put(
      actions.listProgramsSucceeded({
        ...programs.data,
        public: publicPrograms,
      })
    );
  } catch (e) {
    yield put(actions.listProgramsFailed({ message: e.message }));
  }
}

export function* listProgramsSections() {
  try {
    let programs;
    programs = yield call(programService.listProgramsSections);
    yield put(actions.listProgramsSectionsSucceeded(programs.data));
  } catch (e) {
    yield put(actions.listProgramsSectionsFailed({ message: e.message }));
  }
}

export function* fetchProgram(action) {
  const { id } = action.payload;
  try {
    let program;
    const publicPrograms = action.payload && action.payload.public;
    if (publicPrograms) {
      program = yield call(programService.fetchPublicProgram, { id });
    } else {
      program = yield call(programService.fetchProgram, { id });
    }
    yield put(actions.fetchProgramSucceeded(program.data));
  } catch (e) {
    yield put(actions.fetchProgramFailed({ message: e.message }));
  }
}

export function* searchProgram(action) {
  const { query } = action.payload;
  try {
    const program = yield call(programService.searchPrograms, { query });
    yield put(actions.searchProgramSucceeded({ programs: program.data.results }));
  } catch (e) {
    yield put(actions.searchProgramFailed({ message: e.message }));
  }
}

export function* updateProgramByOrganization(action) {
  const { program, organization, callback } = action.payload;
  try {
    const { programTools = [], ...basicData } = yield program;
    const response = yield all([
      // Do not try fetching the program by org without its necessary data.
      organization &&
        organization.id &&
        basicData &&
        basicData.id &&
        call(programService.updateProgramByOrganization, basicData, organization),
      ...programTools.map(programTool => call(programToolsService.updateProgramTool, programTool)),
    ]);
    const [programResponse, ...programToolsResponses] = response;
    if (programResponse && programResponse.status === 200) {
      yield put(actions.fetchProgramSucceeded(programResponse.data));
    }
    if (programToolsResponses && programToolsResponses.length) {
      const updatedProgramTools = yield programToolsResponses.reduce((array, programTool) => {
        if (programTool.status === 200) {
          array.push(programTool.data);
        }
        return array;
      }, []);
      yield put(programToolsActions.listProgramToolsSucceeded(updatedProgramTools));
    }
    if (callback) {
      yield callback(null);
    }
  } catch (e) {
    yield put(actions.fetchProgramFailed({ message: e.message }));
    if (callback) {
      yield callback({ message: e.message });
    }
  }
}

export function* updateSelectedProgram(action) {
  yield put(actions.fetchProgram({ id: action.payload.selectedProgram.id }));
}

function* programSaga() {
  yield all([
    takeEvery(types.PROGRAM_SEARCH, searchProgram),
    takeEvery(types.PROGRAMS_LIST, listPrograms),
    takeEvery(types.PROGRAMS_SECTIONS_LIST, listProgramsSections),
    takeLatest(types.PROGRAM_FETCH, fetchProgram),
    takeEvery(types.PROGRAM_UPDATE_BY_ORGANIZATION, updateProgramByOrganization),
    takeEvery(types.SELECTED_PROGRAM_UPDATE, updateSelectedProgram),
    // Fetch selected program when updated
  ]);
}

export default programSaga;
