import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Img } from 'components/elements';
import parrot from 'static/images/tools/parrot-drawer.png';
import parrotCircle from 'static/images/07-in-tool/07-2-helper/avatar_parrotbot_circle.png';
import parrotRocket from 'static/images/tools/parrobot_rocket.png';
import { getValueForSize } from 'styles/utils';

const getSrc = (type, size) => {
  if (type === 'rocket') {
    return parrotRocket;
  } else if (size === 'small') {
    return parrotCircle;
  }
  return parrot;
};

const RawParrobotTip = props => {
  const { children, rounded, secondary, size, type, ...rest } = props;

  const src = getSrc(type, size);
  const imgHeight = type === 'parrot' ? 167 : 'auto';

  return (
    <div {...rest}>
      <Img style={{ height: imgHeight }} className="parrot" src={src} alt="Parrot" />
      <div className="text-container">{children}</div>
    </div>
  );
};

RawParrobotTip.propTypes = {
  children: PropTypes.node.isRequired,
  rounded: PropTypes.bool,
  secondary: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
};

RawParrobotTip.defaultProps = {
  rounded: false,
  secondary: false,
  size: 'default',
  type: null,
};

const paddingSizes = {
  small: 10,
  default: 30,
};

const imgMaxWidthSizes = {
  small: 25,
  large: 240,
  xlarge: 320,
  default: 56,
};

const imgMinWidthSizes = {
  small: 25,
  large: 100,
  default: 40,
};

const imgMarginSizes = {
  small: 10,
  default: 14,
};

const containerMarginSizes = {
  small: 20,
  default: 30,
};

const imgLeftMargin = {
  large: -30,
  default: null,
};

const txtLeftMargin = Object.entries(imgMaxWidthSizes).reduce((acc, [key, value]) => {
  acc[key] = value + 15;
  return acc;
}, {});

const ParrobotTip = styled(RawParrobotTip)`
  background-color: ${({ secondary, theme }) =>
    secondary ? theme.itemActiveBg : theme.primaryColorBgFade};
  border-radius: ${({ rounded }) => (rounded ? 4 : 0)}px;
  margin-bottom: ${props => getValueForSize(props, containerMarginSizes)}px;
  margin-top: ${props => getValueForSize(props, containerMarginSizes)}px;
  padding: ${props => getValueForSize(props, paddingSizes)}px;
  position: relative;

  p {
    color: black;
  }
  a {
    color: ${({ theme }) => theme.primaryColor};
    &:hover {
      color: ${({ theme }) => theme.primaryColorHover};
    }
  }
  img {
    bottom: ${({ type }) => (type === 'rocket' ? 0 : 'initial')};
    display: inline-block;
    margin-left: ${props => getValueForSize(props, imgLeftMargin)}px;
    margin-right: ${props => getValueForSize(props, imgMarginSizes)}px;
    max-width: ${props => getValueForSize(props, imgMaxWidthSizes)}px;
    min-width: ${props => getValueForSize(props, imgMinWidthSizes)}px;
    position: absolute;
    vertical-align: text-top;
  }
  .text-container {
    display: inline-block;
    vertical-align: text-top;
    margin-left: ${props => getValueForSize(props, txtLeftMargin)}px;
  }
  @media (max-width: ${({ theme: { screenMdMin } }) => screenMdMin}) {
    img {
      display: none;
    }
    .text-container {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`;

export default ParrobotTip;
