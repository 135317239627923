import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from 'antd';

const { Sider: AntSider } = Layout;

export const zIndex = 10000000;

const Sider = ({ collapsed, onCollapse, children, ...rest }) => (
  <AntSider
    collapsible
    collapsed={collapsed}
    onCollapse={onCollapse}
    collapsedWidth="0"
    trigger={null}
    {...rest}
  >
    {children}
  </AntSider>
);

Sider.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Sider;
