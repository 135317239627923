import api from '../api';

const createMetric = ({ ...metric }) => api.post('api/metrics/', { ...metric });

const deleteMetric = ({ id }) => api.delete(`api/metrics/${id}`);

const fetchMetric = ({ id }) => api.get(`api/metrics/${id}/`);

const listMetrics = ({ project }) => api.get(`api/metrics/?project=${project}`);

const updateMetric = ({ id, ...updatedMetric }) =>
  api.patch(`api/metrics/${id}/`, { ...updatedMetric });

export default {
  createMetric,
  deleteMetric,
  fetchMetric,
  listMetrics,
  updateMetric,
};
