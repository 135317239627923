import styled, { css } from 'styled-components';

const Line = styled.div`
  ${({ primary, theme }) =>
    primary &&
    css`
      background-image: linear-gradient(to right, ${theme.primaryColor}, ${theme.secondaryColor});
    `}

  ${({ primary, color }) =>
    !primary &&
    color &&
    css`
      background-color: ${color};
    `}

  ${({ primary, color, $teal, theme }) =>
    !primary &&
    !color &&
    css`
      background-color: ${$teal ? theme.itemActiveBg : theme.primaryColor};
    `}

  height: ${({ primary }) => (primary ? 2 : 1)}px;
  margin: ${({ size }) => (size === 'large' ? 20 : 6)}px auto;
  width: 100%;
`;

export default Line;
