import { createSelector } from 'reselect';

const getState = state => state.programStep;

const getRawSteps = createSelector([getState], state => state.data);

const getStepsById = createSelector([getRawSteps], state => state.byId);

const getStepsByTool = createSelector([getRawSteps], state => state.byTool);

const getSteps = createSelector([getStepsById], state => Object.values(state));

const getErrorMessage = createSelector([getState], state => state.errorMessage);

export default {
  getSteps,
  getStepsById,
  getErrorMessage,
  getStepsByTool,
};
