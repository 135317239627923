const OUTCOME_INDICATOR_CLEAR_ERROR = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_CLEAR_ERROR';
const OUTCOME_INDICATORS_LIST = 'bfb/PROJECT_SDG/OUTCOME_INDICATORS_LIST';
const OUTCOME_INDICATORS_LIST_SUCCEEDED = 'bfb/PROJECT_SDG/OUTCOME_INDICATORS_LIST_SUCCEEDED';
const OUTCOME_INDICATORS_LIST_FAILED = 'bfb/PROJECT_SDG/OUTCOME_INDICATORS_LIST_FAILED';
const OUTCOME_INDICATOR_CREATE = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_CREATE';
const OUTCOME_INDICATOR_CREATE_SUCCEEDED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_CREATE_SUCCEEDED';
const OUTCOME_INDICATOR_CREATE_FAILED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_CREATE_FAILED';
const OUTCOME_INDICATOR_DELETE = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_DELETE';
const OUTCOME_INDICATOR_DELETE_SUCCEEDED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_DELETE_SUCCEEDED';
const OUTCOME_INDICATOR_DELETE_FAILED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_DELETE_FAILED';
const OUTCOME_INDICATOR_FETCH = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_FETCH';
const OUTCOME_INDICATOR_FETCH_SUCCEEDED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_FETCH_SUCCEEDED';
const OUTCOME_INDICATOR_FETCH_FAILED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_FETCH_FAILED';
const OUTCOME_INDICATOR_UPDATE = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_UPDATE';
const OUTCOME_INDICATOR_UPDATE_SUCCEEDED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_UPDATE_SUCCEEDED';
const OUTCOME_INDICATOR_UPDATE_FAILED = 'bfb/PROJECT_SDG/OUTCOME_INDICATOR_UPDATE_FAILED';

export default {
  OUTCOME_INDICATORS_LIST,
  OUTCOME_INDICATORS_LIST_SUCCEEDED,
  OUTCOME_INDICATORS_LIST_FAILED,
  OUTCOME_INDICATOR_CLEAR_ERROR,
  OUTCOME_INDICATOR_CREATE,
  OUTCOME_INDICATOR_CREATE_SUCCEEDED,
  OUTCOME_INDICATOR_CREATE_FAILED,
  OUTCOME_INDICATOR_DELETE,
  OUTCOME_INDICATOR_DELETE_SUCCEEDED,
  OUTCOME_INDICATOR_DELETE_FAILED,
  OUTCOME_INDICATOR_FETCH,
  OUTCOME_INDICATOR_FETCH_SUCCEEDED,
  OUTCOME_INDICATOR_FETCH_FAILED,
  OUTCOME_INDICATOR_UPDATE,
  OUTCOME_INDICATOR_UPDATE_SUCCEEDED,
  OUTCOME_INDICATOR_UPDATE_FAILED,
};
