import { createSelector } from 'reselect';

const getState = state => state.attribute;

const getRawAttribute = createSelector([getState], state => state.get('data'));

const getAttributesById = createSelector([getRawAttribute], state => state.get('byId'));

const getAttributesByProduct = createSelector([getRawAttribute], state => state.get('byProduct'));

const getAttributes = createSelector([getAttributesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getAttributes,
  getAttributesById,
  getAttributesByProduct,
  getErrorMessage,
};
