import { css } from 'styled-components';
import bfbTheme from 'styles/b4b-theme';

const InputStyle = css`
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 0 5px;
  background-color: transparent;
  border: 0;
  border-bottom: 1.25px solid ${bfbTheme.placeholderGrey};
  border-radius: 0;
  color: black;
  font-weight: normal;

  ::placeholder,
  &::placeholder,
  .ant-select-selection-placeholder {
    font-weight: 100;
    font-style: italic;
  }

  &:focus,
  &:hover {
    border-color: ${bfbTheme.mainColor};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: transparent !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  &.remove-required .ant-form-item-required:before {
    content: '';
  }

  .ant-form-item-label {
    padding-bottom: 11px;
  }
`;

export default InputStyle;
