import api from '../api';

const createOutcome = ({ project, product, segment, ...outcome }) =>
  api.post(`api/projects/${project}/products/${product}/segments/${segment}/outcomes/`, {
    ...outcome,
  });

const deleteOutcome = ({ project, product, segment, outcomeId }) =>
  api.delete(
    `api/projects/${project}/products/${product}/segments/${segment}/outcomes/${outcomeId}/`
  );

const fetchOutcome = ({ project, product, segment, id }) =>
  api.get(`api/projects/${project}/products/${product}/segments/${segment}/outcomes/${id}`);

const listOutcomes = ({ project, product, segment }) =>
  api.get(`api/projects/${project}/products/${product}/segments/${segment}/outcomes/`);

const updateOutcome = ({ project, product, segment, outcomeId, ...outcome }) =>
  api.patch(
    `api/projects/${project}/products/${product}/segments/${segment}/outcomes/${outcomeId}/`,
    { ...outcome }
  );

export default {
  createOutcome,
  deleteOutcome,
  fetchOutcome,
  listOutcomes,
  updateOutcome,
};
