import types from './types';

const clearError = () => ({
  type: types.INITIATIVE_CLEAR_ERROR,
});

const createInitiative = ({ objective, project, ...initiative }, callback) => ({
  type: types.INITIATIVE_CREATE,
  payload: { objective, project, ...initiative },
  callback,
});

const createInitiativeSucceeded = ({ project, ...initiative }) => ({
  type: types.INITIATIVE_CREATE_SUCCEEDED,
  payload: { project, ...initiative },
});

const createInitiativeFailed = ({ message }) => ({
  type: types.INITIATIVE_CREATE_FAILED,
  payload: { message },
});

const deleteInitiative = ({ project, id }) => ({
  type: types.INITIATIVE_DELETE,
  payload: { project, id },
});

const deleteInitiativeSucceeded = ({ project, id }) => ({
  type: types.INITIATIVE_DELETE_SUCCEEDED,
  payload: { project, id },
});

const deleteInitiativeFailed = ({ message }) => ({
  type: types.INITIATIVE_DELETE_FAILED,
  payload: message,
});

const fetchInitiative = ({ project, id }) => ({
  type: types.INITIATIVE_FETCH,
  payload: { project, id },
});

const fetchInitiativeSucceeded = ({ project, ...initiative }) => ({
  type: types.INITIATIVE_FETCH_SUCCEEDED,
  payload: { project, ...initiative },
});

const fetchInitiativeFailed = ({ message }) => ({
  type: types.INITIATIVE_FETCH_FAILED,
  payload: { message },
});

const listInitiatives = ({ project }) => ({
  type: types.INITIATIVES_LIST,
  payload: { project },
});

const listInitiativesSucceeded = payload => ({
  type: types.INITIATIVES_LIST_SUCCEEDED,
  payload,
});

const listInitiativesFailed = ({ message }) => ({
  type: types.INITIATIVES_LIST_FAILED,
  payload: { message },
});

const updateInitiative = ({ id, project, ...updatedInitiative }) => ({
  type: types.INITIATIVE_UPDATE,
  payload: { id, project, ...updatedInitiative },
});

const updateInitiativeSucceeded = ({ project, ...initiative }) => ({
  type: types.INITIATIVE_UPDATE_SUCCEEDED,
  payload: { project, ...initiative },
});

const updateInitiativeFailed = ({ message }) => ({
  type: types.INITIATIVE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createInitiative,
  createInitiativeFailed,
  createInitiativeSucceeded,
  deleteInitiative,
  deleteInitiativeFailed,
  deleteInitiativeSucceeded,
  fetchInitiative,
  fetchInitiativeFailed,
  fetchInitiativeSucceeded,
  listInitiatives,
  listInitiativesFailed,
  listInitiativesSucceeded,
  updateInitiative,
  updateInitiativeFailed,
  updateInitiativeSucceeded,
};
