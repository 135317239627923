import { call, put, all, takeLatest } from 'redux-saga/effects';
import notificationsService from 'services/notifications';
import types from './types';
import actions from './actions';

export function* listNotifications(action) {
  try {
    const response = yield call(notificationsService.listNotifications, action.payload);
    yield put(
      actions.listNotificationsSucceeded({
        ...action.payload,
        ...response.data,
      })
    );
    if (action.callback) yield call(action.callback);
  } catch (e) {
    yield put(actions.listNotificationsFailed({ message: e.message }));
  }
}
export function* updateNotification(action) {
  const { id, ...notification } = action.payload;
  try {
    const response = yield call(notificationsService.updateNotification, {
      id,
      ...notification,
    });
    yield put(actions.updateNotificationSucceeded(response.data));
  } catch (e) {
    yield put(actions.updateNotificationFailed({ message: e.message }));
  }
}

function* notificationsSaga() {
  yield all([
    takeLatest(types.NOTIFICATIONS_LIST, listNotifications),
    takeLatest(types.NOTIFICATION_UPDATE, updateNotification),
  ]);
}
export default notificationsSaga;
