import { Record } from 'immutable';
import Member from 'models/Member';
import Mentor from 'models/Mentor';
import ContentType from './ContentType';

/**
 * Possible project statuses fetched from the backend.
 */
export const PROGRAM_COMPLETED = 100;
export const PROJECT_PROGRESS_EOI = 80;
export const MENTORS_PROGRAM_COMPLETED = 66;
export const PROJECT_STATUS_APPLICATION = 'application';
export const PROJECT_STATUS_INTERVIEW = 'interview';
export const PROJECT_STATUS_MATCHING = 'matching';
export const PROJECT_STATUS_INCUBATING = 'incubating';
export const PROJECT_STATUS_ALUMNI = 'alumni';

export const PM_STATUS_APPLICATION = 'incomplete';
export const PM_STATUS_SELECTION = 'selection';
export const PM_STATUS_APPROVED = 'approved';
export const PM_STATUS_MATCHING = 'matching';
export const PM_STATUS_INCUBATION = 'incubation';
export const PM_STATUS_ALUMNI = 'alumni';
export const PM_STATUS_DROPPED = 'dropped';
export const PM_STATUS_REJECTED = 'rejected';

// New Statuses PM Panel

export const ALUMNI = 'alumni';
export const DROPPED = 'dropped';
export const REJECTED = 'rejected';

// End of new statuses of PM Panel

export const PROJECT_STATUSES = [
  PROJECT_STATUS_APPLICATION,
  PROJECT_STATUS_INTERVIEW,
  PROJECT_STATUS_MATCHING,
  PROJECT_STATUS_INCUBATING,
  PROJECT_STATUS_ALUMNI,
];

export const getProjectStatusDisplay = (t, status) =>
  ({
    [PROJECT_STATUS_APPLICATION]: t('application'),
    [PROJECT_STATUS_INTERVIEW]: t('interview'),
    [PROJECT_STATUS_MATCHING]: t('matching'),
    [PROJECT_STATUS_INCUBATING]: t('incubating'),
    [PROJECT_STATUS_ALUMNI]: t('alumni'),
  })[status];

/**
 * Project stages.
 */
export const IDEA_OR_DEVELOPED_CONCEPT = 1;
export const PROJECT_OR_MVP = 2;
export const VALIDATED_OR_STARTUP = 3;
export const SCALE_UP_BUSINESS = 4;

export const getStageForFilter = t => [
  {
    text: t('Scale Up of Business'),
    value: SCALE_UP_BUSINESS,
  },
  {
    text: t('Validated or Startup'),
    value: VALIDATED_OR_STARTUP,
  },
  {
    text: t('Project or Min. Viable Product'),
    value: PROJECT_OR_MVP,
  },
  {
    text: t('Idea or Developed Concept'),
    value: IDEA_OR_DEVELOPED_CONCEPT,
  },
];

export const getProjectStageDisplay = (t, stage) => {
  switch (stage) {
    case SCALE_UP_BUSINESS:
      return t('Scale Up of Business');
    case VALIDATED_OR_STARTUP:
      return t('Validated or Startup');
    case PROJECT_OR_MVP:
      return t('Project or Min. Viable Product');
    case IDEA_OR_DEVELOPED_CONCEPT:
      return t('Idea or Developed Concept');
    default:
      return t('No data yet.');
  }
};

const ProjectRecord = new Record({
  id: null,
  allow_information: null,
  application_id: null,
  application_additional_answer: null,
  application_need_grant: null,
  application_status: null,
  application_ranking_submitted: null,
  application_recommended_program_referrer: null,
  application_referrer: null,
  application_rejected: null,
  application_rejected_reason: null,
  application_progress: 0,
  application_rating: 0,
  application_additional_answer_1: null,
  application_additional_answer_2: null,
  application_additional_answer_3: null,
  application_additional_answer_4: null,
  application_additional_answer_5: null,
  application_additional_answer_6: null,
  application_additional_answer_7: null,
  area_innovation_name: '',
  bootcamp_completed: null,
  business_model: null,
  challenges: null,
  city: null,
  comments: [],
  company: null,
  competition_map_days: null,
  competition_map_engagement: null,
  competition_map_start: null,
  competition_map_status: null,
  competitive_advantage: null,
  competitors: null,
  contentType: ContentType.PROJECT,
  country: null,
  country_sort: null,
  created: null,
  currency: null,
  demographic_statement: null,
  demo: false,
  expertise_needed: null,
  explore: null,
  financial_projection_month: null,
  financial_projection_status: null,
  financial_projection_summary: null,
  financial_projection_year: null,
  financial_projections_days: null,
  financial_projections_engagement: null,
  financial_projections_start: null,
  financial_forecast: null,
  go_to_market_strategy: null,
  growth_market_strategy: null,
  growth_plan_days: null,
  growth_plan_engagement: null,
  growth_plan_start: null,
  growth_plan_status: null,
  hypothesis_experiments_days: null,
  hypothesis_experiments_engagement: null,
  hypothesis_experiments_start: null,
  hypothesis_experiments_status: null,
  industry: null,
  initial_operating_capital: null,
  interest: null,
  invited: false,
  key_activities_days: null,
  key_activities_engagement: null,
  key_activities_start: null,
  key_activities_status: null,
  language: '',
  market: null,
  matched_invites_count: 0,
  members: [],
  mentors: [],
  milestones: null,
  mission_statement: null,
  mission: null,
  motivation: null,
  name: '',
  name_sort: '',
  notes: '',
  objective_enable: null,
  objective_statement: null,
  owner: null,
  picture: null,
  pitch_days: null,
  pitch_engagement: null,
  pitch_start: null,
  pricing_models_days: null,
  pricing_models_engagement: null,
  pricing_models_start: null,
  pricing_status: null,
  program_progress: null,
  program: null,
  products: [],
  sector: null,
  sector_name: '',
  skills: null,
  signed_invites_count: 0,
  social_impact_days: null,
  social_impact_engagement: null,
  social_impact_start: null,
  social_impact_status: null,
  social_impact: null,
  technology_component: null,
  no_sustainable_development_goals: null,
  stage: null,
  stage_checks: [],
  stakeholder_map_days: null,
  stakeholder_map_engagement: null,
  stakeholder_map_start: null,
  stakeholder_map_status: null,
  status: null,
  spreadsheet_type: null,
  summary: '',
  survey_responses_pti: [],
  systemic_change_statement: null,
  tag: null,
  target: '',
  total_fixed_assets: null,
  total_required_funds: null,
  total_sources_funds: null,
  updated: null,
  value_proposition_days: null,
  value_proposition_engagement: null,
  value_proposition_start: null,
  value_proposition_status: null,
  video_url: null,
  vision_statement: null,
  website: null,
  interestships: [],
});

class Project extends ProjectRecord {
  static contentType = ContentType.PROJECT;

  get id() {
    return this.get('id');
  }

  /**
   * Iterating over the members and mentors array, to give them proper structures.
   */
  constructor(project) {
    if (project) {
      const alteredProject = { ...project };
      alteredProject.members =
        alteredProject.members && alteredProject.members.map(member => new Member(member).toJS());
      alteredProject.mentors =
        alteredProject.mentors && alteredProject.mentors.map(mentor => new Mentor(mentor).toJS());
      super(alteredProject);
    } else {
      super(project);
    }
  }

  getProjectStageDisplay = t => getProjectStageDisplay(t, this.get('stage'));
}

export default Project;
