import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions as memberApplicationActions } from 'store/modules/memberApplication';
import applicationService from 'services/memberApplication';
import { actions as programActions } from 'store/modules/program';
import programService from 'services/program';
import { actions as projectActions } from 'store/modules/project';
import { actions as productActions } from 'store/modules/product';
import actions from './actions';
import types from './types';

export function* fetchMyProgramDependencies(action) {
  const { id } = action.payload;
  try {
    let program;
    try {
      program = yield call(programService.fetchPublicProgram, { id });
    } catch (e) {
      if (e.response.status === 404) {
        try {
          // Program is no longer available in public endpoint, let's try to get it from /programs/
          program = yield call(programService.fetchProgram, { id });
        } catch (error) {
          if (error.response.status === 404) {
            action.errorCallback();
          } else {
            throw error;
          }
        }
      } else {
        throw e;
      }
    }
    yield put(programActions.fetchProgramSucceeded(program.data));
    if (program.data.member_application) {
      const application = yield call(applicationService.fetchApplication, {
        id: program.data.member_application,
      });
      try {
        yield put(memberApplicationActions.fetchApplicationSucceeded(application.data));
        yield put(projectActions.fetchProject({ id: application.data.project }));
        yield put(productActions.listProducts({ project: application.data.project }));
      } catch (error) {
        if (error.response.status === 404) {
          action.errorCallback();
        } else {
          throw error;
        }
      }
    }
  } catch (e) {
    yield put(
      actions.fetchDependenciesFailed({
        message: e.message || e.response.data.detail,
      })
    );
  }
}

export function* createApplicationAndFetchDependencies(action) {
  const { program, ...application } = action.payload;
  try {
    const created = yield call(applicationService.createApplication, {
      program,
      ...application,
    });
    yield put(memberApplicationActions.createApplicationSucceeded(created.data));
    // FIXME: call fetchMyProgramDependencies({ id: program });
    // Couldn't make it work
    try {
      const updatedProgram = yield call(programService.fetchPublicProgram, {
        id: program,
      });
      yield put(programActions.fetchProgramSucceeded(updatedProgram.data));
      if (updatedProgram.data.member_application) {
        const createdApplication = yield call(applicationService.fetchApplication, {
          id: updatedProgram.data.member_application,
        });
        yield put(memberApplicationActions.fetchApplicationSucceeded(createdApplication.data));
        yield put(projectActions.fetchProject({ id: createdApplication.data.project }));
        yield put(
          productActions.listProducts({
            project: createdApplication.data.project,
          })
        );
        if (action.callback) {
          action.callback();
        }
      }
    } catch (e) {
      yield put(
        actions.fetchDependenciesFailed({
          message: e.message || e.response.data.detail,
        })
      );
    }
  } catch (e) {
    yield put(
      actions.createApplicationAndFetchDependenciesFailed({
        message: e.message || e.response.data.detail,
      })
    );
  }
}

function* myProgramCombiner() {
  yield all([
    takeLatest(types.FETCH_MY_PROGRAM_DEPENDENCIES, fetchMyProgramDependencies),
    takeLatest(
      types.CREATE_APPLICATION_AND_FETCH_DEPENDENCIES,
      createApplicationAndFetchDependencies
    ),
  ]);
}

export default myProgramCombiner;
