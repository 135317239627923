// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import { Popover } from 'antd';

const ToolPopover = props => {
  const { content, tooltip, placement, ...rest } = props;
  return (
    <Popover
      content={content}
      placement={placement || 'bottomRight'}
      overlayClassName="tool-icon-popover"
      {...rest}
    >
      {tooltip ? (
        <div className="tool-tooltip-popover">{tooltip}</div>
      ) : (
        <span className="default-tooltip">i</span>
      )}
    </Popover>
  );
};

ToolPopover.propTypes = {
  content: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
  placement: PropTypes.string,
};

ToolPopover.defaultProps = {
  tooltip: undefined,
  placement: 'bottom',
};

const StyledToolPopover = styled(ToolPopover).attrs({
  overlayClassName: 'tool-icon-popover-teal',
})`
  user-select: none;
  ${({ tooltip }) =>
    tooltip
      ? css`
          display: inline-block;
          width: fit-content;
        `
      : css`
          width: 21px;
          height: 21px;
          color: white !important;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background-color: #b3b3b3;
          &:hover {
            background: ${({ theme }) => theme.secondaryColor};
          }
        `}
`;

export default StyledToolPopover;
