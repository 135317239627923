const SEGMENT_CLEAR_ERROR = 'bfb/segment/SEGMENT_CLEAR_ERROR';
const SEGMENTS_LIST = 'bfb/segment/SEGMENTS_LIST';
const SEGMENTS_LIST_ALL = 'bfb/segment/SEGMENTS_LIST_ALL';
const SEGMENTS_LIST_SUCCEEDED = 'bfb/segment/SEGMENTS_LIST_SUCCEEDED';
const SEGMENTS_LIST_FAILED = 'bfb/segment/SEGMENTS_LIST_FAILED';
const SEGMENT_CREATE = 'bfb/segment/SEGMENT_CREATE';
const SEGMENT_CREATE_SUCCEEDED = 'bfb/segment/SEGMENT_CREATE_SUCCEEDED';
const SEGMENT_CREATE_FAILED = 'bfb/segment/SEGMENT_CREATE_FAILED';
const SEGMENT_DELETE = 'bfb/segment/SEGMENT_DELETE';
const SEGMENT_DELETE_SUCCEEDED = 'bfb/segment/SEGMENT_DELETE_SUCCEEDED';
const SEGMENT_DELETE_FAILED = 'bfb/segment/SEGMENT_DELETE_FAILED';
const SEGMENT_FETCH = 'bfb/segment/SEGMENT_FETCH';
const SEGMENT_FETCH_SUCCEEDED = 'bfb/segment/SEGMENT_FETCH_SUCCEEDED';
const SEGMENT_FETCH_FAILED = 'bfb/segment/SEGMENT_FETCH_FAILED';
const SEGMENT_UPDATE = 'bfb/segment/SEGMENT_UPDATE';
const SEGMENT_UPDATE_SUCCEEDED = 'bfb/segment/SEGMENT_UPDATE_SUCCEEDED';
const SEGMENT_UPDATE_FAILED = 'bfb/segment/SEGMENT_UPDATE_FAILED';

export default {
  SEGMENTS_LIST,
  SEGMENTS_LIST_ALL,
  SEGMENTS_LIST_SUCCEEDED,
  SEGMENTS_LIST_FAILED,
  SEGMENT_CLEAR_ERROR,
  SEGMENT_CREATE,
  SEGMENT_CREATE_SUCCEEDED,
  SEGMENT_CREATE_FAILED,
  SEGMENT_DELETE,
  SEGMENT_DELETE_SUCCEEDED,
  SEGMENT_DELETE_FAILED,
  SEGMENT_FETCH,
  SEGMENT_FETCH_SUCCEEDED,
  SEGMENT_FETCH_FAILED,
  SEGMENT_UPDATE,
  SEGMENT_UPDATE_SUCCEEDED,
  SEGMENT_UPDATE_FAILED,
};
