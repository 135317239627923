import types from './types';

const listProgramMentors = ({ organizationId, programId, ...query }) => ({
  type: types.PROGRAM_MENTORS_LIST,
  payload: { organizationId, programId, ...query },
});

const listProgramMentorsSucceeded = (programMentors, count) => ({
  type: types.PROGRAM_MENTORS_LIST_SUCCEEDED,
  payload: {
    count,
    programMentors,
  },
});

const listProgramMentorsFailed = ({ message }) => ({
  type: types.PROGRAM_MENTORS_LIST_FAILED,
  payload: { message },
});

const listProgramMentorsByCategory = ({
  organizationId,
  programId,
  mentor_status: category,
  ...query
}) => ({
  type: types.PROGRAM_MENTORS_LIST_BY_CATEGORY,
  payload: { organizationId, programId, category, ...query },
});

const listProgramMentorsByCategoryPending = () => ({
  type: types.PROGRAM_MENTORS_LIST_PENDING,
});

const listProgramMentorsByCategorySucceeded = (programMentors, count, category) => ({
  type: types.PROGRAM_MENTORS_LIST_BY_CATEGORY_SUCCEEDED,
  payload: {
    category,
    count,
    programMentors,
  },
});

const listProgramMentorsByCategoryFailed = ({ message }) => ({
  type: types.PROGRAM_MENTORS_LIST_BY_CATEGORY_FAILED,
  payload: { message },
});

export default {
  listProgramMentors,
  listProgramMentorsSucceeded,
  listProgramMentorsFailed,
  listProgramMentorsByCategory,
  listProgramMentorsByCategorySucceeded,
  listProgramMentorsByCategoryFailed,
  listProgramMentorsByCategoryPending,
};
