import { Record } from 'immutable';

const MilestoneRecord = new Record({
  id: null,
  project: null,
  name: null,
  year: null,
  selected: false,
  created: null,
  updated: null,
  comments: [],
  contentType: 'bfb_growth_plan.milestone',
});

class Milestone extends MilestoneRecord {
  static contentType = 'bfb_growth_plan.milestone';

  get id() {
    return this.get('id');
  }
}

export default Milestone;
