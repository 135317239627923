import { Record } from 'immutable';
import ContentType from '../ContentType';

const ProblemRecord = Record({
  id: null,
  contentType: ContentType.PROBLEM,
  problem: '',
  solution: '',
  project: null,
  comments: [],
  selected: false,

  created: null,
  updated: null,
});

class Problem extends ProblemRecord {
  static contentType = ContentType.PROBLEM;
  get getId() {
    return this.get('id');
  }
}

export default Problem;
