import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import SdgTarget from 'models/ImpactPlan/SdgTarget';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      bySdg: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SDG_TARGETS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (sdgTargetsById, sdgTarget) =>
                sdgTargetsById.set(String(sdgTarget.id), new SdgTarget(sdgTarget)),
              state.get('data').get('byId')
            ),
            bySdg: action.payload.results.reduce((targetsBySdg, sdgTarget) => {
              const newTargetsBySdg = { ...targetsBySdg };
              // I hate immutable.js!
              newTargetsBySdg[String(sdgTarget.sdg)] =
                newTargetsBySdg[String(sdgTarget.sdg)] &&
                newTargetsBySdg[String(sdgTarget.sdg)].length
                  ? // Spreading target into existing svg targets array.
                    [...newTargetsBySdg[String(sdgTarget.sdg)], new SdgTarget(sdgTarget)]
                  : // New array
                    [new SdgTarget(sdgTarget)];
              return newTargetsBySdg;
            }, state.get('data').get('bySdg')),
          },
        })
      );
    case types.SDG_TARGETS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
