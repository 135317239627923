const FEATURE_CLEAR_ERROR = 'bfb/competitionMap/features/FEATURE_CLEAR_ERROR';
const FEATURES_LIST = 'bfb/competitionMap/features/FEATURES_LIST';
const FEATURES_LIST_SUCCEEDED = 'bfb/competitionMap/features/FEATURES_LIST_SUCCEEDED';
const FEATURES_LIST_FAILED = 'bfb/competitionMap/features/FEATURES_LIST_FAILED';
const FEATURE_CREATE = 'bfb/competitionMap/features/FEATURE_CREATE';
const FEATURE_CREATE_SUCCEEDED = 'bfb/competitionMap/features/FEATURE_CREATE_SUCCEEDED';
const FEATURE_CREATE_FAILED = 'bfb/competitionMap/features/FEATURE_CREATE_FAILED';
const FEATURE_DELETE = 'bfb/competitionMap/features/FEATURE_DELETE';
const FEATURE_DELETE_SUCCEEDED = 'bfb/competitionMap/features/FEATURE_DELETE_SUCCEEDED';
const FEATURE_DELETE_FAILED = 'bfb/competitionMap/features/FEATURE_DELETE_FAILED';
const FEATURE_FETCH = 'bfb/competitionMap/features/FEATURE_FETCH';
const FEATURE_FETCH_SUCCEEDED = 'bfb/competitionMap/features/FEATURE_FETCH_SUCCEEDED';
const FEATURE_FETCH_FAILED = 'bfb/competitionMap/features/FEATURE_FETCH_FAILED';
const FEATURE_UPDATE = 'bfb/competitionMap/features/FEATURE_UPDATE';
const FEATURE_UPDATE_SUCCEEDED = 'bfb/competitionMap/features/FEATURE_UPDATE_SUCCEEDED';
const FEATURE_UPDATE_FAILED = 'bfb/competitionMap/features/FEATURE_UPDATE_FAILED';

export default {
  FEATURES_LIST,
  FEATURES_LIST_SUCCEEDED,
  FEATURES_LIST_FAILED,
  FEATURE_CLEAR_ERROR,
  FEATURE_CREATE,
  FEATURE_CREATE_SUCCEEDED,
  FEATURE_CREATE_FAILED,
  FEATURE_DELETE,
  FEATURE_DELETE_SUCCEEDED,
  FEATURE_DELETE_FAILED,
  FEATURE_FETCH,
  FEATURE_FETCH_SUCCEEDED,
  FEATURE_FETCH_FAILED,
  FEATURE_UPDATE,
  FEATURE_UPDATE_SUCCEEDED,
  FEATURE_UPDATE_FAILED,
};
