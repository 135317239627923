// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Needle = props => {
  const {
    value,
    range,
    width,
    strokeWidth,
    // needleHeightRatio is relative to the radius.
    needleHeightRatio,
  } = props;
  const [minValue, maxValue] = range;
  // The radius will be the height of the needle
  const radius = (width / 2 - strokeWidth) * needleHeightRatio;
  /**
   * The needle's rotation is determined by the current value.
   * We will see the value relative to the range, then we will
   * calculate the roation depending on the relative value.
   * For example:
   * - If the value is at 0%, the rotation is 0 deg.
   * - If the value is at 100%, the rotation is 180 deg.
   * It's important to then decrement the rotation by 90 degrees,
   * this is to align it with the circle due to the CSS.
   */
  const relativeValue = (value / (maxValue - minValue)) * 100;
  const deg = (relativeValue / 100) * 180 - 90;
  return (
    <React.Fragment>
      <StyledNeedle height={radius} deg={deg} />
      <Pivot />
    </React.Fragment>
  );
};

Needle.propTypes = {
  value: PropTypes.number,
  range: PropTypes.instanceOf(Array),
  width: PropTypes.number,
  strokeWidth: PropTypes.number,
  needleHeightRatio: PropTypes.number,
};

Needle.defaultProps = {
  value: undefined,
  range: undefined,
  width: undefined,
  strokeWidth: undefined,
  needleHeightRatio: undefined,
};

const StyledNeedle = styled.div`
  width: 5px;
  height: ${({ height }) => height}px;
  background: #b3b3b3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  display: inline-block;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%) rotate(${({ deg }) => deg}deg);
  transition: transform cubic-bezier(0.68, -0.25, 0.265, 1.25) 500ms;
  position: absolute;
  transform-origin: 50% 100%;
`;

const Pivot = styled.div`
  position: absolute;
  transform: translateX(-50%);
  bottom: -5px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b3b3b3;
`;

export default React.memo(Needle);
