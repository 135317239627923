import { Record } from 'immutable';

import ContentType from '../ContentType';

const PricingModelRecord = new Record({
  id: null,
  colorIndex: 0,
  description: null,
  initial_price: null,
  long_term_price: null,
  name: null,
  product_pricing_model: null,
  selected: null,
  step: null,
  subtitle: null,
});

class PricingModel extends PricingModelRecord {
  static contentType = ContentType.PRICINGMODEL;
  get id() {
    return this.get('id');
  }
}

const getOtherPricingModel = t =>
  new PricingModel({
    name: t('Other'),
    subtitle: t('Choose this option if a custom pricing model suits your project'),
    selected: true,
    step: 3,
  });

export default PricingModel;
export { getOtherPricingModel };
