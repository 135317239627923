import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Positioning from 'models/CompetitionMap/Positioning';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byCompetitor: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.POSITIONINGS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, positioning) =>
                prevData.set(positioning.id.toString(), new Positioning(positioning)).sort(byId),
              state.get('data').get('byId')
            ),
            byCompetitor: state
              .get('data')
              .get('byCompetitor')
              .set(
                action.payload.competitor.toString(),
                fromJS(
                  action.payload.results.map(positioning => new Positioning(positioning)).sort(byId)
                )
              ),
          },
        })
      );
    case types.POSITIONING_FETCH_SUCCEEDED:
    case types.POSITIONING_CREATE_SUCCEEDED:
    case types.POSITIONING_UPDATE_SUCCEEDED:
      return (() => {
        const competitorId = action.payload.competitor.toString();
        const competitorPositionings = state
          .get('data')
          .get('byCompetitor')
          .get(competitorId, new List())
          .filter(positioning => positioning.id !== action.payload.id)
          .push(new Positioning(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Positioning(action.payload)),
              byCompetitor: state
                .get('data')
                .get('byCompetitor')
                .set(competitorId, competitorPositionings),
            },
          })
        );
      })();
    case types.POSITIONING_FETCH:
    case types.POSITIONING_CREATE:
    case types.POSITIONING_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.POSITIONING_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byCompetitor: {
              ...state.get('data').get('byCompetitor').toJS(),
              [action.payload.competitor]: state
                .get('data')
                .get('byCompetitor')
                .get(action.payload.competitor.toString(), new List())
                .filter(positioning => positioning.id !== action.payload.id),
            },
          },
        })
      );
    case types.POSITIONING_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.POSITIONING_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.POSITIONING_FETCH_FAILED:
    case types.POSITIONINGS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
