const toolPalette = [
  '#38B8E0',
  '#A8BC3B',
  '#FDB833',
  '#F46300',
  '#CA1551',
  '#F57EDA',
  '#B75EFF',
  '#5C5CEA',
  '#227EA3',
  '#707D28',
  '#B1740F',
  '#CC3000',
  '#89043D',
  '#EA36D9',
  '#8407E5',
  '#2121CE',
  '#81D7EA',
  '#CAD142',
  '#F7CD00',
  '#FF915D',
  '#E82E81',
  '#F49DCB',
  '#D99BF9',
  '#9098FC',
  '#0FC5D8',
  '#37CC82',
  '#5185E0',
  '#4C91B9',
  '#1E9296',
  '#AF0E46',
  '#71A0E2',
  '#85E0B0',
  '#E5243B',
  '#DDA63A',
  '#F2F2F2',
  '#999999',
  '#FFF8ED',
  '#EDF8FC',
  '#123DC6',
  '#8fa2e2',
  '#D0D8F4',
  '#E7EBF9',
  '#1F1F1F',
  '#D9D9D9',
];

/**
 * **Note from Robert:**
 * I think we should use `toolPalette.indexOf(########)` here.
 * Example:
 * `const CUSTOMER_SEGMENT_OFFSET = toolPalette.indexOf('#38B8E0')`
 */

// General
const PARROT_PINK_OFFSET = 20;

// Tool 1 //
const CUSTOMER_SEGMENT_OFFSET = 0;
const WHY_TREE_OFFSET = 11;

// Tool 2 //
const COMPETITORS_OFFSET = toolPalette.indexOf('#81D7EA');
const SELF_COMPETITOR_INDEX = toolPalette.indexOf('#37CC82');

// Tool 3 //
const PARTNERS_SUPPLIERS_OFFSET = 23;
const PARTNERS_STRATEGIC_OFFSET = 19;
const PARTNERS_ECOSYSTEM_OFFSET = 20;
const PARTNERS_TEAM_OFFSET = 16;

// Tool 4 //
const DISTRIBUTION_RELATED_OFFSET = 8;
const PAYMENT_RELATED_OFFSET = 9;
const REACHING_LOWER_COSTS_OFFSET = 10;
const ALTERNATIVE_BUSINESS_MODELS_OFFSET = 11;
const TOFU_OFFSET = 15;
const MOFU_OFFSET = 14;
const BOFU_OFFSET = 6;

// Tool 5 //
const PRICING_MODEL_INITIAL_OFFSET = 8;
const COST_OFFSET = toolPalette.indexOf('#FF915D');
const PRICE_OFFSET = toolPalette.indexOf('#0FC5D8');
const PROFIT_OFFSET = toolPalette.indexOf('#5C5CEA');
const AVERAGE_OFFSET = toolPalette.indexOf('#37CC82');
const WTP_1_OFFSET = toolPalette.indexOf('#CAD142');
const WTP_2_OFFSET = toolPalette.indexOf('#A8BC3B');
const WTP_3_OFFSET = toolPalette.indexOf('#707D28');
const WTP_4_OFFSET = toolPalette.indexOf('#B1740F');
const LTV_OFFSET = toolPalette.indexOf('#5185E0');

// Tool 6 //
const FIXED_ASSETS_OFFSET = 0;
const PAYROLL_OFFSET = 8;
const VARIABLE_EXPENSES_OFFSET = 10;
const EXPENSES_OFFSET = 12;
const REVENUE_OFFSET = 14;
const ANNUAL_EXPENSES_OFFSET = toolPalette.indexOf('#89043D');
const ANNUAL_REVENUE_OFFSET = toolPalette.indexOf('#8407E5');
const FIXED_ASSET_OFFSET = toolPalette.indexOf('#38B8E0');
const CHECKBOX_LIGHTGREY = toolPalette.indexOf('#F2F2F2');
const CHECKBOX_GREY = toolPalette.indexOf('#999999');
const PENDING_FIXED_ASSETS = toolPalette.indexOf('#81D7EA');
const OPERATING_CAPITAL_OFFSET = toolPalette.indexOf('#F7CD00');
const PENDING_OPERATING_CAPITAL = toolPalette.indexOf('#FDB833');
const REQUIRED_FUNDS_OFFSET = toolPalette.indexOf('#A8BC3B');
const SOURCE_OF_FUNDING_OFFSET = toolPalette.indexOf('#9098FC');
const SOURCE_OF_FUNDING_SECURED_OFFSET = toolPalette.indexOf('#5C5CEA');
const TOTAL_REVENUE_OFFSET = toolPalette.indexOf('#707D28');
const NET_INCOME_LOSS_OFFSET = toolPalette.indexOf('#2121CE');
const TOTAL_COST_OFFSET = toolPalette.indexOf('#89043D');
const OPACITY_OPERATING_CAPITAL = toolPalette.indexOf('#FFF8ED');
const OPACITY_FIXED_ASSET = toolPalette.indexOf('#EDF8FC');
const NEED_TO_RAISE_OFFSET = toolPalette.indexOf('#E82E81');
const SECURED_ENOUGH_FUDING = toolPalette.indexOf('#37CC82');

// Tool 7 //
const GUIDING_OFFSET = toolPalette.indexOf('#E82E81');
const RESOURCES_OFFSET = toolPalette.indexOf('#227EA3');
const ROLES_RELATIONSHIPS_OFFSET = toolPalette.indexOf('#9098FC');
const RULES_OFFSET = toolPalette.indexOf('#2121CE');
const RESULTS_OFFSET = toolPalette.indexOf('#37CC82');
const OUTCOMES_OFFSET = toolPalette.indexOf('#E5243B');
const SYSTEM_CHANGE_OFFSET = toolPalette.indexOf('#0FC5D8');

// Tool 8 //
const MISSION_OFFSET = toolPalette.indexOf('#37CC82');
const MOONSHOT_OFFSET = 8;
const KPI_OFFSET = toolPalette.indexOf('#5C5CEA');
const MILESTONES_YEAR_3_OFFSET = toolPalette.indexOf('#F46300');
const ORGANIZATIONAL_ROLE_OFFSET = toolPalette.indexOf('#81D7EA');
const RISK_OFFSET = toolPalette.indexOf('#CC3000');
const NEGATIVE_BEHAVIOR_OFFSET = toolPalette.indexOf('#F46300');
const POSITIVE_BEHAVIOR_OFFSET = toolPalette.indexOf('#2121CE');
const VALUE_OFFSET = toolPalette.indexOf('#89043D');

// Executive Summary

const EXECUTIVE_SUMMARY_OFFSET = toolPalette.indexOf('#123DC6');
const EXECUTIVE_SUMMARY_LIGHT_OFFSET = toolPalette.indexOf('#8fa2e2');

const EXECUTIVE_SUMMARY_DARK_OUTPUT = toolPalette.indexOf('#D0D8F4');
const EXECUTIVE_SUMMARY_LIGHT_OUTPUT = toolPalette.indexOf('#E7EBF9');
const EXECUTIVE_SUMMARY_DARK_TITLE = toolPalette.indexOf('#030202');
const EXECUTIVE_SUMMARY_VENTURE_STATUS_INPUT = toolPalette.indexOf('#D9D9D9');

export default toolPalette;
export {
  CUSTOMER_SEGMENT_OFFSET,
  WHY_TREE_OFFSET,
  COMPETITORS_OFFSET,
  SELF_COMPETITOR_INDEX,
  PARTNERS_SUPPLIERS_OFFSET,
  PARTNERS_STRATEGIC_OFFSET,
  PARTNERS_ECOSYSTEM_OFFSET,
  PARTNERS_TEAM_OFFSET,
  PARROT_PINK_OFFSET,
  DISTRIBUTION_RELATED_OFFSET,
  PAYMENT_RELATED_OFFSET,
  REACHING_LOWER_COSTS_OFFSET,
  ALTERNATIVE_BUSINESS_MODELS_OFFSET,
  TOFU_OFFSET,
  MOFU_OFFSET,
  BOFU_OFFSET,
  KPI_OFFSET,
  PRICING_MODEL_INITIAL_OFFSET,
  COST_OFFSET,
  PRICE_OFFSET,
  PROFIT_OFFSET,
  AVERAGE_OFFSET,
  WTP_1_OFFSET,
  WTP_2_OFFSET,
  WTP_3_OFFSET,
  WTP_4_OFFSET,
  LTV_OFFSET,
  FIXED_ASSETS_OFFSET,
  OPERATING_CAPITAL_OFFSET,
  REQUIRED_FUNDS_OFFSET,
  PAYROLL_OFFSET,
  VARIABLE_EXPENSES_OFFSET,
  EXPENSES_OFFSET,
  REVENUE_OFFSET,
  ANNUAL_EXPENSES_OFFSET,
  ANNUAL_REVENUE_OFFSET,
  FIXED_ASSET_OFFSET,
  CHECKBOX_LIGHTGREY,
  CHECKBOX_GREY,
  PENDING_FIXED_ASSETS,
  PENDING_OPERATING_CAPITAL,
  SOURCE_OF_FUNDING_OFFSET,
  SOURCE_OF_FUNDING_SECURED_OFFSET,
  OPACITY_OPERATING_CAPITAL,
  OPACITY_FIXED_ASSET,
  NEED_TO_RAISE_OFFSET,
  SECURED_ENOUGH_FUDING,
  MISSION_OFFSET,
  TOTAL_REVENUE_OFFSET,
  NET_INCOME_LOSS_OFFSET,
  TOTAL_COST_OFFSET,
  GUIDING_OFFSET,
  RESOURCES_OFFSET,
  ROLES_RELATIONSHIPS_OFFSET,
  RULES_OFFSET,
  RESULTS_OFFSET,
  OUTCOMES_OFFSET,
  MOONSHOT_OFFSET,
  SYSTEM_CHANGE_OFFSET,
  MILESTONES_YEAR_3_OFFSET,
  ORGANIZATIONAL_ROLE_OFFSET,
  RISK_OFFSET,
  NEGATIVE_BEHAVIOR_OFFSET,
  POSITIVE_BEHAVIOR_OFFSET,
  VALUE_OFFSET,
  EXECUTIVE_SUMMARY_OFFSET,
  EXECUTIVE_SUMMARY_LIGHT_OFFSET,
  EXECUTIVE_SUMMARY_DARK_OUTPUT,
  EXECUTIVE_SUMMARY_LIGHT_OUTPUT,
  EXECUTIVE_SUMMARY_DARK_TITLE,
  EXECUTIVE_SUMMARY_VENTURE_STATUS_INPUT,
};
