import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Value from 'models/GrowthPlan/Value';
import serviceValue from 'services/GrowthPlan/value';
import types from './types';
import actions from './actions';

export function* createValue(action) {
  const { project, ...value } = action.payload;
  try {
    const response = yield call(serviceValue.createValue, {
      project,
      ...value,
    });
    yield put(actions.createValueSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(
      actions.createValueFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteValue(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceValue.deleteValue, { id });
    yield put(actions.deleteValueSucceeded({ project, id }));
  } catch (e) {
    yield put(
      actions.deleteValueFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchValue(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceValue.fetchValue, { id });
    yield put(actions.fetchValueSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(actions.fetchValueFailed({ message: e.message }));
  }
}

export function* listValues(action) {
  const { project } = action.payload;
  try {
    const values = yield call(serviceValue.listValues, { project });
    yield put(actions.listValuesSucceeded({ project, results: values.data }));
  } catch (e) {
    yield put(actions.listValuesFailed({ message: e.message }));
  }
}

export function* updateValue(action) {
  const { id, project, ...updatedValue } = action.payload;
  try {
    const updated = yield call(serviceValue.updateValue, {
      id,
      ...updatedValue,
    });
    yield put(actions.updateValueSucceeded({ project, ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateValueFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Value.contentType) {
    yield put(
      actions.fetchValue({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* valueSaga() {
  yield all([
    takeLatest(types.VALUE_CREATE, createValue),
    takeLatest(types.VALUE_DELETE, deleteValue),
    takeLatest(types.VALUE_FETCH, fetchValue),
    takeLatest(types.VALUE_UPDATE, updateValue),
    takeEvery(types.VALUES_LIST, listValues),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default valueSaga;
