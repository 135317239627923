import api from '../api';

const updateMonthlyExpense = ({ id, ...updatedMonthlyExpense }) =>
  api({
    method: 'patch',
    url: `api/monthly-expenses/${id}/`,
    data: updatedMonthlyExpense,
  });

export default {
  updateMonthlyExpense,
};
