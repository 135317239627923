// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';

// Components
import { P } from 'components/fontSystem';

const IntroStep = props => {
  const { text, ...rest } = props;
  return (
    <div {...rest}>
      <CheckCircleOutlined style={{ fontSize: '26px' }} />
      <P $primary {...rest}>
        {` ${text}`}
      </P>
    </div>
  );
};

IntroStep.propTypes = {
  text: PropTypes.string.isRequired,
};

const StyledStep = styled(IntroStep)`
  &&& {
    display: flex;
    align-items: start;
    font-size: 16px;
    .anticon {
      color: ${({ theme }) => theme.primaryColor};
      vertical-align: middle;
      margin-right: 10px;
    }
  }
`;

export default StyledStep;
