import { createSelector } from 'reselect';

const getState = state => state.managementPanelMentor;

const getRawApplications = createSelector([getState], state => state.data);

const applicationsListLoading = createSelector([getState], state => state.loading);

const getApplicationsBySpecificStatus = createSelector(
  [getRawApplications],
  state => status => state[status]
);

const getCurrentApplication = createSelector([getRawApplications], state => state.get('current'));

const getApplications = createSelector([getRawApplications], state => state.application);

const getMatcheableMentorsApplication = createSelector(
  [getRawApplications],
  state => state.matcheables
);

export default {
  getMatcheableMentorsApplication,
  getApplicationsBySpecificStatus,
  applicationsListLoading,
  getCurrentApplication,
  getApplications,
};
