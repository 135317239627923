import api from '../api';

const createCompetitor = ({ project, product, ...competitor }) =>
  api.post(`api/projects/${project}/products/${product}/competitors/`, {
    ...competitor,
  });

const deleteCompetitor = ({ project, product, id }) =>
  api.delete(`api/projects/${project}/products/${product}/competitors/${id}/`);

const fetchCompetitor = ({ project, product, id }) =>
  api.get(`api/projects/${project}/products/${product}/competitors/${id}/`);

const listCompetitors = ({ project, product }) =>
  api.get(`api/projects/${project}/products/${product}/competitors/`);

const updateCompetitor = ({ project, product, competitorId, ...competitor }) =>
  api.patch(`api/projects/${project}/products/${product}/competitors/${competitorId}/`, {
    ...competitor,
  });

export default {
  createCompetitor,
  deleteCompetitor,
  fetchCompetitor,
  listCompetitors,
  updateCompetitor,
};
