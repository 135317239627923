const RELATIONSHIP_CLEAR_ERROR = 'bfb/RELATIONSHIP/RELATIONSHIP_CLEAR_ERROR';
const RELATIONSHIPS_LIST = 'bfb/RELATIONSHIP/RELATIONSHIPS_LIST';
const RELATIONSHIPS_LIST_SUCCEEDED = 'bfb/RELATIONSHIP/RELATIONSHIPS_LIST_SUCCEEDED';
const RELATIONSHIPS_LIST_FAILED = 'bfb/RELATIONSHIP/RELATIONSHIPS_LIST_FAILED';
const RELATIONSHIP_CREATE = 'bfb/RELATIONSHIP/RELATIONSHIP_CREATE';
const RELATIONSHIP_CREATE_SUCCEEDED = 'bfb/RELATIONSHIP/RELATIONSHIP_CREATE_SUCCEEDED';
const RELATIONSHIP_CREATE_FAILED = 'bfb/RELATIONSHIP/RELATIONSHIP_CREATE_FAILED';
const RELATIONSHIP_DELETE = 'bfb/RELATIONSHIP/RELATIONSHIP_DELETE';
const RELATIONSHIP_DELETE_SUCCEEDED = 'bfb/RELATIONSHIP/RELATIONSHIP_DELETE_SUCCEEDED';
const RELATIONSHIP_DELETE_FAILED = 'bfb/RELATIONSHIP/RELATIONSHIP_DELETE_FAILED';
const RELATIONSHIP_FETCH = 'bfb/RELATIONSHIP/RELATIONSHIP_FETCH';
const RELATIONSHIP_FETCH_SUCCEEDED = 'bfb/RELATIONSHIP/RELATIONSHIP_FETCH_SUCCEEDED';
const RELATIONSHIP_FETCH_FAILED = 'bfb/RELATIONSHIP/RELATIONSHIP_FETCH_FAILED';
const RELATIONSHIP_UPDATE = 'bfb/RELATIONSHIP/RELATIONSHIP_UPDATE';
const RELATIONSHIP_UPDATE_SUCCEEDED = 'bfb/RELATIONSHIP/RELATIONSHIP_UPDATE_SUCCEEDED';
const RELATIONSHIP_UPDATE_FAILED = 'bfb/RELATIONSHIP/RELATIONSHIP_UPDATE_FAILED';

export default {
  RELATIONSHIPS_LIST,
  RELATIONSHIPS_LIST_SUCCEEDED,
  RELATIONSHIPS_LIST_FAILED,
  RELATIONSHIP_CLEAR_ERROR,
  RELATIONSHIP_CREATE,
  RELATIONSHIP_CREATE_SUCCEEDED,
  RELATIONSHIP_CREATE_FAILED,
  RELATIONSHIP_DELETE,
  RELATIONSHIP_DELETE_SUCCEEDED,
  RELATIONSHIP_DELETE_FAILED,
  RELATIONSHIP_FETCH,
  RELATIONSHIP_FETCH_SUCCEEDED,
  RELATIONSHIP_FETCH_FAILED,
  RELATIONSHIP_UPDATE,
  RELATIONSHIP_UPDATE_SUCCEEDED,
  RELATIONSHIP_UPDATE_FAILED,
};
