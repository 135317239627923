import { createSelector } from 'reselect';

const getState = state => state.sdgOutcomeIndicator;

const getRawOutcomeIndicators = createSelector([getState], state => state.get('data'));

const getOutcomeIndicatorsById = createSelector([getRawOutcomeIndicators], state =>
  state.get('byId')
);

const getOutcomeIndicatorOfId = createSelector(
  [getOutcomeIndicatorsById],
  state => id => state.toJS()[id]
);

const getOutcomeIndicators = createSelector([getOutcomeIndicatorsById], state =>
  state.valueSeq().toJS()
);

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getOutcomeIndicators,
  getOutcomeIndicatorsById,
  getOutcomeIndicatorOfId,
  getErrorMessage,
};
