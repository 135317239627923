/* eslint-disable max-len */
import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import BusinessModel from 'models/BusinessModelsMarketing/BusinessModel';
import ProductBusinessModel from 'models/BusinessModelsMarketing/ProductBusinessModel';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byProduct: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  const productId = action.payload && String(action.payload.product);
  switch (action.type) {
    case types.BUSINESS_MODELS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byProduct: state.get('data').get('byProduct'),
            byId: action.payload.results.reduce(
              (businessModelsById, businessModel) =>
                businessModelsById.set(
                  businessModel.id.toString(),
                  new BusinessModel(businessModel)
                ),
              state.get('data').get('byId')
            ),
          },
        })
      );
    case types.PRODUCT_BUSINESS_MODELS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: state.get('data').get('byId'),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                productId,
                action.payload.results.reduce(
                  (list, businessModel) =>
                    list
                      .set(businessModel.id, new ProductBusinessModel(businessModel))
                      // Redundant filtering, but just in case.
                      .filter(model => Boolean(model) && String(model.product) === productId),
                  new List()
                )
              ),
          },
        })
      );
    case types.PRODUCT_BUSINESS_MODEL_FETCH_SUCCEEDED:
    case types.PRODUCT_BUSINESS_MODEL_CREATE_SUCCEEDED:
    case types.PRODUCT_BUSINESS_MODEL_UPDATE_SUCCEEDED:
      return (() => {
        const businessModelsByIdByProduct = state
          .get('data')
          .get('byProduct')
          .get(productId, new List())
          .filter(businessModel => businessModel.id !== action.payload.id)
          .push(new ProductBusinessModel(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state.get('data').get('byId'),
              byProduct: state
                .get('data')
                .get('byProduct')
                .set(productId, businessModelsByIdByProduct),
            },
          })
        );
      })();
    case types.PRODUCT_BUSINESS_MODEL_FETCH:
    case types.PRODUCT_BUSINESS_MODEL_CREATE:
    case types.PRODUCT_BUSINESS_MODEL_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PRODUCT_BUSINESS_MODEL_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId'),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                String(action.payload.product),
                state
                  .get('data')
                  .get('byProduct')
                  .get(String(action.payload.product), [])
                  .filter(businessModel => businessModel.id !== action.payload.id)
              )
              .sort(byId),
          },
        })
      );
    case types.PRODUCT_BUSINESS_MODEL_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.PRODUCT_BUSINESS_MODEL_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.PRODUCT_BUSINESS_MODEL_FETCH_FAILED:
    case types.PRODUCT_BUSINESS_MODELS_LIST_FAILED:
    case types.BUSINESS_MODELS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
