import types from './types';

const clearError = () => ({
  type: types.REASON_CLEAR_ERROR,
});

const clearReasons = () => ({
  type: types.REASONS_LIST_SUCCEEDED,
  payload: { results: [] },
});

const createReason = ({ ...reason }) => ({
  type: types.REASON_CREATE,
  payload: { ...reason },
});

const createReasonSucceeded = ({ ...reason }) => ({
  type: types.REASON_CREATE_SUCCEEDED,
  payload: { ...reason },
});

const createReasonFailed = ({ message }) => ({
  type: types.REASON_CREATE_FAILED,
  payload: { message },
});

const deleteReason = ({ id, ...reason }) => ({
  type: types.REASON_DELETE,
  payload: { id, ...reason },
});

const deleteReasonSucceeded = ({ id, ...reason }) => ({
  type: types.REASON_DELETE_SUCCEEDED,
  payload: { id, ...reason },
});

const deleteReasonFailed = ({ message }) => ({
  type: types.REASON_DELETE_FAILED,
  payload: message,
});

const fetchReason = ({ id }) => ({
  type: types.REASON_FETCH,
  payload: { id },
});

const fetchReasonSucceeded = ({ ...reason }) => ({
  type: types.REASON_FETCH_SUCCEEDED,
  payload: { ...reason },
});

const fetchReasonFailed = ({ message }) => ({
  type: types.REASON_FETCH_FAILED,
  payload: { message },
});

const listReasons = ({ problemId, ...rest }) => ({
  type: types.REASONS_LIST,
  payload: { problemId, ...rest },
});

const listReasonsSucceeded = payload => ({
  type: types.REASONS_LIST_SUCCEEDED,
  payload,
});

const listReasonsFailed = ({ message }) => ({
  type: types.REASONS_LIST_FAILED,
  payload: { message },
});

const updateReason = ({ problem, id, ...reason }) => ({
  type: types.REASON_UPDATE,
  payload: { problem, id, ...reason },
});

const updateReasonSucceeded = ({ ...reason }) => ({
  type: types.REASON_UPDATE_SUCCEEDED,
  payload: { ...reason },
});

const updateReasonFailed = ({ message }) => ({
  type: types.REASON_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  clearReasons,
  createReason,
  createReasonFailed,
  createReasonSucceeded,
  deleteReason,
  deleteReasonFailed,
  deleteReasonSucceeded,
  fetchReason,
  fetchReasonFailed,
  fetchReasonSucceeded,
  listReasons,
  listReasonsFailed,
  listReasonsSucceeded,
  updateReason,
  updateReasonFailed,
  updateReasonSucceeded,
};
