import expenseTypes from '../expense/types';
import monthlyMonthlyExpenseTypes from './types';

const updateMonthlyExpense = ({ scenario, ...updatedMonthlyExpense }, callback) => ({
  type: monthlyMonthlyExpenseTypes.MONTHLY_EXPENSE_UPDATE,
  payload: { scenario, ...updatedMonthlyExpense },
  callback,
});

const updateMonthlyExpenseSucceeded = payload => ({
  type: expenseTypes.MONTHLY_EXPENSE_UPDATE_SUCCEEDED,
  payload,
});

const updateMonthlyExpenseFailed = ({ message }) => ({
  type: expenseTypes.MONTHLY_EXPENSE_UPDATE_FAILED,
  payload: { message },
});

export default {
  updateMonthlyExpense,
  updateMonthlyExpenseFailed,
  updateMonthlyExpenseSucceeded,
};
