import React from 'react';
import styled from 'styled-components';

const PopoverContent = props => <div {...props} />;

const StyledContent = styled(PopoverContent)`
  max-width: 500px;
`;

export default StyledContent;
