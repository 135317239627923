import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Reason from 'models/ValueProposition/Reason';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REASONS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, reason) => ({ ...obj, [reason.id]: new Reason(reason) }),
              {}
            ),
          },
        })
      );
    case types.REASON_FETCH_SUCCEEDED:
    case types.REASON_CREATE_SUCCEEDED:
    case types.REASON_UPDATE_SUCCEEDED:
      return (() => {
        const byId = state.get('data').get('byId');
        const parentId = action.payload.parent && action.payload.parent.toString();
        const reasonId = action.payload.id.toString();
        let result = byId.set(reasonId, new Reason(action.payload));
        if (parentId) {
          result = result.set(
            parentId,
            new Reason({
              ...byId.get(parentId).toJS(),
              children: reasonId,
            })
          );
        }
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: result,
            },
          })
        );
      })();
    case types.REASON_FETCH:
    case types.REASON_CREATE:
    case types.REASON_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.REASON_DELETE_SUCCEEDED:
      return (() => {
        const byId = state.get('data').get('byId');
        const parentId = action.payload.parent && action.payload.parent.toString();
        const reasonId = action.payload.id.toString();
        let result = byId.delete(reasonId);
        if (parentId) {
          result = result.set(
            parentId,
            new Reason({
              ...byId.get(parentId).toJS(),
              children: null,
            })
          );
        }
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: result,
            },
          })
        );
      })();
    case types.REASON_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.REASON_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.REASON_FETCH_FAILED:
    case types.REASONS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
