import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Project, {
  PM_STATUS_APPLICATION,
  PM_STATUS_DROPPED,
  PM_STATUS_SELECTION,
  PROJECT_STATUSES,
} from 'models/Project';
import types from './types';

const projectsByStatus = PROJECT_STATUSES.reduce(
  (prevObj, status) => ({
    ...prevObj,
    [status]: [],
    [`${status}Count`]: 0,
    [`${status}TotalCount`]: 0,
  }),
  {}
);

const pmProjectsByStatus = [PM_STATUS_APPLICATION, PM_STATUS_SELECTION, PM_STATUS_DROPPED].reduce(
  (prevObj, status) => ({
    ...prevObj,
    [status]: [],
    [`${status}Count`]: 0,
    [`${status}TotalCount`]: 0,
  }),
  {}
);

export const initialState = fromJS({
  ...reduxHelper.applyRequestInfo({
    current: PM_STATUS_APPLICATION,
    loading: false,
    data: {
      byId: {},
      admin: [],
      adminCount: 0,
      explore: [],
      exploreCount: 0,
      ...projectsByStatus,
      ...pmProjectsByStatus,
      selectedProject: new Project(),
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECTS_LIST:
    case types.SELECTED_PROJECT_FETCH:
      return state.merge(
        reduxHelper.applyRequestInfo({
          isFetching: true,
        })
      );
    case types.PROJECTS_LIST_SUCCEEDED: {
      let data;
      let subKey;
      let subKeyCount;
      let subKeyTotalCount;
      switch (true) {
        case Boolean(action.payload.explore):
          subKey = 'explore';
          subKeyCount = 'exploreCount';
          data = state
            .get('data')
            .set(subKey, fromJS(action.payload.results.map(project => new Project(project))))
            .set(subKeyCount, action.payload.count);
          break;
        case Boolean(action.payload.admin && action.payload.projectStatus):
          {
            subKey = action.payload.projectStatus;
            subKeyCount = `${action.payload.projectStatus}Count`;
            subKeyTotalCount = `${action.payload.projectStatus}TotalCount`;
            let byId = state.get('data').get('byId');
            const projectsBySubKey = fromJS(
              action.payload.results.map(project => {
                const projectMap = new Project(project);
                byId = byId.set(String(project.id), projectMap);
                return projectMap;
              })
            );
            data = state
              .get('data')
              .set('byId', byId)
              .set(subKey, projectsBySubKey)
              .set(subKeyCount, action.payload.count)
              .set(
                subKeyTotalCount,
                Math.max((data && data[subKeyTotalCount]) || 0, action.payload.count || 0)
              );
          }
          break;
        case Boolean(action.payload.admin && !action.payload.projectStatus):
          subKey = 'admin';
          subKeyCount = 'adminCount';
          data = state
            .get('data')
            .set(subKey, fromJS(action.payload.results.map(project => new Project(project))))
            .set(subKeyCount, action.payload.count);
          break;
        default:
          data = state.get('data').set(
            'byId',
            action.payload.results.reduce(
              (obj, project) => obj.set(String(project.id), new Project(project)),
              state.get('data').get('byId')
            )
          );
          break;
      }
      return state.merge(reduxHelper.applyReceiveInfo({ data, loading: false }));
    }
    case types.MULTIPLE_PROJECTS_UPDATE_SUCCEEDED:
    case types.PROJECT_FETCH_SUCCEEDED:
    case types.PROJECT_UPDATE_SUCCEEDED: {
      const { id, tag } = action.payload;
      const status = state
        .get('data')
        .set(
          state.get('current'),
          state
            .get('data')
            .get(state.get('current'))
            .map(item => {
              let itemRating = item;
              if (item.id === id) {
                itemRating = item.set('tag', tag);
              }
              return itemRating;
            })
        )
        .get(state.get('current'));

      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: state
            .get('data')
            .set(
              'byId',
              state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Project(action.payload))
            )
            .set(state.get('current'), status),
        })
      );
    }
    case types.SELECTED_PROJECT_FETCH_SUCCEEDED: {
      const selectedProject = action.payload;
      const data = state.get('data').set('selectedProject', fromJS(new Project(selectedProject)));
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data,
          errorMessage: '',
        })
      );
    }
    case types.MULTIPLE_PROJECTS_UPDATE:
    case types.PROJECT_FETCH:
    case types.PROJECT_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PROJECT_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.PROJECT_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.MULTIPLE_PROJECTS_UPDATE_FAILED:
    case types.PROJECT_FETCH_FAILED:
    case types.PROJECTS_LIST_FAILED:
    case types.SELECTED_PROJECT_FETCH_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
          loading: false,
        })
      );
    case types.SELECTED_PROJECT_CLEAR: {
      const data = state.get('data').set('selectedProject', fromJS(new Project()));
      return state.merge(reduxHelper.applyReceiveInfo({ data }));
    }
    case types.CURRENT_PROJECT:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          current: action.payload,
        })
      );
    case types.PROJECTS_LIST_PENDING:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          loading: true,
        })
      );
    default:
      return state;
  }
}
