import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

const getState = state => state.segment;

const getRawSegment = createSelector([getState], state => state.get('data'));

const getSegmentsById = createSelector([getRawSegment], state => state.get('byId'));

const getSegmentsByProduct = createSelector([getRawSegment], state => state.get('byProduct'));

const getProductSegments = createSelector(
  [getSegmentsByProduct],
  state => productId => state.get(String(productId), new List())
);

const getSelectedProductSegments = createSelector([getSegmentsByProduct], state => productId => {
  const segments = state.get(productId.toString(), new List());
  return segments.filter(segment => segment.isSelected);
});

const getSelectedProductSegmentsById = createSelector(
  [getSelectedProductSegments],
  getSegments => productId =>
    getSegments(productId).reduce(
      (prevData, segment) => prevData.set(segment.id.toString(), segment),
      new Map()
    )
);

const getSegments = createSelector([getSegmentsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getErrorMessage,
  getSegments,
  getSegmentsById,
  getSegmentsByProduct,
  getProductSegments,
  getSelectedProductSegments,
  getSelectedProductSegmentsById,
};
