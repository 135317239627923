// Libraries
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import { Form as AntForm } from 'antd';
import { H1 } from 'components/fontSystem';
import Item from './Item';

export const LAYOUT_LIMIT_WIDTH = 0;

const Header = ({
  title = null,
  className = '',
  logoLayout = LAYOUT_LIMIT_WIDTH,
  showLine = true,
  imgSrc = null,
  imgAlt = '',
}) => (
  <StyledHeader className={className} $logoLayout={logoLayout}>
    {imgSrc && <img src={imgSrc} alt={imgAlt} />}

    <div
      className={showLine ? 'break line' : 'break'}
      style={{
        width: 'calc(100% + 60px)',
        transform: 'translateX(-30px)',
      }}
    />
    {title && <H1>{title}</H1>}
  </StyledHeader>
);

const logoLayoutStyle = css`
  ${({ $logoLayout }) =>
    $logoLayout === LAYOUT_LIMIT_WIDTH
      ? css`
          min-width: 262px;
          width: 45%;
        `
      : css`
          min-height: 50px;
          max-height: 150px;
          max-width: 100%;
        `}
`;

const FooterImg = styled.img`
  ${logoLayoutStyle};
  margin: 0 auto;
  display: ${({ src }) => (src ? 'block' : 'none')};
`;

const StyledHeader = styled.div`
  &&& {
    img {
      display: block;
      margin: 15px auto;
      @media (min-width: ${({ theme: { screenMdMin } }) => screenMdMin}) {
        margin: 0 auto 15px;
      }
      ${logoLayoutStyle}
    }

    .break {
      height: 3px;
      margin-bottom: 5%;
      width: auto;
    }
    .line {
      background: ${({ theme }) =>
        `linear-gradient(to right, ${theme.primaryColor}, ${
          theme.secondaryColor || theme.primaryColor
        })`};
    }
    h1 {
      margin-bottom: 1.5em;
    }
    &.no-margin h1 {
      margin-bottom: 0;
    }
  }
`;

const StyledForm = styled(AntForm)`
  &&& {
    ::-webkit-input-placeholder {
      font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }
  }
`;

StyledForm.Header = Header;
StyledForm.Item = Item;
StyledForm.FooterImg = FooterImg;

Header.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  logoLayout: PropTypes.number,
  showLine: PropTypes.bool,
};

export default StyledForm;
