import { createSelector } from 'reselect';

const getState = state => state.beneficiary;

const getRawBeneficiaries = createSelector([getState], state => state.get('data'));

const getBeneficiariesById = createSelector([getRawBeneficiaries], state => state.get('byId'));

const getBeneficiaries = createSelector([getBeneficiariesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getBeneficiaries,
  getBeneficiariesById,
  getErrorMessage,
};
