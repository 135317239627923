// Libraries
import PropTypes from 'prop-types';
import { useContext } from 'react';
import styled from 'styled-components';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';

// Components
import { Collapse } from 'antd';

const SeeMoreWrapper = styled.div`
  &&& {
    display: flex;
    width: 100%;
    padding: ${({ padding }) => padding || '0px 35px'};
    flex-flow: column;
    line-height: 25px;
    font-size: 15px;

    .see-more {
      height: 25px;
      line-height: 23px;
      color: #0fc5d8;
      padding-left: 20px;
    }

    .ant-collapse-SeeMore {
      margin: 10px 0 5px;
      border: ${props => !props.bordered && 0};
    }

    .ant-collapse-borderless {
      background-color: transparent;
    }

    .ant-collapse-item {
      border: ${({ $borderless }) => $borderless && 0};
    }

    .ant-collapse-header {
      padding: 0;
      display: flex;
      align-items: center;
    }

    .anticon.anticon-right {
      color: #0fc5d8;
      line-height: 25px;
      left: 0;
    }

    .ant-collapse-content-box {
      padding: ${({ padding }) => padding || undefined};
    }
  }
`;

const SeeMore = props => {
  const { t } = useContext(TranslationsContext);
  const {
    className,
    description,
    padding,
    bordered,
    borderless,
    header,
    isActiveByDefault,
    ...rest
  } = props;
  const KEY = '1';
  const defaultActiveKey = isActiveByDefault ? KEY : null;

  const collapseItems = [
    {
      key: { KEY },
      label: <span className="see-more">{header || t('See more')}</span>,
      children: description,
    },
  ];

  return (
    <SeeMoreWrapper $borderless={borderless} padding={padding} className={className}>
      <Collapse
        bordered={bordered}
        defaultActiveKey={defaultActiveKey}
        items={collapseItems}
        {...rest}
      />
    </SeeMoreWrapper>
  );
};

SeeMore.propTypes = {
  description: PropTypes.node.isRequired,
  className: PropTypes.string,
  padding: PropTypes.string,
  caption: PropTypes.node,
  header: PropTypes.node,
  borderless: PropTypes.bool,
  bordered: PropTypes.bool,
  isActiveByDefault: PropTypes.bool,
};

SeeMore.defaultProps = {
  className: undefined,
  padding: undefined,
  caption: null,
  header: null,
  borderless: false,
  bordered: false,
  isActiveByDefault: false,
};

const Accordion = Collapse;

Accordion.SeeMore = SeeMore;

const Wrapper = styled.div`
  &&& {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      transition: background-color 200ms ease;
    }
  }
`;

const StyledAccordion = props => {
  const { t } = useContext(TranslationsContext);
  const { wrapperProps, ...rest } = props;
  const accordionProps = {
    ...rest,
    t,
  };
  return (
    <Wrapper {...wrapperProps}>
      <Accordion {...accordionProps} />
    </Wrapper>
  );
};

StyledAccordion.propTypes = {
  wrapperProps: PropTypes.instanceOf(Object),
};

StyledAccordion.defaultProps = {
  wrapperProps: null,
};

StyledAccordion.SeeMore = SeeMore;
StyledAccordion.Panel = Collapse.Panel;

export default StyledAccordion;
