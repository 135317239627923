import { createSelector } from 'reselect';

const getState = state => state.investment;

const getRawInvestment = createSelector([getState], state => state.get('data'));

const getInvestmentsById = createSelector([getRawInvestment], state => state.get('byId'));

const getInvestmentsByScenario = createSelector([getRawInvestment], state =>
  state.get('byScenario')
);

const getTotalByScenario = createSelector([getRawInvestment], state =>
  state.get('totalByScenario')
);

const getTotalPaidByScenario = createSelector([getRawInvestment], state =>
  state.get('totalPaidByScenario')
);

const getInvestments = createSelector([getInvestmentsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getErrorMessage,
  getInvestments,
  getInvestmentsById,
  getInvestmentsByScenario,
  getTotalPaidByScenario,
  getTotalByScenario,
};
