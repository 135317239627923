import api from '../api';

const createPartner = ({ project, product, ...partner }) =>
  api.post(`/api/projects/${project}/products/${product}/partners/`, {
    project,
    ...partner,
  });

const deletePartner = ({ project, product, partnerId }) =>
  api.delete(`/api/projects/${project}/products/${product}/partners/${partnerId}`);

const fetchPartner = ({ project, product, partnerId }) =>
  api.get(`/api/projects/${project}/products/${product}/partners/${partnerId}`);

const listPartners = ({ project, product }) =>
  api.get(`/api/projects/${project}/products/${product}/partners/`);

const listOrganizationalRoles = query => api.get('/api/organizational-roles/', { params: query });

const updatePartner = ({ project, product, partnerId, ...partner }) =>
  api.patch(`/api/projects/${project}/products/${product}/partners/${partnerId}/`, { ...partner });
const updateOrganizationalRole = ({ partnerId, ...partner }) =>
  api.patch(`/api/organizational-roles/${partnerId}/`, { ...partner });

export default {
  createPartner,
  deletePartner,
  fetchPartner,
  listPartners,
  listOrganizationalRoles,
  updatePartner,
  updateOrganizationalRole,
};
