// Libraries
import styled, { css } from 'styled-components';

export default styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.lightGrey};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: all ease-in-out 0.2s;
  padding: 20px 10px;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      }
    `}
`;
