const PDF_TOOL_FETCH = 'bfb/PBV/PDF_TOOL_FETCH';
const PDF_TOOL_FETCH_SUCCEEDED = 'bfb/PBV/PDF_TOOL_FETCH_SUCCEEDED';
const PDF_TOOL_FETCH_FAILED = 'bfb/PBV/PDF_TOOL_FETCH_FAILED';
const PDF_TOOL_CLEAR = 'bfb/PBV/PDF_TOOL_CLEAR';

export default {
  PDF_TOOL_FETCH,
  PDF_TOOL_FETCH_SUCCEEDED,
  PDF_TOOL_FETCH_FAILED,
  PDF_TOOL_CLEAR,
};
