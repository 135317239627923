import api from './api';

// In this case, the id representes the Member Application ID.
const fetchMemberInvites = ({ id, ...query }) =>
  api.get(`/api/management-panel/members/matching/${id}/`, { params: query });

const updateMemberBulk = ({ ...query }) =>
  api.patch(`/api/management-panel/members/matching/bulk/`, { params: query });

export default {
  fetchMemberInvites,
  updateMemberBulk,
};
