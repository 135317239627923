// Libraries
import React, { useRef, useMemo, useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';

// Dependencies
import {
  FIXED_ALIGNMENT,
  TOTAL_KEY,
  CELL_ALIGNMENT,
  fromEntries,
  AVERAGE_KEY,
} from './dependencies';
import TableContext from './Context/context';
import TableProvider from './Context';

// Components
import { Wrapper, TableWrapper, Table, TableHead, TableBody } from './styled-components';
import TableRow from './TableRow';
import TableHeadRow from './TableHeadRow';
import TotalRow from './TotalRow';
import BodyCell from './BodyCell';
import TotalCell from './TotalCell';
import AverageCell from './AverageCell';
import LoadingSpinner from './LoadingSpinner';

const sortFixedColumns = (tableColumns = []) => {
  // Sorting the tableColumns if their fixed.
  tableColumns.sort(([, a], [, b]) => {
    if (!a || !b) return 0;
    switch (true) {
      case a.fixed === FIXED_ALIGNMENT.LEFT:
        return 1;
      case b.fixed === FIXED_ALIGNMENT.RIGHT:
        return -1;
      default:
        return 0;
    }
  });
};

/**
 * Columns fixed to the `right` are not ready yet and are a WIP.
 * Won't be released until it's necessary to do so, consider that
 * feature outside our MVP.
 */

const TableComponent = React.memo(props => {
  const { tableColumns = {}, tableRows = [], isLoading, ...rest } = props;
  // Generating headColumns array and sorting fixed columns, if any.
  const headColumns = useMemo(() => {
    const columns = Object.entries(tableColumns);
    sortFixedColumns(columns);
    return columns;
  }, [tableColumns]);
  // Generating the columns key array.
  const columnKeys = useMemo(() => Object.keys(fromEntries(headColumns)), [headColumns]);

  // Generating the fixed left columns array, if any.
  const [fixedLeftColumns, fixedRightColumns] = useMemo(() => {
    const fixedColumns = headColumns.reduce((columnsArray, [key, column]) => {
      const [leftColumns = [], rightColumns = []] = columnsArray;
      switch (column.fixed) {
        case CELL_ALIGNMENT.LEFT:
          leftColumns.push([key, column]);
          break;
        case CELL_ALIGNMENT.RIGHT:
          rightColumns.push([key, column]);
          break;
        default:
        // Do nothing.
      }
      return [leftColumns, rightColumns];
    }, []);
    return fixedColumns;
  }, [headColumns]);

  const [shouldShowFixedShadowOnScroll, setShowFixedShadowOnScroll] = useState(false);

  const totalValuesPerRow = useRef({});
  const totalValuesPerColumn = useRef({});

  const storeValuesPerColumn = useCallback(
    (columnKey, rowKey, value) => {
      if (totalValuesPerColumn.current && totalValuesPerColumn.current[columnKey]) {
        totalValuesPerColumn.current[columnKey][rowKey] = value;
      } else {
        totalValuesPerColumn.current[columnKey] = {};
        totalValuesPerColumn.current[columnKey][rowKey] = value;
      }
    },
    [totalValuesPerColumn]
  );

  const storeTotalValuesPerRow = useCallback(
    (rowKey, totalValue) => {
      if (totalValuesPerRow.current) {
        totalValuesPerRow.current[rowKey] = totalValue;
      }
    },
    [totalValuesPerRow]
  );

  const generateTableBody = useCallback(
    isFixedColumn =>
      tableRows.map((row, index) => {
        // TOTAL ROW
        if (row.key === TOTAL_KEY) {
          return (
            <TotalRow
              key={row.key || index}
              totalRow={row}
              columnKeys={columnKeys}
              tableColumns={tableColumns}
              isFixedColumn={isFixedColumn}
              fixedLeftColumns={fixedLeftColumns}
              totalValuesPerRow={totalValuesPerRow}
              totalValuesPerColumn={totalValuesPerColumn}
            />
          );
        }
        const rightmostFixedColumn =
          fixedLeftColumns && fixedLeftColumns[fixedLeftColumns.length - 1];
        return (
          <TableRow className="table-body-row" key={row.key || index} rowKey={row.key || index}>
            {columnKeys.map(key => {
              const cell = row[key];
              const isFixed = isFixedColumn
                ? tableColumns[key] && !tableColumns[key].fixed
                : tableColumns[key] && tableColumns[key].fixed;
              const isRightmost = key === (rightmostFixedColumn && rightmostFixedColumn[0]);
              // TOTAL CELL
              if (key === TOTAL_KEY) {
                return (
                  <TotalCell
                    key={key}
                    storeTotalValuesPerRow={storeTotalValuesPerRow}
                    storeValuesPerColumn={storeValuesPerColumn}
                    cell={cell}
                    tableColumns={tableColumns}
                    row={row}
                    columnKeys={columnKeys}
                    // If the respective table header is fixed, then hide this one.
                    fixed={isFixed}
                    className={`table-head-cell${isRightmost ? ' rightmost-cell' : ''}`}
                  />
                );
              }
              // AVERAGE CELL
              if (key === AVERAGE_KEY) {
                return (
                  <AverageCell
                    key={key}
                    storeAverageValuesPerRow={storeTotalValuesPerRow}
                    storeValuesPerColumn={storeValuesPerColumn}
                    cell={cell}
                    tableColumns={tableColumns}
                    row={row}
                    columnKeys={columnKeys}
                    // If the respective table header is fixed, then hide this one.
                    fixed={isFixed}
                    className={`table-head-cell${isRightmost ? ' rightmost-cell' : ''}`}
                  />
                );
              }
              // BODY CELL
              const isNumeric = tableColumns[key] && tableColumns[key].numeric;
              return (
                <BodyCell
                  key={key}
                  tableColumns={tableColumns}
                  isNumeric={isNumeric}
                  // If the respective table header is fixed, then hide this one.
                  fixed={isFixed}
                  className={`table-head-cell${isRightmost ? ' rightmost-cell' : ''}`}
                  {...cell}
                />
              );
            })}
          </TableRow>
        );
      }),
    [
      tableRows,
      tableColumns,
      columnKeys,
      storeTotalValuesPerRow,
      storeValuesPerColumn,
      fixedLeftColumns,
    ]
  );

  const tableBody = useMemo(() => generateTableBody(), [generateTableBody]);

  const fixedLeftTableBody = useMemo(() => {
    if (fixedLeftColumns.length) {
      return generateTableBody(true);
    }
    return null;
  }, [generateTableBody, fixedLeftColumns.length]);

  const onFixedTableScrollHandler = e => {
    const wrapperElement = e.target;
    const tableElement = e.target && e.target.firstChild;
    if (
      tableElement &&
      tableElement.classList &&
      tableElement.classList.contains('on-fixed-table-scroll-handler')
    ) {
      const { x: wrapperX } = wrapperElement.getBoundingClientRect();
      const { x: tableX } = tableElement.getBoundingClientRect();
      setShowFixedShadowOnScroll(tableX !== wrapperX);
    }
  };

  const { elevationBoxShadow, fixedLeftBoxShadow } = useContext(TableContext);

  return (
    <Wrapper
      className="global-table-wrapper"
      onScroll={onFixedTableScrollHandler}
      // CSS React Context variable
      elevationBoxShadow={elevationBoxShadow}
      isLoading={isLoading}
      isFixed={fixedLeftColumns.length}
      {...rest}
    >
      <TableWrapper className="table-wrapper">
        <Table className="table on-fixed-table-scroll-handler">
          {/* HEAD */}
          <TableHead className="table-head">
            {/* HEAD ROW */}
            <TableHeadRow headColumns={headColumns} />
          </TableHead>
          {/* BODY */}
          <TableBody className="table-body">{tableBody}</TableBody>
        </Table>
      </TableWrapper>
      {fixedLeftColumns.length > 0 && (
        <div className="fixed-left-table-wrapper">
          <Table
            className="fixed-left-table"
            shouldShowFixedShadowOnScroll={shouldShowFixedShadowOnScroll}
            // CSS React Context variable
            fixedLeftBoxShadow={fixedLeftBoxShadow}
          >
            {/* HEAD */}
            <TableHead className="table-head">
              {/* HEAD ROW */}
              <TableHeadRow headColumns={headColumns} fixedLeftColumns={fixedLeftColumns} />
            </TableHead>
            {/* BODY */}
            <TableBody className="table-body">{fixedLeftTableBody}</TableBody>
          </Table>
        </div>
      )}
      {/* WIP */}
      {fixedRightColumns && null}
      {/* LOADING SPINNER COMP */}
      <LoadingSpinner isLoading={isLoading} />
    </Wrapper>
  );
});

TableComponent.propTypes = {
  tableColumns: PropTypes.instanceOf(Object).isRequired,
  tableRows: PropTypes.instanceOf(Array).isRequired,
  backgroundColor: PropTypes.string,
  elevationBoxShadow: PropTypes.string,
  fixedLeftBoxShadow: PropTypes.string,
  isLoading: PropTypes.bool,
};

TableComponent.defaultProps = {
  backgroundColor: undefined,
  elevationBoxShadow: undefined,
  fixedLeftBoxShadow: undefined,
  isLoading: undefined,
};

const TableWithContext = props => {
  const {
    backgroundColor,
    elevationBoxShadow,
    fixedLeftBoxShadow,
    hoveredRowBackgroundColor,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <TableProvider
        backgroundColor={backgroundColor}
        elevationBoxShadow={elevationBoxShadow}
        fixedLeftBoxShadow={fixedLeftBoxShadow}
        hoveredRowBackgroundColor={hoveredRowBackgroundColor}
      >
        <TableComponent {...rest} />
      </TableProvider>
    </React.Fragment>
  );
};

TableWithContext.propTypes = {
  backgroundColor: PropTypes.string,
  elevationBoxShadow: PropTypes.string,
  fixedLeftBoxShadow: PropTypes.string,
  hoveredRowBackgroundColor: PropTypes.string,
};

TableWithContext.defaultProps = {
  backgroundColor: undefined,
  elevationBoxShadow: undefined,
  fixedLeftBoxShadow: undefined,
  hoveredRowBackgroundColor: undefined,
};

export { generateTableRowColumns } from './helpers';

export default TableWithContext;
