// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';

// Components
import { Button } from 'components/elements';

const Container = styled.div`
  &&& {
    display: flex;
    justify-content: flex-end;
  }
`;

export default function CreateProfile(props) {
  const { t } = React.useContext(TranslationsContext);

  const { onClick } = props;
  const navigate = useNavigate();

  /**
   * Redirects to the profile view when the create profile button is clicked.
   */
  function handleButtonClick() {
    navigate('/profile');
    onClick();
  }

  return (
    <Container data-test="component-create-profile" className="button-container">
      <Button
        data-test="create-profile-button"
        type="primary"
        effect="blink"
        onClick={handleButtonClick}
      >
        {t('CREATE PROFILE')}
      </Button>
    </Container>
  );
}

CreateProfile.propTypes = {
  onClick: PropTypes.func.isRequired,
};
