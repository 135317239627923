import api from '../api';

const fetchProjectSdg = ({ id }) => api.get(`/api/project-sdgs/${id}`);

const createProjectSdg = projectSdg => api.post('/api/project-sdgs/', projectSdg);

const deleteProjectSdg = ({ id }) => api.delete(`/api/project-sdgs/${id}`);

const listProjectSdgs = query => api.get('/api/project-sdgs/', { params: query });

const updateProjectSdg = ({ id, ...projectSdg }) =>
  api.patch(`/api/project-sdgs/${id}/`, projectSdg);

export default {
  createProjectSdg,
  deleteProjectSdg,
  fetchProjectSdg,
  listProjectSdgs,
  updateProjectSdg,
};
