import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Expense from 'models/FinancialProjections/Expense';
import MonthlyExpense from 'models/FinancialProjections/MonthlyExpense';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byScenario: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.EXPENSE_FETCH:
    case types.EXPENSE_CREATE:
    case types.EXPENSE_UPDATE:
    case types.EXPENSES_LIST:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.EXPENSES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, expense) =>
                prevData.set(String(expense.id), new Expense(expense)).sort(byId),
              state.get('data').get('byId')
            ),
            byScenario: state
              .get('data')
              .get('byScenario')
              .set(
                String(action.payload.scenario),
                fromJS(action.payload.results.map(expense => new Expense(expense)).sort(byId))
              ),
          },
        })
      );
    case types.EXPENSE_FETCH_SUCCEEDED:
    case types.EXPENSE_CREATE_SUCCEEDED:
    case types.EXPENSE_UPDATE_SUCCEEDED:
      return (() => {
        const scenarioId = String(action.payload.scenario);
        const scenarioExpenses = state
          .get('data')
          .get('byScenario')
          .get(scenarioId, new List())
          .filter(expense => expense.id !== action.payload.id)
          .push(new Expense(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyReceiveInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(String(action.payload.id), new Expense(action.payload)),
              byScenario: state.get('data').get('byScenario').set(scenarioId, scenarioExpenses),
            },
          })
        );
      })();
    case types.EXPENSE_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(String(action.payload.id)),
            byScenario: state
              .get('data')
              .get('byScenario')
              .set(
                String(action.payload.scenario),
                state
                  .get('data')
                  .get('byScenario')
                  .get(String(action.payload.scenario), new List())
                  .filter(expense => expense.id !== action.payload.id)
              ),
          },
        })
      );
    case types.MONTHLY_EXPENSE_UPDATE_SUCCEEDED:
      return (() => {
        const {
          scenario: scenarioId,
          expense: expenseId,
          ...updatedMonthlyExpense
        } = action.payload;
        // Fetching the expense that will be updated.
        const updatedExpense = state.get('data').get('byId').get(String(expenseId));
        // Fetching the amount of the old monthly expense.
        const { amount: oldMonthlyExpenseAmount } = updatedExpense.yearly_expenses[
          updatedMonthlyExpense.year
        ]
          .get(String(updatedMonthlyExpense.id))
          .toJS();
        // Recalculating the new yearly total.
        updatedExpense.yearly_totals[updatedMonthlyExpense.year] +=
          +updatedMonthlyExpense.amount - +oldMonthlyExpenseAmount;
        // Resetting the new monthly expenses.
        updatedExpense.yearly_expenses[updatedMonthlyExpense.year] = updatedExpense.yearly_expenses[
          updatedMonthlyExpense.year
        ].set(
          String(updatedMonthlyExpense.id),
          new MonthlyExpense({
            id: Number(updatedMonthlyExpense.id),
            amount: Number(updatedMonthlyExpense.amount),
            year: Number(updatedMonthlyExpense.year),
            month: Number(updatedMonthlyExpense.month),
            expense: Number(expenseId),
          })
        );
        // Resetting the expenses by scenario.
        const scenarioExpenses = state
          .get('data')
          .get('byScenario')
          .get(String(scenarioId), new List())
          .filter(expense => String(expense.id) !== String(expenseId))
          .push(updatedExpense)
          .sort(byId);
        // Returning the new state.
        return state.merge(
          reduxHelper.applyReceiveInfo({
            updatePending: false,
            data: {
              byId: state.get('data').get('byId').set(String(action.payload.id), updatedExpense),
              byScenario: state
                .get('data')
                .get('byScenario')
                .set(String(scenarioId), scenarioExpenses),
            },
          })
        );
      })();
    case types.MONTHLY_EXPENSE_UPDATE_FAILED:
    case types.EXPENSE_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.EXPENSE_FETCH_FAILED:
    case types.EXPENSES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    case types.EXPENSE_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    default:
      return state;
  }
}
