import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Feature from 'models/CompetitionMap/Feature';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byProduct: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FEATURES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, feature) =>
                prevData.set(feature.id.toString(), new Feature(feature)).sort(byId),
              state.get('data').get('byId')
            ),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                action.payload.product.toString(),
                fromJS(action.payload.results.map(feature => new Feature(feature)).sort(byId))
              ),
          },
        })
      );
    case types.FEATURE_FETCH_SUCCEEDED:
    case types.FEATURE_CREATE_SUCCEEDED:
    case types.FEATURE_UPDATE_SUCCEEDED:
      return (() => {
        const productId = action.payload.product.toString();
        const productFeatures = state
          .get('data')
          .get('byProduct')
          .get(productId, new List())
          .filter(feature => feature.id !== action.payload.id)
          .push(new Feature(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Feature(action.payload)),
              byProduct: state.get('data').get('byProduct').set(productId, productFeatures),
            },
          })
        );
      })();
    case types.FEATURE_FETCH:
    case types.FEATURE_CREATE:
    case types.FEATURE_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.FEATURE_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProduct: {
              ...state.get('data').get('byProduct').toJS(),
              [action.payload.product]: state
                .get('data')
                .get('byProduct')
                .get(action.payload.product.toString(), new List())
                .filter(feature => feature.id !== action.payload.id),
            },
          },
        })
      );
    case types.FEATURE_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.FEATURE_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.FEATURE_FETCH_FAILED:
    case types.FEATURES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
