import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/ValueProposition/segment';
import { types as commentTypes } from 'store/modules/comment';
import serviceSegment from 'services/ValueProposition/segment';
import Segment from 'models/ValueProposition/Segment';

export function* createSegment(action) {
  const { project, product, ...segment } = action.payload;
  try {
    const response = yield call(serviceSegment.createSegment, {
      project,
      product,
      ...segment,
    });
    yield put(actions.createSegmentSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(
      actions.createSegmentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteSegment(action) {
  const { project, product, segmentId } = action.payload;
  try {
    yield call(serviceSegment.deleteSegment, { project, product, segmentId });
    yield put(actions.deleteSegmentSucceeded({ segmentId, product }));
  } catch (e) {
    yield put(
      actions.deleteSegmentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchSegment(action) {
  const { project, product, id } = action.payload;
  try {
    const response = yield call(serviceSegment.fetchSegment, {
      project,
      product,
      id,
    });
    yield put(actions.fetchSegmentSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(actions.fetchSegmentFailed({ message: e.message }));
  }
}

export function* listSegments(action) {
  const { project, product } = action.payload;
  try {
    const segments = yield call(serviceSegment.listSegments, {
      project,
      product,
    });
    yield put(actions.listSegmentsSucceeded({ results: segments.data, product }));
  } catch (e) {
    yield put(actions.listSegmentsFailed({ message: e.message }));
  }
}

export function* listAllSegments(action) {
  const { project } = action.payload;
  try {
    const segments = yield call(serviceSegment.listAllSegments, { project });
    yield put(actions.listSegmentsSucceeded({ results: segments.data }));
  } catch (e) {
    yield put(actions.listSegmentsFailed({ message: e.message }));
  }
}

export function* updateSegment(action) {
  const { project, product, segmentId, ...updatedSegment } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceSegment.updateSegment, {
      project,
      product,
      segmentId,
      ...updatedSegment,
    });
    yield put(actions.updateSegmentSucceeded({ ...updated.data, product }));
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateSegmentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Segment.contentType) {
    yield put(
      actions.fetchSegment({
        project: object.get('project'),
        product: object.get('product'),
        segment: object.get('segment'),
        id: object.get('id'),
      })
    );
  }
}

function* segmentSaga() {
  yield all([
    takeLatest(types.SEGMENT_CREATE, createSegment),
    takeLatest(types.SEGMENT_DELETE, deleteSegment),
    takeLatest(types.SEGMENT_FETCH, fetchSegment),
    takeLatest(types.SEGMENT_UPDATE, updateSegment),
    takeEvery(types.SEGMENTS_LIST, listSegments),
    takeEvery(types.SEGMENTS_LIST_ALL, listAllSegments),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default segmentSaga;
