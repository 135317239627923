import { Record } from 'immutable';

const DemoExampleRecord = Record({
  id: null,
  project: null,
  business_model: 1,
  country: null,
  cover_image: null,
  default: true,
  image: null,
  sector: null,
  type_of_product: 0,
});

class DemoExample extends DemoExampleRecord {}

export default DemoExample;
