// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Dependencies
import { Step } from 'models/Tools';

// Assets
import { ReactComponent as emptyCheck } from 'static/icons/00-general/00-1-icons/icon_empty.svg';
import { ReactComponent as attention } from 'static/icons/00-general/00-1-icons/icon_attention.svg';
import { ReactComponent as filledCheck } from 'static/icons/00-general/00-1-icons/icon_complete.svg';
import { ReactComponent as edit } from 'static/icons/00-general/00-1-icons/icon_edit.svg';
import { ReactComponent as iconView } from 'static/icons/icon_view.svg';

// Components
import Icon from '@ant-design/icons';

const RawStepIcon = props => {
  const { current, status, ...rest } = props;
  const iconMap = {
    [Step.STATUS_INCOMPLETE]: emptyCheck,
    [Step.STATUS_IN_PROGRESS]: attention,
    [Step.STATUS_COMPLETE]: filledCheck,
    [Step.STATUS_STATUS_PENDING_REVIEW]: iconView,
  };
  const icon = current ? edit : iconMap[status];
  return <Icon {...rest} component={icon} disabled />;
};

RawStepIcon.propTypes = {
  current: PropTypes.bool,
  status: PropTypes.number,
};

RawStepIcon.defaultProps = {
  current: false,
  status: Step.STATUS_INCOMPLETE,
};

const getColor = ({ current, status, theme }) => {
  const colorsMap = {
    [Step.STATUS_IN_PROGRESS]: theme.greyColor,
    [Step.STATUS_INCOMPLETE]: theme.warningColor,
    [Step.STATUS_COMPLETE]: theme.primaryColor,
    [Step.STATUS_STATUS_PENDING_REVIEW]: theme.violet,
  };
  return current ? theme.secondaryColor : colorsMap[status];
};

const StepIcon = styled(RawStepIcon)`
  color: ${getColor};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 19px;
  transition: all ease-in 0.15s;
  :hover {
    color: ${({ disabled, theme }) => (disabled ? theme.greyColor : theme.itemActiveColor)};
  }
`;

export default StepIcon;
