/* eslint-disable camelcase */
// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { ProgressCircle } from 'components/elements';
import { P } from 'components/fontSystem';
import { Popover } from 'antd';

const GoalStats = props => {
  const { data, t } = props;

  if (!data) return;

  const application_count =
    data.program_goals.e_application_count ?? data.program_goals.m_application_count;
  const application_goal =
    data.program_goals.e_application_goal ?? data.program_goals.m_application_goal;
  const approval_count = data.program_goals.e_approval_count ?? data.program_goals.m_approval_count;
  const approval_goal = data.program_goals.e_approval_goal ?? data.program_goals.m_approval_goal;
  const incubation_count =
    data.program_goals.e_incubation_count ?? data.program_goals.m_incubation_count;
  const incubation_goal =
    data.program_goals.e_incubation_goal ?? data.program_goals.m_incubation_goal;

  const appProgress = Math.round((application_count / application_goal) * 100);
  const aprProgress = Math.round((approval_count / approval_goal) * 100);
  const incubProgress = Math.round((incubation_count / incubation_goal) * 100);

  const LILAC = '#5C5CEA';
  const BLUE = '#0FC5D8';
  const GREEN = '#1A754E';

  return (
    <Wrapper>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              maxWidth: '190px',
            }}
          >
            <p style={{ marginBottom: '5px' }}>
              <b>{t('Application Goal Completion ')}</b>(<span style={{ color: BLUE }}>%</span>){' '}
              {t('= Application Submitted ')}(
              <span style={{ color: BLUE }}>{application_count}</span>){t(' / Application Goal ')}(
              <span style={{ color: BLUE }}>{application_goal || 0}</span>) * 100%
            </p>
          </div>
        }
        trigger="hover"
      >
        <ProgressCircle
          strokeColor={BLUE}
          color={BLUE}
          opacity="rgba(15, 196, 216, 0.1)"
          percent={appProgress}
          strokeWidth={10}
          width={70}
          type="circle"
        />
      </Popover>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              maxWidth: '190px',
            }}
          >
            <p style={{ marginBottom: '5px' }}>
              <b>{t('Appoval Goal Completion ')}</b>(<span style={{ color: GREEN }}>%</span>){' '}
              {t('= Application Approved ')}(<span style={{ color: GREEN }}>{approval_count}</span>)
              {t(' / Approval Goal ')}(<span style={{ color: GREEN }}>{approval_goal || 0}</span>) *
              100%
            </p>
          </div>
        }
        trigger="hover"
      >
        <ProgressCircle
          strokeColor={GREEN}
          color={GREEN}
          opacity="rgba(26, 117, 78, 0.1)"
          percent={aprProgress}
          strokeWidth={10}
          width={70}
          type="circle"
        />
      </Popover>
      <Popover
        placement="bottom"
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              maxWidth: '190px',
            }}
          >
            <p style={{ marginBottom: '5px' }}>
              <b>{t('Incubation Goal Completion ')}</b>(<span style={{ color: LILAC }}>%</span>){' '}
              {t('= Projects in Incubation ')}(
              <span style={{ color: LILAC }}>{incubation_count}</span>){t(' / Incubation Goal ')}(
              <span style={{ color: LILAC }}>{incubation_goal || 0}</span>) * 100%
            </p>
          </div>
        }
        trigger="hover"
      >
        <ProgressCircle
          strokeColor={LILAC}
          color={LILAC}
          opacity="rgba(92, 92, 234, 0.1)"
          percent={incubProgress}
          strokeWidth={10}
          width={70}
          type="circle"
        />
      </Popover>
      <div className="text-wrapper">
        <div className="text">
          <P>{t('Application Goal')}</P>
          <span data-test-id="app-goal">{application_goal || 0}</span>
        </div>
        <div className="text">
          <P>{t('Approval Goal')}</P>
          <span className="green" data-test-id="approval-goal">
            {approval_goal || 0}
          </span>
        </div>
        <div className="text">
          <P>{t('Incubation Goal')}</P>
          <span className="lilac" data-test-id="incubation-goal">
            {incubation_goal || 0}
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

GoalStats.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(18, 61, 198, 0.2);
    .ant-progress {
      padding: 0 8px;
    }
    .text-wrapper {
      min-width: 130px;
      color: #0fc5d8;
      .text {
        display: flex;
        justify-content: space-between;
        p {
          margin: 0;
        }
        .lilac {
          color: '#5C5CEA';
        }
        .green {
          color: '#1A754E';
        }
      }
    }
  }
`;

export default GoalStats;
