import { types } from 'store/modules/userLink';

const userLinkRequest = query => ({
  type: types.USER_LINK_REQUEST,
  payload: query,
});

const userLinkSucceeded = ({ userLinkData }) => ({
  type: types.USER_LINK_SUCCEEDED,
  payload: userLinkData,
});

const userLinkFailed = ({ message }) => ({
  type: types.USER_LINK_FAILED,
  payload: message,
});

const userLinkBulkEdit = ({ user, data }, callback) => ({
  type: types.USER_LINK_BULK_EDIT,
  payload: { user, data },
  callback,
});

const userLinkBulkEditSucceeded = ({ userLinkData }) => ({
  type: types.USER_LINK_BULK_EDIT_SUCCEEDED,
  payload: userLinkData,
});

const userLinkBulkEditFailed = ({ message }) => ({
  type: types.USER_LINK_BULK_EDIT_FAILED,
  payload: message,
});

export default {
  userLinkRequest,
  userLinkSucceeded,
  userLinkFailed,
  userLinkBulkEdit,
  userLinkBulkEditSucceeded,
  userLinkBulkEditFailed,
};
