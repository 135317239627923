import { Record } from 'immutable';
import ContentType from '../ContentType';

const RelationshipRecord = Record({
  id: null,
  description: null,
  system: null,
  first_role: null,
  second_role: null,
  created: null,
  updated: null,
  comments: [],
});

class Relationship extends RelationshipRecord {
  static contentType = ContentType.IPRESOURCE;

  get getId() {
    return this.get('id');
  }
}

export default Relationship;
