// Libraries
import React, { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from 'components/elements/Loader';

const LoadableComponent = props => {
  const { importedComponent, loaderComponent: LoaderComponent = null } = props;

  const ImportedComponent = useMemo(() => lazy(importedComponent), [importedComponent]);
  return (
    <Suspense fallback={LoaderComponent ? <LoaderComponent /> : <Loader />}>
      <ImportedComponent {...props} />
    </Suspense>
  );
};

LoadableComponent.propTypes = {
  importedComponent: PropTypes.func.isRequired,
  loaderComponent: PropTypes.node,
};

const Loadable =
  ({ importedComponent, loader }) =>
  props => <LoadableComponent importedComponent={importedComponent} loader={loader} {...props} />;

export default Loadable;
