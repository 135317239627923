import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CheckOutlined } from '@ant-design/icons';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';

// Components
import { Img } from 'components/elements';
import Icon from '@ant-design/icons';
import { Col } from 'antd';
import { P, semiBoldStyle } from 'components/fontSystem';

// Assets
import imageProfile from 'static/images/program/e-welcome_1-profile.png';
import imageApply from 'static/images/program/e-welcome_2-apply.png';
import imageMatching from 'static/images/program/e-welcome_3-matching.png';
import imageWork from 'static/images/program/e-welcome_4-work.png';
import imageES from 'static/images/program/e-welcome_5-vbp.png';

export const STEP_PROFILE = 0;
export const STEP_PROGRAM = 1;
export const STEP_MENTOR = 2;
export const STEP_VENTURE = 3;
export const STEP_ES = 4;

const getStages = t => [
  {
    image: imageProfile,
    text: t('Create your profile'),
    id: STEP_PROFILE,
  },
  {
    image: imageApply,
    text: t('Apply to a program'),
    id: STEP_PROGRAM,
  },
  {
    image: imageMatching,
    text: t('Match with a mentor'),
    id: STEP_MENTOR,
  },
  {
    image: imageWork,
    text: t('Work on your venture'),
    id: STEP_VENTURE,
  },
  {
    image: imageES,
    text: t('Get your Executive Summary & Celebrate!'),
    id: STEP_ES,
  },
];

const OnboardingStages = props => {
  const { currentStep, boxShadow } = props;
  const { t } = React.useContext(TranslationsContext);
  const stages = React.useMemo(() => getStages(t), [t]);

  return (
    <StyledDiv className={boxShadow && 'box-shadow'}>
      {stages.map(stage => (
        <StyledCol className={currentStep !== stage.id && 'opacity'} flex={1}>
          <Img src={stage.image} alt={stage.text} />
          <P className="col-title">{stage.text}</P>
          {stage.id < currentStep && <CheckOutlined />}
        </StyledCol>
      ))}
    </StyledDiv>
  );
};

const StyledCol = styled(Col)`
  width: 150px;
  padding: 10px;
  text-align: center;
  .col-title {
    padding-top: 10px;
    margin: 0;
    ${semiBoldStyle};
  }
  &.opacity {
    opacity: 0.5;
    .col-title {
      font-weight: normal;
    }
  }

  .anticon {
    font-size: 23px;
    margin-top: 10px;
  }
  @media screen and (min-width: ${({ theme }) => theme.screenMdMin}) {
    width: auto;
  }
`;

const StyledDiv = styled.div`
  margin: 0 0 10px 0;
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  &.box-shadow {
    box-shadow: 2px 2px 8px ${({ theme }) => theme.primaryColorFade};
  }
  @media screen and (min-width: ${({ theme }) => theme.screenMdMin}) {
    flex-flow: nowrap;
    /* justify-content: space-evenly; */
  }
`;

OnboardingStages.propTypes = {
  currentStep: PropTypes.number.isRequired,
  boxShadow: PropTypes.bool,
};

OnboardingStages.defaultProps = {
  boxShadow: false,
};

export default OnboardingStages;
