// Libraries
import React from 'react';

// Components
import DesktopPopup from './DesktopPopup';

export const WIDTH_BREAKPOINT = 644;

function WelcomePopup(props) {
  // eslint-disable-next-line no-unused-vars
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <DesktopPopup {...props} data-test="component-desktop-popup" />;
}

export default WelcomePopup;
