import { createSelector } from 'reselect';

const getState = state => state.pricingModel;

const getRawPricingModels = createSelector([getState], state => state.get('data'));

const getPricingModelsById = createSelector([getRawPricingModels], state => state.get('byId'));

const getPricingModelsByProduct = createSelector([getRawPricingModels], state =>
  state.get('byProduct')
);

const getPricingModels = createSelector([getPricingModelsById], state => state.valueSeq().toJS());

const getProductPricingModels = createSelector([getPricingModelsByProduct], state => state.toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const getProductPricingModelByModelId = (pricingModelsByProduct = [], id) => {
  if (!pricingModelsByProduct) return undefined;
  const productPricingModel = pricingModelsByProduct.find(
    productModel => productModel.pricing_model === id
  );
  return productPricingModel;
};

export default {
  getPricingModelsByProduct,
  getPricingModelsById,
  getPricingModels,
  getProductPricingModels,
  getErrorMessage,
  getProductPricingModelByModelId,
};
