import { normalize, schema } from 'normalizr';

const initiativeSchema = new schema.Entity('initiatives');
const keyResultSchema = new schema.Entity('key_results');
const objectiveSchema = new schema.Entity('objective', {
  initiatives: [initiativeSchema],
  key_results: [keyResultSchema],
});

const normalizeObjective = data => {
  // https://redux.js.org/recipes/structuringreducers/normalizingstateshape#relationships-and-tables
  const { result, entities } = normalize(data, objectiveSchema);
  return entities.objective[result];
};

const normalizeObjectives = data => {
  const {
    entities: { objective, initiatives, key_results: keyResults },
  } = normalize(data, [objectiveSchema]);
  return {
    objectives: objective ? Object.values(objective) : [],
    initiatives: initiatives ? Object.values(initiatives) : [],
    key_results: keyResults ? Object.values(keyResults) : [],
  };
};

export { normalizeObjective, normalizeObjectives };
