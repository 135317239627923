import { Record } from 'immutable';

const SURVEY_STATUS_PENDING = 0;
const SURVEY_STATUS_SENT = 1;

const STATUS_IN_PROGRESS = 0; // Application
const STATUS_PENDING_DECISION = 1; // Selection
const STATUS_APPROVED_NO_EMAIL = 4; // Approved
const STATUS_APPROVED_ONBOARDING_SESSION = 6; // Approved
const STATUS_UNMATCHED = 7; // Matching
const STATUS_MATCHED = 8; // Matching
const STATUS_INCUBATING = 9; // Incubating
const STATUS_ALUMNI = 10; // Alumni

const REJ_EARLY = 0;
const REJ_LATE = 1;
const REJ_PROJECT = 2;
const REJ_MENTORS = 3;
const REJ_LANGUAGE = 4;
const REJ_CONNECTION = 5;
const REJ_INACTIVE = 6;
export const REJ_OTHER = 7;
export const REJ_REASONS = [
  REJ_EARLY,
  REJ_LATE,
  REJ_PROJECT,
  REJ_MENTORS,
  REJ_LANGUAGE,
  REJ_CONNECTION,
  REJ_INACTIVE,
  REJ_OTHER,
];

const DROP_INACTIVE = 0;
const DROP_TIMING = 1;
const DROP_NO_MENTOR_INTEREST = 2;
const DROP_DIDNT_LIKE_MENTORS = 3;
const DROP_NOT_SUPPORTED_BY_MENTOR = 4;
const DROP_NOT_SUPPORTED_BY_BRIDGE = 5;
const DROP_CONFUSED_FRUSTRATED = 6;
const DROP_NOT_PREPARED = 7;
const DROP_OVER_PREPARED = 8;
const DROP_PAUSED_PROGRAM = 9;

export const DROP_REASON = [
  DROP_INACTIVE,
  DROP_TIMING,
  DROP_NO_MENTOR_INTEREST,
  DROP_DIDNT_LIKE_MENTORS,
  DROP_NOT_SUPPORTED_BY_MENTOR,
  DROP_NOT_SUPPORTED_BY_BRIDGE,
  DROP_CONFUSED_FRUSTRATED,
  DROP_NOT_PREPARED,
  DROP_OVER_PREPARED,
  DROP_PAUSED_PROGRAM,
];

const INACTIVE = {
  id: DROP_INACTIVE,
  value: 'becameInactive',
  text: 'Became Inactive',
};

const TIMING = {
  id: DROP_TIMING,
  value: 'badTiming',
  text: 'Bad Timing',
};

const INTEREST = {
  id: DROP_NO_MENTOR_INTEREST,
  value: 'hadHoMentorInterest',
  text: 'Had no mentor interest',
};

const LIKE_MENTOR = {
  id: DROP_DIDNT_LIKE_MENTORS,
  value: 'didntLikeMentors',
  text: 'Didnt like mentors',
};

const MENTOR_SUPPORT = {
  id: DROP_NOT_SUPPORTED_BY_MENTOR,
  value: 'notSupportedByMentor',
  text: 'Not supported by mentor',
};

const BRIDGE_SUPPORT = {
  id: DROP_NOT_SUPPORTED_BY_BRIDGE,
  value: 'notSupportedByBridge',
  text: 'Not supported by bridge',
};

const CONFUSED = {
  id: DROP_CONFUSED_FRUSTRATED,
  value: 'confusedFrustrated',
  text: 'Confused / Frustrated',
};

const PREPARED = {
  id: DROP_NOT_PREPARED,
  value: 'wasNotPrepared',
  text: 'Was not prepared',
};

const OVER_PREPARED = {
  id: DROP_OVER_PREPARED,
  value: 'wasOverPrepared',
  text: 'Was over-prepared',
};

const PAUSED = {
  id: DROP_PAUSED_PROGRAM,
  value: 'pausedProgram',
  text: 'Paused Program',
};

export const MEMBER_APP_DROP_OPTIONS = {
  INACTIVE,
  TIMING,
  INTEREST,
  LIKE_MENTOR,
  MENTOR_SUPPORT,
  BRIDGE_SUPPORT,
  CONFUSED,
  PREPARED,
  OVER_PREPARED,
  PAUSED,
};

export const PM_STATUS_APPLICATION = 'application';
export const PM_STATUS_SELECTION = 'selection';
export const PM_STATUS_APPROVED = 'approved';
export const PM_STATUS_MATCHING = 'matching';
export const PM_STATUS_INCUBATION = 'incubating';
export const PM_STATUS_ALUMNI = 'alumni';
export const PM_STATUS_DROPPED = 'dropped';
export const PM_STATUS_REJECTED = 'rejected';

/*
PROGRAM PHASES:
- Application <- endpoint
  - Application <- application_stage
  - Selection <- application_stage
  - Approved <- application_stage
- Matching <- endpoint
- Incubating <- endpoint
- Alumni <- endpoint
*/
export const PM_STATUS_MAP = [
  PM_STATUS_APPLICATION, // Program Phase: Application | Application phase: 'Application'
  PM_STATUS_SELECTION, // Program Phase: Application | Application phase: 'Selection'
  PM_STATUS_APPROVED, // Program Phase: Application | Application phase: 'Approved'
  PM_STATUS_MATCHING, // Program Phase: Matching
  PM_STATUS_INCUBATION, // Program Phase: Incubating
  PM_STATUS_ALUMNI, // Program Phase: Alumni
  PM_STATUS_DROPPED, // Program Phase: Dropped
  PM_STATUS_REJECTED, // Program Phase: Rejected
];

export const REVIEW_STATUS_PENDING_REVIEW = 0;
export const REVIEW_STATUS_IN_PROGRESS = 1;
export const REVIEW_STATUS_REVIEWED = 2;
export const REVIEW_STATUS_DONE = 3;
export const REVIEW_STATUS_NO_MATCH = 4;
export const REVIEW_STATUS_EXPIRED = 5;
export const REVIEW_STATUS_RANKING_PENDING = 6;
export const REVIEW_STATUS_INCUBATING = 7;
export const REVIEW_STATUS_ALUMNI = 8;

export const REVIEW_STATUSES = {
  REVIEW_STATUS_PENDING_REVIEW,
  REVIEW_STATUS_IN_PROGRESS,
  REVIEW_STATUS_REVIEWED,
  REVIEW_STATUS_DONE,
  REVIEW_STATUS_NO_MATCH,
  REVIEW_STATUS_EXPIRED,
  REVIEW_STATUS_RANKING_PENDING,
  REVIEW_STATUS_INCUBATING,
  REVIEW_STATUS_ALUMNI,
};

export const getPMStageByApplicationStatus = status => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return PM_STATUS_APPLICATION;
    case STATUS_PENDING_DECISION:
      return PM_STATUS_SELECTION;
    case STATUS_APPROVED_NO_EMAIL:
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return PM_STATUS_APPROVED;
    case STATUS_UNMATCHED:
    case STATUS_MATCHED:
      return PM_STATUS_MATCHING;
    case STATUS_INCUBATING:
      return PM_STATUS_INCUBATION;
    case STATUS_ALUMNI:
      return PM_STATUS_ALUMNI;
    default:
      return 'Application Incomplete';
  }
};

export const getStatusDisplay = (t, status) => {
  switch (status) {
    case STATUS_IN_PROGRESS:
      return t('In Progress');
    case STATUS_PENDING_DECISION:
      return t('Pending Decision');
    case STATUS_APPROVED_NO_EMAIL:
      return t('Other Next Steps');
    case STATUS_APPROVED_ONBOARDING_SESSION:
      return t('BFB Onboarding');
    case STATUS_INCUBATING:
      return t('Incubating');
    case STATUS_ALUMNI:
      return t('Alumni');
    case STATUS_UNMATCHED:
      return t('Unmatched');
    case STATUS_MATCHED:
      return t('Matched');
    default:
      return t('Application Incomplete');
  }
};

// Accept Application Options
const getIncompleteOption = t => ({
  id: STATUS_IN_PROGRESS,
  value: 'incomplete',
  label: t('Move to Application'),
  view: 'application',
});

const getSelectionOption = t => ({
  id: STATUS_PENDING_DECISION,
  value: 'selection',
  label: t('Move to Selection'),
  view: 'selection',
});

const getApprovedOption = t => ({
  id: STATUS_APPROVED_ONBOARDING_SESSION,
  value: 'approved',
  label: t('Accept & Attend Onboarding Session'),
  view: 'approved',
});

const getFollowUpOption = t => ({
  id: STATUS_APPROVED_NO_EMAIL,
  value: 'manualFollowUp',
  label: t('Accept, but no email sent'),
  view: 'approved',
});

const getMatchingOption = t => ({
  id: STATUS_UNMATCHED,
  value: 'matching',
  label: t('Accept & move directly to MATCHING'),
  view: 'matching',
});

const getIncubatingOption = t => ({
  id: STATUS_INCUBATING,
  value: 'incubating',
  label: t('Accept & move directly to INCUBATING'),
  view: 'incubating',
});

export const getProjectAcceptOptions = t => [
  getIncompleteOption(t),
  getSelectionOption(t),
  getApprovedOption(t),
  getFollowUpOption(t),
  getMatchingOption(t),
  getIncubatingOption(t),
];

// Reject Application Options
const getTooEarlyOption = t => ({
  id: REJ_EARLY,
  value: 'tooEarly',
  label: t('Too Early-Stage'),
});

const getTooMatureOption = t => ({
  id: REJ_LATE,
  value: 'tooMature',
  label: t('Too Mature'),
});

const getProblemWithProjectOption = t => ({
  id: REJ_PROJECT,
  value: 'problemProject',
  label: t('Problem with Project'),
});

const getProblemWithMentorOption = t => ({
  id: REJ_MENTORS,
  value: 'problemMentor',
  label: t('Problem with Mentor'),
});

const getProblemWithLanguageOption = t => ({
  id: REJ_LANGUAGE,
  value: 'problemLanguage',
  label: t('Problem with Language'),
});

const getProblemWithConnectivityOption = t => ({
  id: REJ_CONNECTION,
  value: 'problemConnectivity',
  label: t('Problem with Connectivity'),
});

const getBecameInactiveOption = t => ({
  id: REJ_INACTIVE,
  value: 'becameInactive',
  label: t('Became Inactive'),
});

const getOtherOption = t => ({
  id: REJ_OTHER,
  value: 'other',
  label: t('Other (no email sent)'),
});

export const getProjectRejectOptions = t => [
  getTooEarlyOption(t),
  getTooMatureOption(t),
  getProblemWithProjectOption(t),
  getProblemWithMentorOption(t),
  getProblemWithLanguageOption(t),
  getProblemWithConnectivityOption(t),
  getBecameInactiveOption(t),
  getOtherOption(t),
];

export const getRejectedStatusDisplay = (t, rejected) => {
  switch (rejected) {
    case REJ_EARLY:
      return t('Too Early');
    case REJ_LATE:
      return t('Too Late');
    case REJ_PROJECT:
      return t('Problem w/ Project');
    case REJ_MENTORS:
      return t('Problem w/ Mentors');
    case REJ_LANGUAGE:
      return t('Problem w/ Language');
    case REJ_CONNECTION:
      return t('Problem w/ Connectivity');
    case REJ_INACTIVE:
      return t('Became inactive');
    case REJ_OTHER:
      return t('Other (no email sent)');
    default:
      return undefined;
  }
};

export const getDroppedStatusDisplay = (t, dropped) => {
  switch (dropped) {
    case DROP_INACTIVE:
      return t('Became Inactive');
    case DROP_TIMING:
      return t('Bad Timing');
    case DROP_NO_MENTOR_INTEREST:
      return t('Had no mentor interest');
    case DROP_DIDNT_LIKE_MENTORS:
      return t('Didnt like mentors');
    case DROP_NOT_SUPPORTED_BY_MENTOR:
      return t('Not supported by mentor');
    case DROP_NOT_SUPPORTED_BY_BRIDGE:
      return t('Not supported by bridge');
    case DROP_CONFUSED_FRUSTRATED:
      return t('Confused / Frustrated');
    case DROP_NOT_PREPARED:
      return t('Was not prepared');
    case DROP_OVER_PREPARED:
      return t('Was over-prepared');
    case DROP_PAUSED_PROGRAM:
      return t('Paused Program');
    default:
      return undefined;
  }
};

export const getRejectedOrStatusDisplay = (format, t, rejected, status) => {
  // If the application is rejected, show rejected
  if (rejected !== null && rejected !== undefined) {
    return format(t('Rejected due to %(rejected_reason)s'), {
      rejected_reason: getRejectedStatusDisplay(t, rejected),
    });
  }
  // Otherwise, show the app status.
  if (status !== null && status !== undefined) {
    return getStatusDisplay(t, status);
  }

  return t('Undefined'); // This is provisional, we have to think about which will be the name displayed when application status and application rejected are null.
};

export const getApplicationReviewStatusDisplay = (t, reviewStatus) => {
  switch (reviewStatus) {
    case REVIEW_STATUS_PENDING_REVIEW:
      return t('Pending Review');
    case REVIEW_STATUS_IN_PROGRESS:
      return t('In Progress');
    case REVIEW_STATUS_REVIEWED:
      return t('Reviewed');
    case REVIEW_STATUS_DONE:
      return t('Done');
    case REVIEW_STATUS_NO_MATCH:
      return t('No Match');
    case REVIEW_STATUS_EXPIRED:
      return t('Expired');
    case REVIEW_STATUS_RANKING_PENDING:
      return t('Ranking Pending');
    case REVIEW_STATUS_INCUBATING:
      return t('Incubating');
    case REVIEW_STATUS_ALUMNI:
      return t('Alumni');
    default:
      return '';
  }
};

const MemberApplicationRecord = Record({
  id: null,
  member: null,
  review_status: null,
  additional_answer: null,
  additional_answer_1: null,
  additional_answer_2: null,
  additional_answer_3: null,
  additional_answer_4: null,
  additional_answer_5: null,
  additional_answer_6: null,
  additional_answer_7: null,
  check_commitment: null,
  check_duration: null,
  check_payment: null,
  complete_surveys: [],
  dropped: null,
  need_grant: null,
  matching_notes: '',
  application_notes: '',
  incubating_notes: '',
  open_to_comentor: null,
  participate_in_another_program_if_rejected: null,
  program_type: null,
  program: null,
  progress: null,
  project: null,
  pti_survey_status: SURVEY_STATUS_PENDING,
  ranking_reasons: null,
  ranking_submitted: null,
  ranking: [],
  recommended_program_referrer: null,
  recommended_program: null,
  referrer: null,
  rejected: null,
  remark: null,
  status: null,
  survey_responses_eoi: [],
  survey_responses_pc_ent: [],
  survey_responses_pp: [],
  survey_responses_pti: [],
  team_description: '',
  team: null,
  venture_summary_complete: null,
  last_follow_up_date: null,
});

class MemberApplication extends MemberApplicationRecord {
  static STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;
  static STATUS_PENDING_DECISION = STATUS_PENDING_DECISION;
  static STATUS_APPROVED_NO_EMAIL = STATUS_APPROVED_NO_EMAIL;
  static STATUS_APPROVED_ONBOARDING_SESSION = STATUS_APPROVED_ONBOARDING_SESSION;
  static STATUS_UNMATCHED = STATUS_UNMATCHED;
  static STATUS_MATCHED = STATUS_MATCHED;
  static STATUS_INCUBATING = STATUS_INCUBATING;
  static STATUS_ALUMNI = STATUS_ALUMNI;

  static REJ_EARLY = REJ_EARLY;
  static REJ_LATE = REJ_LATE;
  static REJ_PROJECT = REJ_PROJECT;
  static REJ_MENTORS = REJ_MENTORS;
  static REJ_LANGUAGE = REJ_LANGUAGE;
  static REJ_CONNECTION = REJ_CONNECTION;
  static REJ_INACTIVE = REJ_INACTIVE;
  static REJ_OTHER = REJ_OTHER;

  static DROP_INACTIVE = DROP_INACTIVE;
  static DROP_TIMING = DROP_TIMING;
  static DROP_NO_MENTOR_INTEREST = DROP_NO_MENTOR_INTEREST;
  static DROP_DIDNT_LIKE_MENTORS = DROP_DIDNT_LIKE_MENTORS;
  static DROP_NOT_SUPPORTED_BY_MENTOR = DROP_NOT_SUPPORTED_BY_MENTOR;
  static DROP_NOT_SUPPORTED_BY_BRIDGE = DROP_NOT_SUPPORTED_BY_BRIDGE;
  static DROP_CONFUSED_FRUSTRATED = DROP_CONFUSED_FRUSTRATED;
  static DROP_NOT_PREPARED = DROP_NOT_PREPARED;
  static DROP_OVER_PREPARED = DROP_OVER_PREPARED;
  static DROP_PAUSED_PROGRAM = DROP_PAUSED_PROGRAM;

  static SURVEY_STATUS_PENDING = SURVEY_STATUS_PENDING;
  static SURVEY_STATUS_SENT = SURVEY_STATUS_SENT;

  get id() {
    return this.get('id');
  }

  getStatusDisplay = t => getStatusDisplay(t, this.get('status'));
  getRejectedStatusDisplay = t => getRejectedStatusDisplay(t, this.get('rejected'));
  getDroppedStatusDisplay = t => getDroppedStatusDisplay(t, this.get('dropped'));
}

export default MemberApplication;
