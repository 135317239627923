import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

const getState = state => state.payroll;

const getRawPayroll = createSelector([getState], state => state.get('data'));

const getPayrollsById = createSelector([getRawPayroll], state => state.get('byId'));

const getPayrollsByScenario = createSelector([getRawPayroll], state => state.get('byScenario'));

const getScenarioPayrolls = createSelector(
  [getPayrollsByScenario],
  state => scenarioId => state.get(String(scenarioId), new List())
);

const getSelectedScenarioPayrolls = createSelector([getPayrollsByScenario], state => scenarioId => {
  const payrolls = state.get(String(scenarioId), new List());
  return payrolls.filter(payroll => payroll.isSelected);
});

const getSelectedScenarioPayrollsById = createSelector(
  [getSelectedScenarioPayrolls],
  getPayrolls => scenarioId =>
    getPayrolls(scenarioId).reduce(
      (prevData, payroll) => prevData.set(String(payroll.id), payroll),
      new Map()
    )
);

const getPayrolls = createSelector([getPayrollsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getErrorMessage,
  getPayrolls,
  getPayrollsById,
  getPayrollsByScenario,
  getScenarioPayrolls,
  getSelectedScenarioPayrolls,
  getSelectedScenarioPayrollsById,
  isFetching,
};
