const DEEPDIVE_LIST = 'bfb/DeepDive/DEEPDIVE_LIST';
const DEEPDIVE_LIST_SUCCEEDED = 'bfb/DeepDive/DEEPDIVE_LIST_SUCCEEDED';
const DEEPDIVE_LIST_FAILED = 'bfb/DeepDive/DEEPDIVE_LIST_FAILED';
const DEEPDIVE_CREATE = 'bfb/DeepDive/DEEPDIVE_CREATE';
const DEEPDIVE_CREATE_SUCCEEDED = 'bfb/DeepDive/DEEPDIVE_CREATE_SUCCEEDED';
const DEEPDIVE_CREATE_FAILED = 'bfb/DeepDive/DEEPDIVE_CREATE_FAILED';
const DEEPDIVE_EDIT = 'bfb/DeepDive/DEEPDIVE_EDIT';
const DEEPDIVE_EDIT_SUCCEEDED = 'bfb/DeepDive/DEEPDIVE_EDIT_SUCCEEDED';
const DEEPDIVE_EDIT_FAILED = 'bfb/DeepDive/DEEPDIVE_EDIT_FAILED';
const DEEPDIVE_DELETE = 'bfb/DeepDive/DEEPDIVE_DELETE';
const DEEPDIVE_DELETE_SUCCEEDED = 'bfb/DeepDive/DEEPDIVE_DELETE_SUCCEEDED';
const DEEPDIVE_DELETE_FAILED = 'bfb/DeepDive/DEEPDIVE_DELETE_FAILED';

export default {
  DEEPDIVE_LIST,
  DEEPDIVE_LIST_SUCCEEDED,
  DEEPDIVE_LIST_FAILED,
  DEEPDIVE_CREATE,
  DEEPDIVE_CREATE_SUCCEEDED,
  DEEPDIVE_CREATE_FAILED,
  DEEPDIVE_EDIT,
  DEEPDIVE_EDIT_SUCCEEDED,
  DEEPDIVE_EDIT_FAILED,
  DEEPDIVE_DELETE,
  DEEPDIVE_DELETE_SUCCEEDED,
  DEEPDIVE_DELETE_FAILED,
};
