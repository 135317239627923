// Libraries
import React, { useContext } from 'react';
import styled from 'styled-components';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 100%;
  border-radius: 4px;
  background-color: #f2f2f2;

  span {
    color: #999999;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }
`;

const NoStepYet = () => {
  const { t } = useContext(TranslationsContext);
  return (
    <StyledDiv>
      <span>{t('This step will be ready very soon.')}</span>
    </StyledDiv>
  );
};

export default NoStepYet;
