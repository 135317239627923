import { types } from 'store/modules/mentorApplication';

const createApplication = ({ mentorId, program, ...application }, callback) => ({
  type: types.APPLICATION_CREATE,
  payload: { mentorId, program, ...application },
  callback,
});

const createApplicationSucceeded = ({ id, ...application }) => ({
  type: types.APPLICATION_CREATE_SUCCEEDED,
  payload: { id, ...application },
});

const createApplicationFailed = ({ message }) => ({
  type: types.APPLICATION_CREATE_FAILED,
  payload: { message },
});

const listApplications = () => ({
  type: types.APPLICATIONS_LIST,
});

const listApplicationsSucceeded = ({ applicationsData }) => ({
  type: types.APPLICATIONS_LIST_SUCCEEDED,
  payload: applicationsData,
});

const listApplicationsFailed = ({ message }) => ({
  type: types.APPLICATIONS_LIST_FAILED,
  payload: { message },
});

const fetchApplication = ({ id }) => ({
  type: types.APPLICATION_FETCH_REQUESTED,
  payload: { id },
});

const fetchApplicationSucceeded = ({ id, ...application }) => ({
  type: types.APPLICATION_FETCH_SUCCEEDED,
  payload: { id, ...application },
});

const fetchApplicationFailed = ({ message }) => ({
  type: types.APPLICATION_FETCH_FAILED,
  payload: { message },
});

const updateApplication = ({ id, ...application }, callback) => ({
  type: types.APPLICATION_UPDATE,
  payload: { id, ...application },
  callback,
});

const updateApplicationBulk = ({ ids, program, status, stage, rejected }, callback) => ({
  type: types.APPLICATION_BULK_UPDATE,
  payload: { ids, program, status, stage, rejected },
  callback,
});

const updateApplicationBulkSucceeded = payload => ({
  type: types.APPLICATION_BULK_UPDATE_SUCCEEDED,
  payload,
});

const updateApplicationBulkFailed = ({ message }) => ({
  type: types.APPLICATION_BULK_UPDATE_FAILED,
  payload: { message },
});

const updateApplicationSucceeded = ({ applicationData }) => ({
  type: types.APPLICATION_UPDATE_SUCCEEDED,
  payload: { ...applicationData },
});

const updateApplicationFailed = ({ message }) => ({
  type: types.APPLICATION_UPDATE_FAILED,
  payload: message,
});

const validateApplicationStatus = ({ applicationId }) => ({
  type: types.APPLICATION_STATUS,
  payload: { applicationId },
});

const validateApplicationStatusSucceeded = ({ ...application }) => ({
  type: types.APPLICATION_STATUS_SUCCEEDED,
  payload: { ...application },
});

const validateApplicationStatusFailed = ({ message }) => ({
  type: types.APPLICATION_STATUS_FAILED,
  payload: { message },
});

export default {
  createApplication,
  createApplicationSucceeded,
  createApplicationFailed,
  listApplications,
  listApplicationsSucceeded,
  listApplicationsFailed,
  fetchApplication,
  fetchApplicationSucceeded,
  fetchApplicationFailed,
  updateApplication,
  updateApplicationSucceeded,
  updateApplicationFailed,
  updateApplicationBulk,
  updateApplicationBulkSucceeded,
  updateApplicationBulkFailed,
  validateApplicationStatus,
  validateApplicationStatusSucceeded,
  validateApplicationStatusFailed,
};
