const WORK_EXPERIENCE_REQUEST = 'bfb/workExperience/WORK_EXPERIENCE_REQUEST';
const WORK_EXPERIENCE_SUCCEEDED = 'bfb/workExperience/WORK_EXPERIENCE_SUCCEEDED';
const WORK_EXPERIENCE_FAILED = 'bfb/workExperience/WORK_EXPERIENCE_FAILED';
const WORK_EXPERIENCE_CREATE = 'bfb/workExperience/WORK_EXPERIENCE_CREATE';
const WORK_EXPERIENCE_CREATE_SUCCEEDED = 'bfb/workExperience/WORK_EXPERIENCE_CREATE_SUCCEEDED';
const WORK_EXPERIENCE_CREATE_FAILED = 'bfb/workExperience/WORK_EXPERIENCE_CREATE_FAILED';
const WORK_EXPERIENCE_UPDATE = 'bfb/workExperience/WORK_EXPERIENCE_UPDATE';
const WORK_EXPERIENCE_UPDATE_SUCCEEDED = 'bfb/workExperience/WORK_EXPERIENCE_UPDATE_SUCCEEDED';
const WORK_EXPERIENCE_UPDATE_FAILED = 'bfb/workExperience/WORK_EXPERIENCE_UPDATE_FAILED';
const WORK_EXPERIENCE_DELETE = 'bfb/workExperience/WORK_EXPERIENCE_DELETE';
const WORK_EXPERIENCE_DELETE_SUCCEEDED = 'bfb/workExperience/WORK_EXPERIENCE_DELETE_SUCCEEDED';
const WORK_EXPERIENCE_DELETE_FAILED = 'bfb/workExperience/WORK_EXPERIENCE_DELETE_FAILED';

export default {
  WORK_EXPERIENCE_REQUEST,
  WORK_EXPERIENCE_SUCCEEDED,
  WORK_EXPERIENCE_FAILED,
  WORK_EXPERIENCE_CREATE,
  WORK_EXPERIENCE_CREATE_SUCCEEDED,
  WORK_EXPERIENCE_CREATE_FAILED,
  WORK_EXPERIENCE_UPDATE,
  WORK_EXPERIENCE_UPDATE_SUCCEEDED,
  WORK_EXPERIENCE_UPDATE_FAILED,
  WORK_EXPERIENCE_DELETE,
  WORK_EXPERIENCE_DELETE_SUCCEEDED,
  WORK_EXPERIENCE_DELETE_FAILED,
};
