import { fromJS, List, Map } from 'immutable';
import selectedProgramLocalStorage from 'utils/selectedProgramLocalStorage';
import * as reduxHelper from 'store/utils/reduxHelper';
import Program from 'models/Program';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyRequestInfo({
    data: {
      byId: {},
      list: [],
      public: [],
      sections: [],
      userType: '',
      searchedPrograms: [],
    },
    // TODO: We must stop using deeep objects to store data
    selectedProgram: new Map(),
    errorMessage: '',
    updatePending: true,
  }),
});

export default function reducer(state = initialState, action) {
  const publicPrograms = action.payload && action.payload.public;
  const programsSections = action.payload && action.payload.sections;
  const userWhoRequested = action.payload && action.payload.request_performed_by;

  switch (action.type) {
    case types.PROGRAM_SEARCH:
    case types.PROGRAMS_LIST:
    case types.PROGRAMS_SECTIONS_LIST:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PROGRAMS_SECTIONS_LIST_SUCCEEDED: {
      const prevData = state.get('data').toJS();
      const userType = userWhoRequested ? action.payload.request_performed_by : '';
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            ...prevData,
            sections: [...action.payload.sections],
            userType: userType,
          },
        })
      );
    }
    case types.PROGRAMS_LIST_SUCCEEDED: {
      const initialData = state.get('data').get('byId', new Map()).toJS();
      const initialPrograms = Object.entries(initialData).reduce(
        (obj, [key, program]) => ({ ...obj, [key]: new Program(program) }),
        {}
      );
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: action.payload.results.reduce((obj, program) => {
              const prevProgram = obj[program.id];
              if (prevProgram && prevProgram.detail) {
                // If we already have the detail, we don't update the program
                return obj;
              }
              return { ...obj, [program.id]: new Program(program) };
            }, initialPrograms),
            list: action.payload.results.map(program => new Program(program)),
            public: publicPrograms
              ? action.payload.results.map(program => new Program(program))
              : state.get('data').get('public', new List()),
            userType: userWhoRequested ? action.payload.request_performed_by : '',
            sections: programsSections
              ? action.payload.sections
              : state.get('data').get('sections', new List()),
          },
        })
      );
    }
    case types.PROGRAM_SEARCH_SUCCEEDED: {
      const programsSearched = action.payload.programs.map(
        program => new Program({ id: program.id, ...program })
      );
      const prevData = state.get('data').toJS();
      const adjustedProgramsArray = [];
      let programsObject = {};
      programsSearched.map(program =>
        adjustedProgramsArray.push({
          [program.id]: program,
        })
      );
      adjustedProgramsArray.forEach(programObject => {
        programsObject = {
          ...programsObject,
          ...programObject,
        };
        return programsObject;
      });
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            ...prevData,
            list: programsSearched,
          },
        })
      );
    }
    case types.PROGRAM_FETCH_SUCCEEDED: {
      const program = new Program({ ...action.payload, detail: true });
      const isUpdatingSelectedProgram = state.get('selectedProgram').id === program.id;
      const prevData = state.get('data').toJS();
      if (isUpdatingSelectedProgram) {
        return state.merge(
          reduxHelper.applyReceiveInfo({
            data: {
              ...prevData,
              byId: state.get('data').get('byId').set(action.payload.id.toString(), program),
            },
            selectedProgram: program,
          })
        );
      }
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            ...prevData,
            byId: state.get('data').get('byId').set(action.payload.id.toString(), program),
          },
        })
      );
    }
    case types.PROGRAM_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PROGRAM_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: action.payload.map(program => new Program(program)),
        })
      );
    case types.PROGRAM_UPDATE_FAILED:
    case types.PROGRAM_SEARCH_FAILED:
    case types.PROGRAM_FETCH_FAILED:
    case types.PROGRAMS_LIST_FAILED:
    case types.PROGRAMS_SECTIONS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.SELECTED_PROGRAM_UPDATE: {
      const {
        payload: { selectedProgram },
      } = action;
      // Save the selected program id to the store
      selectedProgramLocalStorage.storeSelectedProgram(String(selectedProgram.id));
      return state.merge(reduxHelper.applyReceiveInfo({ selectedProgram }));
    }
    default:
      return state;
  }
}
