import types from './types';

const fetchApplication = ({ id }) => ({
  type: types.APPLICATION_FETCH,
  payload: { id },
});

const fetchApplicationSucceeded = ({ id, ...application }) => ({
  type: types.APPLICATION_FETCH_SUCCEEDED,
  payload: { id, ...application },
});

const fetchApplicationFailed = ({ message }) => ({
  type: types.APPLICATION_FETCH_FAILED,
  payload: { message },
});

const listMemberApplication = (payload, programPhase) => ({
  type: types.MEMBER_APPLICATIONS_LIST,
  payload,
  programPhase,
});

const listMemberApplicationPending = () => ({
  type: types.MEMBER_APPLICATIONS_LIST_PENDING,
});

const listMemberApplicationSucceeded = (payload, programPhase) => ({
  type: types.MEMBER_APPLICATIONS_LIST_SUCCEEDED,
  payload,
  programPhase,
});

const listMemberApplicationFailed = ({ message }) => ({
  type: types.MEMBER_APPLICATIONS_LIST_FAILED,
  payload: { message },
});

const updateMemberApplicationBulk = (payload, callback, programPhase) => ({
  type: types.MEMBER_APPLICATION_BULK_UPDATE,
  payload,
  callback,
  programPhase,
});

const updateMemberApplicationBulkSucceeded = (payload, programPhase) => ({
  type: types.MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED,
  payload,
  programPhase,
});

const updateMemberApplicationBulkFailed = ({ message }) => ({
  type: types.MEMBER_APPLICATION_BULK_UPDATE_FAILED,
  payload: { message },
});

const moreActionsApplicationManagementPanel = (payload, callback, programPhase) => ({
  type: types.MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS,
  payload,
  callback,
  programPhase,
});

const moreActionsApplicationManagementPanelSucceeded = (payload, programPhase) => ({
  type: types.MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_SUCCEEDED,
  payload,
  programPhase,
});

const moreActionsApplicationManagementPanelFailed = ({ message }) => ({
  type: types.MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_FAILED,
  payload: { message },
});

const updateMemberApplicationBulkInvite = (payload, callback, programPhase) => ({
  type: types.MEMBER_APPLICATION_BULK_UPDATE_INVITE,
  payload,
  callback,
  programPhase,
});

const updateMemberApplicationBulkSucceededInvite = (payload, programPhase) => ({
  type: types.MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED_INVITE,
  payload,
  programPhase,
});

const updateMemberApplicationBulkFailedInvite = ({ message }) => ({
  type: types.MEMBER_APPLICATION_BULK_UPDATE_FAILED_INVITE,
  payload: { message },
});

const currentApplication = payload => ({
  type: types.CURRENT_APPLICATION,
  payload,
});

const fetchManagementPanelMemberApplication = (payload, callback) => ({
  type: types.MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH,
  payload,
  callback,
});

const fetchManagementPanelMemberApplicationSucceeded = payload => ({
  type: types.MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_SUCCEEDED,
  payload,
});

const fetchManagementPanelMemberApplicationFailed = ({ message }) => ({
  type: types.MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_FAILED,
  payload: { message },
});

export default {
  fetchApplication,
  fetchApplicationSucceeded,
  fetchApplicationFailed,
  listMemberApplication,
  listMemberApplicationPending,
  listMemberApplicationSucceeded,
  listMemberApplicationFailed,
  updateMemberApplicationBulk,
  updateMemberApplicationBulkSucceeded,
  updateMemberApplicationBulkFailed,
  updateMemberApplicationBulkInvite,
  updateMemberApplicationBulkSucceededInvite,
  updateMemberApplicationBulkFailedInvite,
  moreActionsApplicationManagementPanel,
  moreActionsApplicationManagementPanelSucceeded,
  moreActionsApplicationManagementPanelFailed,
  currentApplication,
  fetchManagementPanelMemberApplication,
  fetchManagementPanelMemberApplicationSucceeded,
  fetchManagementPanelMemberApplicationFailed,
};
