import types from './types';

const clearError = () => ({
  type: types.METRIC_CLEAR_ERROR,
});

const createMetric = ({ project, ...metric }) => ({
  type: types.METRIC_CREATE,
  payload: { project, ...metric },
});

const createMetricSucceeded = ({ project, ...metric }) => ({
  type: types.METRIC_CREATE_SUCCEEDED,
  payload: { project, ...metric },
});

const createMetricFailed = ({ message }) => ({
  type: types.METRIC_CREATE_FAILED,
  payload: { message },
});

const deleteMetric = ({ project, id }) => ({
  type: types.METRIC_DELETE,
  payload: { project, id },
});

const deleteMetricSucceeded = ({ project, id }) => ({
  type: types.METRIC_DELETE_SUCCEEDED,
  payload: { project, id },
});

const deleteMetricFailed = ({ message }) => ({
  type: types.METRIC_DELETE_FAILED,
  payload: message,
});

const fetchMetric = ({ project, id }) => ({
  type: types.METRIC_FETCH,
  payload: { project, id },
});

const fetchMetricSucceeded = ({ project, ...metric }) => ({
  type: types.METRIC_FETCH_SUCCEEDED,
  payload: { project, ...metric },
});

const fetchMetricFailed = ({ message }) => ({
  type: types.METRIC_FETCH_FAILED,
  payload: { message },
});

const listMetrics = ({ project }) => ({
  type: types.METRICS_LIST,
  payload: { project },
});

const listMetricsSucceeded = payload => ({
  type: types.METRICS_LIST_SUCCEEDED,
  payload,
});

const listMetricsFailed = ({ message }) => ({
  type: types.METRICS_LIST_FAILED,
  payload: { message },
});

const updateMetric = ({ id, project, ...updatedMetric }) => ({
  type: types.METRIC_UPDATE,
  payload: { id, project, ...updatedMetric },
});

const updateMetricSucceeded = ({ project, ...metric }) => ({
  type: types.METRIC_UPDATE_SUCCEEDED,
  payload: { project, ...metric },
});

const updateMetricFailed = ({ message }) => ({
  type: types.METRIC_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createMetric,
  createMetricFailed,
  createMetricSucceeded,
  deleteMetric,
  deleteMetricFailed,
  deleteMetricSucceeded,
  fetchMetric,
  fetchMetricFailed,
  fetchMetricSucceeded,
  listMetrics,
  listMetricsFailed,
  listMetricsSucceeded,
  updateMetric,
  updateMetricFailed,
  updateMetricSucceeded,
};
