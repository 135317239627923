// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Dependencies
import TableContext from './context';

const TableContextProvider = props => {
  const {
    children,
    backgroundColor,
    elevationBoxShadow,
    fixedLeftBoxShadow,
    hoveredRowBackgroundColor,
  } = props;

  const [hoveredRow, setHoveredRow] = useState(undefined);

  const onRowHoverHandler = rowKey => {
    setHoveredRow(rowKey);
  };

  return (
    <TableContext.Provider
      value={{
        backgroundColor: backgroundColor || '#FFFFFF',
        elevationBoxShadow:
          elevationBoxShadow ||
          '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
        fixedLeftBoxShadow: fixedLeftBoxShadow || '6px 6px 6px -2px rgba(0,0,0,0.15)',
        hoveredRow,
        onRowHover: onRowHoverHandler,
        hoveredRowBackgroundColor: hoveredRowBackgroundColor || '#e6f7ff',
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

TableContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  elevationBoxShadow: PropTypes.string,
  fixedLeftBoxShadow: PropTypes.string,
  hoveredRowBackgroundColor: PropTypes.string,
};

TableContextProvider.defaultProps = {
  backgroundColor: undefined,
  elevationBoxShadow: undefined,
  fixedLeftBoxShadow: undefined,
  hoveredRowBackgroundColor: undefined,
};

export default TableContextProvider;
