// Libraries
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

// Dependencies
import { selectors as userSelectors } from 'store/modules/user';

const PrivateRoutes = () => {
  // TODO: we are already checking this variable outside in src/containers/Routes/index.js, why do we need it here too?
  const isAuthenticated = useSelector(userSelectors.isAuthenticated);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;

{
  /* DOCS
To be used like:

<Route exact path='/' element={<PrivateRoute />}> // this wrapper Route will protect the children Route's
  <Route exact path='/' element={<Home />} /> // Pure Route's to be rendered already protected by the parent Route
</Route>

*/
}
