import React from 'react';

const initialContext = {
  activeNodeRect: null,
  setActiveNodeRect: null,
  deleteActiveNodeRect: null,
};

const CommentsRefContext = React.createContext(initialContext);

export default CommentsRefContext;
