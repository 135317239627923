import api from './api';

const listEmails = query => api.get('/api/management-panel/emails', { params: query });

const listEmailsDetail = query => {
  const { id } = query;
  return api.get(`/api/management-panel/emails/${id}/`, { params: query });
};

export default { listEmails, listEmailsDetail };
