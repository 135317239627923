import { createSelector } from 'reselect';

const getState = state => state.fixedAsset;

const getRawFixedAsset = createSelector([getState], state => state.get('data'));

const getFixedAssetsById = createSelector([getRawFixedAsset], state => state.get('byId'));

const getFixedAssetsByScenario = createSelector([getRawFixedAsset], state =>
  state.get('byScenario')
);

const getTotalByScenario = createSelector([getRawFixedAsset], state =>
  state.get('totalByScenario')
);

const getTotalAcquiredByScenario = createSelector([getRawFixedAsset], state =>
  state.get('totalAquiredByScenario')
);

const getFixedAssets = createSelector([getFixedAssetsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getErrorMessage,
  getFixedAssets,
  getFixedAssetsById,
  getFixedAssetsByScenario,
  getTotalByScenario,
  getTotalAcquiredByScenario,
};
