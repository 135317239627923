import { call, put, all, takeLatest } from 'redux-saga/effects';
import memberApplicationService from 'services/memberApplication';
import types from './types';
import actions from './actions';

export function* listMemberApplication(action) {
  yield put(actions.listMemberApplicationPending());
  try {
    const response = yield call(
      memberApplicationService.listMemberApplication,
      action.payload,
      action.programPhase
    );
    yield put(actions.listMemberApplicationSucceeded(response.data, action.programPhase));
  } catch (e) {
    yield put(actions.listMemberApplicationFailed({ message: e.message }));
  }
}

export function* updateMemberApplicationBulk(action) {
  const { callback, payload, programPhase } = action;
  try {
    const update = yield call(
      memberApplicationService.updateApplicationBulk,
      payload,
      programPhase
    );
    yield put(
      actions.updateMemberApplicationBulkSucceeded(
        { ...update.data, ids: payload.ids },
        programPhase
      )
    );
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.updateMemberApplicationBulkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* moreActionsApplicationManagementPanel(action) {
  const { callback, payload, programPhase } = action;
  try {
    yield call(memberApplicationService.moreActionsApplicationManagementPanel, payload);
    yield put(
      actions.moreActionsApplicationManagementPanelSucceeded(
        { id: payload.id, mentor: payload.mentor },
        programPhase
      )
    );
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.moreActionsApplicationManagementPanelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

// TODO: This function is duplicated from updateMemberApplicationBulk
export function* updateMemberApplicationBulkInvites(action) {
  const { callback, payload, programPhase } = action;
  try {
    const update = yield call(
      memberApplicationService.updateApplicationBulk,
      payload,
      programPhase
    );
    yield put(
      actions.updateMemberApplicationBulkSucceededInvite(
        { ...update.data, ids: payload.ids },
        programPhase
      )
    );
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.updateMemberApplicationBulkFailedInvite({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchApplication(action) {
  const { id } = action.payload;
  try {
    const application = yield call(memberApplicationService.fetchApplication, {
      id,
    });
    yield put(actions.fetchApplicationSucceeded(application.data));
  } catch (e) {
    yield put(actions.fetchApplicationFailed({ message: e.message }));
  }
}

export function* currentApplication(action) {
  yield put(action.payload);
}

export function* fetchManagementPanelMemberApplication(action) {
  const { id, programPhase, program } = action.payload;
  try {
    const application = yield call(memberApplicationService.fetchManagementPanelMemberApplication, {
      id,
      programPhase,
      program,
    });
    yield put(
      actions.fetchManagementPanelMemberApplicationSucceeded({
        data: application.data,
        id,
        programPhase,
        program,
      })
    );
    if (action.callback) yield call(action.callback);
  } catch (e) {
    yield put(
      actions.fetchManagementPanelMemberApplicationFailed({
        message: e.message,
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.APPLICATION_FETCH, fetchApplication),
    takeLatest(types.MEMBER_APPLICATIONS_LIST, listMemberApplication),
    takeLatest(types.MEMBER_APPLICATION_BULK_UPDATE_INVITE, updateMemberApplicationBulkInvites),
    takeLatest(types.MEMBER_APPLICATION_BULK_UPDATE, updateMemberApplicationBulk),
    takeLatest(
      types.MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS,
      moreActionsApplicationManagementPanel
    ),
    takeLatest(
      types.MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH,
      fetchManagementPanelMemberApplication
    ),
    currentApplication,
  ]);
}

export default authSaga;
