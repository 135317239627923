import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: {
      stats: {
        application: {},
        approved: {},
        profile: {},
        program_goals: {},
        matching: {},
        total_e_applications: 0,
        total_m_applications: 0,
      },
    },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MEMBER_PROGRAM_STATS_LIST:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.MEMBER_PROGRAM_STATS_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            stats: {
              application: action.payload.application,
              approved: action.payload.approved,
              profile: action.payload.profile,
              program_goals: action.payload.program_goals,
              matching: action.payload.matching,
              total_e_applications: action.payload.total_e_applications,
            },
          },
        })
      );
    case types.MEMBER_PROGRAM_STATS_LIST_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    case types.MENTOR_PROGRAM_STATS_LIST:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.MENTOR_PROGRAM_STATS_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            stats: {
              application: action.payload.application,
              approved: action.payload.approved,
              program_goals: action.payload.program_goals,
              matching: action.payload.matching,
              total_m_applications: action.payload.total_m_applications,
              profile: {},
            },
          },
        })
      );
    case types.MENTOR_PROGRAM_STATS_LIST_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
