import types from './types';

const clearError = () => ({
  type: types.CHAIN_CLEAR_ERROR,
});

const fetchChain = ({ productId }) => ({
  type: types.CHAIN_FETCH,
  payload: { productId },
});

const fetchChainSucceeded = payload => ({
  type: types.CHAIN_FETCH_SUCCEEDED,
  payload,
});

const fetchChainFailed = ({ message }) => ({
  type: types.CHAIN_FETCH_FAILED,
  payload: { message },
});

const listChains = ({ productId }) => ({
  type: types.CHAINS_LIST,
  payload: { productId },
});

const listChainsSucceeded = payload => ({
  type: types.CHAINS_LIST_SUCCEEDED,
  payload,
});

const listChainsFailed = ({ message }) => ({
  type: types.CHAINS_LIST_FAILED,
  payload: { message },
});

const createChain = ({ product, ...chain }) => ({
  type: types.CHAIN_CREATE,
  payload: { product, ...chain },
});

const createChainSucceeded = ({ product, ...chain }) => ({
  type: types.CHAIN_CREATE_SUCCEEDED,
  payload: { product, ...chain },
});

const createChainFailed = ({ message }) => ({
  type: types.CHAIN_CREATE_FAILED,
  payload: { message },
});

const updateChain = ({ product, id, ...chain }) => ({
  type: types.CHAIN_UPDATE,
  payload: { product, id, ...chain },
});

const updateChainSucceeded = ({ product, ...chain }) => ({
  type: types.CHAIN_UPDATE_SUCCEEDED,
  payload: { product, ...chain },
});

const updateChainFailed = ({ message }) => ({
  type: types.CHAIN_UPDATE_FAILED,
  payload: { message },
});

const deleteChain = ({ id, product }) => ({
  type: types.CHAIN_DELETE,
  payload: { id, product },
});

const deleteChainSucceeded = payload => ({
  type: types.CHAIN_DELETE_SUCCEEDED,
  payload,
});

const deleteChainFailed = ({ message }) => ({
  type: types.CHAIN_DELETE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createChain,
  createChainFailed,
  createChainSucceeded,
  fetchChain,
  fetchChainFailed,
  fetchChainSucceeded,
  listChains,
  listChainsFailed,
  listChainsSucceeded,
  updateChain,
  updateChainFailed,
  updateChainSucceeded,
  deleteChain,
  deleteChainSucceeded,
  deleteChainFailed,
};
