import { call, put, all, takeLatest } from 'redux-saga/effects';

import serviceMember from 'services/member';
import actions from './actions';
import types from './types';

export function* memberFetch() {
  try {
    const member = yield call(serviceMember.getMember);
    yield put(actions.memberFetch({ userData: member.data }));
  } catch (e) {
    yield put(actions.memberFetchFailed({ message: e.message }));
  }
}

export function* memberEdit(action) {
  const {
    callback,
    payload: { id, ...memberData },
  } = action;
  try {
    const response = yield call(serviceMember.editMember, {
      id,
      ...memberData,
    });
    yield put(actions.memberEditSucceeded(response.data));
    if (callback) callback();
  } catch (e) {
    if (callback) {
      yield call(callback, e.response);
    }
    yield put(
      actions.memberEditFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* memberSaga() {
  yield all([
    takeLatest(types.MEMBER_FETCH, memberFetch),
    takeLatest(types.MEMBER_EDIT, memberEdit),
  ]);
}

export default memberSaga;
