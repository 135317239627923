// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Components
import { Select } from 'antd';
import Icon from '@ant-design/icons';
import { Span } from 'components/fontSystem';

// Dependencies
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import TranslationsContext from 'providers/translationsProvider';
import { potentialMatchOptions as options } from 'models/Matching/Interestship';

// Actions and selectors
import { actions as interestshipActions } from 'store/modules/Matching/interestship';
import bfbTheme from 'styles/b4b-theme';

function PotentialMatch(props) {
  const { application, invite, currentValue, onInvitesUpdate, agreementSent, isVacancyFull } =
    props;
  const { t } = useContext(TranslationsContext);
  const dispatch = useDispatch();
  const { updateInterestship } = interestshipActions;

  const handleSelection = value => {
    if (!application) return;

    dispatch(
      updateInterestship(
        {
          id: invite.id,
          program: invite.program,
          potential_match_level: value,
        },
        () => onInvitesUpdate(application.id)
      )
    );
  };

  // checking currentValue.value type, when value is 0 it was returning FALSE
  const defaultValue =
    currentValue &&
    currentValue.value !== undefined &&
    currentValue.value !== null &&
    currentValue.value;

  return (
    <StyledSelect
      colorText={currentValue && currentValue.color}
      onChange={handleSelection}
      placeholder={t('Is this mentor a potential match?')}
      value={defaultValue}
      currentValue={currentValue}
      disabled={agreementSent}
    >
      {options.map(tag => (
        <Select.Option
          value={tag.value}
          label={tag.text}
          disabled={tag.value === 0 && isVacancyFull}
        >
          <Icon
            component={tag.icon}
            theme="filled"
            style={{ color: tag.iconColor, fontSize: 20 }}
          />
          <Span style={{ marginLeft: '6px' }}>{tag.text}</Span>
        </Select.Option>
      ))}
    </StyledSelect>
  );
}

PotentialMatch.propTypes = {
  currentValue: PropTypes.instanceOf(Object).isRequired,
  invite: PropTypes.instanceOf(Object).isRequired,
  application: PropTypes.instanceOf(Object).isRequired,
  isVacancyFull: PropTypes.bool.isRequired,
  agreementSent: PropTypes.bool.isRequired,
  onInvitesUpdate: PropTypes.func.isRequired,
};

const StyledSelect = styled(Select)`
  width: ${({ currentValue }) => (currentValue === undefined ? '280px' : '180px')};
  height: 35px;

  .ant-select-dropdown-menu-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-selected-value > span {
    font-weight: 600;
  }

  .ant-select-selection {
    border: 1px
      ${({ colorText, currentValue }) =>
        `solid ${currentValue === undefined ? bfbTheme.mainColor : colorText}`};
    background: ${({ currentValue }) =>
      currentValue === undefined ? bfbTheme.mainColor : '#FFFFFF'};
    padding: 2px;
  }

  &&& {
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: 0 0 0 2px ${({ colorBg }) => colorBg};
    }

    .ant-select-selection-selected-value {
      color: ${({ colorText, currentValue }) => (currentValue === undefined ? 'white' : colorText)};
      display: flex !important;
      align-content: center !important;
      align-items: center;
    }

    .ant-select-arrow {
      color: ${({ colorText, currentValue }) => (currentValue === undefined ? 'white' : colorText)};
    }

    .ant-select-selection__placeholder {
      color: white;
      font-weight: 600;
    }
  }
`;

export default PotentialMatch;
