class ContentType {
  /*
    This class should be updated after we add a new model in the backend
    You can run this script in your Docker container:
    $ python manage.py print_content_types
  */
  static BOOKMARK = 'menu.bookmark';
  static DASHBOARDPREFERENCES = 'dashboard.dashboardpreferences';
  static LOGENTRY = 'admin.logentry';
  static GROUP = 'auth.group';
  static PERMISSION = 'auth.permission';
  static USER = 'auth.user';
  static CONTENTTYPE = 'contenttypes.contenttype';
  static SESSION = 'sessions.session';
  static TOKEN = 'authtoken.token';
  static EXPENSETYPE = 'api.expensetype';
  static BENEFIT = 'api.benefit';
  static NOTIFICATION = 'api.notification';
  static INVESTOR = 'api.investor';
  static CUSTOMIZATION = 'api.customization';
  static MEMBER = 'api.member';
  static RESOURCE = 'api.resource';
  static SKILL = 'api.skill';
  static EXPERIENCE = 'api.experience';
  static GOAL = 'api.goal';
  static PRODUCT = 'api.product';
  static ORGANIZATION = 'api.organization';
  static DEMOGRAPHIC = 'api.demographic';
  static GEOGRAPHICPREFERENCE = 'api.geographicpreference';
  static QUARTER = 'api.quarter';
  static COMMENT = 'api.comment';
  static SEGMENT = 'api.segment';
  static INDUSTRY = 'api.industry';
  static OBJECTIVETYPE = 'api.objectivetype';
  static CHANNEL = 'api.channel';
  static MENTOR = 'api.mentor';
  static STAGE = 'api.stage';
  static EDUCATIONEXPERIENCE = 'api.educationexperience';
  static PROGRAM = 'api.program';
  static ADMINSHIP = 'api.adminship';
  static FUNDING = 'api.funding';
  static OUTCOME = 'api.outcome';
  static COMPETITOR = 'api.competitor';
  static RISK = 'api.risk';
  static ACTION = 'api.action';
  static FIXEDASSET = 'api.fixedasset';
  static PARTNER = 'api.partner';
  static PAYROLL = 'api.payroll';
  static INTEREST = 'api.interest';
  static SALE = 'api.sale';
  static MILESTONE = 'api.milestone';
  static OPERATINGCAPITAL = 'api.operatingcapital';
  static PROJECT = 'api.project';
  static INVITEMEMBER = 'api.invitemember';
  static PAIN = 'api.pain';
  static MENTORSHIP = 'api.mentorship';
  static EXPENSE = 'api.expense';
  static POSITIONING = 'api.positioning';
  static CULTURALEXPERIENCE = 'api.culturalexperience';
  static ACTIVITY = 'api.activity';
  static CUSTOMERMODEL = 'api.customermodel';
  static EXPERTISE = 'api.expertise';
  static WORKEXPERIENCE = 'api.workexperience';
  static RETENTION = 'api.retention';
  static MEMBERSHIP = 'api.membership';
  static ATTRIBUTE = 'api.attribute';
  static LANGUAGE = 'api.language';
  static PRICINGMODEL = 'api.pricingmodel';
  static BUSINESSMODEL = 'api.businessmodel';
  static ATTRACTION = 'api.attraction';
  static ACTIONOBJECT = 'api.actionobject';
  static OBJECTIVE = 'api.objective';
  static INDICATOR = 'api.indicator';
  static ACHIEVEMENT = 'api.achievement';
  static FEATURE = 'api.feature';
  static YEAR = 'api.year';
  static RISKCATEGORY = 'api.riskcategory';
  static INVITEMENTOR = 'api.invitementor';
  static DASHBOARDSTATSCRITERIA = 'admin_tools_stats.dashboardstatscriteria';
  static DASHBOARDSTATS = 'admin_tools_stats.dashboardstats';
  static ACCESSTOKEN = 'oauth2_provider.accesstoken';
  static GRANT = 'oauth2_provider.grant';
  static REFRESHTOKEN = 'oauth2_provider.refreshtoken';
  static APPLICATION = 'oauth2_provider.application';
  static KEYFINDING = 'api.keyfinding';
  static HYPOTHESIS = 'api.hypothesis';
  static HYPOTHESISCATEGORY = 'api.hypothesiscategory';
  static EXPERIMENT = 'api.experiment';
  static LEAD = 'api.lead';
  static INTERESTSHIP = 'api.interestship';
  static PROFILEPICTURE = 'api.profilepicture';
  static PROJECTPICTURE = 'api.projectpicture';
  static LINK = 'bfb_profile.link';
  static EDUCATIONEXPERIENCE = 'bfb_profile.educationexperience';
  static WORKEXPERIENCE = 'bfb_profile.workexperience';
  static AREAEXPERTISE = 'bfb_application.areaexpertise';
  static AREAINNOVATION = 'bfb_application.areainnovation';
  static SECTOR = 'bfb_application.sector';
  static USERLINK = 'bfb_media.userlink';
  static PROJECTLINK = 'bfb_media.projectlink';
  static MENTORAPPLICATION = 'bfb_application.mentorapplication';
  static MEMBERAPPLICATION = 'bfb_application.memberapplication';
  static TESTMODEL = 'db.testmodel';
  static PROGRAMTOOL = 'bfb_tools.programtool';
  static PROJECTPROGRAMTOOL = 'bfb_tools.projectprogramtool';
  static TOOL = 'bfb_tools.tool';
  static REASON = 'bfb_value_proposition.reason';
  static PROBLEM = 'bfb_value_proposition.problem';
  static PROJECTPROGRAMTOOLSTEP = 'bfb_tools.projectprogramtoolstep';
  static STEP = 'bfb_tools.step';
  static TASKSTATE = 'celery_monitor.taskstate';
  static WORKERSTATE = 'celery_monitor.workerstate';
  static CHAIN = 'api.chain';
  static PRODUCTBUSINESSMODEL = 'bfb_business_model.productbusinessmodel';
  static BUSINESSMODEL = 'bfb_business_model.businessmodel';
  static MARKETINGACTIVITY = 'bfb_business_model.marketingactivity';
  static PRICINGMODEL = 'bfb_pricing_model.pricingmodel';
  static PRODUCTPRICINGMODEL = 'bfb_pricing_model.productpricingmodel';
  static RETENTIONACTIVITY = 'bfb_pricing_model.retentionactivity';
  static MONTHLYPAYROLL = 'bfb_financial_projections.monthlypayroll';
  static MONTHLYEXPENSE = 'bfb_financial_projections.monthlyexpense';
  static SCENARIO = 'bfb_financial_projections.scenario';
  static INVESTMENT = 'bfb_financial_projections.investment';
  static FIXEDASSET = 'bfb_financial_projections.fixedasset';
  static PAYROLL = 'bfb_financial_projections.payroll';
  static FUNDING = 'bfb_financial_projections.funding';
  static EXPENSE = 'bfb_financial_projections.expense';
  static OPERATINGCAPITAL = 'bfb_financial_projections.operatingcapital';
  static MONTHLYUNITSSOLD = 'bfb_financial_projections.monthlyunitssold';
  static MONTHLYPRICE = 'bfb_financial_projections.monthlyprice';
  static FINANCIALPRODUCT = 'bfb_financial_projections.financialproduct';
  static MONTHLYCOGS = 'bfb_financial_projections.monthlycogs';
  static MOONSHOTGOAL = 'bfb_growth_plan.moonshotgoal';
  static BENEFICIARY = 'bfb_impact_plan.beneficiary';
  static RESULT = 'bfb_impact_plan.result';
  static ROLE = 'bfb_impact_plan.role';
  static RULE = 'bfb_impact_plan.rule';
  static RELATIONSHIP = 'bfb_impact_plan.relationship';
  static SDGTARGET = 'bfb_impact_plan.sdgtarget';
  static SYSTEM = 'bfb_impact_plan.system';
  static PROJECTSDG = 'bfb_impact_plan.projectsdg';
  static SDG = 'bfb_impact_plan.sdg';
  static RESOURCE = 'bfb_impact_plan.resource';
  static BENEFICIARYTYPE = 'bfb_impact_plan.beneficiarytype';
  static INDICATOR = 'bfb_impact_plan.indicator';
  static OUTCOME = 'bfb_impact_plan.outcome';
  static INITIATIVE = 'bfb_growth_plan.initiative';
  static BEHAVIOR = 'bfb_growth_plan.behavior';
  static MILESTONE = 'bfb_growth_plan.milestone';
  static OBJECTIVE = 'bfb_growth_plan.objective';
  static METRIC = 'bfb_growth_plan.metric';
  static KEYRESULT = 'bfb_growth_plan.keyresult';
  static VALUE = 'bfb_growth_plan.value';
  static GROWTHRISK = 'bfb_growth_plan.growthrisk';
  static DEMOPROGRAM = 'bfb_demo_program.demoprogram';
  static MENTORSHIPRANKING = 'bfb_application.mentorshipranking';
  static MEMBERSHIPRANKING = 'bfb_application.membershipranking';
  static SUBSCRIPTION = 'bfb_subscription.subscription';
  static MEMBERSUBSCRIPTION = 'bfb_subscription.membersubscription';
  static TASKRESULT = 'django_celery_results.taskresult';
  static DEEPDIVEMATERIAL = 'bfb_deep_dive.deepdivematerial';
  static LINK = 'bfb_deep_dive.link';
  static DEEPDIVEPROGRAMTOOL = 'bfb_deep_dive.deepdiveprogramtool';
  static NOTIFICATION = 'bfb_notification.notification';
  static ACTIONOBJECT = 'bfb_notification.actionobject';
  static PROJECT_EXPERTISE_NEEDED = 'api.project_expertise_needed';
  static CHAIN_SEGMENTS = 'api.chain_segments';
  static PRODUCTPRICINGMODEL_SEGMENTS = 'bfb_pricing_model.productpricingmodel_segments';
  static PRODUCTBUSINESSMODEL_SEGMENTS = 'bfb_business_model.productbusinessmodel_segments';
  static BENEFICIARYTYPE_SEGMENTS_BENEFICIARIES =
    'bfb_impact_plan.beneficiarytype_segments_beneficiaries';
  static INITIATIVE_ROLES = 'bfb_growth_plan.initiative_roles';
  static PROJECTSDG_SDG_TARGETS = 'bfb_impact_plan.projectsdg_sdg_targets';
  static SURVEY = 'bfb_survey.survey';
  static QUESTION = 'bfb_survey.question';
  static PROGRAMSURVEY = 'bfb_survey.programsurvey';
  static SURVEYRESPONSE = 'bfb_survey.surveyresponse';
  static CHATROOM = 'bfb_chat.chatroom';
  static PROJECTIMPACT = 'bfb_impact.projectimpact';
  static PROJECTIMPACTTIME = 'bfb_impact.projectimpacttime';
  static PROJECTIMPACTTOOLS = 'bfb_impact.projectimpacttools';
  static PROJECTIMPACTPULSECHECKS = 'bfb_impact.projectimpactpulsechecks';
  static SURVEYRESPONSEJSONID1 = 'bfb_survey.surveyresponsejsonid1';
  static PROGRAMSURVEYREMINDER = 'bfb_survey.programsurveyreminder';
  static CERTIFICATE = 'bfb_certificates.certificate';
  static PROJECTIMPACTPULSECHECKSMENTOR = 'bfb_impact.projectimpactpulsechecksmentor';
  static PROJECTIMPACTTOOLSMENTOR = 'bfb_impact.projectimpacttoolsmentor';
  static EMAILSENT = 'bfb_email.emailsent';
  static MENTORIMPACT = 'bfb_impact.mentorimpact';
  static CATEGORY = 'bfb_forum.category';
  static POST = 'bfb_forum.post';
  static TOPIC = 'bfb_forum.topic';
  static TOPICTAG = 'bfb_forum.topictag';
  static TOPICFLAG = 'bfb_forum.topicflag';
  static TOPICFILE = 'bfb_forum.topicfile';
  static POSTFLAG = 'bfb_forum.postflag';
  static POSTFILE = 'bfb_forum.postfile';
  static FORUM = 'bfb_forum.forum';
  static EXECUTIVESUMMARY = 'bfb_executive_summary.executivesummary';
  static EXECUTIVESUMMARYMEMBER = 'bfb_executive_summary.executivesummarymember';
  static CHORDCOUNTER = 'django_celery_results.chordcounter';
  static GROUPRESULT = 'django_celery_results.groupresult';
  static SURVEYRESPONSEEOIENT = 'bfb_survey.surveyresponseeoient';
  static SURVEYRESPONSEEOIMENT = 'bfb_survey.surveyresponseeoiment';
  static SURVEYRESPONSEEOTENT = 'bfb_survey.surveyresponseeotent';
  static SURVEYRESPONSEEOTMENT = 'bfb_survey.surveyresponseeotment';
  static SURVEYRESPONSEPCENT = 'bfb_survey.surveyresponsepcent';
  static SURVEYRESPONSEPCMENT = 'bfb_survey.surveyresponsepcment';
  static SURVEYRESPONSEPPENT = 'bfb_survey.surveyresponseppent';
  static SURVEYRESPONSEPTIENT = 'bfb_survey.surveyresponseptient';
  static PILLCATEGORY = 'bfb_pills.pillcategory';
  static PILL = 'bfb_pills.pill';
  static PILLLINK = 'bfb_pills.pilllink';
  static PROJECTEXAMPLE = 'bfb_examples.projectexample';
  static PROGRAMGROUP = 'api.programgroup';
  static CHATROOM_USERS = 'bfb_chat.chatroom_users';
  static TERM = 'bfb_glossary.term';
  static CRONTABSCHEDULE = 'django_celery_beat.crontabschedule';
  static INTERVALSCHEDULE = 'django_celery_beat.intervalschedule';
  static PERIODICTASK = 'django_celery_beat.periodictask';
  static PERIODICTASKS = 'django_celery_beat.periodictasks';
  static SOLARSCHEDULE = 'django_celery_beat.solarschedule';
  static CLOCKEDSCHEDULE = 'django_celery_beat.clockedschedule';
  static IDTOKEN = 'oauth2_provider.idtoken';
  static TOKENPROXY = 'authtoken.tokenproxy';
  static THREAD = 'bfb_parrobot_gpt.thread';
  static MESSAGE = 'bfb_parrobot_gpt.message';
  static FINANCIALPROJECTIONS = 'api.financialprojections';
  static UPLOAD = 'api.upload';
  static FINANCIALPROJECTION = 'api.financialprojection';
  static FINANCIALPROJECTIONSCENARIO = 'api.financialprojectionscenario';
  static FINANCIALFORECAST = 'bfb_financial_forecast.financialforecast';
  static FINANCIALFORECASTSCENARIO = 'bfb_financial_forecast.financialforecastscenario';
}

export default ContentType;
