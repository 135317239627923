import types from './types';

const clearError = () => ({
  type: types.PRODUCT_PRICING_MODEL_CLEAR_ERROR,
});

const listPricingModels = ({ product }) => ({
  type: types.PRICING_MODELS_LIST,
  payload: { product },
});

const listPricingModelsSucceeded = payload => ({
  type: types.PRICING_MODELS_LIST_SUCCEEDED,
  payload,
});

const listPricingModelsFailed = ({ message }) => ({
  type: types.PRICING_MODELS_LIST_FAILED,
  payload: { message },
});

const listProductPricingModels = ({ product }) => ({
  type: types.PRODUCT_PRICING_MODELS_LIST,
  payload: { product },
});

const listProductPricingModelsSucceeded = payload => ({
  type: types.PRODUCT_PRICING_MODELS_LIST_SUCCEEDED,
  payload,
});

const listProductPricingModelsFailed = ({ message }) => ({
  type: types.PRODUCT_PRICING_MODELS_LIST_FAILED,
  payload: { message },
});

const fetchProductPricingModel = ({ product, id }) => ({
  type: types.PRODUCT_PRICING_MODEL_FETCH,
  payload: { product, id },
});

const fetchProductPricingModelSucceeded = ({ ...pricingModel }) => ({
  type: types.PRODUCT_PRICING_MODEL_FETCH_SUCCEEDED,
  payload: { ...pricingModel },
});

const fetchProductPricingModelFailed = ({ message }) => ({
  type: types.PRODUCT_PRICING_MODEL_FETCH_FAILED,
  payload: { message },
});

const createProductPricingModel = ({ product, ...pricingModel }, callback) => ({
  type: types.PRODUCT_PRICING_MODEL_CREATE,
  payload: { product, ...pricingModel },
  callback,
});

const createProductPricingModelSucceeded = ({ product, ...pricingModel }) => ({
  type: types.PRODUCT_PRICING_MODEL_CREATE_SUCCEEDED,
  payload: { product, ...pricingModel },
});

const createProductPricingModelFailed = ({ message }) => ({
  type: types.PRODUCT_PRICING_MODEL_CREATE_FAILED,
  payload: { message },
});

const updateProductPricingModel = ({ product, ...pricingModel }) => ({
  type: types.PRODUCT_PRICING_MODEL_UPDATE,
  payload: { product, ...pricingModel },
});

const updateProductPricingModelSucceeded = ({ ...pricingModel }) => ({
  type: types.PRODUCT_PRICING_MODEL_UPDATE_SUCCEEDED,
  payload: { ...pricingModel },
});

const updateProductPricingModelFailed = ({ message }) => ({
  type: types.PRODUCT_PRICING_MODEL_UPDATE_FAILED,
  payload: { message },
});

const deleteProductPricingModel = ({ product, ...pricingModel }) => ({
  type: types.PRODUCT_PRICING_MODEL_DELETE,
  payload: { product, ...pricingModel },
});

const deleteProductPricingModelSucceeded = ({ ...pricingModel }) => ({
  type: types.PRODUCT_PRICING_MODEL_DELETE_SUCCEEDED,
  payload: { ...pricingModel },
});

const deleteProductPricingModelFailed = ({ message }) => ({
  type: types.PRODUCT_PRICING_MODEL_DELETE_FAILED,
  payload: { message },
});

export default {
  clearError,
  listPricingModels,
  listPricingModelsFailed,
  listPricingModelsSucceeded,
  createProductPricingModel,
  createProductPricingModelFailed,
  createProductPricingModelSucceeded,
  fetchProductPricingModel,
  fetchProductPricingModelFailed,
  fetchProductPricingModelSucceeded,
  listProductPricingModels,
  listProductPricingModelsSucceeded,
  listProductPricingModelsFailed,
  updateProductPricingModel,
  updateProductPricingModelFailed,
  updateProductPricingModelSucceeded,
  deleteProductPricingModel,
  deleteProductPricingModelSucceeded,
  deleteProductPricingModelFailed,
};
