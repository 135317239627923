import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Outcome from 'models/ValueProposition/Outcome';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, bySegment: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.OUTCOMES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, outcome) =>
                prevData.set(outcome.id.toString(), new Outcome(outcome)).sort(byId),
              state.get('data').get('byId')
            ),
            bySegment: state
              .get('data')
              .get('bySegment')
              .set(
                action.payload.segment.toString(),
                fromJS(action.payload.results.map(outcome => new Outcome(outcome)).sort(byId))
              ),
          },
        })
      );
    case types.OUTCOME_FETCH_SUCCEEDED:
    case types.OUTCOME_CREATE_SUCCEEDED:
    case types.OUTCOME_UPDATE_SUCCEEDED:
      return (() => {
        const segmentId = action.payload.segment.toString();
        const segmentOutcomes = state
          .get('data')
          .get('bySegment')
          .get(segmentId, new List())
          .filter(segment => segment.id !== action.payload.id)
          .push(new Outcome(action.payload))
          .sort(byId);

        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Outcome(action.payload)),
              bySegment: state.get('data').get('bySegment').set(segmentId, segmentOutcomes),
            },
          })
        );
      })();
    case types.OUTCOME_FETCH:
    case types.OUTCOME_CREATE:
    case types.OUTCOME_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.OUTCOME_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            bySegment: state
              .get('data')
              .get('bySegment')
              .set(
                action.payload.segment.toString(),
                state
                  .get('data')
                  .get('bySegment')
                  .get(action.payload.segment.toString(), [])
                  .filter(outcome => outcome.id !== action.payload.id)
              ),
          },
        })
      );
    case types.OUTCOME_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.OUTCOME_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.OUTCOME_FETCH_FAILED:
    case types.OUTCOMES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
