import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/elements/Button';
import { Link } from 'components/elements';
import { Row, Col } from 'antd';
import { H1, semiBoldStyle } from 'components/fontSystem';

const PanelMessage = props => {
  const { btnEffect, btnValue, className, disabled, icon, link, message, onClick, title } = props;
  return (
    <Row className={className} type="flex" justify="space-between" align="middle">
      <Col className="text-container">
        {title && <H1 color="white">{title}</H1>}
        <p>{message}</p>
      </Col>
      <Col xs={24} sm={0} className="smallscreen-margin" />
      <Col className="button-container">
        {link ? (
          <Link to={link}>
            <Button
              disabled={disabled}
              effect={btnEffect}
              icon={icon}
              onClick={onClick}
              $semibold
              size="default"
              type="white"
            >
              {btnValue}
            </Button>
          </Link>
        ) : (
          <Button
            disabled={disabled}
            effect={btnEffect}
            icon={icon}
            onClick={onClick}
            $semibold
            size="default"
            type="white"
          >
            {btnValue}
          </Button>
        )}
      </Col>
    </Row>
  );
};

const StyledPanelMessage = styled(PanelMessage)`
  display: flex;
  flex-direction: column;

  .text-container {
    flex: 1;
  }
  p {
    ${({ semiBoldMessage }) => semiBoldMessage && semiBoldStyle}
    color: white;
    display: block;
    margin-bottom: 15px;
  }
  .button-container {
    text-align: right;
  }

  @media screen and (min-width: ${({ theme }) => theme.screenMd}) {
    flex-direction: row;
    p {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }
`;

PanelMessage.propTypes = {
  btnEffect: PropTypes.string,
  btnValue: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  link: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

PanelMessage.defaultProps = {
  btnEffect: undefined,
  disabled: false,
  icon: '',
  link: '',
  onClick: null,
  title: '',
};

export default StyledPanelMessage;
