import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import TranslationsContext from 'providers/translationsProvider';

class ToolContainerComponent extends Component {
  static propTypes = {
    alignItems: PropTypes.bool,
    bold: PropTypes.bool,
    children: PropTypes.node.isRequired,
    colorIndex: PropTypes.number,
    empty: PropTypes.bool,
    filled: PropTypes.bool,
    noBorder: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    selectedBackground: PropTypes.bool,
    useTheme: PropTypes.bool,
    content: PropTypes.node,
    textAreaMinHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    softFilled: PropTypes.bool,
    softFill: PropTypes.number,
    blinking: PropTypes.bool,
    defaultContainerColor: PropTypes.bool,
  };

  static defaultProps = {
    alignItems: false,
    bold: false,
    colorIndex: 0,
    empty: false,
    filled: false,
    noBorder: false,
    selected: false,
    disabled: false,
    selectedBackground: false,
    useTheme: false,
    content: null,
    textAreaMinHeight: undefined,
    softFilled: false,
    softFill: undefined,
    blinking: false,
    defaultContainerColor: false,
  };

  static contextType = TranslationsContext;

  render() {
    const {
      bold,
      children,
      colorIndex,
      alignItems,
      empty,
      filled,
      noBorder,
      selected,
      disabled,
      selectedBackground,
      useTheme,
      content,
      textAreaMinHeight,
      softFilled,
      softFill,
      blinking,
      defaultContainerColor,
      ...rest
    } = this.props;
    const isContent = Boolean(content);
    return (
      <div {...rest}>
        {isContent ? (
          <React.Fragment>
            <Container>{children}</Container>
            <Content>{content}</Content>
          </React.Fragment>
        ) : (
          children
        )}
      </div>
    );
  }
}

const containerColor = ({ colorIndex, theme, useTheme }) => {
  if (useTheme) return theme.primaryColor;
  return colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#D9D9D9';
};

const getBorderWidth = props => {
  if (props.noBorder) return 0;
  if (props.selected) return '3px';
  if (props.bold) return '2px';
  return '1px';
};

const getBackground = props => {
  const { filled, selected, selectedBackground } = props;
  if (filled) {
    return containerColor(props);
  }
  if (selectedBackground && selected) {
    return `${containerColor(props)}19`; // Fourth HEX position is used for opacity
  }
  return 'transparent';
};

const ToolContainer = styled(ToolContainerComponent)`
  align-items: ${props => (props.alignItems ? 'center' : undefined)};
  background-color: ${getBackground};
  border-color: ${containerColor};
  border-radius: 4px;
  border-style: ${props => (props.empty ? 'dashed' : 'solid')};
  border-width: ${getBorderWidth};
  display: flex;
  flex-direction: ${props => (props.content ? 'column' : 'row')};
  padding: ${props => (props.filled ? '6px 8px' : '3px')};
  transition: all ease 300ms;
  -webkit-print-color-adjust: exact;
  ${props =>
    props.disabled &&
    css`
      &&& {
        opacity: 0.4;
        cursor: not-allowed;
        * {
          cursor: not-allowed;
        }
      }
    `}
  ${({ softFilled, softFill }) =>
    (softFilled || softFill) &&
    css`
      border-radius: 4px;
      border-style: solid;
      border-width: ${getBorderWidth};
      border-color: ${({ noBorder }) => (noBorder ? 'transparent' : `${containerColor}`)};
      background-color: ${containerColor}1A;
      ${({ defaultContainerColor }) =>
        defaultContainerColor &&
        css`
          .default {
            color: ${containerColor} !important;
          }
        `};
    `}
`;

const Container = styled.div`
  padding: 0 !important;
  display: flex;
  align-items: center;
  flex-direction: center;
  width: 100%;
`;

const Content = styled.div`
  padding: 10px 0 0px !important;
  width: 100%;
`;

export default ToolContainer;
