import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/PillsCategories';
import pillsCategoriesService from 'services/pillsCategories';

export function* fetchPillsCategories(action) {
  try {
    const response = yield call(pillsCategoriesService.listPillsCategories, action.payload);
    yield put(actions.fetchPillsCategoriesSucceeded(response.data));
  } catch (e) {
    yield put(actions.fetchPillsCategoriesFailed({ message: e.message }));
  }
}

function* pillsCategoriesSaga() {
  yield all([takeLatest(types.PILLS_CATEGORIES_FETCH, fetchPillsCategories)]);
}

export default pillsCategoriesSaga;
