import types from './types';

const clearError = () => ({
  type: types.ORGANIZATIONAL_ROLE_CLEAR_ERROR,
});

const createOrganizationalRole = ({ project, ...organizationalRole }, callback) => ({
  type: types.ORGANIZATIONAL_ROLE_CREATE,
  payload: { project, ...organizationalRole },
  callback,
});

const createOrganizationalRoleSucceeded = ({ ...organizationalRole }) => ({
  type: types.ORGANIZATIONAL_ROLE_CREATE_SUCCEEDED,
  payload: { ...organizationalRole },
});

const createOrganizationalRoleFailed = ({ message }) => ({
  type: types.ORGANIZATIONAL_ROLE_CREATE_FAILED,
  payload: { message },
});

const deleteOrganizationalRole = ({ project, organizationalRoleId }, callback) => ({
  type: types.ORGANIZATIONAL_ROLE_DELETE,
  payload: { project, organizationalRoleId },
  callback,
});

const deleteOrganizationalRolesSucceeded = payload => ({
  type: types.ORGANIZATIONAL_ROLE_DELETE_SUCCEEDED,
  payload,
});

const deleteOrganizationalRoleFailed = ({ message }) => ({
  type: types.ORGANIZATIONAL_ROLE_DELETE_FAILED,
  payload: { message },
});

const fetchOrganizationalRole = ({ id }) => ({
  type: types.ORGANIZATIONAL_ROLE_FETCH,
  payload: { id },
});

const fetchOrganizationalRoleSucceeded = ({ ...organizationalRole }) => ({
  type: types.ORGANIZATIONAL_ROLE_FETCH_SUCCEEDED,
  payload: { ...organizationalRole },
});

const fetchOrganizationalRoleFailed = ({ message }) => ({
  type: types.ORGANIZATIONAL_ROLE_FETCH_FAILED,
  payload: { message },
});

const listOrganizationalRoles = ({ project }) => ({
  type: types.ORGANIZATIONAL_ROLES_LIST,
  payload: { project },
});

const listOrganizationalRolesSucceeded = payload => ({
  type: types.ORGANIZATIONAL_ROLES_LIST_SUCCEEDED,
  payload,
});

const listOrganizationalRolesFailed = ({ message }) => ({
  type: types.ORGANIZATIONAL_ROLES_LIST_FAILED,
  payload: { message },
});

const updateOrganizationalRole = ({ project, organizationalRoleId, ...organizationalRole }) => ({
  type: types.ORGANIZATIONAL_ROLE_UPDATE,
  payload: { project, organizationalRoleId, ...organizationalRole },
});

const updateOrganizationalRoleSucceeded = ({ ...organizationalRole }) => ({
  type: types.ORGANIZATIONAL_ROLE_UPDATE_SUCCEEDED,
  payload: { ...organizationalRole },
});

const updateOrganizationalRoleFailed = ({ message }) => ({
  type: types.ORGANIZATIONAL_ROLE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createOrganizationalRole,
  createOrganizationalRoleFailed,
  createOrganizationalRoleSucceeded,
  deleteOrganizationalRole,
  deleteOrganizationalRoleFailed,
  deleteOrganizationalRolesSucceeded,
  fetchOrganizationalRole,
  fetchOrganizationalRoleFailed,
  fetchOrganizationalRoleSucceeded,
  listOrganizationalRoles,
  listOrganizationalRolesFailed,
  listOrganizationalRolesSucceeded,
  updateOrganizationalRole,
  updateOrganizationalRoleFailed,
  updateOrganizationalRoleSucceeded,
};
