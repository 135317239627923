// TODO: This file should in in styles, not here.
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @page {
    margin: 0cm;
  }

  @media print {
    body {
      margin: 10mm, 10mm, 0 !important;
    }

    h3, h4 {
      page-break-after: avoid;
    }

    p {
      font-size: 20px !important;
    }

    .no-print, .no-print * {
        display: none !important;
    }
    .hubspot-messages-iframe-container {
      display: none !important;
    }
  }

  /* Hyperlinks. */
  a, a:hover, a:active {
    color: ${({ theme }) => theme.linkColor};
  }

  // FIXME F001: Review this styles
  button.hyperlink, button.no-button-styling {
    /* Disabling native CSS */
    font: inherit;
    font-family: inherit;
    font-size: 100%;
    text-transform: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    border: none;
    outline: none;
    background: none;
    all: unset;
    -moz-appearance: button;
    -webkit-appearance: button;
  }

  // FIXME F001: Review this styles
  button.hyperlink {
    /* Making it look like hyperlinks */
    cursor: pointer;
    color: ${({ theme }) => theme.linkColor};
  }

  ::selection {
    color: #fff;
    background: ${({ theme }) => theme.primaryColor};
  }

  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }
  .ant-select-dropdown {
    z-index: 10000000;
  }
  .ant-select-dropdown-menu > .ant-select-dropdown-menu-item {
    overflow: unset !important;
    white-space: normal !important;
  }

  /* Header, sider, and footer background. */
  .ant-layout-header,
  .ant-layout-sider,
  .ant-layout-sider-children {
    background-color: ${({ theme }) => theme.layoutHeaderBackground} !important;
    overflow-y: auto;
  }

  .ant-layout-footer {
    background-color: transparent;
    text-align: center;
  }

  .tool-icon-popover{
    /* FIXME: there is a way to create a styled-component class and use it directly in the code */
    /* That way we could use the color from theme, and not hard-code it */
    /* https:/*github.com/reactjs/react-modal/issues/603 */
    &.ant-popover-placement-bottomRight>.ant-popover-arrow {
      top: 1px;
    }
    &.ant-popover .ant-popover-arrow::after{
      border: 1px solid ${({ theme }) => theme.parrotColor};
    }
    .ant-popover-content{
      border: 1px solid ${({ theme }) => theme.parrotColor} !important;
      border-radius: 4px !important;
    }
  }

  .tool-icon-popover-teal{
    /* FIXME: there is a way to create a styled-component class and use it directly in the code */
    /* That way we could use the color from theme, and not hard-code it */
    /* https:/*github.com/reactjs/react-modal/issues/603 */
    .ant-popover-arrow{
      border: solid ${({ theme }) => theme.neon} !important;
      border-width: 1px 0 0 1px !important;
    }
    .ant-popover-content{
      border: 1px solid ${({ theme }) => theme.neon} !important;
      border-radius: 4px !important;
    }
  }

  /* Header. */
  li.ant-menu-item-group.profile-title-group-submenu-item > .ant-menu-item-group-title {
    padding: 20px ;
    background: ${({ theme }) =>
      `linear-gradient(to right, ${theme.primaryColor}, ${
        theme.secondaryColor || theme.primaryColor
      })`} ;
  }
  .header-settings.ant-menu-submenu-popup {
    border-radius: 4px !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2) !important;
    overflow: hidden !important;
    padding: 0 !important;

    .ant-menu-item-group {
      padding: 0 !important;
      margin-bottom: 0 !important;
      box-shadow: none !important;
    }
    .ant-menu-item-group-title {
      background: ${({ theme }) =>
        `linear-gradient(to right, ${theme.primaryColor}, ${
          theme.secondaryColor || theme.primaryColor
        })`} !important;
      padding: 20px !important;
    }
  }

  /* TODO: remove this style, it's preventing antd tooltips 
  // from making use of the color prop to have a colored background */
  /* Ant Calendar. */
  .ant-tooltip-inner {
    background-color: white !important; /*FIXME make also arrow of tooltip white */
    color: black !important;
  }

  .ant-calendar-footer-btn {
    padding: 5px 0 !important;
  }

  .no-time {
    background: red !important;
  }

  .ant-calendar-range-middle {
    position: absolute !important;
    left: 50% !important;
    width: 20px !important;
    margin-left: -162px !important;
    text-align: center !important;
    height: 34px !important;
    line-height: 34px !important;
    color: rgba(0, 0, 0, 0.45) !important;
  }

  /* Disabled select dropdown items. */
  .ant-select-dropdown-menu-item-disabled:hover,
  .ant-select-dropdown-menu-item-disabled {
    opacity: 0.25 !important;
    color: rgba(0, 0, 0, .75) !important;
    background-color: #fff !important;
    cursor: not-allowed !important;
  }

  /* Separating the calendar LEFT month/year buttons. */
  .ant-calendar-header .ant-calendar-prev-month-btn {
    left: 39px;
  }

  /* Separating the calendar RIGHT month/year buttons. */
  .ant-calendar-header .ant-calendar-next-month-btn {
    right: 39px;
  }

  /* Ant menu dropdown links. */
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light.submenu-class-pmpanel {
    ul.ant-menu-sub {
      padding: 5px 0;
    }

    li.ant-menu-item:hover {
        color: #1f1f1f;
        font-weight: 600;
        background-color: ${({ theme }) => theme.memberGreenBgFade};
    }
  }

  .ant-menu .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: ${({ theme }) => theme.primaryColor};
  }

  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: ${({ theme }) => theme.primaryColor};
    border-bottom: 2px solid ${({ theme }) => theme.primaryColor};
  }

  /* Checkboxes. */
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
  }

  .ant-checkbox-checked::after {
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.secondaryColor};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.secondaryColor};
  }

  /* Ant table. */
  .ant-table-pagination,
  .ant-pagination-item-link,
  .ant-pagination-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  /* Next and Previous button links. */
  .ant-pagination-prev:not(.ant-pagination-disabled),
  .ant-pagination-next:not(.ant-pagination-disabled) {
    a {
      border-color: #D9D9D9;
      color: #000000 !important;
    }
  }

  .ant-pagination-prev:not(.ant-pagination-disabled):hover,
  .ant-pagination-next:not(.ant-pagination-disabled):hover,
  .ant-pagination-prev:not(.ant-pagination-disabled):focus,
  .ant-pagination-next:not(.ant-pagination-disabled):focus {
    a {
      border-color: ${({ theme }) => theme.secondaryColor} !important;
    }
  }

  /* Pagination buttons. */
  .ant-pagination-item {
    position: relative;
    border-color: #D9D9D9;
    transition: all ease 300ms;
    a {
      color: #000000 !important;
    }
  }

  .ant-pagination-item:hover {
    border-color: ${({ theme }) => theme.secondaryColor} !important;
  }

  .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.secondaryColor} !important;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-color: ${({ theme }) => theme.secondaryColor};
      opacity: 0.1;
      content: '';
    }
  }

  .ant-pagination-item-link {
    width: 100%;
  }

  /* Columns */
  .ant-table-header-column,
  .ant-table-column-sorters {
    width: 100%;
  }

  /* Row colors when hovered. */
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: ${({ theme }) => `${theme.secondaryColor}26`};
  }

  /* Order and filter/search icons */
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
  .anticon.anticon-search.ant-table-filter-icon.ant-table-filter-selected {
    color: ${({ theme }) => theme.secondaryColor} !important;
  }

  .anticon.anticon-search.ant-table-filter-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      position: relative;
      margin: 0;
      top: unset;
      left: unset;
    }
  }

  /* Ant switch */
  .ant-switch {
    background-color: ${({ theme }) => theme.entColor} !important;
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.entColor} !important;
  }

  /* Ant notifications */
  .the-most-amazing-error-notification {
    .ant-notification-notice-close {
      color: #f5222d;
    }
  }

  /* Ant default tooltips */
  .ant-tooltip-arrow:before {
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.backgroundGrey};
    border: 1px solid rgba(0, 0, 0, 0.07);
  }

  /* Ant Modals */
  .ant-modal-mask {
    background-color: rgba(255, 255, 255, 0.45);
  }

  /* Time Picker */
  .ant-time-picker-panel-select ul {
    padding: 0;
  }

  /* Languages Select */
  .languages-menu .ant-select-item-option-selected {
    background-color: rgba(0, 255, 175, 0.2) !important;
  }
`;

export default GlobalStyle;
