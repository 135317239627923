const MILESTONE_CLEAR_ERROR = 'bfb/MILESTONE/MILESTONE_CLEAR_ERROR';
const MILESTONES_LIST = 'bfb/MILESTONE/MILESTONES_LIST';
const MILESTONES_LIST_SUCCEEDED = 'bfb/MILESTONE/MILESTONES_LIST_SUCCEEDED';
const MILESTONES_LIST_FAILED = 'bfb/MILESTONE/MILESTONES_LIST_FAILED';
const MILESTONE_CREATE = 'bfb/MILESTONE/MILESTONE_CREATE';
const MILESTONE_CREATE_SUCCEEDED = 'bfb/MILESTONE/MILESTONE_CREATE_SUCCEEDED';
const MILESTONE_CREATE_FAILED = 'bfb/MILESTONE/MILESTONE_CREATE_FAILED';
const MILESTONE_DELETE = 'bfb/MILESTONE/MILESTONE_DELETE';
const MILESTONE_DELETE_SUCCEEDED = 'bfb/MILESTONE/MILESTONE_DELETE_SUCCEEDED';
const MILESTONE_DELETE_FAILED = 'bfb/MILESTONE/MILESTONE_DELETE_FAILED';
const MILESTONE_FETCH = 'bfb/MILESTONE/MILESTONE_FETCH';
const MILESTONE_FETCH_SUCCEEDED = 'bfb/MILESTONE/MILESTONE_FETCH_SUCCEEDED';
const MILESTONE_FETCH_FAILED = 'bfb/MILESTONE/MILESTONE_FETCH_FAILED';
const MILESTONE_UPDATE = 'bfb/MILESTONE/MILESTONE_UPDATE';
const MILESTONE_UPDATE_SUCCEEDED = 'bfb/MILESTONE/MILESTONE_UPDATE_SUCCEEDED';
const MILESTONE_UPDATE_FAILED = 'bfb/MILESTONE/MILESTONE_UPDATE_FAILED';

export default {
  MILESTONES_LIST,
  MILESTONES_LIST_SUCCEEDED,
  MILESTONES_LIST_FAILED,
  MILESTONE_CLEAR_ERROR,
  MILESTONE_CREATE,
  MILESTONE_CREATE_SUCCEEDED,
  MILESTONE_CREATE_FAILED,
  MILESTONE_DELETE,
  MILESTONE_DELETE_SUCCEEDED,
  MILESTONE_DELETE_FAILED,
  MILESTONE_FETCH,
  MILESTONE_FETCH_SUCCEEDED,
  MILESTONE_FETCH_FAILED,
  MILESTONE_UPDATE,
  MILESTONE_UPDATE_SUCCEEDED,
  MILESTONE_UPDATE_FAILED,
};
