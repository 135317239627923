const FUNDING_CLEAR_ERROR = 'bfb/financialProjections/FUNDING_CLEAR_ERROR';
const FUNDINGS_LIST = 'bfb/financialProjections/FUNDINGS_LIST';
const FUNDINGS_LIST_SUCCEEDED = 'bfb/financialProjections/FUNDINGS_LIST_SUCCEEDED';
const FUNDINGS_LIST_FAILED = 'bfb/financialProjections/FUNDINGS_LIST_FAILED';
const FUNDING_CREATE = 'bfb/financialProjections/FUNDING_CREATE';
const FUNDING_CREATE_SUCCEEDED = 'bfb/financialProjections/FUNDING_CREATE_SUCCEEDED';
const FUNDING_CREATE_FAILED = 'bfb/financialProjections/FUNDING_CREATE_FAILED';
const FUNDING_DELETE = 'bfb/financialProjections/FUNDING_DELETE';
const FUNDING_DELETE_SUCCEEDED = 'bfb/financialProjections/FUNDING_DELETE_SUCCEEDED';
const FUNDING_DELETE_FAILED = 'bfb/financialProjections/FUNDING_DELETE_FAILED';
const FUNDING_FETCH = 'bfb/financialProjections/FUNDING_FETCH';
const FUNDING_FETCH_SUCCEEDED = 'bfb/financialProjections/FUNDING_FETCH_SUCCEEDED';
const FUNDING_FETCH_FAILED = 'bfb/financialProjections/FUNDING_FETCH_FAILED';
const FUNDING_UPDATE = 'bfb/financialProjections/FUNDING_UPDATE';
const FUNDING_UPDATE_SUCCEEDED = 'bfb/financialProjections/FUNDING_UPDATE_SUCCEEDED';
const FUNDING_UPDATE_FAILED = 'bfb/financialProjections/FUNDING_UPDATE_FAILED';

export default {
  FUNDINGS_LIST,
  FUNDINGS_LIST_SUCCEEDED,
  FUNDINGS_LIST_FAILED,
  FUNDING_CLEAR_ERROR,
  FUNDING_CREATE,
  FUNDING_CREATE_SUCCEEDED,
  FUNDING_CREATE_FAILED,
  FUNDING_DELETE,
  FUNDING_DELETE_SUCCEEDED,
  FUNDING_DELETE_FAILED,
  FUNDING_FETCH,
  FUNDING_FETCH_SUCCEEDED,
  FUNDING_FETCH_FAILED,
  FUNDING_UPDATE,
  FUNDING_UPDATE_SUCCEEDED,
  FUNDING_UPDATE_FAILED,
};
