import { Record } from 'immutable';

export const FREE = 'free';
export const SPONSORED = 'sponsored';
export const PREMIUM = 'premium';
export const CUSTOM = 'custom';
export const EXTENSION = 'extension';
export const PREMIUM_EUROS = 'premium_euros';
export const SPONSORED_EUROS = 'sponsored_euros';
export const CUSTOM_EUROS = 'custom_euros';
export const EXTENSION_EUROS = 'extension_euros';
export const COMMUNITY = 'community';

// Key/value pair object
const PLAN_TIERS = [
  FREE,
  SPONSORED,
  PREMIUM,
  CUSTOM,
  EXTENSION,
  PREMIUM_EUROS,
  SPONSORED_EUROS,
  CUSTOM_EUROS,
  EXTENSION_EUROS,
  COMMUNITY,
];

const MemberRecord = Record({
  GDPR: null,
  affiliation: '',
  applied_to_other_programs: null,
  biography: '',
  birthday: '',
  card_addres_line1: '',
  card_adrress_line2: '',
  card_city: '',
  card_country: '',
  card_exp_month: '',
  card_exp_year: '',
  card_last_four: '',
  card_name: '',
  card_state: '',
  card_zip: '',
  city: '',
  country: '',
  country_sort: '',
  coupon: '',
  currency: null,
  demo_community: null,
  demo_education: null,
  demo_employment: null,
  demo_income: null,
  demo_none_apply: null,
  demo_parent: null,
  department: '',
  education: [],
  email: '',
  emails: null,
  expertise_type: '',
  first_name: '',
  free_trial: false,
  gender: '',
  had_first_visit_workspace: false,
  had_tools_tutorial: false,
  id: null,
  industry: [],
  language: '',
  languages: [],
  additional_languages: [],
  last_name: '',
  last_login: null,
  linkedin: '',
  origin: '',
  origin_program_type: null,
  phone_number: '',
  picture: null,
  plan: null,
  plan_ended: '',
  extension_ended: '',
  plan_period: '',
  plan_started: '',
  profile_completion: 0,
  referrer: '',
  status: null,
  subscription_type: null,
  tagline: '',
  timezone: '',
  token: '',
  work_experience: [],
  user: null,
  ownership: null,
  isMentor: false,
  isMember: true,
  // Latest application values
  application_status: null,
  application_program: null,
  application_project: null,
  application_rejected: null,
  // Project properties
  expertises: [],
  skills: [],
  projects: [],
  ruid: '',
  hubspotutk: '',
  application_venture_summary_complete: null,
});

class Member extends MemberRecord {
  static STATUS_PROFILE_INCOMPLETE = 10;

  static STATUS_PROFILE_COMPLETE = 11;

  static STATUS_INCUBATING = 12;

  static STATUS_PAYMENT_FAILED = 4;

  static CURRENCY_USD = 0;

  static CURRENCY_EUR = 1;

  static PLAN_TIERS = PLAN_TIERS;

  static isMentor = false;

  static isMember = true;

  static STATUS_FIND_NO_SUPPORT = 2;

  static STATUS_NO_PROGRAM_BARRIER = 0;

  static STATUS_PROGRAM_REJECTION = 1;

  static ORIGIN_PROGRAM_B2B = 0;

  static ORIGIN_PROGRAM_B2C = 1;

  static isAdmin() {
    return false;
  }

  /**
   * Gets the status display strings based on the user status types.
   * @param {function} t - Translate function.
   * @param {number} status - Integer of the user status type.
   */

  static isOwner(member, project) {
    return project && project.owner ? Boolean(project.owner.id === member.id) : false;
  }

  static getStatusDisplay(t, status) {
    switch (status) {
      case Member.STATUS_PROFILE_INCOMPLETE:
        return t('Profile Incomplete');
      case Member.STATUS_PROFILE_COMPLETE:
        return t('Profile Complete');
      case Member.STATUS_INCUBATING:
        return t('Incubating');
      default:
        return t('No data yet.');
    }
  }

  /**
   * Gets the status display strings based on the user status types.
   * @param {function} t - Translate function.
   * @param {number} status - Integer of the user status type.
   */
}

export default Member;
