import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import serviceBeneficiary from 'services/ImpactPlan/beneficiary';
import types from './types';
import actions from './actions';

export function* createBeneficiary(action) {
  const { project, ...beneficiary } = action.payload;
  try {
    const response = yield call(serviceBeneficiary.createBeneficiary, {
      project,
      ...beneficiary,
    });
    yield put(actions.createBeneficiariesucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.createBeneficiaryFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteBeneficiary(action) {
  const { project, beneficiaryId } = action.payload;
  try {
    yield call(serviceBeneficiary.deleteBeneficiary, {
      project,
      id: beneficiaryId,
    });
    yield put(actions.deleteBeneficiariesucceeded({ id: beneficiaryId }));
  } catch (e) {
    yield put(
      actions.deleteBeneficiaryFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchBeneficiary(action) {
  const { project, beneficiaryId } = action.payload;
  try {
    const response = yield call(serviceBeneficiary.fetchBeneficiary, {
      project,
      beneficiaryId,
    });
    yield put(actions.fetchBeneficiariesucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchBeneficiaryFailed({ message: e.message }));
  }
}

export function* listBeneficiaries(action) {
  const { project } = action.payload;
  try {
    const beneficiaries = yield call(serviceBeneficiary.listBeneficiaries, {
      project,
    });
    yield put(actions.listBeneficiariesSucceeded({ results: beneficiaries.data }));
  } catch (e) {
    yield put(actions.listBeneficiariesFailed({ message: e.message }));
  }
}

export function* updateBeneficiary(action) {
  const { project, beneficiaryId, ...beneficiary } = action.payload;
  try {
    const updated = yield call(serviceBeneficiary.updateBeneficiary, {
      project,
      id: beneficiaryId,
      ...beneficiary,
    });
    yield put(actions.updateBeneficiariesucceeded({ ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateBeneficiaryFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* saga() {
  yield all([
    takeLatest(types.BENEFICIARY_CREATE, createBeneficiary),
    takeLatest(types.BENEFICIARY_DELETE, deleteBeneficiary),
    takeLatest(types.BENEFICIARY_FETCH, fetchBeneficiary),
    takeLatest(types.BENEFICIARY_UPDATE, updateBeneficiary),
    takeEvery(types.BENEFICIARIES_LIST, listBeneficiaries),
  ]);
}

export default saga;
