import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import serviceOrganizationalRole from 'services/GrowthPlan/organizationalRole';
import { types as commentTypes } from 'store/modules/comment';
import OrganizationalRole from 'models/GrowthPlan/OrganizationalRole';
import types from './types';
import actions from './actions';

export function* createOrganizationalRole(action) {
  const { project, ...organizationalRole } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(serviceOrganizationalRole.createOrganizationalRole, {
      project,
      ...organizationalRole,
    });
    yield put(actions.createOrganizationalRoleSucceeded({ ...response.data }));
    if (callback) yield callback(response.data.id);
  } catch (e) {
    yield put(
      actions.createOrganizationalRoleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteOrganizationalRole(action) {
  const { project, organizationalRoleId } = action.payload;
  const { callback } = action;
  try {
    yield call(serviceOrganizationalRole.deleteOrganizationalRole, {
      project,
      id: organizationalRoleId,
    });
    yield put(actions.deleteOrganizationalRolesSucceeded({ id: organizationalRoleId }));
    if (callback) yield callback(organizationalRoleId);
  } catch (e) {
    yield put(
      actions.deleteOrganizationalRoleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchOrganizationalRole(action) {
  const { id } = action.payload;
  try {
    const response = yield call(serviceOrganizationalRole.fetchOrganizationalRole, { id });
    yield put(actions.fetchOrganizationalRoleSucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchOrganizationalRoleFailed({ message: e.message }));
  }
}

export function* listOrganizationalRoles(action) {
  const { project } = action.payload;
  try {
    const organizationalRoles = yield call(serviceOrganizationalRole.listOrganizationalRoles, {
      project,
    });
    yield put(
      actions.listOrganizationalRolesSucceeded({
        results: organizationalRoles.data,
      })
    );
  } catch (e) {
    yield put(actions.listOrganizationalRolesFailed({ message: e.message }));
  }
}

export function* updateOrganizationalRole(action) {
  const { project, organizationalRoleId, ...organizationalRole } = action.payload;
  try {
    const updated = yield call(serviceOrganizationalRole.updateOrganizationalRole, {
      project,
      id: organizationalRoleId,
      ...organizationalRole,
    });
    yield put(actions.updateOrganizationalRoleSucceeded({ ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateOrganizationalRoleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  // This is called allways that someone comment in tool 3's step 1, and
  // only must when is a organizational role type
  if (object.get('type') === 3) {
    yield put(
      actions.fetchOrganizationalRole({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* saga() {
  yield all([
    takeLatest(types.ORGANIZATIONAL_ROLE_CREATE, createOrganizationalRole),
    takeLatest(types.ORGANIZATIONAL_ROLE_DELETE, deleteOrganizationalRole),
    takeLatest(types.ORGANIZATIONAL_ROLE_FETCH, fetchOrganizationalRole),
    takeLatest(types.ORGANIZATIONAL_ROLE_UPDATE, updateOrganizationalRole),
    takeEvery(types.ORGANIZATIONAL_ROLES_LIST, listOrganizationalRoles),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default saga;
