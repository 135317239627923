import api from '../api';

const createMoonshotGoal = ({ ...moonshotGoal }) =>
  api.post('api/moonshot-goals/', { ...moonshotGoal });

const deleteMoonshotGoal = ({ id }) => api.delete(`api/moonshot-goals/${id}`);

const fetchMoonshotGoal = ({ id }) => api.get(`api/moonshot-goals/${id}/`);

const listMoonshotGoals = ({ project }) => api.get(`api/moonshot-goals/?project=${project}`);

const updateMoonshotGoal = ({ id, ...updatedMoonshotGoal }) =>
  api.patch(`api/moonshot-goals/${id}/`, { ...updatedMoonshotGoal });

export default {
  createMoonshotGoal,
  deleteMoonshotGoal,
  fetchMoonshotGoal,
  listMoonshotGoals,
  updateMoonshotGoal,
};
