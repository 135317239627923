import { fromJS, Map } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import { byId } from 'store/utils/sortFunctions';
import OrganizationalRole from 'models/GrowthPlan/OrganizationalRole';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ORGANIZATIONAL_ROLES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results
              .reduce(
                (organizationalRolesById, organizationalRole) =>
                  organizationalRolesById.set(
                    String(organizationalRole.id),
                    new OrganizationalRole(organizationalRole)
                  ),
                new Map()
              )
              .sort(byId),
          },
        })
      );
    case types.ORGANIZATIONAL_ROLE_FETCH_SUCCEEDED:
    case types.ORGANIZATIONAL_ROLE_CREATE_SUCCEEDED:
    case types.ORGANIZATIONAL_ROLE_UPDATE_SUCCEEDED: {
      return (() =>
        state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(String(action.payload.id), new OrganizationalRole(action.payload)),
            },
          })
        ))();
    }
    case types.ORGANIZATIONAL_ROLE_FETCH:
    case types.ORGANIZATIONAL_ROLE_CREATE:
    case types.ORGANIZATIONAL_ROLE_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.ORGANIZATIONAL_ROLE_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(String(action.payload.id)),
          },
        })
      );
    case types.ORGANIZATIONAL_ROLE_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.ORGANIZATIONAL_ROLE_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.ORGANIZATIONAL_ROLE_FETCH_FAILED:
    case types.ORGANIZATIONAL_ROLES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
