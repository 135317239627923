import { createSelector } from 'reselect';

const getState = state => state.benefit;

const getRawBenefits = createSelector([getState], state => state.get('data'));

const getBenefitsBySegment = createSelector([getRawBenefits], state => state.get('bySegment'));

const getBenefitsById = createSelector([getRawBenefits], state => state.get('byId'));

const getBenefits = createSelector([getBenefitsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getBenefits,
  getBenefitsBySegment,
  getBenefitsById,
  getErrorMessage,
};
