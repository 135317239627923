import { createSelector } from 'reselect';

const getState = state => state.mentorApplication;

const getRawApplications = createSelector([getState], state => state.get('data'));

const getApplicationsById = createSelector([getRawApplications], state => state.get('byId'));

const getApplications = createSelector([getApplicationsById], state => state.valueSeq().toJS());

const updatePending = createSelector([getState], state => state.get('updatePending'));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getApplications,
  getApplicationsById,
  updatePending,
  getErrorMessage,
};
