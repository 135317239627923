import api from '../api';

const createResult = ({ ...result }) => api.post('/api/results/', { ...result });

const deleteResult = ({ id }) => api.delete(`/api/results/${id}/`);

const fetchResult = ({ id }) => api.get(`/api/results/${id}/`);

const listResults = ({ system }) => api.get(`/api/results/?system=${system}`);

const updateResult = ({ id, ...result }) => api.patch(`/api/results/${id}/`, { ...result });

export default {
  createResult,
  deleteResult,
  fetchResult,
  listResults,
  updateResult,
};
