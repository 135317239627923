import types from './types';

const clearError = () => ({
  type: types.GROWTH_RISK_CLEAR_ERROR,
});

const createGrowthRisk = ({ ...value }) => ({
  type: types.GROWTH_RISK_CREATE,
  payload: { ...value },
});

const createGrowthRiskSucceeded = ({ ...value }) => ({
  type: types.GROWTH_RISK_CREATE_SUCCEEDED,
  payload: { ...value },
});

const createGrowthRiskFailed = ({ message }) => ({
  type: types.GROWTH_RISK_CREATE_FAILED,
  payload: { message },
});

const deleteGrowthRisk = ({ id }) => ({
  type: types.GROWTH_RISK_DELETE,
  payload: { id },
});

const deleteGrowthRiskSucceeded = ({ id }) => ({
  type: types.GROWTH_RISK_DELETE_SUCCEEDED,
  payload: { id },
});

const deleteGrowthRiskFailed = ({ message }) => ({
  type: types.GROWTH_RISK_DELETE_FAILED,
  payload: message,
});

const fetchGrowthRisk = ({ id }) => ({
  type: types.GROWTH_RISK_FETCH,
  payload: { id },
});

const fetchGrowthRiskSucceeded = ({ ...value }) => ({
  type: types.GROWTH_RISK_FETCH_SUCCEEDED,
  payload: { ...value },
});

const fetchGrowthRiskFailed = ({ message }) => ({
  type: types.GROWTH_RISK_FETCH_FAILED,
  payload: { message },
});

const listGrowthRisks = ({ project }) => ({
  type: types.GROWTH_RISKS_LIST,
  payload: { project },
});

const listGrowthRisksSucceeded = payload => ({
  type: types.GROWTH_RISKS_LIST_SUCCEEDED,
  payload,
});

const listGrowthRisksFailed = ({ message }) => ({
  type: types.GROWTH_RISKS_LIST_FAILED,
  payload: { message },
});

const updateGrowthRisk = ({ id, ...updatedGrowthRisk }) => ({
  type: types.GROWTH_RISK_UPDATE,
  payload: { id, ...updatedGrowthRisk },
});

const updateGrowthRiskSucceeded = ({ ...value }) => ({
  type: types.GROWTH_RISK_UPDATE_SUCCEEDED,
  payload: { ...value },
});

const updateGrowthRiskFailed = ({ message }) => ({
  type: types.GROWTH_RISK_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createGrowthRisk,
  createGrowthRiskFailed,
  createGrowthRiskSucceeded,
  deleteGrowthRisk,
  deleteGrowthRiskFailed,
  deleteGrowthRiskSucceeded,
  fetchGrowthRisk,
  fetchGrowthRiskFailed,
  fetchGrowthRiskSucceeded,
  listGrowthRisks,
  listGrowthRisksFailed,
  listGrowthRisksSucceeded,
  updateGrowthRisk,
  updateGrowthRiskFailed,
  updateGrowthRiskSucceeded,
};
