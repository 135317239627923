const APPLICATION_CLEAR_ERROR = 'bfb/memberApplication/APPLICATION_CLEAR_ERROR';
const APPLICATION_FETCH = 'bfb/memberApplication/APPLICATION_FETCH';
const APPLICATION_FETCH_SUCCEEDED = 'bfb/memberApplication/APPLICATION_FETCH_SUCCEEDED';
const APPLICATION_FETCH_FAILED = 'bfb/memberApplication/APPLICATION_FETCH_FAILED';
const APPLICATION_UPDATE = 'bfb/memberApplication/APPLICATION_UPDATE';
const APPLICATION_UPDATE_SUCCEEDED = 'bfb/memberApplication/APPLICATION_UPDATE_SUCCEEDED';
const APPLICATION_UPDATE_FAILED = 'bfb/memberApplication/APPLICATION_UPDATE_FAILED';
const APPLICATION_BULK_UPDATE = 'bfb/memberApplication/APPLICATION_BULK_UPDATE';
const APPLICATION_BULK_UPDATE_SUCCEEDED = 'bfb/memberApplication/APPLICATION_BULK_UPDATE_SUCCEEDED';
const APPLICATION_BULK_UPDATE_FAILED = 'bfb/memberApplication/APPLICATION_BULK_UPDATE_FAILED';
const APPLICATION_CREATE = 'bfb/memberApplication/APPLICATION_CREATE';
const APPLICATION_CREATE_SUCCEEDED = 'bfb/memberApplication/APPLICATION_CREATE_SUCCEEDED';
const APPLICATION_CREATE_FAILED = 'bfb/memberApplication/APPLICATION_CREATE_FAILED';
const APPLICATIONS_LIST = 'bfb/memberApplication/APPLICATIONS_LIST';
const APPLICATIONS_LIST_SUCCEEDED = 'bfb/memberApplication/APPLICATIONS_LIST_SUCCEEDED';
const APPLICATIONS_LIST_FAILED = 'bfb/memberApplication/APPLICATIONS_LIST_FAILED';
const APPLICATION_STATUS = 'bfb/memberApplication/APPLICATION_STATUS';
const APPLICATION_STATUS_SUCCEEDED = 'bfb/memberApplication/APPLICATION_STATUS_SUCCEEDED';
const APPLICATION_STATUS_FAILED = 'bfb/memberApplication/APPLICATION_STATUS_FAILED';

export default {
  APPLICATION_CLEAR_ERROR,
  APPLICATION_FETCH,
  APPLICATION_FETCH_SUCCEEDED,
  APPLICATION_FETCH_FAILED,
  APPLICATION_UPDATE,
  APPLICATION_UPDATE_SUCCEEDED,
  APPLICATION_UPDATE_FAILED,
  APPLICATION_CREATE,
  APPLICATION_CREATE_SUCCEEDED,
  APPLICATION_CREATE_FAILED,
  APPLICATIONS_LIST,
  APPLICATIONS_LIST_SUCCEEDED,
  APPLICATIONS_LIST_FAILED,
  APPLICATION_BULK_UPDATE,
  APPLICATION_BULK_UPDATE_SUCCEEDED,
  APPLICATION_BULK_UPDATE_FAILED,
  APPLICATION_STATUS,
  APPLICATION_STATUS_SUCCEEDED,
  APPLICATION_STATUS_FAILED,
};
