// eslint-disable-next-line
export const generateTableRowColumns = (tableColumns = {}, excludedRows = [], callback) => {
  const tableColumnsKeys = Object.keys(tableColumns);
  return (
    tableColumnsKeys
      // Filter excluded rows, if any.
      .filter(key => !excludedRows.includes(key))
      // Generate the row.
      .reduce((row, columnKey, index) => {
        const tableRow = { ...row };
        tableRow[columnKey] = callback(columnKey, index, tableRow);
        return tableRow;
      }, {})
  );
};
