import * as reduxHelper from 'store/utils/reduxHelper';
import Program from 'models/Program';
import types from './types';

export const initialState = {
  ...reduxHelper.applyRequestInfo({
    data: {
      byId: {},
    },
    selectedProgram: {},
    errorMessage: '',
    updatePending: true,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROGRAM_MANAGEMENT_FETCH_PROGRAM:
    case types.PROGRAM_MANAGEMENT_UPDATE_PROGRAM:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PROGRAM_MANAGEMENT_FETCH_PROGRAM_SUCCEEDED:
    case types.PROGRAM_MANAGEMENT_UPDATE_PROGRAM_SUCCEEDED:
    case types.PROGRAM_MANAGEMENT_UPLOAD_IMAGE:
      const program = new Program({ ...action.payload, detail: true });
      const prevData = state.data;
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            ...prevData,
            byId: program.toJS(),
          },
          selectedProgram: { ...state.selectedProgram, ...program.toJS() },
          updatePending: false,
        })
      );
    case types.PROGRAM_MANAGEMENT_FETCH_PROGRAM_FAILED:
    case types.PROGRAM_MANAGEMENT_UPDATE_PROGRAM_FAILED:
    case types.PROGRAM_MANAGEMENT_UPLOAD_IMAGE_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
