import types from './types';

const fetchOrganization = ({ id }) => ({
  type: types.ORGANIZATION_FETCH,
  payload: { id },
});

const fetchPublicOrganization = ({ id }) => ({
  type: types.ORGANIZATION_FETCH,
  payload: { public: true, id },
});

const fetchOrganizationSucceeded = ({ id, ...program }) => ({
  type: types.ORGANIZATION_FETCH_SUCCEEDED,
  payload: { id, ...program },
});

const fetchOrganizationFailed = ({ message }) => ({
  type: types.ORGANIZATION_FETCH_FAILED,
  payload: { message },
});

const listOrganizations = () => ({
  type: types.ORGANIZATIONS_LIST,
});

const listOrganizationsSucceeded = payload => ({
  type: types.ORGANIZATIONS_LIST_SUCCEEDED,
  payload,
});

const listOrganizationsFailed = ({ message }) => ({
  type: types.ORGANIZATIONS_LIST_FAILED,
  payload: { message },
});

export default {
  fetchOrganization,
  fetchPublicOrganization,
  fetchOrganizationSucceeded,
  fetchOrganizationFailed,
  listOrganizations,
  listOrganizationsSucceeded,
  listOrganizationsFailed,
};
