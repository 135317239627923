import { createSelector } from 'reselect';

const getState = state => state.role;

const getRawRoles = createSelector([getState], state => state.get('data'));

const getRolesById = createSelector([getRawRoles], state => state.get('byId'));

const getRolesBySystem = createSelector([getRawRoles], state => state.get('bySystem'));

const getRoles = createSelector([getRolesById], state => state.valueSeq().toJS());

const getSystemRoles = createSelector([getRolesBySystem], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getRoles,
  getRolesById,
  getRolesBySystem,
  getSystemRoles,
  getErrorMessage,
};
