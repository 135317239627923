import { call, put, all, takeLatest } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import { actions, types } from 'store/modules/demoExamples';
import demoExamplesService from 'services/demoExamples';

export function* listDemoExamples(action) {
  try {
    const { query } = action.payload;
    const response = yield call(demoExamplesService.listDemoExamples, {
      ...query,
    });
    yield put(actions.listDemoExamplesSucceeded(response.data.results));
  } catch (e) {
    yield put(actions.listDemoExamplesFailed({ message: e.message }));
  }
}

function* demoExamplesSaga() {
  yield all([takeLatest(types.DEMO_EXAMPLES_LIST, listDemoExamples)]);
}

export default demoExamplesSaga;
