import { createSelector } from 'reselect';

const getState = state => state.reason;

const getRawReasons = createSelector([getState], state => state.get('data'));

const getReasonsById = createSelector([getRawReasons], state => state.get('byId'));

const getReasons = createSelector([getReasonsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getReasons,
  getReasonsById,
  getErrorMessage,
};
