import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/FinancialProjections/payroll';
import { types as commentTypes } from 'store/modules/comment';
import servicePayroll from 'services/FinancialProjections/payroll';
import Payroll from 'models/FinancialProjections/Payroll';

export function* createPayroll(action) {
  const { scenario, ...payroll } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(servicePayroll.createPayroll, {
      scenario,
      ...payroll,
    });
    yield put(actions.createPayrollSucceeded({ ...response.data, scenario }));
    if (callback) yield call(callback);
  } catch (e) {
    yield put(
      actions.createPayrollFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deletePayroll(action) {
  const { scenario, payrollId } = action.payload;
  const { callback } = action;
  try {
    yield call(servicePayroll.deletePayroll, { id: payrollId });
    if (callback) yield callback();
    yield put(actions.deletePayrollSucceeded({ payrollId, scenario }));
  } catch (e) {
    yield put(
      actions.deletePayrollFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchPayroll(action) {
  const { scenario, id } = action.payload;
  try {
    const response = yield call(servicePayroll.fetchPayroll, { scenario, id });
    yield put(actions.fetchPayrollSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(actions.fetchPayrollFailed({ message: e.message }));
  }
}

export function* listPayrolls(action) {
  const { scenario } = action.payload;
  try {
    const payrolls = yield call(servicePayroll.listPayrolls, { scenario });
    yield put(actions.listPayrollsSucceeded({ results: payrolls.data, scenario }));
  } catch (e) {
    yield put(actions.listPayrollsFailed({ message: e.message }));
  }
}

export function* updatePayroll(action) {
  const { scenario, id, ...updatedPayroll } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(servicePayroll.updatePayroll, {
      id,
      ...updatedPayroll,
    });
    yield put(actions.updatePayrollSucceeded({ ...updated.data, scenario }));
    if (callback) yield callback();
  } catch (e) {
    yield put(
      actions.updatePayrollFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Payroll.contentType) {
    yield put(
      actions.fetchPayroll({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* payrollSaga() {
  yield all([
    takeLatest(types.PAYROLL_CREATE, createPayroll),
    takeLatest(types.PAYROLL_DELETE, deletePayroll),
    takeLatest(types.PAYROLL_FETCH, fetchPayroll),
    takeLatest(types.PAYROLL_UPDATE, updatePayroll),
    takeEvery(types.PAYROLLS_LIST, listPayrolls),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default payrollSaga;
