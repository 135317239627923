import PropTypes from 'prop-types';
import styled from 'styled-components';
import bfbTheme from 'styles/b4b-theme';

import { InfoBubble } from 'components/elements';
import { DownOutlined } from '@ant-design/icons';
import { Col, Divider } from 'antd';
import { Row } from 'components/layout';

function TableModules(props) {
  const { data, title, color, fontStyle, infoText, type, currency, separator } = props;

  return (
    <>
      <Row type="flex" justify="start" style={{ width: '100%' }}>
        <Title span={24} key={title}>
          {title}
          {infoText && (
            <InfoBubble
              style={{ margin: '0 5px 0 auto', color: 'white' }}
              trigger="hover"
              infotext={infoText}
            />
          )}
        </Title>
      </Row>
      {type !== 'children'
        ? data.map(item => (
            <>
              <Row justify={currency ? 'space-between' : 'start'} wrap={false}>
                {item && currency ? <Cell color={`${color}5F`}>{currency}</Cell> : null}
                {type === 'asSelect' ? (
                  <MockedSelect>
                    {item}
                    <DownOutlined
                      style={{ color: bfbTheme.mainColor, margin: '0px 10px 0px auto' }}
                    />
                  </MockedSelect>
                ) : (
                  <Cell color={color} fontStyle={fontStyle}>
                    <div>{item}</div>
                  </Cell>
                )}
              </Row>
              {separator && <Divider style={{ margin: '1px 0' }} />}
            </>
          ))
        : data}
    </>
  );
}

const Title = styled(Col)`
  display: flex;
  align-items: center;
  background: ${bfbTheme.mainColor};
  font-size: 15px;
  color: white;
  border-radius: 4px;
  padding: 8px 0;
  margin-left: 10px;
`;

const Cell = styled(Col)`
  display: flex;
  align-items: center;
  min-height: 36px;
  color: ${({ color }) => color || 'black'};
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  font-size: 15px;
  margin-top: 4px;
`;

const MockedSelect = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  height: 34px;
  color: black;
  background-color: #f2f2f2;
  font-style: italic;
  font-size: 15px;
  padding: 4px 8px;
  margin: 5px 0 1px 12px;
  border-radius: 100px;
`;

TableModules.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  infoText: PropTypes.string,
  fontStyle: PropTypes.string,
  type: PropTypes.string,
  currency: PropTypes.string,
  separator: PropTypes.bool,
};

TableModules.defaultProps = {
  color: null,
  type: null,
  currency: null,
  infoText: null,
  fontStyle: null,
  separator: false,
};

export default TableModules;
