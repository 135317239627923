// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Dependencies
import { getValueForSize } from 'styles/utils';

// Components
import Icon from '@ant-design/icons';

/* If you want to use this, you can just pass in the native or custom Icon component:
  <ToolIcon component={AntIconComponent} />
  <ToolIcon component={CustomSVG} />
*/
const ToolIconComponent = props => {
  const { colorIndex, useTheme, star, ...rest } = props;
  return <Icon {...rest} />;
};

ToolIconComponent.propTypes = {
  colorIndex: PropTypes.number,
  useTheme: PropTypes.bool,
  star: PropTypes.bool,
};

ToolIconComponent.defaultProps = {
  colorIndex: undefined,
  useTheme: undefined,
  star: false,
};

const IconSizes = {
  small: '14px',
  default: '22px',
  large: '24px',
};

const ToolIcon = styled(ToolIconComponent)`
  &&& {
    color: ${({ colorIndex, useTheme, theme }) => {
      switch (true) {
        case colorIndex !== undefined:
          return theme.getToolColor(colorIndex);
        case useTheme:
          return theme.primaryColor;
        default:
          return '#D9D9D9';
      }
    }};

    font-size: ${props => getValueForSize(props, IconSizes)};

    ${props =>
      props.star &&
      css`
        svg,
        path {
          width: 25px;
          height: 25px;
        }
      `}
    vertical-align: middle;
  }
`;

export default ToolIcon;
