import React from 'react';
import { Button } from 'components/elements';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withTranslations from 'hocs/withTranslations';
import { PlusOutlined } from '@ant-design/icons';

const AddButton = props => {
  const isPopoverDisabled = props.isDisabled ? null : { open: false };
  const { t, popoverContent, isDisabled, onClick, ...rest } = props;
  // const isButtonDisabled = props.isDisabled ? 'disabled' : null;
  return popoverContent ? (
    <Popover
      placement="bottom"
      trigger="hover"
      {...isPopoverDisabled}
      content={popoverContent}
      {...rest}
    >
      <Button
        type="primary"
        size="small"
        onClick={!isDisabled ? onClick : () => {}}
        style={isDisabled ? { cursor: 'not-allowed', opacity: '50%' } : { cursor: 'pointer' }}
      >
        <PlusOutlined /> {t('Add')}
      </Button>
    </Popover>
  ) : (
    <Button
      type="primary"
      size="small"
      onClick={!isDisabled ? onClick : () => {}}
      style={isDisabled ? { cursor: 'not-allowed', opacity: '0.5' } : { cursor: 'pointer' }}
    >
      <PlusOutlined /> {t('Add')}
    </Button>
  );
};

const StyledAddButton = styled(AddButton)`
  &&& {
    float: right;
  }
`;

AddButton.propTypes = {
  popoverContent: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

AddButton.defaultProps = {
  popoverContent: null,
  onClick: () => {},
};

export default withTranslations(StyledAddButton);
