const { localStorage } = window;

const ADMIN_SELECTED_PROGRAM = 'ADMIN_SELECTED_PROGRAM';

/**
 * Sets the selected program identifier in the window's local storage.
 * @param {number} selectedProgram - Selected program id.
 */
function storeSelectedProgram(selectedProgram) {
  if (typeof selectedProgram === 'string') {
    localStorage.setItem(ADMIN_SELECTED_PROGRAM, selectedProgram);
  }
}

/**
 * Fetches the stored selected program.
 */
function getStoredSelectedProgram() {
  return localStorage.getItem(ADMIN_SELECTED_PROGRAM);
}

/**
 * Deletes the stored selected program.
 */
function deleteStoredSelectedProgram() {
  return localStorage.removeItem(ADMIN_SELECTED_PROGRAM);
}

export default {
  ADMIN_SELECTED_PROGRAM,
  storeSelectedProgram,
  getStoredSelectedProgram,
  deleteStoredSelectedProgram,
};
