const MENTOR_FETCH = 'bfb/mentor/MENTOR_FETCH';
const MENTOR_FETCH_SUCCEEDED = 'bfb/mentor/MENTOR_FETCH_SUCCEEDED';
const MENTOR_FETCH_FAILED = 'bfb/mentor/MENTOR_FETCH_FAILED';
const MENTOR_EDIT = 'bfb/mentor/MENTOR_EDIT';
const MENTOR_EDIT_SUCCEEDED = 'bfb/mentor/MENTOR_EDIT_SUCCEEDED';
const MENTOR_EDIT_FAILED = 'bfb/mentor/MENTOR_EDIT_FAILED';
const MENTOR_PUBLIC_FETCH = 'bfb/mentor/MENTOR_PUBLIC_FETCH';
const MENTOR_PUBLIC_FETCH_SUCCEEDED = 'bfb/mentor/MENTOR_PUBLIC_FETCH_SUCCEEDED';
const MENTOR_PUBLIC_FETCH_FAILED = 'bfb/mentor/MENTOR_PUBLIC_FETCH_FAILED';

export default {
  MENTOR_FETCH,
  MENTOR_FETCH_SUCCEEDED,
  MENTOR_FETCH_FAILED,
  MENTOR_EDIT,
  MENTOR_EDIT_SUCCEEDED,
  MENTOR_EDIT_FAILED,
  MENTOR_PUBLIC_FETCH,
  MENTOR_PUBLIC_FETCH_SUCCEEDED,
  MENTOR_PUBLIC_FETCH_FAILED,
};
