import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Channel from 'models/StakeholderMap/Channel';
import serviceChannel from 'services/StakeholderMap/channel';
import types from './types';
import actions from './actions';

export function* createChannel(action) {
  try {
    const response = yield call(serviceChannel.createChannel, {
      ...action.payload,
    });
    yield put(
      actions.createChannelSucceeded({
        chain: response.data.new_chain,
        ...response.data,
      })
    );
  } catch (e) {
    yield put(
      actions.createChannelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteChannel(action) {
  const { chain, id } = action.payload;
  try {
    yield call(serviceChannel.deleteChannel, { chain, id });
    yield put(actions.deleteChannelSucceeded({ id, chain }));
  } catch (e) {
    yield put(
      actions.deleteChannelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchChannel(action) {
  const { project, product, id } = action.payload;
  try {
    const response = yield call(serviceChannel.fetchChannel, {
      project,
      product,
      id,
    });
    yield put(actions.fetchChannelSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(actions.fetchChannelFailed({ message: e.message }));
  }
}

export function* listChannels(action) {
  const { project, product } = action.payload;
  try {
    const channels = yield call(serviceChannel.listChannels, {
      project,
      product,
    });
    yield put(actions.listChannelsSucceeded({ results: channels.data, product }));
  } catch (e) {
    yield put(actions.listChannelsFailed({ message: e.message }));
  }
}

export function* updateChannel(action) {
  const { project, product, channelId, ...updatedChannel } = action.payload;
  try {
    const updated = yield call(serviceChannel.updateChannel, {
      project,
      product,
      channelId,
      ...updatedChannel,
    });
    yield put(actions.updateChannelSucceeded({ ...updated.data, product }));
  } catch (e) {
    yield put(
      actions.updateChannelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Channel.contentType) {
    yield put(
      actions.fetchChannel({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* channelSaga() {
  yield all([
    takeLatest(types.CHANNEL_CREATE, createChannel),
    takeLatest(types.CHANNEL_DELETE, deleteChannel),
    takeLatest(types.CHANNEL_FETCH, fetchChannel),
    takeLatest(types.CHANNEL_UPDATE, updateChannel),
    takeEvery(types.CHANNELS_LIST, listChannels),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default channelSaga;
