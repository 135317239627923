import { Record, Map } from 'immutable';
import { byId } from 'store/utils/sortFunctions';
import ContentType from '../ContentType';
import MonthlyUnitsSold from './MonthlyUnitsSold';
import MonthlyPrice from './MonthlyPrice';
import MonthlyCogs from './MonthlyCogs';

export const YEARLY_PROPS_YEAR_1 = '0';
export const YEARLY_PROPS_YEAR_2 = '1';
export const YEARLY_PROPS_YEAR_3 = '2';

export const YEARLY_UNITS_SOLD = 'yearly_units_sold';
export const YEARLY_PRICE = 'yearly_price';
export const YEARLY_COGS = 'yearly_cogs';
export const YEARLY_UNITS_SOLD_TOTALS = 'yearly_units_sold_totals';
export const YEARLY_PRICE_TOTALS = 'yearly_price_totals';
export const YEARLY_COGS_TOTALS = 'yearly_cogs_totals';

export const YEARLY_PROPS = [YEARLY_PROPS_YEAR_1, YEARLY_PROPS_YEAR_2, YEARLY_PROPS_YEAR_3];

const TYPE_DAY = 0;
const TYPE_WEEK = 1;
const TYPE_MONTH = 2;
const TYPE_YEAR = 3;

const TYPE_CHOICES = [TYPE_DAY, TYPE_WEEK, TYPE_MONTH, TYPE_YEAR];

const FinancialProductRecord = Record({
  contentType: ContentType.FINANCIALPRODUCT,
  id: null,
  product: null,
  scenario: null,
  created: null,
  updated: null,
  name: null,
  comments: null,
  units: null,
  price: null,
  cogs_unit: null,
  gross_margin: null,
  units_production: null,
  time_frame: null,
  direct_labor: null,
  materials: null,
  production_facility: null,
  shipping: null,
  other_costs: null,
  [YEARLY_UNITS_SOLD]: {
    [YEARLY_PROPS_YEAR_1]: [],
    [YEARLY_PROPS_YEAR_2]: [],
    [YEARLY_PROPS_YEAR_3]: [],
  },
  [YEARLY_PRICE]: {
    [YEARLY_PROPS_YEAR_1]: [],
    [YEARLY_PROPS_YEAR_2]: [],
    [YEARLY_PROPS_YEAR_3]: [],
  },
  [YEARLY_COGS]: {
    [YEARLY_PROPS_YEAR_1]: [],
    [YEARLY_PROPS_YEAR_2]: [],
    [YEARLY_PROPS_YEAR_3]: [],
  },
  [YEARLY_UNITS_SOLD_TOTALS]: {},
  [YEARLY_PRICE_TOTALS]: {},
  [YEARLY_COGS_TOTALS]: {},
});

class FinancialProduct extends FinancialProductRecord {
  static contentType = ContentType.FINANCIALPRODUCT;

  static TYPE_CHOICES = TYPE_CHOICES;

  constructor(payload) {
    const financialProduct = { ...payload };
    [
      [YEARLY_UNITS_SOLD, YEARLY_UNITS_SOLD_TOTALS, MonthlyUnitsSold],
      [YEARLY_PRICE, YEARLY_PRICE_TOTALS, MonthlyPrice],
      [YEARLY_COGS, YEARLY_COGS_TOTALS, MonthlyCogs],
    ].forEach(([key, totalKey, Constructor]) => {
      const yearlyTotals = {
        [YEARLY_PROPS_YEAR_1]: {},
        [YEARLY_PROPS_YEAR_2]: {},
        [YEARLY_PROPS_YEAR_3]: {},
      };
      const yearlyProps = {
        ...financialProduct[key],
      };
      // Normalizing data and calculating the yearly financialProduct totals:
      YEARLY_PROPS.forEach(year => {
        // immutable.js leads to an unintended mutation of the yearlyProps.
        // The problem is that the yearly_totals aren't calculated, so we must
        // mutate it back to JavaScript and calculate the new yearly totals.
        const financialProducts = Array.isArray(yearlyProps[year] || [])
          ? yearlyProps[year]
          : yearlyProps[year].valueSeq().toJS();
        const normalizedData = (financialProducts || [])
          .reduce((prevData, monthlyFinancialProduct) => {
            yearlyTotals[String(year)][monthlyFinancialProduct.id] = monthlyFinancialProduct.amount;
            return prevData.set(
              String(monthlyFinancialProduct.id),
              new Constructor({
                ...monthlyFinancialProduct,
                product: financialProduct.id,
                year,
              })
            );
          }, new Map())
          .sort(byId);
        financialProduct[key][year] = normalizedData;
      });
      financialProduct[totalKey] = yearlyTotals;
    });
    super(financialProduct);
  }

  static getDisplayTimeframe = (type, t) => {
    switch (type) {
      case TYPE_DAY:
        return t('Day');
      case TYPE_WEEK:
        return t('Week');
      case TYPE_MONTH:
        return t('Month');
      case TYPE_YEAR:
        return t('Year');
      default:
        return undefined;
    }
  };

  get getId() {
    return this.get('id');
  }

  static getBasicData = financialProduct => {
    const {
      id,
      name,
      units,
      price,
      units_production: unitsProduction,
      direct_labor: directLabor,
      materials,
      production_facility: productionFacility,
      shipping,
      other_costs: otherCosts,
    } = financialProduct;
    const cogs =
      Number(directLabor) +
      Number(materials) +
      Number(productionFacility) +
      Number(shipping) +
      Number(otherCosts);
    const cogsPerUnit = unitsProduction ? cogs / unitsProduction : '-';
    const grossMargin = price - cogsPerUnit;
    return {
      id,
      name,
      units,
      price,
      cogs,
      cogsPerUnit,
      grossMargin,
    };
  };

  static getYearlyTotal = yearlyTotals => {
    const yearly = yearlyTotals.toJS ? { ...yearlyTotals.toJS() } : { ...yearlyTotals };
    const total = Object.values(yearly).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return total;
  };

  static getYearlyRevenue = (financialProduct, year) => {
    const yearlyUnitsSold = financialProduct[YEARLY_UNITS_SOLD][year];
    const yearlyPrices = financialProduct[YEARLY_PRICE][year];
    const unitsSold =
      yearlyUnitsSold && yearlyUnitsSold.toJS
        ? { ...yearlyUnitsSold.toJS() }
        : { ...yearlyUnitsSold };
    const prices =
      yearlyPrices && yearlyPrices.toJS ? { ...yearlyPrices.toJS() } : { ...yearlyPrices };
    const extractedPrices = Object.values(prices);
    const total = Object.values(unitsSold).reduce((accumulator, monthlyUnits) => {
      const monthlyPrice = extractedPrices.find(obj => obj.month === monthlyUnits.month);
      if (!monthlyUnits || !monthlyPrice) return accumulator;
      return accumulator + +monthlyUnits.amount * +monthlyPrice.amount;
    }, 0);
    return total;
  };

  static getTotalRevenue = (financialProduct, yearlyForecast = {}) => {
    const forecast = Object.keys(yearlyForecast).length
      ? { ...yearlyForecast }
      : {
          [YEARLY_PROPS_YEAR_1]: 0,
          [YEARLY_PROPS_YEAR_2]: 0,
          [YEARLY_PROPS_YEAR_3]: 0,
        };
    // Yearly total revenues.
    [YEARLY_PROPS_YEAR_1, YEARLY_PROPS_YEAR_2, YEARLY_PROPS_YEAR_3].forEach(year => {
      const yearlyRevenue = FinancialProduct.getYearlyRevenue(financialProduct, year);
      forecast[year] += yearlyRevenue;
    });
    return forecast;
  };

  static getYearlyCogs = (financialProduct, year) => {
    const cogsValues = Object.values(financialProduct.yearly_cogs_totals[year]);
    const unitsSoldValues = Object.values(financialProduct.yearly_units_sold_totals[year]);
    const yearCogsTotal = cogsValues.reduce((cogsTotal, current, index) => {
      const units = unitsSoldValues[index];
      return cogsTotal + current * units;
    }, 0);
    return yearCogsTotal;
  };

  static getTotalCogs = (financialProduct, yearlyForecast = {}) => {
    const forecast = Object.keys(yearlyForecast).length
      ? { ...yearlyForecast }
      : {
          [YEARLY_PROPS_YEAR_1]: 0,
          [YEARLY_PROPS_YEAR_2]: 0,
          [YEARLY_PROPS_YEAR_3]: 0,
        };
    // Yearly total revenues.
    [YEARLY_PROPS_YEAR_1, YEARLY_PROPS_YEAR_2, YEARLY_PROPS_YEAR_3].forEach(year => {
      const yearlyCogs = FinancialProduct.getYearlyCogs(financialProduct, year);
      forecast[year] += yearlyCogs;
    });
    return forecast;
  };
}

export default FinancialProduct;
