// Libraries
import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Dependedncies
import bfbTheme from 'styles/b4b-theme';
import TranslationsContext from 'providers/translationsProvider';

// Components
import { CountryIcon } from 'components/elements';
import { Select } from 'antd';

const CountrySelect = ({ countriesData, placeholder, ...props }) => {
  const { t } = useContext(TranslationsContext);
  // Select Label (Flag Icon & Country Name)
  const CountryLabelWIthFlag = (countryCode, countryName) => (
    <>
      <CountryIcon style={{ paddingInline: '20px 10px' }} countryCode={countryCode} />
      <span>{t(countryName)}</span>
    </>
  );

  // Select options
  const options = countriesData
    ? countriesData.map(country => ({
        label: CountryLabelWIthFlag(country[0], country[1]),
        value: country[0],
        text: country[1],
      }))
    : [];

  // Get Country name from Ant option data
  const getCountryName = option => option.text;

  return (
    <StyledSelect
      showSearch
      filterOption={(inputValue, option) =>
        getCountryName(option).toLowerCase().includes(inputValue.toLowerCase())
      }
      placeholder={placeholder || t('Choose a country')}
      options={options}
      {...props}
    />
  );
};

const StyledSelect = styled(Select)`
  &&& {
    width: 100%;

    .ant-select-selector {
      .ant-select-selection-search {
        inset-inline-start: 0;
        inset-inline-end: 0;
      }

      ${props =>
        !props.bordered &&
        css`
          padding: 0;
          border: 0;
          border-bottom: 1px solid ${bfbTheme.placeholderGrey};
          border-radius: 0;

          .ant-select-selection-search-input {
            inset-inline-start: 0px;
          }

          .ant-select-selection-placeholder {
            inset-inline-start: 0;
            inset-inline-end: 0;
          }
        `}
    }

    .ant-select-arrow {
      color: ${bfbTheme.placeholderGrey};
    }

    .ant-select-selection-placeholder {
      font-style: italic;
      font-weight: 100;
    }
  }
`;

CountrySelect.propTypes = {
  countriesData: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
};

CountrySelect.defaultProps = {
  placeholder: null,
  countriesData: undefined,
};

export default CountrySelect;
