import types from './types';

const clearError = () => ({
  type: types.POSITIONING_CLEAR_ERROR,
});

const createPositioning = ({ project, product, competitor, ...positioning }) => ({
  type: types.POSITIONING_CREATE,
  payload: { project, product, competitor, ...positioning },
});

const createPositioningSucceeded = ({ product, id, ...positioning }) => ({
  type: types.POSITIONING_CREATE_SUCCEEDED,
  payload: { product, id, ...positioning },
});

const createPositioningFailed = ({ message }) => ({
  type: types.POSITIONING_CREATE_FAILED,
  payload: { message },
});

const deletePositioning = ({ project, product, competitor, id }) => ({
  type: types.POSITIONING_DELETE,
  payload: { project, product, competitor, id },
});

const deletePositioningSucceeded = ({ product, id }) => ({
  type: types.POSITIONING_DELETE_SUCCEEDED,
  payload: { product, id },
});

const deletePositioningFailed = ({ message }) => ({
  type: types.POSITIONING_DELETE_FAILED,
  payload: message,
});

const fetchPositioning = ({ project, product, competitor, id }) => ({
  type: types.POSITIONING_FETCH,
  payload: { project, product, competitor, id },
});

const fetchPositioningSucceeded = ({ ...positioning }) => ({
  type: types.POSITIONING_FETCH_SUCCEEDED,
  payload: { ...positioning },
});

const fetchPositioningFailed = ({ message }) => ({
  type: types.POSITIONING_FETCH_FAILED,
  payload: { message },
});

const listPositionings = ({ project, product, competitor }) => ({
  type: types.POSITIONINGS_LIST,
  payload: { project, product, competitor },
});

const listPositioningsSucceeded = payload => ({
  type: types.POSITIONINGS_LIST_SUCCEEDED,
  payload,
});

const listPositioningsFailed = ({ message }) => ({
  type: types.POSITIONINGS_LIST_FAILED,
  payload: { message },
});

const updatePositioning = ({ project, product, competitor, id, ...updatedPositioning }) => ({
  type: types.POSITIONING_UPDATE,
  payload: { project, product, competitor, id, ...updatedPositioning },
});

const updatePositioningSucceeded = ({ ...positioning }) => ({
  type: types.POSITIONING_UPDATE_SUCCEEDED,
  payload: { ...positioning },
});

const updatePositioningFailed = ({ message }) => ({
  type: types.POSITIONING_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createPositioning,
  createPositioningFailed,
  createPositioningSucceeded,
  deletePositioning,
  deletePositioningFailed,
  deletePositioningSucceeded,
  fetchPositioning,
  fetchPositioningFailed,
  fetchPositioningSucceeded,
  listPositionings,
  listPositioningsFailed,
  listPositioningsSucceeded,
  updatePositioning,
  updatePositioningFailed,
  updatePositioningSucceeded,
};
