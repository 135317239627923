// Libraries
import PropTypes from 'prop-types';
import { useContext } from 'react';

// Components
import { Tag } from 'components/elements';

import TranslationsContext from 'providers/translationsProvider';
import bfbTheme from 'styles/b4b-theme';
import Step from 'models/Tools/Step';

export default function ToolStatusTag(props) {
  const { status } = props;
  const { t } = useContext(TranslationsContext);

  const getRenderOptions = () => {
    switch (status) {
      case Step.STATUS_INCOMPLETE:
        return {
          text: t('Never reviewed'),
          color: bfbTheme.grey,
        };
      case Step.STATUS_STATUS_PENDING_REVIEW:
        return {
          text: t('Pending review'),
          color: bfbTheme.violet,
        };
      case Step.STATUS_COMPLETE:
        return {
          text: t('Yes, validated'),
          color: bfbTheme.mentorBlue,
        };
      case Step.STATUS_IN_PROGRESS:
        return {
          text: t('Need more work'),
          color: bfbTheme.orange,
        };
      default:
        return {
          text: t('Never reviewed'),
          color: bfbTheme.grey,
        };
    }
  };

  return (
    <Tag type="squared" style={{ margin: '2.4px 0' }} color={getRenderOptions().color}>
      {getRenderOptions().text}
    </Tag>
  );
}

ToolStatusTag.propTypes = {
  status: PropTypes.number.isRequired,
};
