import types from './types';

const clearError = () => ({
  type: types.PROBLEM_CLEAR_ERROR,
});

const createProblem = ({ project, ...problem }) => ({
  type: types.PROBLEM_CREATE,
  payload: { project, ...problem },
});

const createProblemSucceeded = ({ ...problem }) => ({
  type: types.PROBLEM_CREATE_SUCCEEDED,
  payload: { ...problem },
});

const createProblemFailed = ({ message }) => ({
  type: types.PROBLEM_CREATE_FAILED,
  payload: { message },
});

const deleteProblem = ({ id }) => ({
  type: types.PROBLEM_DELETE,
  payload: { id },
});

const deleteProblemSucceeded = ({ id, ...problem }) => ({
  type: types.PROBLEM_DELETE_SUCCEEDED,
  payload: { id, ...problem },
});

const deleteProblemFailed = ({ message }) => ({
  type: types.PROBLEM_DELETE_FAILED,
  payload: message,
});

const fetchProblem = ({ project, id }) => ({
  type: types.PROBLEM_FETCH,
  payload: { project, id },
});

const fetchProblemSucceeded = ({ ...problem }) => ({
  type: types.PROBLEM_FETCH_SUCCEEDED,
  payload: { ...problem },
});

const fetchProblemFailed = ({ message }) => ({
  type: types.PROBLEM_FETCH_FAILED,
  payload: { message },
});

const listProblems = ({ projectId }) => ({
  type: types.PROBLEMS_LIST,
  payload: { projectId },
});

const listProblemsSucceeded = payload => ({
  type: types.PROBLEMS_LIST_SUCCEEDED,
  payload,
});

const listProblemsFailed = ({ message }) => ({
  type: types.PROBLEMS_LIST_FAILED,
  payload: { message },
});

const updateProblem = ({ problemId, ...problem }) => ({
  type: types.PROBLEM_UPDATE,
  payload: { problemId, ...problem },
});

const updateProblemSucceeded = ({ ...problem }) => ({
  type: types.PROBLEM_UPDATE_SUCCEEDED,
  payload: { ...problem },
});

const updateProblemFailed = ({ message }) => ({
  type: types.PROBLEM_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createProblem,
  createProblemFailed,
  createProblemSucceeded,
  deleteProblem,
  deleteProblemFailed,
  deleteProblemSucceeded,
  fetchProblem,
  fetchProblemFailed,
  fetchProblemSucceeded,
  listProblems,
  listProblemsFailed,
  listProblemsSucceeded,
  updateProblem,
  updateProblemFailed,
  updateProblemSucceeded,
};
