// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Panel } from 'components/elements';
import { P } from 'components/fontSystem';

const RawToolOutputNoCollapse = props => {
  const {
    buttonText,
    colorIndex,
    title,
    contentDescription,
    contentTitle,
    exampleContent1,
    exampleContent2,
    popoverContent,
    ...rest
  } = props;
  return (
    <React.Fragment>
      <Panel
        $colorIndex={colorIndex}
        size="small"
        mt="10px"
        mb="0px"
        style={{ padding: 10 }}
        {...rest}
      >
        <P $mb0 $semibold className="title" color="white">
          {title}
        </P>
      </Panel>
      <Panel
        $colorIndex={colorIndex}
        $light
        size="small"
        mt="0px"
        mb="10px"
        style={{ padding: 10 }}
      >
        <div>{contentDescription}</div>
      </Panel>
    </React.Fragment>
  );
};

RawToolOutputNoCollapse.propTypes = {
  colorIndex: PropTypes.number.isRequired,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  contentTitle: PropTypes.string,
  contentDescription: PropTypes.node.isRequired,
  exampleContent1: PropTypes.node,
  exampleContent2: PropTypes.node,
  popoverContent: PropTypes.node,
};

RawToolOutputNoCollapse.defaultProps = {
  buttonText: null,
  title: null,
  contentTitle: null,
  exampleContent1: null,
  exampleContent2: null,
  popoverContent: null,
};

const ToolOutputNoCollapse = styled(RawToolOutputNoCollapse)`
  .hidden-button {
    background-color: transparent;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
`;

export default ToolOutputNoCollapse;
