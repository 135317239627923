import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const publicProgramsApi = createApi({
  reducerPath: 'publicProgramsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/public_programs/',
  }),
  endpoints: builder => ({
    // Returns all public programs
    getPrograms: builder.query({
      query: () => '',
    }),
    // Returns public program by program's id
    getProgramById: builder.query({
      query: programId => ({ url: programId }),
    }),
  }),
});

export const { useGetProgramsQuery, useGetProgramByIdQuery } = publicProgramsApi;
