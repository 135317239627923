import React from 'react';
import TranslationsContext from 'providers/translationsProvider';
import instance from 'services/api';
import { message } from 'antd';
import { NoConnectionMessage } from 'components/elements';

const NO_ERR = null;
const MESSAGES_DELAY = 1.5;

const isBridgeRequest = config =>
  config.baseURL.includes('bridgeforbillions') ||
  config.baseURL.includes('bfb') ||
  config.baseURL.includes('localhost');

export default function ApiMessageHandler() {
  const { t } = React.useContext(TranslationsContext);
  const [err, setError] = React.useState(null);

  React.useEffect(() => {
    message.config({
      top: 12,
      maxCount: 1,
      duration: 2,
    });
  }, []);

  // const DATA_NOT_LOADED = t('Error loading data');
  const DATA_NOT_SAVED = t('New data is not saved');
  const SERVER_ERR = t(
    'Please, refresh the page by clicking here and try again. If this problem persists, reach out to us via the chat.'
  );

  const errorMessageHandler = error => {
    let errorMessage = null;
    if (error && error.response && window.location.pathname.indexOf('login') === -1) {
      const responseError = error.response.data && error.response.data.detail;
      if (responseError) {
        if (
          error.response.config.url.includes('/public_programs/') ||
          error.response.config.url.includes('/demo-projects/') ||
          error.response.config.url.includes('/customizations/')
        ) {
          errorMessage = null;
        } else {
          errorMessage = `Error: ${responseError}`;
        }
      } else if (
        error.response.config.method === 'patch'
        // FIXME: add a customed message for the most relevant Post errors so it is not for users.
        // || error.response.config.method === 'post'
      ) {
        errorMessage = DATA_NOT_SAVED;
      }
      if (error.response.status > 499) {
        setError(SERVER_ERR);
      }
    }
    if (error && error.message === 'Network Error') {
      // This is a 500 error, but Django doesn't send CORS headers so it's a Network Error
      // https://github.com/adamchainz/django-cors-headers/issues/512
      setError(SERVER_ERR);
    }
    if (errorMessage) message.error(errorMessage, MESSAGES_DELAY * 2);
    throw error;
  };

  const responseMessageHandler = response => {
    if (
      response &&
      response.status &&
      response.status < 300 &&
      isBridgeRequest(response.config) &&
      (response.config.method === 'patch' || response.config.method === 'post') &&
      window.location.pathname.indexOf('login') === -1
    ) {
      setError(NO_ERR);
      setTimeout(() => {
        message.success(t('Data saved'));
      }, MESSAGES_DELAY / 2);
    }
    return response;
  };

  const requestMessageHandler = config => {
    if (isBridgeRequest(config) && (config.method === 'patch' || config.method === 'post')) {
      if (window.location.pathname.indexOf('login') === -1) {
        if (navigator.onLine) {
          message.loading(t('Saving...'), MESSAGES_DELAY);
        } else {
          message.error(t(DATA_NOT_SAVED), MESSAGES_DELAY);
        }
      }
    }
    return config;
  };

  // Add a response interceptor for success and error message handling
  instance.interceptors.request.use(requestMessageHandler);

  // Add a response interceptor for success and error message handling
  instance.interceptors.response.use(responseMessageHandler, errorMessageHandler);

  if (err) return <NoConnectionMessage message={t(err)} t={t} />;
  return null;
}
