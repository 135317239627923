import api from '../api';

const listChains = query => api.get('/api/chains/', { params: query });

const createChain = ({ productId, ...chain }) =>
  api.post(`/api/chains/?product=${productId}`, { ...chain });

const fetchChain = ({ id }) => api.get(`/api/chains/${id}/`);

const updateChain = ({ id, ...chain }) => api.patch(`/api/chains/${id}/`, { ...chain });

const deleteChain = ({ id }) => api.delete(`/api/chains/${id}/`);

export default {
  createChain,
  listChains,
  fetchChain,
  updateChain,
  deleteChain,
};
