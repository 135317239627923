import { Record } from 'immutable';
import ContentType from '../ContentType';

const SdgRecord = new Record({
  id: null,
  name: null,
  sdg: null,
  created: null,
  updated: null,
});

class Sdg extends SdgRecord {
  static contentType = ContentType.PARTNER;

  get id() {
    return this.get('id');
  }
}

export default Sdg;
