import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Selectors
import { selectors as userSelectors } from 'store/modules/user';

// Dependencies
import * as Sentry from '@sentry/react';

// components
import ErrorComponent from './components/ErrorComponent';
import { CONNECTIVITY_ERROR, DEFAULT_ERROR } from './components/ErrorComponent/constants';

const withErrorHandler = Component => {
  class WithErrorHandler extends React.Component {
    static propTypes = {
      userData: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isConnectivityError: false,
      };
    }

    onClose = () => {
      window.location.href = '/';
    };

    componentDidCatch(error, errorInfo) {
      const isConnectivityError = error.message.toLowerCase().includes('loading chunk');
      // Setting the state
      this.setState({
        error,
        isConnectivityError,
      });
      // Sending error to sentry
      if (!isConnectivityError) {
        Sentry.withScope(scope => {
          Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key]);
            scope.setUser({
              id: this.props.userData.get('id'),
              email: this.props.userData.get('username'),
            });
          });
          Sentry.captureException(error);
        });
      }
    }

    render() {
      const { isConnectivityError } = this.state;
      return this.state.error ? (
        <ErrorComponent errorType={isConnectivityError ? CONNECTIVITY_ERROR : DEFAULT_ERROR} />
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = state => ({
    userData: userSelectors.getUser(state),
  });

  WithErrorHandler.displayName = `withErrorHandler(${Component.displayName})`;
  return connect(mapStateToProps)(WithErrorHandler);
};

export default withErrorHandler;
