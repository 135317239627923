import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const programsDashboardApi = createApi({
  reducerPath: 'programsDashboardApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/programs-dashboard/',
  }),
  endpoints: builder => ({
    // Returns sections with programs in Es & Ms Dashboard
    getSections: builder.query({
      query: () => '',
    }),
  }),
});

export const { useGetSectionsQuery } = programsDashboardApi;
