// Libraries
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

// Dependencies
import { parrotTheme } from 'styles/themes';
import TranslationsContext from 'providers/translationsProvider';

// Assets
import ParrotbotAvatar from 'static/images/tools/avatar_parrotbot_aha.png';

// Components
import { Img } from 'components/elements';
import { P } from 'components/fontSystem';

export default function ParrobotIncompleteHelper() {
  const { t } = React.useContext(TranslationsContext);
  return (
    <ThemeProvider theme={parrotTheme}>
      <StyledDiv>
        <Img src={ParrotbotAvatar} style={{ width: '88px' }} />
        <div>
          <P $primary $mb0 $italic>
            {t(
              "Don't forget to fill this step for each one of your products/services for it to be marked as complete."
            )}
          </P>
        </div>
      </StyledDiv>
    </ThemeProvider>
  );
}

const StyledDiv = styled.div`
  display: flex;
  max-width: 820px;
  position: relative;
  top: 15px;
  z-index: 2;
  width: 90%;
  div {
    align-items: center;
    display: flex;
    position: relative;
    top: -15px;
    left: -15px;
    p {
      background-color: ${props => props.theme.primaryColorBgFade};
      border-radius: 4px;
      padding: 10px;
    }
    &:before {
      border-bottom: 8px solid transparent;
      border-right: 8px solid ${props => props.theme.primaryColorBgFade};
      border-top: 8px solid transparent;
      content: '';
      height: 0;
      width: 0;
    }
  }
`;
