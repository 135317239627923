import { Record } from 'immutable';
import ContentType from '../ContentType';

const PositioningRecord = new Record({
  id: null,
  contentType: ContentType.POSITIONING,
  name: '',
  positioning: false,
  rating: null,
  competitor: null,
  attribute: null,
  comments: [],

  created: null,
  updated: null,
});

class Positioning extends PositioningRecord {
  static contentType = ContentType.POSITIONING;

  get id() {
    return this.get('id');
  }
}

export default Positioning;
