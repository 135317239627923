import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const areasOfExpertiseApi = createApi({
  reducerPath: 'areasOfExpertiseApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/areas_expertise/',
  }),
  endpoints: builder => ({
    // Returns all areas of expertise
    getAreasOfExpertise: builder.query({
      query: () => '',
    }),
    // Returns areas of expertise by program's id
    getAreasOfExpertiseByProgramId: builder.query({
      query: programId => ({ params: programId }),
    }),
  }),
});

export const { useGetAreasOfExpertiseQuery, useGetAreasOfExpertiseByProgramIdQuery } =
  areasOfExpertiseApi;
