import styled, { css } from 'styled-components';
import { Slider as AntSlider } from 'antd';

const Slider = props => {
  const { colorIndex, ...rest } = props;

  return <StyledSlider colorIndex={colorIndex} {...rest} />;
};

export default Slider;

const containerColor = css`
  ${({ colorIndex, theme, useTheme }) => {
    if (useTheme) {
      return theme.primaryColor;
    }
    return colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#D9D9D9';
  }}
`;

const StyledSlider = styled(AntSlider)`
  &&& {
    .ant-slider-track {
      background-color: ${containerColor};
    }

    .ant-slider-dot {
      &.ant-slider-dot-active {
        border-color: ${containerColor};
      }
    }

    .ant-slider-handle {
      &:after {
        box-shadow: 0 0 0 3px ${containerColor};
      }
    }
    .ant-slider-handle:focus {
      border-color: ${containerColor};
    }
  }
`;
