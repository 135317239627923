import api from '../api';

const createSegment = ({ project, product, ...segment }) =>
  api.post(`api/projects/${project}/products/${product}/segments/`, {
    ...segment,
  });

const deleteSegment = ({ project, product, segmentId }) =>
  api.delete(`api/projects/${project}/products/${product}/segments/${segmentId}/`);

const fetchSegment = ({ project, product, id }) =>
  api.get(`api/projects/${project}/products/${product}/segments/${id}`);

const listSegments = ({ project, product }) =>
  api.get(`api/projects/${project}/products/${product}/segments/`);

const listAllSegments = ({ project }) =>
  api.get('api/segments/', {
    params: {
      project,
    },
  });

const updateSegment = ({ project, product, segmentId, ...updatedSegment }) =>
  api.patch(`api/projects/${project}/products/${product}/segments/${segmentId}/`, {
    ...updatedSegment,
  });

export default {
  createSegment,
  deleteSegment,
  fetchSegment,
  listSegments,
  listAllSegments,
  updateSegment,
};
