import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/ImpactPlan/relationship';
import { types as commentTypes } from 'store/modules/comment';
import serviceRelationship from 'services/ImpactPlan/relationship';
import Relationship from 'models/ImpactPlan/Relationship';

export function* createRelationship(action) {
  const { system, ...relationship } = action.payload;
  try {
    const response = yield call(serviceRelationship.createRelationship, {
      system,
      ...relationship,
    });
    yield put(
      actions.createRelationshipSucceeded({
        system,
        ...response.data,
      })
    );
  } catch (e) {
    yield put(
      actions.createRelationshipFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteRelationship(action) {
  const { id, system } = action.payload;
  try {
    yield call(serviceRelationship.deleteRelationship, { id });
    yield put(actions.deleteRelationshipSucceeded({ id, system }));
  } catch (e) {
    yield put(
      actions.deleteRelationshipFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchRelationship(action) {
  const { id } = action.payload;
  try {
    const relationship = yield call(serviceRelationship.fetchRelationship, {
      id,
    });
    yield put(actions.fetchRelationshipSucceeded(relationship.data));
  } catch (e) {
    yield put(actions.fetchRelationshipFailed({ message: e.message }));
  }
}

export function* listRelationships(action) {
  const { system } = action.payload;
  try {
    const relationships = yield call(serviceRelationship.listRelationships, {
      system,
    });
    yield put(
      actions.listRelationshipsSucceeded({
        results: relationships.data,
        system,
      })
    );
  } catch (e) {
    yield put(actions.listRelationshipsFailed({ message: e.message }));
  }
}

export function* updateRelationship(action) {
  const { id, ...relationship } = action.payload;
  try {
    const updated = yield call(serviceRelationship.updateRelationship, {
      id,
      ...relationship,
    });
    yield put(actions.updateRelationshipSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateRelationshipFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Relationship.contentType) {
    yield put(
      actions.fetchRelationship({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* relationshipSaga() {
  yield all([
    takeLatest(types.RELATIONSHIP_CREATE, createRelationship),
    takeLatest(types.RELATIONSHIP_DELETE, deleteRelationship),
    takeLatest(types.RELATIONSHIP_FETCH, fetchRelationship),
    takeLatest(types.RELATIONSHIP_UPDATE, updateRelationship),
    takeEvery(types.RELATIONSHIPS_LIST, listRelationships),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default relationshipSaga;
