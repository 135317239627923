const PROJECTION_CLEAR_ERROR = 'bfb/financialProjections/PROJECTION_CLEAR_ERROR';
const PROJECTIONS_LIST = 'bfb/financialProjections/PROJECTIONS_LIST';
const PROJECTIONS_LIST_SUCCEEDED = 'bfb/financialProjections/PROJECTIONS_LIST_SUCCEEDED';
const PROJECTIONS_LIST_FAILED = 'bfb/financialProjections/PROJECTIONS_LIST_FAILED';

export default {
  PROJECTIONS_LIST,
  PROJECTIONS_LIST_SUCCEEDED,
  PROJECTIONS_LIST_FAILED,
  PROJECTION_CLEAR_ERROR,
};
