const INITIATIVE_CLEAR_ERROR = 'bfb/INITIATIVE/INITIATIVE_CLEAR_ERROR';
const INITIATIVES_LIST = 'bfb/INITIATIVE/INITIATIVES_LIST';
const INITIATIVES_LIST_SUCCEEDED = 'bfb/INITIATIVE/INITIATIVES_LIST_SUCCEEDED';
const INITIATIVES_LIST_FAILED = 'bfb/INITIATIVE/INITIATIVES_LIST_FAILED';
const INITIATIVE_CREATE = 'bfb/INITIATIVE/INITIATIVE_CREATE';
const INITIATIVE_CREATE_SUCCEEDED = 'bfb/INITIATIVE/INITIATIVE_CREATE_SUCCEEDED';
const INITIATIVE_CREATE_FAILED = 'bfb/INITIATIVE/INITIATIVE_CREATE_FAILED';
const INITIATIVE_DELETE = 'bfb/INITIATIVE/INITIATIVE_DELETE';
const INITIATIVE_DELETE_SUCCEEDED = 'bfb/INITIATIVE/INITIATIVE_DELETE_SUCCEEDED';
const INITIATIVE_DELETE_FAILED = 'bfb/INITIATIVE/INITIATIVE_DELETE_FAILED';
const INITIATIVE_FETCH = 'bfb/INITIATIVE/INITIATIVE_FETCH';
const INITIATIVE_FETCH_SUCCEEDED = 'bfb/INITIATIVE/INITIATIVE_FETCH_SUCCEEDED';
const INITIATIVE_FETCH_FAILED = 'bfb/INITIATIVE/INITIATIVE_FETCH_FAILED';
const INITIATIVE_UPDATE = 'bfb/INITIATIVE/INITIATIVE_UPDATE';
const INITIATIVE_UPDATE_SUCCEEDED = 'bfb/INITIATIVE/INITIATIVE_UPDATE_SUCCEEDED';
const INITIATIVE_UPDATE_FAILED = 'bfb/INITIATIVE/INITIATIVE_UPDATE_FAILED';

export default {
  INITIATIVES_LIST,
  INITIATIVES_LIST_SUCCEEDED,
  INITIATIVES_LIST_FAILED,
  INITIATIVE_CLEAR_ERROR,
  INITIATIVE_CREATE,
  INITIATIVE_CREATE_SUCCEEDED,
  INITIATIVE_CREATE_FAILED,
  INITIATIVE_DELETE,
  INITIATIVE_DELETE_SUCCEEDED,
  INITIATIVE_DELETE_FAILED,
  INITIATIVE_FETCH,
  INITIATIVE_FETCH_SUCCEEDED,
  INITIATIVE_FETCH_FAILED,
  INITIATIVE_UPDATE,
  INITIATIVE_UPDATE_SUCCEEDED,
  INITIATIVE_UPDATE_FAILED,
};
