import { normalize, schema } from 'normalizr';

const linkSchema = new schema.Entity('link');
const materialSchema = new schema.Entity('material', {
  links: [linkSchema],
});

const normalizeMaterial = data => {
  // https://redux.js.org/recipes/structuringreducers/normalizingstateshape#relationships-and-tables
  const { result, entities } = normalize(data, materialSchema);
  return {
    material: entities.material[result],
    links: entities.link || [],
  };
};

const normalizeMaterials = data => {
  if (!data.length) return { materials: [], links: [] };
  const {
    entities: { material, link = {} },
  } = normalize(data, [materialSchema]);
  return {
    materials: Object.values(material),
    links: Object.values(link),
  };
};

export { normalizeMaterial, normalizeMaterials };
