import { Record } from 'immutable';

const OrganizationRecord = new Record({
  id: null,
  name: '',
  admins: null,
  role: null,
  created: null,
  updated: null,
  status: null,
});

class Organization extends OrganizationRecord {
  static PENDING = 0;
  static ACTIVE = 1;
  static DELETED = 2;

  static ROLE_SUPER_ADMIN = 0;
  static ROLE_ADMIN = 1;

  get id() {
    return this.get('id');
  }
}

export default Organization;
