import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const exploreProjectsApi = createApi({
  reducerPath: 'exploreProjectsApi',
  baseQuery: axiosBaseQuery({ apiPath: '/api/explore_projects/' }),
  endpoints: builder => ({
    // Fetch ALL Projects
    listExploreProjects: builder.query({
      query: params => ({ params }),
    }),
    // Get a Project by id
    getProjectById: builder.query({
      query: programId => ({ url: programId }),
    }),
  }),
});

export const { useListExploreProjectsQuery, useGetProjectByIdQuery } = exploreProjectsApi;
