import { types } from 'store/modules/product';

const clearError = () => ({
  type: types.PRODUCT_CLEAR_ERROR,
});

const createProduct = ({ project, ...product }) => ({
  type: types.PRODUCT_CREATE,
  payload: { project, ...product },
});

const createProductSucceeded = ({ id, project, ...product }) => ({
  type: types.PRODUCT_CREATE_SUCCEEDED,
  payload: { id, ...product },
});

const createProductFailed = ({ id }) => ({
  type: types.PRODUCT_CREATE_FAILED,
  payload: { id },
});

const deleteProduct = ({ project, productId }) => ({
  type: types.PRODUCT_DELETE,
  payload: { project, productId },
});

const deleteProductSucceeded = ({ productId }) => ({
  type: types.PRODUCT_DELETE_SUCCEEDED,
  payload: { id: productId },
});

const deleteProductFailed = ({ message }) => ({
  type: types.PRODUCT_DELETE_FAILED,
  payload: message,
});

const fetchProduct = ({ id, projectId }) => ({
  type: types.PRODUCT_FETCH,
  payload: { id, projectId },
});

const fetchProductSucceeded = ({ id, ...product }) => ({
  type: types.PRODUCT_FETCH_SUCCEEDED,
  payload: { id, ...product },
});

const fetchProductFailed = ({ message }) => ({
  type: types.PRODUCT_FETCH_FAILED,
  payload: { message },
});

const listProducts = ({ project }) => ({
  type: types.PRODUCTS_LIST,
  payload: { project },
});

const listProductsSucceeded = payload => ({
  type: types.PRODUCTS_LIST_SUCCEEDED,
  payload,
});

const listProductsFailed = error => ({
  type: types.PRODUCTS_LIST_FAILED,
  payload: error,
});

const updateProduct = ({ project, productId, ...product }, callback) => ({
  type: types.PRODUCT_UPDATE,
  payload: { project, productId, ...product },
  callback,
});

const updateProductSucceeded = ({ id, ...product }) => ({
  type: types.PRODUCT_UPDATE_SUCCEEDED,
  payload: { id, ...product },
});

const updateProductFailed = ({ message }) => ({
  type: types.PRODUCT_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createProduct,
  createProductFailed,
  createProductSucceeded,
  deleteProduct,
  deleteProductFailed,
  deleteProductSucceeded,
  fetchProduct,
  fetchProductFailed,
  fetchProductSucceeded,
  listProducts,
  listProductsFailed,
  listProductsSucceeded,
  updateProduct,
  updateProductFailed,
  updateProductSucceeded,
};
