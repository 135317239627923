// Define keys to be stored in local storage
const entAcceptedPopup = 'ent-accepted-popup';
const entAppRejectedPopup = 'entrepreneur-app-rejected-popup';
const entInterviewAcceptedPopup = 'entrepreneur-interview-accepted-popup';
const entInterviewRejectedPopup = 'entrepreneur-interview-rejected-popup';
const mentorAcceptedPopup = 'mentor-accepted-popup';
const mentorAppRejectedPopup = 'mentor-app-rejected-popup';
const customization = 'customization';
const subscriptionType = 'ust'; // user subscription type (obfuscated so they don't know what that key is)
const HAS_SEEN_MODULE_6_TUTORIAL = 'has_seen_module_6_tutorial';
const WORKSHEET_RESPONSES_REMINDER_SHOWN = 'worksheet_responses_reminder_shown';

// This has been deprecated, use the useLocalStorage hook
const setLocalStorageItem = (key, value) => localStorage.setItem(key, value);
const getLocalStorageItem = key => localStorage.getItem(key);

export {
  customization,
  entAcceptedPopup,
  entAppRejectedPopup,
  entInterviewAcceptedPopup,
  entInterviewRejectedPopup,
  getLocalStorageItem,
  mentorAcceptedPopup,
  mentorAppRejectedPopup,
  setLocalStorageItem,
  subscriptionType,
  HAS_SEEN_MODULE_6_TUTORIAL,
  WORKSHEET_RESPONSES_REMINDER_SHOWN,
};
