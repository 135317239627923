import { Record } from 'immutable';

const ResourceRecord = Record({
  id: null,
  name: null,
  system: null,
  created: null,
  updated: null,
  comments: [],
  contentType: 'bfb_impact_plan.resource',
});

class Resource extends ResourceRecord {
  static contentType = 'bfb_impact_plan.resource';

  get getId() {
    return this.get('id');
  }
}

export default Resource;
