import * as reduxHelper from 'store/utils/reduxHelper';
import MemberExecutiveSummary from 'models/ExecutiveSummary/memberExecutiveSummary';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { list: [] },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MEMBER_EXECUTIVE_SUMMARY_LIST:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.MEMBER_EXECUTIVE_SUMMARY_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            list: action.payload.map(member => new MemberExecutiveSummary(member)),
          },
        })
      );
    case types.MEMBER_EXECUTIVE_SUMMARY_DELETE_SUCCEEDED: {
      const { id } = action.payload;
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            list: state.data.list.filter(member => member.id !== id),
          },
        })
      );
    }
    case types.MEMBER_EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED: {
      const { id } = action.payload;
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            list: state.data.list.map(member =>
              member.id === id ? new MemberExecutiveSummary(action.payload) : member
            ),
          },
        })
      );
    }
    case types.MEMBER_EXECUTIVE_SUMMARY_CREATE_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            list: [...state.data.list, new MemberExecutiveSummary(action.payload)],
          },
        })
      );
    case types.MEMBER_EXECUTIVE_SUMMARY_CREATE_FAILED:
    case types.MEMBER_EXECUTIVE_SUMMARY_UPDATE_FAILED:
    case types.MEMBER_EXECUTIVE_SUMMARY_LIST_FAILED:
    case types.MEMBER_EXECUTIVE_SUMMARY_DELETE_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
