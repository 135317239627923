const BENEFICIARY_CLEAR_ERROR = 'bfb/BENEFICIARY/BENEFICIARY_CLEAR_ERROR';
const BENEFICIARIES_LIST = 'bfb/BENEFICIARY/BENEFICIARIES_LIST';
const BENEFICIARIES_LIST_SUCCEEDED = 'bfb/BENEFICIARY/BENEFICIARIES_LIST_SUCCEEDED';
const BENEFICIARIES_LIST_FAILED = 'bfb/BENEFICIARY/BENEFICIARIES_LIST_FAILED';
const BENEFICIARY_CREATE = 'bfb/BENEFICIARY/BENEFICIARY_CREATE';
const BENEFICIARY_CREATE_SUCCEEDED = 'bfb/BENEFICIARY/BENEFICIARY_CREATE_SUCCEEDED';
const BENEFICIARY_CREATE_FAILED = 'bfb/BENEFICIARY/BENEFICIARY_CREATE_FAILED';
const BENEFICIARY_DELETE = 'bfb/BENEFICIARY/BENEFICIARY_DELETE';
const BENEFICIARY_DELETE_SUCCEEDED = 'bfb/BENEFICIARY/BENEFICIARY_DELETE_SUCCEEDED';
const BENEFICIARY_DELETE_FAILED = 'bfb/BENEFICIARY/BENEFICIARY_DELETE_FAILED';
const BENEFICIARY_FETCH = 'bfb/BENEFICIARY/BENEFICIARY_FETCH';
const BENEFICIARY_FETCH_SUCCEEDED = 'bfb/BENEFICIARY/BENEFICIARY_FETCH_SUCCEEDED';
const BENEFICIARY_FETCH_FAILED = 'bfb/BENEFICIARY/BENEFICIARY_FETCH_FAILED';
const BENEFICIARY_UPDATE = 'bfb/BENEFICIARY/BENEFICIARY_UPDATE';
const BENEFICIARY_UPDATE_SUCCEEDED = 'bfb/BENEFICIARY/BENEFICIARY_UPDATE_SUCCEEDED';
const BENEFICIARY_UPDATE_FAILED = 'bfb/BENEFICIARY/BENEFICIARY_UPDATE_FAILED';

export default {
  BENEFICIARIES_LIST,
  BENEFICIARIES_LIST_SUCCEEDED,
  BENEFICIARIES_LIST_FAILED,
  BENEFICIARY_CLEAR_ERROR,
  BENEFICIARY_CREATE,
  BENEFICIARY_CREATE_SUCCEEDED,
  BENEFICIARY_CREATE_FAILED,
  BENEFICIARY_DELETE,
  BENEFICIARY_DELETE_SUCCEEDED,
  BENEFICIARY_DELETE_FAILED,
  BENEFICIARY_FETCH,
  BENEFICIARY_FETCH_SUCCEEDED,
  BENEFICIARY_FETCH_FAILED,
  BENEFICIARY_UPDATE,
  BENEFICIARY_UPDATE_SUCCEEDED,
  BENEFICIARY_UPDATE_FAILED,
};
