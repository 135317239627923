import { Record } from 'immutable';

const ProgramToolRecord = new Record({
  id: null,
  program: null,
  tool: null,
  slug: null,
  order: null,
  name: null,
  start_date: null,
  end_date: null,
  intro_video_url: '',
  intro_video_url_en: '',
  intro_video_url_es: '',
  intro_video_url_fr: '',
  intro_video_url_pt: '',
  intro_video_url_pt_br: '',
  intro_video_url_hy: '',
  intro_video_url_it: '',
  intro_video_url_sk: '',
  intro_video_url_lv: '',
  intro_video_url_vi: '',
  intro_video_url_th: '',
  intro_video_url_km: '',
  intro_video_url_lo: '',
});

class ProgramTool extends ProgramToolRecord {
  get tool() {
    return this.get('tool');
  }
}

export default ProgramTool;
