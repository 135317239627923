import { createSelector } from 'reselect';

const getState = state => state.organizationalRole;

const getRawOrganizationalRoles = createSelector([getState], state => state.get('data'));

const getOrganizationalRolesById = createSelector([getRawOrganizationalRoles], state =>
  state.get('byId')
);

const getOrganizationalRoleOfId = createSelector(
  [getOrganizationalRolesById],
  state => id => state.toJS()[id]
);

const getOrganizationalRoles = createSelector([getOrganizationalRolesById], state =>
  state.valueSeq().toJS()
);

const getOrganizationalRolesOfYear = year =>
  createSelector([getOrganizationalRolesById], state =>
    state
      .valueSeq()
      .toJS()
      .filter(organizationalRole => organizationalRole.year === year)
  );

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getOrganizationalRoles,
  getOrganizationalRolesOfYear,
  getOrganizationalRolesById,
  getOrganizationalRoleOfId,
  getErrorMessage,
};
