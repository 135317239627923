import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RankSquare from 'components/ProgramManagement/Matching/RankSquare';
import { Img, MatchAffinity, InfoBubble } from 'components/elements';
import { Card as AntCard, Col, Collapse } from 'antd';
import { H5, P } from 'components/fontSystem';
import getDefaultProfileByUserGender from 'helper/User/getDefaultProfileByUserGender';
import PotentialMatch from 'components/ProgramManagement/Matching/RankingAndMatchTable/PotentialMatch.js';
import { potentialMatchOptions } from 'models/Matching/Interestship';
import bfbTheme from 'styles/b4b-theme';
import { Row } from 'components/layout';

function MatchingCard(props) {
  const {
    application,
    project,
    invite,
    t,
    type,
    onInvitesUpdate,
    agreementSent,
    isVacancyFull,
    isInModal = false,
  } = props;

  // support for taking the invites from mentors and from mentorApplications
  const mentorInvites = invite.mentor_invites ?? invite.invites ?? [];
  const capacity = invite.mentor
    ? invite.mentor.ranking_max_projects -
      (invite.mentor.mentees + invite.mentor.invites_pending_sign)
    : (invite.ranking_max_projects - (invite.mentees + invite.invites_pending_sign) ?? '-');
  const mentees = invite.mentor.mentees ?? invite.mentees ?? '-';
  const rankingMaxProjects =
    invite.mentor.ranking_max_projects ?? invite.ranking_max_projects ?? '-';
  const openTocoMentor =
    invite.mentor.ranking_open_to_comentor || invite.ranking_open_to_comentor ? t('Yes') : t('No');
  // TODO: currently we don't have invite.mentor.ranking_reasons in the JSON response
  const rankingReasons = invite.mentor.ranking_reasons ?? invite.ranking_reasons;

  const getTagOption = level => potentialMatchOptions.find(option => option.value === level);
  const getTagOptionText = level => {
    if ([undefined, null].includes(level)) return '-';
    return getTagOption(level).text;
  };
  const [currentValue, setCurrentValue] = useState(getTagOption(invite.potential_match_level));

  useEffect(() => {
    setCurrentValue(getTagOption(invite.potential_match_level));
  }, [invite.potential_match_level]);

  const owner = invite.member ?? invite.mentor;
  const profilePicture = user => {
    if (user && user.picture && user.picture.photo) {
      return user.picture.photo;
    }
    return getDefaultProfileByUserGender(user);
  };

  const { Panel } = Collapse;

  function LinePanel() {
    return (
      <MentorDetails>
        <div>
          <RowP>
            {t('CAPACITY')}
            <span>{capacity}</span>
          </RowP>
          <RowP>
            {t('MATCHED')}
            <span>{mentees}</span>
          </RowP>
          <RowP>
            {t('WILLING TO MENTOR')}
            <span>{rankingMaxProjects}</span>
          </RowP>
          <RowP>
            {t('CO-MENTOR?')}
            <span>
              <span>{openTocoMentor}</span>
              <InfoBubble
                trigger="hover"
                infotext={t(
                  'The Co-Mentor field indicates whether the mentor agrees to participate in the mentoring process alongside another mentor for a specific project.'
                )}
                style={{ color: '#B3B3B3' }}
              />
            </span>
          </RowP>
        </div>
        {!isInModal && <Show>{t("Show mentor's ranking")}</Show>}
      </MentorDetails>
    );
  }

  return (
    <MatchingCardWrapper type={type}>
      <Card>
        <div className="leftCard">
          <MatchAffinity value={invite.suggested_match_level} />
          <RankSquare
            value={invite.e_ranking}
            $red={invite.e_meet_status === 3}
            $filled
            forWho="project"
          />
          <RankSquare
            value={invite.m_ranking}
            $red={invite.m_meet_status === 3}
            $filled
            forWho="mentor"
          />
          <Img className="profile-pic" src={profilePicture(owner)} height="28px" width="28px" />
          <MentorName>{`${owner.first_name} ${owner.last_name}`}</MentorName>
        </div>
        {type === 'ranked' && !isInModal && (
          <div>
            <PotentialMatch
              currentValue={currentValue}
              invite={invite}
              onInvitesUpdate={onInvitesUpdate}
              agreementSent={agreementSent}
              isVacancyFull={isVacancyFull}
              application={application}
            />
          </div>
        )}
      </Card>
      <StyledCollapse defaultActiveKey={isInModal ? '1' : null}>
        <Panel header={<LinePanel />} key={1} showArrow={!isInModal} forceRender={isInModal}>
          <Row>
            <Col md={isInModal ? 24 : 16}>
              <Subtitle>{t("MENTOR'S RANKING")}</Subtitle>
              {mentorInvites.map(mentorInvite => (
                <InviteWrapper isThisInvite={project === mentorInvite.project.id}>
                  <InviteUpSide>
                    <RankSquare
                      value={mentorInvite.m_ranking}
                      $red={mentorInvite.m_meet_status === 3}
                      $filled={project === mentorInvite.project.id}
                      $border={project !== mentorInvite.project.id}
                      forWho="mentor"
                    />
                    <RankSquare
                      value={mentorInvite.e_ranking}
                      $red={mentorInvite.e_meet_status === 3}
                      $filled={project === mentorInvite.project.id}
                      $border={project !== mentorInvite.project.id}
                      forWho="project"
                    />
                    <ProjectName>{mentorInvite.project.name}</ProjectName>
                  </InviteUpSide>
                  <InviteBottomSide>
                    <RowP>
                      {t('NUMBER OF INVITES')}
                      <span>{mentorInvite.number_of_invites}</span>
                    </RowP>
                    <RowP>
                      {t('POTENTIAL MATCH')}
                      <span>{getTagOptionText(mentorInvite.potential_match_level)}</span>
                    </RowP>
                  </InviteBottomSide>
                </InviteWrapper>
              ))}
            </Col>
            <Col md={isInModal ? 24 : 8}>
              <Subtitle>{t("MENTOR'S REASONS OF RANKING")}</Subtitle>
              <P $italic>{rankingReasons ?? t('No ranking data received yet.')}</P>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </MatchingCardWrapper>
  );
}

const InviteWrapper = styled.div`
  padding: 10px 15px;
  border-radius: 4px;
  margin: 5px 0;
  border: 1px solid ${({ isThisInvite }) => (isThisInvite ? bfbTheme.memberGreen : '#D9D9D9')};
`;

const InviteBottomSide = styled.div`
  display: flex;
  padding-left: 65px;
`;

const InviteUpSide = styled.div`
  display: flex;
  gap: 5px;
`;

const ProjectName = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${bfbTheme.memberGreen};
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
`;

const Subtitle = styled(H5)`
  color: #999999;
  font-size: 15px;
  margin: 20px 0 5px;
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    border: none;
    width: calc(100% - 30px);
    margin: 10px 15px;

    .ant-collapse {
      padding: 0;
    }

    .ant-collapse-header {
      align-items: center;
      border: none;
      color: ${({ theme }) => theme.hyperlink};
      font-weight: bold;
      padding: 0;

      .ant-collapse-arrow {
        padding-left: 5px;
      }
    }

    .ant-collapse-content-active {
      border: none;
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-item {
      border-bottom: none;
    }
  }
`;

const RowP = styled.p`
  &&& {
    font-size: 12px;
    font-weight: bold;
    color: #999999;
    margin: 0;

    span {
      color: #101010;
      font-weight: 400;
      padding-left: 5px;
      padding-right: 10px;
    }
  }
`;

const MentorName = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${bfbTheme.mainColor};
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
`;

const Card = styled(AntCard)`
  height: 50px;
  width: 100%;
  border-radius: 4px;
  border: none;
  background-color: #ffffff;
  cursor: default;
  padding: 10px 15px;
  margin: 2px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .leftCard {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row !important;
    gap: 0 8px;
  }
  .ant-card-body {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }
`;

const MatchingCardWrapper = styled.div`
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  margin: ${({ type }) => (type === 'manual' ? '10px 0' : '20px 20px 20px 0')};
  flex-direction: column;
  width: 100%;
`;

const Show = styled.span`
  color: ${bfbTheme.hyperlink};
  font-size: 14px;
  font-weight: 400;
  padding-right: 15px;
`;

const MentorDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  color: #999999;
  font-size: 12px;
  height: 30px;
  width: 98%;
  background-color: #fafafa;
  padding: 5px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;

    p {
      margin: 0;
    }
  }
`;

MatchingCard.propTypes = {
  invite: PropTypes.instanceOf(Object).isRequired,
  project: PropTypes.number.isRequired,
  isInModal: PropTypes.bool,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  agreementSent: PropTypes.bool.isRequired,
  onInvitesUpdate: PropTypes.func.isRequired,
  isVacancyFull: PropTypes.bool.isRequired,
  application: PropTypes.instanceOf(Object).isRequired,
};

export default MatchingCard;
