import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import { Tool } from 'models/Tools';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, sorted: [] },
    errorMessage: '',
    updatePending: false,
  }),
});

const sortByOrder = (a, b) => a.order - b.order;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.TOOLS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, tool) => ({ ...obj, [tool.id]: new Tool(tool) }),
              {}
            ),
            sorted: action.payload.results.sort(sortByOrder),
          },
        })
      );
    case types.TOOL_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.TOOL_UPDATE_SUCCEEDED: {
      const prevTool = state
        .get('data')
        .get('byId')
        .get(String(action.payload.programToolId))
        .toJS();
      // eslint-disable-next-line max-len
      const newTool = new Tool({
        ...prevTool,
        ...action.payload,
        id: action.payload.programToolId,
      });
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.programToolId.toString(), newTool),
            sorted: state
              .get('data')
              .get('sorted')
              .map(tool => (tool.id === action.payload.programToolId ? newTool : tool)),
          },
        })
      );
    }
    case types.TOOL_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.TOOL_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            sorted: state
              .get('data')
              .get('sorted')
              .filter(tool => tool.id !== action.payload.id),
          },
        })
      );
    case types.TOOL_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.TOOL_FETCH_FAILED:
    case types.TOOLS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
