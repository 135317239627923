import { types } from 'store/modules/comment';

const createComment = ({ message, item, contentType, objectId, ...rest }, callback) => ({
  type: types.COMMENT_CREATE,
  payload: { message, item, contentType, objectId, ...rest },
  callback,
});

const createCommentSucceeded = ({ id, message, item, contentType, object, ...comment }) => ({
  type: types.COMMENT_CREATE_SUCCEEDED,
  payload: { id, message, item, contentType, object, ...comment },
});

const createCommentFailed = ({ message }) => ({
  type: types.COMMENT_CREATE_FAILED,
  payload: message,
});

const deleteComment = payload => ({
  type: types.COMMENT_DELETE,
  payload,
});

const deleteCommentSucceeded = payload => ({
  type: types.COMMENT_DELETE_SUCCEEDED,
  payload,
});

const deleteCommentFailed = ({ message }) => ({
  type: types.COMMENT_DELETE_FAILED,
  payload: message,
});

const editComment = ({ message, item, contentType, objectId, ...rest }, callback) => ({
  type: types.COMMENT_EDIT,
  payload: { message, item, contentType, objectId, ...rest },
  callback,
});

const editCommentSucceeded = ({ id, message, item, contentType, object, ...comment }) => ({
  type: types.COMMENT_EDIT_SUCCEEDED,
  payload: { id, message, item, contentType, object, ...comment },
});

const editCommentFailed = ({ message }) => ({
  type: types.COMMENT_EDIT_FAILED,
  payload: message,
});

const listComments = query => ({
  type: types.COMMENT_LIST,
  payload: query,
});

const listCommentsSucceeded = ({ data }) => ({
  type: types.COMMENT_LIST_SUCCEEDED,
  payload: data,
});

const listCommentsFailed = ({ message }) => ({
  type: types.COMMENT_LIST_FAILED,
  payload: message,
});

const toggleShow = () => ({
  type: types.COMMENT_TOGGLE_SHOW,
});

const hideComments = () => ({
  type: types.COMMENT_TOGGLE_SHOW,
  payload: { show: false },
});

const selectContentObject = ({ id, contentType, item, ...rest }) => ({
  type: types.COMMENT_SELECT_CONTENT_OBJECT,
  payload: { id, contentType, item, ...rest },
});

export default {
  createComment,
  createCommentFailed,
  createCommentSucceeded,
  deleteComment,
  deleteCommentSucceeded,
  deleteCommentFailed,
  editComment,
  editCommentSucceeded,
  editCommentFailed,
  hideComments,
  listComments,
  listCommentsFailed,
  listCommentsSucceeded,
  selectContentObject,
  toggleShow,
};
