import * as reduxHelper from 'store/utils/reduxHelper';
import { Step } from 'models/Tools';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROGRAM_STEPS_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, step) => ({ ...obj, [step.id]: new Step(step) }),
              {}
            ),
            byTool: action.payload.results.reduce((obj, step) => {
              const toolSteps = obj[step.tool] || [];
              return {
                ...obj,
                [step.tool]: [...toolSteps, new Step(step)],
              };
            }, {}),
          },
        })
      );
    case types.PROGRAM_STEPS_LIST_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
