// Libraries
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Select as AntSelect } from 'antd';

// Dependecies
import bfbTheme from 'styles/b4b-theme';

// This component should not be used in the newly built features as Ant doesn't support children inside select.
// Use the new "elements/Select" instead.

const SelectOld = props => {
  const { children, options, className, onBlur, onChange, name, ...rest } = props;

  const onBlurHandler = (value, option) => {
    const event = {
      target: { name: name, value, ...option },
    };

    onBlur ? onBlur(event) : null;
  };

  const onChangeHandler = (value, option) => {
    const event = {
      target: { name: name, value, ...option },
    };

    onChange ? onChange(event) : null;
    onBlur && document.activeElement ? document.activeElement.blur() : null;
  };

  const optionsJSX =
    options &&
    options.map(option => (
      <AntSelect.Option
        disabled={option.disabled}
        key={option.value !== undefined ? option.value : option.id}
        value={option.value !== undefined ? option.value : option.slug}
      >
        {option.text || option.name}
      </AntSelect.Option>
    ));

  return (
    <StyledSelect className={className} onBlur={onBlurHandler} onChange={onChangeHandler} {...rest}>
      {optionsJSX || children}
    </StyledSelect>
  );
};

const StyledSelect = styled(AntSelect)`
  &&& {
    &.languages-select {
      .ant-select-selector {
        background-color: rgba(0, 255, 175, 0.2);
        border: 0;
      }

      .ant-select-item-option-selected {
        background-color: rgba(0, 255, 175, 0.2);
      }
    }

    .ant-select-selector {
      ${props =>
        !props.bordered &&
        css`
          border: 0;
          border-bottom: 1px solid ${bfbTheme.placeholderGrey};
          padding: 0;
          border-radius: 0;
        `}
    }

    .ant-select-selection-placeholder {
      color: ${bfbTheme.grey};
      font-style: italic;
      font-weight: 100;
      inset-inline-start: 0;
      inset-inline-end: 0;
    }

    .ant-select-arrow {
      color: black;
    }
  }
`;

SelectOld.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array),
};

SelectOld.defaultProps = {
  children: null,
  onBlur: null,
  onChange: null,
  options: null,
  name: null,
  className: '',
};

SelectOld.Option = AntSelect.Option;

export default SelectOld;
