const RISK_CATEGORY_CLEAR_ERROR = 'bfb/growthPlan/growthRisk/RISK_CATEGORY_CLEAR_ERROR';
const RISK_CATEGORIES_LIST = 'bfb/growthPlan/growthRisk/RISK_CATEGORIES_LIST';
const RISK_CATEGORIES_LIST_SUCCEEDED = 'bfb/growthPlan/growthRisk/RISK_CATEGORIES_LIST_SUCCEEDED';
const RISK_CATEGORIES_LIST_FAILED = 'bfb/growthPlan/growthRisk/RISK_CATEGORIES_LIST_FAILED';
const RISK_CATEGORY_FETCH = 'bfb/growthPlan/growthRisk/RISK_CATEGORY_FETCH';
const RISK_CATEGORY_FETCH_SUCCEEDED = 'bfb/growthPlan/growthRisk/RISK_CATEGORY_FETCH_SUCCEEDED';
const RISK_CATEGORY_FETCH_FAILED = 'bfb/growthPlan/growthRisk/RISK_CATEGORY_FETCH_FAILED';

export default {
  RISK_CATEGORIES_LIST,
  RISK_CATEGORIES_LIST_SUCCEEDED,
  RISK_CATEGORIES_LIST_FAILED,
  RISK_CATEGORY_CLEAR_ERROR,
  RISK_CATEGORY_FETCH,
  RISK_CATEGORY_FETCH_SUCCEEDED,
  RISK_CATEGORY_FETCH_FAILED,
};
