import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from 'components/elements/MultiSelect';
import APIComponent from 'components/elements/APIComponent';
import TranslationsContext from 'providers/translationsProvider';

class APIMultiSelect extends APIComponent {
  static contextType = TranslationsContext;
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
  };

  render() {
    let { results } = this.state;
    const { t } = this.context;
    const { extrahelp, initialValue, ...rest } = this.props;
    results = results.sort((a, b) => this.getText(a).localeCompare(this.getText(b)));
    return (
      <React.Fragment>
        {extrahelp && <label style={{ fontWeight: 600, color: '#999999' }}>{extrahelp}</label>}{' '}
        {/* eslint-disable-line */}
        <MultiSelect
          defaultValue={initialValue}
          optionFilterProp={this.getText ? 'text' : 'value'}
          {...rest}
        >
          {results.map(result => (
            <MultiSelect.Option
              key={this.getKey(result)}
              value={this.getValue(result)}
              text={this.getText && t(this.getText(result))}
            >
              {this.getIcon && this.getIcon(result)}
              {t(this.getText(result))}
            </MultiSelect.Option>
          ))}
        </MultiSelect>
      </React.Fragment>
    );
  }
}

export default APIMultiSelect;
