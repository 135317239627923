// Libraries
import styled, { css } from 'styled-components';

// Dependencies
import { CELL_ALIGNMENT } from '../dependencies';

export const Wrapper = styled.div`
  position: relative;
  overflow-x: auto;
  box-shadow: ${({ elevationBoxShadow }) => elevationBoxShadow};
  ${({ isFixed }) =>
    isFixed &&
    css`
      .table-wrapper {
        padding-bottom: 15px;
      }
    `}

  .fixed-left-table-wrapper {
    position: absolute;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    z-index: auto;
    overflow: hidden;
    pointer-events: none;
  }

  .table-wrapper,
  .fixed-left-table-wrapper {
    opacity: 1;
    transition: opacity ease 300ms;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      .table-wrapper,
      .fixed-left-table-wrapper {
        opacity: 0.5;
        transition: opacity ease 300ms;
      }
    `}
`;

export const TableWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  pointer-events: auto;

  .fixed-left-columns {
    position: absolute;
    top: 0;
    left: 0;
    z-index: auto;
    overflow: hidden;
    pointer-events: none;
  }
`;

export const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  min-width: 650px;

  .rightmost-cell {
    position: relative;
    :before {
      position: absolute;
      content: '';
      ${({ shouldShowFixedShadowOnScroll, fixedLeftBoxShadow }) =>
        shouldShowFixedShadowOnScroll
          ? css`
              top: 0%;
              left: 0;
              width: 100%;
              height: 105%;
              box-shadow: ${fixedLeftBoxShadow};
            `
          : css`
              top: 0%;
              left: 0;
              width: 100%;
              height: 100%;
              box-shadow: none;
            `};
      transition: all ease 300ms;
    }
  }
`;

export const TableHead = styled.thead`
  display: table-header-group;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  display: table-row-group;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  color: inherit;
  display: table-row;
  outline: none;
  vertical-align: middle;
  ${({ isHovered }) =>
    isHovered &&
    css`
      td,
      th {
        background-color: ${({ hoveredRowBackgroundColor }) => hoveredRowBackgroundColor};
      }
    `}
`;

export const cellAlignment = css`
  ${({ align }) => {
    switch (align) {
      case CELL_ALIGNMENT.MIDDLE:
        return css`
          text-align: center;
        `;
      case CELL_ALIGNMENT.LEFT:
        return css`
          text-align: left;
        `;
      case CELL_ALIGNMENT.RIGHT:
        return css`
          text-align: right;
          flex-direction: row-reverse;
        `;
      default:
        return css`
          text-align: left;
        `;
    }
  }}
`;

export const cellPadding = css`
  padding: 14px 40px 14px 16px;
`;

export const cellCss = css`
  ${({ padding }) => padding || cellPadding};
  ${cellAlignment};
  border-bottom: ${({ borderless }) =>
    !borderless &&
    css`
    1px solid ${({ borderColor }) => borderColor || '#e0e0e0'};
  `};
  ${({ fixed }) =>
    fixed
      ? css`
          visibility: hidden;
          border-color: transparent;
          pointer-events: none;
        `
      : css`
          background: ${({ backgroundColor }) => backgroundColor};
          pointer-events: auto;
        `}
  transition: all ease 300ms;
`;

export const HeaderCell = styled.th`
  ${cellCss};
`;

export const StandardCell = styled.td`
  ${cellCss};
`;
