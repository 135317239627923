const MENTOR_EMAIL_DASHBOARD_LIST = 'bfb/ManagementPanel/MENTOR_EMAIL_DASHBOARD_LIST';
const MENTOR_EMAIL_DASHBOARD_LIST_PENDING =
  'bfb/ManagementPanel/MENTOR_EMAIL_DASHBOARD_LIST_PENDING';
const MENTOR_EMAIL_DASHBOARD_LIST_SUCCEEDED =
  'bfb/ManagementPanel/MENTOR_EMAIL_DASHBOARD_LIST_SUCCEEDED';
const MENTOR_EMAIL_DASHBOARD_LIST_FAILED = 'bfb/ManagementPanel/MENTOR_EMAIL_DASHBOARD_LIST_FAILED';

const MENTOR_DETAIL_EMAIL_DASHBOARD_LIST = 'bfb/ManagementPanel/MENTOR_DETAIL_EMAIL_DASHBOARD_LIST';
const MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_PENDING =
  'bfb/ManagementPanel/MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_PENDING';
const MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED =
  'bfb/ManagementPanel/MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED';
const MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_FAILED =
  'bfb/ManagementPanel/MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_FAILED';

export default {
  MENTOR_EMAIL_DASHBOARD_LIST,
  MENTOR_EMAIL_DASHBOARD_LIST_PENDING,
  MENTOR_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  MENTOR_EMAIL_DASHBOARD_LIST_FAILED,
  MENTOR_DETAIL_EMAIL_DASHBOARD_LIST,
  MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_PENDING,
  MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_SUCCEEDED,
  MENTOR_DETAIL_EMAIL_DASHBOARD_LIST_FAILED,
};
