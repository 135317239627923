import types from './types';

const createMemberSurveys = (payload, callback) => ({
  type: types.MEMBER_SURVEYS_CREATE,
  payload,
  callback,
});

const createMemberSurveysSucceeded = payload => ({
  type: types.MEMBER_SURVEYS_CREATE_SUCCEEDED,
  payload,
});

const createMemberSurveysFailed = payload => ({
  type: types.MEMBER_SURVEYS_CREATE_FAILED,
  payload,
});

export default {
  createMemberSurveys,
  createMemberSurveysSucceeded,
  createMemberSurveysFailed,
};
