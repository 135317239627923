import { Record } from 'immutable';
import ContentType from '../ContentType';

const SystemRecord = Record({
  id: null,
  contentType: ContentType.SYSTEM,
  project: null,
  name: null,
  system_change: null,
  created: null,
  updated: null,
  comments: [],
});

class System extends SystemRecord {
  // add contenttype
  static contentType = ContentType.SYSTEM;

  get getId() {
    return this.get('id');
  }
}

export default System;
