import api from './api';

const fetchCustomization = ({ id }) => api.get(`/api/customizations/${id}/`);
const listCustomizations = query => api.get('/api/customizations/', { params: query });
const updateCustomization = ({ id, customization }) =>
  api.patch(`/api/customizations/${id}/`, { ...customization });

export default {
  fetchCustomization,
  listCustomizations,
  updateCustomization,
};
