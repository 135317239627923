import { types } from 'store/modules/ImpactPlan/resource';

const clearError = () => ({
  type: types.RESOURCE_CLEAR_ERROR,
});

const createResource = ({ ...resource }) => ({
  type: types.RESOURCE_CREATE,
  payload: { ...resource },
});

const createResourceSucceeded = ({ id, ...resource }) => ({
  type: types.RESOURCE_CREATE_SUCCEEDED,
  payload: { id, ...resource },
});

const createResourceFailed = ({ message }) => ({
  type: types.RESOURCE_CREATE_FAILED,
  payload: { message },
});

const deleteResource = ({ id, system }) => ({
  type: types.RESOURCE_DELETE,
  payload: { id, system },
});

const deleteResourceSucceeded = ({ id, system }) => ({
  type: types.RESOURCE_DELETE_SUCCEEDED,
  payload: { id, system },
});

const deleteResourceFailed = ({ message }) => ({
  type: types.RESOURCE_DELETE_FAILED,
  payload: { message },
});

const fetchResource = ({ id }) => ({
  type: types.RESOURCE_FETCH,
  payload: { id },
});

const fetchResourceSucceeded = ({ id, ...resource }) => ({
  type: types.RESOURCE_FETCH_SUCCEEDED,
  payload: { id, ...resource },
});

const fetchResourceFailed = ({ message }) => ({
  type: types.RESOURCE_FETCH_FAILED,
  payload: { message },
});

const listResources = ({ system }) => ({
  type: types.RESOURCES_LIST,
  payload: { system },
});

const listResourcesSucceeded = payload => ({
  type: types.RESOURCES_LIST_SUCCEEDED,
  payload,
});

const listResourcesFailed = error => ({
  type: types.RESOURCES_LIST_FAILED,
  payload: error,
});

const updateResource = ({ id, ...resource }) => ({
  type: types.RESOURCE_UPDATE,
  payload: { id, ...resource },
});

const updateResourceSucceeded = ({ id, ...resource }) => ({
  type: types.RESOURCE_UPDATE_SUCCEEDED,
  payload: { id, ...resource },
});

const updateResourceFailed = ({ message }) => ({
  type: types.RESOURCE_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createResource,
  createResourceFailed,
  createResourceSucceeded,
  deleteResource,
  deleteResourceFailed,
  deleteResourceSucceeded,
  fetchResource,
  fetchResourceFailed,
  fetchResourceSucceeded,
  listResources,
  listResourcesFailed,
  listResourcesSucceeded,
  updateResource,
  updateResourceSucceeded,
  updateResourceFailed,
};
