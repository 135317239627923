const RETENTION_ACTIVITY_CLEAR_ERROR = 'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_CLEAR_ERROR';
const RETENTION_ACTIVITIES_LIST = 'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITIES_LIST';
const RETENTION_ACTIVITIES_LIST_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITIES_LIST_SUCCEEDED';
const RETENTION_ACTIVITIES_LIST_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITIES_LIST_FAILED';
const RETENTION_ACTIVITY_CREATE = 'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_CREATE';
const RETENTION_ACTIVITY_CREATE_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_CREATE_SUCCEEDED';
const RETENTION_ACTIVITY_CREATE_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_CREATE_FAILED';
const RETENTION_ACTIVITY_DELETE = 'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_DELETE';
const RETENTION_ACTIVITY_DELETE_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_DELETE_SUCCEEDED';
const RETENTION_ACTIVITY_DELETE_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_DELETE_FAILED';
const RETENTION_ACTIVITY_UPDATE = 'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_UPDATE';
const RETENTION_ACTIVITY_UPDATE_SUCCEEDED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_UPDATE_SUCCEEDED';
const RETENTION_ACTIVITY_UPDATE_FAILED =
  'bfb/PRODUCT_PRICING_MODEL/RETENTION_ACTIVITY_UPDATE_FAILED';

export default {
  RETENTION_ACTIVITIES_LIST,
  RETENTION_ACTIVITIES_LIST_SUCCEEDED,
  RETENTION_ACTIVITIES_LIST_FAILED,
  RETENTION_ACTIVITY_CLEAR_ERROR,
  RETENTION_ACTIVITY_CREATE,
  RETENTION_ACTIVITY_CREATE_SUCCEEDED,
  RETENTION_ACTIVITY_CREATE_FAILED,
  RETENTION_ACTIVITY_DELETE,
  RETENTION_ACTIVITY_DELETE_SUCCEEDED,
  RETENTION_ACTIVITY_DELETE_FAILED,
  RETENTION_ACTIVITY_UPDATE,
  RETENTION_ACTIVITY_UPDATE_SUCCEEDED,
  RETENTION_ACTIVITY_UPDATE_FAILED,
};
