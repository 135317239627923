import { createSelector } from 'reselect';

const getState = state => state.managementPanelMember;

const getRawApplications = createSelector([getState], state => state.data);

const applicationsListLoading = createSelector([getState], state => state.loading);

const getApplicationById = createSelector([getState], state => state.applicationById);

const getApplicationsBySpecificStatus = createSelector(
  [getRawApplications],
  state => status => state[status]
);

const getCurrentApplication = createSelector([getRawApplications], state => state.current);

const getApplications = createSelector([getRawApplications], state => state.application);

const updatePending = createSelector([getState], state => state.updatePending);

export default {
  getApplicationsBySpecificStatus,
  applicationsListLoading,
  getCurrentApplication,
  getApplicationById,
  getState,
  getApplications,
  updatePending,
};
