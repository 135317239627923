import { Menu as AntMenu } from 'antd';
import styled from 'styled-components';

const Menu = styled(AntMenu)`
  background-color: white;
`;

Menu.Item = styled(AntMenu.Item)`
  &&& {
    border-radius: 0;
    line-height: 22px;
    height: 20px;
    padding-left: 30px !important;
    text-transform: capitalize;

    &.ant-menu-item:after {
      border: none;
    }

    &:active {
      background: ${({ theme }) => theme.itemActiveBg};
    }

    &:hover {
      color: ${({ theme }) => theme.linkColor};
      &:active {
        color: white;
      }
    }

    &.ant-menu-item {
      height: 42px;
      line-height: 40px;
      margin: 0;

      @media screen and (max-width: ${({ theme }) => theme.screenSmMin}) {
        height: 60px;
        line-height: 60px;

        .anticon {
          margin: 0;
        }
      }
    }

    &.ant-menu-item-selected {
      background: ${({ theme }) => theme.secondaryColorBgFade};
      border-right: 4px solid ${({ theme }) => theme.secondaryColor};
      color: black;
      font-weight: 600;
      &:after {
        border: none;
      }
      &:active {
        background: ${({ theme }) => theme.itemActiveBg};
      }
    }

    &.ant-menu-item-disabled,
    &.ant-menu-submenu-disabled {
      color: #b3b3b3;
    }
  }
`;
Menu.SubMenu = styled(AntMenu.SubMenu)`
  &&& {
    .ant-menu-submenu-title {
      color: ${({ theme }) => theme.primaryColor};
      font-weight: 600;
      height: 42px;
      line-height: 30px;
      margin: 0;
      padding-left: 30px !important;
      text-transform: capitalize;

      &:active {
        background: ${({ theme }) => theme.itemActiveBg};
        color: white;
      }
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      height: 42px;
      line-height: 30px;
      margin: 0;
    }

    &.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
    &.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
    &.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
    &.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
    &.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
    &.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
    &.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
    &.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
      background: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export default Menu;
