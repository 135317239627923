import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import UserLink from 'models/Link';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    linkData: [],
    errorMessage: '',
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_LINK_REQUEST:
    case types.USER_LINK_BULK_EDIT:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.USER_LINK_SUCCEEDED:
    case types.USER_LINK_BULK_EDIT_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          linkData: action.payload.map(link => new UserLink(link)),
        })
      );
    case types.USER_LINK_FAILED:
    case types.USER_LINK_BULK_EDIT_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
