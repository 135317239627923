import { Record } from 'immutable';

const STATUS_INCOMPLETE = 0; // (blocked)
const STATUS_IN_PROGRESS = 1;
const STATUS_PENDING = 2;
const STATUS_COMPLETE = 3;

const ToolRecord = new Record({
  id: null,
  name: '',
  slug: '',
  end_date: new Date(),
  start_date: new Date(),
  current_step: null,
  status: '',
  program: null,
  project_program_tool: null,
  tool: null,
  steps: [],
  order: null,
  intro_video_url: '',
});

class Tool extends ToolRecord {
  static STATUS_INCOMPLETE = STATUS_INCOMPLETE; // (blocked)

  static STATUS_IN_PROGRESS = STATUS_IN_PROGRESS;

  static STATUS_PENDING = STATUS_PENDING;

  static STATUS_COMPLETE = STATUS_COMPLETE;

  static getTooltipTitle = (status, t, isMember) => {
    switch (status) {
      case Tool.STATUS_INCOMPLETE:
        return t('Incomplete');
      case Tool.STATUS_IN_PROGRESS:
        return t('In Progress');
      case Tool.STATUS_PENDING:
        return isMember ? t('Pending Mentor Review') : t('Pending Your Review');
      case Tool.STATUS_COMPLETE:
        return t('Complete!');
      default: // Do nothing
        return null;
    }
  };

  get id() {
    return this.get('id');
  }
}

export default Tool;
