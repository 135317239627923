import { Record } from 'immutable';
import ContentType from '../ContentType';

const MetricRecord = new Record({
  id: null,
  project: null,
  name: null,
  selected: false,
  created: null,
  updated: null,
  comments: [],
  contentType: ContentType.METRIC,
});

class Metric extends MetricRecord {
  static contentType = ContentType.METRIC;

  get id() {
    return this.get('id');
  }
}

export default Metric;
