import { createSelector } from 'reselect';

const getState = state => state.beneficiaryTypes;

const getRawBeneficiaries = createSelector([getState], state => state.get('data'));

const getBeneficiariesById = createSelector([getRawBeneficiaries], state => state.get('byId'));

const getBeneficiaries = createSelector([getBeneficiariesById], state => state.valueSeq().toJS());

const getBeneficiariesOptions = createSelector([getBeneficiariesById], state => {
  const beneficiaries = state.valueSeq().toJS();
  return (selectedBeneficiaries = [], t) =>
    beneficiaries.map(benefit => ({
      value: benefit.id,
      disabled: Boolean(
        selectedBeneficiaries.find(selectedBeneficiary => selectedBeneficiary === benefit.id)
      ),
      label: t(benefit.name),
    }));
});

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getBeneficiaries,
  getBeneficiariesById,
  getBeneficiariesOptions,
  getErrorMessage,
};
