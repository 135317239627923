import types from './types';

const clearError = () => ({
  type: types.PROJECTIONS_CLEAR_ERROR,
});

const listFinancialProjections = payload => ({
  type: types.PROJECTIONS_LIST,
  payload,
});

const listFinancialProjectionsSucceeded = payload => ({
  type: types.PROJECTIONS_LIST_SUCCEEDED,
  payload,
});

const listFinancialProjectionsFailed = ({ message }) => ({
  type: types.PROJECTIONS_LIST_FAILED,
  payload: { message },
});

export default {
  clearError,
  listFinancialProjections,
  listFinancialProjectionsFailed,
  listFinancialProjectionsSucceeded,
};
