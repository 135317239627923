import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for retrieving various program statistics related to the admin dashboard.

export const adminDashboardApi = createApi({
  reducerPath: 'adminDashboardApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/admin-dashboard/',
  }),
  endpoints: builder => ({
    /**
     * Default: Returns statistics for each of the program manager's organizations.
     * useListDashboardTotalsQuery();
     *
     * With programIds as a query param: Returns statistics for the chosen program.
     * useListDashboardTotalsQuery({ programs: 'chosen-program' });
     */
    listDashboardTotals: builder.query({
      query: query => ({ params: query }),
    }),
  }),
});

export const { useListDashboardTotalsQuery } = adminDashboardApi;
