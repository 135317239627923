import api from '../api';

const createFixedAsset = ({ ...fixedAsset }) => api.post('api/fixed-assets/', { ...fixedAsset });

const deleteFixedAsset = ({ id }) => api.delete(`api/fixed-assets/${id}/`);

const fetchFixedAsset = ({ id }) => api.get(`api/fixed-assets/${id}/`);

const listFixedAssets = ({ ...query }) => api.get('api/fixed-assets/', { params: query });

const updateFixedAsset = ({ id, ...updatedFixedAsset }) =>
  api.patch(`api/fixed-assets/${id}/`, { ...updatedFixedAsset });

export default {
  createFixedAsset,
  deleteFixedAsset,
  fetchFixedAsset,
  listFixedAssets,
  updateFixedAsset,
};
