import { createSelector } from 'reselect';

const getState = state => state.pills;

const getPills = createSelector([getState], state => state.data);

const getPillsByTool = createSelector([getPills], state => state.byTool);

const getToolPills = toolId => createSelector([getPillsByTool], state => state && state[toolId]);

const getPillsByCategory = createSelector([getPills], state => state.byCategory);

export default {
  getPills,
  getPillsByTool,
  getPillsByCategory,
  getToolPills,
};
