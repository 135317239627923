import api from '../api';

const fetchActivity = ({ id }) => api.get(`/api/marketing-activities/${id}`);

const createActivity = ({ product, ...channel }) =>
  api.post('/api/marketing-activities/', { product, ...channel });

const deleteActivity = ({ id }) => api.delete(`/api/marketing-activities/${id}`);

const listActivities = query => api.get('/api/marketing-activities/', { params: query });

const updateActivity = ({ id, ...channel }) =>
  api.patch(`/api/marketing-activities/${id}/`, { ...channel });

export default {
  createActivity,
  deleteActivity,
  fetchActivity,
  listActivities,
  updateActivity,
};
