import { Record } from 'immutable';
import ContentType from '../ContentType';

const FundingRecord = Record({
  id: null,
  contentType: ContentType.FUNDING,
  depreciation: 1,
  loan_rate: null,
  name: '',
  scenario: null,
  terms: null,
  type: null,
  value: null,
  comments: null,
  created: null,
  updated: null,
  secured: null,
});

class Funding extends FundingRecord {
  // TODO: ADD PRODUCT CONTENT TYPE
  static contentType = ContentType.FUNDING;

  static TYPE_LOAN = 0;
  static TYPE_OWNERS_CONTRIBUTION = 1;
  static TYPE_OUTSIDE_INVESTMENT = 2;
  static TYPE_GRANTS = 3;
  static TYPE_COMMERCIAL_MORTAGE = 4;
  static TYPE_OTHER_LOANS = 5;

  static getTypeNames = t => ({
    [Funding.TYPE_LOAN]: t('Bank Loans'),
    [Funding.TYPE_OWNERS_CONTRIBUTION]: t("Owners' Contribution"),
    [Funding.TYPE_OUTSIDE_INVESTMENT]: t('Outside Investment'),
    [Funding.TYPE_GRANTS]: t('Grants'),
    [Funding.TYPE_COMMERCIAL_MORTAGE]: t('Commercial Mortgage'),
    [Funding.TYPE_OTHER_LOANS]: t('Other Loans or Debt'),
  });

  static getTypesOptions = t =>
    Object.entries(Funding.getTypeNames(t)).map(([type, typeName]) => ({
      label: typeName,
      value: parseInt(type, 10),
    }));

  get getId() {
    return this.get('id');
  }
}

export default Funding;
