const RULE_CLEAR_ERROR = 'bfb/RULE/RULE_CLEAR_ERROR';
const RULES_LIST = 'bfb/RULE/RULES_LIST';
const RULES_LIST_SUCCEEDED = 'bfb/RULE/RULES_LIST_SUCCEEDED';
const RULES_LIST_FAILED = 'bfb/RULE/RULES_LIST_FAILED';
const RULE_CREATE = 'bfb/RULE/RULE_CREATE';
const RULE_CREATE_SUCCEEDED = 'bfb/RULE/RULE_CREATE_SUCCEEDED';
const RULE_CREATE_FAILED = 'bfb/RULE/RULE_CREATE_FAILED';
const RULE_DELETE = 'bfb/RULE/RULE_DELETE';
const RULE_DELETE_SUCCEEDED = 'bfb/RULE/RULE_DELETE_SUCCEEDED';
const RULE_DELETE_FAILED = 'bfb/RULE/RULE_DELETE_FAILED';
const RULE_FETCH = 'bfb/RULE/RULE_FETCH';
const RULE_FETCH_SUCCEEDED = 'bfb/RULE/RULE_FETCH_SUCCEEDED';
const RULE_FETCH_FAILED = 'bfb/RULE/RULE_FETCH_FAILED';
const RULE_UPDATE = 'bfb/RULE/RULE_UPDATE';
const RULE_UPDATE_SUCCEEDED = 'bfb/RULE/RULE_UPDATE_SUCCEEDED';
const RULE_UPDATE_FAILED = 'bfb/RULE/RULE_UPDATE_FAILED';

export default {
  RULES_LIST,
  RULES_LIST_SUCCEEDED,
  RULES_LIST_FAILED,
  RULE_CLEAR_ERROR,
  RULE_CREATE,
  RULE_CREATE_SUCCEEDED,
  RULE_CREATE_FAILED,
  RULE_DELETE,
  RULE_DELETE_SUCCEEDED,
  RULE_DELETE_FAILED,
  RULE_FETCH,
  RULE_FETCH_SUCCEEDED,
  RULE_FETCH_FAILED,
  RULE_UPDATE,
  RULE_UPDATE_SUCCEEDED,
  RULE_UPDATE_FAILED,
};
