import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const publicProjectsApi = createApi({
  reducerPath: 'publicProjectsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/public_projects/',
  }),
  endpoints: builder => ({
    // Fetch ALL Projects
    listPublicProjects: builder.query({
      query: params => ({ params }),
    }),
    // Get a Project by id
    getPublicProjectById: builder.query({
      query: programId => ({ url: programId }),
    }),
  }),
});

export const {
  useListPublicProjectsQuery,
  useGetPublicProjectByIdQuery,
  useLazyGetPublicProjectByIdQuery,
} = publicProjectsApi;
