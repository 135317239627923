import api from '../api';

const updateMonthlyPayroll = ({ id, ...updatedMonthlyPayroll }) =>
  api({
    method: 'patch',
    url: `api/monthly-payrolls/${id}/`,
    data: updatedMonthlyPayroll,
  });

export default {
  updateMonthlyPayroll,
};
