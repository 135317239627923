// Libraries
import { createSlice } from '@reduxjs/toolkit';
import { projectsApi } from 'services/apiSlices/projects/projectsApi';
import ContentType from 'models/ContentType.js';
import FinancialForecast from 'models/FinancialForecast';

const initialState = {
  id: null,
  contentType: ContentType.FINANCIALFORECAST,
  project: null,
  spreadsheet_type: null,
  e_example_choice: null,
  m_example_choice: null,
  comments: null,
  escenarios: [],
};

const projectFinancialForecastSlice = createSlice({
  name: 'projectFinancialForecast',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(
        projectsApi.endpoints.getProjectFinancialForecastByProjectId.matchFulfilled,
        (state, action) => {
          const { payload: projectFinancialForecast } = action;
          state.data = new FinancialForecast(projectFinancialForecast);
        }
      )
      .addMatcher(
        projectsApi.endpoints.updateProjectFinancialForecast.matchFulfilled,
        (state, action) => {
          const { payload: projectFinancialForecast } = action;
          state.data = new FinancialForecast(projectFinancialForecast);
        }
      )
      .addMatcher(
        projectsApi.endpoints.submitProjectFinancialForecast.matchFulfilled,
        (state, action) => {
          const { payload: projectFinancialForecast } = action;
          state.data = new FinancialForecast(projectFinancialForecast);
        }
      );
  },
});

export const projectFinancialForecastReducer = projectFinancialForecastSlice.reducer;
