const GROWTH_RISK_CLEAR_ERROR = 'bfb/growthPlan/growthRisk/GROWTH_RISK_CLEAR_ERROR';
const GROWTH_RISKS_LIST = 'bfb/growthPlan/growthRisk/GROWTH_RISKS_LIST';
const GROWTH_RISKS_LIST_SUCCEEDED = 'bfb/growthPlan/growthRisk/GROWTH_RISKS_LIST_SUCCEEDED';
const GROWTH_RISKS_LIST_FAILED = 'bfb/growthPlan/growthRisk/GROWTH_RISKS_LIST_FAILED';
const GROWTH_RISK_CREATE = 'bfb/growthPlan/growthRisk/GROWTH_RISK_CREATE';
const GROWTH_RISK_CREATE_SUCCEEDED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_CREATE_SUCCEEDED';
const GROWTH_RISK_CREATE_FAILED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_CREATE_FAILED';
const GROWTH_RISK_DELETE = 'bfb/growthPlan/growthRisk/GROWTH_RISK_DELETE';
const GROWTH_RISK_DELETE_SUCCEEDED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_DELETE_SUCCEEDED';
const GROWTH_RISK_DELETE_FAILED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_DELETE_FAILED';
const GROWTH_RISK_FETCH = 'bfb/growthPlan/growthRisk/GROWTH_RISK_FETCH';
const GROWTH_RISK_FETCH_SUCCEEDED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_FETCH_SUCCEEDED';
const GROWTH_RISK_FETCH_FAILED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_FETCH_FAILED';
const GROWTH_RISK_UPDATE = 'bfb/growthPlan/growthRisk/GROWTH_RISK_UPDATE';
const GROWTH_RISK_UPDATE_SUCCEEDED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_UPDATE_SUCCEEDED';
const GROWTH_RISK_UPDATE_FAILED = 'bfb/growthPlan/growthRisk/GROWTH_RISK_UPDATE_FAILED';

export default {
  GROWTH_RISKS_LIST,
  GROWTH_RISKS_LIST_SUCCEEDED,
  GROWTH_RISKS_LIST_FAILED,
  GROWTH_RISK_CLEAR_ERROR,
  GROWTH_RISK_CREATE,
  GROWTH_RISK_CREATE_SUCCEEDED,
  GROWTH_RISK_CREATE_FAILED,
  GROWTH_RISK_DELETE,
  GROWTH_RISK_DELETE_SUCCEEDED,
  GROWTH_RISK_DELETE_FAILED,
  GROWTH_RISK_FETCH,
  GROWTH_RISK_FETCH_SUCCEEDED,
  GROWTH_RISK_FETCH_FAILED,
  GROWTH_RISK_UPDATE,
  GROWTH_RISK_UPDATE_SUCCEEDED,
  GROWTH_RISK_UPDATE_FAILED,
};
