import { call, put, all, takeLatest } from 'redux-saga/effects';
import serviceGlossary from 'services/glossary';
import types from './types';
// eslint-disable-next-line import/no-cycle
import actions from './actions';

export function* glossaryList(action) {
  try {
    const res = yield call(serviceGlossary.getGlossary, action.payload);
    yield put(actions.getGlossarySucceeded(res.data.results));
  } catch (e) {
    yield put(actions.getGlossaryFailed({ message: e.message }));
  }
}

function* glossarySaga() {
  yield all([takeLatest(types.GLOSSARY_LIST, glossaryList)]);
}

export default glossarySaga;
