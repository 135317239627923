import types from './types';

const clearError = () => ({
  type: types.STEP_CLEAR_ERROR,
});

const fetchStep = ({ id, ...step }) => ({
  type: types.STEP_FETCH,
  payload: { id, ...step },
});

const fetchStepSucceeded = payload => ({
  type: types.STEP_FETCH_SUCCEEDED,
  payload,
});

const fetchStepFailed = ({ message }) => ({
  type: types.STEP_FETCH_FAILED,
  payload: { message },
});

const listSteps = ({ program, project }) => ({
  type: types.STEPS_LIST,
  payload: { program, project },
});

const listStepsSucceeded = payload => ({
  type: types.STEPS_LIST_SUCCEEDED,
  payload,
});

const listStepsFailed = ({ message }) => ({
  type: types.STEPS_LIST_FAILED,
  payload: { message },
});

const updateStep = ({ id, ...step }) => ({
  type: types.STEP_UPDATE,
  payload: { id, ...step },
});

const updateStepSucceeded = payload => ({
  type: types.STEP_UPDATE_SUCCEEDED,
  payload,
});

const updateStepFailed = ({ message }) => ({
  type: types.STEP_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  listSteps,
  listStepsFailed,
  listStepsSucceeded,
  updateStep,
  updateStepSucceeded,
  updateStepFailed,
  fetchStep,
  fetchStepFailed,
  fetchStepSucceeded,
};
