import { css } from 'styled-components';
import InputStyle from '../Form/Input/common';
import bfbTheme from 'styles/b4b-theme';

export const DateTimePickerStyle = css`
  width: 100%;
  padding: 0;

  input {
    ${InputStyle}
  }

  &:hover,
  &:focus {
    .anticon {
      color: ${bfbTheme.mainColor};
      fill: ${bfbTheme.mainColor};
    }
  }

  .ant-picker-suffix {
    position: absolute;
    right: 0;
    bottom: 15px;
  }

  .anticon {
    font-size: 19px;
    color: ${bfbTheme.placeholderGrey};
    fill: ${bfbTheme.placeholderGrey};

    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
`;
