import types from './types';

const clearError = () => ({
  type: types.PARTNER_CLEAR_ERROR,
});

const createPartner = ({ project, product, ...partner }) => ({
  type: types.PARTNER_CREATE,
  payload: { project, product, ...partner },
});

const createPartnerSucceeded = ({ product, ...partner }) => ({
  type: types.PARTNER_CREATE_SUCCEEDED,
  payload: { product, ...partner },
});

const createPartnerFailed = ({ message }) => ({
  type: types.PARTNER_CREATE_FAILED,
  payload: { message },
});

const deletePartner = ({ project, product, partnerId }) => ({
  type: types.PARTNER_DELETE,
  payload: { project, product, partnerId },
});

const deletePartnerSucceeded = payload => ({
  type: types.PARTNER_DELETE_SUCCEEDED,
  payload,
});

const deletePartnerFailed = ({ message }) => ({
  type: types.PARTNER_DELETE_FAILED,
  payload: { message },
});

const fetchPartner = ({ project, product, partnerId }) => ({
  type: types.PARTNER_FETCH,
  payload: { project, product, partnerId },
});

const fetchPartnerSucceeded = ({ ...partner }) => ({
  type: types.PARTNER_FETCH_SUCCEEDED,
  payload: { ...partner },
});

const fetchPartnerFailed = ({ message }) => ({
  type: types.PARTNER_FETCH_FAILED,
  payload: { message },
});

const listPartners = ({ project, product }) => ({
  type: types.PARTNERS_LIST,
  payload: { project, product },
});

const listPartnersSucceeded = payload => ({
  type: types.PARTNERS_LIST_SUCCEEDED,
  payload,
});

const listPartnersFailed = ({ message }) => ({
  type: types.PARTNERS_LIST_FAILED,
  payload: { message },
});

const updatePartner = ({ project, product, partnerId, ...partner }) => ({
  type: types.PARTNER_UPDATE,
  payload: { project, product, partnerId, ...partner },
});

const updatePartnerSucceeded = ({ ...partner }) => ({
  type: types.PARTNER_UPDATE_SUCCEEDED,
  payload: { ...partner },
});

const updatePartnerFailed = ({ message }) => ({
  type: types.PARTNER_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createPartner,
  createPartnerFailed,
  createPartnerSucceeded,
  deletePartner,
  deletePartnerFailed,
  deletePartnerSucceeded,
  fetchPartner,
  fetchPartnerFailed,
  fetchPartnerSucceeded,
  listPartners,
  listPartnersFailed,
  listPartnersSucceeded,
  updatePartner,
  updatePartnerFailed,
  updatePartnerSucceeded,
};
