// Libraries
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { InfoOutlined } from '@ant-design/icons';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import isMobile from 'utils/isMobile';

// Components
import { notification } from 'antd';
import { H5, P } from 'components/fontSystem';

const BROWSER_NOTIFICATION_CLASS = 'browser-notification';

const Notification = () => {
  const { t } = React.useContext(TranslationsContext);
  const [api, contextHolder] = notification.useNotification();

  const openBrowserNotification = () => {
    api.destroy('mobile');
    api.warning({
      key: 'desktop',
      duration: 0,
      message: <H5 color="#123DC6">{t('Friendly reminder about your browser')}</H5>,
      description: (
        <P
          dangerouslySetInnerHTML={{
            __html: t('We highly recommend using <b>Google Chrome</b> for the best experience'),
          }}
        />
      ),
      icon: <InfoOutlined />,
      className: BROWSER_NOTIFICATION_CLASS,
    });
  };

  const openBrowserNotificationMobile = () => {
    api.destroy('desktop');
    api.warning({
      key: 'mobile',
      duration: 0,
      message: <H5 color="#123DC6">{t('Friendly reminder about your browser')}</H5>,
      description: (
        <P
          dangerouslySetInnerHTML={{
            __html: t('We highly recommend using <b>Google Chrome</b> for the best experience'),
          }}
        />
      ),
      icon: <InfoOutlined />,
      placement: 'bottomRight',
      bottom: 10,
      className: BROWSER_NOTIFICATION_CLASS,
    });
  };

  React.useEffect(() => {
    const userAgent = navigator && navigator.userAgentData && navigator.userAgentData.brands;
    const isChrome = Boolean(
      userAgent &&
        userAgent.filter(data => data.brand.includes('Chrom') || data.brand.includes('Google'))
    );

    if (!isChrome) {
      isMobile ? openBrowserNotificationMobile() : openBrowserNotification();
    }
  }, []);

  return (
    <>
      {contextHolder}
      <NotificationGlobalStyle />
    </>
  );
};

export const NotificationGlobalStyle = createGlobalStyle`
.${BROWSER_NOTIFICATION_CLASS} {
  .anticon-info{
    background-color: ${({ theme }) => theme.warning};
    border-radius: 50%;
    color: white;
    padding: 5px;
  }
}

`;

Notification.NotificationGlobalStyle = NotificationGlobalStyle;
export default Notification;
