const FIXED_ASSET_CLEAR_ERROR = 'bfb/financialProjections/FIXED_ASSET_CLEAR_ERROR';
const FIXED_ASSETS_LIST = 'bfb/financialProjections/FIXED_ASSETS_LIST';
const FIXED_ASSETS_LIST_SUCCEEDED = 'bfb/financialProjections/FIXED_ASSETS_LIST_SUCCEEDED';
const FIXED_ASSETS_LIST_FAILED = 'bfb/financialProjections/FIXED_ASSETS_LIST_FAILED';
const FIXED_ASSET_CREATE = 'bfb/financialProjections/FIXED_ASSET_CREATE';
const FIXED_ASSET_CREATE_SUCCEEDED = 'bfb/financialProjections/FIXED_ASSET_CREATE_SUCCEEDED';
const FIXED_ASSET_CREATE_FAILED = 'bfb/financialProjections/FIXED_ASSET_CREATE_FAILED';
const FIXED_ASSET_DELETE = 'bfb/financialProjections/FIXED_ASSET_DELETE';
const FIXED_ASSET_DELETE_SUCCEEDED = 'bfb/financialProjections/FIXED_ASSET_DELETE_SUCCEEDED';
const FIXED_ASSET_DELETE_FAILED = 'bfb/financialProjections/FIXED_ASSET_DELETE_FAILED';
const FIXED_ASSET_FETCH = 'bfb/financialProjections/FIXED_ASSET_FETCH';
const FIXED_ASSET_FETCH_SUCCEEDED = 'bfb/financialProjections/FIXED_ASSET_FETCH_SUCCEEDED';
const FIXED_ASSET_FETCH_FAILED = 'bfb/financialProjections/FIXED_ASSET_FETCH_FAILED';
const FIXED_ASSET_UPDATE = 'bfb/financialProjections/FIXED_ASSET_UPDATE';
const FIXED_ASSET_UPDATE_SUCCEEDED = 'bfb/financialProjections/FIXED_ASSET_UPDATE_SUCCEEDED';
const FIXED_ASSET_UPDATE_FAILED = 'bfb/financialProjections/FIXED_ASSET_UPDATE_FAILED';

export default {
  FIXED_ASSETS_LIST,
  FIXED_ASSETS_LIST_SUCCEEDED,
  FIXED_ASSETS_LIST_FAILED,
  FIXED_ASSET_CLEAR_ERROR,
  FIXED_ASSET_CREATE,
  FIXED_ASSET_CREATE_SUCCEEDED,
  FIXED_ASSET_CREATE_FAILED,
  FIXED_ASSET_DELETE,
  FIXED_ASSET_DELETE_SUCCEEDED,
  FIXED_ASSET_DELETE_FAILED,
  FIXED_ASSET_FETCH,
  FIXED_ASSET_FETCH_SUCCEEDED,
  FIXED_ASSET_FETCH_FAILED,
  FIXED_ASSET_UPDATE,
  FIXED_ASSET_UPDATE_SUCCEEDED,
  FIXED_ASSET_UPDATE_FAILED,
};
