import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/StakeholderMap/chain';
import { actions as channelActions } from 'store/modules/StakeholderMap/channel';
import serviceChain from 'services/StakeholderMap/chain';
import { types as commentTypes } from 'store/modules/comment';
import { normalizeChains } from 'store/normalizers/chainNormalizer';
import Chain from 'models/StakeholderMap/Chain';

export function* createChain(action) {
  const { product, ...chain } = action.payload;
  try {
    const response = yield call(serviceChain.createChain, {
      product,
      ...chain,
    });
    yield put(actions.createChainSucceeded({ product, ...response.data }));
  } catch (e) {
    yield put(
      actions.createChainFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* listChains(action) {
  const { productId } = action.payload;
  try {
    const response = yield call(serviceChain.listChains, {
      product: productId,
    });
    const { chains, channels } = yield call(normalizeChains, response.data);
    yield put(actions.listChainsSucceeded({ results: chains, product: productId }));
    yield put(
      channelActions.listChannelsSucceeded({
        results: channels,
        product: productId,
      })
    );
  } catch (e) {
    yield put(actions.listChainsFailed({ message: e.message }));
  }
}

export function* fetchChain(action) {
  const { product, id, ...chain } = action.payload;
  try {
    const response = yield call(serviceChain.fetchChain, { id, ...chain });
    yield put(actions.fetchChainSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(
      actions.fetchChainFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateChain(action) {
  const { product, id, ...chain } = action.payload;
  try {
    const updated = yield call(serviceChain.updateChain, { id, ...chain });
    yield put(actions.updateChainSucceeded({ ...updated.data, product }));
  } catch (e) {
    yield put(
      actions.updateChainFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteChain(action) {
  const { id, product } = action.payload;
  try {
    yield call(serviceChain.deleteChain, { id });
    yield put(actions.deleteChainSucceeded({ id, product }));
  } catch (e) {
    yield put(
      actions.deleteChainFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Chain.contentType) {
    yield put(
      actions.fetchChain({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* chainSaga() {
  yield all([
    takeEvery(types.CHAIN_FETCH, fetchChain),
    takeEvery(types.CHAINS_LIST, listChains),
    takeLatest(types.CHAIN_CREATE, createChain),
    takeLatest(types.CHAIN_UPDATE, updateChain),
    takeLatest(types.CHAIN_DELETE, deleteChain),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default chainSaga;
