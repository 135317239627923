import { createSelector } from 'reselect';

const getState = state => state.organization;

const getRawOrganizations = createSelector([getState], state => state.get('data'));

const getOrganizationsById = createSelector([getRawOrganizations], state => state.get('byId'));

const getOrganizations = createSelector([getOrganizationsById], state => state.valueSeq().toJS());

export default {
  getOrganizations,
  getOrganizationsById,
};
