import { types } from 'store/modules/DeepDive/link';

const listDeepDiveLinks = payload => ({
  type: types.DEEP_DIVE_LINKS_LIST,
  payload,
});

const listDeepDiveLinksSucceeded = payload => ({
  type: types.DEEP_DIVE_LINKS_LIST_SUCCEEDED,
  payload,
});

const listDeepDiveLinksFailed = payload => ({
  type: types.DEEP_DIVE_LINKS_LIST_FAILED,
  payload,
});

const createDeepDiveLink = (payload, callback) => ({
  type: types.DEEP_DIVE_LINK_CREATE,
  payload,
  callback,
});

const createDeepDiveLinkSucceeded = payload => ({
  type: types.DEEP_DIVE_LINK_CREATE_SUCCEEDED,
  payload,
});

const createDeepDiveLinkFailed = payload => ({
  type: types.DEEP_DIVE_LINK_CREATE_FAILED,
  payload,
});

const updateDeepDiveLink = payload => ({
  type: types.DEEP_DIVE_LINK_UPDATE,
  payload,
});

const updateDeepDiveLinkSucceeded = payload => ({
  type: types.DEEP_DIVE_LINK_UPDATE_SUCCEEDED,
  payload,
});

const updateDeepDiveLinkFailed = payload => ({
  type: types.DEEP_DIVE_LINK_UPDATE_FAILED,
  payload,
});
const deleteDeepDiveLink = payload => ({
  type: types.DEEP_DIVE_LINK_DELETE,
  payload,
});

const deleteDeepDiveLinkSucceeded = payload => ({
  type: types.DEEP_DIVE_LINK_DELETE_SUCCEEDED,
  payload,
});

const deleteDeepDiveLinkFailed = payload => ({
  type: types.DEEP_DIVE_LINK_DELETE_FAILED,
  payload,
});

export default {
  listDeepDiveLinks,
  listDeepDiveLinksSucceeded,
  listDeepDiveLinksFailed,
  createDeepDiveLink,
  createDeepDiveLinkSucceeded,
  createDeepDiveLinkFailed,
  updateDeepDiveLink,
  updateDeepDiveLinkSucceeded,
  updateDeepDiveLinkFailed,
  deleteDeepDiveLink,
  deleteDeepDiveLinkSucceeded,
  deleteDeepDiveLinkFailed,
};
