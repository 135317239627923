const PROGRAM_MENTORS_LIST = 'bfb/program/PROGRAM_MENTORS_LIST';
const PROGRAM_MENTORS_LIST_SUCCEEDED = 'bfb/program/PROGRAM_MENTORS_LIST_SUCCEEDED';
const PROGRAM_MENTORS_LIST_FAILED = 'bfb/program/PROGRAM_MENTORS_LIST_FAILED';
const PROGRAM_MENTORS_LIST_BY_CATEGORY = 'bfb/program/PROGRAM_MENTORS_LIST_BY_CATEGORY';
const PROGRAM_MENTORS_LIST_BY_CATEGORY_SUCCEEDED =
  'bfb/program/PROGRAM_MENTORS_LIST_BY_CATEGORY_SUCCEEDED';
const PROGRAM_MENTORS_LIST_BY_CATEGORY_FAILED =
  'bfb/program/PROGRAM_MENTORS_LIST_BY_CATEGORY_FAILED';
const PROGRAM_MENTORS_LIST_PENDING = 'bfb/program/PROGRAM_MENTORS_LIST_BY_CATEGORY_PENDING';

export default {
  PROGRAM_MENTORS_LIST,
  PROGRAM_MENTORS_LIST_SUCCEEDED,
  PROGRAM_MENTORS_LIST_FAILED,
  PROGRAM_MENTORS_LIST_BY_CATEGORY,
  PROGRAM_MENTORS_LIST_BY_CATEGORY_SUCCEEDED,
  PROGRAM_MENTORS_LIST_BY_CATEGORY_FAILED,
  PROGRAM_MENTORS_LIST_PENDING,
};
