const MENTOR_FEEDBACK_CONTAINER = 'mentor-feedback-container';
const MENTOR_FEEDBACK_DISCUSS_BUTTON = 'mentor-feedback-discuss-button';
const WORKSHEET_DRAWER_HEADER = 'worksheet-drawer-header';
const WORKSHEET_DRAWER_UPLOAD_BUTTON = 'worksheet-drawer-upload-button';
const WORKSHEET_DRAWER_START_TUTORIAL_BUTTON = 'worksheet-drawer-start-tutorial-button';
const HEADER_NOTIFICATION_BADGE = 'header-notification-badge';
const WORKSHEET_DRAWER_SCENARIO_CONTAINER = 'worksheet-drawer-scenario-container';

export {
  HEADER_NOTIFICATION_BADGE,
  MENTOR_FEEDBACK_CONTAINER,
  MENTOR_FEEDBACK_DISCUSS_BUTTON,
  WORKSHEET_DRAWER_HEADER,
  WORKSHEET_DRAWER_SCENARIO_CONTAINER,
  WORKSHEET_DRAWER_START_TUTORIAL_BUTTON,
  WORKSHEET_DRAWER_UPLOAD_BUTTON,
};
