import types from './types';

const listNotifications = (payload, callback) => ({
  type: types.NOTIFICATIONS_LIST,
  payload,
  callback,
});

const listNotificationsSucceeded = payload => ({
  type: types.NOTIFICATIONS_LIST_SUCCEEDED,
  payload,
});

const listNotificationsFailed = ({ message }) => ({
  type: types.NOTIFICATIONS_LIST_FAILED,
  payload: { message },
});

const updateNotification = ({ id, ...notification }) => ({
  type: types.NOTIFICATION_UPDATE,
  payload: { id, ...notification },
});

const updateNotificationSucceeded = payload => ({
  type: types.NOTIFICATION_UPDATE_SUCCEEDED,
  payload,
});

const updateNotificationFailed = ({ message }) => ({
  type: types.NOTIFICATION_UPDATE_FAILED,
  payload: { message },
});
export default {
  listNotificationsSucceeded,
  listNotificationsFailed,
  listNotifications,
  updateNotification,
  updateNotificationSucceeded,
  updateNotificationFailed,
};
