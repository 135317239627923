import * as reduxHelper from 'store/utils/reduxHelper';
import ExecutiveSummary from 'models/ExecutiveSummary/ExecutiveSummary';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { executiveSummary: new ExecutiveSummary(), pdf: 'hola soy un pdf' },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.EXECUTIVE_SUMMARY_UPDATE:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.EXECUTIVE_SUMMARY_FETCH_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            executiveSummary: new ExecutiveSummary(action.payload),
          },
        })
      );
    case types.EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            executiveSummary: new ExecutiveSummary(action.payload),
          },
        })
      );
    case types.EXECUTIVE_SUMMARY_FETCH_FAILED:
    case types.EXECUTIVE_SUMMARY_UPDATE_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
