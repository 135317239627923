import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// Dependencies
import TranslationsContext from 'providers/translationsProvider';
// Components
import styled from 'styled-components';
import { Progress as AntProgress } from 'antd';
import { Body } from 'components/fontSystem';
import { Button } from 'components/elements';

const ProgressBar = props => {
  const { className, title, onClick, hideWhatsLeft, showPercentage, percent, ...rest } = props;
  const { t } = useContext(TranslationsContext);
  const isProgressComplete = percent >= 100;

  return (
    <div className={className}>
      <div className="value-container">
        <Body className="title">{title}</Body>
        {isProgressComplete && !hideWhatsLeft && (
          <Button size="small" onClick={onClick}>
            <em>{t("What's left?")}</em>
          </Button>
        )}
        <Body className="percent" $bold={isProgressComplete} $showPercentage={showPercentage}>
          {t(`${percent}%`)}
        </Body>
      </div>
      <AntProgress percent={percent} {...rest} showInfo={false} />
    </div>
  );
};

const StyledProgressBar = styled(ProgressBar)`
  width: 98%;
  .value-container {
    display: flex;
    justify-content: space-between;
    height: 25px;
    .ant-btn {
      padding: 0;
    }
    .percent {
      display: ${({ $showPercentage }) => !$showPercentage && 'none'};
    }
  }
  .ant-progress-inner {
    width: 100%;
    background-color: ${({ theme }) => theme.lightGrey};
  }
  .ant-progress-bg {
    background-image: linear-gradient(to right, #123dc6, #00ffaf);
    height: 10px !important;
  }
`;

ProgressBar.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  hideWhatsLeft: PropTypes.bool,
  percent: PropTypes.number.isRequired,
  showPercentage: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
ProgressBar.defaultProps = {
  hideWhatsLeft: false,
  showPercentage: false,
};

export default StyledProgressBar;
