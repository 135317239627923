import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// Application as in information that M submits in order to enter selection phase (Draft)

export const mentorApplicationApi = createApi({
  reducerPath: 'mentorApplicationApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/mentor-applications/',
  }),
  endpoints: builder => ({
    // Returns draft of a mentor application by id
    getApplicationDraftById: builder.query({
      query: applicationId => ({ url: `${applicationId}/draft` }),
    }),
    // Update draft of an E application, "data" is equal to modified field
    updateApplication: builder.mutation({
      query: ({ applicationId, data, allValues }) => ({
        url: `${applicationId}/draft`,
        method: 'PATCH',
        data,
      }),
    }),
    // Submit draft of an E application, "data" is equal to ALL application fields.
    submitApplication: builder.mutation({
      query: ({ applicationId, ...data }) => ({
        url: `${applicationId}/draft`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetApplicationDraftByIdQuery,
  useUpdateApplicationMutation,
  useSubmitApplicationMutation,
} = mentorApplicationApi;
