const PRODUCT_CLEAR_ERROR = 'bfb/project/PRODUCT_CLEAR_ERROR';
const PRODUCTS_LIST = 'bfb/project/PRODUCTS_LIST';
const PRODUCTS_LIST_SUCCEEDED = 'bfb/project/PRODUCTS_LIST_SUCCEEDED';
const PRODUCTS_LIST_FAILED = 'bfb/project/PRODUCTS_LIST_FAILED';
const PRODUCT_CREATE = 'bfb/project/PRODUCT_CREATE';
const PRODUCT_CREATE_SUCCEEDED = 'bfb/project/PRODUCT_CREATE_SUCCEEDED';
const PRODUCT_CREATE_FAILED = 'bfb/project/PRODUCT_CREATE_FAILED';
const PRODUCT_DELETE = 'bfb/project/PRODUCT_DELETE';
const PRODUCT_DELETE_SUCCEEDED = 'bfb/project/PRODUCT_DELETE_SUCCEEDED';
const PRODUCT_DELETE_FAILED = 'bfb/project/PRODUCT_DELETE_FAILED';
const PRODUCT_FETCH = 'bfb/project/PRODUCT_FETCH';
const PRODUCT_FETCH_SUCCEEDED = 'bfb/project/PRODUCT_FETCH_SUCCEEDED';
const PRODUCT_FETCH_FAILED = 'bfb/project/PRODUCT_FETCH_FAILED';
const PRODUCT_UPDATE = 'bfb/project/PRODUCT_UPDATE';
const PRODUCT_UPDATE_SUCCEEDED = 'bfb/project/PRODUCT_UPDATE_SUCCEEDED';
const PRODUCT_UPDATE_FAILED = 'bfb/project/PRODUCT_UPDATE_FAILED';

export default {
  PRODUCTS_LIST,
  PRODUCTS_LIST_SUCCEEDED,
  PRODUCTS_LIST_FAILED,
  PRODUCT_CLEAR_ERROR,
  PRODUCT_CREATE,
  PRODUCT_CREATE_SUCCEEDED,
  PRODUCT_CREATE_FAILED,
  PRODUCT_DELETE,
  PRODUCT_DELETE_SUCCEEDED,
  PRODUCT_DELETE_FAILED,
  PRODUCT_FETCH,
  PRODUCT_FETCH_SUCCEEDED,
  PRODUCT_FETCH_FAILED,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCEEDED,
  PRODUCT_UPDATE_FAILED,
};
