import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { types as commentTypes } from 'store/modules/comment';
import Investment from 'models/FinancialProjections/Investment';
import serviceInvestment from 'services/FinancialProjections/investment';
import actions from './actions';
import types from './types';

export function* createInvestment(action) {
  const {
    callback,
    payload: { scenario, ...investment },
  } = action;
  try {
    const response = yield call(serviceInvestment.createInvestment, {
      scenario,
      ...investment,
    });
    yield put(actions.createInvestmentSucceeded({ ...response.data, scenario }));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.createInvestmentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteInvestment(action) {
  const { scenario, id } = action.payload;
  try {
    yield call(serviceInvestment.deleteInvestment, { id });
    yield put(actions.deleteInvestmentSucceeded({ id, scenario }));
  } catch (e) {
    yield put(
      actions.deleteInvestmentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchInvestment(action) {
  const { scenario, id } = action.payload;
  try {
    const response = yield call(serviceInvestment.fetchInvestment, {
      scenario,
      id,
    });
    yield put(actions.fetchInvestmentSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(actions.fetchInvestmentFailed({ message: e.message }));
  }
}

export function* listInvestments(action) {
  const { scenario } = action.payload;
  try {
    const investments = yield call(serviceInvestment.listInvestments, {
      scenario,
    });
    yield put(actions.listInvestmentsSucceeded({ results: investments.data, scenario }));
  } catch (e) {
    yield put(actions.listInvestmentsFailed({ message: e.message }));
  }
}

export function* updateInvestment(action) {
  const { scenario, id, ...updatedInvestment } = action.payload;
  try {
    const updated = yield call(serviceInvestment.updateInvestment, {
      scenario,
      id,
      ...updatedInvestment,
    });
    yield put(actions.updateInvestmentSucceeded({ ...updated.data, scenario }));
  } catch (e) {
    yield put(
      actions.updateInvestmentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Investment.contentType) {
    yield put(
      actions.fetchInvestment({
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* investmentSaga() {
  yield all([
    takeLatest(types.INVESTMENT_CREATE, createInvestment),
    takeLatest(types.INVESTMENT_DELETE, deleteInvestment),
    takeLatest(types.INVESTMENT_FETCH, fetchInvestment),
    takeLatest(types.INVESTMENT_UPDATE, updateInvestment),
    takeEvery(types.INVESTMENTS_LIST, listInvestments),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default investmentSaga;
