import api from '../api';

const fetchBeneficiary = ({ id }) => api.get(`/api/beneficiaries/${id}`);

const createBeneficiary = beneficiary => api.post('/api/beneficiaries/', beneficiary);

const deleteBeneficiary = ({ id }) => api.delete(`/api/beneficiaries/${id}`);

const listBeneficiaries = query => api.get('/api/beneficiaries/', { params: query });

const updateBeneficiary = ({ id, ...beneficiary }) =>
  api.patch(`/api/beneficiaries/${id}/`, beneficiary);

export default {
  createBeneficiary,
  deleteBeneficiary,
  fetchBeneficiary,
  listBeneficiaries,
  updateBeneficiary,
};
