import ImgCrop from 'antd-img-crop';
import PropTypes from 'prop-types';
import TranslationsContext from 'providers/translationsProvider';
import { useContext } from 'react';

/**
 * Returns wrapped children with ImgCrop to crop the uploaded image if `withCrop` prop is true.
 * Otherwise it return its children.
 * Expected children is an Upload component from Antd
 *
 * @param {boolean} props.withCrop - Whether to wrap children with ImgCrop.
 * @param {boolean} props.aspect - The aspect ratio the image will be cropped with. width/height
 * @param {ReactNode} props.children - Upload component from Antd.
 * @returns {ReactNode} Rendered component.
 */
const ImageCropWrapper = ({ withCrop = false, aspect = 1, children }) => {
  const { t } = useContext(TranslationsContext);

  if (withCrop) {
    return (
      <ImgCrop
        aspect={aspect}
        modalOk={t('Ok')}
        modalCancel={t('Cancel')}
        modalTitle={t('Edit image')}
      >
        {children}
      </ImgCrop>
    );
  }

  return children;
};

ImageCropWrapper.propTypes = {
  withCrop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  aspect: PropTypes.number,
};

export default ImageCropWrapper;
