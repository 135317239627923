import { Record } from 'immutable';

const LinkRecord = Record({
  url: '',
  type: null,
});

class Link extends LinkRecord {
  get getUrl() {
    return this.get('url');
  }

  get getType() {
    return this.get('type');
  }
}

export default Link;
