import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import * as Sentry from '@sentry/react';
import User from 'models/User';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    isAuthenticated: false,
    data: new User(),
    errorMessage: '',
    userIsAccessible: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.USER_ACCESSIBILITY:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          userIsAccessible: true,
        })
      );
    case types.USER_FETCH_REQUESTED:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.USER_FETCH_SUCCEEDED:
      Sentry.configureScope(scope => {
        scope.setUser({
          email: action.payload.username,
          id: action.payload.id,
          member: action.payload.member ? action.payload.member.id : null,
          mentor: action.payload.mentor ? action.payload.mentor.id : null,
        });
      });
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: new User(action.payload),
          isAuthenticated: true,
        })
      );
    case types.USER_EDIT_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: new User(action.payload),
        })
      );
    case types.USER_FORGOT_PASSWORD_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          forgotPasswordRequested: true,
        })
      );
    case types.USER_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.USER_FETCH_FAILED:
    case types.USER_RESET_PASSWORD_FAILED:
    case types.USER_REGISTER_FAILED:
    case types.USER_EDIT_FAILED:
    case types.USER_FORGOT_PASSWORD_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
