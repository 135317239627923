import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import GrowthRisk from 'models/GrowthPlan/GrowthRisk';
import serviceGrowthRisk from 'services/GrowthPlan/growthRisk';
import types from './types';
import actions from './actions';

export function* createGrowthRisk(action) {
  const { ...growthRisk } = action.payload;
  try {
    const response = yield call(serviceGrowthRisk.createGrowthRisk, {
      ...growthRisk,
    });
    yield put(actions.createGrowthRiskSucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.createGrowthRiskFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteGrowthRisk(action) {
  const { id } = action.payload;
  try {
    yield call(serviceGrowthRisk.deleteGrowthRisk, { id });
    yield put(actions.deleteGrowthRiskSucceeded({ id }));
  } catch (e) {
    yield put(
      actions.deleteGrowthRiskFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchGrowthRisk(action) {
  const { id } = action.payload;
  try {
    const response = yield call(serviceGrowthRisk.fetchGrowthRisk, { id });
    yield put(actions.fetchGrowthRiskSucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchGrowthRiskFailed({ message: e.message }));
  }
}

export function* listGrowthRisks(action) {
  const { project } = action.payload;
  try {
    const growthRisks = yield call(serviceGrowthRisk.listGrowthRisks, {
      project,
    });
    yield put(actions.listGrowthRisksSucceeded({ results: growthRisks.data }));
  } catch (e) {
    yield put(actions.listGrowthRisksFailed({ message: e.message }));
  }
}

export function* updateGrowthRisk(action) {
  const { id, ...updatedGrowthRisk } = action.payload;
  try {
    const updated = yield call(serviceGrowthRisk.updateGrowthRisk, {
      id,
      ...updatedGrowthRisk,
    });
    yield put(actions.updateGrowthRiskSucceeded({ ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateGrowthRiskFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === GrowthRisk.contentType) {
    yield put(
      actions.fetchGrowthRisk({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* growthRiskSaga() {
  yield all([
    takeLatest(types.GROWTH_RISK_CREATE, createGrowthRisk),
    takeLatest(types.GROWTH_RISK_DELETE, deleteGrowthRisk),
    takeLatest(types.GROWTH_RISK_FETCH, fetchGrowthRisk),
    takeLatest(types.GROWTH_RISK_UPDATE, updateGrowthRisk),
    takeEvery(types.GROWTH_RISKS_LIST, listGrowthRisks),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default growthRiskSaga;
