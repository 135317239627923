// Libraries
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

// Dependencies
import { semiBoldStyle } from 'components/fontSystem';

// Components
import ToolContainer from '../ToolContainer/index';

const ToolOutputComponent = props => {
  const { children, color, ...rest } = props;
  return (
    <ToolContainer {...rest}>
      <div className="default">{children}</div>
    </ToolContainer>
  );
};

ToolOutputComponent.propTypes = {
  children: PropTypes.node,
  filled: PropTypes.bool,
  color: PropTypes.string,
};

ToolOutputComponent.defaultProps = {
  children: null,
  filled: true,
  color: undefined,
};

// Output blinking keyframes
const blinkingAnimation = props => keyframes`
  0% { box-shadow: none; }
  50% { box-shadow: 0px 0px 0px 5px ${props.theme.getToolColor(props.colorIndex)}66; }
  100% { box-shadow: none; }
`;

const ToolOutput = styled(ToolOutputComponent)`
  ${({ blinking }) =>
    blinking &&
    css`
      animation: ${props => blinkingAnimation(props)} 2000ms linear infinite;
    `};
  ${({ empty }) =>
    empty &&
    css`
      opacity: 0.3;
    `}
  padding: 8px;
  -webkit-print-color-adjust: exact;
  .default {
    ${semiBoldStyle}
    color: ${({ color }) => color || 'white'};
    font-size: 15px;
    line-height: 25px;
    margin: 0;
    min-height: 25px;
  }
`;

export default ToolOutput;
