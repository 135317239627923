import sortByField from 'helper/Sorters/sortByField';
import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { list: [], byStatus: { empty: [], withContent: [] } },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PILLS_CATEGORIES_FETCH:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.PILLS_CATEGORIES_FETCH_SUCCEEDED: {
      const categoriesByStatus = action.payload.reduce(
        (byStatus, pillCategory) => {
          const key = pillCategory.pills_count <= 0 ? 'empty' : 'withContent';
          byStatus[key].push(pillCategory);
          return byStatus;
        },
        { empty: [], withContent: [] }
      );
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            list: action.payload,
            byStatus: {
              empty: categoriesByStatus.empty.sort(sortByField('title')),
              withContent: categoriesByStatus.withContent.sort(sortByField('title')),
            },
          },
        })
      );
    }
    case types.PILLS_CATEGORIES_FETCH_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
