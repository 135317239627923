import api from '../api';

const createResource = ({ ...resource }) => api.post('/api/resources/', { ...resource });

const deleteResource = ({ id }) => api.delete(`/api/resources/${id}/`);

const fetchResource = ({ id }) => api.get(`/api/resources/${id}/`);

const listResources = ({ system }) => api.get(`/api/resources/?system=${system}`);

const updateResource = ({ id, ...resource }) => api.patch(`/api/resources/${id}/`, { ...resource });

export default {
  createResource,
  deleteResource,
  fetchResource,
  listResources,
  updateResource,
};
