import { Record, Map } from 'immutable';
import { byId } from 'store/utils/sortFunctions';
import ContentType from '../ContentType';
import MonthlyExpense from './MonthlyExpense';

const YEARLY_EXPENSES_YEAR_1 = '0';
const YEARLY_EXPENSES_YEAR_2 = '1';
const YEARLY_EXPENSES_YEAR_3 = '2';

export const YEARLY_EXPENSES = [
  YEARLY_EXPENSES_YEAR_1,
  YEARLY_EXPENSES_YEAR_2,
  YEARLY_EXPENSES_YEAR_3,
];

const TYPE_ADVERTISING = 0;
const TYPE_AUTO = 1;
const TYPE_COMMISSIONS_FEES = 2;
const TYPE_CONTRACT_LABOR = 3;
const TYPE_EQUIPMENT_RENTAL_SUPPLIES = 4;
const TYPE_FACILITIES_LAND_BUILDINGS = 5;
const TYPE_INSURANCE = 6;
const TYPE_LEGAL_PROFESSIONAL_SERVICES = 7;
const TYPE_OFFICE_SPACE = 8;
const TYPE_OFFICE_SUPPLIES_SOFTWARE = 9;
const TYPE_REGISTRATIONS_LICENSES_PERMITS = 10;
const TYPE_TRAINING_DEVELOPMENT = 11;
const TYPE_TRAVEL_MEALS_ENTERTAINMENT = 12;
const TYPE_UTILITIES = 13;
const TYPE_OTHER = 14;

const TYPE_CHOICES = [
  [TYPE_ADVERTISING, 'advertising_promotion'],
  [TYPE_AUTO, 'auto_truck'],
  [TYPE_COMMISSIONS_FEES, 'comissions_feeds'],
  [TYPE_CONTRACT_LABOR, 'contract_labor'],
  [TYPE_EQUIPMENT_RENTAL_SUPPLIES, 'equipment_rental_supplies'],
  [TYPE_FACILITIES_LAND_BUILDINGS, 'facilities_land_buildings'],
  [TYPE_INSURANCE, 'insurance'],
  [TYPE_LEGAL_PROFESSIONAL_SERVICES, 'legal_professional_services'],
  [TYPE_OFFICE_SPACE, 'office_space'],
  [TYPE_OFFICE_SUPPLIES_SOFTWARE, 'office_supplies_sosftware'],
  [TYPE_REGISTRATIONS_LICENSES_PERMITS, 'registration_licenses_permits'],
  [TYPE_TRAINING_DEVELOPMENT, 'training_development'],
  [TYPE_TRAVEL_MEALS_ENTERTAINMENT, 'travel_meals_entertainment'],
  [TYPE_UTILITIES, 'utilities'],
  [TYPE_OTHER, 'other'],
];

const ExpenseRecord = Record({
  contentType: ContentType.EXPENSE,
  id: null,
  scenario: null,
  created: null,
  updated: null,
  type: null,
  name: null,
  comments: null,
  yearly_expenses: {
    [YEARLY_EXPENSES_YEAR_1]: [],
    [YEARLY_EXPENSES_YEAR_2]: [],
    [YEARLY_EXPENSES_YEAR_3]: [],
  },
  yearly_totals: {},
});

class Expense extends ExpenseRecord {
  static contentType = ContentType.EXPENSE;

  static TYPE_ADVERTISING = TYPE_ADVERTISING;
  static TYPE_AUTO = TYPE_AUTO;
  static TYPE_COMMISSIONS_FEES = TYPE_COMMISSIONS_FEES;
  static TYPE_CONTRACT_LABOR = TYPE_CONTRACT_LABOR;
  static TYPE_EQUIPMENT_RENTAL_SUPPLIES = TYPE_EQUIPMENT_RENTAL_SUPPLIES;
  static TYPE_FACILITIES_LAND_BUILDINGS = TYPE_FACILITIES_LAND_BUILDINGS;
  static TYPE_INSURANCE = TYPE_INSURANCE;
  static TYPE_LEGAL_PROFESSIONAL_SERVICES = TYPE_LEGAL_PROFESSIONAL_SERVICES;
  static TYPE_OFFICE_SPACE = TYPE_OFFICE_SPACE;
  static TYPE_OFFICE_SUPPLIES_SOFTWARE = TYPE_OFFICE_SUPPLIES_SOFTWARE;
  static TYPE_REGISTRATIONS_LICENSES_PERMITS = TYPE_REGISTRATIONS_LICENSES_PERMITS;
  static TYPE_TRAINING_DEVELOPMENT = TYPE_TRAINING_DEVELOPMENT;
  static TYPE_TRAVEL_MEALS_ENTERTAINMENT = TYPE_TRAVEL_MEALS_ENTERTAINMENT;
  static TYPE_UTILITIES = TYPE_UTILITIES;
  static TYPE_OTHER = TYPE_OTHER;
  static TYPE_CHOICES = TYPE_CHOICES;

  static getDisplayName = (type, t) => {
    switch (type) {
      case TYPE_ADVERTISING:
        return t('Advertising & Promotion');
      case TYPE_AUTO:
        return t('Auto & Truck');
      case TYPE_COMMISSIONS_FEES:
        return t('Commissions & Fees');
      case TYPE_CONTRACT_LABOR:
        return t('Contract Labor (not included in payroll)');
      case TYPE_EQUIPMENT_RENTAL_SUPPLIES:
        return t('Equipment Rental & Supplies');
      case TYPE_FACILITIES_LAND_BUILDINGS:
        return t('Facilities, Land, Buildings (Rental & Maintenance)');
      case TYPE_INSURANCE:
        return t('Insurance');
      case TYPE_LEGAL_PROFESSIONAL_SERVICES:
        return t('Legal & Professional Services');
      case TYPE_OFFICE_SPACE:
        return t('Office Space (Rental & Maintenance)');
      case TYPE_OFFICE_SUPPLIES_SOFTWARE:
        return t('Office Supplies & Software');
      case TYPE_REGISTRATIONS_LICENSES_PERMITS:
        return t('Registrations, Licenses, Permits');
      case TYPE_TRAINING_DEVELOPMENT:
        return t('Training & Development');
      case TYPE_TRAVEL_MEALS_ENTERTAINMENT:
        return t('Travel, Meals, Entertainment');
      case TYPE_UTILITIES:
        return t('Utilities');
      case TYPE_OTHER:
        return t('Other Expenses');
      default:
        return undefined;
    }
  };

  constructor(payload) {
    const expense = { ...payload };
    const yearlyExpenses = {
      ...expense.yearly_expenses,
    };
    const yearlyTotals = {
      [YEARLY_EXPENSES_YEAR_1]: 0,
      [YEARLY_EXPENSES_YEAR_2]: 0,
      [YEARLY_EXPENSES_YEAR_3]: 0,
    };
    // Normalizing data and calculating the yearly expense totals:
    YEARLY_EXPENSES.forEach(year => {
      // immutable.js leads to an unintended mutation of the yearlyExpenses.
      // The problem is that the yearly_totals aren't calculated, so we must
      // mutate it back to JavaScript and calculate the new yearly totals.
      const expenses = Array.isArray(yearlyExpenses[year])
        ? yearlyExpenses[year]
        : yearlyExpenses[year].valueSeq().toJS();
      const normalizedData = expenses
        .reduce((prevData, monthlyExpense) => {
          yearlyTotals[String(year)] += monthlyExpense.amount;
          return prevData.set(
            String(monthlyExpense.id),
            new MonthlyExpense({
              ...monthlyExpense,
              expense: expense.id,
              year,
            })
          );
        }, new Map())
        .sort(byId);
      expense.yearly_expenses[year] = normalizedData;
    });
    expense.yearly_totals = yearlyTotals;
    super(expense);
  }

  get getId() {
    return this.get('id');
  }

  static getExpenseTotal = yearlyTotals => {
    const total = Object.values(yearlyTotals).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return total;
  };
}

export default Expense;
