import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Interestship from 'models/Matching/Interestship';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byProgram: {} },
    invites: { byId: {}, byProgram: {}, count: 0 }, // Interetship endpoint V2
    loading: false,
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.INTERESTSHIPS_LIST_PENDING:
    case types.INTERESTSHIPS_LIST_MP_PENDING:
      return state.merge(
        reduxHelper.applyRequestInfo({
          loading: true,
        })
      );
    case types.INTERESTSHIPS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, interestship) =>
                prevData.set(interestship.id.toString(), new Interestship(interestship)).sort(byId),
              state.get('data').get('byId')
            ),
            byProgram: state
              .get('data')
              .get('byProgram')
              .set(
                action.payload.program.toString(),
                fromJS(
                  action.payload.results
                    .map(interestship => new Interestship(interestship))
                    .sort(byId)
                )
              ),
          },
          loading: false,
        })
      );
    case types.INTERESTSHIPS_LIST_MP_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          invites: {
            byId: action.payload.results.reduce(
              (prevData, interestship) =>
                prevData.set(interestship.id.toString(), new Interestship(interestship)),
              fromJS({})
            ),
            byProgram: state
              .get('invites')
              .get('byProgram')
              .set(
                action.payload.program.toString(),
                fromJS(action.payload.results.map(interestship => new Interestship(interestship)))
              ),
            count: action.payload.count,
          },
          loading: false,
        })
      );
    case types.INTERESTSHIP_FETCH_SUCCEEDED:
    case types.INTERESTSHIP_CREATE_SUCCEEDED:
    case types.INTERESTSHIP_UPDATE_SUCCEEDED: {
      const programId = action.payload.program.toString();
      const programInterestships = state
        .get('data')
        .get('byProgram')
        .get(programId, new List())
        .filter(interestship => interestship.id !== action.payload.id)
        .push(new Interestship(action.payload))
        .sort(byId);
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new Interestship(action.payload)),
            byProgram: state.get('data').get('byProgram').set(programId, programInterestships),
          },
        })
      );
    }
    case types.INTERESTSHIP_FETCH:
    case types.INTERESTSHIP_CREATE:
    case types.INTERESTSHIP_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.INTERESTSHIP_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProgram: {
              ...state.get('data').get('byProgram').toJS(),
              [action.payload.program]: state
                .get('data')
                .get('byProgram')
                .get(action.payload.program.toString(), new List())
                .filter(interestship => interestship.id !== action.payload.id),
            },
          },
        })
      );
    case types.INTERESTSHIP_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.INTERESTSHIP_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.INTERESTSHIP_FETCH_FAILED:
    case types.INTERESTSHIPS_LIST_FAILED:
    case types.INTERESTSHIPS_LIST_MP_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
          loading: false,
        })
      );
    default:
      return state;
  }
}
