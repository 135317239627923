import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/executiveSummary';
import { types as commentTypes } from 'store/modules/comment';
import executiveSummaryService from 'services/executiveSummary';
import ExecutiveSummary from 'models/ExecutiveSummary/ExecutiveSummary';

export function* fetchExecutiveSummary(action) {
  const { projectId, step } = action.payload;
  try {
    const response = yield call(executiveSummaryService.fetchExecutiveSummary, {
      projectId,
      step,
    });
    yield put(actions.fetchExecutiveSummarySucceeded(response.data && response.data[0]));
  } catch (e) {
    yield put(actions.fetchExecutiveSummaryFailed({ message: e.message }));
  }
}

export function* updateExecutiveSummary(action) {
  const { id, ...executiveSummary } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(executiveSummaryService.updateExecutiveSummary, {
      id,
      ...executiveSummary,
    });
    yield put(actions.updateExecutiveSummarySucceeded(response.data));
    if (callback) callback();
  } catch (e) {
    yield put(actions.updateExecutiveSummaryFailed({ message: e.message }));
  }
}

export function* uploadExecutiveSummaryImage(action) {
  const { id, ...executiveSummary } = action.payload;
  try {
    const response = yield call(executiveSummaryService.uploadExecutiveSummaryImage, {
      id,
      ...executiveSummary,
    });
    yield put(actions.updateExecutiveSummarySucceeded(response.data));
  } catch (e) {
    yield put(actions.updateExecutiveSummaryFailed({ message: e.message }));
  }
}

// this might need corrections depending on where comments are
export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === ExecutiveSummary.contentType) {
    yield put(
      actions.fetchProject({
        executiveSummary: object.get('executiveSummary'),
        id: object.get('id'),
      })
    );
  }
}

function* executiveSummarySaga() {
  yield all([
    takeLatest(types.EXECUTIVE_SUMMARY_UPDATE, updateExecutiveSummary),
    takeLatest(types.EXECUTIVE_SUMMARY_UPLOAD_IMAGE, uploadExecutiveSummaryImage),
    takeLatest(types.EXECUTIVE_SUMMARY_FETCH, fetchExecutiveSummary),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
  ]);
}

export default executiveSummarySaga;
