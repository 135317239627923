import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Metric from 'models/GrowthPlan/Metric';
import serviceMetric from 'services/GrowthPlan/metric';
import types from './types';
import actions from './actions';

export function* createMetric(action) {
  const { project, ...metric } = action.payload;
  try {
    const response = yield call(serviceMetric.createMetric, {
      project,
      ...metric,
    });
    yield put(actions.createMetricSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(
      actions.createMetricFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteMetric(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceMetric.deleteMetric, { id });
    yield put(actions.deleteMetricSucceeded({ project, id }));
  } catch (e) {
    yield put(
      actions.deleteMetricFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchMetric(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceMetric.fetchMetric, { id });
    yield put(actions.fetchMetricSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(actions.fetchMetricFailed({ message: e.message }));
  }
}

export function* listMetrics(action) {
  const { project } = action.payload;
  try {
    const metrics = yield call(serviceMetric.listMetrics, { project });
    yield put(actions.listMetricsSucceeded({ project, results: metrics.data }));
  } catch (e) {
    yield put(actions.listMetricsFailed({ message: e.message }));
  }
}

export function* updateMetric(action) {
  const { id, project, ...updatedMetric } = action.payload;
  try {
    const updated = yield call(serviceMetric.updateMetric, {
      id,
      ...updatedMetric,
    });
    yield put(actions.updateMetricSucceeded({ project, ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateMetricFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Metric.contentType) {
    yield put(
      actions.fetchMetric({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* metricSaga() {
  yield all([
    takeLatest(types.METRIC_CREATE, createMetric),
    takeLatest(types.METRIC_DELETE, deleteMetric),
    takeLatest(types.METRIC_FETCH, fetchMetric),
    takeLatest(types.METRIC_UPDATE, updateMetric),
    takeEvery(types.METRICS_LIST, listMetrics),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default metricSaga;
