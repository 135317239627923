import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Sdg from 'models/ImpactPlan/Sdg';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      list: [],
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SDG_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (sdgsById, sdg) => sdgsById.set(String(sdg.id), new Sdg(sdg)),
              state.get('data').get('byId')
            ),
            list: action.payload.results,
          },
        })
      );
    case types.SDG_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
