import { types } from 'store/modules/ImpactPlan/rule';

const clearError = () => ({
  type: types.RULE_CLEAR_ERROR,
});

const createRule = ({ ...rule }) => ({
  type: types.RULE_CREATE,
  payload: { ...rule },
});

const createRuleSucceeded = ({ id, ...rule }) => ({
  type: types.RULE_CREATE_SUCCEEDED,
  payload: { id, ...rule },
});

const createRuleFailed = ({ message }) => ({
  type: types.RULE_CREATE_FAILED,
  payload: { message },
});

const deleteRule = ({ id, system }) => ({
  type: types.RULE_DELETE,
  payload: { id, system },
});

const deleteRuleSucceeded = ({ id, system }) => ({
  type: types.RULE_DELETE_SUCCEEDED,
  payload: { id, system },
});

const deleteRuleFailed = ({ message }) => ({
  type: types.RULE_DELETE_FAILED,
  payload: { message },
});

const fetchRule = ({ id }) => ({
  type: types.RULE_FETCH,
  payload: { id },
});

const fetchRuleSucceeded = ({ id, ...rule }) => ({
  type: types.RULE_FETCH_SUCCEEDED,
  payload: { id, ...rule },
});

const fetchRuleFailed = ({ message }) => ({
  type: types.RULE_FETCH_FAILED,
  payload: { message },
});

const listRules = ({ system }) => ({
  type: types.RULES_LIST,
  payload: { system },
});

const listRulesSucceeded = payload => ({
  type: types.RULES_LIST_SUCCEEDED,
  payload,
});

const listRulesFailed = error => ({
  type: types.RULES_LIST_FAILED,
  payload: error,
});

const updateRule = ({ id, ...rule }) => ({
  type: types.RULE_UPDATE,
  payload: { id, ...rule },
});

const updateRuleSucceeded = ({ id, ...rule }) => ({
  type: types.RULE_UPDATE_SUCCEEDED,
  payload: { id, ...rule },
});

const updateRuleFailed = ({ message }) => ({
  type: types.RULE_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createRule,
  createRuleFailed,
  createRuleSucceeded,
  deleteRule,
  deleteRuleFailed,
  deleteRuleSucceeded,
  fetchRule,
  fetchRuleFailed,
  fetchRuleSucceeded,
  listRules,
  listRulesFailed,
  listRulesSucceeded,
  updateRule,
  updateRuleSucceeded,
  updateRuleFailed,
};
