import api from './api';

const getWorkExperience = query => api.get(`/api/work_experiences/?user=${query.user}`);

const updateWorkExperience = workExperienceData =>
  api.patch(`/api/work_experiences/${workExperienceData.id}/`, {
    ...workExperienceData,
  });

const deleteWorkExperience = workExperienceId =>
  api.delete(`/api/work_experiences/${workExperienceId}/`);

const createWorkExperience = workExperienceData =>
  api.post('/api/work_experiences/', { ...workExperienceData });

export default {
  getWorkExperience,
  deleteWorkExperience,
  updateWorkExperience,
  createWorkExperience,
};
