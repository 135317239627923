const MEMBER_FETCH = 'bfb/member/MEMBER_FETCH';
const MEMBER_FETCH_SUCCEEDED = 'bfb/member/MEMBER_FETCH_SUCCEEDED';
const MEMBER_FETCH_FAILED = 'bfb/member/MEMBER_FETCH_FAILED';
const MEMBER_EDIT = 'bfb/member/MEMBER_EDIT';
const MEMBER_EDIT_SUCCEEDED = 'bfb/member/MEMBER_EDIT_SUCCEEDED';
const MEMBER_EDIT_FAILED = 'bfb/member/MEMBER_EDIT_FAILED';

export default {
  MEMBER_FETCH,
  MEMBER_FETCH_SUCCEEDED,
  MEMBER_FETCH_FAILED,
  MEMBER_EDIT,
  MEMBER_EDIT_SUCCEEDED,
  MEMBER_EDIT_FAILED,
};
