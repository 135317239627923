// Libraries
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { TimePicker as AntTimePicker } from 'antd';
import { Form } from 'components/elements';

// Dependencies
import { DateTimePickerStyle } from '../style';

function TimePicker(props) {
  const { name, initialTime, label, format, onBlur, form, t, ...rest } = props;

  const handleOnChange = pickerTime => {
    form.setFieldsValue({ [name]: pickerTime });
    const event = { target: { value: pickerTime } };
    if (onBlur) onBlur(event, { ...rest });
  };

  return (
    // TODO: This Form.Item should not be inside the component
    <Form.Item
      label={label}
      rules={[{ required: true, message: t('This field is required') }]}
      name={name}
      initialValue={initialTime}
    >
      <StyledTimePicker
        onChange={e => handleOnChange(e)}
        format={format}
        bordered={false}
        {...rest}
      />
    </Form.Item>
  );
}

const StyledTimePicker = styled(AntTimePicker)`
  &&& {
    ${DateTimePickerStyle}
  }
`;

export default TimePicker;

TimePicker.propTypes = {
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  initialTime: PropTypes.number.isRequired,
  format: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
