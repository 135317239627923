import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CountryIcon } from 'components/elements';
import { P } from 'components/fontSystem';
import InfoBubble from 'components/elements/InfoBubble';

const FieldWithDecorator = ({
  className,
  children = null,
  decorator = null,
  dummy = null,
  flag = null,
  icon = null,
  text = null,
  infotext = '',
}) => {
  const content = children || (text && <P>{text}</P>);

  return (
    <div className={className}>
      {/* Field Label */}
      {(icon || decorator) && (
        <div className="label">
          {icon && <img className="icon" src={icon} alt="Icon" />}
          {decorator && <b>{decorator}</b>}
          {infotext && <InfoBubble key="info-help-field-decorator" infotext={infotext} />}
        </div>
      )}

      {/* Field Content */}
      <div className="field-content">
        {flag && <CountryIcon className="flag" countryCode={flag} />}
        {content || <P>{dummy || '—'}</P>}
      </div>
    </div>
  );
};

const StyledFieldWithDecorator = styled(FieldWithDecorator)`
  margin-top: ${props => props.mt || '20px'};
  width: ${props => props.width || ''};
  overflow: hidden;

  .label {
    margin-bottom: 10px;

    display: flex;
    gap: 10px;
  }

  b {
    color: ${props => props.color || 'black'};
    font-weight: ${props => props.fweight || '600'};
    margin-right: 5px;
  }

  .icon {
    width: 20px;
  }

  .flag {
    height: auto;
    width: 22px;
    margin-right: 5px;
  }

  p {
    line-height: 22px;
    display: inline;
    padding-left: ${props => (props.flag ? '10px' : '0px')};
  }

  &:has(.icon) .field-content {
    margin-left: 30px;
  }
`;

FieldWithDecorator.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  decorator: PropTypes.string,
  dummy: PropTypes.node,
  flag: PropTypes.node,
  icon: PropTypes.node,
  text: PropTypes.node,
  infotext: PropTypes.string,
};

export default StyledFieldWithDecorator;
