import { createSelector } from 'reselect';

const getState = state => state.pricingBusinessViabilityPdf;

const getData = createSelector([getState], state => state.get('data'));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getData,
  getErrorMessage,
  isFetching,
};
