import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/organization';
import organizationService from 'services/organization';

export function* listOrganizations() {
  try {
    const response = yield call(organizationService.listOrganizations);
    yield put(actions.listOrganizationsSucceeded(response.data.results));
  } catch (e) {
    yield put(actions.listOrganizationsFailed({ message: e.message }));
  }
}

export function* fetchOrganization(action) {
  const { id } = action.payload;
  try {
    const response = yield call(organizationService.fetchOrganization, { id });
    yield put(actions.fetchOrganizationSucceeded(response.data));
  } catch (e) {
    yield put(actions.fetchOrganizationFailed({ message: e.message }));
  }
}

function* organizationSaga() {
  yield all([
    takeEvery(types.ORGANIZATIONS_LIST, listOrganizations),
    takeLatest(types.ORGANIZATION_FETCH, fetchOrganization),
  ]);
}

export default organizationSaga;
