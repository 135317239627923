import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/areasOfExpertise';
import serviceAreas from 'services/areasOfExpertise';

export function* fetchAreasOfExpertise(action) {
  try {
    const areas = yield call(serviceAreas.listAreasOfExpertise, action.payload);
    yield put(
      actions.fetchAreasOfExpertiseSucceeded({
        areasOfExpertiseData: areas.data,
      })
    );
  } catch (e) {
    yield put(
      actions.fetchAreasOfExpertiseFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* areasOfExpertiseSaga() {
  yield all([takeLatest(types.AREASOFEXPERTISE_FETCH, fetchAreasOfExpertise)]);
}

export default areasOfExpertiseSaga;
