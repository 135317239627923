import axios from 'axios';

// Loom functions
const getLoomId = src => {
  const match = src.match(/(?:http?s?:\/\/)?(?:www\.)?(?:loom\.com)\/(?:share)\/?(.+)/);
  return match && match[1];
};

const getLoomIframeSrc = src => {
  const loomId = getLoomId(src);
  return loomId && `//www.loom.com/embed/${loomId}`;
};

// Vimeo functions
const getVimeoId = src => {
  const match = src.match(/(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/);
  return match && match[1];
};

const getVimeoIframeSrc = src => {
  const vimeoId = getVimeoId(src);
  return vimeoId && `//player.vimeo.com/video/${vimeoId}`;
};

const getVimeoThumbnail = async src => {
  const isVimeoVideo = getVimeoId(src);
  if (!isVimeoVideo) return null;
  const apiURL = `https://vimeo.com/api/oembed.json?url=${src}`;
  const response = await axios.get(apiURL);
  return response.data.thumbnail_url;
};

// Youtube functions
const getYoutubeId = src => {
  const match = src.match(
    /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/
  );
  // .split('?') here is to extract just the id, it was not working before because
  // it was getting this URL "https://youtu.be/QtKGMfeyPUE?si=6WJePVGkuCgeUgII" as input
  // and returning this ID as output "ZqdzjQZr374?si=MQAxRamHscPM7wor"
  // we just need the video id with no additional query params
  return match && match[1].split('?')[0];
};

const getYoutubeIframeSrc = src => {
  const youtubeId = getYoutubeId(src);
  return youtubeId && `//www.youtube.com/embed/${youtubeId}`;
};

const getYoutubeThumbnail = async src => {
  const videoId = getYoutubeId(src);
  if (!videoId) return null;
  return `http://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
};

// Combiners
export const getIframeSrc = src => {
  if (!src) return null;
  const vimeoURL = getVimeoIframeSrc(src);
  const youtubeURL = getYoutubeIframeSrc(src);
  const loomURL = getLoomIframeSrc(src);
  return vimeoURL || youtubeURL || loomURL;
};

export const getThumbnail = src => {
  const promises = [getVimeoThumbnail(src), getYoutubeThumbnail(src)];
  return Promise.allSettled(promises);
};
