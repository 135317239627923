// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function ToolWarning({ children, ...rest }) {
  return (
    <StyledDiv hasChildren={Boolean(children)} {...rest}>
      <span>{children}</span>
    </StyledDiv>
  );
}

ToolWarning.propTypes = {
  children: PropTypes.node,
};

ToolWarning.defaultProps = {
  children: null,
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  background-color: #f2f2f2;
  padding: 10px 20px;

  span {
    color: #999999;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }
`;
