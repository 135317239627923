const PROGRAM_FETCH = 'bfb/program/PROGRAM_FETCH';
const PROGRAM_FETCH_SUCCEEDED = 'bfb/program/PROGRAM_FETCH_SUCCEEDED';
const PROGRAM_FETCH_FAILED = 'bfb/program/PROGRAM_FETCH_FAILED';
const PROGRAM_SEARCH = 'bfb/program/PROGRAM_SEARCH';
const PROGRAM_SEARCH_SUCCEEDED = 'bfb/program/PROGRAM_SEARCH_SUCCEEDED';
const PROGRAM_SEARCH_FAILED = 'bfb/program/PROGRAM_SEARCH_FAILED';
const PROGRAMS_LIST = 'bfb/program/PROGRAMS_LIST';
const PROGRAMS_LIST_SUCCEEDED = 'bfb/program/PROGRAMS_LIST_SUCCEEDED';
const PROGRAMS_LIST_FAILED = 'bfb/program/PROGRAMS_LIST_FAILED';
const PROGRAM_UPDATE = 'bfb/program/PROGRAM_UPDATE';
const PROGRAM_UPDATE_BY_ORGANIZATION = 'bfb/program/PROGRAM_UPDATE_BY_ORGANIZATION';
const PROGRAM_UPDATE_SUCCEEDED = 'bfb/program/PROGRAM_UPDATE_SUCCEEDED';
const PROGRAM_UPDATE_FAILED = 'bfb/program/PROGRAM_UPDATE_FAILED';
const SELECTED_PROGRAM_UPDATE = 'bfb/program/SELECTED_PROGRAM_UPDATE';
const PROGRAMS_SECTIONS_LIST_SUCCEEDED = 'bfb/program/PROGRAMS_SECTIONS_LIST_SUCCEEDED';
const PROGRAMS_SECTIONS_LIST_FAILED = 'bfb/program/PROGRAMS_SECTIONS_LIST_FAILED';
const PROGRAMS_SECTIONS_LIST = 'bfb/program/PROGRAMS_SECTIONS_LIST';

export default {
  PROGRAM_FETCH,
  PROGRAM_FETCH_SUCCEEDED,
  PROGRAM_FETCH_FAILED,
  PROGRAMS_LIST,
  PROGRAMS_LIST_SUCCEEDED,
  PROGRAMS_LIST_FAILED,
  PROGRAM_UPDATE,
  PROGRAM_UPDATE_BY_ORGANIZATION,
  PROGRAM_UPDATE_SUCCEEDED,
  PROGRAM_UPDATE_FAILED,
  SELECTED_PROGRAM_UPDATE,
  PROGRAM_SEARCH,
  PROGRAM_SEARCH_FAILED,
  PROGRAM_SEARCH_SUCCEEDED,
  PROGRAMS_SECTIONS_LIST_SUCCEEDED,
  PROGRAMS_SECTIONS_LIST_FAILED,
  PROGRAMS_SECTIONS_LIST,
};
