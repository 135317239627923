import dayjs from 'dayjs';

const byId = (a, b) => a.id - b.id;
const byMySelf = (a, b) => {
  if (a.myself) return -1;
  if (b.myself) return 1;
  return byId(a, b);
};

const byCreatedDescent = (a, b) => {
  return dayjs(b.created) - dayjs(a.created);
};

export { byId, byMySelf, byCreatedDescent };
