import api from './api';

const endpoints = {
  value_proposition: 'value-proposition-vbp', // Tool 1
  competition_map: 'competition-map-vbp', // Tool 2
  stakeholder_map: 'stakeholder-map-vbp', // Tool 3
  business_model_marketing: 'business-model-marketing-vbp', // Tool 4
  pricing_business_viability: 'pricing-business-viability-vbp', // Tool 5
  financial_projections: 'financial-projections-vbp', // Tool 6
  impact_plan: 'impact-plan-vbp', // Tool 7
  growth_plan: 'growth-plan-vbp', // Tool 8
};

const fetchData = ({ endpoint, ...query }) => api.get(`api/${endpoint}/`, { params: query });

export default {
  fetchData,
  endpoints,
};
