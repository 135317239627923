// About you
export const INPUT_PROFILE_PICTURE = 'profile_picture';
export const INPUT_FIRST_NAME = 'first_name';
export const INPUT_LAST_NAME = 'last_name';
export const INPUT_GENDER = 'gender';
export const INPUT_BIRTHDAY = 'birthday';
export const INPUT_ORIGIN = 'origin';
export const INPUT_COUNTRY = 'country';
export const INPUT_CITY = 'city';
export const INPUT_LANGUAGES = 'languages';
export const INPUT_BIO = 'biography';
export const INPUT_LINKEDIN = 'linkedin';
export const INPUT_PHONE_NUMBER = 'phone_number';
export const INPUT_ACCESS_TO_COMPUTER = 'has_regular_access_to_computer';
export const INPUT_COMMITMENT = 'can_commit_several_hours';

// Venture Basics
export const INPUT_LANGUAGE = 'language';
export const INPUT_VENTURE_NAME = 'name';
export const INPUT_PRODUCTS = 'products_names';
export const INPUT_SECTOR = 'area_innovation';
export const INPUT_VENTURE_DESCRIPTION = 'summary';
export const INPUT_STAGES = 'stages';
export const INPUT_WEBSITE = 'website';

// Venture Details
export const INPUT_MISSION = 'mission';
export const INPUT_ADVANTAGE = 'competitive_advantage';
export const INPUT_BUSINESS_MODEL = 'business_model';
export const INPUT_MILESTONES = 'milestones';
export const INPUT_MOTIVATION = 'motivation';
export const INPUT_EXPERTISE_NEEDED = 'expertise_needed';
export const INPUT_SOCIAL_IMPACT = 'social_impact';
export const INPUT_VIDEO = 'video_url';

// Demographics
export const INPUT_INCOME = 'demo_income';
export const INPUT_APPLIED_TO_OTHER_PROGRAMS = 'applied_to_other_programs';

// Final checklist
export const INPUT_ALTERANTIVE_PROGRAM_PREFERENCE = 'participate_in_another_program_if_rejected';
export const INPUT_TEAMMATES = 'team';
export const INPUT_TEAM_DESCRIPTION = 'team_description';
export const INPUT_REFERRER = 'referrer';
export const INPUT_REFERRER_NAME = 'recommended_program_referrer';
export const INPUT_ANSWER_TO_ADDITIONAL_QUESTION = 'additional_answer';

// Form Sections
export const ABOUT_YOU = 'about_you';
export const VENTURE_BASICS = 'venture_basics';
export const VENTURE_DETAILS = 'venture_details';
export const DEMOGRAPHICS = 'demographics';
export const FINAL_CHECKLIST = 'final_checklist';

// E has applied to other support programs
export const STATUS_ACCEPTED = 0; // E was ACCEPTED
export const STATUS_REJECTED = 1; // E was REJECTED
export const STATUS_NO_SUPPORT = 2; // E hasn't found support before
