import types from './types';

const fetchProgram = ({ id }) => ({
  type: types.PROGRAM_FETCH,
  payload: { id },
});

const fetchPublicProgram = ({ id }) => ({
  type: types.PROGRAM_FETCH,
  payload: { public: true, id },
});

const fetchProgramSucceeded = ({ id, ...program }) => ({
  type: types.PROGRAM_FETCH_SUCCEEDED,
  payload: { id, ...program },
});

const fetchProgramFailed = ({ message }) => ({
  type: types.PROGRAM_FETCH_FAILED,
  payload: { message },
});

const searchPrograms = ({ query }) => ({
  type: types.PROGRAM_SEARCH,
  payload: { query },
});

const searchProgramSucceeded = ({ programs }) => ({
  type: types.PROGRAM_SEARCH_SUCCEEDED,
  payload: { programs },
});

const searchProgramFailed = ({ message }) => ({
  type: types.PROGRAM_SEARCH_FAILED,
  payload: { message },
});

const listPrograms = () => ({
  type: types.PROGRAMS_LIST,
});

const listOrganizationPrograms = ({ organization }) => ({
  type: types.PROGRAMS_LIST,
  payload: { organization },
});

const listPublicPrograms = () => ({
  type: types.PROGRAMS_LIST,
  payload: { public: true },
});

const listProgramsSections = () => ({
  type: types.PROGRAMS_SECTIONS_LIST,
});

const listProgramsSectionsSucceeded = payload => ({
  type: types.PROGRAMS_SECTIONS_LIST_SUCCEEDED,
  payload,
});

const listProgramsSectionsFailed = ({ message }) => ({
  type: types.PROGRAMS_SECTIONS_LIST_FAILED,
  payload: { message },
});

const listProgramsSucceeded = payload => ({
  type: types.PROGRAMS_LIST_SUCCEEDED,
  payload,
});

const listProgramsFailed = ({ message }) => ({
  type: types.PROGRAMS_LIST_FAILED,
  payload: { message },
});

const updateProgram = ({ id, ...program }) => ({
  type: types.PROGRAM_UPDATE,
  payload: { id, ...program },
});

const updateProgramByOrganization = ({ program, organization }, callback) => ({
  type: types.PROGRAM_UPDATE_BY_ORGANIZATION,
  payload: { program, organization, callback },
  callback,
});

const updateProgramSucceeded = ({ id, ...program }) => ({
  type: types.PROGRAM_UPDATE_SUCCEEDED,
  payload: { id, ...program },
});

const updateProgramFailed = ({ message }) => ({
  type: types.PROGRAM_UPDATE_FAILED,
  payload: message,
});

const updateSelectedProgram = ({ selectedProgram }) => ({
  type: types.SELECTED_PROGRAM_UPDATE,
  payload: { selectedProgram },
});

export default {
  fetchProgram,
  fetchPublicProgram,
  fetchProgramSucceeded,
  fetchProgramFailed,
  updateProgram,
  updateProgramByOrganization,
  updateProgramSucceeded,
  updateProgramFailed,
  listOrganizationPrograms,
  listPrograms,
  listPublicPrograms,
  listProgramsSections,
  listProgramsSectionsSucceeded,
  listProgramsSectionsFailed,
  listProgramsSucceeded,
  listProgramsFailed,
  searchPrograms,
  searchProgramSucceeded,
  searchProgramFailed,
  updateSelectedProgram,
};
