// Libraries
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Dependencies
import {
  ABOUT_YOU,
  EDUCATION,
  INPUT_BIRTHDAY,
  WORK_EXPERIENCE,
} from 'containers/MentorApplication/constants';
import { mentorApplicationApi } from 'services/apiSlices/applications/mentorApplicationApi';

dayjs.extend(utc);

const initialState = {
  id: null,
  isLoading: true,
  isUpdating: false,
  data: {
    about_you: {
      first_name: null,
      last_name: null,
      gender: null,
      birthday: null,
      origin: null,
      country: null,
      city: null,
      languages: [],
      phone_number: null,
      biography: null,
      linkedin: null,
      website: null,
    },
    expertise: {
      areas_expertise: [],
      areas_innovation: [],
    },
    work_experiences: [],
    education_experiences: [],
    motivation: {
      mentoring_experience: null,
      motivation: null,
      why_me: null,
      expectations: null,
      entrepreneur_before: null,
      startup_investor: null,
    },
    final_checklist: {
      check_commitment: null,
      check_confidential: null,
      check_online: null,
      recommended_program: null,
      recommended_program_referrer: null,
      GDPR: false,
    },
  },
};

const applicationMentorSlice = createSlice({
  name: 'applicationMentor',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(
        mentorApplicationApi.endpoints.getApplicationDraftById.matchFulfilled,
        (state, action) => {
          const { payload: mentorApplication } = action;

          // Transform birthday string into a date object
          const birthdayDayjs = mentorApplication[ABOUT_YOU][INPUT_BIRTHDAY]
            ? dayjs.utc(mentorApplication[ABOUT_YOU][INPUT_BIRTHDAY])
            : null;
          const aboutYou = {
            [ABOUT_YOU]: { ...mentorApplication[ABOUT_YOU], [INPUT_BIRTHDAY]: birthdayDayjs },
          };
          const workExperience = {
            [WORK_EXPERIENCE]:
              mentorApplication[WORK_EXPERIENCE].length > 0
                ? mentorApplication[WORK_EXPERIENCE] // render empty row if no data
                : [null],
          };
          const educationExperience = {
            [EDUCATION]:
              mentorApplication[EDUCATION].length > 0
                ? mentorApplication[EDUCATION] // render empty row if no data
                : [null],
          };
          const formattedApplication = {
            ...mentorApplication,
            ...aboutYou,
            ...workExperience,
            ...educationExperience,
          };

          // Load fetched data into the state
          state.data = formattedApplication;
          state.isLoading = false;
          state.id = action.meta.arg.originalArgs;
        }
      )
      .addMatcher(mentorApplicationApi.endpoints.updateApplication.matchPending, state => {
        state.isUpdating = true;
      })
      .addMatcher(
        mentorApplicationApi.endpoints.updateApplication.matchFulfilled,
        (state, action) => {
          // Sync state with all values from form when backend updated
          state.data = action.meta.arg.originalArgs.allValues;
          state.isUpdating = false;
        }
      )
      .addMatcher(mentorApplicationApi.endpoints.updateApplication.matchRejected, state => {
        state.isUpdating = false;
      });
  },
});

export const applicationMentorReducer = applicationMentorSlice.reducer;
