import { createSelector } from 'reselect';

const getState = state => state.sdgOutcome;

const getRawOutcomes = createSelector([getState], state => state.get('data'));

const getOutcomesById = createSelector([getRawOutcomes], state => state.get('byId'));

const getOutcomeOfId = createSelector([getOutcomesById], state => id => state.toJS()[id]);

const getOutcomes = createSelector([getOutcomesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getOutcomes,
  getOutcomesById,
  getOutcomeOfId,
  getErrorMessage,
};
