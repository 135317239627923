import { call, put, all, takeLatest } from 'redux-saga/effects';
import emailDashboardService from 'services/emailDashboard';
import types from './types';
import actions from './actions';

export function* listMemberEmailDashboard(action) {
  yield put(actions.listMemberEmailDashboardPending());
  try {
    const emails = yield call(emailDashboardService.listEmails, action.payload);
    yield put(actions.listMemberEmailDashboardSucceeded({ emailsData: emails.data }));
  } catch (e) {
    yield put(actions.listMemberEmailDashboardFailed({ message: e.message }));
  }
}

export function* listMemberDetailEmailDashboard(action) {
  yield put(actions.listMemberDetailEmailDashboardPending());
  try {
    const email = yield call(emailDashboardService.listEmailsDetail, action.payload);
    yield put(actions.listMemberDetailEmailDashboardSucceeded({ emailData: email.data }));
  } catch (e) {
    yield put(actions.listMemberDetailEmailDashboardFailed({ message: e.message }));
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.MEMBER_EMAIL_DASHBOARD_LIST, listMemberEmailDashboard),
    takeLatest(types.MEMBER_DETAIL_EMAIL_DASHBOARD_LIST, listMemberDetailEmailDashboard),
  ]);
}

export default authSaga;
