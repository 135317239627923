import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import serviceInterestship from 'services/Matching/interestship';
import actions from './actions';
import types from './types';

export function* createInterestship(action, callback) {
  const { payload } = action;
  try {
    const response = yield call(serviceInterestship.createInterestship, payload);
    if (callback) {
      callback();
    }
    yield put(actions.createInterestshipSucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.createInterestshipFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteInterestship(action) {
  const { program, id } = action.payload;
  try {
    yield call(serviceInterestship.deleteInterestship, { program, id });
    if (action.callback) {
      action.callback();
    }
    yield put(actions.deleteInterestshipSucceeded({ id, program }));
  } catch (e) {
    yield put(
      actions.deleteInterestshipFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchInterestship(action) {
  const { program, id } = action.payload;
  try {
    const response = yield call(serviceInterestship.fetchInterestship, {
      program,
      id,
    });
    yield put(actions.fetchInterestshipSucceeded({ ...response.data, program }));
  } catch (e) {
    yield put(actions.fetchInterestshipFailed({ message: e.message }));
  }
}

export function* listInterestships(action) {
  yield put(actions.listInterestshipsPending());
  try {
    const interestships = yield call(serviceInterestship.listInterestships, action.payload);
    yield put(
      actions.listInterestshipsSucceeded({
        results: interestships.data,
        program: action.payload.project__program || action.payload.program,
      })
    );
  } catch (e) {
    yield put(actions.listInterestshipsFailed({ message: e.message }));
  }
}

export function* listMPInterestships(action) {
  yield put(actions.listMPInterestshipsPending());
  try {
    const { programId, organizationId, ...query } = action && action.payload;
    const interestships = yield call(serviceInterestship.listMPInterestships, {
      organizationId,
      programId,
      ...query,
    });
    yield put(
      actions.listMPInterestshipsSucceeded({
        results: interestships.data.results,
        count: interestships.data.count,
        program: action.payload.program,
      })
    );
  } catch (e) {
    yield put(actions.listMPInterestshipsFailed({ message: e.message }));
  }
}

export function* updateMPInterestships(action) {
  yield put(actions.updateMPInterestshipsPending());
  try {
    const { id, program, ...query } = action && action.payload;
    const interestships = yield call(serviceInterestship.updateMPInterestships, {
      id,
      program,
      ...query,
    });
    if (action.callback) {
      action.callback();
    }
    yield put(
      actions.updateMPInterestshipsSucceeded({
        results: interestships.data.results,
        count: interestships.data.count,
        program: action.payload.program,
      })
    );
  } catch (e) {
    yield put(actions.updateMPInterestshipsFailed({ message: e.message }));
  }
}

export function* updateInterestship(action) {
  try {
    const { program, ...invite } = action.payload;
    const updated = yield call(serviceInterestship.updateInterestship, invite);
    if (action.callback) {
      action.callback();
    }
    yield put(actions.updateInterestshipSucceeded({ ...updated.data, program }));
  } catch (e) {
    yield put(
      actions.updateInterestshipFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* interestshipSaga() {
  yield all([
    takeLatest(types.INTERESTSHIP_CREATE, createInterestship),
    takeLatest(types.INTERESTSHIP_DELETE, deleteInterestship),
    takeLatest(types.INTERESTSHIP_FETCH, fetchInterestship),
    takeEvery(types.INTERESTSHIP_UPDATE, updateInterestship),
    takeEvery(types.INTERESTSHIPS_LIST, listInterestships),
    takeEvery(types.INTERESTSHIPS_LIST_MP, listMPInterestships),
    takeEvery(types.INTERESTSHIPS_UPDATE_MP, updateMPInterestships),
  ]);
}

export default interestshipSaga;
