import sortByToolOrder from 'helper/ProgramTool/sortByToolOrder';
import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { list: [], byStatus: { empty: [], withContent: [] } },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PILLS_TOOLKIT_FETCH:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.PILLS_TOOLKIT_FETCH_SUCCEEDED: {
      const toolsByStatus = action.payload.reduce(
        (byStatus, tool) => {
          const key = tool.pills_count <= 0 ? 'empty' : 'withContent';
          byStatus[key].push(tool);
          return byStatus;
        },
        { empty: [], withContent: [] }
      );

      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            list: action.payload.sort(sortByToolOrder),
            byStatus: {
              empty: toolsByStatus.empty.sort(sortByToolOrder),
              withContent: toolsByStatus.withContent.sort(sortByToolOrder),
            },
          },
        })
      );
    }
    case types.PILLS_TOOLKIT_FETCH_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
