// Libraries
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

// Dependencies
import currencies, { formatCurrency } from 'utils/currencies';

// Components

// Locals
import Indicator from '../Indicator';
import LineComponent from '../LineComponent';

const Line = props => {
  const {
    name,
    value,
    currency,
    highestValue,
    colorIndex,
    dashed,
    bold,
    placement,
    shouldRenderIndicator,
    shouldHideWhenEmpty,
    backgroundColor,
    indicatorBackgroundColor,
  } = props;

  const formatCurrencyValue = useCallback(
    (val, shouldPrintEmpty) => {
      const selectedCurrency = currencies[currency] || {};
      return val || shouldPrintEmpty ? formatCurrency(selectedCurrency, val) : 'N/A';
    },
    [currency]
  );

  if (highestValue === 0) return null;

  const isArrayOfValues = Array.isArray(value);

  return isArrayOfValues ? (
    value.map((val, index) => {
      const isPlacementArray = Array.isArray(placement);
      const isNameArray = Array.isArray(name);
      const place = isPlacementArray ? placement[index] : placement;
      const respectiveName = isNameArray ? name[index] : name;
      return (
        <LineComponent
          /**
           * If there are two lines, their position is not expected to change,
           * or one of them to disappear/unmount, so we can use the index
           * in this case because we actually do not have unique IDs.
           */
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          colorIndex={colorIndex}
          heightPercentage={(val / highestValue) * 100 || 0}
          dashed={dashed}
          shouldHideWhenEmpty={shouldHideWhenEmpty}
          value={val}
        >
          {shouldRenderIndicator && (
            <Indicator
              $bold={bold}
              backgroundColor={backgroundColor}
              placement={place}
              indicatorBackgroundColor={indicatorBackgroundColor}
            >
              {respectiveName}
              {respectiveName && ': '}
              {formatCurrencyValue(Math.round(val * 100) / 100)}
            </Indicator>
          )}
        </LineComponent>
      );
    })
  ) : (
    <LineComponent
      colorIndex={colorIndex}
      heightPercentage={(value / highestValue) * 100 || 0}
      dashed={dashed}
      shouldHideWhenEmpty={shouldHideWhenEmpty}
      value={value}
    >
      {shouldRenderIndicator && (
        <Indicator
          $bold={bold}
          placement={placement}
          backgroundColor={backgroundColor}
          indicatorBackgroundColor={indicatorBackgroundColor}
        >
          {name}
          {name && ': '}
          {formatCurrencyValue(Math.round(value * 100) / 100)}
        </Indicator>
      )}
    </LineComponent>
  ); // eslint-disable-line react/jsx-closing-tag-location
};

Line.propTypes = {
  name: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Array)]),
  currency: PropTypes.number,
  highestValue: PropTypes.number,
  colorIndex: PropTypes.number,
  dashed: PropTypes.bool,
  bold: PropTypes.bool,
  placement: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Array)]),
  shouldRenderIndicator: PropTypes.bool,
  shouldHideWhenEmpty: PropTypes.bool,
  backgroundColor: PropTypes.string,
  indicatorBackgroundColor: PropTypes.string,
};

Line.defaultProps = {
  name: undefined,
  value: undefined,
  currency: undefined,
  highestValue: undefined,
  colorIndex: undefined,
  dashed: true,
  bold: false,
  placement: 'top',
  shouldRenderIndicator: false,
  shouldHideWhenEmpty: true,
  backgroundColor: undefined,
  indicatorBackgroundColor: undefined,
};

export default React.memo(Line);
