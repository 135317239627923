import api from '../api';

// Every parameter is optional to filter the data, if no parameter is provided will fetch all.
const listFinancialProjections = ({ scenario, project, program } = {}) =>
  api.get('api/financial-projections/', {
    params: {
      scenario,
      project,
      program,
    },
  });

export default {
  listFinancialProjections,
};
