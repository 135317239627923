import api from '../api';

const listPricingModels = ({ product }) =>
  api.get('/api/pricing-models/', {
    product,
  });

const listProductPricingModels = ({ product }) =>
  api.get('/api/product-pricing-models/', {
    params: {
      product,
    },
  });

const createProductPricingModel = ({ product, pricingModel, ...rest }) =>
  api.post('/api/product-pricing-models/', {
    product,
    pricing_model: pricingModel,
    ...rest,
  });

const updateProductPricingModel = ({ product, ...pricingModel }) =>
  api.patch(`/api/product-pricing-models/${pricingModel.id}/`, {
    product,
    ...pricingModel,
  });

const deleteProductPricingModel = ({ id }) => api.delete(`/api/product-pricing-models/${id}/`);

export default {
  listPricingModels,
  listProductPricingModels,
  createProductPricingModel,
  updateProductPricingModel,
  deleteProductPricingModel,
};
