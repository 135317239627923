import { useState } from 'react';

function getFromLocalStorage(key, initialValue) {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
}

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => getFromLocalStorage(key, initialValue));

  const setValue = value => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, value.toJSON ? value.toJSON() : JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

// implementation:
// const [value, setValue] = useLocalStorage('keyName', 'initialValue');
