// Libraries
import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';

const UNMOUNT_TIMEOUT = 300;

const LoadingSpinner = props => {
  const { isLoading } = props;
  const [isMounted, setIsMounted] = useState(false);
  const mountingTimeout = useRef(undefined);

  useEffect(() => {
    switch (true) {
      // When not mounted, and it's loading: mount component.
      case isLoading:
        // If it's in the middle of an unmount transition, cancel it.
        clearTimeout(mountingTimeout.current);
        setIsMounted(true);
        break;
      // When it finished loading and it's mounted: unmount component.
      case !isLoading && isMounted: {
        const timeoutId = setTimeout(() => {
          setIsMounted(false);
        }, UNMOUNT_TIMEOUT);
        mountingTimeout.current = timeoutId;
        break;
      }
      default:
      // Do nothing.
    }
  }, [isLoading, isMounted]);

  // Cleanup for any possible pending timeouts when the component unmounts.
  useEffect(
    () => () => {
      if (mountingTimeout.current) clearTimeout(mountingTimeout.current);
      // eslint-disable-next-line
    },
    []
  );

  if (!isMounted) return null;

  const isUnmounting = !isLoading && isMounted;

  return (
    <Wrapper isUnmounting={isUnmounting}>
      <Spinner>
        <i />
        <i />
        <i />
        <i />
      </Spinner>
    </Wrapper>
  );
};

// Animations
const mountAnimation = keyframes`
  100% {
    opacity: 1;
  }
`;

const unmountAnimation = keyframes`
  100% {
    opacity: 0;
  }
`;

const rotateAnimation = keyframes`
  100% {
    transform: rotate(405deg);
  }
`;

const itemAnimation = keyframes`
  100% {
    opacity: 1;
  }
`;

// Components
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: wait;

  ${({ isUnmounting }) =>
    isUnmounting
      ? css`
          animation: ${unmountAnimation} ${UNMOUNT_TIMEOUT}ms linear;
        `
      : css`
          animation: ${mountAnimation} ${UNMOUNT_TIMEOUT}ms linear;
        `}
`;

const Spinner = styled.span`
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
  transform: rotate(45deg);
  animation: ${rotateAnimation} 1.2s infinite linear;
  margin: -10px;

  i {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #1890ff;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation: ${itemAnimation} 1s infinite linear alternate;
  }

  i:nth-child(1) {
    top: 0;
    left: 0;
  }

  i:nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: 0.4s;
  }

  i:nth-child(3) {
    right: 0;
    bottom: 0;
    animation-delay: 0.8s;
  }

  i:nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 0.12s;
  }
`;

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  isLoading: false,
};

export default LoadingSpinner;
