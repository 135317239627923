// Libraries
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

// Components
import Icon from '@ant-design/icons';
import { DatePicker as AntDatePicker } from 'antd';
import IconCalendar from '../IconCalendar';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import { DateTimePickerStyle } from '../style';

const getPlaceholder = (placeholder, dateFormat, langFormat, t) => {
  if (placeholder) {
    return placeholder;
  }
  if (dateFormat) {
    return langFormat(
      t('Select date (%(dateFormat)s)'),
      { dateFormat }
    );
  }
  return t('Select date');
};

const DatePicker = forwardRef(
  (
    {
      allowClear = false,
      name = null,
      onBlur = () => { },
      onChange = () => { },
      placeholder = null,
      ...rest
    },
    ref
  ) => {
    const { format, t } = React.useContext(TranslationsContext);

    const handleEvent = date => {
      const event = { target: { name, value: date } };
      if (onChange) onChange(event);
      if (onBlur) onBlur(event);
    };

    return (
      <StyledDatePicker
        allowClear={allowClear}
        bordered={false}
        data-test="component-date-picker"
        name={name}
        onChange={handleEvent}
        placeholder={getPlaceholder(placeholder, rest.format, format, t)}
        ref={ref}
        suffixIcon={<Icon component={IconCalendar} />}
        {...rest}
      />
    );
  }
);

const StyledDatePicker = styled(AntDatePicker)`
  &&& {
    ${DateTimePickerStyle}
  }
`;

DatePicker.propTypes = {
  allowClear: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default DatePicker;
