import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Chain from 'models/StakeholderMap/Chain';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byProduct: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.CHAINS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (chainsById, chain) => chainsById.set(chain.id.toString(), new Chain(chain)),
              state.get('data').get('byId')
            ),
            byProduct: state
              .get('data')
              .get('byProduct', new Map())
              .set(
                action.payload.product.toString(),
                action.payload.results
                  .reduce((arr, chain) => arr.push(new Chain(chain)), new List())
                  .sort(byId)
              ),
          },
        })
      );
    case types.CHAIN_FETCH_SUCCEEDED:
    case types.CHAIN_CREATE_SUCCEEDED:
    case types.CHAIN_UPDATE_SUCCEEDED:
      return (() => {
        const productId = action.payload.product.toString();
        const chainsByProduct = state
          .get('data')
          .get('byProduct')
          .get(productId, new List())
          .filter(chain => chain.id !== action.payload.id)
          .push(new Chain(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Chain(action.payload)),
              byProduct: state.get('data').get('byProduct').set(productId, chainsByProduct),
            },
          })
        );
      })();
    case types.CHAIN_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                action.payload.product.toString(),
                state
                  .get('data')
                  .get('byProduct')
                  .get(action.payload.product.toString())
                  .filter(chain => chain.id !== action.payload.id)
              ),
          },
        })
      );
    case types.CHAIN_CREATE:
    case types.CHAIN_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.CHAIN_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.CHAIN_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.CHAINS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
