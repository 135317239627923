import { createSelector } from 'reselect';

const getState = state => state.problem;

const getRawProblems = createSelector([getState], state => state.get('data'));

const getProblemsById = createSelector([getRawProblems], state => state.get('byId'));

const getProblems = createSelector([getProblemsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getProblems,
  getProblemsById,
  getErrorMessage,
};
