import { Record } from 'immutable';

const AreasOfExpertiseRecord = Record({
  name: '',
});

class AreasOfExpertise extends AreasOfExpertiseRecord {
  get name() {
    return this.get('name');
  }
}

export default AreasOfExpertise;
