import { Record } from 'immutable';
import ContentType from '../ContentType';

const BehaviorRecord = new Record({
  id: null,
  comments: [],
  contentType: ContentType.BEHAVIOR,
  name: null,
  project: null,
  type: null,
  created: null,
  updated: null,
});

class Behavior extends BehaviorRecord {
  static contentType = ContentType.BEHAVIOR;

  static DISLIKE_OTHERS = 0;
  static LIKE_OTHERS = 1;
  static DISLIKE_YOURSELF = 2;
  static LIKE_YOURSELF = 3;

  get id() {
    return this.get('id');
  }
}

export default Behavior;
