import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AffinityFive from 'static/icons/10-admin/affinity-5.png';
import AffinityFour from 'static/icons/10-admin/affinity-4.png';
import AffinityThree from 'static/icons/10-admin/affinity-3.png';
import AffinityTwo from 'static/icons/10-admin/affinity-2.png';
import AffinityOne from 'static/icons/10-admin/affinity-1.png';
import AffinityNull from 'static/icons/10-admin/affinity-null.png';

const MatchAffinity = ({ value, matchCard }) => {
  let affinityIcon;
  switch (Number(value)) {
    case 5:
      affinityIcon = AffinityFive;
      break;
    case 4:
      affinityIcon = AffinityFour;
      break;
    case 3:
      affinityIcon = AffinityThree;
      break;
    case 2:
      affinityIcon = AffinityTwo;
      break;
    case 1:
      affinityIcon = AffinityOne;
      break;
    default:
      affinityIcon = AffinityNull;
  }

  return (
    <Div matchCard={matchCard} data-testid="match-affinity-wrapper">
      <img
        data-testid="match-affinity-image"
        src={affinityIcon}
        alt={`This is the affinity (${Number(value)}) in this potential match!`}
      />
    </Div>
  );
};

const Div = styled.div`
  ${({ matchCard }) =>
    matchCard &&
    `
    position: absolute;
    top: 7px;
    right: 10px;
  `}
`;

MatchAffinity.propTypes = {
  value: PropTypes.number.isRequired,
  matchCard: PropTypes.bool,
};

MatchAffinity.defaultProps = {
  matchCard: false,
};

export default MatchAffinity;
