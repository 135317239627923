import { all, call, put, takeLatest } from 'redux-saga/effects';
import commentsService from 'services/comment';

import types from './types';
import actions from './actions';

export function* selectObject({ payload }) {
  yield put(actions.toggleShow());
  yield put(actions.listComments(payload));
}

export function* createComment({ payload }, callback) {
  const { message, item, contentType, objectId, object, ...rest } = payload;
  try {
    const response = yield call(commentsService.createComment, {
      content_type_str: contentType,
      item,
      message,
      object_id: objectId,
      ...rest,
    });
    yield put(actions.createCommentSucceeded({ ...response.data, object }));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.createCommentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteComment({ payload }) {
  const { id } = payload;
  try {
    yield call(commentsService.deleteComment, { id });
    yield put(
      actions.deleteCommentSucceeded({
        ...payload,
      })
    );
  } catch (e) {
    yield put(
      actions.deleteCommentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* editComment({ payload, callback }) {
  const { objectId } = payload;
  try {
    const response = yield call(commentsService.updateComment, {
      id: objectId,
      ...payload,
    });
    if (callback) callback();
    yield put(actions.editCommentSucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.editCommentFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* listComments({ payload }) {
  const { contentType, id, item } = payload;
  try {
    const response = yield call(commentsService.listComments, {
      object_id: id,
      content_type: contentType,
      item,
    });
    yield put(actions.listCommentsSucceeded({ data: response.data }));
  } catch (e) {
    yield put(
      actions.listCommentsFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* CommentSaga() {
  yield all([
    takeLatest(types.COMMENT_CREATE, createComment),
    takeLatest(types.COMMENT_DELETE, deleteComment),
    takeLatest(types.COMMENT_EDIT, editComment),
    takeLatest(types.COMMENT_LIST, listComments),
    takeLatest(types.COMMENT_SELECT_CONTENT_OBJECT, selectObject),
  ]);
}

export default CommentSaga;
