import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Investment from 'models/FinancialProjections/Investment';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byScenario: {},
      totalByScenario: {},
      totalPaidByScenario: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

const sumInvestmentTotals = (prev, investment) => prev + parseFloat(investment.value);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.INVESTMENTS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, investment) =>
                prevData.set(investment.id.toString(), new Investment(investment)).sort(byId),
              state.get('data').get('byId')
            ),
            byScenario: state
              .get('data')
              .get('byScenario')
              .set(
                action.payload.scenario.toString(),
                fromJS(
                  action.payload.results.map(investment => new Investment(investment)).sort(byId)
                )
              ),
            totalByScenario: state
              .get('data')
              .get('totalByScenario')
              .set(
                action.payload.scenario.toString(),
                action.payload.results.reduce(sumInvestmentTotals, 0)
              ),
            totalPaidByScenario: state
              .get('data')
              .get('totalPaidByScenario')
              .set(
                action.payload.scenario.toString(),
                fromJS(
                  action.payload.results
                    .filter(investment => investment.paid)
                    .reduce(sumInvestmentTotals, 0)
                )
              ),
          },
        })
      );
    case types.INVESTMENT_FETCH_SUCCEEDED:
    case types.INVESTMENT_CREATE_SUCCEEDED:
    case types.INVESTMENT_UPDATE_SUCCEEDED:
      return (() => {
        const scenarioId = action.payload.scenario.toString();
        const scenarioInvestments = state
          .get('data')
          .get('byScenario')
          .get(scenarioId, new List())
          .filter(investment => investment.id !== action.payload.id)
          .push(new Investment(action.payload))
          .sort(byId);
        const scenarioTotal = scenarioInvestments.reduce(sumInvestmentTotals, 0);
        const secenarioPaidTotal = scenarioInvestments
          .filter(investment => investment.paid)
          .reduce(sumInvestmentTotals, 0);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Investment(action.payload)),
              byScenario: state.get('data').get('byScenario').set(scenarioId, scenarioInvestments),
              totalByScenario: state
                .get('data')
                .get('totalByScenario')
                .set(scenarioId, scenarioTotal),
              totalPaidByScenario: state
                .get('data')
                .get('totalPaidByScenario')
                .set(scenarioId, secenarioPaidTotal),
            },
          })
        );
      })();
    case types.INVESTMENT_FETCH:
    case types.INVESTMENT_CREATE:
    case types.INVESTMENT_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.INVESTMENT_DELETE_SUCCEEDED:
      return (() => {
        const scenarioId = action.payload.scenario.toString();
        const scenarioInvestments = state
          .get('data')
          .get('byScenario')
          .get(scenarioId, new List())
          .filter(investment => investment.id !== action.payload.id)
          .sort(byId);
        const scenarioTotal = scenarioInvestments.reduce(sumInvestmentTotals, 0);
        const secenarioPaidTotal = scenarioInvestments
          .filter(investment => investment.paid)
          .reduce(sumInvestmentTotals, 0);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state.get('data').get('byId').delete(action.payload.id.toString()),
              byScenario: state.get('data').get('byScenario').set(scenarioId, scenarioInvestments),
              totalByScenario: state
                .get('data')
                .get('totalByScenario')
                .set(scenarioId, scenarioTotal),
              totalPaidByScenario: state
                .get('data')
                .get('totalPaidByScenario')
                .set(scenarioId, secenarioPaidTotal),
            },
          })
        );
      })();
    case types.INVESTMENT_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.INVESTMENT_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.INVESTMENT_FETCH_FAILED:
    case types.INVESTMENTS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
