import * as reduxHelper from 'store/utils/reduxHelper';
import { fromJS } from 'immutable';
import types from './types';

const DEFAULT_LANGUAGE = 'en_us';
const initialState = fromJS({
  selectedLanguage: DEFAULT_LANGUAGE,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.TRANSLATION_FETCH_REQUESTED:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.TRANSLATION_FETCH_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: action.payload,
          isAuthenticated: true,
        })
      );
    case types.TRANSLATION_FETCH_FAILED:
      return state.merge(reduxHelper.applyErrorInfo());
    case types.TRANSLATION_CHANGE: {
      const { selectedLanguage } = action.payload;
      return state.set('selectedLanguage', selectedLanguage || DEFAULT_LANGUAGE);
    }
    default:
      return state;
  }
}
