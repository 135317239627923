// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Popover } from 'antd';
import Icon from '@ant-design/icons';

const IconPopover = props => {
  // TODO: this component has just 1 usage reference
  const { content, placement, ...rest } = props;
  return (
    <Popover content={content} placement={placement} overlayClassName="tool-icon-popover">
      <Icon {...rest} />
    </Popover>
  );
};

IconPopover.propTypes = {
  content: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

IconPopover.defaultProps = {
  placement: 'bottom',
};

const StyledIconPopover = styled(IconPopover)`
  &&& {
    background: ${({ theme }) => theme.primaryColorBgFade};
    color: ${({ theme }) => theme.primaryColor};
    border-radius: 100%;
    font-size: 30px;
    margin-left: 30px;
    padding: 10px;
    &:hover {
      background: ${({ theme }) => theme.primaryColor};
      color: white;
    }
  }
`;

export default StyledIconPopover;
