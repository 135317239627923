const MEMBER_PROGRAM_STATS_LIST = 'bfb/programStats/MEMBER_PROGRAM_STATS_LIST';
const MEMBER_PROGRAM_STATS_LIST_SUCCEEDED = 'bfb/programStats/MEMBER_PROGRAM_STATS_LIST_SUCCEEDED';
const MEMBER_PROGRAM_STATS_LIST_FAILED = 'bfb/programStats/MEMBER_PROGRAM_STATS_LIST_FAILED';
const MENTOR_PROGRAM_STATS_LIST = 'bfb/programStats/MENTOR_PROGRAM_STATS_LIST';
const MENTOR_PROGRAM_STATS_LIST_SUCCEEDED = 'bfb/programStats/MENTOR_PROGRAM_STATS_LIST_SUCCEEDED';
const MENTOR_PROGRAM_STATS_LIST_FAILED = 'bfb/programStats/MENTOR_PROGRAM_STATS_LIST_FAILED';

export default {
  MEMBER_PROGRAM_STATS_LIST,
  MEMBER_PROGRAM_STATS_LIST_SUCCEEDED,
  MEMBER_PROGRAM_STATS_LIST_FAILED,
  MENTOR_PROGRAM_STATS_LIST,
  MENTOR_PROGRAM_STATS_LIST_SUCCEEDED,
  MENTOR_PROGRAM_STATS_LIST_FAILED,
};
