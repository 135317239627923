import { types } from 'store/modules/sector';

const fetchSectors = query => ({
  type: types.SECTORS_FETCH,
  payload: query,
});

const fetchSectorsSucceeded = ({ areasOfExpertiseData }) => ({
  type: types.SECTORS_FETCH_SUCCEEDED,
  payload: areasOfExpertiseData,
});

const fetchSectorsFailed = ({ message }) => ({
  type: types.SECTORS_FETCH_FAILED,
  payload: message,
});

export default {
  fetchSectors,
  fetchSectorsSucceeded,
  fetchSectorsFailed,
};
