import { Record } from 'immutable';
import ContentType from '../ContentType';

const GrowthRiskRecord = new Record({
  category: null,
  comments: [],
  contentType: ContentType.GROWTHRISK,
  id: null,
  mitigation: null,
  name: null,
  project: null,
  created: null,
  updated: null,
});

class GrowthRisk extends GrowthRiskRecord {
  static contentType = ContentType.GROWTHRISK;

  get id() {
    return this.get('id');
  }
}

export default GrowthRisk;
