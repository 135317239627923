import { Record } from 'immutable';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const STAGE_STATUS_DISABLED = 0;
const STAGE_STATUS_OFF = 1;
const STAGE_STATUS_ON = 2;

const MAX_RECEIVED_INTERESTSHIPS = 3;
const MAX_SENT_INTERESTSHIPS = 3;

const DEFAULT_COMMUNITY_LINK =
  'https://join.slack.com/t/bfb-community/shared_invite/zt-2pif0t98y-B26anXofTDB4t6OsvTXuVQ';

const ProgramRecord = new Record({
  id: null,
  additional_member_application_question: null,
  additional_member_application_question_1: null,
  additional_member_application_question_2: null,
  additional_member_application_question_3: null,
  additional_member_application_question_4: null,
  additional_member_application_question_5: null,
  additional_member_application_question_6: null,
  additional_member_application_question_7: null,
  additional_mentor_application_question: null,
  additional_mentor_application_question_1: null,
  additional_mentor_application_question_2: null,
  additional_mentor_application_question_3: null,
  additional_mentor_application_question_required: false,
  additional_mentor_application_question_1_required: false,
  additional_mentor_application_question_2_required: false,
  additional_mentor_application_question_3_required: false,
  application_info_banner_entrepreneurs: null,
  application_info_banner_mentors: null,
  admin_email: '',
  admin_name: '',
  announcement: '',
  hide_default_deep_dives: false,
  hide_deep_dive: true,
  announcement_link: null,
  community_link: '',
  community_link_mentor: '',
  description: '',
  countries: null,
  e_application_goal: null,
  m_application_goal: null,
  e_approval_goal: null,
  m_approval_goal: null,
  e_incubation_goal: null,
  m_incubation_goal: null,
  languages: [],
  exploring: false,
  interestships_count: 0,
  license_end: null,
  program_group: null,
  program_group_name: '',
  member_application_status: null,
  member_application: false,
  mentor_application_status: null,
  mentor_application: false,
  member_survey_link_en: null,
  member_survey_link_es: null,
  member_survey_link_fr: null,
  mentor_survey_link_en: null,
  mentor_survey_link_es: null,
  mentor_survey_link_fr: null,
  name: '',
  organization: null,
  picture: '',
  private: null,
  email_from: '',
  email_reply: '',
  email_bcc: '',
  email_cc: '',
  program_manager: '',
  program_manager_email: '',
  projects_count: 0,
  program_calendar: null,
  program_type: null,
  free_trial: false,
  sector: null,
  selection_criteria: '',
  signup_url: '',
  m_selection_criteria: '',
  welcome_call_date: undefined,
  certificates_visibility: true,
  certificate_logo: null,
  private_for_members: null,
  private_for_mentors: null,
  members_exclusive: null,
  mentors_exclusive: null,
  // Application
  application_stage_status: STAGE_STATUS_ON,
  application_decision_date: undefined,
  application_end: undefined,
  m_application_end: undefined,
  m_application_decision_date: undefined,
  application_start: undefined,
  member_phone_number_required_on_signup: false,
  mentor_phone_number_required_on_signup: false,
  member_phone_number_required: false,
  mentor_phone_number_required: false,
  member_phone_number_signup: false,
  mentor_phone_number_signup: false,
  e_application_landing: null,
  m_application_landing: null,
  // Interview
  interview_stage_status: null,
  interview_end: undefined,
  interview_start: undefined,
  interview_decision_date: undefined,
  // Accept My Spot
  accept_my_spot_stage_status: true,
  accept_my_spot_deadline: undefined,
  // Matching
  matching_agreement: null,
  matching_stage_status: STAGE_STATUS_ON,
  matching_end: undefined,
  matching_start: undefined,
  matching_decision_date: undefined,
  matching_ranking_end: null,
  explore_max_invites_sent: MAX_SENT_INTERESTSHIPS,
  explore_max_invites_received: MAX_RECEIVED_INTERESTSHIPS,
  // Matching: Onboarding sessions
  e_matching_onboarding_date: undefined,
  e_matching_onboarding_link: '',
  e_matching_onboarding_address: '',
  e_matching_onboarding_timezone: '',
  e_matching_onboarding_duration: '',
  e_matching_onboarding_type: 0,
  m_matching_onboarding_date: undefined,
  m_matching_onboarding_link: '',
  m_matching_onboarding_address: '',
  m_matching_onboarding_timezone: '',
  m_matching_onboarding_duration: '',
  m_matching_onboarding_type: 0,
  // Incubation
  incubation_stage_status: STAGE_STATUS_ON,
  incubation_end: undefined,
  incubation_start: undefined,
  incubation_onboarding_date: undefined,
  incubation_onboarding_link: '',
  incubation_onboarding_timezone: '',
  incubation_onboarding_duration: '',
  // AdminDashboard
  incomplete_count: 0,
  incubating_count: 0,
  invites_count: 0,
  pending_count: 0,
  unmatched_count: 0,
  rejected_count: 0,
  stage: '',
  region: null,
  document_repository: null,
  kind: null,
  service_level: '',
  program_status: 1,
  // If the program is fetched, detail will be true
  // this field is used to prevent programList from update a fetched object
  detail: false,
  // Add dashboard fields for search
  application_status: null,
  disable_program_button: false,
  program_id: null,
});

class Program extends ProgramRecord {
  static B2B = 0;
  static B2C = 1;
  static STAGE_STATUS_DISABLED = STAGE_STATUS_DISABLED;
  static STAGE_STATUS_OFF = STAGE_STATUS_OFF;
  static STAGE_STATUS_ON = STAGE_STATUS_ON;
  static MAX_RECEIVED_INTERESTSHIPS = MAX_RECEIVED_INTERESTSHIPS;
  static MAX_SENT_INTERESTSHIPS = MAX_SENT_INTERESTSHIPS;

  static isStageDisabled(stageStatus) {
    return stageStatus === Program.STAGE_STATUS_DISABLED;
  }

  get id() {
    return this.get('id') || this.get('program_id');
  }

  get isB2B() {
    return this.get('program_type') === this.B2B;
  }

  get isB2C() {
    return this.get('program_type') === this.B2C;
  }

  get community_link() {
    return this.get('community_link') || DEFAULT_COMMUNITY_LINK;
  }

  get community_link_mentor() {
    return this.get('community_link_mentor') || DEFAULT_COMMUNITY_LINK;
  }

  get isEntrepreneurApplicationOpen() {
    return dayjs().isBetween(this.get('application_start'), this.get('application_end'));
  }

  get isMentorApplicationOpen() {
    // if m_application_end is not set, use application_end
    return dayjs().isBetween(
      this.get('application_start'),
      this.get('m_application_end') || this.get('application_end'));
  }
}

export default Program;

// Program status
const STATUS_FUTURE = 0;
const STATUS_ACTIVE = 1;
const STATUS_COMPLETE = 2;
const STATUS_LEGACY = 3;
const STATUS_NEVER_STARTED = 4;

export const PROGRAM_STATUSES = {
  STATUS_FUTURE,
  STATUS_ACTIVE,
  STATUS_COMPLETE,
  STATUS_LEGACY,
  STATUS_NEVER_STARTED,
};
