import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/ImpactPlan/rule';
import { types as commentTypes } from 'store/modules/comment';
import serviceRule from 'services/ImpactPlan/rule';
import Rule from 'models/ImpactPlan/Rule';

export function* createRule(action) {
  const { system, ...rule } = action.payload;
  try {
    const response = yield call(serviceRule.createRule, { system, ...rule });
    yield put(
      actions.createRuleSucceeded({
        system,
        ...response.data,
      })
    );
  } catch (e) {
    yield put(
      actions.createRuleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteRule(action) {
  const { id, system } = action.payload;
  try {
    yield call(serviceRule.deleteRule, { id });
    yield put(actions.deleteRuleSucceeded({ id, system }));
  } catch (e) {
    yield put(
      actions.deleteRuleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchRule(action) {
  const { id } = action.payload;
  try {
    const rule = yield call(serviceRule.fetchRule, { id });
    yield put(actions.fetchRuleSucceeded(rule.data));
  } catch (e) {
    yield put(actions.fetchRuleFailed({ message: e.message }));
  }
}

export function* listRules(action) {
  const { system } = action.payload;
  try {
    const rules = yield call(serviceRule.listRules, { system });
    yield put(actions.listRulesSucceeded({ results: rules.data, system }));
  } catch (e) {
    yield put(actions.listRulesFailed({ message: e.message }));
  }
}

export function* updateRule(action) {
  const { id, ...rule } = action.payload;
  try {
    const updated = yield call(serviceRule.updateRule, { id, ...rule });
    yield put(actions.updateRuleSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateRuleFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Rule.contentType) {
    yield put(
      actions.fetchRule({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* ruleSaga() {
  yield all([
    takeLatest(types.RULE_CREATE, createRule),
    takeLatest(types.RULE_DELETE, deleteRule),
    takeLatest(types.RULE_FETCH, fetchRule),
    takeLatest(types.RULE_UPDATE, updateRule),
    takeEvery(types.RULES_LIST, listRules),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default ruleSaga;
