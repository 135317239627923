import { createSelector } from 'reselect';

const getState = state => state.sdg;

const getRawSdg = createSelector([getState], state => state.get('data'));

const getSdgsList = createSelector([getRawSdg], state => state.get('list'));

const getSdgById = createSelector([getRawSdg], state => state.get('byId'));

const getSdg = createSelector([getSdgById], state => state.valueSeq().toJS());

const getSdgOfId = createSelector([getRawSdg], state => {
  const sdgs = state.get('byId');
  return sdgId => sdgs.get(String(sdgId));
});

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getErrorMessage,
  getSdg,
  getSdgById,
  getSdgOfId,
  getSdgsList,
};
