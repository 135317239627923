import { Record } from 'immutable';
import ContentType from '../ContentType';

const SegmentRecord = new Record({
  id: null,
  contentType: ContentType.SEGMENT,
  name: '',
  description: '',
  value_proposition: '',
  selected: '',
  isSelected: false,
  market_available: null,
  market_served: null,
  market_buyers: null,
  market_total: null,
  expected_visitors: null,
  expected_leads: null,
  expected_customers: null,
  estimated_marketing_costs: null,
  pricing_strategies_summary: '',
  business_models_summary: '',
  product: null,
  comments: null,
  created: null,
  updated: null,
  business_models: [],
  product_business_models: [],
  persona_description: '',
  persona_name: '',
  persona_picture: null,
  pricing_models: [],
  product_pricing_models: [],
  subtype: null,
  type: null,
  tangible_rating: null,
  payment_rating: null,
  payment_frequency_rating: null,
  sell_rating: null,
  life_time_value: null,
  segments_beneficiaries: [],
  beneficiary_selected: false,
});

class Segment extends SegmentRecord {
  static TYPE_B2B = 0;
  static TYPE_B2C = 1;
  static TYPE_B2G = 2;

  static SUB_TYPE_BUYER = 0;
  static SUB_TYPE_USER = 1;
  static SUB_TYPE_BUYER_USER = 2;

  static contentType = ContentType.SEGMENT;

  constructor(props) {
    const segment = props;
    const isSelected = segment.selected === 'true';
    segment.isSelected = isSelected;
    super(segment);
  }

  get id() {
    return this.get('id');
  }
}

export default Segment;
