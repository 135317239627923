import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Scenario from 'models/FinancialProjections/Scenario';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SCENARIOS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, scenario) => ({
                ...obj,
                [scenario.id]: new Scenario(scenario),
              }),
              {}
            ),
          },
        })
      );
    case types.SCENARIO_FETCH_SUCCEEDED:
    case types.SCENARIO_CREATE_SUCCEEDED:
    case types.SCENARIO_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new Scenario(action.payload)),
          },
        })
      );
    case types.SCENARIO_FETCH:
    case types.SCENARIOS_LIST:
    case types.SCENARIO_CREATE:
    case types.SCENARIO_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.SCENARIO_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
          },
        })
      );
    case types.SCENARIO_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.SCENARIO_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.SCENARIO_FETCH_FAILED:
    case types.SCENARIOS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
