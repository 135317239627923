import { Record } from 'immutable';

const ObjectiveRecord = new Record({
  id: null,
  project: null,
  name: null,
  year: null,
  created: null,
  updated: null,
  comments: [],
  key_results: [],
  initiatives: [],
  contentType: 'bfb_growth_plan.objective',
});

class Objective extends ObjectiveRecord {
  static contentType = 'bfb_growth_plan.objective';

  get id() {
    return this.get('id');
  }
}

export default Objective;
