import { call, put, all, takeEvery } from 'redux-saga/effects';
import serviceSdgTargets from 'services/ImpactPlan/sdgTargets';
import types from './types';
import actions from './actions';

export function* listSdgTargets() {
  try {
    const response = yield call(serviceSdgTargets.listSdgTargets);
    yield put(actions.listSdgTargetsSucceeded({ results: response.data }));
  } catch (e) {
    yield put(actions.listSdgTargetsFailed({ message: e.message }));
  }
}

function* saga() {
  yield all([takeEvery(types.SDG_TARGETS_LIST, listSdgTargets)]);
}

export default saga;
