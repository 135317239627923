import types from './types';

const clearError = () => ({
  type: types.INVESTMENT_CLEAR_ERROR,
});

const createInvestment = ({ project, scenario, ...investment }, callback) => ({
  type: types.INVESTMENT_CREATE,
  payload: { project, scenario, ...investment },
  callback,
});

const createInvestmentSucceeded = ({ scenario, id, ...investment }) => ({
  type: types.INVESTMENT_CREATE_SUCCEEDED,
  payload: { scenario, id, ...investment },
});

const createInvestmentFailed = ({ message }) => ({
  type: types.INVESTMENT_CREATE_FAILED,
  payload: { message },
});

const deleteInvestment = ({ project, scenario, id }) => ({
  type: types.INVESTMENT_DELETE,
  payload: { project, scenario, id },
});

const deleteInvestmentSucceeded = ({ scenario, id }) => ({
  type: types.INVESTMENT_DELETE_SUCCEEDED,
  payload: { scenario, id },
});

const deleteInvestmentFailed = ({ message }) => ({
  type: types.INVESTMENT_DELETE_FAILED,
  payload: message,
});

const fetchInvestment = ({ project, scenario, id }) => ({
  type: types.INVESTMENT_FETCH,
  payload: { project, scenario, id },
});

const fetchInvestmentSucceeded = ({ ...investment }) => ({
  type: types.INVESTMENT_FETCH_SUCCEEDED,
  payload: { ...investment },
});

const fetchInvestmentFailed = ({ message }) => ({
  type: types.INVESTMENT_FETCH_FAILED,
  payload: { message },
});

const listInvestments = ({ project, scenario }) => ({
  type: types.INVESTMENTS_LIST,
  payload: { project, scenario },
});

const listInvestmentsSucceeded = payload => ({
  type: types.INVESTMENTS_LIST_SUCCEEDED,
  payload,
});

const listInvestmentsFailed = ({ message }) => ({
  type: types.INVESTMENTS_LIST_FAILED,
  payload: { message },
});

const updateInvestment = ({ project, scenario, id, ...updatedInvestment }) => ({
  type: types.INVESTMENT_UPDATE,
  payload: { project, scenario, id, ...updatedInvestment },
});

const updateInvestmentSucceeded = ({ ...investment }) => ({
  type: types.INVESTMENT_UPDATE_SUCCEEDED,
  payload: { ...investment },
});

const updateInvestmentFailed = ({ message }) => ({
  type: types.INVESTMENT_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createInvestment,
  createInvestmentFailed,
  createInvestmentSucceeded,
  deleteInvestment,
  deleteInvestmentFailed,
  deleteInvestmentSucceeded,
  fetchInvestment,
  fetchInvestmentFailed,
  fetchInvestmentSucceeded,
  listInvestments,
  listInvestmentsFailed,
  listInvestmentsSucceeded,
  updateInvestment,
  updateInvestmentFailed,
  updateInvestmentSucceeded,
};
