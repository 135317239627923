import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import ProjectLink from 'models/Link';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: [],
    errorMessage: '',
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_LINKS_LIST:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.PROJECT_LINKS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: action.payload.map(link => new ProjectLink(link)),
        })
      );
    case types.PROJECT_LINKS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
