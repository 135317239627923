import { createSelector } from 'reselect';

const getState = state => state.rules;

const getRawRules = createSelector([getState], state => state.get('data'));

const getRulesById = createSelector([getRawRules], state => state.get('byId'));

const getRulesBySystem = createSelector([getRawRules], state => state.get('bySystem'));

const getRules = createSelector([getRulesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getRules,
  getRulesById,
  getRulesBySystem,
  getErrorMessage,
};
