// Libraries
import { ExclamationOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import { Col } from 'antd';
import { H2, P } from 'components/fontSystem';
import { Row } from 'components/layout';
import Panel from '.';

const InfoPanel = ({
  children = null,
  message = '',
  noIcon = false,
  title = null,
  icon = null,
  type = 'yellow',
  style = {},
  ...rest
}) => {
  const renderedIcon = icon || <ExclamationOutlined />;

  return (
    <StyledPanel type={type} style={style} {...rest}>
      <Row type="flex" gutter={10} className="info-panel">
        {!noIcon && <Col className="icon-container">{renderedIcon}</Col>}
        <Col className="message-container">
          {title && (
            <Col>
              <StyledH2 {...rest}>{title}</StyledH2>
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <Col>{children || <P $italic dangerouslySetInnerHTML={{ __html: message }} />}</Col>
      </Row>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)`
  &&& {
    padding: ${props => (props.padding ? `${props.padding}px` : '15px')};
    margin: ${({ mt, mb, ml, mr }) => !mt && !mb && !ml && !mr && 0};
    background-color: ${props => `${props.theme.memberGreen}1A`};

    .message-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin-block: 0;
      }
    }

    .ant-col {
      flex: 1;
    }

    .icon-container {
      flex: 0;
      .anticon {
        background-color: ${props => props.theme.memberGreen};
        color: white;
        border-radius: 100%;
        padding: 5px;
      }
    }

    ${props =>
      props.type === 'green' &&
      css`
        background-color: ${`${props.theme.memberGreen}1A`};
        .icon-container {
          .anticon {
            background-color: ${props.theme.memberGreen};
          }
        }
      `}

    ${props =>
      props.type === 'teal' &&
      css`
        background-color: ${`${props.theme.teal}1A`};
        .icon-container {
          .anticon {
            background-color: ${props.theme.teal};
          }
        }
      `}

    ${props =>
      props.type === 'yellow' &&
      css`
        background-color: ${`${props.theme.warning}1A`};
        .icon-container {
          .anticon {
            background-color: ${props.theme.warning};
          }
        }
      `}

    ${props =>
      props.type === 'pink' &&
      css`
        background-color: ${`${props.theme.parrotPink}1A`};
        .icon-container {
          .anticon {
            background-color: ${props.theme.parrotPink};
          }
        }
      `}
  }
`;

const StyledH2 = styled(H2)`
  font-weight: 600;
  font-size: 18px;
  color: ${props => (props.backgroundType ? props.theme.teal : props.theme.warning)};
`;

InfoPanel.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  padding: PropTypes.number,
  title: PropTypes.string,
  icon: PropTypes.node,
  noIcon: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

export default InfoPanel;
