import types from './types';

const fetchCustomization = ({ id, program }) => ({
  type: types.CUSTOMIZATION_FETCH,
  payload: { id, program },
});

const fetchCustomizationSucceeded = ({ id, ...customization }) => ({
  type: types.CUSTOMIZATION_FETCH_SUCCEEDED,
  payload: { id, ...customization },
});

const fetchCustomizationFailed = ({ message }) => ({
  type: types.CUSTOMIZATION_FETCH_FAILED,
  payload: { message },
});

const updateCustomization = ({ id, customization }) => ({
  type: types.CUSTOMIZATION_UPDATE,
  payload: { id, customization },
});

const updateCustomizationSucceeded = customization => ({
  type: types.CUSTOMIZATION_UPDATE_SUCCEEDED,
  payload: { ...customization },
});

const updateCustomizationFailed = ({ message }) => ({
  type: types.CUSTOMIZATION_UPDATE_FAILED,
  payload: { message },
});

export default {
  fetchCustomization,
  fetchCustomizationSucceeded,
  fetchCustomizationFailed,
  updateCustomization,
  updateCustomizationSucceeded,
  updateCustomizationFailed,
};
