import React from 'react';
import Translations from 'providers/translationsProvider';

const withTranslations = (Component, desiredFunctions) => {
  const Translate = props => (
    <Translations.Consumer>
      {defaultHandlers => {
        let handlers = defaultHandlers;
        if (desiredFunctions) {
          handlers = Object.entries(defaultHandlers)
            .filter(([handlerName]) => desiredFunctions.indexOf(handlerName) !== -1)
            .reduce((prev, [key, val]) => ({ ...prev, [key]: val }), {});
        }
        return <Component {...props} {...handlers} />;
      }}
    </Translations.Consumer>
  );
  return Translate;
};

export default withTranslations;
