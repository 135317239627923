import types from './types';

const clearError = () => ({
  type: types.KEY_RESULT_CLEAR_ERROR,
});

const createKeyResult = ({ objective, ...keyResult }, callback) => ({
  type: types.KEY_RESULT_CREATE,
  payload: { objective, ...keyResult },
  callback,
});

const createKeyResultSucceeded = ({ ...keyResult }) => ({
  type: types.KEY_RESULT_CREATE_SUCCEEDED,
  payload: { ...keyResult },
});

const createKeyResultFailed = ({ message }) => ({
  type: types.KEY_RESULT_CREATE_FAILED,
  payload: { message },
});

const deleteKeyResult = ({ objective, keyResultId }, callback) => ({
  type: types.KEY_RESULT_DELETE,
  payload: { objective, keyResultId },
  callback,
});

const deleteKeyResultsSucceeded = payload => ({
  type: types.KEY_RESULT_DELETE_SUCCEEDED,
  payload,
});

const deleteKeyResultFailed = ({ message }) => ({
  type: types.KEY_RESULT_DELETE_FAILED,
  payload: { message },
});

const fetchKeyResult = ({ id }) => ({
  type: types.KEY_RESULT_FETCH,
  payload: { id },
});

const fetchKeyResultSucceeded = ({ ...keyResult }) => ({
  type: types.KEY_RESULT_FETCH_SUCCEEDED,
  payload: { ...keyResult },
});

const fetchKeyResultFailed = ({ message }) => ({
  type: types.KEY_RESULT_FETCH_FAILED,
  payload: { message },
});

const listKeyResults = ({ objective }) => ({
  type: types.KEY_RESULTS_LIST,
  payload: { objective },
});

const listKeyResultsSucceeded = payload => ({
  type: types.KEY_RESULTS_LIST_SUCCEEDED,
  payload,
});

const listKeyResultsFailed = ({ message }) => ({
  type: types.KEY_RESULTS_LIST_FAILED,
  payload: { message },
});

const updateKeyResult = ({ objective, keyResultId, ...keyResult }) => ({
  type: types.KEY_RESULT_UPDATE,
  payload: { objective, keyResultId, ...keyResult },
});

const updateKeyResultSucceeded = ({ ...keyResult }) => ({
  type: types.KEY_RESULT_UPDATE_SUCCEEDED,
  payload: { ...keyResult },
});

const updateKeyResultFailed = ({ message }) => ({
  type: types.KEY_RESULT_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createKeyResult,
  createKeyResultFailed,
  createKeyResultSucceeded,
  deleteKeyResult,
  deleteKeyResultFailed,
  deleteKeyResultsSucceeded,
  fetchKeyResult,
  fetchKeyResultFailed,
  fetchKeyResultSucceeded,
  listKeyResults,
  listKeyResultsFailed,
  listKeyResultsSucceeded,
  updateKeyResult,
  updateKeyResultFailed,
  updateKeyResultSucceeded,
};
