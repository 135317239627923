import { Map, List } from 'immutable';
import { createSelector } from 'reselect';

const getState = state => state.competitor;

const getRawCompetitors = createSelector([getState], state => state.get('data'));

const getCompetitorsByOutcome = createSelector([getRawCompetitors], state =>
  state.get('byOutcome')
);

const getCompetitorsByProduct = createSelector([getRawCompetitors], state =>
  state.get('byProduct')
);

const getMyselfCompetitor = state => productId => {
  const productCompetitors = getCompetitorsByProduct(state).get(String(productId));
  if (productCompetitors) {
    const myselfCompetitor = productCompetitors.find(competitor => competitor.myself);
    return myselfCompetitor;
  }
  return null;
};

/**
 * Thunk function, takes a state and returns a callback that gets all `competitorsByOutcome`
 * in the `outcomesBySegment` parameter that belong to the `segments` parameter.
 * @param {object} state - Reducer state.
 * @returns {function} - Returned competitors getter.
 *      @param {number} productId - Product id number.
 *      @param {List} segments - Segments `List` instance.
 *      @param {Map} outcomesBySegment - Segments `Map` instance.
 *      @param {Map} competitorsByOutcome - Segments `Map` instance.
 */
const getOutcomeCompetitors =
  state => (productId, segments, outcomesBySegment, competitorsByOutcome) => {
    let competitors = new List();
    segments.forEach(segment => {
      if (segment.isSelected) {
        const segmentOutcomes = outcomesBySegment
          .get(String(segment.id), new List())
          .filter(outcome => outcome.selected);
        segmentOutcomes.forEach(outcome => {
          const outcomeCompetitors = competitorsByOutcome
            .get(outcome.id.toString(), new Map())
            .filter(competitor => !competitor.myself && competitor.selected);
          competitors = competitors.concat(outcomeCompetitors);
        });
      }
    });
    const myselfCompetitor = getMyselfCompetitor(state)(productId);
    if (myselfCompetitor) {
      competitors = competitors.unshift(myselfCompetitor);
    }
    return competitors;
  };

const getCompetitorsById = createSelector([getRawCompetitors], state => state.get('byId'));

const getCompetitors = createSelector([getCompetitorsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getCompetitors,
  getCompetitorsByOutcome,
  getCompetitorsByProduct,
  getMyselfCompetitor,
  getOutcomeCompetitors,
  getCompetitorsById,
  getErrorMessage,
};
