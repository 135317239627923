import * as reduxHelper from 'store/utils/reduxHelper';
import ProgramTool from 'models/ProgramTool';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROGRAM_TOOLS_LIST_SUCCEEDED: {
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.reduce(
              (obj, tool) => ({
                ...obj,
                [tool.id]: new ProgramTool(tool),
              }),
              state.data.byId
            ),
          },
        })
      );
    }
    case types.PROGRAM_TOOLS_LIST_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
