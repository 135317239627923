import styled, { css } from 'styled-components';
import { Modal } from 'antd';

const MODAL_PADDING = 30;
const BUTTON_PADDING = 10;
const MODAL_PREVIEW_PADDING = 0;

const getColor = ({ type, theme }) => (type === 'danger' ? theme.errorColor : theme.primaryColor);
const getShadowColor = ({ type, theme }) => {
  switch (type) {
    case 'danger':
      return theme.errorColorFade;
    case 'interestship':
      return theme.secondaryColorFade;
    default:
      return theme.primaryColorFade;
  }
};

const StyledModal = styled(Modal)`
  width: 600px !important;
  @media print {
    @page {
      size: auto;
      margin: 5mm;
    }
    .printed-name {
      color: #37cc82;
    }
    .ant-modal-content {
      box-shadow: unset !important;
    }
    width: 950px !important;
    height: 100%;
  }

  ${props =>
    props.size === 'xlarge' &&
    css`
      width: 100% !important;
      @media screen and (min-width: ${props.theme.screenMdMin}) {
        max-width: 900px;
      }
    `}

  ${props =>
    props.size === 'extra-large' &&
    css`
      width: 900px !important;
      max-width: 95vw;
    `}  

  ${props =>
    props.size === 'large' &&
    css`
      width: 760px !important;
    `}

  ${props =>
    props.size === 'small' &&
    css`
      width: 320px !important;
    `}

  ${props =>
    props.width !== null &&
    !props.size &&
    css`
      width: ${props.width} !important;
    `}

  &.bold-title .ant-modal-title {
    font-weight: bolder;
  }

  .ant-modal-close {
    margin-top: ${MODAL_PADDING - BUTTON_PADDING}px;
    margin-right: ${MODAL_PADDING - BUTTON_PADDING}px;

    &:hover {
      background-color: transparent;
    }
  }

  .ant-modal-content {
    box-shadow: 0 3px 8px 1px ${getShadowColor};
    padding: ${props => (props.preview ? MODAL_PREVIEW_PADDING : MODAL_PADDING)}px;
    & > * {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ant-modal-close-x {
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: ${props => props.xColor || getColor};
  }

  .ant-modal-header {
    border-bottom: none;
    padding-bottom: ${props => (props.preview ? MODAL_PREVIEW_PADDING : '15px')};
    padding-top: 0;
    .ant-modal-title {
      color: ${getColor};
      font-size: 20px;
      font-weight: 600;
    }
  }

  ${props =>
    !props.overrideBodyStyle &&
    css`
      .ant-modal-body {
        padding-top: ${props.preview ? MODAL_PREVIEW_PADDING : '20px'};
        &:last-child {
          padding-bottom: 0;
        }

        /* Note: This style is problematic. In most modals, p elements need a margin, 
        we have to override this style back to the p's default mb */
        p {
          line-height: 20px;
          margin: 0;
        }
      }
    `}

  .ant-modal-footer {
    border-top: none;
    padding-bottom: 0;
  }
`;

export default StyledModal;
