import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: [
      {
        id: null,
        name: null,
        description: null,
        tools: null,
      },
    ],
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GLOSSARY_LIST:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.GLOSSARY_LIST_SUCCESS:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: action.payload,
        })
      );
    default:
      return state;
  }
}
