import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/ValueProposition/reason';
import serviceReason from 'services/ValueProposition/reason';
import { types as commentTypes } from 'store/modules/comment';
import Reason from 'models/ValueProposition/Reason';

export function* createReason(action) {
  const { ...reason } = action.payload;
  try {
    const response = yield call(serviceReason.createReason, { ...reason });
    yield put(actions.createReasonSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.createReasonFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteReason(action) {
  const { id, parent } = action.payload;
  try {
    yield call(serviceReason.deleteReason, { id });
    yield put(actions.deleteReasonSucceeded({ id, parent }));
  } catch (e) {
    yield put(
      actions.deleteReasonFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchReason(action) {
  const { id } = action.payload;
  try {
    const response = yield call(serviceReason.fetchReason, { id });
    yield put(actions.fetchReasonSucceeded(response.data));
  } catch (e) {
    yield put(actions.fetchReasonFailed({ message: e.message }));
  }
}

export function* listReasons(action) {
  const { problemId, ...rest } = action.payload;
  try {
    const reasons = yield call(serviceReason.listReasons, {
      problem: problemId,
      ...rest,
    });
    yield put(actions.listReasonsSucceeded({ results: reasons.data }));
  } catch (e) {
    yield put(actions.listReasonsFailed({ message: e.message }));
  }
}

export function* updateReason(action) {
  const { problem, id, ...reason } = action.payload;
  try {
    const updated = yield call(serviceReason.updateReason, {
      problem,
      id,
      ...reason,
    });
    yield put(actions.updateReasonSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateReasonFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Reason.contentType) {
    yield put(actions.fetchReason({ id: object.get('id') }));
  }
}

function* reasonSaga() {
  yield all([
    takeLatest(types.REASON_CREATE, createReason),
    takeLatest(types.REASON_DELETE, deleteReason),
    takeLatest(types.REASON_FETCH, fetchReason),
    takeLatest(types.REASON_UPDATE, updateReason),
    takeLatest(types.REASONS_LIST, listReasons),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default reasonSaga;
