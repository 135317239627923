const PROJECT_CLEAR_ERROR = 'bfb/project/PROJECT_CLEAR_ERROR';
const PROJECT_FETCH = 'bfb/project/PROJECT_FETCH';
const PROJECT_FETCH_FAILED = 'bfb/project/PROJECT_FETCH_FAILED';
const PROJECT_FETCH_SUCCEEDED = 'bfb/project/PROJECT_FETCH_SUCCEEDED';
const PROJECT_UPDATE = 'bfb/project/PROJECT_UPDATE';
const PROJECT_UPDATE_FAILED = 'bfb/project/PROJECT_UPDATE_FAILED';
const PROJECT_UPDATE_SUCCEEDED = 'bfb/project/PROJECT_UPDATE_SUCCEEDED';
const BREADCRUMB_PROJECTS_LIST = 'bfb/project/BREADCRUMB_PROJECTS_LIST';
const PROJECTS_LIST = 'bfb/project/PROJECTS_LIST';
const PROJECTS_LIST_FAILED = 'bfb/project/PROJECTS_LIST_FAILED';
const PROJECTS_LIST_SUCCEEDED = 'bfb/project/PROJECTS_LIST_SUCCEEDED';
const PROJECTS_LIST_PENDING = 'bfb/project/PROJECTS_LIST_PENDING';
const SELECTED_PROJECT_FETCH = 'bfb/selectedProject/SELECTED_PROJECT_FETCH';
const SELECTED_PROJECT_FETCH_FAILED = 'bfb/selectedProject/SELECTED_PROJECT_FETCH_FAILED';
const SELECTED_PROJECT_FETCH_SUCCEEDED = 'bfb/selectedProject/SELECTED_PROJECT_FETCH_SUCCEEDED';
const SELECTED_PROJECT_CLEAR = 'bfb/selectedProject/SELECTED_PROJECT_CLEAR';
const CURRENT_PROJECT = 'bfb/project/CURRENT_PROJECT';

export default {
  PROJECT_CLEAR_ERROR,
  PROJECT_FETCH,
  PROJECT_FETCH_SUCCEEDED,
  PROJECT_FETCH_FAILED,
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCEEDED,
  PROJECT_UPDATE_FAILED,
  BREADCRUMB_PROJECTS_LIST,
  PROJECTS_LIST,
  PROJECTS_LIST_FAILED,
  PROJECTS_LIST_SUCCEEDED,
  PROJECTS_LIST_PENDING,
  SELECTED_PROJECT_FETCH,
  SELECTED_PROJECT_FETCH_SUCCEEDED,
  SELECTED_PROJECT_FETCH_FAILED,
  SELECTED_PROJECT_CLEAR,
  CURRENT_PROJECT,
};
