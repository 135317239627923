import {
  DEFAULT_ERROR,
  CONNECTIVITY_ERROR,
  SERVER_ERROR,
} from 'hocs/components/ErrorComponent/constants';
import { getMaintenanceMessage } from 'utils/maintenance';

/**
 * Helper function to return the correct error message. In the future, we could add more
 * and turn the function into a switch statement to handle multiple cases.
 * @param {boolean} errorType - Dictates which error should be sent.
 * @param {function} t - translations function.
 * @return {object}
 */

export default function getErrorMessages(errorType, t, format) {
  const messages = {
    [SERVER_ERROR]: {
      title: t('Bridge building under construction. We will be back up and running in no time.'),
      description: getMaintenanceMessage(format, t),
    },
    [CONNECTIVITY_ERROR]: {
      title: t('New version available'),
      description: t(
        "We've released a new version of the app. Please refresh the page to start using the latest version."
      ),
    },
    [DEFAULT_ERROR]: {
      title: t('Sorry, something bad happened 😱'),
      description: t('We will appreciate if you can report the detailed issue:'),
    },
  };
  return messages[errorType] || messages[DEFAULT_ERROR];
}
