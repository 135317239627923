import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Objective from 'models/GrowthPlan/Objective';
import serviceObjective from 'services/GrowthPlan/objective';
import { normalizeObjectives, normalizeObjective } from 'store/normalizers/objectiveNormalizer';

import actions from './actions';
import types from './types';
import { actions as keyResultActions } from '../keyResult';
import { actions as initiativeActions } from '../initiative';

export function* createObjective(action) {
  const { project, ...objective } = action.payload;
  try {
    const response = yield call(serviceObjective.createObjective, {
      project,
      ...objective,
    });
    yield put(actions.createObjectiveSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(
      actions.createObjectiveFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteObjective(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceObjective.deleteObjective, { id });
    yield put(actions.deleteObjectiveSucceeded({ project, id }));
  } catch (e) {
    yield put(
      actions.deleteObjectiveFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchObjective(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceObjective.fetchObjective, { id });
    yield put(actions.fetchObjectiveSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(actions.fetchObjectiveFailed({ message: e.message }));
  }
}

export function* listObjectives(action) {
  const { project } = action.payload;
  try {
    const objectives = yield call(serviceObjective.listObjectives, { project });
    const {
      objectives: normalizedObjectives,
      initiatives,
      key_results: keyResults,
    } = yield call(normalizeObjectives, objectives.data);
    yield put(
      actions.listObjectivesSucceeded({
        results: normalizedObjectives,
        project,
      })
    );
    yield put(
      initiativeActions.listInitiativesSucceeded({
        results: initiatives,
        project,
      })
    );
    yield put(keyResultActions.listKeyResultsSucceeded({ results: keyResults, project }));
  } catch (e) {
    yield put(actions.listObjectivesFailed({ message: e.message }));
  }
}

export function* updateObjective(action) {
  const { id, project, ...objective } = action.payload;
  try {
    const updated = yield call(serviceObjective.updateObjective, {
      id,
      project,
      ...objective,
    });
    yield put(actions.updateObjectiveSucceeded({ ...normalizeObjective(updated.data) }));
  } catch (e) {
    yield put(
      actions.updateObjectiveFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Objective.contentType) {
    yield put(
      actions.fetchObjective({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* objectiveSaga() {
  yield all([
    takeLatest(types.OBJECTIVE_CREATE, createObjective),
    takeLatest(types.OBJECTIVE_DELETE, deleteObjective),
    takeLatest(types.OBJECTIVE_FETCH, fetchObjective),
    takeLatest(types.OBJECTIVE_UPDATE, updateObjective),
    takeEvery(types.OBJECTIVES_LIST, listObjectives),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default objectiveSaga;
