import { createSelector } from 'reselect';

const getState = state => state.emailDashboardMember;

const getRawMemberEmails = createSelector([getState], state => state.data);

const emailsListLoading = createSelector([getRawMemberEmails], state => state.loading);

const getMemberDetailEmail = createSelector([getState], state => state.detail);

export default {
  emailsListLoading,
  getRawMemberEmails,
  getMemberDetailEmail,
};
