import api from '../api';

const createPositioning = ({ project, product, competitor, ...positioning }) =>
  api.post(`api/projects/${project}/products/${product}/competitors/${competitor}/positionings/`, {
    ...positioning,
  });

const deletePositioning = ({ project, product, competitor, id }) =>
  api.delete(
    `api/projects/${project}/products/${product}/competitors/${competitor}/positionings/${id}/`
  );

const fetchPositioning = ({ project, product, competitor, id }) =>
  api.get(
    `api/projects/${project}/products/${product}/competitors/${competitor}/positionings/${id}`
  );

const listPositionings = ({ project, product, competitor }) =>
  api.get(`api/projects/${project}/products/${product}/competitors/${competitor}/positionings/`);

const updatePositioning = ({ project, product, competitor, id, ...positioning }) =>
  api.patch(
    `api/projects/${project}/products/${product}/competitors/${competitor}/positionings/${id}/`,
    { ...positioning }
  );

export default {
  createPositioning,
  deletePositioning,
  fetchPositioning,
  listPositionings,
  updatePositioning,
};
