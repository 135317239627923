// eslint-disable-next-line import/no-cycle
import { types } from '.';

const getGlossary = payload => ({
  type: types.GLOSSARY_LIST,
  payload,
});

const getGlossarySucceeded = payload => ({
  type: types.GLOSSARY_LIST_SUCCESS,
  payload,
});

const getGlossaryFailed = ({ message }) => ({
  type: types.GLOSSARY_LIST_FAILED,
  payload: message,
});

export default {
  getGlossary,
  getGlossarySucceeded,
  getGlossaryFailed,
};
