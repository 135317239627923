// Libraries
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { DatePicker } from 'antd';
import Icon from '@ant-design/icons';

// Dependencies
import { DateTimePickerStyle } from '../style';
import InputStyle from 'components/elements/Form/Input/common';
import IconCalendar from '../IconCalendar';

const RangePicker = props => {
  const { name, onChange, ...rest } = props;

  const handleOnChange = date => {
    const event = { target: { name, value: date } };
    if (onChange) onChange(event);
  };

  return (
    <DatePicker.RangePicker
      allowClear={false}
      onChange={handleOnChange}
      name={name}
      suffixIcon={<Icon component={IconCalendar} />}
      {...rest}
    />
  );
};

const StyledDatePicker = styled(RangePicker)`
  &&& {
    ${DateTimePickerStyle}
    ${InputStyle}


    input {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }

    .ant-picker-suffix {
      bottom: 5px;
    }

    .ant-picker-active-bar {
      margin-inline-start: 0;
    }
  }
`;

StyledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StyledDatePicker;
