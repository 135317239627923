import styled from 'styled-components';

const ParrobotList = styled.ul`
  li {
    list-style: none;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: ${({ theme }) => theme.primaryColor};
      border-radius: 50%;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export default ParrobotList;
