import Jed from 'jed';

const esES = require('i18n/es.json');
const enUS = require('i18n/en.json');
const frFR = require('i18n/fr.json');
const ptPT = require('i18n/pt.json');
const brPT = require('i18n/pt-br.json');
const hyHY = require('i18n/hy.json');
const thTH = require('i18n/th.json');
const viVI = require('i18n/vi.json');
const itIT = require('i18n/it.json');
const lvLV = require('i18n/lv.json');
const skSK = require('i18n/sk.json');
const kmKM = require('i18n/km.json');
const loLo = require('i18n/lo.json');

// Languages platform is translated to
export const languages = {
  EN: 'en_us',
  ES: 'es_es',
  FR: 'fr_fr',
  PT: 'pt_pt',
  BR: 'pt_br',
  HY: 'hy_hy',
  TH: 'th_th',
  VI: 'vi_vi',
  IT: 'it_it',
  LV: 'lv_lv',
  SK: 'sk_sk',
  KM: 'km_km',
  LO: 'lo_lo',
};

// Available Platform languages with codes
export const platformLanguages = [
  { name: 'English', code: languages.EN },
  { name: 'Español', code: languages.ES },
  { name: 'Français', code: languages.FR },
  { name: 'Português', code: languages.PT },
  { name: 'Português (Brasil)', code: languages.BR },
  { name: 'Italiano', code: languages.IT },
  { name: 'Latviski', code: languages.LV },
  { name: 'Slovenský', code: languages.SK },
  { name: 'Հայերեն', code: languages.HY },
  { name: 'ไทย', code: languages.TH },
  { name: 'Tiếng Việt', code: languages.VI },
  { name: 'ភាសាខ្មែរ', code: languages.KM },
  { name: 'ລາວ', code: languages.LO },
];

export const getLanguagesDisplay = (t, language) =>
  ({
    [languages.EN]: t('English'),
    [languages.ES]: t('Spanish'),
    [languages.FR]: t('French'),
    [languages.PT]: t('Portuguese'),
    [languages.BR]: t('Portuguese (BR)'),
    [languages.IT]: t('Italian'),
    [languages.LV]: t('Latvian'),
    [languages.SK]: t('Slovak'),
    [languages.HY]: t('Armenian'),
    [languages.TH]: t('Thai'),
    [languages.VI]: t('Vietnamese'),
    [languages.KM]: t('Central Khmer'),
    [languages.LO]: t('Lao'),
  })[language];

/**
 * Map function that iterates through language codes
 * @function mapLanguageCodes
 * @param {function} callback - Function that will be called for every language
 * @return {Array}
 */
export const mapLanguageCodes = callback =>
  Object.values(languages).map(language => {
    const lang = language.split('_')[0];
    return (callback && callback(lang)) || lang;
  });

// If we don't pass any callback, we get an array with lang codes: ['en', 'es'...]
export const getLanguageCodes = mapLanguageCodes;

// Function that transforms language codes into names: ["sq_sq", ...] -> ["LAN_sq", ...]
export const languageCodesToNames = (inputArray, t) =>
  inputArray.map(element => t(`LAN_${element.split('_')[1]}`));

const messages = {
  en_us: enUS,
  es_es: esES,
  fr_fr: frFR,
  pt_pt: ptPT,
  pt_br: brPT,
  hy_hy: hyHY,
  th_th: thTH,
  vi_vi: viVI,
  it_it: itIT,
  lv_lv: lvLV,
  sk_sk: skSK,
  km_km: kmKM,
  lo_lo: loLo,
};

const wrap =
  (i18n, fn) =>
  (...args) => {
    try {
      return i18n[fn](...args);
    } catch (error) {
      return args[0];
    }
  };

const getTranslationHandlers = lang => {
  const i18n = new Jed(messages[lang]);
  return {
    t: wrap(i18n, 'gettext'),
    dt: wrap(i18n, 'dgettext'),
    tn: wrap(i18n, 'ngettext'),
    dtn: wrap(i18n, 'dngettext'),
    tctxt: wrap(i18n, 'pgettext'),
    dtctxt: wrap(i18n, 'dpgettext'),
    tnctxt: wrap(i18n, 'npgettext'),
    dtnctxt: wrap(i18n, 'dnpgettext'),
    format: wrap(i18n, 'sprintf'),
    language: lang,
  };
};

export default getTranslationHandlers;
