import api from './api';

const createProduct = ({ project, ...product }) =>
  api.post(`/api/projects/${project}/products/`, { ...product });

const deleteProduct = ({ productId, project }) =>
  api.delete(`/api/projects/${project}/products/${productId}/`);

const fetchProduct = ({ id, project }) => api.get(`/api/projects/${project}/products/${id}/`);

const listProducts = ({ project }) => api.get(`/api/projects/${project}/products/`);

const updateProduct = ({ project, productId, ...product }) =>
  api.patch(`/api/projects/${project}/products/${productId}/`, { ...product });

export default {
  createProduct,
  deleteProduct,
  fetchProduct,
  listProducts,
  updateProduct,
};
