import * as reduxHelper from 'store/utils/reduxHelper';
import MemberSurveys from 'models/MemberSurveys';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MEMBER_SURVEYS_CREATE_SUCCEEDED: {
      const survey = action.payload;
      const memberSurveys = {
        ...state.data.byId,
        [survey.id]: new MemberSurveys(action.payload),
      };

      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            ...state.data,
            byId: memberSurveys,
          },
        })
      );
    }

    case types.MEMBER_SURVEYS_CREATE_FAILED:
    case types.SURVEYS_LIST_FAILED:
    default:
      return state;
  }
}
