import types from './types';

const fetchPills = payload => ({
  type: types.PILLS_FETCH,
  payload,
});

const fetchPillsSucceeded = payload => ({
  type: types.PILLS_FETCH_SUCCEEDED,
  payload,
});

const fetchPillsFailed = ({ message }) => ({
  type: types.PILLS_FETCH_FAILED,
  payload: { message },
});

export default {
  fetchPills,
  fetchPillsSucceeded,
  fetchPillsFailed,
};
