import api from './api';

const fetchProgramTools = query => api.get('/api/program-tools/', { params: query });

const updateProgramTool = programTool =>
  api.patch(`/api/program-tools/${programTool.id}/?program=${programTool.program}`, {
    id: programTool.id,
    program: programTool.program,
    start_date: programTool.start_date,
    end_date: programTool.end_date,
    intro_video_url: programTool.intro_video_url,
    intro_video_url_en: programTool.intro_video_url_en,
    intro_video_url_es: programTool.intro_video_url_es,
    intro_video_url_fr: programTool.intro_video_url_fr,
    intro_video_url_pt: programTool.intro_video_url_pt,
    intro_video_url_pt_br: programTool.intro_video_url_pt_br,
    intro_video_url_hy: programTool.intro_video_url_hy,
    intro_video_url_it: programTool.intro_video_url_it,
    intro_video_url_sk: programTool.intro_video_url_sk,
    intro_video_url_lv: programTool.intro_video_url_lv,
    intro_video_url_vi: programTool.intro_video_url_vi,
    intro_video_url_th: programTool.intro_video_url_th,
    intro_video_url_km: programTool.intro_video_url_km,
    intro_video_url_lo: programTool.intro_video_url_lo,
  });

export default {
  fetchProgramTools,
  updateProgramTool,
};
