import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Initiative from 'models/GrowthPlan/Initiative';
import serviceInitiative from 'services/GrowthPlan/initiative';
import types from './types';
import actions from './actions';

export function* createInitiative(action) {
  const { objective, project, ...initiative } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(serviceInitiative.createInitiative, {
      objective,
      project,
      ...initiative,
    });
    yield put(actions.createInitiativeSucceeded({ project, ...response.data }));
    if (callback) yield callback(response.data.id);
  } catch (e) {
    yield put(
      actions.createInitiativeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteInitiative(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceInitiative.deleteInitiative, { id });
    yield put(actions.deleteInitiativeSucceeded({ project, id }));
  } catch (e) {
    yield put(
      actions.deleteInitiativeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchInitiative(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceInitiative.fetchInitiative, { id });
    yield put(actions.fetchInitiativeSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(actions.fetchInitiativeFailed({ message: e.message }));
  }
}

export function* listInitiatives(action) {
  const { project } = action.payload;
  try {
    const initiatives = yield call(serviceInitiative.listInitiatives, {
      project,
    });
    yield put(actions.listInitiativesSucceeded({ project, results: initiatives.data }));
  } catch (e) {
    yield put(actions.listInitiativesFailed({ message: e.message }));
  }
}

export function* updateInitiative(action) {
  const { id, project, ...updatedInitiative } = action.payload;
  try {
    const updated = yield call(serviceInitiative.updateInitiative, {
      id,
      ...updatedInitiative,
    });
    yield put(actions.updateInitiativeSucceeded({ project, ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateInitiativeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Initiative.contentType) {
    yield put(
      actions.fetchInitiative({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* initiativeSaga() {
  yield all([
    takeLatest(types.INITIATIVE_CREATE, createInitiative),
    takeLatest(types.INITIATIVE_DELETE, deleteInitiative),
    takeLatest(types.INITIATIVE_FETCH, fetchInitiative),
    takeLatest(types.INITIATIVE_UPDATE, updateInitiative),
    takeEvery(types.INITIATIVES_LIST, listInitiatives),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default initiativeSaga;
