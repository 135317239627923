import api from '../api';

const updateMonthlyUnitsSold = ({ id, ...updatedMonthlyUnitsSold }) =>
  api({
    method: 'patch',
    url: `api/monthly-units-sold/${id}/`,
    data: updatedMonthlyUnitsSold,
  });

export default {
  updateMonthlyUnitsSold,
};
