import { Record } from 'immutable';
import ContentType from './ContentType';

const CommentRecord = new Record({
  id: null,
  contentType: ContentType.COMMENT,
  message: '',
  item: '',
  user: null,
  project: null,
  action: '',
  content_type: null,
  object_id: null,
  content_object: null,
  created: null,
  updated: null,
});

class Comment extends CommentRecord {
  static ACTION_CREATED_COMMENT = 'CREATED_COMMENT';
  static ACTION_EDIT_COMMENT = 'EDIT_COMMENT';
  static ACTION_CREATED_FEEDBACK = 'CREATED_FEEDBACK';

  static contentType = ContentType.COMMENT;

  get id() {
    return this.get('id');
  }
}

export default Comment;
