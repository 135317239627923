// Libraries
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// Dependencies
import { CELL_ALIGNMENT } from '../dependencies';
import TableContext from '../Context/context';

// Components
import { HeaderCell, StandardCell } from '../styled-components';

const BodyCell = props => {
  const { tableColumns, head, children, align, className, isNumeric, fixed, ...cellProps } = props;
  const isHeaderCell = head;

  const { backgroundColor } = useContext(TableContext);

  const cell = useMemo(() => {
    // HEADER CELLS
    if (isHeaderCell) {
      return (
        <HeaderCell
          className={className || 'table-body-header-cell'}
          align={align || (isNumeric ? CELL_ALIGNMENT.RIGHT : CELL_ALIGNMENT.LEFT)}
          fixed={fixed}
          backgroundColor={backgroundColor}
          {...cellProps}
        >
          {children}
        </HeaderCell>
      );
    }
    // STANDARD CELLS
    return (
      <StandardCell
        className={className || 'table-body-standard-cell'}
        align={align || (isNumeric ? CELL_ALIGNMENT.RIGHT : CELL_ALIGNMENT.LEFT)}
        fixed={fixed}
        backgroundColor={backgroundColor}
        {...cellProps}
      >
        {children}
      </StandardCell>
    );
  }, [children, isHeaderCell, align, backgroundColor, cellProps, className, fixed, isNumeric]);

  return cell;
};

BodyCell.propTypes = {
  tableColumns: PropTypes.instanceOf(Object).isRequired,
  head: PropTypes.bool,
  isNumeric: PropTypes.bool,
  children: PropTypes.node,
  align: PropTypes.string,
  className: PropTypes.string,
  fixed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

BodyCell.defaultProps = {
  head: undefined,
  isNumeric: undefined,
  children: undefined,
  align: undefined,
  className: undefined,
  fixed: undefined,
};

export default React.memo(BodyCell);
