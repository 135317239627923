import { createSelector } from 'reselect';

const getState = state => state.scenario;

const getRawScenarios = createSelector([getState], state => state.get('data'));

const getScenariosById = createSelector([getRawScenarios], state => state.get('byId'));

const getScenarios = createSelector([getScenariosById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getScenarios,
  getScenariosById,
  getErrorMessage,
  isFetching,
};
