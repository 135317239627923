import { createSelector } from 'reselect';

const getState = state => state.sector;

const getRawSectors = createSelector([getState], state => state.get('data'));

const getSectorById = createSelector([getRawSectors], state => state.get('byId'));

const listSectors = createSelector([getSectorById], state => state.valueSeq());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getRawSectors,
  getSectorById,
  listSectors,
  getErrorMessage,
};
