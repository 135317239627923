import { createSelector } from 'reselect';

const getState = state => state.translations;

const getSelectedLanguage = createSelector([getState], translations =>
  translations.get('selectedLanguage')
);

const getLanguages = createSelector([getState], translations => translations.get('languages'));

const getTranslations = createSelector(
  [getLanguages, getSelectedLanguage],
  (languages, selectedLanguage) => languages.get(selectedLanguage)
);

export default {
  getTranslations,
  getSelectedLanguage,
  getLanguages,
};
