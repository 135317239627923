import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import System from 'models/ImpactPlan/System';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SYSTEMS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, system) => ({ ...obj, [system.id]: new System(system) }),
              {}
            ),
          },
        })
      );
    case types.SYSTEM_FETCH_SUCCEEDED:
    case types.SYSTEM_CREATE_SUCCEEDED:
    case types.SYSTEM_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new System(action.payload)),
          },
        })
      );
    case types.SYSTEM_FETCH:
    case types.SYSTEM_CREATE:
    case types.SYSTEM_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.SYSTEM_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
          },
        })
      );
    case types.SYSTEM_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.SYSTEM_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.SYSTEM_FETCH_FAILED:
    case types.SYSTEMS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
