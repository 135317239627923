const FETCH_MY_PROGRAM_DEPENDENCIES = 'bfb/combiners/myProgram/FETCH_MY_PROGRAM_DEPENDENCIES';
const FETCH_MY_PROGRAM_DEPENDENCIES_FAILED =
  'bfb/combiners/myProgram/FETCH_MY_PROGRAM_DEPENDENCIES_FAILED';

const CREATE_APPLICATION_AND_FETCH_DEPENDENCIES =
  'bfb/combiners/myProgram/CREATE_APPLICATION_AND_FETCH_DEPENDENCIES';
const CREATE_APPLICATION_AND_FETCH_DEPENDENCIES_FAILED =
  'bfb/combiners/myProgram/CREATE_APPLICATION_AND_FETCH_DEPENDENCIES_FAILED';

export default {
  FETCH_MY_PROGRAM_DEPENDENCIES,
  FETCH_MY_PROGRAM_DEPENDENCIES_FAILED,
  CREATE_APPLICATION_AND_FETCH_DEPENDENCIES,
  CREATE_APPLICATION_AND_FETCH_DEPENDENCIES_FAILED,
};
