import types from './types';

const createEducationExperience = ({ ...educationExperience }, callback) => ({
  type: types.EDUCATION_EXPERIENCE_CREATE,
  payload: { ...educationExperience },
  callback,
});

const createEducationExperienceSucceeded = ({ ...educationExperience }) => ({
  type: types.EDUCATION_EXPERIENCE_CREATE_SUCCEEDED,
  payload: { ...educationExperience },
});

const createEducationExperienceFailed = ({ message }) => ({
  type: types.EDUCATION_EXPERIENCE_CREATE_FAILED,
  payload: { message },
});

const fetchEducationExperience = query => ({
  type: types.EDUCATION_EXPERIENCE_REQUEST,
  payload: query,
});

const fetchEducationExperienceSucceeded = ({ ...educationExperienceData }) => ({
  type: types.EDUCATION_EXPERIENCE_SUCCEEDED,
  payload: { ...educationExperienceData },
});

const fetchEducationExperienceFailed = ({ message }) => ({
  type: types.EDUCATION_EXPERIENCE_FAILED,
  payload: { message },
});

const updateEducationExperience = ({ id, ...educationExperience }, callback) => ({
  type: types.EDUCATION_EXPERIENCE_UPDATE,
  payload: { id, ...educationExperience },
  callback,
});

const updateEducationExperienceSucceeded = ({ ...educationExperienceData }) => ({
  type: types.EDUCATION_EXPERIENCE_UPDATE_SUCCEEDED,
  payload: { ...educationExperienceData },
});

const updateEducationExperienceFailed = ({ message }) => ({
  type: types.EDUCATION_EXPERIENCE_UPDATE_FAILED,
  payload: message,
});

const deleteEducationExperience = (experienceId, callback) => ({
  type: types.EDUCATION_EXPERIENCE_DELETE,
  payload: experienceId,
  callback,
});

const deleteEducationExperienceSucceeded = experienceId => ({
  type: types.EDUCATION_EXPERIENCE_DELETE_SUCCEEDED,
  payload: experienceId,
});

const deleteEducationExperienceFailed = ({ message }) => ({
  type: types.EDUCATION_EXPERIENCE_DELETE_FAILED,
  payload: message,
});

export default {
  createEducationExperience,
  createEducationExperienceSucceeded,
  createEducationExperienceFailed,
  fetchEducationExperience,
  fetchEducationExperienceSucceeded,
  fetchEducationExperienceFailed,
  updateEducationExperience,
  updateEducationExperienceSucceeded,
  updateEducationExperienceFailed,
  deleteEducationExperience,
  deleteEducationExperienceSucceeded,
  deleteEducationExperienceFailed,
};
