import { Record } from 'immutable';
import ContentType from '../ContentType';

const RoleRecord = Record({
  id: null,
  name: null,
  system: null,
  created: null,
  updated: null,
  comments: [],
});

class Role extends RoleRecord {
  static contentType = ContentType.ROLE;

  get getId() {
    return this.get('id');
  }
}

export default Role;
