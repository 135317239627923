import styled from 'styled-components';
import { InputNumber as AntInputNumber } from 'antd';
import InputStyle from './common';

const InputNumber = styled(AntInputNumber)`
  ${InputStyle}
  width: ${props => (props.$fullWidth ? '100%' : '80px')};

  .ant-input-number-handler-wrap {
    border-left: none;
  }

  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 50% !important;
  }

  .ant-input-number-handler {
    &-up,
    &-down {
      border: none !important;
      &:hover {
        height: 50% !important;
      }
    }
  }

  .ant-input-number-input {
    text-align: ${props => props.align || 'center'};
    height: 25px;
    padding-inline: 0;
  }
`;

export default InputNumber;
