const SCENARIO_CLEAR_ERROR = 'bfb/scenario/SCENARIO_CLEAR_ERROR';
const SCENARIOS_LIST = 'bfb/scenario/SCENARIOS_LIST';
const SCENARIOS_LIST_SUCCEEDED = 'bfb/scenario/SCENARIOS_LIST_SUCCEEDED';
const SCENARIOS_LIST_FAILED = 'bfb/scenario/SCENARIOS_LIST_FAILED';
const SCENARIO_CREATE = 'bfb/scenario/SCENARIO_CREATE';
const SCENARIO_CREATE_SUCCEEDED = 'bfb/scenario/SCENARIO_CREATE_SUCCEEDED';
const SCENARIO_CREATE_FAILED = 'bfb/scenario/SCENARIO_CREATE_FAILED';
const SCENARIO_DELETE = 'bfb/scenario/SCENARIO_DELETE';
const SCENARIO_DELETE_SUCCEEDED = 'bfb/scenario/SCENARIO_DELETE_SUCCEEDED';
const SCENARIO_DELETE_FAILED = 'bfb/scenario/SCENARIO_DELETE_FAILED';
const SCENARIO_FETCH = 'bfb/scenario/SCENARIO_FETCH';
const SCENARIO_FETCH_SUCCEEDED = 'bfb/scenario/SCENARIO_FETCH_SUCCEEDED';
const SCENARIO_FETCH_FAILED = 'bfb/scenario/SCENARIO_FETCH_FAILED';
const SCENARIO_UPDATE = 'bfb/scenario/SCENARIO_UPDATE';
const SCENARIO_UPDATE_SUCCEEDED = 'bfb/scenario/SCENARIO_UPDATE_SUCCEEDED';
const SCENARIO_UPDATE_FAILED = 'bfb/scenario/SCENARIO_UPDATE_FAILED';

export default {
  SCENARIOS_LIST,
  SCENARIOS_LIST_SUCCEEDED,
  SCENARIOS_LIST_FAILED,
  SCENARIO_CLEAR_ERROR,
  SCENARIO_CREATE,
  SCENARIO_CREATE_SUCCEEDED,
  SCENARIO_CREATE_FAILED,
  SCENARIO_DELETE,
  SCENARIO_DELETE_SUCCEEDED,
  SCENARIO_DELETE_FAILED,
  SCENARIO_FETCH,
  SCENARIO_FETCH_SUCCEEDED,
  SCENARIO_FETCH_FAILED,
  SCENARIO_UPDATE,
  SCENARIO_UPDATE_SUCCEEDED,
  SCENARIO_UPDATE_FAILED,
};
