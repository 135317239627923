import { call, put, all, takeLatest } from 'redux-saga/effects';

import serviceWorkExperience from 'services/workExperience';
import actions from './actions';
import types from './types';

export function* fetchWorkExperience(action) {
  try {
    const workExperiences = yield call(serviceWorkExperience.getWorkExperience, action.payload);
    yield put(
      actions.fetchWorkExperienceSucceeded({
        workExperienceData: workExperiences.data,
      })
    );
  } catch (e) {
    yield put(
      actions.fetchWorkExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateWorkExperience(action) {
  const {
    payload: { ...workExperience },
    callback,
  } = action;
  try {
    const updated = yield call(serviceWorkExperience.updateWorkExperience, {
      ...workExperience,
    });
    yield put(actions.updateWorkExperienceSucceeded(updated.data));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.updateWorkExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* createWorkExperience(action) {
  const {
    payload: { ...workExperience },
    callback,
  } = action;
  try {
    const created = yield call(serviceWorkExperience.createWorkExperience, {
      ...workExperience,
    });
    yield put(actions.createWorkExperienceSucceeded(created.data));
    if (callback) callback(created.data);
  } catch (e) {
    yield put(
      actions.createWorkExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteWorkExperience(action) {
  const { payload, callback } = action;
  const experienceId = payload;
  try {
    yield call(serviceWorkExperience.deleteWorkExperience, experienceId);
    yield put(actions.deleteWorkExperienceSucceeded(experienceId));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.deleteWorkExperienceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* workExperienceSaga() {
  yield all([
    takeLatest(types.WORK_EXPERIENCE_REQUEST, fetchWorkExperience),
    takeLatest(types.WORK_EXPERIENCE_UPDATE, updateWorkExperience),
    takeLatest(types.WORK_EXPERIENCE_CREATE, createWorkExperience),
    takeLatest(types.WORK_EXPERIENCE_DELETE, deleteWorkExperience),
  ]);
}

export default workExperienceSaga;
