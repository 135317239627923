import types from './types';

const createChainAndChannel = ({ chainName, ...channel }) => ({
  type: types.CREATE_CHAIN_AND_CHANNEL,
  payload: { chainName, ...channel },
});

const createChainAndChannelFailed = () => ({
  type: types.CREATE_CHAIN_AND_CHANNEL_FAILED,
  payload: null,
});

export default {
  createChainAndChannel,
  createChainAndChannelFailed,
};
