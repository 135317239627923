const ATTRIBUTE_CLEAR_ERROR = 'bfb/competitionMap/attributes/ATTRIBUTE_CLEAR_ERROR';
const ATTRIBUTES_LIST = 'bfb/competitionMap/attributes/ATTRIBUTES_LIST';
const ATTRIBUTES_LIST_SUCCEEDED = 'bfb/competitionMap/attributes/ATTRIBUTES_LIST_SUCCEEDED';
const ATTRIBUTES_LIST_FAILED = 'bfb/competitionMap/attributes/ATTRIBUTES_LIST_FAILED';
const ATTRIBUTE_CREATE = 'bfb/competitionMap/attributes/ATTRIBUTE_CREATE';
const ATTRIBUTE_CREATE_SUCCEEDED = 'bfb/competitionMap/attributes/ATTRIBUTE_CREATE_SUCCEEDED';
const ATTRIBUTE_CREATE_FAILED = 'bfb/competitionMap/attributes/ATTRIBUTE_CREATE_FAILED';
const ATTRIBUTE_DELETE = 'bfb/competitionMap/attributes/ATTRIBUTE_DELETE';
const ATTRIBUTE_DELETE_SUCCEEDED = 'bfb/competitionMap/attributes/ATTRIBUTE_DELETE_SUCCEEDED';
const ATTRIBUTE_DELETE_FAILED = 'bfb/competitionMap/attributes/ATTRIBUTE_DELETE_FAILED';
const ATTRIBUTE_FETCH = 'bfb/competitionMap/attributes/ATTRIBUTE_FETCH';
const ATTRIBUTE_FETCH_SUCCEEDED = 'bfb/competitionMap/attributes/ATTRIBUTE_FETCH_SUCCEEDED';
const ATTRIBUTE_FETCH_FAILED = 'bfb/competitionMap/attributes/ATTRIBUTE_FETCH_FAILED';
const ATTRIBUTE_UPDATE = 'bfb/competitionMap/attributes/ATTRIBUTE_UPDATE';
const ATTRIBUTE_UPDATE_SUCCEEDED = 'bfb/competitionMap/attributes/ATTRIBUTE_UPDATE_SUCCEEDED';
const ATTRIBUTE_UPDATE_FAILED = 'bfb/competitionMap/attributes/ATTRIBUTE_UPDATE_FAILED';

export default {
  ATTRIBUTES_LIST,
  ATTRIBUTES_LIST_SUCCEEDED,
  ATTRIBUTES_LIST_FAILED,
  ATTRIBUTE_CLEAR_ERROR,
  ATTRIBUTE_CREATE,
  ATTRIBUTE_CREATE_SUCCEEDED,
  ATTRIBUTE_CREATE_FAILED,
  ATTRIBUTE_DELETE,
  ATTRIBUTE_DELETE_SUCCEEDED,
  ATTRIBUTE_DELETE_FAILED,
  ATTRIBUTE_FETCH,
  ATTRIBUTE_FETCH_SUCCEEDED,
  ATTRIBUTE_FETCH_FAILED,
  ATTRIBUTE_UPDATE,
  ATTRIBUTE_UPDATE_SUCCEEDED,
  ATTRIBUTE_UPDATE_FAILED,
};
