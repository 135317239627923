import { createSelector } from 'reselect';

const getState = state => state.chain;

const getRawChains = createSelector([getState], state => state.get('data'));

const getChainsById = createSelector([getRawChains], state => state.get('byId'));

const getChainsByProduct = createSelector([getRawChains], state => state.get('byProduct'));

const getChains = createSelector([getChainsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getChains,
  getChainsByProduct,
  getChainsById,
  getErrorMessage,
};
