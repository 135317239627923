import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import serviceOutcomeIndicator from 'services/ImpactPlan/outcomeIndicator';
import OutcomeIndicator from 'models/ImpactPlan/OutcomeIndicator';
import { types as commentTypes } from 'store/modules/comment';

import types from './types';
import actions from './actions';

export function* createOutcomeIndicator(action) {
  const { project, ...outcomeIndicator } = action.payload;
  try {
    const response = yield call(serviceOutcomeIndicator.createOutcomeIndicator, {
      project,
      ...outcomeIndicator,
    });
    yield put(actions.createOutcomeIndicatorsucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.createOutcomeIndicatorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteOutcomeIndicator(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceOutcomeIndicator.deleteOutcomeIndicator, { project, id });
    yield put(actions.deleteOutcomeIndicatorsSucceeded({ id }));
  } catch (e) {
    yield put(
      actions.deleteOutcomeIndicatorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchOutcomeIndicator(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceOutcomeIndicator.fetchOutcomeIndicator, {
      project,
      id,
    });
    yield put(actions.fetchOutcomeIndicatorsucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchOutcomeIndicatorFailed({ message: e.message }));
  }
}

export function* listOutcomeIndicators(action) {
  const { project } = action.payload;
  try {
    // project_sdg__project fetches outcomeIndicators by project
    const outcomeIndicators = yield call(serviceOutcomeIndicator.listOutcomeIndicators, {
      outcome__project_sdg__project: project,
    });
    yield put(
      actions.listOutcomeIndicatorsSucceeded({
        results: outcomeIndicators.data,
      })
    );
  } catch (e) {
    yield put(actions.listOutcomeIndicatorsFailed({ message: e.message }));
  }
}

export function* updateOutcomeIndicator(action) {
  const { project, id, ...outcomeIndicator } = action.payload;
  try {
    const updated = yield call(serviceOutcomeIndicator.updateOutcomeIndicator, {
      project,
      id,
      ...outcomeIndicator,
    });
    yield put(actions.updateOutcomeIndicatorsucceeded({ ...updated.data }));
    if (action.callback) yield call(action.callback);
  } catch (e) {
    yield put(
      actions.updateOutcomeIndicatorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === OutcomeIndicator.contentType) {
    yield put(
      actions.fetchOutcomeIndicator({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* saga() {
  yield all([
    takeLatest(types.OUTCOME_INDICATOR_CREATE, createOutcomeIndicator),
    takeLatest(types.OUTCOME_INDICATOR_DELETE, deleteOutcomeIndicator),
    takeLatest(types.OUTCOME_INDICATOR_FETCH, fetchOutcomeIndicator),
    takeLatest(types.OUTCOME_INDICATOR_UPDATE, updateOutcomeIndicator),
    takeEvery(types.OUTCOME_INDICATORS_LIST, listOutcomeIndicators),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default saga;
