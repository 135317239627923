// Libraries
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Button } from 'components/elements';
import { ToolOutput } from 'components/Tools/';
import ToolCommentIcon from '../ToolCommentIcon/index';

const StyledIcon = styled(ToolCommentIcon)`
  &&& {
    color: white;
    margin-left: 10px;
    margin-top: 3px;

    &:hover {
      color: white;
    }
  }
`;

const RawToolCollapse = props => {
  const {
    bottomChildren,
    children,
    className,
    colorIndex,
    header,
    highlightComments,
    initialShow,
    onCommentsClick,
    onToggle,
    showComments,
    ...rest
  } = props;
  const [show, setShow] = useState(initialShow);

  const onClickHandler = () => {
    setShow(prevShow => {
      if (onToggle) onToggle(!prevShow);
      return !prevShow;
    });
  };

  return (
    <div className={className}>
      <ToolOutput filled colorIndex={colorIndex} className="tool-collapse-container" {...rest}>
        <div className="tool-collapse-header">{header}</div>
        {show && <div className="tool-collapse-children">{children}</div>}
        <Button
          icon={show ? <UpOutlined /> : <DownOutlined />}
          onClick={onClickHandler}
          className="tool-collapse-button"
        />
        {showComments && <StyledIcon active={highlightComments} onClick={onCommentsClick} />}
      </ToolOutput>
      {show && <div className="tool-collapse-bottom-children">{bottomChildren}</div>}
    </div>
  );
};

RawToolCollapse.propTypes = {
  bottomChildren: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  colorIndex: PropTypes.number.isRequired,
  content: PropTypes.node,
  header: PropTypes.node.isRequired,
  highlightComments: PropTypes.bool,
  initialShow: PropTypes.bool,
  onCommentsClick: PropTypes.func,
  onToggle: PropTypes.func,
  showComments: PropTypes.bool,
};

RawToolCollapse.defaultProps = {
  bottomChildren: null,
  children: null,
  content: null,
  highlightComments: false,
  initialShow: false,
  onCommentsClick: null,
  onToggle: null,
  showComments: false,
};

const ToolCollapse = styled(RawToolCollapse)`
  &&& {
    padding: 0;
    margin-bottom: 10px;
    .tool-collapse-container {
      padding: 10px 20px;
      justify-content: center;
      .default {
        width: 100%;
      }
      .tool-collapse-children {
        color: white;
      }
      .tool-collapse-button {
        color: white !important;
        background-color: transparent !important;
        padding: 0 !important;
        margin-left: 0 !important;
      }
    }
    ${({ type }) =>
      type === 'row' &&
      css`
        .default {
          display: flex;
        }
        .tool-collapse-header {
          width: 100%;
          flex: 1;
        }
      `}
  }
`;

export default ToolCollapse;
