const PROBLEM_CLEAR_ERROR = 'bfb/problem/PROBLEM_CLEAR_ERROR';
const PROBLEMS_LIST = 'bfb/problem/PROBLEMS_LIST';
const PROBLEMS_LIST_SUCCEEDED = 'bfb/problem/PROBLEMS_LIST_SUCCEEDED';
const PROBLEMS_LIST_FAILED = 'bfb/problem/PROBLEMS_LIST_FAILED';
const PROBLEM_CREATE = 'bfb/problem/PROBLEM_CREATE';
const PROBLEM_CREATE_SUCCEEDED = 'bfb/problem/PROBLEM_CREATE_SUCCEEDED';
const PROBLEM_CREATE_FAILED = 'bfb/problem/PROBLEM_CREATE_FAILED';
const PROBLEM_DELETE = 'bfb/problem/PROBLEM_DELETE';
const PROBLEM_DELETE_SUCCEEDED = 'bfb/problem/PROBLEM_DELETE_SUCCEEDED';
const PROBLEM_DELETE_FAILED = 'bfb/problem/PROBLEM_DELETE_FAILED';
const PROBLEM_FETCH = 'bfb/problem/PROBLEM_FETCH';
const PROBLEM_FETCH_SUCCEEDED = 'bfb/problem/PROBLEM_FETCH_SUCCEEDED';
const PROBLEM_FETCH_FAILED = 'bfb/problem/PROBLEM_FETCH_FAILED';
const PROBLEM_UPDATE = 'bfb/problem/PROBLEM_UPDATE';
const PROBLEM_UPDATE_SUCCEEDED = 'bfb/problem/PROBLEM_UPDATE_SUCCEEDED';
const PROBLEM_UPDATE_FAILED = 'bfb/problem/PROBLEM_UPDATE_FAILED';

export default {
  PROBLEMS_LIST,
  PROBLEMS_LIST_SUCCEEDED,
  PROBLEMS_LIST_FAILED,
  PROBLEM_CLEAR_ERROR,
  PROBLEM_CREATE,
  PROBLEM_CREATE_SUCCEEDED,
  PROBLEM_CREATE_FAILED,
  PROBLEM_DELETE,
  PROBLEM_DELETE_SUCCEEDED,
  PROBLEM_DELETE_FAILED,
  PROBLEM_FETCH,
  PROBLEM_FETCH_SUCCEEDED,
  PROBLEM_FETCH_FAILED,
  PROBLEM_UPDATE,
  PROBLEM_UPDATE_SUCCEEDED,
  PROBLEM_UPDATE_FAILED,
};
