import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Mentor from 'models/Mentor';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: new Mentor(),
    mentorSelected: {
      data: new Mentor(),
      loading: false,
      error: '',
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MENTOR_FETCH:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.MENTOR_FETCH_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: new Mentor(action.payload),
          updatePending: false,
        })
      );
    case types.MENTOR_FETCH_FAILED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          errorMessage: action.payload,
        })
      );
    case types.MENTOR_EDIT:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.MENTOR_EDIT_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: new Mentor(action.payload),
        })
      );
    case types.MENTOR_EDIT_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.MENTOR_PUBLIC_FETCH:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          mentorSelected: {
            data: state.get('mentorSelected').get('data'),
            loading: true,
            error: '',
          },
        })
      );
    case types.MENTOR_PUBLIC_FETCH_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          mentorSelected: {
            data: action.payload,
            loading: false,
            error: '',
          },
        })
      );
    case types.MENTOR_PUBLIC_FETCH_FAILED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          mentorSelected: {
            data: new Mentor(),
            loading: false,
            error: action.payload,
          },
        })
      );
    default:
      return state;
  }
}
