import api from '../api';

const createSystem = ({ ...system }) => api.post('/api/systems/', { ...system });

const deleteSystem = ({ id }) => api.delete(`/api/systems/${id}/`);

const fetchSystem = ({ id }) => api.get(`/api/systems/${id}/`);

const listSystems = ({ project }) => api.get(`/api/systems/?project=${project}`);

const updateSystem = ({ id, ...system }) => api.patch(`/api/systems/${id}/`, { ...system });

export default {
  createSystem,
  deleteSystem,
  fetchSystem,
  listSystems,
  updateSystem,
};
