import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/projectLink';
import projectLinkService from 'services/projectLink';

export function* listLinks(action) {
  const links = yield call(projectLinkService.listProjectLinks, action.payload);
  yield put(actions.listProjectLinksSucceeded({ projectLinks: links.data }));
}

export function* updateLinks(action) {
  const links = yield call(projectLinkService.updateProjectLinks, action.payload);
  yield put(actions.updateProjectLinksSucceeded({ projectLinks: links.data }));
}

function* linkSaga() {
  yield all([
    takeLatest(types.PROJECT_LINKS_LIST, listLinks),
    takeLatest(types.PROJECT_LINK_UPDATE_REQUEST, updateLinks),
  ]);
}

export default linkSaga;
