import api, { setAuth } from './api';

// Access Token is used for regular use on the platform
export const ACCESS_TOKEN = 'access_token';

// Troubleshoot Token is used for troubleshooting mode in the Management Panel
export const TROUBLESHOOT_TOKEN = 'troubleshoot_token';

const getAuth = ({ username, password }) =>
  api.post(
    '/api/oauth/token/',
    {
      username: String(username).toLowerCase(), // Making sure the email is lower case.
      password,
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: 'password',
    },
    {
      transformRequest: data => {
        const form = new FormData();
        Object.keys(data).forEach(key => {
          form.append(key, data[key]);
        });
        return form;
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

const registerAuth = ({ username, password, member, mentor, organization, program }) =>
  api.post('/api/credentials/users/', {
    username: String(username).toLowerCase(), // Making sure the email is lower case.
    password,
    member,
    mentor,
    organization,
    program,
  });

const forgotPassword = email =>
  api.post('/api/credentials/users/reset_password/', {
    email: String(email).toLowerCase(), // Making sure the email is lower case.
  });

const resetPassword = ({ uid, token, user, password }) =>
  api.post('/api/credentials/users/reset_password_confirm/', {
    new_password: password,
    uid,
    token,
    user,
  });

const setToken = ({ accessToken }) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
  setAuth(accessToken);
};

export default {
  forgotPassword,
  getAuth,
  registerAuth,
  resetPassword,
  setToken,
};
