import { call, put, all, takeLatest } from 'redux-saga/effects';
import serviceBeneficiaries from 'services/ImpactPlan/beneficiaryTypes';
import actions from './actions';
import types from './types';

export function* listBeneficiaries() {
  try {
    const beneficiaryTypes = yield call(serviceBeneficiaries.listBeneficiaries);
    yield put(actions.listBeneficiariesSucceeded({ results: beneficiaryTypes.data }));
  } catch (e) {
    yield put(actions.listBeneficiariesFailed({ message: e.message }));
  }
}

function* saga() {
  yield all([takeLatest(types.BENEFICIARIES_LIST, listBeneficiaries)]);
}

export default saga;
