// Libraries
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';

// Dependencies
import { AVERAGE_OFFSET } from 'styles/toolColors';

// Components
import { InfoText, Span } from 'components/fontSystem';

// Locals
import {
  BAR_CHART_AVERAGE_LINE,
  BAR_CHART_LINE,
  baseClamp,
  calculateChartValues,
  TOP_INDICATOR_SPACING,
} from './dependencies';

import Legend, { LegendLine } from './Legend';
import AverageLine from './AverageLine';
import Line from './Line';

const DEFAULT_COLUMN_WIDTH = 130;
const DEFAULT_COLUMN_SIDES_PADDING = 15;
const CENTER_CONTENT_BREAKPOINT = 1280;
const DEFAULT_PRINT_COLUMN_WIDTH = 90;
const DEFAULT_PRINT_COLUMN_SIDES_PADDING = 10;

function BarChart(props) {
  const {
    data = [],
    shouldFixLeft,
    shouldRenderLegend,
    shouldCenterContent,
    currency,
    chartWidth,
    barsHeight,
    contentHeight,
    children,
    legendPlacement,
    /**
     * `backgroundColor` is expected to be hexadecimal to allow for transparent
     * backgrounds on indicators (transparency of 50%).
     */
    backgroundColor = '#FFFFFF',
    ...rest
  } = props;

  const [isContentCentered, setContentCentered] = useState(() => {
    if (window.innerWidth >= CENTER_CONTENT_BREAKPOINT) {
      return shouldCenterContent;
    }
    return false;
  });
  const [scrollWidth, setScrollWidth] = useState(undefined);

  /**
   * A calculation to check if there will be overflow depending
   * on the maximum number of columns is made here. If `dataWidth`
   * is bigger or equal than `scrollWidth`, then we can center the
   * data bars if `shouldCenterContent`. Otherwise they're placed at
   * the left to avoid clipping in the left border due to flex-box.
   *
   * TODO: It seems like it's not working as expected.
   * The code checks if dataWidth is less than scrollWidth, not the
   * other way around. This way shouldCenterData is true
   * when the content is expected to align to the left.
   */
  useEffect(() => {
    if (shouldCenterContent && scrollWidth) {
      const dataWidth = data.length * DEFAULT_COLUMN_WIDTH;
      const shouldCenterData = dataWidth <= scrollWidth;
      setContentCentered(shouldCenterData);
    }
  }, [scrollWidth, shouldCenterContent, data.length]);

  const timestampId = useMemo(() => Date.now(), []);

  const [highestValue, avg] = useMemo(() => calculateChartValues(data), [data]);

  useEffect(() => {
    const topDiv = document.getElementById(`${timestampId}-top-container`);
    const bottomDiv = document.getElementById(`${timestampId}-bottom-container`);

    const scrollTogether = scrollLeft => {
      topDiv.scrollLeft = scrollLeft;
      bottomDiv.scrollLeft = scrollLeft;
    };

    bottomDiv.addEventListener('scroll', event => {
      scrollTogether(event.target.scrollLeft);
    });
    topDiv.addEventListener('scroll', event => {
      scrollTogether(event.target.scrollLeft);
    });
  }, [timestampId]);

  /**
   * Clonning children to pass props.
   * Useful for the line components.
   */
  const [childrenWithProps, chartLegend] = useMemo(() => {
    const legend = [];
    const clonedChildren = React.Children.map(children, (child, index) => {
      // Building up the chart legend.
      if (React.isValidElement(child)) {
        switch (child.type.displayName) {
          case BAR_CHART_AVERAGE_LINE:
            legend.push(
              <LegendLine
                key={`${child.props.name || child.props.key || 'average'}-${avg}`}
                {...child.props}
                average
                colorIndex={AVERAGE_OFFSET}
                currency={currency}
                value={avg}
              />
            );
            break;
          case BAR_CHART_LINE:
            legend.push(
              <LegendLine
                key={`${child.props.name || child.props.key || index}-${child.props.value}`}
                {...child.props}
                currency={currency}
              />
            );
            break;
          default: // Do nothing.
        }
      }
      return React.cloneElement(child, {
        currency,
        highestValue,
        average: avg,
        backgroundColor,
      });
    });
    return [clonedChildren, legend];
  }, [children, avg, highestValue, backgroundColor, currency]);

  const { ref } = useResizeDetector({ onResize: ({ width }) => setScrollWidth(width) });

  return (
    <Chart shouldRenderLegend={shouldRenderLegend} {...rest}>
      <div
        style={{
          width: chartWidth,
        }}
      >
        <UpperContainer shouldCenterContent={isContentCentered} barsHeight={barsHeight}>
          {shouldFixLeft && <FixedLeftColumn />}
          <DataContainer id={`${timestampId}-top-container`}>
            <div ref={ref} className="resize-detector" />
            {data.map((dataValue, idx) => {
              const isStackedBar = Array.isArray(dataValue);
              /**
               * If it's a stacked column, then render them in the same
               * column element.
               */
              if (isStackedBar) {
                return (
                  <Column
                    stacked
                    /**
                     * If there are stacked columns, their position is not expected to change,
                     * or one of them to disappear/unmount, so we can use the index
                     * in this case because we actually do not have unique IDs.
                     */
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                  >
                    {dataValue.map((stackedColumnVal, index) => {
                      const isFirst = index === 0;
                      const stackedValue = stackedColumnVal.value;
                      /**
                       * In case the values are equal, we generate a random number
                       * based on their value.
                       */
                      const relativeHeight = (stackedValue / highestValue) * 100 || 0;
                      return (
                        <Bar
                          shouldHaveBordersTop={isFirst}
                          key={stackedColumnVal.key}
                          opacity={stackedColumnVal.opacity || 0}
                          heightPercentage={relativeHeight}
                          colorIndex={stackedColumnVal.colorIndex}
                          backgroundColor={backgroundColor}
                        />
                      );
                    })}
                  </Column>
                );
              }
              const relativeHeight = (dataValue.value / highestValue) * 100 || 0;
              return (
                <Column key={dataValue.key}>
                  <Bar
                    shouldHaveBordersTop
                    opacity={dataValue.opacity || 0}
                    heightPercentage={relativeHeight}
                    colorIndex={dataValue.colorIndex}
                    backgroundColor={backgroundColor}
                    bold={dataValue.bold}
                  >
                    {dataValue.shouldRenderIndicator && (
                      <Span $colorIndex={dataValue.colorIndex}>
                        {/* Smart decimals display */}
                        {Number(Math.round(dataValue.value * 100) / 100).toLocaleString()}
                      </Span>
                    )}
                  </Bar>
                </Column>
              );
            })}
          </DataContainer>
          {childrenWithProps}
        </UpperContainer>
        <BottomContainer shouldCenterContent={isContentCentered} contentHeight={contentHeight}>
          {shouldFixLeft && <FixedLeftColumn />}
          <DataContainer id={`${timestampId}-bottom-container`}>
            {data.map((dataValue, index) => {
              const isStackedBar = Array.isArray(dataValue);
              /**
               * If it's a stacked column, then render them in the same
               * column element.
               */
              if (isStackedBar) {
                return (
                  <Column
                    stacked
                    /**
                     * If there are stacked columns, their position is not expected to change,
                     * or one of them to disappear/unmount, so we can use the index
                     * in this case because we actually do not have unique IDs.
                     */
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    {dataValue.map(stackedColumnVal => (
                      <ColumnChildren key={stackedColumnVal.key}>
                        {stackedColumnVal.name && (
                          <InfoText $alignCenter $colorIndex={stackedColumnVal.colorIndex}>
                            {stackedColumnVal.name}
                          </InfoText>
                        )}
                        {stackedColumnVal.children}
                      </ColumnChildren>
                    ))}
                  </Column>
                );
              }
              return (
                <Column key={dataValue.key}>
                  <ColumnChildren>
                    {dataValue.name && (
                      <InfoText $alignCenter $colorIndex={dataValue.colorIndex}>
                        {dataValue.name}
                      </InfoText>
                    )}
                    {dataValue.children}
                  </ColumnChildren>
                </Column>
              );
            })}
          </DataContainer>
        </BottomContainer>
      </div>
      {shouldRenderLegend && <Legend placement={legendPlacement}>{chartLegend}</Legend>}
    </Chart>
  );
}

BarChart.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  shouldFixLeft: PropTypes.bool,
  shouldRenderLegend: PropTypes.bool,
  shouldCenterContent: PropTypes.bool,
  legendPlacement: PropTypes.string,
  currency: PropTypes.number,
  chartWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  barsHeight: PropTypes.number,
  contentHeight: PropTypes.number,
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
};

BarChart.defaultProps = {
  shouldFixLeft: false,
  shouldRenderLegend: false,
  shouldCenterContent: false,
  legendPlacement: undefined,
  currency: undefined,
  chartWidth: undefined,
  barsHeight: undefined,
  contentHeight: undefined,
  children: null,
  backgroundColor: undefined,
};

const Chart = styled.div`
  display: inline-block;
  max-width: 100%;
  @media print {
    width: 100%;
  }

  ${({ shouldRenderLegend }) =>
    shouldRenderLegend &&
    css`
      display: flex !important;
    `}
`;

const DataContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
`;

const UpperContainer = styled.div`
  height: ${({ barsHeight }) => (barsHeight ? `${barsHeight}px` : '385px')};
  position: relative;
  border-bottom: 2px solid #b3b3b3;
  flex-wrap: nowrap;
  display: flex;
  align-items: flex-end;
  justify-content: ${({ shouldCenterContent }) => shouldCenterContent && 'center'};
  ${DataContainer} {
    @media print {
      max-width: 100%;
    }
    position: relative;
    .resize-detector {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
    ${({ shouldCenterContent }) =>
      shouldCenterContent &&
      css`
        width: 100%;
        justify-content: space-evenly;
      `}
    padding-top: ${TOP_INDICATOR_SPACING};
    align-items: flex-end;
    overflow-x: auto;
    z-index: 1;
  }
`;

const BottomContainer = styled.div`
  height: ${({ contentHeight }) => (contentHeight ? `${contentHeight}px` : undefined)};
  position: relative;
  flex-wrap: nowrap;
  display: flex;
  align-items: flex-start;
  justify-content: ${({ shouldCenterContent }) => shouldCenterContent && 'center'};
  ${DataContainer} {
    ${({ shouldCenterContent }) =>
      shouldCenterContent &&
      css`
        width: 100%;
        justify-content: space-evenly;
      `}
    align-items: flex-start;
    overflow-x: auto;
    padding: 0 0 30px;
  }
`;

const FixedLeftColumn = styled.div`
  position: relative;
  height: 100%;
  width: ${DEFAULT_COLUMN_WIDTH}px;
  max-width: ${DEFAULT_COLUMN_WIDTH}px;
  min-width: ${DEFAULT_COLUMN_WIDTH}px;
  flex: ${DEFAULT_COLUMN_WIDTH}px;
  margin-right: ${DEFAULT_COLUMN_SIDES_PADDING}px;
  padding-top: ${TOP_INDICATOR_SPACING};

  @media print {
    width: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    max-width: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    min-width: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    flex: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    margin-right: ${DEFAULT_PRINT_COLUMN_SIDES_PADDING}px;
    padding-top: ${TOP_INDICATOR_SPACING};
  }
`;

const Column = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${({ stacked }) =>
    stacked
      ? css`
          flex-flow: column;
          justify-content: flex-end;
          align-items: center;
        `
      : css`
          align-items: flex-end;
          justify-content: center;
        `}
  height: 100%;
  width: ${DEFAULT_COLUMN_WIDTH}px;
  max-width: ${DEFAULT_COLUMN_WIDTH}px;
  min-width: ${DEFAULT_COLUMN_WIDTH}px;
  flex: ${DEFAULT_COLUMN_WIDTH}px;
  padding: 0 ${DEFAULT_COLUMN_SIDES_PADDING}px;

  @media print {
    width: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    max-width: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    min-width: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    flex: ${DEFAULT_PRINT_COLUMN_WIDTH}px;
    padding: 0 ${DEFAULT_PRINT_COLUMN_SIDES_PADDING}px;
  }
`;

const Bar = styled.div`
  position: relative;
  width: ${DEFAULT_COLUMN_WIDTH * 0.9}px;
  height: ${({ heightPercentage }) =>
    heightPercentage ? `${baseClamp(heightPercentage, 0, 100)}%` : '0%'};
  transition: height ease 200ms;
  will-change: height;
  border-radius: ${({ shouldHaveBordersTop }) => shouldHaveBordersTop && '4px 4px 0 0'};
  background-color: ${({ theme, colorIndex }) =>
    colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#999999'};
  -webkit-print-color-adjust: exact;
  @media print {
    width: ${DEFAULT_PRINT_COLUMN_WIDTH * 0.7}px;
  }
  :after {
    border-radius: ${({ shouldHaveBordersTop }) => shouldHaveBordersTop && '4px 4px 0 0'};
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: ${({ opacity }) => opacity || 0};
    background-color: ${({ backgroundColor }) => backgroundColor};
    -webkit-print-color-adjust: exact;
    pointer-events: none;
    transition: height ease 200ms;
    will-change: height;
  }

  span {
    position: absolute;
    bottom: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
    background: ${({ backgroundColor }) => backgroundColor};
    text-align: center;
    display: inline-flex;
    align-items: center;
    padding: 1px;
    border-radius: 4px;
    font-weight: ${({ bold }) => bold && 'bold'};
  }
`;

const ColumnChildren = styled.div`
  width: 100%;
`;

AverageLine.displayName = BAR_CHART_AVERAGE_LINE;
BarChart.AverageLine = AverageLine;
Line.displayName = BAR_CHART_LINE;
BarChart.Line = Line;

export default BarChart;
