import { Record } from 'immutable';
import ContentType from '../ContentType';

const InitiativeRecord = new Record({
  id: null,
  comments: [],
  name: null,
  created: null,
  updated: null,
  objective: null,
  contentType: ContentType.INITIATIVE,
});

class Initiative extends InitiativeRecord {
  static contentType = ContentType.INITIATIVE;

  get id() {
    return this.get('id');
  }
}

export default Initiative;
