import React from 'react';
import PropTypes from 'prop-types';
import withTranslations from 'hocs/withTranslations';

import isInteger from 'utils/isInteger';

import Form from '../index';

import Select from '../../SelectOld';

const SelectItem = props => {
  const { initialValue, label, name, t, ...rest } = props;

  return (
    <Form.Item
      label={label}
      rules={[
        {
          required: true,
          message: t('Please, select one'),
          validator: (rule, value, callback) => {
            if (!value && !isInteger(value)) {
              callback(t('Please, select one'));
            }
            callback();
          },
        },
      ]}
      initialValue={initialValue}
      name={name}
    >
      <Select {...rest} />
    </Form.Item>
  );
};

SelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.node,
  t: PropTypes.func.isRequired,
};

SelectItem.defaultProps = {
  initialValue: null,
};

export default withTranslations(SelectItem);
