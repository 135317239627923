import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/product';
import { types as commentTypes } from 'store/modules/comment';
import serviceProduct from 'services/product';
import Product from 'models/Product';

export function* createProduct(action) {
  const { project, ...product } = action.payload;
  try {
    const response = yield call(serviceProduct.createProduct, {
      project,
      ...product,
    });
    yield put(actions.createProductSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.createProductFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteProduct(action) {
  const { project, productId } = action.payload;
  try {
    yield call(serviceProduct.deleteProduct, { project, productId });
    yield put(actions.deleteProductSucceeded({ productId }));
  } catch (e) {
    yield put(
      actions.deleteProductFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchProduct(action) {
  const { id, project } = action.payload;
  try {
    const product = yield call(serviceProduct.fetchProduct, { id, project });
    yield put(actions.fetchProductSucceeded(product.data));
  } catch (e) {
    yield put(actions.fetchProductFailed({ message: e.message }));
  }
}

export function* listProducts(action) {
  const { project } = action.payload;
  try {
    const products = yield call(serviceProduct.listProducts, { project });
    yield put(actions.listProductsSucceeded({ results: products.data }));
  } catch (e) {
    yield put(actions.listProductsFailed({ message: e.message }));
  }
}

export function* updateProduct(action) {
  const { project, productId, ...product } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceProduct.updateProduct, {
      project,
      productId,
      ...product,
    });
    yield put(actions.updateProductSucceeded(updated.data));
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateProductFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Product.contentType) {
    yield put(
      actions.fetchProduct({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.PRODUCT_CREATE, createProduct),
    takeLatest(types.PRODUCT_DELETE, deleteProduct),
    takeLatest(types.PRODUCT_FETCH, fetchProduct),
    takeLatest(types.PRODUCT_UPDATE, updateProduct),
    takeLatest(types.PRODUCTS_LIST, listProducts),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default authSaga;
