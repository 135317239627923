import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// E Application as in information that E submits in order to enter Selection phase (Draft)

export const entrepreneurApplicationApi = createApi({
  reducerPath: 'entrepreneurApplicationApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/entrepreneur-applications/',
  }),
  endpoints: builder => ({
    // Fetch draft of an E application
    getApplicationDraftById: builder.query({
      query: applicationId => ({ url: `${applicationId}/draft` }),
    }),
    // Update draft of an E application, "data" is equal to modified field
    updateApplication: builder.mutation({
      query: ({ applicationId, data, allValues }) => ({
        url: `${applicationId}/draft`,
        method: 'PATCH',
        data,
      }),
    }),
    // Submit draft of an E application, "data" is equal to ALL application fields.
    submitApplication: builder.mutation({
      query: ({ applicationId, ...data }) => ({
        url: `${applicationId}/draft`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useGetApplicationDraftByIdQuery,
  useUpdateApplicationMutation,
  useSubmitApplicationMutation,
} = entrepreneurApplicationApi;
