import { call, put, all, takeLatest } from 'redux-saga/effects';
import service from 'services/toolsPdf';
import actions from './actions';
import types from './types';

export function* fetchPdfTools(action) {
  const { payload, callback } = action;
  try {
    const fetchDataQuery = yield {
      endpoint: service.endpoints.pricing_business_viability,
      ...payload,
    };
    const response = yield call(service.fetchData, fetchDataQuery);
    yield put(actions.fetchPdfToolsSucceeded({ ...response.data }));
    if (callback) yield callback();
  } catch (e) {
    yield put(
      actions.fetchPdfToolsFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* pdfToolsSaga() {
  yield all([takeLatest(types.PDF_TOOL_FETCH, fetchPdfTools)]);
}

export default pdfToolsSaga;
