import api from './api';

const listExecutiveSummaries = query => api.get('/api/executive-summaries/', { params: query });

const fetchExecutiveSummary = ({ projectId, step }) => {
  if (projectId) return api.get(`/api/executive-summaries/?project=${projectId}&step=${step}`);
};

const updateExecutiveSummary = ({ id, ...executiveSummary }) =>
  api.patch(`/api/executive-summaries/${id}/`, { ...executiveSummary });

const uploadExecutiveSummaryImage = ({ id, field, image }) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append(field, image);

  return api.patch(`/api/executive-summaries/${id}/`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const listMembersExecutiveSummary = query =>
  api.get('/api/executive-summary-members/', { params: query });

const createMemberExecutiveSummary = data =>
  api.post('/api/executive-summary-members/', { ...data });

const updateMemberExecutiveSummary = ({ id, ...memberExecutiveSummary }) =>
  api.patch(`/api/executive-summary-members/${id}/`, {
    ...memberExecutiveSummary,
  });

const uploadMemberExecutiveSummaryImage = ({ id, field, image }) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append(field, image);

  return api.patch(`/api/executive-summary-members/${id}/`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const exportProgramExecutiveSummaries = id =>
  api.get(`/documents/download-program-executive-summaries/?program=${id}`);

const deleteMemberExecutiveSummary = ({ id }) =>
  api.delete(`/api/executive-summary-members/${id}/`);

export default {
  listExecutiveSummaries,
  fetchExecutiveSummary,
  updateExecutiveSummary,
  listMembersExecutiveSummary,
  updateMemberExecutiveSummary,
  uploadMemberExecutiveSummaryImage,
  uploadExecutiveSummaryImage,
  createMemberExecutiveSummary,
  deleteMemberExecutiveSummary,
  exportProgramExecutiveSummaries,
};
