import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { P } from 'components/fontSystem';
import Button from '../../Button';
import Modal from '../index';

const StyledModal = styled(Modal)`
  z-index: 10000;
  padding-bottom: 55px !important;
  .description {
    margin-bottom: 30px;
  }
  .title {
    color: ${props => (props.type === 'danger' ? props.theme.error : '#123DC6 !important')};
    font-size: 20px !important;
  }
`;

const ErrorModal = props => {
  const {
    onCancel,
    visible,
    title,
    buttonValue,
    description,
    dangerHTML,
    dangerDescription,
    type,
    ...rest
  } = props;
  return (
    <StyledModal
      type={type}
      footer={null}
      overrideBodyStyle
      onCancel={onCancel}
      open={visible}
      title={
        <P className="title" $mb0>
          {title}
        </P>
      }
      {...rest}
    >
      {dangerHTML ? (
        <div
          className="description"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: dangerDescription }}
        />
      ) : (
        <div className="description">{description}</div>
      )}
      <div style={{ height: '50px' }}>
        <Button
          push="right"
          type={type === 'danger' ? 'danger' : 'primary'}
          htmlType="submit"
          onClick={onCancel}
          style={{ position: 'relative' }}
        >
          {buttonValue}
        </Button>
      </div>
    </StyledModal>
  );
};

ErrorModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  buttonValue: PropTypes.string,
  description: PropTypes.string,
  dangerDescription: PropTypes.func,
  dangerHTML: PropTypes.bool,
};

ErrorModal.defaultProps = {
  visible: false,
  dangerDescription: null,
  type: '',
  title: '',
  buttonValue: '',
  description: '',
  dangerHTML: false,
};

export default ErrorModal;
