import { createSelector } from 'reselect';

const getState = state => state.resources;

const getRawResources = createSelector([getState], state => state.get('data'));

const getResourcesById = createSelector([getRawResources], state => state.get('byId'));

const getResourcesByPartner = createSelector([getRawResources], state => state.get('byPartner'));

const getResources = createSelector([getResourcesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getResources,
  getResourcesByPartner,
  getResourcesById,
  getErrorMessage,
};
