import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Objective from 'models/GrowthPlan/Objective';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byProject: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.OBJECTIVES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, objective) =>
                prevData.set(objective.id.toString(), new Objective(objective)).sort(byId),
              state.get('data').get('byId')
            ),
            byProject: state
              .get('data')
              .get('byProject')
              .set(
                String(action.payload.project),
                fromJS(action.payload.results.map(goal => new Objective(goal))).sort(byId)
              ),
          },
        })
      );
    case types.OBJECTIVE_FETCH_SUCCEEDED:
    case types.OBJECTIVE_CREATE_SUCCEEDED:
    case types.OBJECTIVE_UPDATE_SUCCEEDED: {
      const projectId = String(action.payload.project);
      const projectGoals = state
        .get('data')
        .get('byProject')
        .get(projectId, new List())
        .filter(goal => goal.id !== action.payload.id)
        .push(new Objective(action.payload))
        .sort(byId);
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new Objective(action.payload)),
            byProject: state.get('data').get('byProject').set(projectId, projectGoals),
          },
        })
      );
    }
    case types.OBJECTIVE_FETCH:
    case types.OBJECTIVE_CREATE:
    case types.OBJECTIVE_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.OBJECTIVE_DELETE_SUCCEEDED: {
      const projectId = String(action.payload.project);
      const projectGoals = state
        .get('data')
        .get('byProject')
        .get(projectId, new List())
        .filter(goal => goal.id !== action.payload.id)
        .sort(byId);
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProject: state.get('data').get('byProject').set(projectId, projectGoals),
          },
        })
      );
    }
    case types.OBJECTIVE_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.OBJECTIVE_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.OBJECTIVE_FETCH_FAILED:
    case types.OBJECTIVES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
