import { createSelector } from 'reselect';

const getState = state => state.growthRisk;

const getRawGrowthRisk = createSelector([getState], state => state.get('data'));

const getGrowthRisksById = createSelector([getRawGrowthRisk], state => state.get('byId'));

const getGrowthRisks = createSelector([getRawGrowthRisk], state => state.get('flat'));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getGrowthRisks,
  getGrowthRisksById,
  getErrorMessage,
};
