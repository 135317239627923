import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import RiskCategory from 'models/GrowthPlan/RiskCategory';
import { byId } from 'store/utils/sortFunctions';

import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, flat: [] },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RISK_CATEGORIES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, riskCategory) =>
                prevData.set(riskCategory.id.toString(), new RiskCategory(riskCategory)),
              state.get('data').get('byId')
            ),
            flat: action.payload.results
              .reduce((prevData, riskCategory) => [new RiskCategory(riskCategory), ...prevData], [])
              .sort(byId),
          },
        })
      );
    case types.RISK_CATEGORY_FETCH_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new RiskCategory(action.payload)),
            flat: state
              .get('data')
              .get('flat')
              .filter(cat => cat.id !== action.payload.id)
              .push(new RiskCategory(action.payload))
              .sort(byId),
          },
        })
      );
    case types.RISK_CATEGORY_FETCH:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.RISK_CATEGORY_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.RISK_CATEGORY_FETCH_FAILED:
    case types.RISK_CATEGORIES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
