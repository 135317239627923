// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import Icon from '@ant-design/icons';

// assets
import { ReactComponent as playIcon } from 'static/icons/07-in-tool/07-1-icons/icon_video-play.svg';

// local imports
import { getThumbnail } from '../utils';

const ICON_SIZE = 50;

const StyledThumbnail = styled.div`
  border: 2px solid ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  height: 100%;
  max-height: 135px;
  max-width: 240px;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    width: 100%;
    /* opacity: 0.5; */
  }

  i.anticon.play-btn {
    position: absolute;
    top: calc(50% - ${ICON_SIZE / 2}px);
    left: calc(50% - ${ICON_SIZE / 2}px);
    font-size: ${ICON_SIZE}px;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const VideoThumbnail = props => {
  const { src, ...rest } = props;
  const [imgSrc, setImgSrc] = React.useState();

  if (!imgSrc) {
    getThumbnail(src).then(results => {
      const thumbnail = results.find(result => result.value);
      if (thumbnail) setImgSrc(thumbnail.value);
    });
  }

  return (
    <StyledThumbnail {...rest}>
      <img alt="video-thumbnail" src={imgSrc} />
      <div className="overlay" />
      <Icon className="play-btn" component={playIcon} />
    </StyledThumbnail>
  );
};

VideoThumbnail.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string,
};

VideoThumbnail.defaultProps = {
  onClick: undefined,
  src: '',
};

export default VideoThumbnail;
