import { createSelector } from 'reselect';

const getState = state => state.programMentors;

const getRawProgramMentors = createSelector([getState], state => state.get('data'));

const getProgramMentorsCount = createSelector([getRawProgramMentors], state => state.get('count'));

const getProgramMentors = createSelector([getRawProgramMentors], state =>
  state.get('admin').valueSeq().toJS()
);

const getIsFetching = createSelector([getState], state => state.get('isFetching'));

const getMentorsByCategory = createSelector(
  [getRawProgramMentors],
  state => category => state.get(category)
);

const mentorsListLoading = createSelector([getState], state => state.get('loading'));

const getMentorsCountByCategory = createSelector(
  [getRawProgramMentors],
  state => category => state.get(`${category}Count`)
);

export default {
  getProgramMentors,
  getProgramMentorsCount,
  getIsFetching,
  getMentorsByCategory,
  getMentorsCountByCategory,
  mentorsListLoading,
};
