import styled from 'styled-components';
import { Tabs } from 'antd';
import bfbTheme from 'styles/b4b-theme';

const DEFAULT_PADDING = 20;

const StyledTabs = styled(Tabs)`
  &&& {
    /* Tabs in Workspace */
    ${() => {
      const isInWorkspace =
        window.location.pathname.includes('workspace') ||
        window.location.pathname.includes('venture') ||
        window.location.pathname.includes('tools');

      if (isInWorkspace) {
        return `
        .ant-tabs-tab {
          background: ${bfbTheme.backgroundGreyColor};
          border: 1px solid #e8e8e8;
          border-radius: 4px 4px 0 0;
        }

        .ant-tabs-tab-active {
          background: white;
        }
        
        .ant-tabs-content {
          background: white;
          padding: ${DEFAULT_PADDING}px;
        }`;
      }
    }}

    /* Tabs in Parrobot */
    &.ant-tabs.parrobot-tabs {
      .ant-tabs-nav {
        border-top: solid 2px ${bfbTheme.teal}4D;
      }

      .ant-tabs-nav::before {
        border-color: transparent;
      }

      .ant-tabs-tab {
        border: none;
        background: white;
        border-radius: 0;
        padding-inline: 20px;

        &.ant-tabs-tab-active {
          background: ${bfbTheme.teal}4D;
        }

        & + .ant-tabs-tab {
          margin: 0;
        }
      }

      .ant-tabs-nav-more {
        display: none;
      }

      .anticon {
        margin-right: 5px;
        font-size: 18px;
      }
    }

    /* Other Tabs */
    &.ant-tabs .ant-tabs-tab-btn {
      border-bottom-color: ${bfbTheme.linkColor};
      transition: border-color 200ms ease;
      color: black;

      &:hover {
        color: black;
      }
    }

    &.ant-tabs-top {
      .ant-tabs-nav {
        margin-bottom: 0;

        &::before {
          border-color: ${bfbTheme.linkColor};
        }

        .ant-tabs-tab {
          border-bottom: 0;
          margin-bottom: 1px;

          &.ant-tabs-tab-active {
            border-color: ${bfbTheme.linkColor};
            margin-bottom: 0;
            font-weight: 600;
          }
        }
      }
    }
  }
`;

// Warning: TabPane is deprecated, use items prop instead
StyledTabs.TabPane = Tabs.TabPane;

export default StyledTabs;

export { DEFAULT_PADDING };
