import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/sector';
import serviceAreas from 'services/sectors';

export function* fetchSectors(action) {
  try {
    const areas = yield call(serviceAreas.listSectors, action.payload);
    yield put(actions.fetchSectorsSucceeded({ areasOfExpertiseData: areas.data }));
  } catch (e) {
    yield put(
      actions.fetchSectorsFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* sectorsSaga() {
  yield all([takeLatest(types.SECTORS_FETCH, fetchSectors)]);
}

export default sectorsSaga;
