import types from './types';

const clearError = () => ({
  type: types.PAIN_CLEAR_ERROR,
});

const createPain = ({ project, product, segment, ...pain }) => ({
  type: types.PAIN_CREATE,
  payload: { project, product, segment, ...pain },
});

const createPainSucceeded = ({ ...pain }) => ({
  type: types.PAIN_CREATE_SUCCEEDED,
  payload: { ...pain },
});

const createPainFailed = ({ message }) => ({
  type: types.PAIN_CREATE_FAILED,
  payload: { message },
});

const deletePain = ({ project, product, segment, painId }) => ({
  type: types.PAIN_DELETE,
  payload: { project, product, segment, painId },
});

const deletePainSucceeded = payload => ({
  type: types.PAIN_DELETE_SUCCEEDED,
  payload,
});

const deletePainFailed = ({ message }) => ({
  type: types.PAIN_DELETE_FAILED,
  payload: message,
});

const fetchPain = ({ project, product, segment, id }) => ({
  type: types.PAIN_FETCH,
  payload: { project, product, segment, id },
});

const fetchPainSucceeded = ({ ...pain }) => ({
  type: types.PAIN_FETCH_SUCCEEDED,
  payload: { ...pain },
});

const fetchPainFailed = ({ message }) => ({
  type: types.PAIN_FETCH_FAILED,
  payload: { message },
});

const listPains = ({ project, product, segment }) => ({
  type: types.PAINS_LIST,
  payload: { project, product, segment },
});

const listPainsSucceeded = payload => ({
  type: types.PAINS_LIST_SUCCEEDED,
  payload,
});

const listPainsFailed = ({ message }) => ({
  type: types.PAINS_LIST_FAILED,
  payload: { message },
});

const updatePain = ({ project, product, segment, painId, ...pain }) => ({
  type: types.PAIN_UPDATE,
  payload: { project, product, segment, painId, ...pain },
});

const updatePainSucceeded = ({ ...pain }) => ({
  type: types.PAIN_UPDATE_SUCCEEDED,
  payload: { ...pain },
});

const updatePainFailed = ({ message }) => ({
  type: types.PAIN_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createPain,
  createPainFailed,
  createPainSucceeded,
  deletePain,
  deletePainFailed,
  deletePainSucceeded,
  fetchPain,
  fetchPainFailed,
  fetchPainSucceeded,
  listPains,
  listPainsFailed,
  listPainsSucceeded,
  updatePain,
  updatePainFailed,
  updatePainSucceeded,
};
