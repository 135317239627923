import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Beneficiary from 'models/ImpactPlan/Beneficiary';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.BENEFICIARIES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (beneficiariesById, beneficiary) =>
                beneficiariesById.set(beneficiary.id.toString(), new Beneficiary(beneficiary)),
              state.get('data').get('byId')
            ),
          },
        })
      );
    case types.BENEFICIARY_FETCH_SUCCEEDED:
    case types.BENEFICIARY_CREATE_SUCCEEDED:
    case types.BENEFICIARY_UPDATE_SUCCEEDED:
      return (() =>
        state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Beneficiary(action.payload)),
            },
          })
        ))();
    case types.BENEFICIARY_FETCH:
    case types.BENEFICIARY_CREATE:
    case types.BENEFICIARY_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.BENEFICIARY_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
          },
        })
      );
    case types.BENEFICIARY_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.BENEFICIARY_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.BENEFICIARY_FETCH_FAILED:
    case types.BENEFICIARIES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
