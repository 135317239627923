const BUSINESS_MODELS_LIST = 'bfb/BUSINESS_MODEL/BUSINESS_MODELS_LIST';
const BUSINESS_MODELS_LIST_SUCCEEDED = 'bfb/BUSINESS_MODEL/BUSINESS_MODELS_LIST_SUCCEEDED';
const BUSINESS_MODELS_LIST_FAILED = 'bfb/BUSINESS_MODEL/BUSINESS_MODELS_LIST_FAILED';

const PRODUCT_BUSINESS_MODEL_CLEAR_ERROR =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_CLEAR_ERROR';
const PRODUCT_BUSINESS_MODELS_LIST = 'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODELS_LIST';
const PRODUCT_BUSINESS_MODELS_LIST_SUCCEEDED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODELS_LIST_SUCCEEDED';
const PRODUCT_BUSINESS_MODELS_LIST_FAILED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODELS_LIST_FAILED';
const PRODUCT_BUSINESS_MODEL_CREATE = 'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_CREATE';
const PRODUCT_BUSINESS_MODEL_CREATE_SUCCEEDED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_CREATE_SUCCEEDED';
const PRODUCT_BUSINESS_MODEL_CREATE_FAILED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_CREATE_FAILED';
const PRODUCT_BUSINESS_MODEL_DELETE = 'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_DELETE';
const PRODUCT_BUSINESS_MODEL_DELETE_SUCCEEDED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_DELETE_SUCCEEDED';
const PRODUCT_BUSINESS_MODEL_DELETE_FAILED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_DELETE_FAILED';
const PRODUCT_BUSINESS_MODEL_FETCH = 'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_FETCH';
const PRODUCT_BUSINESS_MODEL_FETCH_SUCCEEDED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_FETCH_SUCCEEDED';
const PRODUCT_BUSINESS_MODEL_FETCH_FAILED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_FETCH_FAILED';
const PRODUCT_BUSINESS_MODEL_UPDATE = 'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_UPDATE';
const PRODUCT_BUSINESS_MODEL_UPDATE_SUCCEEDED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_UPDATE_SUCCEEDED';
const PRODUCT_BUSINESS_MODEL_UPDATE_FAILED =
  'bfb/PRODUCT_BUSINESS_MODEL/PRODUCT_BUSINESS_MODEL_UPDATE_FAILED';

export default {
  BUSINESS_MODELS_LIST,
  BUSINESS_MODELS_LIST_SUCCEEDED,
  BUSINESS_MODELS_LIST_FAILED,
  PRODUCT_BUSINESS_MODELS_LIST,
  PRODUCT_BUSINESS_MODELS_LIST_SUCCEEDED,
  PRODUCT_BUSINESS_MODELS_LIST_FAILED,
  PRODUCT_BUSINESS_MODEL_CLEAR_ERROR,
  PRODUCT_BUSINESS_MODEL_CREATE,
  PRODUCT_BUSINESS_MODEL_CREATE_SUCCEEDED,
  PRODUCT_BUSINESS_MODEL_CREATE_FAILED,
  PRODUCT_BUSINESS_MODEL_DELETE,
  PRODUCT_BUSINESS_MODEL_DELETE_SUCCEEDED,
  PRODUCT_BUSINESS_MODEL_DELETE_FAILED,
  PRODUCT_BUSINESS_MODEL_FETCH,
  PRODUCT_BUSINESS_MODEL_FETCH_SUCCEEDED,
  PRODUCT_BUSINESS_MODEL_FETCH_FAILED,
  PRODUCT_BUSINESS_MODEL_UPDATE,
  PRODUCT_BUSINESS_MODEL_UPDATE_SUCCEEDED,
  PRODUCT_BUSINESS_MODEL_UPDATE_FAILED,
};
