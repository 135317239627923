import { call, put, all, takeLatest } from 'redux-saga/effects';

import serviceUserLink from 'services/user/userlink';
import actions from './actions';
import types from './types';

export function* fetchLinks(action) {
  try {
    const links = yield call(serviceUserLink.getUserLinks, action.payload);
    yield put(actions.userLinkSucceeded({ userLinkData: links.data }));
  } catch (e) {
    yield put(
      actions.userLinkFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* editLinksBulk(action) {
  const {
    callback,
    payload: { user, data },
  } = action;
  try {
    const links = yield call(serviceUserLink.editUserLinksBulk, user, data);
    yield put(actions.userLinkBulkEditSucceeded({ userLinkData: links.data }));
    if (callback) callback();
  } catch (e) {
    yield put(
      actions.userLinkBulkEditFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* linkSaga() {
  yield all([
    takeLatest(types.USER_LINK_REQUEST, fetchLinks),
    takeLatest(types.USER_LINK_BULK_EDIT, editLinksBulk),
  ]);
}

export default linkSaga;
