import api from '../api';

const createAttribute = ({ project, product, ...attribute }) =>
  api.post(`api/projects/${project}/products/${product}/attributes/`, {
    ...attribute,
  });

const deleteAttribute = ({ project, product, attributeId }) =>
  api.delete(`api/projects/${project}/products/${product}/attributes/${attributeId}/`);

const fetchAttribute = ({ project, product, id }) =>
  api.get(`api/projects/${project}/products/${product}/attributes/${id}/`);

const listAttributes = ({ project, product }) =>
  api.get(`api/projects/${project}/products/${product}/attributes/`);

const updateAttribute = ({ project, product, attributeId, ...updatedAttribute }) =>
  api.patch(`api/projects/${project}/products/${product}/attributes/${attributeId}/`, {
    ...updatedAttribute,
  });

export default {
  createAttribute,
  deleteAttribute,
  fetchAttribute,
  listAttributes,
  updateAttribute,
};
