import { createSelector } from 'reselect';

const getState = state => state.notifications;

const getNotifications = createSelector([getState], state => state.data);

const getNotificationsList = createSelector([getNotifications], state => state.list);

const getUnreadNotificationsCount = createSelector([getNotifications], state => state.unreadCount);

const getTotalNotificationsCount = createSelector([getNotifications], state => state.totalsCount);

export default {
  getNotifications,
  getNotificationsList,
  getUnreadNotificationsCount,
  getTotalNotificationsCount,
};
