import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Panel } from 'components/elements';
import { H1, P } from 'components/fontSystem';
import Tabs from '.';

const TabsHeader = ({
  title = null,
  tagline = null,
  handleDownload = null,
  buttonName = null,
  tabBarExtraContent = null,
  defaultActiveKey = null,
  activeKey = null,
  ...rest
}) => {
  const renderTabBar = (tabsProps, DefaultTabBar) => (
    <StyledPanel {...rest} className="panel">
      {title && <H1>{title}</H1>}
      {tagline && <P>{tagline}</P>}
      {handleDownload && (
        <StyledButton
          icon={<DownloadOutlined />}
          onClick={() => handleDownload()}
          type="primary"
          size="small"
          border="1px solid #FFF"
        >
          {buttonName}
        </StyledButton>
      )}
      <DefaultTabBar {...tabsProps} />
    </StyledPanel>
  );

  return (
    <Tabs
      renderTabBar={renderTabBar}
      tabBarExtraContent={tabBarExtraContent}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      {...rest}
    />
  );
};

TabsHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  handleDownload: PropTypes.func,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  tagline: PropTypes.string,
  defaultActiveKey: PropTypes.string,
  activeKey: PropTypes.string,
  tabBarExtraContent: PropTypes.node,
};

const StyledButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledPanel = styled(Panel)`
  &&& {
    color: white;
    background-color: ${({ theme }) => theme.primaryColor};
  }
  padding-bottom: 0;

  &&& > * {
    color: white;
  }

  .ant-tabs-nav {
    border-color: transparent;
    margin-bottom: 0;
  }

  .ant-tabs-tab-btn {
    color: white;
    padding-inline: 0;

    &:hover {
      color: white;
    }
  }

  &&& .ant-tabs-ink-bar {
    background-color: ${({ theme }) => theme.secondaryColor};
    height: 5px;
  }

  .anticon {
    color: white;
  }
`;

TabsHeader.TabPane = Tabs.TabPane;

export default TabsHeader;
