import api from '../api';

const createReason = ({ ...reason }) => api.post('api/reasons/', { ...reason });
const deleteReason = ({ id }) => api.delete(`api/reasons/${id}/`);
const fetchReason = ({ id }) => api.get(`api/reasons/${id}/`);
const listReasons = params => api.get('api/reasons/', { params });
const updateReason = ({ id, ...reason }) => api.patch(`api/reasons/${id}/`, { ...reason });

export default {
  createReason,
  deleteReason,
  fetchReason,
  listReasons,
  updateReason,
};
