const PARTNER_CLEAR_ERROR = 'bfb/PARTNER/PARTNER_CLEAR_ERROR';
const PARTNERS_LIST = 'bfb/PARTNER/PARTNERS_LIST';
const PARTNERS_LIST_SUCCEEDED = 'bfb/PARTNER/PARTNERS_LIST_SUCCEEDED';
const PARTNERS_LIST_FAILED = 'bfb/PARTNER/PARTNERS_LIST_FAILED';
const PARTNER_CREATE = 'bfb/PARTNER/PARTNER_CREATE';
const PARTNER_CREATE_SUCCEEDED = 'bfb/PARTNER/PARTNER_CREATE_SUCCEEDED';
const PARTNER_CREATE_FAILED = 'bfb/PARTNER/PARTNER_CREATE_FAILED';
const PARTNER_DELETE = 'bfb/PARTNER/PARTNER_DELETE';
const PARTNER_DELETE_SUCCEEDED = 'bfb/PARTNER/PARTNER_DELETE_SUCCEEDED';
const PARTNER_DELETE_FAILED = 'bfb/PARTNER/PARTNER_DELETE_FAILED';
const PARTNER_FETCH = 'bfb/PARTNER/PARTNER_FETCH';
const PARTNER_FETCH_SUCCEEDED = 'bfb/PARTNER/PARTNER_FETCH_SUCCEEDED';
const PARTNER_FETCH_FAILED = 'bfb/PARTNER/PARTNER_FETCH_FAILED';
const PARTNER_UPDATE = 'bfb/PARTNER/PARTNER_UPDATE';
const PARTNER_UPDATE_SUCCEEDED = 'bfb/PARTNER/PARTNER_UPDATE_SUCCEEDED';
const PARTNER_UPDATE_FAILED = 'bfb/PARTNER/PARTNER_UPDATE_FAILED';

export default {
  PARTNERS_LIST,
  PARTNERS_LIST_SUCCEEDED,
  PARTNERS_LIST_FAILED,
  PARTNER_CLEAR_ERROR,
  PARTNER_CREATE,
  PARTNER_CREATE_SUCCEEDED,
  PARTNER_CREATE_FAILED,
  PARTNER_DELETE,
  PARTNER_DELETE_SUCCEEDED,
  PARTNER_DELETE_FAILED,
  PARTNER_FETCH,
  PARTNER_FETCH_SUCCEEDED,
  PARTNER_FETCH_FAILED,
  PARTNER_UPDATE,
  PARTNER_UPDATE_SUCCEEDED,
  PARTNER_UPDATE_FAILED,
};
