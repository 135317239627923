import { Record } from 'immutable';
import ContentType from '../ContentType';

const PainRecord = new Record({
  id: null,
  contentType: ContentType.PAIN,
  name: '',
  segment: null,
  comments: null,
  selected: false,
  created: null,
  updated: null,
});

class Pain extends PainRecord {
  static contentType = ContentType.PAIN;
  get id() {
    return this.get('id');
  }
}

export default Pain;
