import { createSelector } from 'reselect';

const getState = state => state.projectSdg;

const getRawProjectSdgs = createSelector([getState], state => state.get('data'));

const getProjectSdgsById = createSelector([getRawProjectSdgs], state => state.get('byId'));

const getProjectSdgOfId = createSelector([getProjectSdgsById], state => id => state.toJS()[id]);

const getProjectSdgs = createSelector([getProjectSdgsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getProjectSdgs,
  getProjectSdgsById,
  getProjectSdgOfId,
  getErrorMessage,
};
