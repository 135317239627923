import { createSelector } from 'reselect';

const getState = state => state.mentor;

const getMentor = createSelector([getState], state => state.get('data'));

const updatePending = createSelector([getState], state => state.get('updatePending'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

const getPublicMentorSelected = createSelector([getState], state => state.get('mentorSelected'));

const getPublicMentor = createSelector([getPublicMentorSelected], state =>
  state.get('data').toJS()
);

const getPublicMentorLoading = createSelector([getPublicMentorSelected], state =>
  state.get('loading')
);

export default {
  getMentor,
  updatePending,
  isFetching,
  getPublicMentor,
  getPublicMentorLoading,
};
