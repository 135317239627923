import * as reduxHelper from 'store/utils/reduxHelper';
import Notification from 'models/Notifications';
import { byCreatedDescent } from 'store/utils/sortFunctions';
import types from './types';

const formatNotification = not => ({
  ...not.notification,
  ...not,
  user: not.notification.user,
});

const updateUnreadCount = (oldNotification, newNotification, count) => {
  let delta = 0;

  if (!oldNotification.seen_at && Boolean(newNotification.seen_at)) {
    // From unread to read
    delta = -1;
  } else if (Boolean(oldNotification.seen_at) && !newNotification.seen_at) {
    // from read to unread
    delta = 1;
  }
  return count + delta;
};

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { list: [], unreadCount: 0, totalsCount: 0 },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.NOTIFICATIONS_LIST:
    case types.NOTIFICATION_UPDATE:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.NOTIFICATIONS_LIST_SUCCEEDED: {
      const initialList = action.payload.clear ? [] : state.data.list;
      const initialUnreadCount = state.data.unreadCount;
      // If the request is to get unread count, we don't update the stored list
      const notificationsList = action.payload.unreadCount
        ? initialList
        : initialList
            .concat(action.payload.results.map(not => new Notification(formatNotification(not))))
            .sort(byCreatedDescent);
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            totalsCount: action.payload.count,
            unreadCount: action.payload.unreadCount ? action.payload.count : initialUnreadCount,
            list: notificationsList,
          },
        })
      );
    }
    case types.NOTIFICATION_UPDATE_SUCCEEDED: {
      const notificiationId = action.payload.id;
      const { list, unreadCount: initialUnreadCount } = state.data;
      const oldNotification = list.find(notification => notification.id === action.payload.id);

      const notificationsList = list.filter(notification => notification.id !== notificiationId);
      const unreadCount = updateUnreadCount(oldNotification, action.payload, initialUnreadCount);
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            unreadCount,
            list: [...notificationsList, new Notification(formatNotification(action.payload))].sort(
              byCreatedDescent
            ),
          },
        })
      );
    }
    case types.NOTIFICATIONS_LIST_FAILED:
    case types.NOTIFICATION_UPDATE_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
