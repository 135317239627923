import types from './types';

const clearActivities = () => ({
  type: types.ACTIVITIES_CLEAR,
});

const clearError = () => ({
  type: types.ACTIVITY_CLEAR_ERROR,
});

const listActivities = ({ productId, ...query }) => ({
  type: types.ACTIVITIES_LIST,
  payload: { productId, ...query },
});

const listActivitiesSucceeded = payload => ({
  type: types.ACTIVITIES_LIST_SUCCEEDED,
  payload,
});

const listActivitiesFailed = ({ message }) => ({
  type: types.ACTIVITIES_LIST_FAILED,
  payload: { message },
});

const createActivity = ({ segment, ...activity }, callback) => ({
  type: types.ACTIVITY_CREATE,
  payload: { segment, ...activity },
  callback,
});

const createActivitySucceeded = ({ segment, ...activity }) => ({
  type: types.ACTIVITY_CREATE_SUCCEEDED,
  payload: { segment, ...activity },
});

const createActivityFailed = ({ message }) => ({
  type: types.ACTIVITY_CREATE_FAILED,
  payload: { message },
});

const fetchActivity = ({ segment, id, ...activity }) => ({
  type: types.ACTIVITY_FETCH,
  payload: { segment, id, ...activity },
});

const fetchActivitySucceeded = ({ segment, ...activity }) => ({
  type: types.ACTIVITY_FETCH_SUCCEEDED,
  payload: { segment, ...activity },
});

const fetchActivityFailed = ({ message }) => ({
  type: types.ACTIVITY_FETCH_FAILED,
  payload: { message },
});

const updateActivity = ({ segment, id, ...activity }, callback) => ({
  type: types.ACTIVITY_UPDATE,
  payload: { segment, id, ...activity },
  callback,
});

const updateActivitySucceeded = ({ segment, ...activity }) => ({
  type: types.ACTIVITY_UPDATE_SUCCEEDED,
  payload: { segment, ...activity },
});

const updateActivityFailed = ({ message }) => ({
  type: types.ACTIVITY_UPDATE_FAILED,
  payload: { message },
});

const deleteActivity = ({ segment, id }, callback) => ({
  type: types.ACTIVITY_DELETE,
  payload: { segment, id },
  callback,
});

const deleteActivitySucceeded = ({ segment, id }) => ({
  type: types.ACTIVITY_DELETE_SUCCEEDED,
  payload: { segment, id },
});

const deleteActivityFailed = ({ message }) => ({
  type: types.ACTIVITY_DELETE_FAILED,
  payload: { message },
});

export default {
  clearActivities,
  clearError,
  createActivity,
  createActivityFailed,
  createActivitySucceeded,
  listActivities,
  listActivitiesFailed,
  listActivitiesSucceeded,
  fetchActivity,
  fetchActivityFailed,
  fetchActivitySucceeded,
  updateActivity,
  updateActivityFailed,
  updateActivitySucceeded,
  deleteActivity,
  deleteActivityFailed,
  deleteActivitySucceeded,
};
