import api from '../api';

const fetchObjective = ({ id }) => api.get(`/api/objectives/${id}`);

const createObjective = objective => api.post('/api/objectives/', objective);

const deleteObjective = ({ id }) => api.delete(`/api/objectives/${id}`);

const listObjectives = query => api.get('/api/objectives/', { params: query });

const updateObjective = ({ id, ...objective }) => api.patch(`/api/objectives/${id}/`, objective);

export default {
  createObjective,
  deleteObjective,
  fetchObjective,
  listObjectives,
  updateObjective,
};
