import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Comment from 'models/Comment';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      contentObject: null,
      comments: new List(),
      showDrawer: false,
    },
    errorMessage: '',
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.COMMENT_LIST:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.COMMENT_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: state
            .get('data')
            .set('comments', fromJS(action.payload.map(comment => new Comment(comment)))),
        })
      );
    case types.COMMENT_CREATE_SUCCEEDED:
      return (() => {
        const comments = state
          .get('data')
          .get('comments', new List())
          .filter(({ id }) => id !== action.payload.id)
          .push(new Comment(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyReceiveInfo({
            data: state.get('data').set('comments', comments),
          })
        );
      })();
    case types.COMMENT_DELETE_SUCCEEDED:
      return (() => {
        const comments = state
          .get('data')
          .get('comments')
          .filter(stateComment => stateComment.id !== action.payload.id)
          .sort(byId);
        return state.merge(
          reduxHelper.applyReceiveInfo({
            data: state.get('data').set('comments', comments),
          })
        );
      })();
    case types.COMMENT_EDIT_SUCCEEDED:
      return (() => {
        const comments = state
          .get('data')
          .get('comments')
          .filter(stateComment => stateComment.id !== action.payload.id)
          .push(new Comment(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyReceiveInfo({
            data: state.get('data').set('comments', comments),
          })
        );
      })();
    case types.COMMENT_SELECT_CONTENT_OBJECT:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            contentObject: action.payload,
            comments: new List(), // Clean comments when object changes
          },
        })
      );
    case types.COMMENT_TOGGLE_SHOW:
      return (() => {
        const showDrawer = action.payload
          ? action.payload.show
          : !state.get('data').get('showDrawer');
        return state.merge(
          reduxHelper.applyReceiveInfo({
            data: state.get('data').set('showDrawer', showDrawer),
          })
        );
      })();
    case types.COMMENT_LIST_FAILED:
    case types.COMMENT_CREATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
