// Note: This file is deprecated
// For /api/interestships/ use apiSlices/interestships instead.

import api from '../api';

const createInterestship = ({ project, mentor, interest, availability, potential_match_level }) => {
  api.post('/api/interestships/', {
    project,
    mentor,
    interest,
    availability,
    potential_match_level,
  });
};

const createInvite = ({
  program,
  project,
  mentor,
  interest,
  availability,
  potentialMatchLevel,
  reassign = false,
}) =>
  api.post('/api/management-panel/invites/', {
    program,
    project,
    mentor,
    interest,
    availability,
    potentialMatchLevel,
    reassign,
  });

const listMPInterestships = (query = {}) => {
  const params = { limit: query.limit || 20, ...query };
  return api.get('/api/management-panel/invites/', { params });
};

const updateMPInterestships = (query = {}) => {
  const { id, program, params } = query;
  return api.patch(`/api/management-panel/invites/${id}/?program=${program}`, { ...params });
};

const deleteInterestship = ({ id }) => api.delete(`/api/interestships/${id}/`);

const fetchInterestship = ({ id }) => api.get(`/api/interestships/${id}/`);

const listInterestships = ({ program, ...query }) =>
  api.get('/api/interestships/', { params: { program, ...query } });

const updateInterestship = ({ id, ...updatedInterestship }) =>
  api.patch(`/api/interestships/${id}/`, { ...updatedInterestship });

export default {
  createInterestship,
  deleteInterestship,
  fetchInterestship,
  listInterestships,
  listMPInterestships,
  updateInterestship,
  createInvite,
  updateMPInterestships,
};
