import React from 'react';
import PropTypes from 'prop-types';
import { Input as DefaultInput } from 'components/elements';
import styled from 'styled-components';
import InputStyle from './common';

const Input = props => {
  const { $semibold, noborder, usebackground, ...rest } = props;
  return <DefaultInput {...rest} />;
};

Input.propTypes = {
  noborder: PropTypes.bool,
  $semibold: PropTypes.bool,
  usebackground: PropTypes.bool,
};

Input.defaultProps = {
  noborder: true,
  $semibold: false,
  usebackground: true,
};

const StyledInput = styled(Input)`
  &&& {
    ${InputStyle}
  }
`;

export default StyledInput;
