import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Funding from 'models/FinancialProjections/Funding';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byScenario: {},
      totalByScenario: {},
      totalSecuredByScenario: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

const sumAssetTotals = (prev, funding) => prev + parseFloat(funding.value);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FUNDINGS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, funding) =>
                prevData.set(funding.id.toString(), new Funding(funding)).sort(byId),
              state.get('data').get('byId')
            ),
            byScenario: state
              .get('data')
              .get('byScenario')
              .set(
                action.payload.scenario.toString(),
                fromJS(action.payload.results.map(funding => new Funding(funding)).sort(byId))
              ),
            totalByScenario: state
              .get('data')
              .get('totalByScenario')
              .set(
                action.payload.scenario.toString(),
                action.payload.results.reduce(sumAssetTotals, 0)
              ),
            totalSecuredByScenario: state
              .get('data')
              .get('totalSecuredByScenario')
              .set(
                action.payload.scenario.toString(),
                fromJS(
                  action.payload.results
                    .filter(funding => funding.secured)
                    .reduce(sumAssetTotals, 0)
                )
              ),
          },
        })
      );
    case types.FUNDING_FETCH_SUCCEEDED:
    case types.FUNDING_CREATE_SUCCEEDED:
    case types.FUNDING_UPDATE_SUCCEEDED:
      return (() => {
        const scenarioId = action.payload.scenario.toString();
        const scenarioFundings = state
          .get('data')
          .get('byScenario')
          .get(scenarioId, new List())
          .filter(funding => funding.id !== action.payload.id)
          .push(new Funding(action.payload))
          .sort(byId);
        const scenarioTotal = scenarioFundings.reduce(sumAssetTotals, 0);
        const secenarioSecuredTotal = scenarioFundings
          .filter(funding => funding.secured)
          .reduce(sumAssetTotals, 0);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Funding(action.payload)),
              byScenario: state.get('data').get('byScenario').set(scenarioId, scenarioFundings),
              totalByScenario: state
                .get('data')
                .get('totalByScenario')
                .set(scenarioId, scenarioTotal),
              totalSecuredByScenario: state
                .get('data')
                .get('totalSecuredByScenario')
                .set(scenarioId, secenarioSecuredTotal),
            },
          })
        );
      })();
    case types.FUNDING_FETCH:
    case types.FUNDING_CREATE:
    case types.FUNDING_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.FUNDING_DELETE_SUCCEEDED:
      return (() => {
        const scenarioId = action.payload.scenario.toString();
        const scenarioFundings = state
          .get('data')
          .get('byScenario')
          .get(scenarioId, new List())
          .filter(funding => funding.id !== action.payload.id)
          .sort(byId);
        const scenarioTotal = scenarioFundings.reduce(sumAssetTotals, 0);
        const secenarioSecuredTotal = scenarioFundings
          .filter(funding => funding.secured)
          .reduce(sumAssetTotals, 0);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state.get('data').get('byId').delete(action.payload.id.toString()),
              byScenario: state.get('data').get('byScenario').set(scenarioId, scenarioFundings),
              totalByScenario: state
                .get('data')
                .get('totalByScenario')
                .set(scenarioId, scenarioTotal),
              totalSecuredByScenario: state
                .get('data')
                .get('totalSecuredByScenario')
                .set(scenarioId, secenarioSecuredTotal),
            },
          })
        );
      })();
    case types.FUNDING_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.FUNDING_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.FUNDING_FETCH_FAILED:
    case types.FUNDINGS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
