import { createSelector } from 'reselect';

const getState = state => state.surveys;

const getSurveys = createSelector([getState], state => state.data);

const getSurveysList = createSelector([getSurveys], state => state.list);

export default {
  getSurveys,
  getSurveysList,
};
