// Polyfills
import 'polyfills/performance.now-polyfill.js';
import 'polyfills/requestAnimationFrame-polyfill.js';

// core-js polyfills
import 'core-js/actual/string/replace-all';

// Libraries
import React from 'react';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// Components
import App from 'containers/App';

// Dependencies
import store from 'store/store';

// Mixpanel setup
const TESTING_MIXPANEL = '1e95f0763403422ce489e515953f0cd1';
const PRODUCTION_MIXPANEL = '33a3f6524d997236772624270e642baf';
if (window.env === 'dev') {
  mixpanel.init(TESTING_MIXPANEL);
}
if (window.env === 'production') {
  mixpanel.init(PRODUCTION_MIXPANEL);

  // Sentry Replay
  Sentry.init({
    dsn: 'https://e0e474a0fab14f919147bc25cd32429a@o38629.ingest.us.sentry.io/1341698',
    integrations: [
      Sentry.replayIntegration({
        // Allows us to see request details in Replay
        networkDetailAllowUrls: [window.location.origin],
        maskAllText: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%
    replaysOnErrorSampleRate: 0.3, // 30% of errors will be sampled
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
