// Libraries
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Carousel as AntCarousel } from 'antd';
import { Button } from 'components/elements';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const Carousel = props => {
  const carousel = React.useRef();
  const arrowLeft = () => carousel.current.prev();
  const arrowRight = () => carousel.current.next();
  return (
    <StyledDiv>
      {!props.disableArrows && <Button icon={<LeftOutlined />} size="small" onClick={arrowLeft} />}
      <AntCarousel ref={carousel} {...props} />
      {!props.disableArrows && (
        <Button icon={<RightOutlined />} size="small" onClick={arrowRight} />
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  &&& .ant-btn {
    margin-left: 0;
    padding: 0;
    width: auto;
  }
  .ant-carousel {
    max-width: 88%;
    margin-bottom: 10px;
  }
  .slick-dots li button {
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.primaryColorFade};
    border-radius: 100%;
  }
  .slick-dots li.slick-active button {
    width: 7px;
    background-color: ${({ theme }) => theme.primaryColor};
  }
  .slick-dots-bottom {
    bottom: -7px;
  }
  @media screen and (min-width: ${({ theme }) => theme.screenMdMin}) {
    .ant-carousel {
      max-width: 92%;
    }
  }
`;

Carousel.propTypes = {
  disableArrows: PropTypes.bool,
};

Carousel.defaultProps = {
  disableArrows: false,
};

export default Carousel;
