const ORGANIZATIONAL_ROLE_CLEAR_ERROR = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_CLEAR_ERROR';
const ORGANIZATIONAL_ROLES_LIST = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLES_LIST';
const ORGANIZATIONAL_ROLES_LIST_SUCCEEDED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLES_LIST_SUCCEEDED';
const ORGANIZATIONAL_ROLES_LIST_FAILED = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLES_LIST_FAILED';
const ORGANIZATIONAL_ROLE_CREATE = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_CREATE';
const ORGANIZATIONAL_ROLE_CREATE_SUCCEEDED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_CREATE_SUCCEEDED';
const ORGANIZATIONAL_ROLE_CREATE_FAILED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_CREATE_FAILED';
const ORGANIZATIONAL_ROLE_DELETE = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_DELETE';
const ORGANIZATIONAL_ROLE_DELETE_SUCCEEDED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_DELETE_SUCCEEDED';
const ORGANIZATIONAL_ROLE_DELETE_FAILED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_DELETE_FAILED';
const ORGANIZATIONAL_ROLE_FETCH = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_FETCH';
const ORGANIZATIONAL_ROLE_FETCH_SUCCEEDED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_FETCH_SUCCEEDED';
const ORGANIZATIONAL_ROLE_FETCH_FAILED = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_FETCH_FAILED';
const ORGANIZATIONAL_ROLE_UPDATE = 'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_UPDATE';
const ORGANIZATIONAL_ROLE_UPDATE_SUCCEEDED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_UPDATE_SUCCEEDED';
const ORGANIZATIONAL_ROLE_UPDATE_FAILED =
  'bfb/ORGANIZATIONAL_ROLE/ORGANIZATIONAL_ROLE_UPDATE_FAILED';

export default {
  ORGANIZATIONAL_ROLES_LIST,
  ORGANIZATIONAL_ROLES_LIST_SUCCEEDED,
  ORGANIZATIONAL_ROLES_LIST_FAILED,
  ORGANIZATIONAL_ROLE_CLEAR_ERROR,
  ORGANIZATIONAL_ROLE_CREATE,
  ORGANIZATIONAL_ROLE_CREATE_SUCCEEDED,
  ORGANIZATIONAL_ROLE_CREATE_FAILED,
  ORGANIZATIONAL_ROLE_DELETE,
  ORGANIZATIONAL_ROLE_DELETE_SUCCEEDED,
  ORGANIZATIONAL_ROLE_DELETE_FAILED,
  ORGANIZATIONAL_ROLE_FETCH,
  ORGANIZATIONAL_ROLE_FETCH_SUCCEEDED,
  ORGANIZATIONAL_ROLE_FETCH_FAILED,
  ORGANIZATIONAL_ROLE_UPDATE,
  ORGANIZATIONAL_ROLE_UPDATE_SUCCEEDED,
  ORGANIZATIONAL_ROLE_UPDATE_FAILED,
};
