import types from './types';

const clearError = () => ({
  type: types.RETENTION_ACTIVITY_CLEAR_ERROR,
});

const listRetentionActivities = () => ({
  type: types.RETENTION_ACTIVITIES_LIST,
});

const listRetentionActivitiesSucceeded = payload => ({
  type: types.RETENTION_ACTIVITIES_LIST_SUCCEEDED,
  payload,
});

const listRetentionActivitiesFailed = ({ message }) => ({
  type: types.RETENTION_ACTIVITIES_LIST_FAILED,
  payload: { message },
});

const createRetentionActivity = ({ ...retentionActivity }) => ({
  type: types.RETENTION_ACTIVITY_CREATE,
  payload: { ...retentionActivity },
});

const createRetentionActivitySucceeded = ({ ...retentionActivity }) => ({
  type: types.RETENTION_ACTIVITY_CREATE_SUCCEEDED,
  payload: { ...retentionActivity },
});

const createRetentionActivityFailed = ({ message }) => ({
  type: types.RETENTION_ACTIVITY_CREATE_FAILED,
  payload: { message },
});

const updateRetentionActivity = ({ ...retentionActivity }) => ({
  type: types.RETENTION_ACTIVITY_UPDATE,
  payload: { ...retentionActivity },
});

const updateRetentionActivitySucceeded = ({ ...retentionActivity }) => ({
  type: types.RETENTION_ACTIVITY_UPDATE_SUCCEEDED,
  payload: { ...retentionActivity },
});

const updateRetentionActivityFailed = ({ message }) => ({
  type: types.RETENTION_ACTIVITY_UPDATE_FAILED,
  payload: { message },
});

const deleteRetentionActivity = retentionActivityId => ({
  type: types.RETENTION_ACTIVITY_DELETE,
  payload: { retentionActivityId },
});

const deleteRetentionActivitySucceeded = ({ retentionActivityId }) => ({
  type: types.RETENTION_ACTIVITY_DELETE_SUCCEEDED,
  payload: { id: retentionActivityId },
});

const deleteRetentionActivityFailed = ({ message }) => ({
  type: types.RETENTION_ACTIVITY_DELETE_FAILED,
  payload: message,
});

export default {
  clearError,
  listRetentionActivities,
  listRetentionActivitiesFailed,
  listRetentionActivitiesSucceeded,
  createRetentionActivity,
  createRetentionActivityFailed,
  createRetentionActivitySucceeded,
  updateRetentionActivity,
  updateRetentionActivityFailed,
  updateRetentionActivitySucceeded,
  deleteRetentionActivity,
  deleteRetentionActivitySucceeded,
  deleteRetentionActivityFailed,
};
