import api from './api';

const listDeepDiveLinks = payload => api.get('/api/material-links/', { params: payload });
const createDeepDiveLink = link =>
  api.post('/api/material-links/', {
    ...link,
  });
const updateDeepDiveLink = link => {
  const uploadingFile = Object.entries(link).find(
    ([key, value]) => key.startsWith('file') && value
  );
  let formData;
  if (uploadingFile) {
    formData = new FormData();
    Object.entries(link).map(([key, value]) => formData.append(key, value));
  }
  const headers = uploadingFile && {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return api.patch(`/api/material-links/${link.id}/`, formData || link, headers);
};
const deleteDeepDiveLink = link => api.delete(`/api/material-links/${link.id}/`);

export default {
  listDeepDiveLinks,
  updateDeepDiveLink,
  createDeepDiveLink,
  deleteDeepDiveLink,
};
