import { createSelector } from 'reselect';

const getState = state => state.system;

const getRawSystems = createSelector([getState], state => state.get('data'));

const getSystemsById = createSelector([getRawSystems], state => state.get('byId'));

const getSystems = createSelector([getSystemsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getSystems,
  getSystemsById,
  getErrorMessage,
};
