import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions, types } from 'store/modules/ImpactPlan/system';
import { types as commentTypes } from 'store/modules/comment';
import serviceSystem from 'services/ImpactPlan/system';
import System from 'models/ImpactPlan/System';

export function* createSystem(action) {
  const { ...system } = action.payload;
  try {
    const response = yield call(serviceSystem.createSystem, { ...system });
    yield put(actions.createSystemSucceeded(response.data));
  } catch (e) {
    yield put(
      actions.createSystemFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteSystem(action) {
  const { id } = action.payload;
  try {
    yield call(serviceSystem.deleteSystem, { id });
    yield put(actions.deleteSystemSucceeded({ id }));
  } catch (e) {
    yield put(
      actions.deleteSystemFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchSystem(action) {
  const { id } = action.payload;
  try {
    const system = yield call(serviceSystem.fetchSystem, { id });
    yield put(actions.fetchSystemSucceeded(system.data));
  } catch (e) {
    yield put(actions.fetchSystemFailed({ message: e.message }));
  }
}

export function* listSystems(action) {
  const { project } = action.payload;
  try {
    const systems = yield call(serviceSystem.listSystems, { project });
    yield put(actions.listSystemsSucceeded({ results: systems.data }));
  } catch (e) {
    yield put(actions.listSystemsFailed({ message: e.message }));
  }
}

export function* updateSystem(action) {
  const { id, ...system } = action.payload;
  try {
    const updated = yield call(serviceSystem.updateSystem, { id, ...system });
    yield put(actions.updateSystemSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateSystemFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === System.contentType) {
    yield put(
      actions.fetchSystem({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(types.SYSTEM_CREATE, createSystem),
    takeLatest(types.SYSTEM_DELETE, deleteSystem),
    takeLatest(types.SYSTEM_FETCH, fetchSystem),
    takeLatest(types.SYSTEM_UPDATE, updateSystem),
    takeLatest(types.SYSTEMS_LIST, listSystems),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default authSaga;
