import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Member from 'models/Member';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: new Member(),
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MEMBER_FETCH_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: new Member(action.payload),
          updatePending: false,
        })
      );
    case types.MEMBER_FETCH_FAILED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          errorMessage: action.payload,
        })
      );
    case types.MEMBER_EDIT:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.MEMBER_EDIT_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: new Member(action.payload),
        })
      );
    case types.MEMBER_EDIT_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
