import { call, put, all, takeEvery } from 'redux-saga/effects';
import serviceSdg from 'services/ImpactPlan/sdg';
import types from './types';
import actions from './actions';

export function* listSdg() {
  try {
    const response = yield call(serviceSdg.listSdg);
    yield put(actions.listSdgsSucceeded({ results: response.data }));
  } catch (e) {
    yield put(actions.listSdgsFailed({ message: e.message }));
  }
}

function* saga() {
  yield all([takeEvery(types.SDG_LIST, listSdg)]);
}

export default saga;
