import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: {},
    loading: false,
    errorMessage: '',
    updatePending: false,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MEMBER_INVITES:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo({ updatePending: true }));
    case types.FETCH_MEMBER_INVITES_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            ...state.data,
            ...action.payload,
          },
          loading: false,
          updatePending: false,
        })
      );
    case types.FETCH_MEMBER_INVITES_PENDING:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          loading: true,
        })
      );
    case types.FETCH_MEMBER_INVITES_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          errorMessage: 'It failed to get the Invites info',
          loading: false,
          updatePending: false,
        })
      );
    default:
      return state;
  }
}
