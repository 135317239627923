import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Attribute from 'models/CompetitionMap/Attribute';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byProduct: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ATTRIBUTES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, attribute) =>
                prevData.set(attribute.id.toString(), new Attribute(attribute)).sort(byId),
              state.get('data').get('byId')
            ),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                action.payload.product.toString(),
                fromJS(action.payload.results.map(attribute => new Attribute(attribute)).sort(byId))
              ),
          },
        })
      );
    case types.ATTRIBUTE_FETCH_SUCCEEDED:
    case types.ATTRIBUTE_CREATE_SUCCEEDED:
    case types.ATTRIBUTE_UPDATE_SUCCEEDED:
      return (() => {
        const productId = action.payload.product.toString();
        const productAttributes = state
          .get('data')
          .get('byProduct')
          .get(productId, new List())
          .filter(attribute => attribute.id !== action.payload.id)
          .push(new Attribute(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Attribute(action.payload)),
              byProduct: state.get('data').get('byProduct').set(productId, productAttributes),
            },
          })
        );
      })();
    case types.ATTRIBUTE_FETCH:
    case types.ATTRIBUTE_CREATE:
    case types.ATTRIBUTE_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.ATTRIBUTE_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProduct: {
              ...state.get('data').get('byProduct').toJS(),
              [action.payload.product]: state
                .get('data')
                .get('byProduct')
                .get(action.payload.product.toString(), new List())
                .filter(attribute => attribute.id !== action.payload.id),
            },
          },
        })
      );
    case types.ATTRIBUTE_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.ATTRIBUTE_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.ATTRIBUTE_FETCH_FAILED:
    case types.ATTRIBUTES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
