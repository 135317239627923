const EDUCATION_EXPERIENCE_REQUEST = 'bfb/educationExperience/EDUCATION_EXPERIENCE_REQUEST';
const EDUCATION_EXPERIENCE_SUCCEEDED = 'bfb/educationExperience/EDUCATION_EXPERIENCE_SUCCEEDED';
const EDUCATION_EXPERIENCE_FAILED = 'bfb/educationExperience/EDUCATION_EXPERIENCE_FAILED';
const EDUCATION_EXPERIENCE_CREATE = 'bfb/educationExperience/EDUCATION_EXPERIENCE_CREATE';
const EDUCATION_EXPERIENCE_CREATE_SUCCEEDED =
  'bfb/educationExperience/EDUCATION_EXPERIENCE_CREATE_SUCCEEDED';
const EDUCATION_EXPERIENCE_CREATE_FAILED =
  'bfb/educationExperience/EDUCATION_EXPERIENCE_CREATE_FAILED';
const EDUCATION_EXPERIENCE_UPDATE = 'bfb/educationExperience/EDUCATION_EXPERIENCE_UPDATE';
const EDUCATION_EXPERIENCE_UPDATE_SUCCEEDED =
  'bfb/educationExperience/EDUCATION_EXPERIENCE_UPDATE_SUCCEEDED';
const EDUCATION_EXPERIENCE_UPDATE_FAILED =
  'bfb/educationExperience/EDUCATION_EXPERIENCE_UPDATE_FAILED';
const EDUCATION_EXPERIENCE_DELETE = 'bfb/educationExperience/EDUCATION_EXPERIENCE_DELETE';
const EDUCATION_EXPERIENCE_DELETE_SUCCEEDED =
  'bfb/educationExperience/EDUCATION_EXPERIENCE_DELETE_SUCCEEDED';
const EDUCATION_EXPERIENCE_DELETE_FAILED =
  'bfb/educationExperience/EDUCATION_EXPERIENCE_DELETE_FAILED';

export default {
  EDUCATION_EXPERIENCE_REQUEST,
  EDUCATION_EXPERIENCE_SUCCEEDED,
  EDUCATION_EXPERIENCE_FAILED,
  EDUCATION_EXPERIENCE_CREATE,
  EDUCATION_EXPERIENCE_CREATE_SUCCEEDED,
  EDUCATION_EXPERIENCE_CREATE_FAILED,
  EDUCATION_EXPERIENCE_UPDATE,
  EDUCATION_EXPERIENCE_UPDATE_SUCCEEDED,
  EDUCATION_EXPERIENCE_UPDATE_FAILED,
  EDUCATION_EXPERIENCE_DELETE,
  EDUCATION_EXPERIENCE_DELETE_SUCCEEDED,
  EDUCATION_EXPERIENCE_DELETE_FAILED,
};
