import { Record } from 'immutable';
import ContentType from '../ContentType';

const AttributeRecord = new Record({
  id: null,
  contentType: ContentType.ATTRIBUTE,
  name: '',
  low: '',
  mid: '',
  high: '',
  product: null,
  comments: null,
  created: null,
  updated: null,
});

class Attribute extends AttributeRecord {
  static contentType = ContentType.ATTRIBUTE;

  get id() {
    return this.get('id');
  }
}

export default Attribute;
