// Libraries
import React from 'react';
import styled, { css } from 'styled-components';

// Components
import { Switch as RawSwitch } from 'antd';

const StyledSwitch = styled(({ color, colorIndex, ...rest }) => (
  <RawSwitch data-test="component-toggle" {...rest} />
))`
  color: red;
  ${({ theme: { lightGrey, getToolColor, primaryColor }, color, colorIndex }) => {
    // If the color index exists, fetch it. Otherwise, render background color.
    const backgroundColor =
      colorIndex || colorIndex === 0 ? getToolColor(colorIndex) : color || primaryColor;
    return css`
      &.ant-switch {
        background-color: ${lightGrey} !important;
      }
      &.ant-switch-checked {
        background-color: ${backgroundColor} !important;
      }
    `;
  }}
`;

export default StyledSwitch;
