import { Record } from 'immutable';
import ContentType from '../ContentType';

const RetentionActivityRecord = new Record({
  id: null,
  name: '',
  segment: null,
  comments: [],
  contentType: ContentType.RETENTIONACTIVITY,
});

export default RetentionActivityRecord;
