import types from './types';

const createSurveyResponse = (payload, callback) => ({
  type: types.SURVEYS_RESPONSE_CREATE,
  payload,
  callback,
});

const createSurveyResponseSucceeded = payload => ({
  type: types.SURVEYS_RESPONSE_CREATE_SUCCEEDED,
  payload,
});

const createSurveyResponseFailed = payload => ({
  type: types.SURVEYS_RESPONSE_CREATE_FAILED,
  payload,
});

export default {
  createSurveyResponse,
  createSurveyResponseSucceeded,
  createSurveyResponseFailed,
};
