import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/projects/',
  }),
  endpoints: builder => ({
    // Fetch ALL Projects
    listProjects: builder.query({
      query: params => ({ params }),
    }),
    // Get a Project by id
    getProjectById: builder.query({
      query: programId => ({ url: programId }),
    }),

    // Venture Summary
    // Get Venture Summary by Project id
    getVentureSummaryByProjectId: builder.query({
      query: projectId => ({ url: `${projectId}/venture-summary` }),
    }),
    // Update Venture Summary, "data" is equal to modified field
    updateVentureSummary: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `${projectId}/venture-summary`,
        method: 'PATCH',
        data,
      }),
    }),
    // Submit Venture Summary
    submitVentureSummary: builder.mutation({
      query: ({ projectId, ...data }) => ({
        url: `${projectId}/venture-summary`,
        method: 'POST',
        data,
      }),
    }),

    // Financial Forecast
    // Get Financial Forecast by Project id
    getProjectFinancialForecastByProjectId: builder.query({
      query: projectId => ({ url: `${projectId}/financial-forecast/` }),
    }),
    // Update Financial Forecast, "data" is equal to modified field
    updateProjectFinancialForecast: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `${projectId}/financial-forecast/`,
        method: 'PATCH',
        data,
      }),
    }),
    // Submit Financial Forecast
    submitProjectFinancialForecast: builder.mutation({
      query: ({ projectId, ...data }) => ({
        url: `${projectId}/financial-forecast/`,
        method: 'POST',
        data,
      }),
    }),
    // Upload Financial Forecast spreadsheet file
    uploadProjectFinancialForecastFile: builder.mutation({
      query: ({ projectId, financialForecastId, data }) => ({
        url: `${projectId}/financial-forecast/${financialForecastId}/`,
        headers: { 'content-type': 'multipart/form-data' },
        method: 'PATCH',
        data,
      }),
    }),

    // Financial Forecast Scenarios
    // Get scenario by id
    getProjectFinancialForecastScenariosByProjectId: builder.query({
      query: projectId => ({ url: `${projectId}/financial-forecast/scenarios/` }),
    }),
    getProjectFinancialForecastScenarioById: builder.query({
      query: ({ projectId, scenarioId }) => ({
        url: `${projectId}/financial-forecast/scenarios/${scenarioId}/`,
      }),
    }),
    // Update scenario, "data" is equal to modified field
    updateProjectFinancialForecastScenario: builder.mutation({
      query: ({ projectId, scenarioId, data }) => ({
        url: `${projectId}/financial-forecast/scenarios/${scenarioId}/`,
        method: 'PATCH',
        data,
      }),
    }),
    // Submit scenario
    submitProjectFinancialForecastScenario: builder.mutation({
      query: ({ projectId, ...data }) => ({
        url: `${projectId}/financial-forecast/scenarios/`,
        method: 'POST',
        data,
      }),
    }),
    // Upload scenario spreadsheet file
    uploadProjectFinancialForecastScenarioFile: builder.mutation({
      query: ({ projectId, scenarioId, data }) => ({
        url: `${projectId}/financial-forecast/scenarios/${scenarioId}/`,
        headers: { 'content-type': 'multipart/form-data' },
        method: 'PATCH',
        data,
      }),
    }),
    // Upload scenario file, step for validation anf notify mentor
    uploadProjectFinancialForecastScenarioAndMentorNotify: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `${projectId}/financial-forecast/scenarios/upload/`,
        headers: { 'content-type': 'multipart/form-data' },
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useListProjectsQuery,
  useGetProjectByIdQuery,
  useGetVentureSummaryByProjectIdQuery,
  useUpdateVentureSummaryMutation,
  useSubmitVentureSummaryMutation,
  useGetProjectFinancialForecastByProjectIdQuery,
  useUpdateProjectFinancialForecastMutation,
  useSubmitProjectFinancialForecastMutation,
  useUploadProjectFinancialForecastFileMutation,
  useGetProjectFinancialForecastScenariosByProjectIdQuery,
  useGetProjectFinancialForecastScenarioByIdQuery,
  useUpdateProjectFinancialForecastScenarioMutation,
  useSubmitProjectFinancialForecastScenarioMutation,
  useUploadProjectFinancialForecastScenarioFileMutation,
  useUploadProjectFinancialForecastScenarioAndMentorNotifyMutation,
} = projectsApi;
