import { Record } from 'immutable';
import {
  DISTRIBUTION_RELATED_OFFSET,
  PAYMENT_RELATED_OFFSET,
  REACHING_LOWER_COSTS_OFFSET,
  ALTERNATIVE_BUSINESS_MODELS_OFFSET,
} from 'styles/toolColors';
import ContentType from '../ContentType';

export const DISTRIBUTION_RELATED = 0;
export const PAYMENT_RELATED = 1;
export const REACHING_LOWER_COSTS = 2;
export const ALTERNATIVE_BUSINESS_MODELS = 3;
export const BUSINESS_MODEL_TYPES = [
  DISTRIBUTION_RELATED,
  PAYMENT_RELATED,
  REACHING_LOWER_COSTS,
  ALTERNATIVE_BUSINESS_MODELS,
];

export const getColorIndex = type => {
  switch (true) {
    case type === DISTRIBUTION_RELATED:
      return DISTRIBUTION_RELATED_OFFSET;
    case type === PAYMENT_RELATED:
      return PAYMENT_RELATED_OFFSET;
    case type === REACHING_LOWER_COSTS:
      return REACHING_LOWER_COSTS_OFFSET;
    case type === ALTERNATIVE_BUSINESS_MODELS:
      return ALTERNATIVE_BUSINESS_MODELS_OFFSET;
    default:
      return null;
    // Do nothing.
  }
};

const BusinessModelRecord = new Record({
  id: null,
  contentType: ContentType.BUSINESSMODEL,
  name: null,
  subtitle: null,
  description: null,
  selected: null,
  product_business_model: null,
  type: null,
  colorIndex: null,
});

class BusinessModel extends BusinessModelRecord {
  constructor(model) {
    const modelWithColorIndex = {
      ...model,
      colorIndex: getColorIndex(model.type),
    };
    super(modelWithColorIndex);
  }

  static contentType = ContentType.BUSINESSMODEL;
  get id() {
    return this.get('id');
  }
}

export default BusinessModel;
