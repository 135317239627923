// Libraries
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// Dependencies
import { CELL_ALIGNMENT } from '../dependencies';
import TableContext from '../Context/context';

// Components
import { HeaderCell } from '../styled-components';
import TableRow from '../TableRow';

const TableHeadRow = props => {
  const { headColumns, fixedLeftColumns } = props;

  const rightmostFixedColumn = fixedLeftColumns && fixedLeftColumns[fixedLeftColumns.length - 1];

  const { backgroundColor } = useContext(TableContext);

  const headRow = useMemo(
    () => (
      <TableRow className="table-head-row" rowKey="head">
        {headColumns.map(([headKey, headerCell]) => {
          const { key, head, children, align, numeric, fixed, ...headerCellProps } = headerCell;
          const isRightmost = headKey === (rightmostFixedColumn && rightmostFixedColumn[0]);
          return (
            <HeaderCell
              fixed={fixedLeftColumns ? !fixed : fixed}
              className={`table-head-cell${isRightmost ? ' rightmost-cell' : ''}${
                headKey === 'total' ? ' table-head-total-cell' : ''
              }`}
              isRightmost={isRightmost}
              key={key || headKey}
              scope="col"
              align={align || (numeric && CELL_ALIGNMENT.RIGHT)}
              backgroundColor={backgroundColor}
              {...headerCellProps}
            >
              {children}
            </HeaderCell>
          );
        })}
      </TableRow>
    ),
    [backgroundColor, fixedLeftColumns, headColumns, rightmostFixedColumn]
  );

  return headRow;
};

TableHeadRow.propTypes = {
  headColumns: PropTypes.instanceOf(Array).isRequired,
  fixedLeftColumns: PropTypes.instanceOf(Array),
};

TableHeadRow.defaultProps = {
  fixedLeftColumns: undefined,
};

export default React.memo(TableHeadRow);
