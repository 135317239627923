import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/ValueProposition/benefit';
import { types as commentTypes } from 'store/modules/comment';
import serviceBenefit from 'services/ValueProposition/benefit';
import Benefit from 'models/ValueProposition/Benefit';

export function* createBenefit(action) {
  const { project, product, segment, ...benefit } = action.payload;
  try {
    const response = yield call(serviceBenefit.createBenefit, {
      project,
      product,
      segment,
      ...benefit,
    });
    yield put(actions.createBenefitSucceeded({ ...response.data, segment }));
  } catch (e) {
    yield put(
      actions.createBenefitFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteBenefit(action) {
  const { project, product, segment, benefitId } = action.payload;
  try {
    yield call(serviceBenefit.deleteBenefit, {
      project,
      product,
      segment,
      benefitId,
    });
    yield put(actions.deleteBenefitSucceeded({ id: benefitId, segment }));
  } catch (e) {
    yield put(
      actions.deleteBenefitFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchBenefit(action) {
  const { project, product, segment, id } = action.payload;
  try {
    const response = yield call(serviceBenefit.fetchBenefit, {
      project,
      product,
      segment,
      id,
    });
    yield put(actions.fetchBenefitSucceeded({ ...response.data, segment }));
  } catch (e) {
    yield put(actions.fetchBenefitFailed({ message: e.message }));
  }
}

export function* listBenefits(action) {
  const { project, product, segment } = action.payload;
  try {
    const benefits = yield call(serviceBenefit.listBenefits, {
      project,
      product,
      segment,
    });
    yield put(actions.listBenefitsSucceeded({ results: benefits.data, segment }));
  } catch (e) {
    yield put(actions.listBenefitsFailed({ message: e.message }));
  }
}

export function* updateBenefit(action) {
  const { project, product, segment, benefitId, ...benefit } = action.payload;
  try {
    const updated = yield call(serviceBenefit.updateBenefit, {
      project,
      product,
      segment,
      benefitId,
      ...benefit,
    });
    yield put(actions.updateBenefitSucceeded({ ...updated.data, segment }));
  } catch (e) {
    yield put(
      actions.updateBenefitFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Benefit.contentType) {
    yield put(
      actions.fetchBenefit({
        project: object.get('project'),
        product: object.get('product'),
        segment: object.get('segment'),
        id: object.get('id'),
      })
    );
  }
}

function* benefitSaga() {
  yield all([
    takeLatest(types.BENEFIT_CREATE, createBenefit),
    takeLatest(types.BENEFIT_DELETE, deleteBenefit),
    takeLatest(types.BENEFIT_FETCH, fetchBenefit),
    takeLatest(types.BENEFIT_UPDATE, updateBenefit),
    takeEvery(types.BENEFITS_LIST, listBenefits),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default benefitSaga;
