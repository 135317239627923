import styled from 'styled-components';
import { Pagination } from 'antd';

const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active {
    background-color: ${({ theme }) => theme.itemActiveBg};
    border-color: ${({ theme }) => theme.secondaryColor};
    transition: all ease-in-out 0.25s;
    a {
      color: black;
    }
    &:hover {
      background-color: ${({ theme }) => theme.secondaryColor};
      border-color: ${({ theme }) => theme.secondaryColor};
      a {
        color: black;
      }
    }
  }
`;

export default StyledPagination;
