import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import serviceCompetitor from 'services/CompetitionMap/competitor';
import Competitor from 'models/CompetitionMap/Competitor';
import actions from './actions';
import types from './types';

export function* createCompetitor(action) {
  const { project, product, outcome, ...competitor } = action.payload;
  try {
    const response = yield call(serviceCompetitor.createCompetitor, {
      project,
      product,
      outcome,
      ...competitor,
    });
    yield put(actions.createCompetitorSucceeded({ ...response.data, product, outcome }));
  } catch (e) {
    yield put(
      actions.createCompetitorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteCompetitor(action) {
  const { project, product, outcome, id } = action.payload;
  try {
    yield call(serviceCompetitor.deleteCompetitor, {
      project,
      product,
      outcome,
      id,
    });
    yield put(actions.deleteCompetitorSucceeded({ id, outcome, product }));
  } catch (e) {
    yield put(
      actions.deleteCompetitorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchCompetitor(action) {
  const { project, product, outcome, id } = action.payload;
  try {
    const response = yield call(serviceCompetitor.fetchCompetitor, {
      project,
      product,
      outcome,
      id,
    });
    yield put(actions.fetchCompetitorSucceeded({ ...response.data, outcome, product }));
  } catch (e) {
    yield put(actions.fetchCompetitorFailed({ message: e.message }));
  }
}

export function* listCompetitors(action) {
  const { project, product } = action.payload;
  try {
    const competitors = yield call(serviceCompetitor.listCompetitors, {
      project,
      product,
    });
    yield put(actions.listCompetitorsSucceeded({ results: competitors.data, product }));
  } catch (e) {
    yield put(actions.listCompetitorsFailed({ message: e.message }));
  }
}

export function* updateCompetitor(action) {
  const { project, product, outcome, competitorId, ...competitor } = action.payload;
  try {
    const updated = yield call(serviceCompetitor.updateCompetitor, {
      project,
      product,
      outcome,
      competitorId,
      ...competitor,
    });
    yield put(actions.updateCompetitorSucceeded({ ...updated.data, product, outcome }));
  } catch (e) {
    yield put(
      actions.updateCompetitorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Competitor.contentType) {
    yield put(
      actions.fetchCompetitor({
        project: object.get('project'),
        product: object.get('product'),
        outcome: object.get('outcome'),
        id: object.get('id'),
      })
    );
  }
}

function* competitorSaga() {
  yield all([
    takeLatest(types.COMPETITOR_CREATE, createCompetitor),
    takeLatest(types.COMPETITOR_DELETE, deleteCompetitor),
    takeLatest(types.COMPETITOR_FETCH, fetchCompetitor),
    takeLatest(types.COMPETITOR_UPDATE, updateCompetitor),
    takeEvery(types.COMPETITORS_LIST, listCompetitors),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default competitorSaga;
