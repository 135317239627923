import { normalize, schema } from 'normalizr';

const memberSchema = new schema.Entity('member');
const mentorSchema = new schema.Entity('mentor');
const customizationSchema = new schema.Entity('customization');
const userSchema = new schema.Entity('user', {
  customization: customizationSchema,
  member: memberSchema,
  mentor: mentorSchema,
});

const normalizeUser = data => {
  // FIXME: At some point we will have to treat user model as a database
  // https://redux.js.org/recipes/structuringreducers/normalizingstateshape#relationships-and-tables
  const { result, entities } = normalize(data, userSchema);
  const user = entities.user[result];
  const member = user.member && entities.member[user.member];
  const mentor = user.mentor && entities.mentor[user.mentor];
  const customization = user.customization && entities.customization[user.customization];
  return { user, customization, member, mentor };
};

export default normalizeUser;
