// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Dependencies
import { CELL_ALIGNMENT, TOTAL_KEY } from '../dependencies';

// Components
import BodyCell from '../BodyCell';

const TotalCell = props => {
  const {
    storeTotalValuesPerRow,
    storeValuesPerColumn,
    cell,
    row,
    columnKeys,
    tableColumns,
    fixed,
    className,
  } = props;
  /**
   * - Calculates the values of the row. The cell influences the total only if
   * `shouldInfluenceTotal` is `true`. If the cells in this row are `numeric`,
   * then we simply use the `children` properties, otherwise, we fallback to the
   * `value` property.
   * - It is assumed that `value` exists if `numeric` is `false`,
   * and `shouldInfluenceTotal` is `true`
   */
  const totalValue = columnKeys.reduce((total, columnKey) => {
    // Skipping total column
    if (columnKey === TOTAL_KEY) return total;
    const respectiveCell = row[columnKey];
    if (respectiveCell && respectiveCell.shouldInfluenceTotal) {
      const value = Number.isNaN(respectiveCell.children)
        ? respectiveCell.children
        : respectiveCell.value;
      // Storing the value per column to the respective row.
      storeValuesPerColumn(columnKey, row.key, value);
      return total + value;
    }
    return total;
  }, 0);
  // Storing the value per row
  storeTotalValuesPerRow(row.key, totalValue);
  const children = cell && cell.children;
  return (
    <BodyCell
      tableColumns={tableColumns}
      head={false}
      align={CELL_ALIGNMENT.RIGHT}
      className={className ? ['table-total-cell', className].join(' ') : 'table-total-cell'}
      isNumeric
      fixed={fixed}
    >
      {typeof children === 'function' ? children(totalValue) : totalValue}
    </BodyCell>
  );
};

TotalCell.propTypes = {
  storeTotalValuesPerRow: PropTypes.func.isRequired,
  storeValuesPerColumn: PropTypes.func.isRequired,
  tableColumns: PropTypes.instanceOf(Object).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  columnKeys: PropTypes.instanceOf(Array).isRequired,
  fixed: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  cell: PropTypes.instanceOf(Object),
};

TotalCell.defaultProps = {
  fixed: undefined,
  className: undefined,
  cell: null,
};

export default React.memo(TotalCell);
