const itemToToolName = (t, toolSlug) => {
  const toolNames = {
    'value-proposition': t('Value Proposition'),
    'competition-map': t('Competition Map'),
    'stakeholder-map': t('Stakeholder Map'),
    'business-model-marketing': t('Business Model & Marketing'),
    'pricing-business-viability': t('Pricing & Business Viability'),
    'financial-projections': t('Financial Projections'),
    'impact-plan': t('Impact Plan'),
    'growth-plan': t('Growth Plan'),
  };
  return toolNames[toolSlug] || null;
};

const getSteps = t => ({
  introduction: t('Introduction'),
  summary: t('Summary'),
  // Tool 1
  'why-tree': t('Why Tree'),
  'problem-solution-fit': t('Problem-Solution Fit'),
  'my-offerings': t('My Offerings'),
  'customer-segments': t('Customer Segments'),
  'customer-pains': t('Customer Pains'),
  benefits: t('Benefits'),
  'break-time': t('Break Time!'),
  'market-size': t('Market Size'),
  'jobs-to-be-done': t('Jobs to Be Done'),
  'jtbd-selection': t('JTBD Selection'),
  importance: t('Importance'),
  dissatisfaction: t('Dissatisfaction'),
  'opportunity-plot': t('Opportunity Plot'),
  'value-propositions': t('Value Propositions'),
  // Tool 2
  'pains-benefits': t('Pains & Benefits'),
  features: t('Features'),
  attributes: t('Attributes'),
  'attributes-scale': t('Attributes Scale'),
  competitors: t('Competitors'),
  'competitor-ranking': t('Competitor Ranking'),
  'competition-map': t('Competition Map'),
  'value-propositions-revisited': t('Value Propositions Revisited'),
  // Tool 3
  'partnership-identification': t('Partnership Identification'),
  resources: t('Resources'),
  'value-exchange': t('Value Exchange'),
  'distribution-channels': t('Distribution Channels'),
  'partner-selection': t('Partner Selection'),
  'channel-selection': t('Channel Selection'),
  'stakeholder-map': t('Stakeholder Map'),
  // Tool 4
  'customer-business-model-fit': t('Customer - Business Model Fit'),
  'business-models': t('Business Models'),
  'business-models-ideation': t('Business Models Ideation'),
  'business-models-creation': t('Business Models Creation'),
  'the-marketing-funnel': t('The Marketing Funnel'),
  'top-of-funnel-tofu': t('Top of Funnel (TOFU)'),
  'middle-of-funnel-mofu': t('Middle of Funnel (MOFU)'),
  'bottom-of-funnel-bofu': t('Bottom of funnel (BOFU)'),
  'activities-selection': t('Activities Selection'),
  'sales-strategy': t('Sales Strategy'),
  'your-marketing-funnel': t('Your Marketing Funnel'),
  // Tool 5
  'go-to-market-pricing-strategies': t('Go-to-market Pricing Strategies'),
  'other-pricing-strategies': t('Other Pricing Strategies'),
  'pricing-strategy-definition': t('Pricing Strategy Definition'),
  'cost-price-profit': t('Cost, Price & Profit'),
  'competitors-pricing': t('Competitors Pricing'),
  'willingness-to-pay': t('Willingness to Pay'),
  'estimated-price': t('Estimated Price'),
  'life-time-value-retention': t('Lifetime Value & Retention'),
  'ltvcac-ratio': t('LTV/CAC Ratio'),
  'business-viability': t('Business Viability'),
  // Tool 6
  'go-to-market': t('Go to Market'),
  'pre-post-operating-finances': t('Pre & Post Operating Finances'),
  'fixed-assets': t('Fixed Assets'),
  'initial-operating-capital': t('Initial Operating Capital'),
  'sources-of-funding': t('Sources of Funding'),
  'people-payroll': t('People & Payroll'),
  'operational-expenses': t('Operational Expenses'),
  'sales-cost-of-goods-sold-cogs': t('Sales: Cost of Goods Sold (COGS)'),
  'sales-forecast': t('Sales Forecast'),
  'financial-projections': t('Financial Projections'),
  // New Tool 6
  'download-template': t('Download Template'),
  'select-an-example': t('Select an Example'),
  'how-does-the-worksheet-work': t('How Does The Worksheet Work'),
  'financial-scenarios': t('Financial Scenarios'),
  '1-projection-timeline-currency': t('1. Projection Timeline Currency'),
  '2-productsservices-for-projection': t('2. Productsservices For Projection'),
  '3-cost-of-goods-sold-cogs': t('3. Cost of-Goods-Sold-Cogs'),
  '4-sales-forecast': t('4. Sales Forecast'),
  '5-people-payroll': t('5. People Payroll'),
  '6-operating-cost': t('6. Operating Cost'),
  '7-sources-of-funding-strategy': t('7 Sources of Funding Strategy'),
  '8-balance-sheet': t('8. Balance Sheet'),
  'scenarios-comparison': t('Scenarios Comparison'),
  // Tool 7
  'your-system': t('Your System'),
  'resources-roles': t('Resources & Roles'),
  'relationships-rules': t('Relationships & Rules'),
  results: t('Results'),
  'systemic-change': t('Systemic Change'),
  'your-impact': t('Your Impact'),
  'mission-vision': t('Mission & Vision'),
  beneficiaries: t('Beneficiaries'),
  'sustainable-development-goals': t('Sustainable Development Goals'),
  'theory-of-change': t('Theory of Change'),
  outcomes: t('Outcomes'),
  'outcome-indicators': t('Outcome Indicators'),
  // Tool 8
  'future-outlook': t('Future Outlook'),
  'moonshot-goals': t('Moonshot Goals'),
  'values-culture': t('Values & Culture'),
  'key-performance-indicators': t('Key Performance Indicators'),
  milestones: t('Milestones'),
  'year-1-focus': t('Year 1 Focus'),
  'year-1-okrs': t('Year 1 OKRs'),
  'year-2-focus': t('Year 2 Focus'),
  'year-2-okrs': t('Year 2 OKRs'),
  'year-3-focus': t('Year 3 Focus'),
  'year-3-okrs': t('Year 3 OKRs'),
  'risk-management': t('Risk Management'),
  'your-dream-team': t('Your Dream Team'),
  'your-growth-plan': t('Your Growth Plan'),
});

const itemToStep = (t, stepSlug) => {
  const stepNames = getSteps(t);
  return stepNames[stepSlug] || null;
};

export { itemToToolName, itemToStep };
