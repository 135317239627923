import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getIframeSrc } from '../utils';

const EmptyVideo = styled.div`
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width};
  p {
    text-align: center;
    margin-top: ${({ height }) => height / 2 - 10}px;
  }
`;

const VideoIframe = props => {
  const { src, t } = props;

  const iframeProps = {
    allowFullScreen: 1,
    frameBorder: 0,
    src: getIframeSrc(src),
  };
  return iframeProps.src ? (
    <iframe {...props} title={iframeProps.src} {...iframeProps} />
  ) : (
    <EmptyVideo {...props}>
      <p>{t('Video preview')}</p>
    </EmptyVideo>
  );
};

VideoIframe.propTypes = {
  src: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.string,
  t: PropTypes.func,
};

VideoIframe.defaultProps = {
  src: '',
  height: 280,
  width: '100%',
  t: text => text,
};

export default VideoIframe;
