const TOOL_CLEAR_ERROR = 'bfb/tools/tool/TOOL_CLEAR_ERROR';
const TOOLS_LIST = 'bfb/tools/tool/TOOLS_LIST';
const TOOLS_LIST_SUCCEEDED = 'bfb/tools/tool/TOOLS_LIST_SUCCEEDED';
const TOOLS_LIST_FAILED = 'bfb/tools/tool/TOOLS_LIST_FAILED';
const TOOL_UPDATE = 'bfb/tools/tool/TOOL_UPDATE';
const TOOL_UPDATE_SUCCEEDED = 'bfb/tools/tool/TOOL_UPDATE_SUCCEEDED';
const TOOL_UPDATE_FAILED = 'bfb/tools/tool/TOOL_UPDATE_FAILED';

export default {
  TOOLS_LIST,
  TOOLS_LIST_SUCCEEDED,
  TOOLS_LIST_FAILED,
  TOOL_CLEAR_ERROR,
  TOOL_UPDATE,
  TOOL_UPDATE_SUCCEEDED,
  TOOL_UPDATE_FAILED,
};
