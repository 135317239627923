import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import TranslationsContext from 'providers/translationsProvider';

import { ToolInputContainer, ToolInput } from 'components/Tools/';
import isFieldEmpty from 'utils/isFieldEmpty';

// Components
import Form from 'components/elements/Form';

function ToolDefaultInput(props) {
  const {
    autoSize,
    bgColor,
    className,
    disabled,
    empty,
    form,
    height,
    initialValue,
    max,
    name,
    onBlur,
    placeholder,
    rules,
    shouldNotDecorate,
    textAreaMinHeight,
    showWarningAlert,
    showCount,
    maxLength,
    ...rest
  } = props;

  const [updatedValue, setUpdatedValue] = useState(initialValue);

  if (!form) {
    console.error('ToolDefaultInput: form prop is required');
    return null;
  }
  const { getFieldValue } = form;

  const { format, t } = useContext(TranslationsContext);
  const inputValue = isFieldEmpty(getFieldValue(name)) ? updatedValue : getFieldValue(name);

  const inputLength =
    (inputValue !== null && inputValue !== undefined && String(inputValue).length) || 0;

  let decoratorRules = rules;
  if (max) {
    decoratorRules = [
      ...rules,
      {
        validator: (rule, value, callback) => {
          // TODO: we don't need this, we could do showCount and maxLength={40}
          // as they are native antd props and outputs the same
          if (max && value !== undefined && value !== null && value.length > max) {
            callback(
              format(t('This field cannot be longer than %(maxCharactersCount)s characters'), {
                maxCharactersCount: max,
              })
            );
            if (showWarningAlert) showWarningAlert(true);
          } else if (showWarningAlert) {
            showWarningAlert(false);
          }
          callback();
        },
      },
    ];
  }

  const updateLength = e => {
    const { value } = e.target;
    setUpdatedValue(value);
  };

  return (
    <div className={className} style={{ backgroundColor: bgColor || '#FFFFFF' }}>
      <ToolInputContainer
        empty={empty}
        height={height}
        textAreaMinHeight={textAreaMinHeight}
        {...rest}
      >
        {shouldNotDecorate ? (
          <Form.Item name={name} size="small">
            <ToolInput.TextArea
              name={name}
              autoSize={autoSize}
              defaultValue={initialValue}
              disabled={disabled}
              noborder
              onChange={updateLength}
              onBlur={onBlur}
              onEnter={onBlur}
              placeholder={placeholder}
              usebackground
              rows={10}
            />
          </Form.Item>
        ) : (
          <Form.Item initialValue={initialValue} rules={decoratorRules} name={name} size="small">
            <ToolInput.TextArea
              name={name}
              autoSize={autoSize}
              disabled={disabled}
              noborder
              onChange={updateLength}
              placeholder={placeholder}
              onBlur={onBlur}
              onEnter={onBlur}
              usebackground
              rows={10}
              showCount={showCount}
              maxLength={maxLength}
            />
          </Form.Item>
        )}
      </ToolInputContainer>
      {max && (
        <span
          style={{
            float: 'right',
            fontSize: '14px',
            color: `${inputLength > max ? '#F25A5A' : '#999999'}`,
          }}
        >
          {/* TODO:
            we don't need this, we could do showCount and maxLength={40}
            as they are native antd props and outputs the same

            ClickUp task id: DEV-8515
          */}
          {`${inputLength}/${max}`}
        </span>
      )}
    </div>
  );
}

ToolDefaultInput.propTypes = {
  autoSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  bgColor: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  empty: PropTypes.bool,
  form: PropTypes.instanceOf(Object).isRequired,
  height: PropTypes.string,
  initialValue: PropTypes.node,
  max: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  rules: PropTypes.instanceOf(Array),
  shouldNotDecorate: PropTypes.bool,
  textAreaMinHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showWarningAlert: PropTypes.func,
  showCount: PropTypes.bool,
  maxLength: PropTypes.number,
};

ToolDefaultInput.defaultProps = {
  autoSize: true,
  bgColor: '',
  className: '',
  disabled: false,
  empty: false,
  height: undefined,
  initialValue: null,
  max: undefined,
  onBlur: null,
  placeholder: '',
  rules: [],
  shouldNotDecorate: false,
  showWarningAlert: null,
  textAreaMinHeight: undefined,
  showCount: false,
  maxLength: null,
};

export default ToolDefaultInput;
