import { createSelector } from 'reselect';

const getState = state => state.initiative;

const getRawInitiative = createSelector([getState], state => state.get('data'));

const getInitiativesById = createSelector([getRawInitiative], state => state.get('byId'));

const getInitiativeOfId = createSelector(
  [getInitiativesById],
  state => id => state.get(String(id))
);

const getInitiativesByProject = createSelector([getRawInitiative], state => state.get('byProject'));

const getInitiatives = createSelector([getInitiativesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getInitiatives,
  getInitiativesById,
  getInitiativeOfId,
  getInitiativesByProject,
  getErrorMessage,
};
