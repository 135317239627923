import { createSelector } from 'reselect';

const getState = state => state.milestone;

const getRawMilestone = createSelector([getState], state => state.get('data'));

const getMilestonesById = createSelector([getRawMilestone], state => state.get('byId'));

const getMilestonesByProject = createSelector([getRawMilestone], state => state.get('byProject'));

const getMilestones = createSelector([getMilestonesById], state => state.valueSeq().toJS());

const getMilestonesOfYearByProject = year =>
  createSelector([getMilestonesByProject], state => ({
    get: (projectId, defaultVal) =>
      state.get(projectId, defaultVal).filter(milestone => milestone.year === year),
  }));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getMilestones,
  getMilestonesById,
  getMilestonesByProject,
  getMilestonesOfYearByProject,
  getErrorMessage,
};
