import types from './types';

const clearInterestships = () => ({
  type: types.INTERESTSHIPS_CLEAR,
});

const clearError = () => ({
  type: types.INTERESTSHIP_CLEAR_ERROR,
});

const listInterestships = payload => ({
  type: types.INTERESTSHIPS_LIST,
  payload,
});

const listInterestshipsPending = () => ({
  type: types.INTERESTSHIPS_LIST_PENDING,
});

const listInterestshipsSucceeded = payload => ({
  type: types.INTERESTSHIPS_LIST_SUCCEEDED,
  payload,
});

const listInterestshipsFailed = ({ message }) => ({
  type: types.INTERESTSHIPS_LIST_FAILED,
  payload: { message },
});

const listMPInterestships = payload => ({
  type: types.INTERESTSHIPS_LIST_MP,
  payload,
});

const listMPInterestshipsPending = () => ({
  type: types.INTERESTSHIPS_LIST_MP_PENDING,
});

const listMPInterestshipsSucceeded = payload => ({
  type: types.INTERESTSHIPS_LIST_MP_SUCCEEDED,
  payload,
});

const listMPInterestshipsFailed = ({ message }) => ({
  type: types.INTERESTSHIPS_LIST_MP_FAILED,
  payload: { message },
});

const updateMPInterestships = (payload, callback) => ({
  type: types.INTERESTSHIPS_UPDATE_MP,
  payload,
  callback,
});

const updateMPInterestshipsPending = () => ({
  type: types.INTERESTSHIPS_UPDATE_MP_PENDING,
});

const updateMPInterestshipsSucceeded = payload => ({
  type: types.INTERESTSHIPS_UPDATE_MP_SUCCEEDED,
  payload,
});

const updateMPInterestshipsFailed = ({ message }) => ({
  type: types.INTERESTSHIPS_UPDATE_MP_FAILED,
  payload: { message },
});

const createInterestship = (payload, callback) => ({
  type: types.INTERESTSHIP_CREATE,
  payload,
  callback,
});

const createInterestshipSucceeded = payload => ({
  type: types.INTERESTSHIP_CREATE_SUCCEEDED,
  payload,
});

const createInterestshipFailed = ({ message }) => ({
  type: types.INTERESTSHIP_CREATE_FAILED,
  payload: { message },
});

const fetchInterestship = payload => ({
  type: types.INTERESTSHIP_FETCH,
  payload,
});

const fetchInterestshipSucceeded = payload => ({
  type: types.INTERESTSHIP_FETCH_SUCCEEDED,
  payload,
});

const fetchInterestshipFailed = ({ message }) => ({
  type: types.INTERESTSHIP_FETCH_FAILED,
  payload: { message },
});

const updateInterestship = (payload, callback) => ({
  type: types.INTERESTSHIP_UPDATE,
  payload,
  callback,
});

const updateInterestshipSucceeded = payload => ({
  type: types.INTERESTSHIP_UPDATE_SUCCEEDED,
  payload,
});

const updateInterestshipFailed = ({ message }) => ({
  type: types.INTERESTSHIP_UPDATE_FAILED,
  payload: { message },
});

const deleteInterestship = (payload, callback) => ({
  type: types.INTERESTSHIP_DELETE,
  payload,
  callback,
});

const deleteInterestshipSucceeded = payload => ({
  type: types.INTERESTSHIP_DELETE_SUCCEEDED,
  payload,
});

const deleteInterestshipFailed = ({ message }) => ({
  type: types.INTERESTSHIP_DELETE_FAILED,
  payload: { message },
});

export default {
  clearInterestships,
  clearError,
  createInterestship,
  createInterestshipFailed,
  createInterestshipSucceeded,
  listInterestships,
  listInterestshipsPending,
  listInterestshipsFailed,
  listInterestshipsSucceeded,
  fetchInterestship,
  fetchInterestshipFailed,
  fetchInterestshipSucceeded,
  updateInterestship,
  updateInterestshipFailed,
  updateInterestshipSucceeded,
  deleteInterestship,
  deleteInterestshipFailed,
  deleteInterestshipSucceeded,
  listMPInterestships,
  listMPInterestshipsPending,
  listMPInterestshipsSucceeded,
  listMPInterestshipsFailed,
  updateMPInterestships,
  updateMPInterestshipsPending,
  updateMPInterestshipsSucceeded,
  updateMPInterestshipsFailed,
};
