import api from './api';

const createScenario = ({ ...scenario }) => api.post('/api/scenarios/', { ...scenario });

const deleteScenario = ({ scenarioId }) => api.delete(`/api/scenarios/${scenarioId}/`);

const fetchScenario = ({ id }) => api.get(`/api/scenarios/${id}/`);

const listScenarios = ({ project }) =>
  api.get('/api/scenarios/', {
    params: {
      project,
    },
  });

const updateScenario = ({ scenarioId, ...scenario }) =>
  api.patch(`/api/scenarios/${scenarioId}/`, { ...scenario });

export default {
  createScenario,
  deleteScenario,
  fetchScenario,
  listScenarios,
  updateScenario,
};
