import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions as memberApplicationActions } from 'store/modules/memberApplication';
import applicationService from 'services/memberApplication';
import { actions as programActions } from 'store/modules/program';
import programService from 'services/program';
import { actions as projectActions } from 'store/modules/project';
import { actions as productActions } from 'store/modules/product';
import actions from './actions';
import types from './types';

export function* fetchMentorMatchingProgram(action) {
  const { id } = action.payload;
  try {
    let program;
    try {
      program = yield call(programService.fetchPublicProgram, { id });
    } catch (e) {
      if (e.response.status === 404) {
        // Program is no longer available in public endpoint, let's try to get it from /programs/
        program = yield call(programService.fetchProgram, { id });
      } else {
        throw e;
      }
    }
    yield put(programActions.fetchProgramSucceeded(program.data));
    if (program.data.member_application) {
      const application = yield call(applicationService.fetchApplication, {
        id: program.data.member_application,
      });
      yield put(memberApplicationActions.fetchApplicationSucceeded(application.data));
      yield put(projectActions.fetchProject({ id: application.data.project }));
      yield put(productActions.listProducts({ project: application.data.project }));
    }
  } catch (e) {
    yield put(
      actions.fetchMatchingProgramFailed({
        message: e.message || e.response.data.detail,
      })
    );
  }
}

function* myProgramCombiner() {
  yield all([takeLatest(types.FETCH_MATCHING_PROGRAM, fetchMentorMatchingProgram)]);
}

export default myProgramCombiner;
