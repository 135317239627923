import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import serviceMonthlyExpense from 'services/FinancialProjections/monthlyExpense';
import MonthlyExpense from 'models/FinancialProjections/MonthlyExpense';
import { actions, types } from 'store/modules/FinancialProjections/monthlyExpense';
import { types as commentTypes } from 'store/modules/comment';

export function* updateMonthlyExpense(action) {
  const { scenario, id, ...updatedMonthlyExpense } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceMonthlyExpense.updateMonthlyExpense, {
      id,
      ...updatedMonthlyExpense,
    });
    yield put(
      actions.updateMonthlyExpenseSucceeded({
        ...updatedMonthlyExpense, // To keep the year property.
        ...updated.data, // New data.
        scenario, // And to keep the scenario property.
      })
    );
    // yield put(expenseActions.listExpenses({ scenario }));
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateMonthlyExpenseFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === MonthlyExpense.contentType) {
    yield put(
      actions.fetchMonthlyExpense({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* monthlyExpenseSaga() {
  yield all([
    takeEvery(types.MONTHLY_EXPENSE_UPDATE, updateMonthlyExpense),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default monthlyExpenseSaga;
