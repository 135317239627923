import { Record } from 'immutable';
import ContentType from 'models/ContentType.js';

export const FINANCIAL_FORECAST_SLUG = 'financial-forecast';

export const FINANCIAL_FORECAST_STEP_0_SLUG = 'introduction';
export const FINANCIAL_FORECAST_STEP_1_SLUG = 'download-template';
export const FINANCIAL_FORECAST_STEP_2_SLUG = 'select-an-example';
export const FINANCIAL_FORECAST_STEP_3_SLUG = 'how-does-the-worksheet-work';
export const FINANCIAL_FORECAST_STEP_4_SLUG = 'financial-scenarios';
export const FINANCIAL_FORECAST_STEP_5_SLUG = '1-projection-timeline-currency';
export const FINANCIAL_FORECAST_STEP_6_SLUG = '2-products-services-for-projection';
export const FINANCIAL_FORECAST_STEP_7_SLUG = '3-cost-of-goods-sold-cogs';
export const FINANCIAL_FORECAST_STEP_8_SLUG = '4-sales-forecast';
export const FINANCIAL_FORECAST_STEP_9_SLUG = '5-people-payroll';
export const FINANCIAL_FORECAST_STEP_10_SLUG = '6-operating-cost';
export const FINANCIAL_FORECAST_STEP_11_SLUG = '7-sources-of-funding-strategy';
export const FINANCIAL_FORECAST_STEP_12_SLUG = '8-balance-sheet';
export const FINANCIAL_FORECAST_STEP_13_SLUG = 'scenarios-comparison';
export const FINANCIAL_FORECAST_STEP_14_SLUG = 'summary';

export const SPREADSHEET_WORK_STEPS = [
  FINANCIAL_FORECAST_STEP_5_SLUG,
  FINANCIAL_FORECAST_STEP_6_SLUG,
  FINANCIAL_FORECAST_STEP_7_SLUG,
  FINANCIAL_FORECAST_STEP_8_SLUG,
  FINANCIAL_FORECAST_STEP_9_SLUG,
  FINANCIAL_FORECAST_STEP_10_SLUG,
  FINANCIAL_FORECAST_STEP_11_SLUG,
  FINANCIAL_FORECAST_STEP_12_SLUG,
  FINANCIAL_FORECAST_STEP_13_SLUG,
  FINANCIAL_FORECAST_STEP_14_SLUG,
];

export const SPREADSHEET_GOOGLE = 1;
export const SPREADSHEET_EXCEL = 2;

export const EXAMPLE_BEAUTY_CO = 1;
export const EXAMPLE_CODING_COURSE = 2;
export const EXAMPLE_CLINIC_SOFTWARE = 3;

const FinancialForecastRecord = Record({
  id: null,
  contentType: ContentType.FINANCIALFORECAST,
  project: null,
  spreadsheet_type: null,
  e_example_choice: null,
  m_example_choice: null,
  summary: null,
  scenarios: [],
  comments: [],
});

class FinancialForecast extends FinancialForecastRecord {
  static contentType = ContentType.FINANCIALFORECAST;

  get id() {
    return this.get('id');
  }

  get type() {
    return this.get('spreadsheet_type');
  }
}

export default FinancialForecast;
