import api from '../api';

const createRule = ({ ...rule }) => api.post('/api/rules/', { ...rule });

const deleteRule = ({ id }) => api.delete(`/api/rules/${id}/`);

const fetchRule = ({ id }) => api.get(`/api/rules/${id}/`);

const listRules = ({ system }) => api.get(`/api/rules/?system=${system}`);

const updateRule = ({ id, ...rule }) => api.patch(`/api/rules/${id}/`, { ...rule });

export default {
  createRule,
  deleteRule,
  fetchRule,
  listRules,
  updateRule,
};
