import api from '../api';

const updateStep = ({ id, project, program, ...step }) =>
  api.patch(`/api/project-program-tools-steps/${id}/?project=${project}&program=${program}`, {
    ...step,
  });

const fetchStep = ({ id, step }) =>
  api.get(`/api/project-program-tools-steps/${id}/`, { params: step });

const listSteps = query => api.get('/api/project-program-tools-steps/', { params: query });

export default {
  updateStep,
  listSteps,
  fetchStep,
};
