import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Line } from 'components/elements';
import { Row } from 'components/layout';
import { Col } from 'antd';
import { H2 } from 'components/fontSystem';

const TitleLineButton = props => (
  <Row type="flex" justify="space-between" align="middle" className={props.className}>
    <Col sm={5} lg={4}>
      <H2 $mb0>{props.title}</H2>
    </Col>
    <Col sm={props.showButton ? 15 : 20} lg={props.showButton ? 17 : 20}>
      <Line />
    </Col>
    {props.showButton && (
      <Col sm={4} lg={3} className="btn-container">
        <Button
          icon={props.btnIcon}
          onClick={props.onClick}
          size="small"
          type="primary"
          disabled={props.disabled}
        >
          {props.btnValue}
        </Button>
      </Col>
    )}
  </Row>
);

const StyledTitleLineButton = styled(TitleLineButton)`
  &&& {
    margin-bottom: 19px;
    margin-top: ${props => props.mt || '0px'};
    .btn-container {
      text-align: right;
    }
  }
`;

TitleLineButton.propTypes = {
  btnIcon: PropTypes.element.isRequired, // an Icon component, ex: <EyeOutlined/>
  btnValue: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
};

TitleLineButton.defaultProps = {
  onClick: undefined,
  disabled: false,
  showButton: true,
};

export default StyledTitleLineButton;
