import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import FixedAsset from 'models/FinancialProjections/FixedAsset';
import serviceFixedAsset from 'services/FinancialProjections/fixedAsset';
import types from './types';
import actions from './actions';

export function* createFixedAsset(action) {
  const { scenario, ...fixedAsset } = action.payload;
  try {
    const response = yield call(serviceFixedAsset.createFixedAsset, {
      scenario,
      ...fixedAsset,
    });
    yield put(actions.createFixedAssetSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(
      actions.createFixedAssetFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteFixedAsset(action) {
  const { scenario, id } = action.payload;
  try {
    yield call(serviceFixedAsset.deleteFixedAsset, { id });
    yield put(actions.deleteFixedAssetSucceeded({ id, scenario }));
  } catch (e) {
    yield put(
      actions.deleteFixedAssetFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchFixedAsset(action) {
  const { scenario, id } = action.payload;
  try {
    const response = yield call(serviceFixedAsset.fetchFixedAsset, {
      scenario,
      id,
    });
    yield put(actions.fetchFixedAssetSucceeded({ ...response.data, scenario }));
  } catch (e) {
    yield put(actions.fetchFixedAssetFailed({ message: e.message }));
  }
}

export function* listFixedAssets(action) {
  const { scenario } = action.payload;
  try {
    const fixedAssets = yield call(serviceFixedAsset.listFixedAssets, {
      scenario,
    });
    yield put(actions.listFixedAssetsSucceeded({ results: fixedAssets.data, scenario }));
  } catch (e) {
    yield put(actions.listFixedAssetsFailed({ message: e.message }));
  }
}

export function* updateFixedAsset(action) {
  const { scenario, id, ...updatedFixedAsset } = action.payload;
  try {
    const updated = yield call(serviceFixedAsset.updateFixedAsset, {
      scenario,
      id,
      ...updatedFixedAsset,
    });
    yield put(actions.updateFixedAssetSucceeded({ ...updated.data, scenario }));
  } catch (e) {
    yield put(
      actions.updateFixedAssetFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === FixedAsset.contentType) {
    yield put(
      actions.fetchFixedAsset({
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* fixedAssetSaga() {
  yield all([
    takeLatest(types.FIXED_ASSET_CREATE, createFixedAsset),
    takeEvery(types.FIXED_ASSET_DELETE, deleteFixedAsset),
    takeLatest(types.FIXED_ASSET_FETCH, fetchFixedAsset),
    takeLatest(types.FIXED_ASSET_UPDATE, updateFixedAsset),
    takeEvery(types.FIXED_ASSETS_LIST, listFixedAssets),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default fixedAssetSaga;
