import api from '../api';

const createExpense = ({ scenario, ...expense }) =>
  api({
    method: 'post',
    url: 'api/expenses/',
    data: {
      scenario,
      ...expense,
    },
    params: {
      scenario,
    },
  });

const deleteExpense = ({ id }) => api.delete(`api/expenses/${id}`);

const fetchExpense = ({ id }) => api.get(`api/expenses/${id}/`);

const listExpenses = ({ scenario }) =>
  api.get('api/expenses/', {
    params: {
      scenario,
    },
  });

const updateExpense = ({ id, ...updatedExpense }) =>
  api({
    method: 'patch',
    url: `api/expenses/${id}/`,
    data: updatedExpense,
  });

export default {
  createExpense,
  deleteExpense,
  fetchExpense,
  listExpenses,
  updateExpense,
};
