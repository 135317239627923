import types from './types';

const listSdgTargets = () => ({
  type: types.SDG_TARGETS_LIST,
});

const listSdgTargetsSucceeded = payload => ({
  type: types.SDG_TARGETS_LIST_SUCCEEDED,
  payload,
});

const listSdgTargetsFailed = ({ message }) => ({
  type: types.SDG_TARGETS_LIST_FAILED,
  payload: { message },
});

export default {
  listSdgTargets,
  listSdgTargetsFailed,
  listSdgTargetsSucceeded,
};
