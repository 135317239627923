import { createSelector } from 'reselect';

const getState = state => state.user;

const getUser = createSelector([getState], state => state.get('data'));

const isAuthenticated = createSelector([getState], state => state.get('isAuthenticated'));

const isMember = createSelector([getUser, isAuthenticated], (user, authenticated) =>
  Boolean(authenticated && user.get('member'))
);

const isAdmin = createSelector([getUser, isAuthenticated], (user, authenticated) =>
  Boolean(authenticated && user.get('is_admin'))
);

const isMentor = createSelector([getUser, isAuthenticated], (user, authenticated) =>
  Boolean(authenticated && user.get('mentor'))
);

const getUserLinks = createSelector([getState], state => state.get('linkData'));

const fetchingUser = createSelector([getState], state => state.get('isFetching'));

const userIsAccessible = createSelector([getState], state => state.get('userIsAccessible'));

const isError = createSelector([getState], state => state.get('isError'));

const isPendingActivation = createSelector(
  [getState, isError, fetchingUser, getUser],
  (state, error, isFetching, user) => !error && !isFetching && !!user.id && !user.isActive()
);

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const getForgotPasswordRequested = createSelector([getState], state =>
  state.get('forgotPasswordRequested')
);

export default {
  getUser,
  isAuthenticated,
  isMember,
  isMentor,
  isAdmin,
  isPendingActivation,
  fetchingUser,
  userIsAccessible,
  isError,
  getErrorMessage,
  getForgotPasswordRequested,
  getUserLinks,
};
