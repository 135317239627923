import api from './api';

const getDeepDive = payload => api.get('/api/deep-dive-materials/', { params: payload });

const createDeepDive = material => api.post('/api/deep-dive-materials/', { ...material });

const editDeepDive = material =>
  api.patch(`/api/deep-dive-materials/${material.id}/`, { ...material });

const deleteDeepDive = ({ id }) => api.delete(`api/deep-dive-materials/${id}/`);

export default {
  getDeepDive,
  createDeepDive,
  editDeepDive,
  deleteDeepDive,
};
