import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledUL = styled.ul`
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.nolist &&
    css`
      list-style: none;
      padding: 0;
      margin: 0;
    `}
`;

const List = props => {
  const { items, ...rest } = props;
  return (
    <StyledUL {...rest}>
      {items.map(item => (
        <li key={item}>{item}</li>
      ))}
    </StyledUL>
  );
};

List.propTypes = {
  items: PropTypes.instanceOf(Array),
};

List.defaultProps = {
  items: [],
};

export default List;
