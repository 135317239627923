/**
 * Sorts an array of `ProgramTool` objects by their `order` property.
 * @param {ProgramTool} toolA - Instance of `ProgramTool` object containing the `order` property.
 * @param {ProgramTool} toolB - Instance of `ProgramTool` object containing the `order` property.
 */
export default function sortByToolOrder(toolA, toolB) {
  if (toolA.order < toolB.order) {
    return -1;
  }
  if (toolA.order > toolB.order) {
    return 1;
  }
  return 0;
}
