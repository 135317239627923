import * as reduxHelper from 'store/utils/reduxHelper';
import Surveys from 'models/Surveys';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { list: [], byType: {} },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SURVEYS_LIST:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.SURVEYS_LIST_SUCCEEDED: {
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyReceiveInfo({
          data: {
            list: action.payload.results.map(survey => new Surveys(survey)),
            byType: action.payload.results.reduce((obj, survey) => {
              const typeSurveys = obj[survey.type] || [];
              return {
                ...obj,
                [survey.type]: [...typeSurveys, new Surveys(survey)],
              };
            }, {}),
          },
        })
      );
    }
    case types.SURVEYS_LIST_FAILED:
    default:
      return state;
  }
}
