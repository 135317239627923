import types from './types';

const clearError = () => ({
  type: types.RISK_CATEGORY_CLEAR_ERROR,
});

const fetchRiskCategory = ({ id }) => ({
  type: types.RISK_CATEGORY_FETCH,
  payload: { id },
});

const fetchRiskCategoriesucceeded = ({ ...value }) => ({
  type: types.RISK_CATEGORY_FETCH_SUCCEEDED,
  payload: { ...value },
});

const fetchRiskCategoryFailed = ({ message }) => ({
  type: types.RISK_CATEGORY_FETCH_FAILED,
  payload: { message },
});

const listRiskCategories = ({ project }) => ({
  type: types.RISK_CATEGORIES_LIST,
  payload: { project },
});

const listRiskCategoriesSucceeded = payload => ({
  type: types.RISK_CATEGORIES_LIST_SUCCEEDED,
  payload,
});

const listRiskCategoriesFailed = ({ message }) => ({
  type: types.RISK_CATEGORIES_LIST_FAILED,
  payload: { message },
});

export default {
  clearError,
  fetchRiskCategory,
  fetchRiskCategoryFailed,
  fetchRiskCategoriesucceeded,
  listRiskCategories,
  listRiskCategoriesFailed,
  listRiskCategoriesSucceeded,
};
