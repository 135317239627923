/* eslint-disable max-len */
import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import RetentionActivity from 'models/PricingBusinessViability/RetentionActivity';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RETENTION_ACTIVITIES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (retentionActivitiesById, retentionActivity) =>
                retentionActivitiesById.set(
                  retentionActivity.id.toString(),
                  new RetentionActivity(retentionActivity)
                ),
              state.get('data').get('byId')
            ),
          },
        })
      );
    case types.RETENTION_ACTIVITY_CREATE_SUCCEEDED:
    case types.RETENTION_ACTIVITY_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new RetentionActivity(action.payload)),
          },
        })
      );
    case types.RETENTION_ACTIVITY_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
          },
        })
      );
    case types.RETENTION_ACTIVITIES_LIST:
    case types.RETENTION_ACTIVITY_CREATE:
    case types.RETENTION_ACTIVITY_DELETE:
    case types.RETENTION_ACTIVITY_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PRODUCT_PRICING_MODEL_UPDATE_FAILED:
    case types.RETENTION_ACTIVITIES_LIST_FAILED:
    case types.RETENTION_ACTIVITY_DELETE_FAILED:
    case types.RETENTION_ACTIVITY_CREATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
          updatePending: false,
        })
      );
    case types.RETENTION_ACTIVITY_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    default:
      return state;
  }
}
