const PAIN_CLEAR_ERROR = 'bfb/pain/PAIN_CLEAR_ERROR';
const PAINS_LIST = 'bfb/pain/PAINS_LIST';
const PAINS_LIST_SUCCEEDED = 'bfb/pain/PAINS_LIST_SUCCEEDED';
const PAINS_LIST_FAILED = 'bfb/pain/PAINS_LIST_FAILED';
const PAIN_CREATE = 'bfb/pain/PAIN_CREATE';
const PAIN_CREATE_SUCCEEDED = 'bfb/pain/PAIN_CREATE_SUCCEEDED';
const PAIN_CREATE_FAILED = 'bfb/pain/PAIN_CREATE_FAILED';
const PAIN_DELETE = 'bfb/pain/PAIN_DELETE';
const PAIN_DELETE_SUCCEEDED = 'bfb/pain/PAIN_DELETE_SUCCEEDED';
const PAIN_DELETE_FAILED = 'bfb/pain/PAIN_DELETE_FAILED';
const PAIN_FETCH = 'bfb/pain/PAIN_FETCH';
const PAIN_FETCH_SUCCEEDED = 'bfb/pain/PAIN_FETCH_SUCCEEDED';
const PAIN_FETCH_FAILED = 'bfb/pain/PAIN_FETCH_FAILED';
const PAIN_UPDATE = 'bfb/pain/PAIN_UPDATE';
const PAIN_UPDATE_SUCCEEDED = 'bfb/pain/PAIN_UPDATE_SUCCEEDED';
const PAIN_UPDATE_FAILED = 'bfb/pain/PAIN_UPDATE_FAILED';

export default {
  PAINS_LIST,
  PAINS_LIST_SUCCEEDED,
  PAINS_LIST_FAILED,
  PAIN_CLEAR_ERROR,
  PAIN_CREATE,
  PAIN_CREATE_SUCCEEDED,
  PAIN_CREATE_FAILED,
  PAIN_DELETE,
  PAIN_DELETE_SUCCEEDED,
  PAIN_DELETE_FAILED,
  PAIN_FETCH,
  PAIN_FETCH_SUCCEEDED,
  PAIN_FETCH_FAILED,
  PAIN_UPDATE,
  PAIN_UPDATE_SUCCEEDED,
  PAIN_UPDATE_FAILED,
};
