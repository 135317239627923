import api from '../api';

const getAccount = () => api.get('/api/account/');

const userEdit = userData =>
  api.post(`/api/members/${userData.id}`, {
    ...userData,
  });

export default { getAccount, userEdit };
