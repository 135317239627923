import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for listing sectors. For example, used to set up a program.

export const sectorsApi = createApi({
  reducerPath: 'sectorsApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/sectors/',
  }),
  endpoints: builder => ({
    // List ALL sectors
    listSectors: builder.query({
      query: query => ({ params: query }),
    }),
    // Fetch a sector
    getSectorById: builder.query({
      query: sectorId => `${sectorId}/`,
    }),
  }),
});

export const { useGetSectorByIdQuery, useListSectorsQuery } = sectorsApi;
