import theme from './b4b-theme';

export default {
  // -------- Colors -----------
  checkboxColor: theme.formSelectColor,
  primaryColor: theme.mainColor,
  primaryColorHover: theme.mainColorHover,
  primaryColorFade: theme.mainColorFade,
  primaryColorBgFade: theme.mainColorBgFade,
  secondaryColor: theme.teal,
  secondaryColorFade: theme.tealFade,
  secondaryColorBgFade: theme.tealBgFade,
  companyColorHover: theme.themeCompanyColorHover,
  entColor: theme.memberGreen,
  entColorHover: theme.memberGreenHover,
  entColorFade: theme.memberGreenFade,
  entColorBgFade: theme.memberGreenBgFade,
  mentorColor: theme.mentorBlue,
  mentorColorHover: theme.mentorBlueHover,
  mentorColorFade: theme.mentorBlueFade,
  mentorColorBgFade: theme.mentorBlueBgFade,
  parrotColor: theme.parrotPink,
  parrotColorHover: theme.parrotPinkHover,
  parrotColorFade: theme.parrotPinkFade,
  parrotColorBgFade: theme.parrotPinkBgFade,
  mainWaitlistColor: theme.primaryWaitlistColor,
  mainWaitlistColorBg: theme.primaryWaitlistColorBg,
  greyColor: theme.grey,
  lightGreyColor: theme.lightGrey,
  backgroundGrey: theme.backgroundGreyColor,
  neon: theme.teal,
  brandGradient: `linear-gradient(to right, ${theme.mainColor}, ${theme.memberGreen})`,
  labelRequiredColor: theme.error,
  errorColor: theme.error,
  errorColorFade: theme.errorFade,
  errorColorHover: theme.errorHover,
  warningColor: theme.warning,
  highlightColor: theme.highlightFont,
  // The background colors for active and hover states for things like
  // list items or table cells.
  itemActiveColor: theme.teal,
  itemActiveBg: theme.tealFade,
  itemHoverBg: theme.tealFade,
  // Radio
  radioSize: '20px',
  radioDotColor: theme.formSelectColor,
  // LINK
  linkColor: theme.hyperlink,
  linkHoverColor: theme.hyperlink,
  linkDecoration: 'line-through',
  linkHoverDecoration: 'underline',
  // Layout
  layoutHeaderBackground: '#FFFFFF',
  // Media Queries
  screenXsMin: theme.screenXs,
  screenSmMin: theme.screenSm,
  screenMdMin: theme.screenMd,
  screenLgMin: theme.screenLg,
  screenXlMin: theme.screenXl,
  screenXxlMin: theme.screenXxl,
  // Layout variables
  appBarHeaderHeight: theme.appBarHeaderHeight,
};
