import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import withTranslations from 'hocs/withTranslations';

const MultiSelect = props => {
  const handleBlur = (value, option) => {
    if (props.onBlur) {
      const event = {
        target: { name: props.name, value, ...option },
      };
      props.onBlur(event);
    }
  };
  const handleChange = (value, option) => {
    if (props.onChange) {
      const event = {
        target: { name: props.name, value, ...option },
      };
      props.onChange(event);
    }
  };
  const { t, children, ...rest } = props;
  return (
    <AntSelect
      {...rest}
      mode="multiple"
      onBlur={handleBlur}
      onChange={handleChange}
      notFoundContent={t('Not found, choose an option from the list.')}
    >
      {children}
    </AntSelect>
  );
};
MultiSelect.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  t: PropTypes.func.isRequired,
};
MultiSelect.defaultProps = {
  onChange: null,
  onBlur: null,
};

const StyledMultiSelect = styled(MultiSelect)`
  width: 100%;
  &.ant-select-focused .ant-select-selection {
    box-shadow: none;
    outline: none;
  }
  &.ant-select-open .ant-select-selection {
    border-color: ${props => props.theme.primaryColor} !important;
  }
  .ant-select-selection {
    border: none;
    border-radius: 0;
    border-bottom: 1.25px solid #b3b3b3;
    height: auto;
    padding: 0 0 5px;
    .ant-select-selection__placeholder {
      color: ${({ theme }) => theme.greyColor};
      font-style: italic;
      margin-left: 0px;
    }
    &:hover,
    &:focus {
      border-color: ${props => props.theme.primaryColor} !important;
    }
    .ant-select-selection__rendered {
      margin-left: 0px;
    }

    .ant-select-selection__choice {
      background-color: ${({ theme }) => theme.primaryColor};
      color: white;
      border-radius: 20px;
      border-color: white;
      .ant-select-selection__choice__content {
        color: white;
        line-height: 21px;
      }
    }
    .ant-select-selection__choice__remove {
      color: white;
    }
  }
`;

const TranslatedMultiSelect = withTranslations(StyledMultiSelect, ['t']);

TranslatedMultiSelect.Option = AntSelect.Option;

export default TranslatedMultiSelect;
