import types from './types';

const fetchMatchingProgram = payload => ({
  type: types.FETCH_MATCHING_PROGRAM,
  payload: { id: payload.id },
});

const fetchMatchingProgramFailed = () => ({
  type: types.FETCH_MATCHING_PROGRAM_FAILED,
  payload: null,
});

export default {
  fetchMatchingProgram,
  fetchMatchingProgramFailed,
};
