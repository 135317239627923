import { createSelector } from 'reselect';

const getState = state => state.interestship;

const getRawInterestships = createSelector([getState], state => state.get('data'));

const getRawInvites = createSelector([getState], state => state.get('invites'));

const getInvitesById = createSelector([getRawInvites], state => state.get('byId'));

const getInvites = createSelector([getInvitesById], state => state.valueSeq().toJS());

const getTotalInvites = createSelector([getRawInvites], state => state.get('count'));

const getInterestshipsById = createSelector([getRawInterestships], state => state.get('byId'));

const getInterestshipsByProgram = createSelector([getRawInterestships], state =>
  state.get('byProgram')
);

const getInterestships = createSelector([getInterestshipsById], state => state.valueSeq().toJS());

const getInterestshipsLoading = createSelector([getState], state => state.get('loading'));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const getIsFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getInterestships,
  getInterestshipsByProgram,
  getInterestshipsById,
  getErrorMessage,
  getIsFetching,
  getInterestshipsLoading,
  getInvites,
  getTotalInvites,
};
