import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Item from '../Item';
import Checkbox from '../../Checkbox';
import RequiredWrapper from '../RequiredWrapper';

const CheckboxItem = props => {
  const {
    className,
    children,
    defaultChecked,
    extra,
    label,
    name,
    required,
    rules,
    size,
    displayBlock,
    ...rest
  } = props;

  const childrenWrapped = React.Children.map(children, child => {
    const cloned = typeof child === 'string' ? child : React.cloneElement(child);
    return required ? <RequiredWrapper>{cloned}</RequiredWrapper> : cloned;
  });

  return (
    // this is a <Form.Item>
    <Item
      className={className}
      extra={extra}
      label={label}
      name={name}
      required={required}
      rules={rules}
      initialValue={defaultChecked}
      valuePropName="checked"
      size={size}
      displayBlock={displayBlock}
    >
      <Checkbox checked={defaultChecked} {...rest}>
        {childrenWrapped}
      </Checkbox>
    </Item>
  );
};

CheckboxItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  extra: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  rules: PropTypes.instanceOf(Array),
  size: PropTypes.string,
  displayBlock: PropTypes.bool,
};

CheckboxItem.defaultProps = {
  defaultChecked: false,
  extra: null,
  label: '',
  required: false,
  rules: [],
  size: 'default',
  displayBlock: false,
};

const StyledCheckboxItem = styled(CheckboxItem)`
  &&& {
    ${props =>
      !props.size &&
      css`
        margin-bottom: 0.2em;
        &:last-child {
          margin-bottom: 1.2em;
        }
      `}

    .ant-checkbox-wrapper span {
      font-weight: normal;
    }

    .ant-form-item-control {
      line-height: 1;
    }
  }
`;

export default StyledCheckboxItem;
