import { normalize, schema } from 'normalizr';

const channelSchema = new schema.Entity('channel');
const chainSchema = new schema.Entity('chain', {
  channels: [channelSchema],
});

const normalizeChain = data => {
  // https://redux.js.org/recipes/structuringreducers/normalizingstateshape#relationships-and-tables
  const { result, entities } = normalize(data, chainSchema);
  return {
    chain: entities.chain[result],
    channels: entities.channel,
  };
};

const normalizeChains = data => {
  const {
    entities: { chain, channel },
  } = normalize(data, [chainSchema]);
  return {
    chains: Object.values(chain),
    channels: Object.values(channel),
  };
};

export { normalizeChain, normalizeChains };
