import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { H4 } from 'components/fontSystem';

const RawTypeOverviewTag = props => {
  const { colorIndex, text, ...rest } = props;

  return (
    <div {...rest}>
      <div className="square" />
      <H4 $colorIndex={colorIndex} className="text-container">
        {text}
      </H4>
    </div>
  );
};

RawTypeOverviewTag.propTypes = {
  colorIndex: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

const divColor = css`
  ${({ colorIndex, theme }) =>
    colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#D9D9D9'}
`;

const TypeOverviewTag = styled(RawTypeOverviewTag)`
  display: inline-block;
  margin-right: 30px;
  -webkit-print-color-adjust: exact;
  h4 {
    display: inline-block;
  }
  .square {
    background-color: ${divColor};
    border-radius: 4px;
    display: inline-block;
    height: 25px;
    margin-right: 10px;
    vertical-align: top;
    width: 25px;
  }
`;

export default TypeOverviewTag;
