import types from './types';

const listDemoExamples = payload => ({
  type: types.DEMO_EXAMPLES_LIST,
  payload,
});

const listDemoExamplesSucceeded = payload => ({
  type: types.DEMO_EXAMPLES_LIST_SUCCEEDED,
  payload,
});

const listDemoExamplesFailed = ({ message }) => ({
  type: types.DEMO_EXAMPLES_LIST_FAILED,
  payload: { message },
});

export default {
  listDemoExamples,
  listDemoExamplesSucceeded,
  listDemoExamplesFailed,
};
