import api from '../api';

const fetchChannel = ({ id }) => api.get(`/api/channels/${id}`);

const createChannel = ({ product, ...channel }) =>
  api.post('/api/channels/', { product, ...channel });

const deleteChannel = ({ id }) => api.delete(`/api/channels/${id}`);

const listChannels = query => api.get('/api/channels/', { params: query });

const updateChannel = ({ id, ...channel }) => api.patch(`/api/channels/${id}/`, { ...channel });

export default {
  createChannel,
  deleteChannel,
  fetchChannel,
  listChannels,
  updateChannel,
};
