const ROLE_CLEAR_ERROR = 'bfb/ROLE/ROLE_CLEAR_ERROR';
const ROLES_LIST = 'bfb/ROLE/ROLES_LIST';
const ROLES_LIST_SUCCEEDED = 'bfb/ROLE/ROLES_LIST_SUCCEEDED';
const ROLES_LIST_FAILED = 'bfb/ROLE/ROLES_LIST_FAILED';
const ROLE_CREATE = 'bfb/ROLE/ROLE_CREATE';
const ROLE_CREATE_SUCCEEDED = 'bfb/ROLE/ROLE_CREATE_SUCCEEDED';
const ROLE_CREATE_FAILED = 'bfb/ROLE/ROLE_CREATE_FAILED';
const ROLE_DELETE = 'bfb/ROLE/ROLE_DELETE';
const ROLE_DELETE_SUCCEEDED = 'bfb/ROLE/ROLE_DELETE_SUCCEEDED';
const ROLE_DELETE_FAILED = 'bfb/ROLE/ROLE_DELETE_FAILED';
const ROLE_FETCH = 'bfb/ROLE/ROLE_FETCH';
const ROLE_FETCH_SUCCEEDED = 'bfb/ROLE/ROLE_FETCH_SUCCEEDED';
const ROLE_FETCH_FAILED = 'bfb/ROLE/ROLE_FETCH_FAILED';
const ROLE_UPDATE = 'bfb/ROLE/ROLE_UPDATE';
const ROLE_UPDATE_SUCCEEDED = 'bfb/ROLE/ROLE_UPDATE_SUCCEEDED';
const ROLE_UPDATE_FAILED = 'bfb/ROLE/ROLE_UPDATE_FAILED';

export default {
  ROLES_LIST,
  ROLES_LIST_SUCCEEDED,
  ROLES_LIST_FAILED,
  ROLE_CLEAR_ERROR,
  ROLE_CREATE,
  ROLE_CREATE_SUCCEEDED,
  ROLE_CREATE_FAILED,
  ROLE_DELETE,
  ROLE_DELETE_SUCCEEDED,
  ROLE_DELETE_FAILED,
  ROLE_FETCH,
  ROLE_FETCH_SUCCEEDED,
  ROLE_FETCH_FAILED,
  ROLE_UPDATE,
  ROLE_UPDATE_SUCCEEDED,
  ROLE_UPDATE_FAILED,
};
