import Pill from 'models/Pill';
import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { byTool: {}, byCategory: {} },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PILLS_FETCH:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.PILLS_FETCH_SUCCEEDED:
      if (action.payload.tool) {
        return reduxHelper.mergeState(
          state,
          reduxHelper.applyRequestInfo({
            data: {
              byCategory: state.data.byCategory,
              byTool: {
                ...state.data.byTool,
                [action.payload.tool]: action.payload.data.map(pill => new Pill(pill)),
              },
            },
          })
        );
      }
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            byCategory: {
              ...state.data.byCategory,
              [action.payload.category]: action.payload.data.map(pill => new Pill(pill)),
            },
            byTool: state.data.byTool,
          },
        })
      );
    case types.PILLS_FETCH_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
