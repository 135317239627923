import { createSelector } from 'reselect';
import { PROJECT_STATUSES, PROJECT_STATUS_INCUBATING } from 'models/Project';

const getState = state => state.project;

const getRawProjects = createSelector([getState], state => state.get('data'));

const getProjectsById = createSelector([getRawProjects], state => state.get('byId'));

const getProjects = createSelector([getProjectsById], state => state.valueSeq().toJS());

const getProjectsByProgram = createSelector([getProjectsById], state => {
  const stateById = state.valueSeq().toJS();
  const filteredState = stateById.reduce((group, project) => {
    const { program } = project;
    // eslint-disable-next-line no-param-reassign
    group[program] = group[program] ?? [];
    group[program].push(project);
    return group;
  }, {});

  return filteredState;
});

const getAdminProjects = createSelector([getRawProjects], state => state.get('admin'));

const getAdminProjectsCount = createSelector([getRawProjects], state => state.get('adminCount'));

const getExploreProjects = createSelector([getRawProjects], state => state.get('explore'));

const getExploreProjectsCount = createSelector([getRawProjects], state =>
  state.get('exploreCount')
);

const getAllProjectsByStatusCount = createSelector([getRawProjects], state => {
  const projectsByStatusCount = PROJECT_STATUSES.reduce((previousCount, status) => {
    const statusCount = state.get(`${status}Count`);
    return previousCount + statusCount;
  }, 0);
  return projectsByStatusCount;
});

const getProjectsBySpecificStatus = createSelector(
  [getRawProjects],
  state => status => state.get(status)
);

const projectsListLoading = createSelector([getState], state => state.get('loading'));

const getCurrentProject = createSelector([getRawProjects], state => state.get('current'));

const getProjectsBySpecificStatusCount = createSelector(
  [getRawProjects],
  state => status => state.get(`${status}Count`)
);

const getIncubatingProjects = createSelector([getRawProjects], state =>
  state.get(PROJECT_STATUS_INCUBATING)
);

const getIncubatingProjectsCount = createSelector([getRawProjects], state =>
  state.get(`${PROJECT_STATUS_INCUBATING}Count`)
);

const getSelectedProject = createSelector([getRawProjects], state => state.get('selectedProject'));

const updatePending = createSelector([getState], state => state.get('updatePending'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getRawProjects,
  getAdminProjects,
  getAdminProjectsCount,
  getExploreProjects,
  getExploreProjectsCount,
  getAllProjectsByStatusCount,
  getProjects,
  getProjectsByProgram,
  getProjectsById,
  getSelectedProject,
  updatePending,
  isFetching,
  getIncubatingProjects,
  getIncubatingProjectsCount,
  getProjectsBySpecificStatus,
  getProjectsBySpecificStatusCount,
  getCurrentProject,
  projectsListLoading,
};
