import React from 'react';

class APIComponent extends React.Component {
  // FIXME: React team discourages to use class inheritance

  state = {
    results: [], //eslint-disable-line
  };

  componentDidMount = () => {
    this.getResults().then(payload => {
      this.setState({ results: payload.data }); //eslint-disable-line
    });
  };

  /**
   * Function that fetchs results
   * Not implemented because other classes will inherit this class
   * @return {array} - Returns the results as an array
   */
  getResults = () => console.log('getResults not implemented'); //eslint-disable-line

  /**
   * Callback that returns the text for a result
   * Not implemented because other classes will inherit this class
   * @param {object} - Result from getResult function
   * @return {string} - Returns a string
   */
  getText = result => console.log('getText not implemented'); //eslint-disable-line
  getValue = result => result.id;
  getKey = result => this.getValue(result);
}

export default APIComponent;
