import React from 'react';

function IconCalendar() {
  return (
    <span className="anticon">
      <svg viewBox="222.371 34.991 18.979 20.945" height="20px" width="20px" fill="currentColor">
        <path
          d="M 238.74 55.936 L 224.981 55.936 C 223.54 55.934 222.373 54.767 222.371 53.326 L 222.371 39.567 C 222.373 38.126 223.54 36.959 224.981 36.957 L 238.74 36.957 C 240.18 36.959 241.348 38.126 241.35 39.567 L 241.35 53.326 C 241.348 54.767 240.18 55.934 238.74 55.936 Z M 224.981 38.252 C 224.252 38.253 223.661 38.844 223.66 39.573 L 223.66 53.326 C 223.661 54.055 224.252 54.646 224.981 54.647 L 238.74 54.647 C 239.469 54.646 240.059 54.055 240.06 53.326 L 240.06 39.567 C 240.059 38.838 239.469 38.247 238.74 38.246 L 224.981 38.252 Z"
          className="Shape"
        />
        <path
          d="M 235.792 40.212 C 235.436 40.212 235.148 39.923 235.148 39.567 L 235.148 35.636 C 235.148 35.28 235.436 34.991 235.792 34.991 C 236.148 34.991 236.437 35.28 236.437 35.636 L 236.437 39.567 C 236.437 39.923 236.148 40.212 235.792 40.212 Z"
          className="Shape"
        />
        <path
          d="M 227.93 40.212 C 227.574 40.212 227.285 39.923 227.285 39.567 L 227.285 35.636 C 227.285 35.28 227.574 34.991 227.93 34.991 C 228.286 34.991 228.575 35.28 228.575 35.636 L 228.575 39.567 C 228.575 39.923 228.286 40.212 227.93 40.212 Z"
          className="Shape"
        />
        <path
          d="M 240.705 44.143 L 223.021 44.143 C 222.665 44.143 222.376 43.854 222.376 43.498 C 222.376 43.142 222.665 42.854 223.021 42.854 L 240.705 42.854 C 241.061 42.854 241.35 43.142 241.35 43.498 C 241.35 43.854 241.061 44.143 240.705 44.143 Z"
          className="Shape"
        />
        <path
          d="M 227.93 48.312 C 227.443 48.312 227.048 47.917 227.048 47.429 C 227.048 46.942 227.443 46.547 227.93 46.547 C 228.417 46.547 228.813 46.942 228.813 47.429 C 228.81 47.915 228.416 48.309 227.93 48.312 Z"
          className="Shape"
        />
        <path
          d="M 231.865 48.312 C 231.378 48.312 230.983 47.917 230.983 47.429 C 230.983 46.942 231.378 46.547 231.865 46.547 C 232.352 46.547 232.747 46.942 232.747 47.429 C 232.744 47.915 232.351 48.309 231.865 48.312 Z"
          className="Shape"
        />
        <path
          d="M 235.8 48.312 C 235.442 48.313 235.12 48.099 234.982 47.77 C 234.844 47.44 234.919 47.06 235.171 46.807 C 235.423 46.554 235.803 46.477 236.133 46.614 C 236.463 46.75 236.678 47.072 236.678 47.429 C 236.676 47.914 236.285 48.308 235.8 48.312 Z"
          className="Shape"
        />
        <path
          d="M 235.8 52.252 C 235.442 52.253 235.12 52.039 234.982 51.71 C 234.844 51.38 234.919 51 235.171 50.747 C 235.423 50.494 235.803 50.418 236.133 50.554 C 236.463 50.69 236.678 51.012 236.678 51.37 C 236.676 51.855 236.285 52.248 235.8 52.252 Z"
          className="Shape"
        />
        <path
          d="M 231.878 52.252 C 231.39 52.252 230.995 51.857 230.995 51.37 C 230.995 50.882 231.39 50.487 231.878 50.487 C 232.365 50.487 232.76 50.882 232.76 51.37 C 232.757 51.856 232.364 52.249 231.878 52.252 Z"
          className="Shape"
        />
        <path
          d="M 227.936 52.252 C 227.448 52.252 227.053 51.857 227.053 51.37 C 227.053 50.882 227.448 50.487 227.936 50.487 C 228.423 50.487 228.818 50.882 228.818 51.37 C 228.816 51.856 228.422 52.25 227.936 52.252 Z"
          className="Shape"
        />
      </svg>
    </span>
  );
}

export default IconCalendar;
