import { createSelector } from 'reselect';

const getState = state => state.outcome;

const getRawOutcomes = createSelector([getState], state => state.get('data'));

const getOutcomesBySegment = createSelector([getRawOutcomes], state => state.get('bySegment'));

const getOutcomesById = createSelector([getRawOutcomes], state => state.get('byId'));

const getOutcomes = createSelector([getOutcomesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getOutcomes,
  getOutcomesBySegment,
  getOutcomesById,
  getErrorMessage,
};
