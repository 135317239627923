import { Record } from 'immutable';
import ContentType from '../ContentType';

const ChainRecord = new Record({
  id: null,
  contentType: ContentType.CHAIN,
  name: '',
  channels: [],
  product: null,
  comments: [],
  selected: false,
  segments: [],
});

class Chain extends ChainRecord {
  static contentType = ContentType.CHAIN;
  get id() {
    return this.get('id');
  }
}

export default Chain;
