// Deprecated
// Please refer to src/services/apiSlices/entrepreneur.js for more modern approach

import api from './api';

const editMember = memberData =>
  api.patch(`/api/members/${memberData.id}/`, {
    ...memberData,
  });

const getMember = memberData =>
  api.get(`/api/members/${memberData.id}/`, {
    ...memberData,
  });

const getPublicMember = memberData =>
  api.get(`/api/public_members/${memberData.id}/`, {
    ...memberData,
  });

export default {
  editMember,
  getMember,
  getPublicMember,
};
