import { createSelector } from 'reselect';

const getState = state => state.riskCategory;

const getRawRiskCategory = createSelector([getState], state => state.get('data'));

const getRiskCategoriesById = createSelector([getRawRiskCategory], state => state.get('byId'));

const getRiskCategories = createSelector([getRawRiskCategory], state => state.get('flat'));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getRiskCategories,
  getRiskCategoriesById,
  getErrorMessage,
};
