import { types } from 'store/modules/mentor';

const mentorFetch = ({ mentorData }) => ({
  type: types.MENTOR_FETCH,
  payload: mentorData,
});

const mentorFetchSucceeded = ({ mentorData }) => ({
  type: types.MENTOR_FETCH_SUCCEEDED,
  payload: mentorData,
});

const mentorFetchFailed = ({ message }) => ({
  type: types.MENTOR_FETCH_FAILED,
  payload: message,
});

const mentorEdit = ({ id, ...mentorData }, callback) => ({
  type: types.MENTOR_EDIT,
  payload: { id, ...mentorData },
  callback,
});

const mentorEditSucceeded = data => ({
  type: types.MENTOR_EDIT_SUCCEEDED,
  payload: data,
});

const mentorEditFailed = ({ message }) => ({
  type: types.MENTOR_EDIT_FAILED,
  payload: message,
});

const mentorPublicFetch = ({ mentorData }) => ({
  type: types.MENTOR_PUBLIC_FETCH,
  payload: mentorData,
});

const mentorPublicFetchSucceeded = action => ({
  type: types.MENTOR_PUBLIC_FETCH_SUCCEEDED,
  payload: action,
});

const mentorPublicFetchFailed = ({ message }) => ({
  type: types.MENTOR_PUBLIC_FETCH_FAILED,
  payload: message,
});

export default {
  mentorFetch,
  mentorFetchSucceeded,
  mentorFetchFailed,
  mentorEdit,
  mentorEditSucceeded,
  mentorEditFailed,
  mentorPublicFetch,
  mentorPublicFetchSucceeded,
  mentorPublicFetchFailed,
};
