import { call, put, all, takeLatest } from 'redux-saga/effects'; //eslint-disable-line

import { actions, types } from 'store/modules/statusSummary';
import serviceSummary from 'services/statusSummary'; //eslint-disable-line

export function* summaryFetch() {
  try {
    const summary = yield call(serviceSummary.fetchSummary);
    yield put(actions.summaryFetchSucceeded({ summaryData: summary.data }));
  } catch (e) {
    yield put(actions.summaryFetchFailed({ message: e.message }));
  }
}

function* summarySaga() {
  yield all([takeLatest(types.SUMMARY_FETCH, summaryFetch)]);
}

export default summarySaga;
