import { Record } from 'immutable';
import ContentType from '../ContentType';

const ProductBusinessModelRecord = new Record({
  id: null,
  contentType: ContentType.PRODUCTBUSINESSMODEL,
  product: null,
  selected: null,
  summary: null,
  business_model: null,
  segments: null,
  comments: null,
});

class ProductBusinessModel extends ProductBusinessModelRecord {
  static contentType = ContentType.PRODUCTBUSINESSMODEL;
  get id() {
    return this.get('id');
  }
}

export default ProductBusinessModel;
