import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: {
      showDrawer: false,
    },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PARROBOT_TOGGLE_SHOW:
      return reduxHelper.mergeState(
        reduxHelper.applyReceiveInfo({
          data: {
            showDrawer: action.payload ? action.payload.show : !state.data.showDrawer,
          },
        })
      );
    default:
      return state;
  }
}
