import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import serviceMonthlyCogs from 'services/FinancialProjections/monthlyCogs';
import MonthlyCogs from 'models/FinancialProjections/MonthlyCogs';
import { actions, types } from 'store/modules/FinancialProjections/monthlyCogs';
import { types as commentTypes } from 'store/modules/comment';

export function* updateMonthlyCogs(action) {
  const { scenario, id, ...updatedMonthlyCogs } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceMonthlyCogs.updateMonthlyCogs, {
      id,
      ...updatedMonthlyCogs,
    });
    yield put(
      actions.updateMonthlyCogsSucceeded({
        ...updatedMonthlyCogs, // To keep the year property.
        ...updated.data, // New data.
        scenario, // And to keep the scenario property.
      })
    );
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateMonthlyCogsFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === MonthlyCogs.contentType) {
    yield put(
      actions.fetchMonthlyCogs({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* monthlyCogsSaga() {
  yield all([
    takeEvery(types.MONTHLY_COGS_UPDATE, updateMonthlyCogs),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default monthlyCogsSaga;
