import api from '../api';

const fetchOrganizationalRole = ({ id }) => api.get(`/api/organizational-roles/${id}/`);

const createOrganizationalRole = organizationalRole =>
  api.post('/api/organizational-roles/', organizationalRole);

const deleteOrganizationalRole = ({ id }) => api.delete(`/api/organizational-roles/${id}/`);

const listOrganizationalRoles = query => api.get('/api/organizational-roles/', { params: query });

const updateOrganizationalRole = ({ id, ...organizationalRole }) =>
  api.patch(`/api/organizational-roles/${id}/`, organizationalRole);

export default {
  createOrganizationalRole,
  deleteOrganizationalRole,
  fetchOrganizationalRole,
  listOrganizationalRoles,
  updateOrganizationalRole,
};
