import api from '../api';

const fetchKeyResult = ({ id }) => api.get(`/api/key-results/${id}/`);

const createKeyResult = keyResult => api.post('/api/key-results/', keyResult);

const deleteKeyResult = ({ id }) => api.delete(`/api/key-results/${id}/`);

const listKeyResults = query => api.get('/api/key-results/', { params: query });

const updateKeyResult = ({ id, ...keyResult }) => api.patch(`/api/key-results/${id}/`, keyResult);

export default {
  createKeyResult,
  deleteKeyResult,
  fetchKeyResult,
  listKeyResults,
  updateKeyResult,
};
