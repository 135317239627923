import { createSelector } from 'reselect';

const getState = state => state.behavior;

const getRawBehavior = createSelector([getState], state => state.get('data'));

const getBehaviorsById = createSelector([getRawBehavior], state => state.get('byId'));

const getBehaviorsByProject = createSelector([getRawBehavior], state => state.get('byProject'));

const getBehaviors = createSelector([getBehaviorsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getBehaviors,
  getBehaviorsById,
  getBehaviorsByProject,
  getErrorMessage,
};
