import types from './types';

const clearError = () => ({
  type: types.EXPENSE_CLEAR_ERROR,
});

const createExpense = ({ scenario, ...expense }, callback) => ({
  type: types.EXPENSE_CREATE,
  payload: { scenario, ...expense },
  callback,
});

const createExpenseSucceeded = ({ scenario, id, ...expense }) => ({
  type: types.EXPENSE_CREATE_SUCCEEDED,
  payload: { scenario, id, ...expense },
});

const createExpenseFailed = ({ message }) => ({
  type: types.EXPENSE_CREATE_FAILED,
  payload: { message },
});

const deleteExpense = ({ scenario, expenseId }) => ({
  type: types.EXPENSE_DELETE,
  payload: { scenario, expenseId },
});

const deleteExpenseSucceeded = ({ scenario, expenseId }) => ({
  type: types.EXPENSE_DELETE_SUCCEEDED,
  payload: { scenario, id: expenseId },
});

const deleteExpenseFailed = ({ message }) => ({
  type: types.EXPENSE_DELETE_FAILED,
  payload: message,
});

const fetchExpense = ({ scenario, id }) => ({
  type: types.EXPENSE_FETCH,
  payload: { scenario, id },
});

const fetchExpenseSucceeded = ({ ...expense }) => ({
  type: types.EXPENSE_FETCH_SUCCEEDED,
  payload: { ...expense },
});

const fetchExpenseFailed = ({ message }) => ({
  type: types.EXPENSE_FETCH_FAILED,
  payload: { message },
});

const listExpenses = ({ scenario }) => ({
  type: types.EXPENSES_LIST,
  payload: { scenario },
});

const listExpensesSucceeded = payload => ({
  type: types.EXPENSES_LIST_SUCCEEDED,
  payload,
});

const listExpensesFailed = ({ message }) => ({
  type: types.EXPENSES_LIST_FAILED,
  payload: { message },
});

const updateExpense = ({ scenario, expenseId, ...updatedExpense }, callback) => ({
  type: types.EXPENSE_UPDATE,
  payload: { scenario, expenseId, ...updatedExpense },
  callback,
});

const updateExpenseSucceeded = ({ ...expense }) => ({
  type: types.EXPENSE_UPDATE_SUCCEEDED,
  payload: { ...expense },
});

const updateExpenseFailed = ({ message }) => ({
  type: types.EXPENSE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createExpense,
  createExpenseFailed,
  createExpenseSucceeded,
  deleteExpense,
  deleteExpenseFailed,
  deleteExpenseSucceeded,
  fetchExpense,
  fetchExpenseFailed,
  fetchExpenseSucceeded,
  listExpenses,
  listExpensesFailed,
  listExpensesSucceeded,
  updateExpense,
  updateExpenseFailed,
  updateExpenseSucceeded,
};
