import types from './types';

const clearError = () => ({
  type: types.BENEFIT_CLEAR_ERROR,
});

const createBenefit = ({ project, product, segment, ...benefit }) => ({
  type: types.BENEFIT_CREATE,
  payload: { project, product, segment, ...benefit },
});

const createBenefitSucceeded = ({ ...benefit }) => ({
  type: types.BENEFIT_CREATE_SUCCEEDED,
  payload: { ...benefit },
});

const createBenefitFailed = ({ message }) => ({
  type: types.BENEFIT_CREATE_FAILED,
  payload: { message },
});

const deleteBenefit = ({ project, product, segment, benefitId }) => ({
  type: types.BENEFIT_DELETE,
  payload: { project, product, segment, benefitId },
});

const deleteBenefitSucceeded = ({ id, ...benefit }) => ({
  type: types.BENEFIT_DELETE_SUCCEEDED,
  payload: { id, ...benefit },
});

const deleteBenefitFailed = ({ message }) => ({
  type: types.BENEFIT_DELETE_FAILED,
  payload: message,
});

const fetchBenefit = ({ project, product, segment, id }) => ({
  type: types.BENEFIT_FETCH,
  payload: { project, product, segment, id },
});

const fetchBenefitSucceeded = ({ ...benefit }) => ({
  type: types.BENEFIT_FETCH_SUCCEEDED,
  payload: { ...benefit },
});

const fetchBenefitFailed = ({ message }) => ({
  type: types.BENEFIT_FETCH_FAILED,
  payload: { message },
});

const listBenefits = ({ project, product, segment }) => ({
  type: types.BENEFITS_LIST,
  payload: { project, product, segment },
});

const listBenefitsSucceeded = payload => ({
  type: types.BENEFITS_LIST_SUCCEEDED,
  payload,
});

const listBenefitsFailed = ({ message }) => ({
  type: types.BENEFITS_LIST_FAILED,
  payload: { message },
});

const updateBenefit = ({ project, product, segment, benefitId, ...benefit }) => ({
  type: types.BENEFIT_UPDATE,
  payload: { project, product, segment, benefitId, ...benefit },
});

const updateBenefitSucceeded = ({ ...benefit }) => ({
  type: types.BENEFIT_UPDATE_SUCCEEDED,
  payload: { ...benefit },
});

const updateBenefitFailed = ({ message }) => ({
  type: types.BENEFIT_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createBenefit,
  createBenefitFailed,
  createBenefitSucceeded,
  deleteBenefit,
  deleteBenefitFailed,
  deleteBenefitSucceeded,
  fetchBenefit,
  fetchBenefitFailed,
  fetchBenefitSucceeded,
  listBenefits,
  listBenefitsFailed,
  listBenefitsSucceeded,
  updateBenefit,
  updateBenefitFailed,
  updateBenefitSucceeded,
};
