import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Feature from 'models/CompetitionMap/Feature';
import serviceFeature from 'services/CompetitionMap/feature';
import types from './types';
import actions from './actions';

export function* createFeature(action) {
  const { project, product, ...feature } = action.payload;
  try {
    const response = yield call(serviceFeature.createFeature, {
      project,
      product,
      ...feature,
    });
    yield put(actions.createFeatureSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(
      actions.createFeatureFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteFeature(action) {
  const { project, product, featureId } = action.payload;
  try {
    yield call(serviceFeature.deleteFeature, { project, product, featureId });
    yield put(actions.deleteFeatureSucceeded({ featureId, product }));
  } catch (e) {
    yield put(
      actions.deleteFeatureFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchFeature(action) {
  const { project, product, id } = action.payload;
  try {
    const response = yield call(serviceFeature.fetchFeature, {
      project,
      product,
      id,
    });
    yield put(actions.fetchFeatureSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(actions.fetchFeatureFailed({ message: e.message }));
  }
}

export function* listFeatures(action) {
  const { project, product } = action.payload;
  try {
    const features = yield call(serviceFeature.listFeatures, {
      project,
      product,
    });
    yield put(actions.listFeaturesSucceeded({ results: features.data, product }));
  } catch (e) {
    yield put(actions.listFeaturesFailed({ message: e.message }));
  }
}

export function* updateFeature(action) {
  const { project, product, featureId, ...updatedFeature } = action.payload;
  try {
    const updated = yield call(serviceFeature.updateFeature, {
      project,
      product,
      featureId,
      ...updatedFeature,
    });
    yield put(actions.updateFeatureSucceeded({ ...updated.data, product }));
  } catch (e) {
    yield put(
      actions.updateFeatureFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Feature.contentType) {
    yield put(
      actions.fetchFeature({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* featureSaga() {
  yield all([
    takeLatest(types.FEATURE_CREATE, createFeature),
    takeLatest(types.FEATURE_DELETE, deleteFeature),
    takeLatest(types.FEATURE_FETCH, fetchFeature),
    takeLatest(types.FEATURE_UPDATE, updateFeature),
    takeEvery(types.FEATURES_LIST, listFeatures),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default featureSaga;
