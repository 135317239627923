import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';

const Link = styled(ReactLink)`
  &&& {
    color: ${props => props.theme.linkColor};
  }
`;

export default Link;
