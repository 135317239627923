import { Record } from 'immutable';
import ContentType from '../ContentType';

const CompetitorRecord = new Record({
  id: null,
  contentType: ContentType.COMPETITOR,
  name: '',
  product: null,
  outcome: null,
  selected: false,
  comments: null,
  myself: false,
  created: null,
  updated: null,
  unit_price_cents: null,
  unit_name: null,
});

class Competitor extends CompetitorRecord {
  static contentType = ContentType.COMPETITOR;

  get id() {
    return this.get('id');
  }
}

export default Competitor;
