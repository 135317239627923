import priceTypes from '../financialProduct/types';
import monthlyMonthlyPriceTypes from './types';

const updateMonthlyPrice = ({ scenario, ...updatedMonthlyPrice }, callback) => ({
  type: monthlyMonthlyPriceTypes.MONTHLY_PRICE_UPDATE,
  payload: { scenario, ...updatedMonthlyPrice },
  callback,
});

const updateMonthlyPriceSucceeded = payload => ({
  type: priceTypes.MONTHLY_PRICE_UPDATE_SUCCEEDED,
  payload,
});

const updateMonthlyPriceFailed = ({ message }) => ({
  type: priceTypes.MONTHLY_PRICE_UPDATE_FAILED,
  payload: { message },
});

export default {
  updateMonthlyPrice,
  updateMonthlyPriceFailed,
  updateMonthlyPriceSucceeded,
};
