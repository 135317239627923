const DEEP_DIVE_LINKS_LIST = 'bfb/DeepDive/DEEP_DIVE_LINKS_LIST';
const DEEP_DIVE_LINKS_LIST_SUCCEEDED = 'bfb/DeepDive/DEEP_DIVE_LINKS_LIST_SUCCEEDED';
const DEEP_DIVE_LINKS_LIST_FAILED = 'bfb/DeepDive/DEEP_DIVE_LINKS_LIST_FAILED';
const DEEP_DIVE_LINK_CREATE = 'bfb/DeepDive/DEEP_DIVE_LINK_CREATE';
const DEEP_DIVE_LINK_CREATE_SUCCEEDED = 'bfb/DeepDive/DEEP_DIVE_LINK_CREATE_SUCCEEDED';
const DEEP_DIVE_LINK_CREATE_FAILED = 'bfb/DeepDive/DEEP_DIVE_LINK_CREATE_FAILED';
const DEEP_DIVE_LINK_DELETE = 'bfb/DeepDive/DEEP_DIVE_LINK_DELETE';
const DEEP_DIVE_LINK_DELETE_SUCCEEDED = 'bfb/DeepDive/DEEP_DIVE_LINK_DELETE_SUCCEEDED';
const DEEP_DIVE_LINK_DELETE_FAILED = 'bfb/DeepDive/DEEP_DIVE_LINK_DELETE_FAILED';
const DEEP_DIVE_LINK_UPDATE = 'bfb/DeepDive/DEEP_DIVE_LINK_UPDATE';
const DEEP_DIVE_LINK_UPDATE_SUCCEEDED = 'bfb/DeepDive/DEEP_DIVE_LINK_UPDATE_SUCCEEDED';
const DEEP_DIVE_LINK_UPDATE_FAILED = 'bfb/DeepDive/DEEP_DIVE_LINK_UPDATE_FAILED';

export default {
  DEEP_DIVE_LINKS_LIST,
  DEEP_DIVE_LINKS_LIST_SUCCEEDED,
  DEEP_DIVE_LINKS_LIST_FAILED,
  DEEP_DIVE_LINK_CREATE,
  DEEP_DIVE_LINK_CREATE_SUCCEEDED,
  DEEP_DIVE_LINK_CREATE_FAILED,
  DEEP_DIVE_LINK_DELETE,
  DEEP_DIVE_LINK_DELETE_SUCCEEDED,
  DEEP_DIVE_LINK_DELETE_FAILED,
  DEEP_DIVE_LINK_UPDATE,
  DEEP_DIVE_LINK_UPDATE_SUCCEEDED,
  DEEP_DIVE_LINK_UPDATE_FAILED,
};
