import { createSelector } from 'reselect';

const getState = state => state.partner;

const getRawPartners = createSelector([getState], state => state.get('data'));

const getPartnersById = createSelector([getRawPartners], state => state.get('byId'));

const getPartnersByProduct = createSelector([getRawPartners], state => state.get('byProduct'));

const getPartners = createSelector([getPartnersById], state => state.valueSeq().toJS());
const getPartnersByType = type =>
  createSelector([getPartners], state => state.filter(partner => partner.type === type));

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getPartners,
  getPartnersByProduct,
  getPartnersById,
  getErrorMessage,
  getPartnersByType,
};
