import api from './api';

const fetchProgram = ({ id }) => api.get(`/api/programs/${id}/`);
const fetchProgramManagementProgram = ({ id }) => api.get(`/api/management-panel/programs/${id}/`);
const updateProgramManagementProgram = payload =>
  api.patch(`/api/management-panel/programs/${payload.id}/`, { ...payload });

const searchPrograms = ({ query }) => api.get('/api/programs/', { params: { search: query } });
const fetchPublicProgram = ({ id }) => api.get(`/api/public_programs/${id}/`);
const listOrganizationPrograms = ({ organization }) =>
  api.get(`/api/organizations/${organization}/programs/`);
const listPublicPrograms = () => api.get('/api/public_programs/');
const listProgramsSections = () => api.get('/api/programs-dashboard/');
const listPrograms = () => api.get('/api/programs/', { params: { limit: 500 } }); // Overriding default BE limit (25)

// TODO: This endpoint is not used anywhere, check if still needed or should to be refactored
const updateProgram = program =>
  api.patch(`/api/programs/${program.id}/`, {
    ...program,
  });

const config = {
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
};

const updateProgramByOrganization = (program = {}, organization = {}) => {
  const formData = new FormData();
  Object.keys(program).forEach(key => {
    if (program[key]) formData.append(key, program[key]); // Skipping undefined values
  });
  return api.patch(
    `/api/organizations/${organization.id}/programs/${program.id}/`,
    formData,
    config
  );
};

const uploadProgramFile = ({ id, field, file }) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append(field, file);

  return api.patch(`/api/management-panel/programs/${id}/`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export default {
  fetchProgram,
  fetchProgramManagementProgram,
  fetchPublicProgram,
  searchPrograms,
  listPrograms,
  listOrganizationPrograms,
  listProgramsSections,
  listPublicPrograms,
  updateProgram,
  updateProgramByOrganization,
  updateProgramManagementProgram,
  uploadProgramFile,
};
