// Libraries
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import LazyLoad, { forceCheck } from 'react-lazyload';
import styled from 'styled-components';

const LazyImage = props => {
  const {
    offset,
    onLoad,
    className: classNameProp,
    width,
    height,
    objectFit,
    alt,
    src,
    draggable,
    style,
  } = props;
  const [loadingClassName, setLoadingClassName] = useState('loading');

  const lazyImageStyleProps = {
    width,
    height,
    objectFit,
    ...style,
  };

  useEffect(() => {
    forceCheck();
  }, []);

  const onLoadHandler = event => {
    if (onLoad) {
      onLoad(event);
    }
    setLoadingClassName('loaded');
  };

  const className = `${classNameProp} ${loadingClassName}`;

  return (
    <LazyLoad offset={offset} debounce={false} height={height} once>
      <IMG
        style={lazyImageStyleProps}
        className={className}
        onLoad={onLoadHandler}
        alt={alt}
        src={src}
        draggable={draggable}
      />
    </LazyLoad>
  );
};

const IMG = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
  object-fit: ${props => props.objectFit};

  &.loading {
    opacity: 0;
    visibility: hidden;
  }

  &.loaded {
    opacity: 0;
    visibility: visible;
    animation: fade-in 200ms ease-in-out 0ms forwards;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectFit: PropTypes.string,
  offset: PropTypes.number,
  alt: PropTypes.string,
  draggable: PropTypes.bool,
};

LazyImage.defaultProps = {
  onLoad: undefined,
  className: '',
  draggable: false,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  offset: 0,
  alt: '',
  style: {},
};

export default LazyImage;
