import { Record } from 'immutable';

const MemberSurveysRecord = new Record({
  survey: null,
  response: [],
});

class MemberSurveys extends MemberSurveysRecord {
  get id() {
    return this.get('survey');
  }
}

export default MemberSurveys;
