const INVESTMENT_CLEAR_ERROR = 'bfb/financialProjections/INVESTMENT_CLEAR_ERROR';
const INVESTMENTS_LIST = 'bfb/financialProjections/INVESTMENTS_LIST';
const INVESTMENTS_LIST_SUCCEEDED = 'bfb/financialProjections/INVESTMENTS_LIST_SUCCEEDED';
const INVESTMENTS_LIST_FAILED = 'bfb/financialProjections/INVESTMENTS_LIST_FAILED';
const INVESTMENT_CREATE = 'bfb/financialProjections/INVESTMENT_CREATE';
const INVESTMENT_CREATE_SUCCEEDED = 'bfb/financialProjections/INVESTMENT_CREATE_SUCCEEDED';
const INVESTMENT_CREATE_FAILED = 'bfb/financialProjections/INVESTMENT_CREATE_FAILED';
const INVESTMENT_DELETE = 'bfb/financialProjections/INVESTMENT_DELETE';
const INVESTMENT_DELETE_SUCCEEDED = 'bfb/financialProjections/INVESTMENT_DELETE_SUCCEEDED';
const INVESTMENT_DELETE_FAILED = 'bfb/financialProjections/INVESTMENT_DELETE_FAILED';
const INVESTMENT_FETCH = 'bfb/financialProjections/INVESTMENT_FETCH';
const INVESTMENT_FETCH_SUCCEEDED = 'bfb/financialProjections/INVESTMENT_FETCH_SUCCEEDED';
const INVESTMENT_FETCH_FAILED = 'bfb/financialProjections/INVESTMENT_FETCH_FAILED';
const INVESTMENT_UPDATE = 'bfb/financialProjections/INVESTMENT_UPDATE';
const INVESTMENT_UPDATE_SUCCEEDED = 'bfb/financialProjections/INVESTMENT_UPDATE_SUCCEEDED';
const INVESTMENT_UPDATE_FAILED = 'bfb/financialProjections/INVESTMENT_UPDATE_FAILED';

export default {
  INVESTMENTS_LIST,
  INVESTMENTS_LIST_SUCCEEDED,
  INVESTMENTS_LIST_FAILED,
  INVESTMENT_CLEAR_ERROR,
  INVESTMENT_CREATE,
  INVESTMENT_CREATE_SUCCEEDED,
  INVESTMENT_CREATE_FAILED,
  INVESTMENT_DELETE,
  INVESTMENT_DELETE_SUCCEEDED,
  INVESTMENT_DELETE_FAILED,
  INVESTMENT_FETCH,
  INVESTMENT_FETCH_SUCCEEDED,
  INVESTMENT_FETCH_FAILED,
  INVESTMENT_UPDATE,
  INVESTMENT_UPDATE_SUCCEEDED,
  INVESTMENT_UPDATE_FAILED,
};
