/* WARNING: THIS COMPONENT IS VERY CUSTOMIZED TO BE USED IN EXECUTIVE SUMMARY
  It cleans a lot of styles and elements that we don't want the user to add in ExecSumm.
  If you want to reuse this component, please decouple the ExecSumm formatting first
*/

// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

// Components
import { ToolCommentIcon } from 'components/Tools';

const textStylesPrefixes = ['bgcolor', 'color', 'font'];
const isTextStyle = style => textStylesPrefixes.find(prefix => style.startsWith(prefix));

export const editorStateFromHTML = html =>
  EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(html)));

function TextEditor(props) {
  const {
    className,
    editorStyle,
    handleCommentClick,
    highlightComments,
    id,
    initialHTML,
    max,
    onBlur,
    placeholder,
    showComments,
    showWarningAlert,
  } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  React.useEffect(() => {
    setEditorState(editorStateFromHTML(initialHTML || ''));
  }, [initialHTML]);

  const currentText = editorState.getCurrentContent().getPlainText('');
  const inputLength = currentText.length;
  const myCommentIconId = `text-editor-component-comment-icon-${id}`;

  const handleOnChange = setEditorState;

  const handleBlur = () => {
    if (onBlur) {
      // Executive Summary formatting: Remove text styles and header elements
      const raw = convertToRaw(editorState.getCurrentContent());
      const contentHTML = draftToHtml({
        ...raw,
        blocks: raw.blocks.map(block => ({
          ...block,
          type: block.type.startsWith('header') ? 'unstyled' : block.type,
          inlineStyleRanges: block.inlineStyleRanges.filter(inline => !isTextStyle(inline.style)),
        })),
      });
      onBlur(contentHTML);
    }
  };

  const handleMax = () => {
    if (max) {
      if (inputLength > max) {
        showWarningAlert(true);
      } else {
        showWarningAlert(false);
      }
    }
  };

  return (
    <StyledTextEditor style={{ display: 'flex' }} className={className}>
      <div style={{ flex: 2 }}>
        <Editor
          editorStyle={editorStyle}
          editorState={editorState}
          editorClassName="editor-class"
          placeholder={placeholder}
          onBlur={handleBlur}
          onChange={handleMax}
          onEditorStateChange={handleOnChange}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapper-class"
          toolbar={{
            options: ['inline', 'list', 'link', 'history'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
            },
            list: { options: ['unordered', 'ordered'] },
            link: { inDropdown: false },
            history: { inDropdown: false },
          }}
        />
        {max && (
          <span
            style={{
              float: 'right',
              fontSize: '14px',
              color: `${inputLength > max ? '#F25A5A' : '#999999'}`,
            }}
          >
            {`${inputLength}/${max}`}
          </span>
        )}
      </div>
      {/* ICONS */}
      {showComments && (
        <aside>
          <i
            style={{
              paddingLeft: '10px',
            }}
            id={myCommentIconId}
          >
            <ToolCommentIcon active={highlightComments} onClick={handleCommentClick} />
          </i>
        </aside>
      )}
    </StyledTextEditor>
  );
}

const StyledTextEditor = styled.div`
  .rdw-editor-toolbar {
    height: auto;
  }
  .editor-class {
    overflow: auto;
    border: 1px solid #f1f1f1;
    padding: 0 5px;
    border-radius: 4px;
  }

  .rdw-editor-main {
    height: auto;
  }
`;

TextEditor.propTypes = {
  className: PropTypes.string,
  editorStyle: PropTypes.instanceOf(Object),
  handleCommentClick: PropTypes.func,
  highlightComments: PropTypes.bool,
  id: PropTypes.number,
  initialHTML: PropTypes.string,
  max: PropTypes.number.isRequired,
  onBlur: PropTypes.func,
  showComments: PropTypes.bool,
  showWarningAlert: PropTypes.func,
  placeholder: PropTypes.string,
};

TextEditor.defaultProps = {
  className: '',
  editorStyle: undefined,
  handleCommentClick: null,
  highlightComments: false,
  id: undefined,
  initialHTML: '',
  onBlur: null,
  showComments: false,
  showWarningAlert: null,
  placeholder: '',
};

export default TextEditor;
