import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/BusinessModelsMarketing/businessModel';
import serviceBusinessModel from 'services/BusinessModelMarketing/businessModel';
import ProductBusinessModel from 'models/BusinessModelsMarketing/ProductBusinessModel';
import { types as commentTypes } from 'store/modules/comment';

export function* listBusinessModels(action) {
  const { product } = action.payload;
  try {
    const businessModels = yield call(serviceBusinessModel.listBusinessModels, {
      product,
    });
    yield put(
      actions.listBusinessModelsSucceeded({
        results: businessModels.data,
        product,
      })
    );
  } catch (e) {
    yield put(actions.listBusinessModelsFailed({ message: e.message }));
  }
}

export function* listProductBusinessModels(action) {
  const { product } = action.payload;
  try {
    const businessModels = yield call(serviceBusinessModel.listProductBusinessModels, { product });
    yield put(
      actions.listProductBusinessModelsSucceeded({
        results: businessModels.data,
        product,
      })
    );
  } catch (e) {
    yield put(actions.listProductBusinessModelsFailed({ message: e.message }));
  }
}

export function* fetchProductBusinessModel(action) {
  const { product, id } = action.payload;
  try {
    const response = yield call(serviceBusinessModel.fetchProductBusinessModel, { product, id });
    yield put(actions.fetchProductBusinessModelSucceeded({ ...response.data, product }));
  } catch (e) {
    yield put(actions.fetchProductBusinessModelFailed({ message: e.message }));
  }
}

export function* createProductBusinessModel(action) {
  const { product, businessModel, selected } = action.payload;
  try {
    const response = yield call(serviceBusinessModel.createProductBusinessModel, {
      product,
      businessModel,
      selected,
    });
    yield put(actions.createProductBusinessModelSucceeded({ product, ...response.data }));
  } catch (e) {
    yield put(
      actions.createProductBusinessModelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateProductBusinessModel(action) {
  const { product, ...businessModel } = action.payload;
  try {
    const updated = yield call(serviceBusinessModel.updateProductBusinessModel, {
      product,
      ...businessModel,
    });
    yield put(actions.updateProductBusinessModelSucceeded({ ...updated.data, product }));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (e) {
    yield put(
      actions.updateProductBusinessModelFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === ProductBusinessModel.contentType) {
    yield put(
      actions.fetchProductBusinessModel({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* businessModelSaga() {
  yield all([
    takeEvery(types.PRODUCT_BUSINESS_MODEL_CREATE, createProductBusinessModel),
    takeEvery(types.PRODUCT_BUSINESS_MODEL_FETCH, fetchProductBusinessModel),
    takeEvery(types.PRODUCT_BUSINESS_MODEL_UPDATE, updateProductBusinessModel),
    takeEvery(types.PRODUCT_BUSINESS_MODELS_LIST, listProductBusinessModels),
    takeLatest(types.BUSINESS_MODELS_LIST, listBusinessModels),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default businessModelSaga;
