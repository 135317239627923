import { createSelector } from 'reselect';

const getState = state => state.deepdive;

const getData = createSelector([getState], state => state.data);

const getDeepDiveByStep = createSelector([getData], state => state.byStep);

const getDeepDiveByProgram = createSelector([getData], state => state.byProgram);

const getDeepDiveById = createSelector([getData], state => state.byId);

const getDeepDivesCount = createSelector([getData], state => state.count);
export default {
  getDeepDiveById,
  getDeepDiveByProgram,
  getDeepDiveByStep,
  getDeepDivesCount,
};
