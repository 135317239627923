import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import AreasOfExpertise from 'models/AreasOfExpertise';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    areasOfExpertiseData: [],
    errorMessage: '',
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.AREASOFEXPERTISE_FETCH:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.AREASOFEXPERTISE_FETCH_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          areasOfExpertiseData: action.payload.map(area => new AreasOfExpertise(area)),
        })
      );
    case types.AREASOFEXPERTISE_FETCH_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
