/**
 * @param {File} image - Image File Object
 */
function getCroppedImg(file) {
  const img = new Image();

  const promise = new Promise(resolve => {
    img.onload = () => {
      const sourceHeight = img.height;
      const sourceWidth = img.width;

      const canvas = document.createElement('canvas');

      // Get source square
      const limit = Math.min(sourceHeight, sourceWidth);
      const sourceX = (sourceWidth - limit) / 2;
      const sourceY = (sourceHeight - limit) / 2;

      canvas.width = limit;
      canvas.height = limit;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, sourceX, sourceY, limit, limit, 0, 0, limit, limit);
      canvas.toBlob(blob => {
        resolve(blob);
      }, 'image/png');
    };
  });
  img.src = URL.createObjectURL(file, file.name);

  return promise;
}

export default getCroppedImg;
