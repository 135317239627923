import { createSelector } from 'reselect';

const getState = state => state.programMembers;

const getRawProgramMembers = createSelector([getState], state => state.get('data'));

const getProgramMembersCount = createSelector([getRawProgramMembers], state => state.get('count'));

const getProgramMembers = createSelector([getRawProgramMembers], state =>
  state.get('admin').valueSeq().toJS()
);

const getIsFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getProgramMembers,
  getProgramMembersCount,
  getIsFetching,
};
