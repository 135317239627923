import types from './types';

const toggleShow = () => ({
  type: types.PARROBOT_TOGGLE_SHOW,
});

const showDrawer = () => ({
  type: types.PARROBOT_TOGGLE_SHOW,
  payload: { show: true },
});

const hideDrawer = () => ({
  type: types.PARROBOT_TOGGLE_SHOW,
  payload: { show: false },
});

export default {
  hideDrawer,
  showDrawer,
  toggleShow,
};
