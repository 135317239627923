import { createSelector } from 'reselect';

const getState = state => state.funding;

const getRawFunding = createSelector([getState], state => state.get('data'));

const getFundingsById = createSelector([getRawFunding], state => state.get('byId'));

const getFundingsByScenario = createSelector([getRawFunding], state => state.get('byScenario'));

const getTotalByScenario = createSelector([getRawFunding], state => state.get('totalByScenario'));

const getTotalSecuredByScenario = createSelector([getRawFunding], state =>
  state.get('totalSecuredByScenario')
);

const getFundings = createSelector([getFundingsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getErrorMessage,
  getFundings,
  getFundingsById,
  getFundingsByScenario,
  getTotalByScenario,
  getTotalSecuredByScenario,
};
