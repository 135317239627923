import api from './api';

const getInvites = () => api.get('/api/invites_member/');

const sendTeamMemberInvite = ({ email, program, member, language, user, mentor, project }) =>
  api.post('/api/invites_member/', {
    email,
    program,
    member,
    language,
    user,
    mentor,
    project,
  });

const updateMemberInvite = ({ id, ...data }) => {
  api.patch(`/api/invites_member/${id}/`, { ...data });
};

export default {
  sendTeamMemberInvite,
  updateMemberInvite,
  getInvites,
};
