import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import serviceProjectSdg from 'services/ImpactPlan/projectSdg';
import types from './types';
import actions from './actions';

export function* createProjectSdg(action) {
  const { project, ...projectSdg } = action.payload;
  try {
    const response = yield call(serviceProjectSdg.createProjectSdg, {
      project,
      ...projectSdg,
    });
    yield put(actions.createProjectSdgsucceeded({ ...response.data }));
  } catch (e) {
    yield put(
      actions.createProjectSdgFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteProjectSdg(action) {
  const { project, projectSdgId } = action.payload;
  try {
    yield call(serviceProjectSdg.deleteProjectSdg, {
      project,
      id: projectSdgId,
    });
    yield put(actions.deleteProjectSdgsucceeded({ id: projectSdgId }));
  } catch (e) {
    yield put(
      actions.deleteProjectSdgFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchProjectSdg(action) {
  const { project, projectSdgId } = action.payload;
  try {
    const response = yield call(serviceProjectSdg.fetchProjectSdg, {
      project,
      projectSdgId,
    });
    yield put(actions.fetchProjectSdgsucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchProjectSdgFailed({ message: e.message }));
  }
}

export function* listProjectSdgs(action) {
  const { project } = action.payload;
  try {
    const projectSdgs = yield call(serviceProjectSdg.listProjectSdgs, {
      project,
    });
    yield put(actions.listProjectSdgsSucceeded({ results: projectSdgs.data }));
  } catch (e) {
    yield put(actions.listProjectSdgsFailed({ message: e.message }));
  }
}

export function* updateProjectSdg(action) {
  const { project, projectSdgId, ...projectSdg } = action.payload;
  try {
    const updated = yield call(serviceProjectSdg.updateProjectSdg, {
      project,
      id: projectSdgId,
      ...projectSdg,
    });
    yield put(actions.updateProjectSdgsucceeded({ ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateProjectSdgFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

function* saga() {
  yield all([
    takeLatest(types.PROJECT_SDG_CREATE, createProjectSdg),
    takeLatest(types.PROJECT_SDG_DELETE, deleteProjectSdg),
    takeLatest(types.PROJECT_SDG_FETCH, fetchProjectSdg),
    takeLatest(types.PROJECT_SDG_UPDATE, updateProjectSdg),
    takeEvery(types.PROJECT_SDGS_LIST, listProjectSdgs),
  ]);
}

export default saga;
