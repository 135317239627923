/**
 * **COPIED FROM LODASH**
 * **THIS WILL LIMIT THE ABSOLUTELY POSITIONED ELEMENTS TO:**
 * - **A MINIMUM OF 0%.**
 * - **A MAXIMUM OF 100%.**
 *
 * The base implementation of `_.clamp` which doesn't coerce arguments.
 *
 * @private
 * @param {number} number The number to clamp.
 * @param {number} [lower] The lower bound.
 * @param {number} upper The upper bound.
 * @returns {number} Returns the clamped number.
 */
export const baseClamp = (number, lower, upper) => {
  if (typeof number === 'number') {
    if (upper !== undefined) {
      return number <= upper ? number : upper;
    }
    if (lower !== undefined) {
      return number >= lower ? number : lower;
    }
  }
  return number;
};

/**
 * `getRadianFromValue` will calculate the angle of the value respective
 * to the circumference in deegres and in radians.
 */
export const getRadianFromValue = (val, [minValue, maxValue]) => {
  // Calculating the angle relative to the value:
  const angleInDeegres = (val / (maxValue - minValue)) * 180 + 180;
  const angleInRadians = angleInDeegres * (Math.PI / 180);
  return [angleInDeegres, angleInRadians];
};

/**
 * If the label is near the extremes of the speedometer gauge,
 * then the `testAnchor` property is set at the respective
 * extremes, otherwise it's just set at the middle.
 */
export const getTextAnchor = (degrees, shouldNotAngleText) => {
  if (shouldNotAngleText) return 'middle';
  switch (true) {
    case degrees * 0.95 <= 180:
      return 'start';
    case degrees >= 360 * 0.95:
      return 'end';
    default:
      return 'middle';
  }
};

/**
 * `setGaugeDegreesByPercentage` calculates the total deegres of the gauge,
 * relative to the percentage given.
 */
export const setGaugeDegreesByPercentage = (percent, radius) => {
  const perimeter = 2 * Math.PI * radius;
  return perimeter - (percent / 100) * perimeter;
};
