import { Record } from 'immutable';

const OutcomeIndicatorRecord = new Record({
  id: null,
  contentType: 'bfb_impact_plan.indicator',
  comments: [],
  name: null,
  measurement: null,
  selected: null,
  created: null,
  updated: null,
  outcome: null,
});

class OutcomeIndicator extends OutcomeIndicatorRecord {
  static contentType = 'bfb_impact_plan.indicator';

  get id() {
    return this.get('id');
  }
}

export default OutcomeIndicator;
