import { Record } from 'immutable';
import ContentType from '../ContentType';

const ReasonRecord = Record({
  id: null,
  contentType: ContentType.REASON,
  reason: '',
  problem: null,
  comments: [],
  parent: null,
  children: null,
  selected: false,

  created: null,
  updated: null,
});

class Reason extends ReasonRecord {
  static contentType = ContentType.REASON;
  get getId() {
    return this.get('id');
  }
}

export default Reason;
