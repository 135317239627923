import types from './types';

const listSdgs = () => ({
  type: types.SDG_LIST,
});

const listSdgsSucceeded = payload => ({
  type: types.SDG_LIST_SUCCEEDED,
  payload,
});

const listSdgsFailed = ({ message }) => ({
  type: types.SDG_LIST_FAILED,
  payload: { message },
});

export default {
  listSdgs,
  listSdgsFailed,
  listSdgsSucceeded,
};
