const COMMENT_CREATE = 'bfb/comment/COMMENT_CREATE';
const COMMENT_CREATE_SUCCEEDED = 'bfb/comment/COMMENT_CREATE_SUCCEEDED';
const COMMENT_CREATE_FAILED = 'bfb/comment/COMMENT_CREATE_FAILED';
const COMMENT_DELETE = 'bfb/comment/COMMENT_DELETE';
const COMMENT_DELETE_SUCCEEDED = 'bfb/comment/COMMENT_DELETE_SUCCEEDED';
const COMMENT_DELETE_FAILED = 'bfb/comment/COMMENT_DELETE_FAILED';
const COMMENT_EDIT = 'bfb/comment/COMMENT_EDIT';
const COMMENT_EDIT_SUCCEEDED = 'bfb/comment/COMMENT_EDIT_SUCCEEDED';
const COMMENT_EDIT_FAILED = 'bfb/comment/COMMENT_EDIT_FAILED';
const COMMENT_LIST = 'bfb/comment/COMMENT_LIST';
const COMMENT_LIST_SUCCEEDED = 'bfb/comment/COMMENT_LIST_SUCCEEDED';
const COMMENT_LIST_FAILED = 'bfb/comment/COMMENT_LIST_FAILED';
const COMMENT_SELECT_CONTENT_OBJECT = 'bfb/comment/COMMENT_SELECT_CONTENT_OBJECT';
const COMMENT_TOGGLE_SHOW = 'bfb/comment/COMMENT_TOGGLE_SHOW';

export default {
  COMMENT_CREATE,
  COMMENT_CREATE_SUCCEEDED,
  COMMENT_CREATE_FAILED,
  COMMENT_DELETE,
  COMMENT_DELETE_SUCCEEDED,
  COMMENT_DELETE_FAILED,
  COMMENT_EDIT,
  COMMENT_EDIT_SUCCEEDED,
  COMMENT_EDIT_FAILED,
  COMMENT_LIST,
  COMMENT_LIST_SUCCEEDED,
  COMMENT_LIST_FAILED,
  COMMENT_SELECT_CONTENT_OBJECT,
  COMMENT_TOGGLE_SHOW,
};
