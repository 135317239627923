import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'services/api';

// API module for listing ALL the languages

export const languagesApi = createApi({
  reducerPath: 'languagesApi',
  baseQuery: axiosBaseQuery({
    apiPath: '/api/languages/',
  }),
  endpoints: builder => ({
    // List ALL the languages
    listLanguages: builder.query({
      query: () => '',
    }),
  }),
});

export const { useListLanguagesQuery } = languagesApi;
