// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

// Dependencies
import Member from 'models/Member';
import Program from 'models/Program';
import TranslationsContext from 'providers/translationsProvider';
import { memberTheme } from 'styles/themes';
import { useLocalStorage } from 'hooks/useLocalStorage';

// Actions and Selectors
import { selectors as memberSelectors } from 'store/modules/member';
import { selectors as programSelectors } from 'store/modules/program';
import { selectors as userSelectors } from 'store/modules/user';

// Components
import { Button } from 'components/elements';

// Local Component
import { List } from 'immutable';
import CreateProfile from '../CreateProfile';
import OnboardingStages, { STEP_PROFILE, STEP_VENTURE } from '../OnboardingStages';
import { Accordion, Modal } from '../static_variables';
import StaticQA from './StaticQA';

const DesktopPopup = ({ user = {}, programs = new List(), member = {} }) => {
  const { t } = React.useContext(TranslationsContext);

  const stepsByStatus = () => {
    const path = window.location.pathname.split('/');
    switch (true) {
      case user.member && member.status === Member.STATUS_PROFILE_INCOMPLETE:
        return STEP_PROFILE;
      case user.member && member.status === Member.STATUS_INCUBATING && path.includes('workspace'):
        return STEP_VENTURE;
      default:
        return false;
    }
  };

  const currentStep = stepsByStatus();
  // We get the step from localStorage as hexadecimal number for compare after with the currentStep
  const [localStorageStep, setLocalStorageStep] = useLocalStorage('welcomePopupStep', currentStep);

  const shouldRender = () => {
    if (currentStep === STEP_PROFILE || currentStep === STEP_VENTURE) {
      if (localStorageStep === currentStep) {
        return false;
      }
      // If the currentStep is different from localStorage step we must set the new step to local
      // storage and return true to render the modal
      setLocalStorageStep(currentStep);
      return true;
    }
    return false;
  };

  const [visible, setVisible] = React.useState(shouldRender());

  /**
   * Obviously closes the modal. This function is sent to the `CreateProfile` component
   * and to the `StaticQA` component.
   */
  const closeModal = () => {
    setVisible(false);
  };

  /**
   * Function to check if we should render the Additional Info Area with Accordion
   * component (Still have questions?? learn more..)
   */
  const shouldRenderAdditionalInfoArea = () => {
    // The additional info area should only be rendered for new users signed up to a B2C program and
    // if the step is STEP_PROFILE
    if (stepsByStatus() === STEP_PROFILE) {
      // eslint-disable-next-line max-len
      if (programs.length === 0 || programs.find(program => program.program_type === Program.B2C)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Function to check what button should render depending on the STEP
   */
  const whichButtonRowShouldRender = () => {
    switch (stepsByStatus()) {
      case STEP_PROFILE:
        return <CreateProfile onClick={closeModal} />;
      case STEP_VENTURE:
        return <ButtonRow onClick={closeModal} />;

      default:
        return null;
    }
  };

  if (currentStep) {
    return (
      <ThemeProvider theme={memberTheme}>
        <Modal
          title={t('Ready to start your incubation program?')}
          hideHeaderBackground
          data-test="component-welcome-popup"
          open={visible}
          footer={
            shouldRenderAdditionalInfoArea() ? (
              <div data-test="additional-info-area">
                <Accordion
                  header={
                    <div className="accordion-header">{t('Still have questions? Learn more.')}</div>
                  }
                  description={<StaticQA onCreateProfileClick={closeModal} />}
                  borderless
                />
              </div>
            ) : null
          }
          destroyOnClose
          closable
          centered
          onCancel={closeModal}
        >
          <OnboardingStages currentStep={stepsByStatus()} />
          {/* Check which Button should render */}
          {whichButtonRowShouldRender()}
        </Modal>
      </ThemeProvider>
    );
  }

  return null;
};

DesktopPopup.propTypes = {
  user: PropTypes.instanceOf(Object),
  programs: PropTypes.instanceOf(List),
  member: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  user: userSelectors.getUser(state),
  // NOTE: Since this is for new users, they should only ever have ONE program at sign up.
  programs: programSelectors.getPrograms(state),
  member: memberSelectors.getMember(state),
});

const connected = connect(mapStateToProps)(DesktopPopup);

export default connected;

const ButtonRow = ({ onClick }) => {
  const { t } = React.useContext(TranslationsContext);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', height: '45px' }}
      data-test="component-create-profile"
      className="button-container"
    >
      <Button data-test="create-profile-button" type="primary" effect="blink" onClick={onClick}>
        {t('TAKE A TOUR & START')}
      </Button>
    </div>
  );
};

ButtonRow.propTypes = {
  onClick: PropTypes.func.isRequired,
};
