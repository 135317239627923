import { Record } from 'immutable';
import ContentType from '../ContentType';

const MonthlyUnitsSoldRecord = Record({
  id: null,
  comments: null,
  created: null,
  updated: null,
  amount: null,
  month: null,
  year: null,
  expense: null,
});

class MonthlyUnitsSold extends MonthlyUnitsSoldRecord {
  static contentType = ContentType.PAY;

  get getId() {
    return this.get('id');
  }
}

export default MonthlyUnitsSold;
