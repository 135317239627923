import { types } from 'store/modules/ImpactPlan/relationship';

const clearError = () => ({
  type: types.RELATIONSHIP_CLEAR_ERROR,
});

const createRelationship = ({ ...relationship }) => ({
  type: types.RELATIONSHIP_CREATE,
  payload: { ...relationship },
});

const createRelationshipSucceeded = ({ id, ...relationship }) => ({
  type: types.RELATIONSHIP_CREATE_SUCCEEDED,
  payload: { id, ...relationship },
});

const createRelationshipFailed = ({ message }) => ({
  type: types.RELATIONSHIP_CREATE_FAILED,
  payload: { message },
});

const deleteRelationship = ({ id, system }) => ({
  type: types.RELATIONSHIP_DELETE,
  payload: { id, system },
});

const deleteRelationshipSucceeded = ({ id, system }) => ({
  type: types.RELATIONSHIP_DELETE_SUCCEEDED,
  payload: { id, system },
});

const deleteRelationshipFailed = ({ message }) => ({
  type: types.RELATIONSHIP_DELETE_FAILED,
  payload: { message },
});

const fetchRelationship = ({ id }) => ({
  type: types.RELATIONSHIP_FETCH,
  payload: { id },
});

const fetchRelationshipSucceeded = ({ id, ...relationship }) => ({
  type: types.RELATIONSHIP_FETCH_SUCCEEDED,
  payload: { id, ...relationship },
});

const fetchRelationshipFailed = ({ message }) => ({
  type: types.RELATIONSHIP_FETCH_FAILED,
  payload: { message },
});

const listRelationships = ({ system }) => ({
  type: types.RELATIONSHIPS_LIST,
  payload: { system },
});

const listRelationshipsSucceeded = payload => ({
  type: types.RELATIONSHIPS_LIST_SUCCEEDED,
  payload,
});

const listRelationshipsFailed = error => ({
  type: types.RELATIONSHIPS_LIST_FAILED,
  payload: error,
});

const updateRelationship = ({ id, ...relationship }) => ({
  type: types.RELATIONSHIP_UPDATE,
  payload: { id, ...relationship },
});

const updateRelationshipSucceeded = ({ id, ...relationship }) => ({
  type: types.RELATIONSHIP_UPDATE_SUCCEEDED,
  payload: { id, ...relationship },
});

const updateRelationshipFailed = ({ message }) => ({
  type: types.RELATIONSHIP_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createRelationship,
  createRelationshipFailed,
  createRelationshipSucceeded,
  deleteRelationship,
  deleteRelationshipFailed,
  deleteRelationshipSucceeded,
  fetchRelationship,
  fetchRelationshipFailed,
  fetchRelationshipSucceeded,
  listRelationships,
  listRelationshipsFailed,
  listRelationshipsSucceeded,
  updateRelationship,
  updateRelationshipSucceeded,
  updateRelationshipFailed,
};
