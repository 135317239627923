// Libraries
import { createSlice } from '@reduxjs/toolkit';

// Dependencies
import { languagesApi } from 'services/apiSlices/languagesApi';
import { platformLanguages } from 'utils/translationHandlers';

const initialState = {
  isLoading: true,
  languages: [],
  platformLanguages: [],
};

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(languagesApi.endpoints.listLanguages.matchFulfilled, (state, action) => {
        const { payload } = action;
        const allLanguages = payload;

        // Load fetched data into the state
        state.languages = allLanguages;
        state.isLoading = false;

        if (state.platformLanguages.length < 1) {
          // combine the platformLanguages from utils with the IDs of these languages from backend
          const platformLanguagesWithIDs = platformLanguages
            .map(platformLang => {
              const backendLanguage = allLanguages.find(
                language => language.code === platformLang.code
              );
              return backendLanguage ? { ...platformLang, id: backendLanguage.id } : null;
            })
            .filter(element => !!element);
          state.platformLanguages = platformLanguagesWithIDs;
        }
      })
      .addMatcher(languagesApi.endpoints.listLanguages.matchRejected, state => {
        state.isLoading = false;
      });
  },
});

export const languagesReducer = languagesSlice.reducer;
