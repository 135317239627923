const EXECUTIVE_SUMMARY_FETCH = 'bfb/executive-summary/EXECUTIVE_SUMMARY_FETCH';
const EXECUTIVE_SUMMARY_FETCH_SUCCEEDED = 'bfb/executive-summary/EXECUTIVE_SUMMARY_FETCH_SUCCEEDED';
const EXECUTIVE_SUMMARY_FETCH_FAILED = 'bfb/executive-summary/EXECUTIVE_SUMMARY_FETCH_FAILED';
const EXECUTIVE_SUMMARY_UPDATE = 'bfb/executive-summary/EXECUTIVE_SUMMARY_UPDATE';
const EXECUTIVE_SUMMARY_UPLOAD_IMAGE = 'bfb/executive-summary/EXECUTIVE_SUMMARY_UPLOAD_IMAGE';
const EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED =
  'bfb/executive-summary/EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED';
const EXECUTIVE_SUMMARY_UPDATE_FAILED = 'bfb/executive-summary/EXECUTIVE_SUMMARY_UPDATE_FAILED';

export default {
  EXECUTIVE_SUMMARY_FETCH,
  EXECUTIVE_SUMMARY_FETCH_SUCCEEDED,
  EXECUTIVE_SUMMARY_FETCH_FAILED,
  EXECUTIVE_SUMMARY_UPDATE_FAILED,
  EXECUTIVE_SUMMARY_UPLOAD_IMAGE,
  EXECUTIVE_SUMMARY_UPDATE_SUCCEEDED,
  EXECUTIVE_SUMMARY_UPDATE,
};
