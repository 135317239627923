import { Record } from 'immutable';
import ContentType from '../ContentType';

const FixedAssetRecord = Record({
  id: null,
  contentType: ContentType.FIXEDASSET,
  depreciation: 1,
  name: '',
  scenario: null,
  type: null,
  value: null,
  comments: null,
  created: null,
  updated: null,
  acquired: null,
});

class FixedAsset extends FixedAssetRecord {
  // TODO: ADD PRODUCT CONTENT TYPE
  static contentType = ContentType.FIXEDASSET;

  static TYPE_LAND = 0;
  static TYPE_BUILDING = 1;
  static TYPE_EQUIPMENT = 2;
  static TYPE_CONSTRUCTION = 3;
  static TYPE_FURNITURE = 4;
  static TYPE_VEHICLES = 5;

  static getTypeNames = t => ({
    [FixedAsset.TYPE_LAND]: t('Land'),
    [FixedAsset.TYPE_BUILDING]: t('Buildings'),
    [FixedAsset.TYPE_EQUIPMENT]: t('Equipment'),
    [FixedAsset.TYPE_CONSTRUCTION]: t('Construction Improvements'),
    [FixedAsset.TYPE_FURNITURE]: t('Furniture & Fixtures'),
    [FixedAsset.TYPE_VEHICLES]: t('Vehicles'),
  });

  static getTypesOptions = t =>
    Object.entries(FixedAsset.getTypeNames(t)).map(([type, typeName]) => ({
      label: typeName,
      value: parseInt(type, 10),
    }));

  get getId() {
    return this.get('id');
  }
}

export default FixedAsset;
