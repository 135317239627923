import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withTranslations from 'hocs/withTranslations';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 100%;
  border-radius: 4px;
  background-color: #f2f2f2;

  span {
    color: #999999;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }
`;

const EnterDataAlert = props => {
  const { t } = props;
  return (
    <StyledDiv>
      <span>
        {t(
          'Oops! You have to enter data on the previous steps for this product/service in order to continue.'
        )}
      </span>
    </StyledDiv>
  );
};

EnterDataAlert.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslations(EnterDataAlert);
