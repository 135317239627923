import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { types as commentTypes } from 'store/modules/comment';
import Behavior from 'models/GrowthPlan/Behavior';
import serviceBehavior from 'services/GrowthPlan/behavior';
import types from './types';
import actions from './actions';

export function* createBehavior(action) {
  const { project, ...behavior } = action.payload;
  try {
    const response = yield call(serviceBehavior.createBehavior, {
      project,
      ...behavior,
    });
    yield put(actions.createBehaviorSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(
      actions.createBehaviorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteBehavior(action) {
  const { project, id } = action.payload;
  try {
    yield call(serviceBehavior.deleteBehavior, { id });
    yield put(actions.deleteBehaviorSucceeded({ project, id }));
  } catch (e) {
    yield put(
      actions.deleteBehaviorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchBehavior(action) {
  const { project, id } = action.payload;
  try {
    const response = yield call(serviceBehavior.fetchBehavior, { id });
    yield put(actions.fetchBehaviorSucceeded({ project, ...response.data }));
  } catch (e) {
    yield put(actions.fetchBehaviorFailed({ message: e.message }));
  }
}

export function* listBehaviors(action) {
  const { project } = action.payload;
  try {
    const behaviors = yield call(serviceBehavior.listBehaviors, { project });
    yield put(actions.listBehaviorsSucceeded({ project, results: behaviors.data }));
  } catch (e) {
    yield put(actions.listBehaviorsFailed({ message: e.message }));
  }
}

export function* updateBehavior(action) {
  const { id, project, ...updatedBehavior } = action.payload;
  try {
    const updated = yield call(serviceBehavior.updateBehavior, {
      id,
      ...updatedBehavior,
    });
    yield put(actions.updateBehaviorSucceeded({ project, ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateBehaviorFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Behavior.contentType) {
    yield put(
      actions.fetchBehavior({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* behaviorSaga() {
  yield all([
    takeLatest(types.BEHAVIOR_CREATE, createBehavior),
    takeLatest(types.BEHAVIOR_DELETE, deleteBehavior),
    takeLatest(types.BEHAVIOR_FETCH, fetchBehavior),
    takeLatest(types.BEHAVIOR_UPDATE, updateBehavior),
    takeEvery(types.BEHAVIORS_LIST, listBehaviors),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default behaviorSaga;
