const BEHAVIOR_CLEAR_ERROR = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_CLEAR_ERROR';
const BEHAVIORS_LIST = 'bfb/growthPlan/BEHAVIOR/BEHAVIORS_LIST';
const BEHAVIORS_LIST_SUCCEEDED = 'bfb/growthPlan/BEHAVIOR/BEHAVIORS_LIST_SUCCEEDED';
const BEHAVIORS_LIST_FAILED = 'bfb/growthPlan/BEHAVIOR/BEHAVIORS_LIST_FAILED';
const BEHAVIOR_CREATE = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_CREATE';
const BEHAVIOR_CREATE_SUCCEEDED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_CREATE_SUCCEEDED';
const BEHAVIOR_CREATE_FAILED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_CREATE_FAILED';
const BEHAVIOR_DELETE = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_DELETE';
const BEHAVIOR_DELETE_SUCCEEDED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_DELETE_SUCCEEDED';
const BEHAVIOR_DELETE_FAILED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_DELETE_FAILED';
const BEHAVIOR_FETCH = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_FETCH';
const BEHAVIOR_FETCH_SUCCEEDED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_FETCH_SUCCEEDED';
const BEHAVIOR_FETCH_FAILED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_FETCH_FAILED';
const BEHAVIOR_UPDATE = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_UPDATE';
const BEHAVIOR_UPDATE_SUCCEEDED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_UPDATE_SUCCEEDED';
const BEHAVIOR_UPDATE_FAILED = 'bfb/growthPlan/BEHAVIOR/BEHAVIOR_UPDATE_FAILED';

export default {
  BEHAVIORS_LIST,
  BEHAVIORS_LIST_SUCCEEDED,
  BEHAVIORS_LIST_FAILED,
  BEHAVIOR_CLEAR_ERROR,
  BEHAVIOR_CREATE,
  BEHAVIOR_CREATE_SUCCEEDED,
  BEHAVIOR_CREATE_FAILED,
  BEHAVIOR_DELETE,
  BEHAVIOR_DELETE_SUCCEEDED,
  BEHAVIOR_DELETE_FAILED,
  BEHAVIOR_FETCH,
  BEHAVIOR_FETCH_SUCCEEDED,
  BEHAVIOR_FETCH_FAILED,
  BEHAVIOR_UPDATE,
  BEHAVIOR_UPDATE_SUCCEEDED,
  BEHAVIOR_UPDATE_FAILED,
};
