import { call, put, all, takeLatest } from 'redux-saga/effects';
import surveysService from 'services/surveys';
import types from './types';
import actions from './actions';

export function* listSurveys(action) {
  try {
    const response = yield call(surveysService.listSurveys, action.payload);
    yield put(
      actions.listSurveysSucceeded({
        ...action.payload,
        ...response.data,
      })
    );
  } catch (e) {
    yield put(actions.listSurveysFailed({ message: e.message }));
  }
}

function* surveysSaga() {
  yield all([takeLatest(types.SURVEYS_LIST, listSurveys)]);
}
export default surveysSaga;
