const RESOURCE_CLEAR_ERROR = 'bfb/RESOURCE/RESOURCE_CLEAR_ERROR';
const RESOURCES_LIST = 'bfb/RESOURCE/RESOURCES_LIST';
const RESOURCES_LIST_SUCCEEDED = 'bfb/RESOURCE/RESOURCES_LIST_SUCCEEDED';
const RESOURCES_LIST_FAILED = 'bfb/RESOURCE/RESOURCES_LIST_FAILED';
const RESOURCE_CREATE = 'bfb/RESOURCE/RESOURCE_CREATE';
const RESOURCE_CREATE_SUCCEEDED = 'bfb/RESOURCE/RESOURCE_CREATE_SUCCEEDED';
const RESOURCE_CREATE_FAILED = 'bfb/RESOURCE/RESOURCE_CREATE_FAILED';
const RESOURCE_DELETE = 'bfb/RESOURCE/RESOURCE_DELETE';
const RESOURCE_DELETE_SUCCEEDED = 'bfb/RESOURCE/RESOURCE_DELETE_SUCCEEDED';
const RESOURCE_DELETE_FAILED = 'bfb/RESOURCE/RESOURCE_DELETE_FAILED';
const RESOURCE_FETCH = 'bfb/RESOURCE/RESOURCE_FETCH';
const RESOURCE_FETCH_SUCCEEDED = 'bfb/RESOURCE/RESOURCE_FETCH_SUCCEEDED';
const RESOURCE_FETCH_FAILED = 'bfb/RESOURCE/RESOURCE_FETCH_FAILED';
const RESOURCE_UPDATE = 'bfb/RESOURCE/RESOURCE_UPDATE';
const RESOURCE_UPDATE_SUCCEEDED = 'bfb/RESOURCE/RESOURCE_UPDATE_SUCCEEDED';
const RESOURCE_UPDATE_FAILED = 'bfb/RESOURCE/RESOURCE_UPDATE_FAILED';

export default {
  RESOURCES_LIST,
  RESOURCES_LIST_SUCCEEDED,
  RESOURCES_LIST_FAILED,
  RESOURCE_CLEAR_ERROR,
  RESOURCE_CREATE,
  RESOURCE_CREATE_SUCCEEDED,
  RESOURCE_CREATE_FAILED,
  RESOURCE_DELETE,
  RESOURCE_DELETE_SUCCEEDED,
  RESOURCE_DELETE_FAILED,
  RESOURCE_FETCH,
  RESOURCE_FETCH_SUCCEEDED,
  RESOURCE_FETCH_FAILED,
  RESOURCE_UPDATE,
  RESOURCE_UPDATE_SUCCEEDED,
  RESOURCE_UPDATE_FAILED,
};
