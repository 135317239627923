import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions, types } from 'store/modules/ImpactPlan/resource';
import { types as commentTypes } from 'store/modules/comment';
import serviceResource from 'services/ImpactPlan/resource';
import Resource from 'models/ImpactPlan/Resource';

export function* createResource(action) {
  const { system, ...resource } = action.payload;
  try {
    const response = yield call(serviceResource.createResource, {
      system,
      ...resource,
    });
    yield put(
      actions.createResourceSucceeded({
        system,
        ...response.data,
      })
    );
  } catch (e) {
    yield put(
      actions.createResourceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteResource(action) {
  const { id, system } = action.payload;
  try {
    yield call(serviceResource.deleteResource, { id });
    yield put(actions.deleteResourceSucceeded({ id, system }));
  } catch (e) {
    yield put(
      actions.deleteResourceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchResource(action) {
  const { id } = action.payload;
  try {
    const resource = yield call(serviceResource.fetchResource, { id });
    yield put(actions.fetchResourceSucceeded(resource.data));
  } catch (e) {
    yield put(actions.fetchResourceFailed({ message: e.message }));
  }
}

export function* listResources(action) {
  const { system } = action.payload;
  try {
    const resources = yield call(serviceResource.listResources, { system });
    yield put(actions.listResourcesSucceeded({ results: resources.data, system }));
  } catch (e) {
    yield put(actions.listResourcesFailed({ message: e.message }));
  }
}

export function* updateResource(action) {
  const { id, ...resource } = action.payload;
  try {
    const updated = yield call(serviceResource.updateResource, {
      id,
      ...resource,
    });
    yield put(actions.updateResourceSucceeded(updated.data));
  } catch (e) {
    yield put(
      actions.updateResourceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Resource.contentType) {
    yield put(
      actions.fetchResource({
        project: object.get('project'),
        id: object.get('id'),
      })
    );
  }
}

function* resourceSaga() {
  yield all([
    takeLatest(types.RESOURCE_CREATE, createResource),
    takeLatest(types.RESOURCE_DELETE, deleteResource),
    takeLatest(types.RESOURCE_FETCH, fetchResource),
    takeLatest(types.RESOURCE_UPDATE, updateResource),
    takeEvery(types.RESOURCES_LIST, listResources),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default resourceSaga;
