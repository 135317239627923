import { Record } from 'immutable';

const StepRecord = new Record({
  id: null,
  name: '',
  slug: '',
  position: 0,
  status: null,
  step_id: null,
  tool: null,
});

class Step extends StepRecord {
  static STATUS_INCOMPLETE = 0;

  static STATUS_IN_PROGRESS = 1;

  static STATUS_COMPLETE = 2;
  static STATUS_STATUS_PENDING_REVIEW = 3;

  get id() {
    return this.get('id');
  }
}

export default Step;
