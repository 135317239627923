// Libraries
import styled, { css } from 'styled-components';

// Components
import { Span } from 'components/fontSystem';

const Indicator = styled(Span)`
  position: absolute;
  white-space: nowrap;
  ${({ placement }) => {
    switch (placement) {
      case 'bottom':
        return css`
          top: 0;
        `;
      case 'top':
      default:
        return css`
          bottom: 0;
        `;
    }
  }}
  z-index: 2;
  margin: 2px 0;
  padding: 1px;
  border-radius: 4px;
  background-color: ${({ indicatorBackgroundColor, backgroundColor }) =>
    indicatorBackgroundColor || `${backgroundColor}80`};
`;

export default Indicator;
