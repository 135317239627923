import api from '../api';

const listRetentionActivities = () => api.get('/api/retention-activities/');

const createRetentionActivity = ({ ...retentionActivity }) =>
  api.post('/api/retention-activities/', {
    ...retentionActivity,
  });

const updateRetentionActivity = ({ ...retentionActivity }) =>
  api.patch(`/api/retention-activities/${retentionActivity.id}/`, {
    ...retentionActivity,
  });

const deleteRetentionActivity = ({ retentionActivityId }) =>
  api.delete(`/api/retention-activities/${retentionActivityId}/`);

export default {
  listRetentionActivities,
  createRetentionActivity,
  updateRetentionActivity,
  deleteRetentionActivity,
};
