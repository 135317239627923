import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/StakeholderMap/resources';
import { types as commentTypes } from 'store/modules/comment';
import serviceResource from 'services/StakeholderMap/resource';
import Resource from 'models/StakeholderMap/Resource';

export function* createResource(action) {
  const { project, product, partner, ...resource } = action.payload;
  try {
    const response = yield call(serviceResource.createResource, {
      project,
      product,
      partner,
      ...resource,
    });
    yield put(actions.createResourceSucceeded({ partner, ...response.data }));
  } catch (e) {
    yield put(
      actions.createResourceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteResource(action) {
  const { project, product, partner, resourceId } = action.payload;
  try {
    yield call(serviceResource.deleteResource, {
      project,
      product,
      partner,
      resourceId,
    });
    yield put(actions.deleteResourceSucceeded({ id: resourceId, partner }));
  } catch (e) {
    yield put(
      actions.deleteResourceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchResource(action) {
  const { project, product, partner, resourceId } = action.payload;
  try {
    const response = yield call(serviceResource.fetchResource, {
      project,
      product,
      partner,
      resourceId,
    });
    yield put(actions.fetchResourceSucceeded({ ...response.data, partner }));
  } catch (e) {
    yield put(actions.fetchResourceFailed({ message: e.message }));
  }
}

export function* listResources(action) {
  const { project, product, partner } = action.payload;
  try {
    const resources = yield call(serviceResource.listResources, {
      project,
      product,
      partner,
    });
    yield put(actions.listResourcesSucceeded({ results: resources.data, partner }));
  } catch (e) {
    yield put(actions.listResourcesFailed({ message: e.message }));
  }
}

export function* updateResource(action) {
  const { project, product, partner, resourceId, ...resource } = action.payload;
  try {
    const updated = yield call(serviceResource.updateResource, {
      project,
      product,
      partner,
      resourceId,
      ...resource,
    });
    yield put(actions.updateResourceSucceeded({ ...updated.data, partner }));
  } catch (e) {
    yield put(
      actions.updateResourceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Resource.contentType) {
    yield put(
      actions.fetchResource({
        project: object.get('project'),
        product: object.get('product'),
        partner: object.get('partner'),
        resourceId: object.get('id'),
      })
    );
  }
}

function* resourceSaga() {
  yield all([
    takeLatest(types.RESOURCE_CREATE, createResource),
    takeLatest(types.RESOURCE_DELETE, deleteResource),
    takeLatest(types.RESOURCE_FETCH, fetchResource),
    takeLatest(types.RESOURCE_UPDATE, updateResource),
    takeEvery(types.RESOURCES_LIST, listResources),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default resourceSaga;
