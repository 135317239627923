import api from '../api';

const updateMonthlyPrice = ({ id, ...updatedMonthlyPrice }) =>
  api({
    method: 'patch',
    url: `api/monthly-price/${id}/`,
    data: updatedMonthlyPrice,
  });

export default {
  updateMonthlyPrice,
};
