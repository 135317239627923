import { Record } from 'immutable';
import ContentType from './ContentType';

const ProductRecord = Record({
  id: null,
  contentType: ContentType.PRODUCT,
  name: '',
  type: null,
  value_proposition: '',
  stakeholder_map_summary: '',
  competition_map_summary: '',
  key_activities_summary: '',
  customer_models_summary: '',
  pricing_summary: '',

  unit_name: '',
  direct_labor_expenses_cents: 0,
  materials_cents: 0,
  production_facility_cents: 0,
  shipping_storage_cents: 0,
  other_costs_cents: 0,
  unit_monthly_sales: 0,
  unit_costs_cents: null,
  unit_price_cents: null,

  growth_amount_cents_1: 0,
  growth_percentage_1: 0,
  growth_amount_cents_2: 0,
  growth_percentage_2: 0,
  growth_amount_cents_3: 0,
  project: null,
  comments: [],

  deleted: false,
  created: null,
  updated: null,

  wtp1: null,
  wtp2: null,
  wtp3: null,
  wtp4: null,
});

class Product extends ProductRecord {
  static TYPE_PRODUCT = 0;
  static TYPE_SERVICE = 1;

  static contentType = ContentType.PRODUCT;

  get getId() {
    return this.get('id');
  }
}

export default Product;
