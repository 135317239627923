const MEMBER_APPLICATIONS_LIST = 'bfb/ManagementPanel/MEMBER_APPLICATIONS_LIST';
const MEMBER_APPLICATIONS_LIST_PENDING = 'bfb/ManagementPanel/MEMBER_APPLICATIONS_LIST_PENDING';
const MEMBER_APPLICATIONS_LIST_SUCCEEDED = 'bfb/ManagementPanel/MEMBER_APPLICATIONS_LIST_SUCCEEDED';
const MEMBER_APPLICATIONS_LIST_FAILED = 'bfb/ManagementPanel/MEMBER_APPLICATIONS_LIST_FAILED';
const MEMBER_APPLICATION_BULK_UPDATE = 'bfb/ManagementPanel/MEMBER_APPLICATION_UPDATE';
const MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED =
  'bfb/ManagementPanel/MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED';
const MEMBER_APPLICATION_BULK_UPDATE_FAILED =
  'bfb/ManagementPanel/MEMBER_APPLICATION_BULK_UPDATE_FAILED';
const MEMBER_APPLICATION_BULK_UPDATE_INVITE =
  'bfb/ManagementPanel/MEMBER_APPLICATION_UPDATE_INVITE';
const MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED_INVITE =
  'bfb/ManagementPanel/MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED_INVITE';
const MEMBER_APPLICATION_BULK_UPDATE_FAILED_INVITE =
  'bfb/ManagementPanel/MEMBER_APPLICATION_BULK_UPDATE_FAILED_INVITE';
const CURRENT_APPLICATION = 'bfb/ManagementPanel/CURRENT_APPLICATION';
const APPLICATION_FETCH = 'bfb/ManagementPanel/APPLICATION_FETCH';
const APPLICATION_FETCH_SUCCEEDED = 'bfb/ManagementPanel/APPLICATION_FETCH_SUCCEEDED';
const APPLICATION_FETCH_FAILED = 'bfb/ManagementPanel/APPLICATION_FETCH_FAILED';
const MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH';
const MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_SUCCEEDED =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_SUCCEEDED';
const MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_FAILED =
  'bfb/ManagementPanel/MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_FAILED';
const MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS =
  'bfb/ManagementPanel/MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS';
const MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_SUCCEEDED =
  'bfb/ManagementPanel/MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_SUCCEEDED';
const MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_FAILED =
  'bfb/ManagementPanel/MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_FAILED';

export default {
  APPLICATION_FETCH,
  APPLICATION_FETCH_SUCCEEDED,
  APPLICATION_FETCH_FAILED,
  MEMBER_APPLICATIONS_LIST,
  MEMBER_APPLICATIONS_LIST_PENDING,
  MEMBER_APPLICATIONS_LIST_SUCCEEDED,
  MEMBER_APPLICATIONS_LIST_FAILED,
  MEMBER_APPLICATION_BULK_UPDATE,
  MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED,
  MEMBER_APPLICATION_BULK_UPDATE_FAILED,
  MEMBER_APPLICATION_BULK_UPDATE_INVITE,
  MEMBER_APPLICATION_BULK_UPDATE_SUCCEEDED_INVITE,
  MEMBER_APPLICATION_BULK_UPDATE_FAILED_INVITE,
  MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS,
  MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_SUCCEEDED,
  MEMBER_APPLICATION_MANAGEMENT_PANEL_MORE_ACTIONS_FAILED,
  CURRENT_APPLICATION,
  MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH,
  MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_SUCCEEDED,
  MANAGEMENT_PANEL_MEMBER_APPLICATION_FETCH_FAILED,
};
