import types from './types';

const clearError = () => ({
  type: types.PROJECT_SDG_CLEAR_ERROR,
});

const createProjectSdg = ({ project, ...projectSdg }) => ({
  type: types.PROJECT_SDG_CREATE,
  payload: { project, ...projectSdg },
});

const createProjectSdgsucceeded = ({ ...projectSdg }) => ({
  type: types.PROJECT_SDG_CREATE_SUCCEEDED,
  payload: { ...projectSdg },
});

const createProjectSdgFailed = ({ message }) => ({
  type: types.PROJECT_SDG_CREATE_FAILED,
  payload: { message },
});

const deleteProjectSdg = ({ project, projectSdgId }) => ({
  type: types.PROJECT_SDG_DELETE,
  payload: { project, projectSdgId },
});

const deleteProjectSdgsucceeded = payload => ({
  type: types.PROJECT_SDG_DELETE_SUCCEEDED,
  payload,
});

const deleteProjectSdgFailed = ({ message }) => ({
  type: types.PROJECT_SDG_DELETE_FAILED,
  payload: { message },
});

const fetchProjectSdg = ({ project, projectSdgId }) => ({
  type: types.PROJECT_SDG_FETCH,
  payload: { project, projectSdgId },
});

const fetchProjectSdgsucceeded = ({ ...projectSdg }) => ({
  type: types.PROJECT_SDG_FETCH_SUCCEEDED,
  payload: { ...projectSdg },
});

const fetchProjectSdgFailed = ({ message }) => ({
  type: types.PROJECT_SDG_FETCH_FAILED,
  payload: { message },
});

const listProjectSdgs = ({ project }) => ({
  type: types.PROJECT_SDGS_LIST,
  payload: { project },
});

const listProjectSdgsSucceeded = payload => ({
  type: types.PROJECT_SDGS_LIST_SUCCEEDED,
  payload,
});

const listProjectSdgsFailed = ({ message }) => ({
  type: types.PROJECT_SDGS_LIST_FAILED,
  payload: { message },
});

const updateProjectSdg = ({ project, projectSdgId, ...projectSdg }) => ({
  type: types.PROJECT_SDG_UPDATE,
  payload: { project, projectSdgId, ...projectSdg },
});

const updateProjectSdgsucceeded = ({ ...projectSdg }) => ({
  type: types.PROJECT_SDG_UPDATE_SUCCEEDED,
  payload: { ...projectSdg },
});

const updateProjectSdgFailed = ({ message }) => ({
  type: types.PROJECT_SDG_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createProjectSdg,
  createProjectSdgFailed,
  createProjectSdgsucceeded,
  deleteProjectSdg,
  deleteProjectSdgFailed,
  deleteProjectSdgsucceeded,
  fetchProjectSdg,
  fetchProjectSdgFailed,
  fetchProjectSdgsucceeded,
  listProjectSdgs,
  listProjectSdgsFailed,
  listProjectSdgsSucceeded,
  updateProjectSdg,
  updateProjectSdgFailed,
  updateProjectSdgsucceeded,
};
