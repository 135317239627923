import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';

import { actions, types } from 'store/modules/ValueProposition/outcome';
import { types as commentTypes } from 'store/modules/comment';
import serviceOutcome from 'services/ValueProposition/outcome';
import Outcome from 'models/ValueProposition/Outcome';

export function* createOutcome(action) {
  const { project, product, segment, ...outcome } = action.payload;
  try {
    const response = yield call(serviceOutcome.createOutcome, {
      project,
      product,
      segment,
      ...outcome,
    });
    yield put(actions.createOutcomeSucceeded({ ...response.data, segment }));
  } catch (e) {
    yield put(
      actions.createOutcomeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteOutcome(action) {
  const { project, product, segment, outcomeId } = action.payload;
  try {
    yield call(serviceOutcome.deleteOutcome, {
      project,
      product,
      segment,
      outcomeId,
    });
    yield put(actions.deleteOutcomeSucceeded({ id: outcomeId, segment }));
  } catch (e) {
    yield put(
      actions.deleteOutcomeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchOutcome(action) {
  const { project, product, segment, id } = action.payload;
  try {
    const response = yield call(serviceOutcome.fetchOutcome, {
      project,
      product,
      segment,
      id,
    });
    yield put(actions.fetchOutcomeSucceeded({ ...response.data, segment }));
  } catch (e) {
    yield put(actions.fetchOutcomeFailed({ message: e.message }));
  }
}

export function* listOutcomes(action) {
  const { project, product, segment } = action.payload;
  try {
    const outcomes = yield call(serviceOutcome.listOutcomes, {
      project,
      product,
      segment,
    });
    yield put(actions.listOutcomesSucceeded({ results: outcomes.data, segment }));
  } catch (e) {
    yield put(actions.listOutcomesFailed({ message: e.message }));
  }
}

export function* updateOutcome(action) {
  const { project, product, segment, outcomeId, ...outcome } = action.payload;
  try {
    const updated = yield call(serviceOutcome.updateOutcome, {
      project,
      product,
      segment,
      outcomeId,
      ...outcome,
    });
    yield put(actions.updateOutcomeSucceeded({ ...updated.data, segment }));
  } catch (e) {
    yield put(
      actions.updateOutcomeFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === Outcome.contentType) {
    yield put(
      actions.fetchOutcome({
        project: object.get('project'),
        product: object.get('product'),
        segment: object.get('segment'),
        id: object.get('id'),
      })
    );
  }
}

function* outcomeSaga() {
  yield all([
    takeLatest(types.OUTCOME_CREATE, createOutcome),
    takeLatest(types.OUTCOME_DELETE, deleteOutcome),
    takeLatest(types.OUTCOME_FETCH, fetchOutcome),
    takeLatest(types.OUTCOME_UPDATE, updateOutcome),
    takeEvery(types.OUTCOMES_LIST, listOutcomes),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default outcomeSaga;
