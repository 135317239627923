import types from './types';

const clearError = () => ({
  type: types.FEATURE_CLEAR_ERROR,
});

const createFeature = ({ project, product, ...feature }) => ({
  type: types.FEATURE_CREATE,
  payload: { project, product, ...feature },
});

const createFeatureSucceeded = ({ product, id, ...feature }) => ({
  type: types.FEATURE_CREATE_SUCCEEDED,
  payload: { product, id, ...feature },
});

const createFeatureFailed = ({ message }) => ({
  type: types.FEATURE_CREATE_FAILED,
  payload: { message },
});

const deleteFeature = ({ project, product, featureId }) => ({
  type: types.FEATURE_DELETE,
  payload: { project, product, featureId },
});

const deleteFeatureSucceeded = ({ product, featureId }) => ({
  type: types.FEATURE_DELETE_SUCCEEDED,
  payload: { product, id: featureId },
});

const deleteFeatureFailed = ({ message }) => ({
  type: types.FEATURE_DELETE_FAILED,
  payload: message,
});

const fetchFeature = ({ project, product, id }) => ({
  type: types.FEATURE_FETCH,
  payload: { project, product, id },
});

const fetchFeatureSucceeded = ({ ...feature }) => ({
  type: types.FEATURE_FETCH_SUCCEEDED,
  payload: { ...feature },
});

const fetchFeatureFailed = ({ message }) => ({
  type: types.FEATURE_FETCH_FAILED,
  payload: { message },
});

const listFeatures = ({ project, product }) => ({
  type: types.FEATURES_LIST,
  payload: { project, product },
});

const listFeaturesSucceeded = payload => ({
  type: types.FEATURES_LIST_SUCCEEDED,
  payload,
});

const listFeaturesFailed = ({ message }) => ({
  type: types.FEATURES_LIST_FAILED,
  payload: { message },
});

const updateFeature = ({ project, product, featureId, ...updatedFeature }) => ({
  type: types.FEATURE_UPDATE,
  payload: { project, product, featureId, ...updatedFeature },
});

const updateFeatureSucceeded = ({ ...feature }) => ({
  type: types.FEATURE_UPDATE_SUCCEEDED,
  payload: { ...feature },
});

const updateFeatureFailed = ({ message }) => ({
  type: types.FEATURE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createFeature,
  createFeatureFailed,
  createFeatureSucceeded,
  deleteFeature,
  deleteFeatureFailed,
  deleteFeatureSucceeded,
  fetchFeature,
  fetchFeatureFailed,
  fetchFeatureSucceeded,
  listFeatures,
  listFeaturesFailed,
  listFeaturesSucceeded,
  updateFeature,
  updateFeatureFailed,
  updateFeatureSucceeded,
};
