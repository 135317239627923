import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';
import serviceMonthlyPrice from 'services/FinancialProjections/monthlyPrice';
import MonthlyPrice from 'models/FinancialProjections/MonthlyPrice';
import { actions, types } from 'store/modules/FinancialProjections/monthlyPrice';
import { types as commentTypes } from 'store/modules/comment';

export function* updateMonthlyPrice(action) {
  const { scenario, id, ...updatedMonthlyPrice } = action.payload;
  const { callback } = action;
  try {
    const updated = yield call(serviceMonthlyPrice.updateMonthlyPrice, {
      id,
      ...updatedMonthlyPrice,
    });
    yield put(
      actions.updateMonthlyPriceSucceeded({
        ...updatedMonthlyPrice, // To keep the year property.
        ...updated.data, // New data.
        scenario, // And to keep the scenario property.
      })
    );
    if (callback) yield callback(updated.data);
  } catch (e) {
    yield put(
      actions.updateMonthlyPriceFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === MonthlyPrice.contentType) {
    yield put(
      actions.fetchMonthlyPrice({
        project: object.get('project'),
        scenario: object.get('scenario'),
        id: object.get('id'),
      })
    );
  }
}

function* monthlyPriceSaga() {
  yield all([
    takeEvery(types.MONTHLY_PRICE_UPDATE, updateMonthlyPrice),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default monthlyPriceSaga;
