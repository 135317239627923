// Libraries
import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';
import styled from 'styled-components';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import bfbTheme from 'styles/b4b-theme';
import { AVERAGE_OFFSET } from 'styles/toolColors';
import currencies, { formatCurrency } from 'utils/currencies';

// Components
import { Span } from 'components/fontSystem';

// Locals
import Indicator from '../Indicator';
import LineComponent from '../LineComponent';

const AverageLine = props => {
  const {
    currency,
    average,
    highestValue,
    dashed,
    bold,
    placement,
    shouldRenderTooltip,
    shouldRenderIndicator,
    shouldHideWhenEmpty,
  } = props;

  const { t } = useContext(TranslationsContext);

  const formatCurrencyValue = useCallback(
    (value, shouldPrintEmpty) => {
      const selectedCurrency = currencies[currency] || {};
      return value || shouldPrintEmpty ? formatCurrency(selectedCurrency, value) : 'N/A';
    },
    [currency]
  );

  if (highestValue === 0) return null;

  return (
    <LineComponent
      colorIndex={AVERAGE_OFFSET}
      heightPercentage={(average / highestValue) * 100 || 0}
      dashed={dashed}
      shouldHideWhenEmpty={shouldHideWhenEmpty}
      value={average}
    >
      <TextTooltip hidden={!shouldRenderTooltip}>
        {t('Competitors’ average price per unit')}:{' '}
        {formatCurrencyValue(Number(average).toFixed(2))}
      </TextTooltip>

      {shouldRenderIndicator && (
        <Indicator $colorIndex={AVERAGE_OFFSET} $bold={bold} placement={placement}>
          {t('Competitors’ average price per unit')}:{' '}
          {formatCurrencyValue(Number(average).toFixed(2))}
        </Indicator>
      )}
    </LineComponent>
  );
};

const TextTooltip = styled(Span)`
  position: absolute;
  left: 0;
  top: -10px;
  transform: translateY(-100%);
  background: ${bfbTheme.memberGreen};
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  max-width: 350px;
  width: max-content;
  line-height: normal;

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${bfbTheme.memberGreen} transparent transparent transparent;
  }
`;

AverageLine.propTypes = {
  currency: PropTypes.number,
  average: PropTypes.number,
  highestValue: PropTypes.number,
  dashed: PropTypes.bool,
  bold: PropTypes.bool,
  placement: PropTypes.string,
  shouldRenderTooltip: PropTypes.bool,
  shouldRenderIndicator: PropTypes.bool,
  shouldHideWhenEmpty: PropTypes.bool,
};

AverageLine.defaultProps = {
  currency: undefined,
  average: undefined,
  highestValue: undefined,
  dashed: true,
  bold: true,
  placement: 'top',
  shouldRenderTooltip: false,
  shouldRenderIndicator: false,
  shouldHideWhenEmpty: true,
};

export default AverageLine;
