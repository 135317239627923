import React from 'react';
import PropTypes from 'prop-types';
import { P } from 'components/fontSystem';
import styled from 'styled-components';

const Testimonial = props => {
  const { image, message, fullName, roleType, direction, ...rest } = props;
  return (
    <StyledTestimonial {...rest} direction={direction}>
      <img src={image} alt={`${fullName} ${roleType}`} />
      <div className="info">
        <P $italic className="message" dangerouslySetInnerHTML={{ __html: `"${message}"` }} />
        <div className="card-footer-container">
          <P $primary $bold>
            {fullName}
          </P>
          <P className="role-type" $italic>
            {roleType}
          </P>
        </div>
      </div>
    </StyledTestimonial>
  );
};

const StyledTestimonial = styled.div`
  border-radius: 4px;
  box-shadow: 2px 2px 8px 0 ${({ theme }) => theme.primaryColorFade};
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex: 1 0 auto;
  height: 100%;
  padding: 20px;
  img {
    max-width: 100px;
  }
  .card-footer-container {
    display: flex;
  }
  .info {
    display: flex;
    flex-direction: column;
  }
  .message {
    margin: 20px 0;
    flex: 1;
  }
  .role-type {
    color: ${({ theme }) => theme.greyColor};
    flex: 1;
    padding-left: 10px;
  }
`;

Testimonial.propTypes = {
  image: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  roleType: PropTypes.string.isRequired,
  direction: PropTypes.string,
};
Testimonial.defaultProps = {
  direction: 'column',
};

export default Testimonial;
