import api from '../api';

const createValue = ({ ...value }) => api.post('api/values/', { ...value });

const deleteValue = ({ id }) => api.delete(`api/values/${id}`);

const fetchValue = ({ id }) => api.get(`api/values/${id}/`);

const listValues = query => api.get('/api/values/', { params: query });

const updateValue = ({ id, ...updatedValue }) =>
  api.patch(`api/values/${id}/`, { ...updatedValue });

export default {
  createValue,
  deleteValue,
  fetchValue,
  listValues,
  updateValue,
};
