import api from './api';

const createComment = ({ ...comment }) => api.post('api/comments/', { ...comment });

const deleteComment = ({ id }) => api.delete(`api/comments/${id}/`);

const fetchComment = ({ id }) => api.get(`api/comments/${id}/`);

const listComments = params => api.get('api/comments/', { params });

const updateComment = ({ id, ...updatedComment }) =>
  api.patch(`api/comments/${id}/`, { ...updatedComment });

export default {
  createComment,
  deleteComment,
  fetchComment,
  listComments,
  updateComment,
};
