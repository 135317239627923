import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Icon from '@ant-design/icons';
import { ReactComponent as TransparentCircleSVG } from 'static/icons/loaders/circle-spinner-transparent.svg';
import { ReactComponent as CircleSVG } from 'static/icons/loaders/circle-spinner.svg';

const RawLoader = ({ className = '', transparent = false }) => (
  <div className={className}>
    <Icon component={transparent ? TransparentCircleSVG : CircleSVG} spin />
  </div>
);

RawLoader.propTypes = {
  className: PropTypes.string,
  transparent: PropTypes.bool,
};

const Loader = styled(RawLoader)`
  &&& {
    font-size: 40px;
    cursor: default;
    ${({ type }) =>
      type === 'secondary'
        ? css`
            text-align: center;
          `
        : css`
            position: absolute;
            top: 50%;
            left: 50%;
          `}

    i {
      font-size: 40px;
      margin: 10px;
    }
  }
`;

export default Loader;
