const CHAIN_CLEAR_ERROR = 'bfb/CHAIN/CHAIN_CLEAR_ERROR';
const CHAINS_LIST = 'bfb/CHAIN/CHAINS_LIST';
const CHAINS_LIST_SUCCEEDED = 'bfb/CHAIN/CHAINS_LIST_SUCCEEDED';
const CHAINS_LIST_FAILED = 'bfb/CHAIN/CHAINS_LIST_FAILED';
const CHAIN_CREATE = 'bfb/CHAIN/CHAIN_CREATE';
const CHAIN_CREATE_SUCCEEDED = 'bfb/CHAIN/CHAIN_CREATE_SUCCEEDED';
const CHAIN_CREATE_FAILED = 'bfb/CHAIN/CHAIN_CREATE_FAILED';
const CHAIN_DELETE = 'bfb/CHAIN/CHAIN_DELETE';
const CHAIN_DELETE_SUCCEEDED = 'bfb/CHAIN/CHAIN_DELETE_SUCCEEDED';
const CHAIN_DELETE_FAILED = 'bfb/CHAIN/CHAIN_DELETE_FAILED';
const CHAIN_FETCH = 'bfb/CHAIN/CHAIN_FETCH';
const CHAIN_FETCH_SUCCEEDED = 'bfb/CHAIN/CHAIN_FETCH_SUCCEEDED';
const CHAIN_FETCH_FAILED = 'bfb/CHAIN/CHAIN_FETCH_FAILED';
const CHAIN_UPDATE = 'bfb/CHAIN/CHAIN_UPDATE';
const CHAIN_UPDATE_SUCCEEDED = 'bfb/CHAIN/CHAIN_UPDATE_SUCCEEDED';
const CHAIN_UPDATE_FAILED = 'bfb/CHAIN/CHAIN_UPDATE_FAILED';

export default {
  CHAINS_LIST,
  CHAINS_LIST_SUCCEEDED,
  CHAINS_LIST_FAILED,
  CHAIN_CLEAR_ERROR,
  CHAIN_CREATE,
  CHAIN_CREATE_SUCCEEDED,
  CHAIN_CREATE_FAILED,
  CHAIN_DELETE,
  CHAIN_DELETE_SUCCEEDED,
  CHAIN_DELETE_FAILED,
  CHAIN_FETCH,
  CHAIN_FETCH_SUCCEEDED,
  CHAIN_FETCH_FAILED,
  CHAIN_UPDATE,
  CHAIN_UPDATE_SUCCEEDED,
  CHAIN_UPDATE_FAILED,
};
