import DemoExample from 'models/DemoExample';
import * as reduxHelper from 'store/utils/reduxHelper';
import types from './types';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { list: [] },
    errorMessage: '',
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.DEMO_EXAMPLES_LIST:
      return reduxHelper.mergeState(state, reduxHelper.applyRequestInfo());
    case types.DEMO_EXAMPLES_LIST_SUCCEEDED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyRequestInfo({
          data: {
            list: [...action.payload.map(item => new DemoExample(item))],
          },
        })
      );
    case types.DEMO_EXAMPLES_LIST_FAILED:
      return reduxHelper.mergeState(
        state,
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
