const PROFILE_PICTURE_REQUEST = 'bfb/profilePicture/PROFILE_PICTURE_REQUEST';
const PROFILE_PICTURE_SUCCEEDED = 'bfb/profilePicture/PROFILE_PICTURE_SUCCEEDED';
const PROFILE_PICTURE_FAILED = 'bfb/profilePicture/PROFILE_PICTURE_FAILED';
const PROFILE_PICTURE_EDIT = 'bfb/profilePicture/PROFILE_PICTURE_EDIT_REQUESTED';
const PROFILE_PICTURE_EDIT_SUCCEEDED = 'bfb/profilePicture/PROFILE_PICTURE_EDIT_SUCCEEDED';
const PROFILE_PICTURE_EDIT_FAILED = 'bfb/profilePicture/PROFILE_PICTURE_EDIT_FAILED';
const PROFILE_PICTURE_CREATE = 'bfb/profilePicture/PROFILE_PICTURE_CREATE_REQUESTED';
const PROFILE_PICTURE_CREATE_SUCCEEDED = 'bfb/profilePicture/PROFILE_PICTURE_CREATE_SUCCEEDED';
const PROFILE_PICTURE_CREATE_FAILED = 'bfb/profilePicture/PROFILE_PICTURE_CREATE_FAILED';

export default {
  PROFILE_PICTURE_REQUEST,
  PROFILE_PICTURE_SUCCEEDED,
  PROFILE_PICTURE_FAILED,
  PROFILE_PICTURE_EDIT,
  PROFILE_PICTURE_EDIT_SUCCEEDED,
  PROFILE_PICTURE_EDIT_FAILED,
  PROFILE_PICTURE_CREATE,
  PROFILE_PICTURE_CREATE_SUCCEEDED,
  PROFILE_PICTURE_CREATE_FAILED,
};
