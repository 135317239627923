import api from '../api';

const createBenefit = ({ project, product, segment, ...benefit }) =>
  api.post(`api/projects/${project}/products/${product}/segments/${segment}/benefits/`, {
    ...benefit,
  });

const deleteBenefit = ({ project, product, segment, benefitId }) =>
  api.delete(
    `api/projects/${project}/products/${product}/segments/${segment}/benefits/${benefitId}/`
  );

const fetchBenefit = ({ project, product, segment, id }) =>
  api.get(`api/projects/${project}/products/${product}/segments/${segment}/benefits/${id}/`);

const listBenefits = ({ project, product, segment }) =>
  api.get(`api/projects/${project}/products/${product}/segments/${segment}/benefits/`);

const updateBenefit = ({ project, product, segment, benefitId, ...benefit }) =>
  api.patch(
    `api/projects/${project}/products/${product}/segments/${segment}/benefits/${benefitId}/`,
    { ...benefit }
  );

export default {
  createBenefit,
  deleteBenefit,
  fetchBenefit,
  listBenefits,
  updateBenefit,
};
