import { call, put, all, takeEvery } from 'redux-saga/effects';
import programMembersService from 'services/programMembers';
import types from './types';
import actionTypes from './actions';

export const actions = actionTypes;

export function* listProgramMembers(action) {
  try {
    const { programId, organizationId, ...query } = action && action.payload;
    const programMembers = yield call(programMembersService.listProgramMembers, {
      organizationId,
      programId,
      ...query,
    });
    yield put(
      actions.listProgramMembersSucceeded(programMembers.data.results, programMembers.data.count)
    );
  } catch (e) {
    yield put(actions.listProgramMembersFailed({ message: e.message }));
  }
}

function* programSaga() {
  yield all([takeEvery(types.PROGRAM_MEMBERS_LIST, listProgramMembers)]);
}

export default programSaga;
