import cogsTypes from '../financialProduct/types';
import monthlyMonthlyCogsTypes from './types';

const updateMonthlyCogs = ({ scenario, ...updatedMonthlyCogs }, callback) => ({
  type: monthlyMonthlyCogsTypes.MONTHLY_COGS_UPDATE,
  payload: { scenario, ...updatedMonthlyCogs },
  callback,
});

const updateMonthlyCogsSucceeded = payload => ({
  type: cogsTypes.MONTHLY_COGS_UPDATE_SUCCEEDED,
  payload,
});

const updateMonthlyCogsFailed = ({ message }) => ({
  type: cogsTypes.MONTHLY_COGS_UPDATE_FAILED,
  payload: { message },
});

export default {
  updateMonthlyCogs,
  updateMonthlyCogsFailed,
  updateMonthlyCogsSucceeded,
};
