import styled, { css } from 'styled-components';

const panelColor = ({ theme, $colorIndex, $light }) => {
  if (!$colorIndex) return 'white';
  return $light ? `${theme.getToolColor($colorIndex)}19` : theme.getToolColor($colorIndex);
};

const LG_SCREEN_DEFAULT_SM_MARGIN_BOTTOM = '20px';
const LG_SCREEN_DEFAULT_LG_PADDING = '30px';
const LG_SCREEN_DEFAULT_SM_PADDING = '20px';

const MD_SCREEN_DEFAULT_SM_MARGIN_BOTTOM = '15px';
const MD_SCREEN_DEFAULT_LG_PADDING = '20px';
const MD_SCREEN_DEFAULT_SM_PADDING = '10px';

const SM_SCREEN_DEFAULT_SM_MARGIN_BOTTOM = '10px';
const SM_SCREEN_DEFAULT_LG_PADDING = '10px';
const SM_SCREEN_DEFAULT_SM_PADDING = '10px';

function getPaddingAndMarginProperties(props, mb, smDefaultPadding, lgDefaultPadding) {
  return css`
    margin-bottom: ${props.mb || mb};
    margin-left: ${props.ml || '0px'};
    margin-right: ${props.mr || '0px'};
    margin-top: ${props.mt || '0px'};
    padding-left: ${props.pl || (props.size === 'small' ? smDefaultPadding : lgDefaultPadding)};
    padding-right: ${props.pr || (props.size === 'small' ? smDefaultPadding : lgDefaultPadding)};
    padding-bottom: ${props.pb || (props.size === 'small' ? smDefaultPadding : lgDefaultPadding)};
    padding-top: ${props.pt || (props.size === 'small' ? smDefaultPadding : lgDefaultPadding)};
  `;
}

const Panel = styled.div`
  position: relative;
  ${({ position }) =>
    position === 'sticky' &&
    css`
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 60px;
      z-index: 1;
    `};
  background-color: ${({ $bgColor }) => $bgColor || panelColor};
  background-image: ${({ $bgImage }) => $bgImage || 'none'};

  border-radius: ${({ $hardCorners }) => ($hardCorners ? '0' : '4px')};

  height: ${({ $flex }) => $flex && '100%'};

  /* Padding and marging properties: */
  ${props => css`
    @media (max-width: ${props.theme.screenMdMin}) {
      ${getPaddingAndMarginProperties(
        props,
        MD_SCREEN_DEFAULT_SM_MARGIN_BOTTOM,
        MD_SCREEN_DEFAULT_LG_PADDING,
        MD_SCREEN_DEFAULT_SM_PADDING
      )};
    }
    @media (max-width: ${props.theme.screenSmMin}) {
      ${getPaddingAndMarginProperties(
        props,
        SM_SCREEN_DEFAULT_SM_MARGIN_BOTTOM,
        SM_SCREEN_DEFAULT_LG_PADDING,
        SM_SCREEN_DEFAULT_SM_PADDING
      )};
    }
    ${getPaddingAndMarginProperties(
      props,
      LG_SCREEN_DEFAULT_SM_MARGIN_BOTTOM,
      LG_SCREEN_DEFAULT_SM_PADDING,
      LG_SCREEN_DEFAULT_LG_PADDING
    )};
  `}
`;

export default Panel;
