// Libraries
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { InfoCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';

// Dependencies
import theme from 'styles/b4b-theme';

const TOOL6_SLUG = 'financial-projections';

const InfoBubble = ({
  infotext,
  placement = 'bottomRight',
  trigger = 'click',
  style = null,
  HTMLinfotext = null,
  ...rest
}) => {
  const params = useParams();
  const { slug } = params;
  /*
  Switch function which decides which formatting to use for bubbles based upon current step
  */
  let infoContent;

  switch (slug) {
    case TOOL6_SLUG:
      infoContent = <div style={{ maxWidth: 700 }}>{infotext}</div>;
      break;
    default:
      infoContent = HTMLinfotext ? (
        <StyledDiv dangerouslySetInnerHTML={{ __html: HTMLinfotext }} />
      ) : (
        <StyledDiv>{infotext}</StyledDiv>
      );
  }

  return (
    <Popover trigger={trigger} placement={placement} {...rest} content={infoContent}>
      <StyledInfoIcon style={style} />
    </Popover>
  );
};

InfoBubble.propTypes = {
  infotext: PropTypes.node.isRequired,
  HTMLinfotext: PropTypes.string,
  placement: PropTypes.string,
  trigger: PropTypes.string,
  style: PropTypes.instanceOf(Object),
};

const StyledInfoIcon = styled(InfoCircleFilled)`
  &&& {
    font-size: 20px;
    color: ${theme.placeholderGrey};
    cursor: pointer;
    transition: all ease-in 0.15s;

    &:hover {
      color: ${theme.teal};
    }
  }
`;

const StyledDiv = styled.div`
  color: black;
  margin-bottom: 0;
  max-width: 400px;
  padding: 8px 4px;
`;

export default InfoBubble;
