// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Panel } from 'components/elements';

const GradientPanel = props => {
  const { children, className } = props;
  return (
    <Panel
      className={className}
      pb="20px"
      pt="20px"
      $bgImage="linear-gradient(to right, #123DC6, #00FFAF)"
    >
      {children}
    </Panel>
  );
};

GradientPanel.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default GradientPanel;
