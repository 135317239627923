import types from './types';

const fetchPdfTools = (query, callback, shouldFetchObj) => ({
  type: types.PDF_TOOL_FETCH,
  payload: query,
  callback,
  shouldFetchObj,
});

const fetchPdfToolsSucceeded = payload => ({
  type: types.PDF_TOOL_FETCH_SUCCEEDED,
  payload,
});

const fetchPdfToolsFailed = ({ message }) => ({
  type: types.PDF_TOOL_FETCH_FAILED,
  payload: message,
});

const clearPdfTools = (query, callback) => ({
  type: types.PDF_TOOL_CLEAR,
  payload: query,
  callback,
});

export default {
  fetchPdfTools,
  fetchPdfToolsSucceeded,
  fetchPdfToolsFailed,
  clearPdfTools,
};
