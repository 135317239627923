import types from './types';

const clearError = () => ({
  type: types.MOONSHOT_GOAL_CLEAR_ERROR,
});

const createMoonshotGoal = ({ project, ...moonshotGoal }) => ({
  type: types.MOONSHOT_GOAL_CREATE,
  payload: { project, ...moonshotGoal },
});

const createMoonshotGoalSucceeded = ({ project, ...moonshotGoal }) => ({
  type: types.MOONSHOT_GOAL_CREATE_SUCCEEDED,
  payload: { project, ...moonshotGoal },
});

const createMoonshotGoalFailed = ({ message }) => ({
  type: types.MOONSHOT_GOAL_CREATE_FAILED,
  payload: { message },
});

const deleteMoonshotGoal = ({ project, id }) => ({
  type: types.MOONSHOT_GOAL_DELETE,
  payload: { project, id },
});

const deleteMoonshotGoalSucceeded = ({ project, id }) => ({
  type: types.MOONSHOT_GOAL_DELETE_SUCCEEDED,
  payload: { project, id },
});

const deleteMoonshotGoalFailed = ({ message }) => ({
  type: types.MOONSHOT_GOAL_DELETE_FAILED,
  payload: message,
});

const fetchMoonshotGoal = ({ project, id }) => ({
  type: types.MOONSHOT_GOAL_FETCH,
  payload: { project, id },
});

const fetchMoonshotGoalSucceeded = ({ project, ...moonshotGoal }) => ({
  type: types.MOONSHOT_GOAL_FETCH_SUCCEEDED,
  payload: { project, ...moonshotGoal },
});

const fetchMoonshotGoalFailed = ({ message }) => ({
  type: types.MOONSHOT_GOAL_FETCH_FAILED,
  payload: { message },
});

const listMoonshotGoals = ({ project }) => ({
  type: types.MOONSHOT_GOALS_LIST,
  payload: { project },
});

const listMoonshotGoalsSucceeded = payload => ({
  type: types.MOONSHOT_GOALS_LIST_SUCCEEDED,
  payload,
});

const listMoonshotGoalsFailed = ({ message }) => ({
  type: types.MOONSHOT_GOALS_LIST_FAILED,
  payload: { message },
});

const updateMoonshotGoal = ({ id, project, ...updatedMoonshotGoal }) => ({
  type: types.MOONSHOT_GOAL_UPDATE,
  payload: { id, project, ...updatedMoonshotGoal },
});

const updateMoonshotGoalSucceeded = ({ project, ...moonshotGoal }) => ({
  type: types.MOONSHOT_GOAL_UPDATE_SUCCEEDED,
  payload: { project, ...moonshotGoal },
});

const updateMoonshotGoalFailed = ({ message }) => ({
  type: types.MOONSHOT_GOAL_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createMoonshotGoal,
  createMoonshotGoalFailed,
  createMoonshotGoalSucceeded,
  deleteMoonshotGoal,
  deleteMoonshotGoalFailed,
  deleteMoonshotGoalSucceeded,
  fetchMoonshotGoal,
  fetchMoonshotGoalFailed,
  fetchMoonshotGoalSucceeded,
  listMoonshotGoals,
  listMoonshotGoalsFailed,
  listMoonshotGoalsSucceeded,
  updateMoonshotGoal,
  updateMoonshotGoalFailed,
  updateMoonshotGoalSucceeded,
};
