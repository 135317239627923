// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Dependencies
import { baseClamp } from './dependencies';

// Components
import Gauge from './Gauge';
import Needle from './Needle';

const Speedometer = props => {
  const {
    id,
    value = 0,
    range = [0, 100],
    radialGradientRanges = [],
    linearGradientRanges = !radialGradientRanges.length
      ? [
          // RED
          [[0, 1], '#F25A5A'],
          // YELLOW
          [[1, 2.5], '#F4D811'],
          // GREEN
          [[2.5, 3.5], '#37CC82'],
          // YELLOW
          [[3.5, 4.5], '#F4D811'],
          // RED
          [[4.5, 5], '#F25A5A'],
        ]
      : [],
    defaultLabelsAmount = 0,
    radialLabels = [],
    width = 300,
    height = width * (2 / 3),
    filledPercentage = 50,
    strokeWidth = width * 0.1,
    needleHeightRatio = 1.1,
    shouldNotAngleText,
    ...rest
  } = props;
  /**
   * If there are no `radialLabels` and `defaultLabelsAmount` is higher than 0,
   * meaning the SVG wants to be rendered with automatic labels, then they're
   * generated using the range as relative points.
   */
  if (!radialLabels.length && defaultLabelsAmount > 0) {
    const [minValue, maxValue] = range;
    // If we only want to print 1 label, then print the one at the middle of the range.
    if (defaultLabelsAmount === 1) {
      const val = (maxValue - minValue) / 2;
      // This will display two decimals if any, or none if there are none.
      radialLabels.push([val, Math.round(val * 100) / 100]);
    } else {
      /**
       * Otherwise the labels will be distributed in equivalent distances/values
       * between each.
       */
      radialLabels.push([minValue, String(minValue)]);
      const loops = defaultLabelsAmount - 1;
      const increment = (maxValue - minValue) / loops;
      for (let i = 0; i < loops; i) {
        i += 1;
        const labelValue = i * increment;
        // This will display two decimals if any, or none if there are none.
        radialLabels.push([labelValue, Math.round(labelValue * 100) / 100]);
      }
    }
  }
  const [minValue, maxValue] = range;
  /**
   * If `value` exists, then it's clamped between the ranges to avoid the needle going out of
   * the limits. Otherwise, the default value is the `minValue`.
   */
  const clampedValue = value ? baseClamp(value, minValue, maxValue) : minValue;
  return (
    <Wrapper id={id} width={width} height={height} {...rest}>
      <Gauge
        id={id}
        range={range}
        radialGradientRanges={radialGradientRanges}
        linearGradientRanges={linearGradientRanges}
        radialLabels={radialLabels}
        width={width}
        height={height}
        filledPercentage={filledPercentage}
        strokeWidth={strokeWidth}
        shouldNotAngleText={shouldNotAngleText}
      />
      <Needle
        value={clampedValue}
        range={range}
        width={width}
        height={height}
        strokeWidth={strokeWidth}
        needleHeightRatio={needleHeightRatio}
      />
    </Wrapper>
  );
};

Speedometer.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  range: PropTypes.instanceOf(Array),
  radialGradientRanges: PropTypes.instanceOf(Array),
  linearGradientRanges: PropTypes.instanceOf(Array),
  defaultLabelsAmount: PropTypes.number,
  radialLabels: PropTypes.instanceOf(Array),
  width: PropTypes.number,
  height: PropTypes.number,
  filledPercentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  needleHeightRatio: PropTypes.number,
  shouldNotAngleText: PropTypes.bool,
};

Speedometer.defaultProps = {
  id: undefined,
  value: undefined,
  range: undefined,
  radialGradientRanges: undefined,
  linearGradientRanges: undefined,
  defaultLabelsAmount: undefined,
  radialLabels: undefined,
  width: undefined,
  height: undefined,
  filledPercentage: undefined,
  strokeWidth: undefined,
  needleHeightRatio: undefined,
  shouldNotAngleText: false,
};

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  svg {
    overflow: visible;
  }
`;

export default React.memo(Speedometer);
