const PROGRAM_STEP_CLEAR_ERROR = 'bfb/program/PROGRAM_STEP_CLEAR_ERROR';
const PROGRAM_STEPS_LIST = 'bfb/program/PROGRAM_STEPS_LIST';
const PROGRAM_STEPS_LIST_SUCCEEDED = 'bfb/program/PROGRAM_STEPS_LIST_SUCCEEDED';
const PROGRAM_STEPS_LIST_FAILED = 'bfb/program/PROGRAM_STEPS_LIST_FAILED';

export default {
  PROGRAM_STEPS_LIST,
  PROGRAM_STEPS_LIST_SUCCEEDED,
  PROGRAM_STEPS_LIST_FAILED,
  PROGRAM_STEP_CLEAR_ERROR,
};
