// Libraries
import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import currencies, { formatCurrency } from 'utils/currencies';

// Components
import { InfoText } from 'components/fontSystem';

export const LegendLine = props => {
  const { average, name, value, currency, colorIndex, dashed, bold } = props;

  const { t } = useContext(TranslationsContext);

  const isValueArray = Array.isArray(value);

  const formatCurrencyValue = useCallback(
    (val, shouldPrintEmpty) => {
      const selectedCurrency = currencies[currency] || {};
      return value || shouldPrintEmpty ? formatCurrency(selectedCurrency, val) : 'N/A';
    },
    [currency, value]
  );

  return (
    <div>
      <LegendLineContainer>
        <StyledLegendLine colorIndex={colorIndex} dashed={dashed} />
      </LegendLineContainer>
      {(name || average) && (
        <InfoText $colorIndex={colorIndex} $bold={bold}>
          {average ? t("Competitor's Average") : name}:
        </InfoText>
      )}
      <InfoText $colorIndex={colorIndex} $bold={bold}>
        {isValueArray ? (
          <React.Fragment>
            {'['}
            {value.map((val, index) => {
              const isLast = index === value.length - 1;
              return `${formatCurrencyValue(val)}${isLast ? '' : '- '}`;
            })}
            {']'}
          </React.Fragment>
        ) : (
          formatCurrencyValue(value)
        )}
      </InfoText>
    </div>
  );
};

LegendLine.propTypes = {
  average: PropTypes.bool,
  name: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Array)]),
  currency: PropTypes.number,
  colorIndex: PropTypes.number,
  dashed: PropTypes.bool,
  bold: PropTypes.bool,
};

LegendLine.defaultProps = {
  average: false,
  name: undefined,
  value: undefined,
  currency: undefined,
  colorIndex: undefined,
  dashed: true,
  bold: true,
};

const Legend = styled.div`
  display: inline-flex;
  align-self: flex-start;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  padding: 20px;
  width: 240px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  ${({ placement = 'right' }) => {
    if (placement === 'left') {
      return css`
        order: -1;
        margin-right: 30px;
      `;
    }
    return css`
      margin-left: 30px;
    `;
  }}
`;

const LegendLineContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
`;

const StyledLegendLine = styled.div`
  transition: bottom ease 200ms;
  will-change: bottom;
  width: 50px;
  height: 2px;
  border-bottom: 2px ${({ dashed }) => (dashed ? 'dashed' : 'solid')}
    ${({ theme, colorIndex }) =>
      colorIndex !== undefined ? theme.getToolColor(colorIndex) : '#999999'};
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
`;

export default Legend;
