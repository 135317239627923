const METRIC_CLEAR_ERROR = 'bfb/growthPlan/moonshotGoal/METRIC_CLEAR_ERROR';
const METRICS_LIST = 'bfb/growthPlan/moonshotGoal/METRICS_LIST';
const METRICS_LIST_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/METRICS_LIST_SUCCEEDED';
const METRICS_LIST_FAILED = 'bfb/growthPlan/moonshotGoal/METRICS_LIST_FAILED';
const METRIC_CREATE = 'bfb/growthPlan/moonshotGoal/METRIC_CREATE';
const METRIC_CREATE_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/METRIC_CREATE_SUCCEEDED';
const METRIC_CREATE_FAILED = 'bfb/growthPlan/moonshotGoal/METRIC_CREATE_FAILED';
const METRIC_DELETE = 'bfb/growthPlan/moonshotGoal/METRIC_DELETE';
const METRIC_DELETE_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/METRIC_DELETE_SUCCEEDED';
const METRIC_DELETE_FAILED = 'bfb/growthPlan/moonshotGoal/METRIC_DELETE_FAILED';
const METRIC_FETCH = 'bfb/growthPlan/moonshotGoal/METRIC_FETCH';
const METRIC_FETCH_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/METRIC_FETCH_SUCCEEDED';
const METRIC_FETCH_FAILED = 'bfb/growthPlan/moonshotGoal/METRIC_FETCH_FAILED';
const METRIC_UPDATE = 'bfb/growthPlan/moonshotGoal/METRIC_UPDATE';
const METRIC_UPDATE_SUCCEEDED = 'bfb/growthPlan/moonshotGoal/METRIC_UPDATE_SUCCEEDED';
const METRIC_UPDATE_FAILED = 'bfb/growthPlan/moonshotGoal/METRIC_UPDATE_FAILED';

export default {
  METRICS_LIST,
  METRICS_LIST_SUCCEEDED,
  METRICS_LIST_FAILED,
  METRIC_CLEAR_ERROR,
  METRIC_CREATE,
  METRIC_CREATE_SUCCEEDED,
  METRIC_CREATE_FAILED,
  METRIC_DELETE,
  METRIC_DELETE_SUCCEEDED,
  METRIC_DELETE_FAILED,
  METRIC_FETCH,
  METRIC_FETCH_SUCCEEDED,
  METRIC_FETCH_FAILED,
  METRIC_UPDATE,
  METRIC_UPDATE_SUCCEEDED,
  METRIC_UPDATE_FAILED,
};
