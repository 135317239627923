import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import EducationExperience from 'models/EducationExperience';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: [],
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.EDUCATION_EXPERIENCE_REQUEST:
      return state.merge(reduxHelper.applyRequestInfo());
    case types.EDUCATION_EXPERIENCE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: action.payload.educationExperienceData.map(
            experience => new EducationExperience(experience)
          ), //eslint-disable-line
        })
      );
    case types.EDUCATION_EXPERIENCE_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.EDUCATION_EXPERIENCE_UPDATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: state.get('data').map(experience => {
            if (experience.id !== action.payload.id) {
              return experience;
            }
            return new EducationExperience(action.payload);
          }),
        })
      );
    case types.EDUCATION_EXPERIENCE_CREATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.EDUCATION_EXPERIENCE_CREATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: [...state.get('data'), new EducationExperience(action.payload)],
        })
      );
    case types.EDUCATION_EXPERIENCE_FAILED:
    case types.EDUCATION_EXPERIENCE_UPDATE_FAILED:
    case types.EDUCATION_EXPERIENCE_CREATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    case types.EDUCATION_EXPERIENCE_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: state.get('data').filter(educationEntry => educationEntry.id !== action.payload),
        })
      );
    default:
      return state;
  }
}
