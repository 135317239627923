import * as reduxHelper from 'store/utils/reduxHelper';
import { mainTheme, memberTheme, mentorTheme } from 'styles/themes';

import types from './types';
import Customization from 'models/Customization';

export const initialState = {
  ...reduxHelper.applyReceiveInfo({
    data: { mainTheme, memberTheme, mentorTheme },
    errorMessage: '',
    updatePending: false,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.CUSTOMIZATION_FETCH_SUCCEEDED:
    case types.CUSTOMIZATION_UPDATE_SUCCEEDED:
      const customization = new Customization(action.payload);
      return reduxHelper.applyReceiveInfo({
        data: {
          ...customization.toJS(),
          mainTheme,
        },
      });
    case types.CUSTOMIZATION_FETCH_FAILED:
    case types.CUSTOMIZATION_UPDATE_FAILED:
      return reduxHelper.applyErrorInfo({
        ...state,
        updatePending: false,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
}
