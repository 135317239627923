// Libraries
import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Actions and selectors
import {
  selectors as notificationsSelectors,
  actions as notificationsActions,
} from 'store/modules/notifications';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import Notifications from 'models/Notifications';
import { itemToToolName, itemToStep } from 'utils/tools';
import { splitItemAsStep } from 'utils/comments';

// Components
import { Button, Radio } from 'components/elements';
import { List, Avatar, Switch, Col } from 'antd';
import { P } from 'components/fontSystem';
import { Row } from 'components/layout';
import getDefaultProfileByUserGender from 'helper/User/getDefaultProfileByUserGender';
import { getFormattedDate } from 'utils/dates';

dayjs.extend(utc);

const getNotifications = (notification, isMember, isMentor, t, format) => {
  const parentObject = notification.parent_object;
  const actionObject = notification.action_object;
  let toolName =
    actionObject && notification.action_object.tool && notification.action_object.tool.name;
  let stepName;

  if (actionObject && notification.action_object.item) {
    const [toolSlug, stepSlug] = splitItemAsStep(notification.action_object.item);
    toolName = itemToToolName(t, toolSlug);
    stepName = itemToStep(t, stepSlug);
  }
  const replacements = {
    userName: notification.user && notification.user.first_name,
    toolName,
    stepName,
    parentObjectName: notification.parent_object && notification.parent_object.name,
  };

  const gender = () => {
    switch (notification.user.gender) {
      case notification.user.gender === 'female':
        return t('her');
      case notification.user.gender === 'male':
        return t('him');
      case notification.user.gender === 'nonbinary' || 'na':
      default:
        return t('them');
    }
  };
  switch (notification.verb) {
    case Notifications.INVITE_SENT:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                '<span>%(userName)s</span> sent you a matching invitation. Review it and take action by <span>%(rankingEnd)s</span>!'
              ),
              {
                ...replacements,
                rankingEnd: getFormattedDate(parentObject.matching_ranking_end, t),
              }
            ),
          }}
        />
      );
    case Notifications.INVITE_ACCEPTED:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                '<span>%(userName)s</span> accepted your matching invitation. Please coordinate a meeting time with <span>%(gender)s</span>!'
              ),
              {
                ...replacements,
                gender: gender(),
              }
            ),
          }}
        />
      );
    case Notifications.INVITE_REJECTED_BY_E:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t('<span>%(userName)s</span> rejected your matching invitation. Keep exploring!'),
              {
                ...replacements,
              }
            ),
          }}
        />
      );
    case Notifications.INVITE_SIGN_PENDING:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                '<span>%(userName)s</span> signed the mentorship agreement. They are waiting for you to sign too!'
              ),
              {
                ...replacements,
              }
            ),
          }}
        />
      );
    case Notifications.INVITE_MATCHED:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                '<span>%(userName)s</span> signed the mentorship agreement. You can now access the tools! Coordinate your meeting with your mentee!'
              ),
              {
                ...replacements,
              }
            ),
          }}
        />
      );
    case Notifications.E_APP_APPROVED:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                'Congratulations! We would love to have you participate in <span>%(programName)s</span>. Please enter your payment details to officially accept your spot.'
              ),
              {
                ...replacements,
                programName: replacements.parentObjectName,
              }
            ),
          }}
        />
      );
    case Notifications.E_APP_REJECTED:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                'Thanks for applying to <span>%(programName)s</span>, unfortunately we were unable to accept your application at this time, however, you can choose to apply to a future program and improve your application to increase your chances of getting in.'
              ),
              {
                ...replacements,
                programName: replacements.parentObjectName,
              }
            ),
          }}
        />
      );
    case Notifications.CREATED_COMMENT:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                '<span>%(userName)s</span> added a comment in <span>%(toolName)s</span>, <span>%(stepName)s</span>.'
              ),
              replacements
            ),
          }}
        />
      );
    case isMember && Notifications.TOOL_COMPLETED:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(t('<span>%(userName)s</span> finished <span>%(toolName)s</span>.'), {
              ...replacements,
              toolName: t(replacements.toolName),
            }),
          }}
        />
      );
    case isMentor && Notifications.TOOL_COMPLETED:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t('<span>%(userName)s</span> finished <span>%(toolName)s</span>. Please review it.'),
              {
                ...replacements,
                toolName: t(replacements.toolName),
              }
            ),
          }}
        />
      );
    case Notifications.TOOL_REVIEWED:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(t('<span>%(userName)s</span> reviewed <span>%(toolName)s</span>.'), {
              ...replacements,
              toolName: t(replacements.toolName),
            }),
          }}
        />
      );
    case isMentor && Notifications.FINANCIAL_SCENARIO_UPLOAD:
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: format(
              t(
                '<span>%(userName)s</span>, you have at least one step in the <p>Financial Forecast</p> module ready for review.'
              ),
              replacements
            ),
          }}
        />
      );
    default:
      return null;
  }
};

const { listNotifications, updateNotification } = notificationsActions;

const PAGE_SIZE = 10;

const getPagination = page => ({
  limit: PAGE_SIZE,
  offset: (page - 1) * PAGE_SIZE,
});

const NotificationsContent = ({
  isVisible = false,
  onNotificationClick = null,
  projectId = undefined,
  mentorProjectIds = [],
  ventureId = undefined,
  isMember,
  isMentor,
}) => {
  const { t, format } = useContext(TranslationsContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notifications = useSelector(notificationsSelectors.getNotificationsList);
  const totalNotificationsCount = useSelector(notificationsSelectors.getTotalNotificationsCount);

  const [state, setState] = React.useState({
    initLoading: true,
    page: 1,
    checked: false,
  });

  const onLoadMore = () => {
    const pagination = getPagination(state.page).offset;
    if (pagination < totalNotificationsCount) {
      setState(prevState => ({ ...prevState, page: prevState.page + 1 }));
    }
  };

  React.useEffect(() => {
    const filters = {
      seen_at__isnull: state.checked ? 1 : null,
      clear: state.page === 1,
    };
    if (isVisible) {
      dispatch(
        listNotifications(
          {
            ...filters,
            ...getPagination(state.page),
          },
          () => setState(prevState => ({ ...prevState, initLoading: false }))
        )
      );
    }
  }, [dispatch, state.checked, state.page, isVisible]);

  React.useEffect(() => {
    if (isVisible) {
      setState(prevState => ({ ...prevState, initLoading: true, page: 1 }));
    }
  }, [isVisible]);

  const handleRadioClick = notification => {
    dispatch(
      updateNotification({
        id: notification.id,
        seen_at: notification.seen_at ? null : dayjs().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
    );
  };

  const handleSwitch = checked => {
    setState(prevState => ({ ...prevState, page: 1, checked }));
  };

  const handleListItemClick = notification => {
    onNotificationClick(false);
    if (notification.seen_at === null) {
      handleRadioClick(notification);
    }
    const parentId = notification.parent_object.id;
    const id =
      (notification &&
        notification.parent_object &&
        notification.parent_object.project &&
        mentorProjectIds &&
        mentorProjectIds.find(singleId => singleId === notification.parent_object.project.id)) ||
      (ventureId && ventureId !== undefined ? ventureId : projectId);

    switch (notification.verb) {
      case Notifications.INVITE_SENT:
      case isMember && Notifications.INVITE_SIGN_PENDING:
        return navigate(`/program/${parentId}/matching?tab=2`);
      case Notifications.INVITE_REJECTED_BY_E:
        return navigate(`/mentor-matching/${parentId}/explore?tab=1`);
      case Notifications.INVITE_ACCEPTED:
      case isMentor && Notifications.INVITE_SIGN_PENDING:
        return navigate(`/mentor-matching/${parentId}/explore?tab=2`);
      case Notifications.INVITE_MATCHED:
        return navigate('/');
      case Notifications.E_APP_APPROVED:
        return navigate(`/program/${parentId}/accept-spot`);
      case Notifications.E_APP_REJECTED:
        return navigate('/');
      case Notifications.CREATED_COMMENT: {
        const formatLink = splitItemAsStep(notification.action_object.item).join('/');
        const tabParams = notification.action_object.scenario
          ? `scenario=${notification.action_object.scenario.id}`
          : `product=${notification.action_object.product.id}`;
        return navigate({
          pathname: `/tools/${id}/${formatLink}`,
          search: `?contentType=${notification.action_object.content_type}&id=${notification.action_object.object_id}&item=${notification.action_object.item}&${tabParams}`,
        });
      }
      case isMember && Notifications.TOOL_COMPLETED:
        return navigate(`/venture/${projectId}/workspace`);
      case isMentor && Notifications.TOOL_COMPLETED:
        return navigate(`/tools/${id}/${notification.action_object.tool.slug}/summary`);
      case Notifications.TOOL_REVIEWED:
        return navigate(`/venture/${projectId}/workspace`);
      case Notifications.FINANCIAL_SCENARIO_UPLOAD:
        return navigate(`/tools/${parentId}/financial-forecast/${notification.action_object.slug}`);
      default:
        return null;
    }
  };
  const paginationOffset = getPagination(state.page).offset;
  const { checked, initLoading } = state;

  return (
    <>
      <StyledDivider />
      <SyledRow type="flex" justify="end">
        <Col>
          <P $semibold={!checked} color="black">
            {t('All')}
          </P>
        </Col>
        <Col>
          <Switch onChange={handleSwitch} defaultChecked={false} />
        </Col>
        <Col>
          <P $semibold={checked} color="black">
            {t('Unread')}
          </P>
        </Col>
      </SyledRow>
      <StyledList
        itemLayout="horizontal"
        dataSource={notifications}
        loading={initLoading}
        locale={{
          emptyText: (
            <>
              <span role="img" aria-label={t('congratulations!')}>
                &#x1f389;
              </span>
              <P>{t('You have caught up with all notifications!')}</P>
            </>
          ),
        }}
        loadMore={
          <Button
            disabled={paginationOffset > totalNotificationsCount}
            size="small"
            style={{ marginLeft: 0, display: 'block', width: '100%' }}
            onClick={onLoadMore}
          >
            {t('Load more')}
          </Button>
        }
        renderItem={notification => (
          <StyledListItem>
            <List.Item.Meta
              onClick={() => handleListItemClick(notification)}
              avatar={
                <>
                  <Radio
                    onClick={() => handleRadioClick(notification)}
                    checked={!notification.seen_at}
                  />
                  <Avatar
                    shape="square"
                    src={
                      (notification.user && notification.user.picture) ||
                      getDefaultProfileByUserGender(notification.user)
                    }
                  />
                </>
              }
              title={getNotifications(notification, isMember, isMentor, t, format)}
              description={format(t('%(ventureName)s, %(notificationDate)s'), {
                ventureName:
                  notification &&
                  notification.parent_object &&
                  (notification.parent_object.name || notification.parent_object.project.name),
                notificationDate: dayjs(notification.created).format(t('MMM D [at] LT')),
              })}
            />
          </StyledListItem>
        )}
      />
    </>
  );
};

const SyledRow = styled(Row)`
  align-items: center;
  padding-right: 20px;
  .ant-col {
    padding: 0 2px !important;
  }
  p {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: right;
    margin-bottom: 0;
  }
  .ant-switch {
    background-color: #d9d9d9 !important;
    min-width: 34px;
    height: 19px;
    ::after {
      width: 16px;
      height: 16px;
      border-radius: 16px;
    }
  }
  .ant-switch-handle::before {
    height: 15px;
  }
  .ant-switch-checked {
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor} !important;
  }
`;

const StyledList = styled(List)`
  .ant-spin-nested-loading {
    overflow: scroll;
    height: 430px;
  }
`;

const StyledListItem = styled(List.Item)`
  &.ant-list-item {
    padding: 19px 0;
  }
  .ant-list-empty-text {
    img {
      color: unset;
    }
    p {
      color: #999999;
      font-style: italic;
      line-height: 25px;
    }
  }
  cursor: pointer;
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme: { secondaryColor } }) => secondaryColor};
      ::after {
        background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
        transform: scale(2);
      }
    }
    span.ant-radio {
      padding-right: 0px;
    }
  }
  span.ant-radio {
    padding-right: 9.5px;
  }
  &&& .ant-radio-inner {
    height: 13px;
    width: 13px;
  }
  &&& .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
  }
  &&& .ant-list-item-meta-avatar {
    margin-inline-end: 10px;
  }
  .ant-list-item-meta {
    align-items: center;
    .ant-list-item-meta-avatar {
      display: flex;
      align-items: center;
      label.ant-radio-wrapper {
        margin-right: 9.5px;
      }
      span.ant-avatar.ant-avatar-square.ant-avatar-image {
        display: flex;
        align-items: center;
        border-radius: 0;
        border: none;
        width: 40px;
        height: 40px;
      }
    }
    .ant-list-item-meta-avatar .ant-list-item-meta-title span {
      span {
        color: ${({ theme: { primaryColor } }) => primaryColor};
      }
    }
  }
  .ant-list-item-meta-description {
    font-size: 12px;
    line-height: 22px;
    color: #999999;
  }
`;

const StyledDivider = styled.hr`
  height: 2px;
  border: none;
  background: ${({ theme: { primaryColor } }) => primaryColor};
  background: ${({ theme: { primaryColor, secondaryColor } }) =>
    `linear-gradient(90deg,${primaryColor} 0%, ${secondaryColor} 100%)`};
  margin: 10px -12px;
`;

NotificationsContent.propTypes = {
  isMember: PropTypes.bool.isRequired,
  isMentor: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  onNotificationClick: PropTypes.func,
  projectId: PropTypes.number,
  mentorProjectIds: PropTypes.instanceOf(Array),
  ventureId: PropTypes.number,
};

export default NotificationsContent;
