import types from './types';

const clearError = () => ({
  type: types.OBJECTIVE_CLEAR_ERROR,
});

const createObjective = ({ project, ...objective }) => ({
  type: types.OBJECTIVE_CREATE,
  payload: { project, ...objective },
});

const createObjectiveSucceeded = ({ project, ...objective }) => ({
  type: types.OBJECTIVE_CREATE_SUCCEEDED,
  payload: { project, ...objective },
});

const createObjectiveFailed = ({ message }) => ({
  type: types.OBJECTIVE_CREATE_FAILED,
  payload: { message },
});

const deleteObjective = ({ project, id }) => ({
  type: types.OBJECTIVE_DELETE,
  payload: { project, id },
});

const deleteObjectiveSucceeded = ({ project, id }) => ({
  type: types.OBJECTIVE_DELETE_SUCCEEDED,
  payload: { project, id },
});

const deleteObjectiveFailed = ({ message }) => ({
  type: types.OBJECTIVE_DELETE_FAILED,
  payload: message,
});

const fetchObjective = ({ project, id }) => ({
  type: types.OBJECTIVE_FETCH,
  payload: { project, id },
});

const fetchObjectiveSucceeded = ({ project, ...objective }) => ({
  type: types.OBJECTIVE_FETCH_SUCCEEDED,
  payload: { project, ...objective },
});

const fetchObjectiveFailed = ({ message }) => ({
  type: types.OBJECTIVE_FETCH_FAILED,
  payload: { message },
});

const listObjectives = ({ project }) => ({
  type: types.OBJECTIVES_LIST,
  payload: { project },
});

const listObjectivesSucceeded = payload => ({
  type: types.OBJECTIVES_LIST_SUCCEEDED,
  payload,
});

const listObjectivesFailed = ({ message }) => ({
  type: types.OBJECTIVES_LIST_FAILED,
  payload: { message },
});

const updateObjective = ({ id, project, ...updatedObjective }) => ({
  type: types.OBJECTIVE_UPDATE,
  payload: { id, project, ...updatedObjective },
});

const updateObjectiveSucceeded = ({ project, ...objective }) => ({
  type: types.OBJECTIVE_UPDATE_SUCCEEDED,
  payload: { project, ...objective },
});

const updateObjectiveFailed = ({ message }) => ({
  type: types.OBJECTIVE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createObjective,
  createObjectiveFailed,
  createObjectiveSucceeded,
  deleteObjective,
  deleteObjectiveFailed,
  deleteObjectiveSucceeded,
  fetchObjective,
  fetchObjectiveFailed,
  fetchObjectiveSucceeded,
  listObjectives,
  listObjectivesFailed,
  listObjectivesSucceeded,
  updateObjective,
  updateObjectiveFailed,
  updateObjectiveSucceeded,
};
