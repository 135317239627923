import api from '../api';

const fetchOutcome = ({ id }) => api.get(`/api/outcomes/${id}`);

const createOutcome = outcome => api.post('/api/outcomes/', outcome);

const deleteOutcome = ({ id }) => api.delete(`/api/outcomes/${id}`);

const listOutcomes = query => api.get('/api/outcomes/', { params: query });

const updateOutcome = ({ id, ...outcome }) => api.patch(`/api/outcomes/${id}/`, outcome);

export default {
  createOutcome,
  deleteOutcome,
  fetchOutcome,
  listOutcomes,
  updateOutcome,
};
