import api from '../api';

const createInvestment = ({ ...operatingCapital }) =>
  api.post('api/investments/', { ...operatingCapital });

const deleteInvestment = ({ id }) => api.delete(`api/investments/${id}/`);

const fetchInvestment = ({ id }) => api.get(`api/investments/${id}/`);

const listInvestments = ({ scenario }) => api.get(`api/investments/?scenario=${scenario}`);

const updateInvestment = ({ id, ...operatingCapital }) =>
  api.patch(`api/investments/${id}/`, { ...operatingCapital });

export default {
  createInvestment,
  deleteInvestment,
  fetchInvestment,
  listInvestments,
  updateInvestment,
};
