const TRANSLATION_FETCH_REQUESTED = 'bfb/translations/TRANSLATION_FETCH_REQUESTED';
const TRANSLATION_FETCH_SUCCEEDED = 'bfb/translations/TRANSLATION_FETCH_SUCCEEDED';
const TRANSLATION_FETCH_FAILED = 'bfb/translations/TRANSLATION_FETCH_FAILED';
const TRANSLATION_CHANGE = 'bfb/translations/TRANSLATION_CHANGE';

export default {
  TRANSLATION_FETCH_REQUESTED,
  TRANSLATION_FETCH_SUCCEEDED,
  TRANSLATION_FETCH_FAILED,
  TRANSLATION_CHANGE,
};
