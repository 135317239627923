import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import serviceKeyResult from 'services/GrowthPlan/keyResult';
import { types as commentTypes } from 'store/modules/comment';
import KeyResult from 'models/GrowthPlan/KeyResult';
import types from './types';
import actions from './actions';

export function* createKeyResult(action) {
  const { objective, ...keyResult } = action.payload;
  const { callback } = action;
  try {
    const response = yield call(serviceKeyResult.createKeyResult, {
      objective,
      ...keyResult,
    });
    yield put(actions.createKeyResultSucceeded({ ...response.data }));
    if (callback) yield callback(response.data.id);
  } catch (e) {
    yield put(
      actions.createKeyResultFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* deleteKeyResult(action) {
  const { objective, keyResultId } = action.payload;
  const { callback } = action;
  try {
    yield call(serviceKeyResult.deleteKeyResult, {
      objective,
      id: keyResultId,
    });
    yield put(actions.deleteKeyResultsSucceeded({ id: keyResultId }));
    if (callback) yield callback(keyResultId);
  } catch (e) {
    yield put(
      actions.deleteKeyResultFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* fetchKeyResult(action) {
  const { id } = action.payload;
  try {
    const response = yield call(serviceKeyResult.fetchKeyResult, { id });
    yield put(actions.fetchKeyResultSucceeded({ ...response.data }));
  } catch (e) {
    yield put(actions.fetchKeyResultFailed({ message: e.message }));
  }
}

export function* listKeyResults(action) {
  const { objective } = action.payload;
  try {
    const keyResults = yield call(serviceKeyResult.listKeyResults, {
      objective,
    });
    yield put(actions.listKeyResultsSucceeded({ results: keyResults.data }));
  } catch (e) {
    yield put(actions.listKeyResultsFailed({ message: e.message }));
  }
}

export function* updateKeyResult(action) {
  const { objective, keyResultId, ...keyResult } = action.payload;
  try {
    const updated = yield call(serviceKeyResult.updateKeyResult, {
      objective,
      id: keyResultId,
      ...keyResult,
    });
    yield put(actions.updateKeyResultSucceeded({ ...updated.data }));
  } catch (e) {
    yield put(
      actions.updateKeyResultFailed({
        message: (e.response && e.response.data) || e.message,
      })
    );
  }
}

export function* updateObjectComments(action) {
  const { object } = action.payload;
  if (object.get('contentType') === KeyResult.contentType) {
    yield put(
      actions.fetchKeyResult({
        project: object.get('project'),
        product: object.get('product'),
        id: object.get('id'),
      })
    );
  }
}

function* saga() {
  yield all([
    takeLatest(types.KEY_RESULT_CREATE, createKeyResult),
    takeLatest(types.KEY_RESULT_DELETE, deleteKeyResult),
    takeLatest(types.KEY_RESULT_FETCH, fetchKeyResult),
    takeLatest(types.KEY_RESULT_UPDATE, updateKeyResult),
    takeEvery(types.KEY_RESULTS_LIST, listKeyResults),
    takeLatest(commentTypes.COMMENT_CREATE_SUCCEEDED, updateObjectComments),
    takeLatest(commentTypes.COMMENT_DELETE_SUCCEEDED, updateObjectComments),
  ]);
}

export default saga;
