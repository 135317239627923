import { createSelector } from 'reselect';

const getState = state => state.pain;

const getRawPains = createSelector([getState], state => state.get('data'));

const getPainsBySegment = createSelector([getRawPains], state => state.get('bySegment'));

const getPainsById = createSelector([getRawPains], state => state.get('byId'));

const getPains = createSelector([getPainsById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getPains,
  getPainsBySegment,
  getPainsById,
  getErrorMessage,
};
