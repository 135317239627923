import { types } from 'store/modules/scenario';

const clearError = () => ({
  type: types.SCENARIO_CLEAR_ERROR,
});

const createScenario = ({ ...scenario }) => ({
  type: types.SCENARIO_CREATE,
  payload: { ...scenario },
});

const createScenarioSucceeded = ({ id, project, ...scenario }) => ({
  type: types.SCENARIO_CREATE_SUCCEEDED,
  payload: { id, ...scenario },
});

const createScenarioFailed = ({ message }) => ({
  type: types.SCENARIO_CREATE_FAILED,
  payload: { message },
});

const deleteScenario = ({ scenarioId }, callback) => ({
  type: types.SCENARIO_DELETE,
  payload: { scenarioId },
  callback,
});

const deleteScenarioSucceeded = ({ scenarioId }) => ({
  type: types.SCENARIO_DELETE_SUCCEEDED,
  payload: { id: scenarioId },
});

const deleteScenarioFailed = ({ message }) => ({
  type: types.SCENARIO_DELETE_FAILED,
  payload: message,
});

const fetchScenario = ({ id, projectId }) => ({
  type: types.SCENARIO_FETCH,
  payload: { id, projectId },
});

const fetchScenarioSucceeded = ({ id, ...scenario }) => ({
  type: types.SCENARIO_FETCH_SUCCEEDED,
  payload: { id, ...scenario },
});

const fetchScenarioFailed = ({ message }) => ({
  type: types.SCENARIO_FETCH_FAILED,
  payload: { message },
});

const listScenarios = ({ project }) => ({
  type: types.SCENARIOS_LIST,
  payload: { project },
});

const listScenariosSucceeded = payload => ({
  type: types.SCENARIOS_LIST_SUCCEEDED,
  payload,
});

const listScenariosFailed = error => ({
  type: types.SCENARIOS_LIST_FAILED,
  payload: error,
});

const updateScenario = ({ scenarioId, ...scenario }) => ({
  type: types.SCENARIO_UPDATE,
  payload: { scenarioId, ...scenario },
});

const updateScenarioSucceeded = ({ id, ...scenario }) => ({
  type: types.SCENARIO_UPDATE_SUCCEEDED,
  payload: { id, ...scenario },
});

const updateScenarioFailed = ({ message }) => ({
  type: types.SCENARIO_UPDATE_FAILED,
  payload: message,
});

export default {
  clearError,
  createScenario,
  createScenarioFailed,
  createScenarioSucceeded,
  deleteScenario,
  deleteScenarioFailed,
  deleteScenarioSucceeded,
  fetchScenario,
  fetchScenarioFailed,
  fetchScenarioSucceeded,
  listScenarios,
  listScenariosFailed,
  listScenariosSucceeded,
  updateScenario,
  updateScenarioFailed,
  updateScenarioSucceeded,
};
