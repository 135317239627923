import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Pain from 'models/ValueProposition/Pain';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, bySegment: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PAINS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (painsById, pain) => painsById.set(pain.id.toString(), new Pain(pain)),
              state.get('data').get('byId')
            ),
            bySegment: state
              .get('data')
              .get('bySegment', new Map())
              .set(
                action.payload.segment.toString(),
                action.payload.results
                  .reduce((arr, pain) => arr.push(new Pain(pain)), new List())
                  .sort(byId)
              ),
          },
        })
      );
    case types.PAIN_FETCH_SUCCEEDED:
    case types.PAIN_CREATE_SUCCEEDED:
    case types.PAIN_UPDATE_SUCCEEDED:
      return (() => {
        const segmentId = action.payload.segment.toString();
        const painsBySegment = state
          .get('data')
          .get('bySegment')
          .get(segmentId, new List())
          .filter(pain => pain.id !== action.payload.id)
          .push(new Pain(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Pain(action.payload)),
              bySegment: state.get('data').get('bySegment').set(segmentId, painsBySegment),
            },
          })
        );
      })();
    case types.PAIN_FETCH:
    case types.PAIN_CREATE:
    case types.PAIN_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PAIN_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            bySegment: state
              .get('data')
              .get('bySegment')
              .set(
                action.payload.segment.toString(),
                state
                  .get('data')
                  .get('bySegment')
                  .get(action.payload.segment.toString(), [])
                  .filter(pain => pain.id !== action.payload.id)
              )
              .sort(byId),
          },
        })
      );
    case types.PAIN_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.PAIN_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.PAIN_FETCH_FAILED:
    case types.PAINS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
