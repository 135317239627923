import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Rule from 'models/ImpactPlan/Rule';
import { byId } from 'store/utils/sortFunctions';

import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, bySystem: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RULES_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          data: {
            byId: action.payload.results.reduce(
              (obj, rule) => ({ ...obj, [rule.id]: new Rule(rule) }),
              state.get('data').get('byId')
            ),
            bySystem: state
              .get('data')
              .get('bySystem')
              .set(
                String(action.payload.system),
                fromJS(action.payload.results.map(rule => new Rule(rule)).sort(byId))
              ),
          },
        })
      );
    case types.RULE_FETCH_SUCCEEDED:
    case types.RULE_CREATE_SUCCEEDED:
    case types.RULE_UPDATE_SUCCEEDED:
      return (() => {
        const systemId = String(action.payload.system);
        const systemRules = state
          .get('data')
          .get('bySystem')
          .get(systemId, new List())
          .filter(rule => rule.id !== action.payload.id)
          .push(new Rule(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyReceiveInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(String(action.payload.id), new Rule(action.payload)),
              bySystem: state.get('data').get('bySystem').set(systemId, systemRules),
            },
          })
        );
      })();
    case types.RULE_FETCH:
    case types.RULE_CREATE:
    case types.RULE_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.RULE_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(String(action.payload.id)),
            bySystem: state
              .get('data')
              .get('bySystem')
              .set(
                String(action.payload.system),
                state
                  .get('data')
                  .get('bySystem')
                  .get(String(action.payload.system), new List())
                  .filter(rule => rule.id !== action.payload.id)
              ),
          },
        })
      );
    case types.RULE_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.RULE_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.RULE_FETCH_FAILED:
    case types.RULES_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
