// Libraries
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CheckOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

// Components
import { Row, Col } from 'antd';
import { H2, P } from 'components/fontSystem';
import { Button } from 'components/elements';
import Panel from '../Panel';

const NumberBanner = props => {
  const {
    ventureSummaryBanner,
    title,
    text,
    buttonText,
    onClick,
    isVentureSummaryComplete,
    isSurveyPending,
  } = props;

  return (
    <StyledPanel
      $flex
      size="small"
      mb="0"
      $ventureSummaryBanner={ventureSummaryBanner}
      $isSurveyPending={isSurveyPending}
    >
      {/* Number or Check icon */}
      <Row type="flex" align="top">
        <Col style={{ paddingRight: '20px' }}>
          {isVentureSummaryComplete ? (
            <CheckOutlined className="number" />
          ) : (
            <P className="number">{ventureSummaryBanner ? '1' : '2'} </P>
          )}
        </Col>

        {/* Text */}
        <Col style={{ paddingRight: 40 }}>
          <H2 color="white">{title}</H2>
          <P color="white" dangerouslySetInnerHTML={{ __html: text }} />
        </Col>
      </Row>

      {/* Button */}
      <Row type="flex" justify="end">
        <Col style={isVentureSummaryComplete && { paddingBottom: 24 }}>
          <StyledButton
            effect="whiteBlink"
            onClick={onClick}
            icon={ventureSummaryBanner ? <EditOutlined /> : <EyeOutlined />}
            $ventureSummaryBanner={ventureSummaryBanner}
          >
            {buttonText}
          </StyledButton>
        </Col>
      </Row>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)`
  &&& {
    height: ${props => (props.$isSurveyPending ? 160 : 231)};
    padding: 20px;
    border-radius: 4px;
    background-color: ${props =>
      props.$ventureSummaryBanner ? props.theme.primaryColor : '#9098FC'};

    .number {
      font-size: 20px;
      font-weight: bold;
      color: #fff;

      border: 1px solid #fff;
      border-radius: 50%;

      width: 41px;
      height: 41px;

      display: grid;
      place-items: center;
    }
  }
`;

const StyledButton = styled(Button)`
  &&& {
    color: ${props => (props.$ventureSummaryBanner ? props.theme.primaryColor : '#9098FC')};
    background-color: white;
    font-weight: 600;

    &:hover {
      color: ${props => (props.$ventureSummaryBanner ? props.theme.primaryColor : '#9098FC')};
      opacity: 0.75;
    }
  }
`;

NumberBanner.propTypes = {
  ventureSummaryBanner: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isVentureSummaryComplete: PropTypes.bool,
  isSurveyPending: PropTypes.bool,
};

NumberBanner.defaultProps = {
  ventureSummaryBanner: false,
  onClick: undefined,
  isVentureSummaryComplete: false,
  isSurveyPending: false,
};

export default NumberBanner;
