import { Record } from 'immutable';
import ContentType from '../ContentType';

const SdgRecord = new Record({
  id: null,
  name: null,
  description: null,
  created: null,
  updated: null,
  icon: null,
  color: null,
  index: null,
});

class Sdg extends SdgRecord {
  static contentType = ContentType.PARTNER;

  get id() {
    return this.get('id');
  }
}

export default Sdg;
