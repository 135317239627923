import { Record, Map } from 'immutable';
import { byId } from 'store/utils/sortFunctions';
import ContentType from '../ContentType';
import MonthlyPayroll from './MonthlyPayroll';

const YEARLY_PAYROLLS_YEAR_1 = '0';
const YEARLY_PAYROLLS_YEAR_2 = '1';
const YEARLY_PAYROLLS_YEAR_3 = '2';

export const YEARLY_PAYROLLS = [
  YEARLY_PAYROLLS_YEAR_1,
  YEARLY_PAYROLLS_YEAR_2,
  YEARLY_PAYROLLS_YEAR_3,
];

const TYPE_FOUNDER = 0;
const TYPE_FULL_TIME = 1;
const TYPE_PART_TIME = 2;
const TYPE_SEASONABLE = 3;
const TYPE_INTERN = 4;

const TYPE_CHOICES = [
  [TYPE_FOUNDER, 'founder'],
  [TYPE_FULL_TIME, 'full_time'],
  [TYPE_PART_TIME, 'part_time'],
  [TYPE_SEASONABLE, 'seasonable'],
  [TYPE_INTERN, 'intern'],
];

const PayrollRecord = Record({
  contentType: ContentType.PAYROLL,
  id: null,
  scenario: null,
  created: null,
  updated: null,
  type: null,
  name: null,
  comments: null,
  yearly_payrolls: {
    [YEARLY_PAYROLLS_YEAR_1]: [],
    [YEARLY_PAYROLLS_YEAR_2]: [],
    [YEARLY_PAYROLLS_YEAR_3]: [],
  },
  yearly_totals: {},
});

class Payroll extends PayrollRecord {
  static contentType = ContentType.PAYROLL;

  static TYPE_FOUNDER = TYPE_FOUNDER;
  static TYPE_FULL_TIME = TYPE_FULL_TIME;
  static TYPE_PART_TIME = TYPE_PART_TIME;
  static TYPE_SEASONABLE = TYPE_SEASONABLE;
  static TYPE_INTERN = TYPE_INTERN;
  static TYPE_CHOICES = TYPE_CHOICES;

  static getDisplayName = (type, t) => {
    switch (type) {
      case TYPE_FOUNDER:
        return t('Founder / Co-Founder');
      case TYPE_FULL_TIME:
        return t('Full Time Employee');
      case TYPE_PART_TIME:
        return t('Part Time Employee');
      case TYPE_SEASONABLE:
        return t('Seasonal Employee');
      case TYPE_INTERN:
        return t('Intern');
      default:
        return undefined;
    }
  };

  constructor(payload) {
    const payroll = { ...payload };
    const yearlyPayrolls = {
      ...payroll.yearly_payrolls,
    };
    const yearlyTotals = {
      [YEARLY_PAYROLLS_YEAR_1]: 0,
      [YEARLY_PAYROLLS_YEAR_2]: 0,
      [YEARLY_PAYROLLS_YEAR_3]: 0,
    };
    // Normalizing data and calculating the yearly payroll totals:
    YEARLY_PAYROLLS.forEach(year => {
      // immutable.js leads to an unintended mutation of the yearlyPayrolls.
      // The problem is that the yearly_totals aren't calculated, so we must
      // mutate it back to JavaScript and calculate the new yearly totals.
      const payrolls = Array.isArray(yearlyPayrolls[year])
        ? yearlyPayrolls[year]
        : yearlyPayrolls[year].valueSeq().toJS();
      const normalizedData = payrolls
        .reduce((prevData, monthlyPayroll) => {
          yearlyTotals[String(year)] += monthlyPayroll.amount;
          return prevData.set(
            String(monthlyPayroll.id),
            new MonthlyPayroll({
              ...monthlyPayroll,
              payroll: payroll.id,
              year,
            })
          );
        }, new Map())
        .sort(byId);
      payroll.yearly_payrolls[year] = normalizedData;
    });
    payroll.yearly_totals = yearlyTotals;
    super(payroll);
  }

  get getId() {
    return this.get('id');
  }

  static getPayrollTotal = yearlyTotals => {
    const total = Object.values(yearlyTotals).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return total;
  };
}

export default Payroll;
