import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Competitor from 'models/CompetitionMap/Competitor';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byOutcome: {}, byProduct: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.COMPETITORS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (competitorsById, competitor) =>
                competitorsById.set(competitor.id.toString(), new Competitor(competitor)),
              state.get('data').get('byId')
            ),
            byOutcome: action.payload.results
              .reduce((obj, competitor) => {
                const key = competitor.outcome ? competitor.outcome.toString() : 'self';
                return obj.set(
                  key,
                  obj
                    .get(key, new List())
                    .filter(oldCompetitor => oldCompetitor.id !== competitor.id)
                    .push(new Competitor(competitor))
                    .sort(byId)
                );
              }, state.get('data').get('byOutcome'))
              .sort(byId),
            byProduct: state
              .get('data')
              .get('byProduct')
              .set(
                action.payload.product.toString(),
                fromJS(
                  action.payload.results.map(competitor => new Competitor(competitor)).sort(byId)
                )
              ),
          },
        })
      );
    case types.COMPETITOR_FETCH_SUCCEEDED:
    case types.COMPETITOR_CREATE_SUCCEEDED:
    case types.COMPETITOR_UPDATE_SUCCEEDED:
      return (() => {
        const outcomeId = action.payload.outcome.toString();
        const competitorsByOutcome = state
          .get('data')
          .get('byOutcome')
          .get(outcomeId, new List())
          .filter(competitor => competitor.id !== action.payload.id)
          .push(new Competitor(action.payload))
          .sort(byId);
        const productId = action.payload.product.toString();
        const competitorsByProduct = state
          .get('data')
          .get('byProduct')
          .get(productId, new List())
          .filter(competitor => competitor.id !== action.payload.id)
          .push(new Competitor(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyRequestInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(action.payload.id.toString(), new Competitor(action.payload)),
              byOutcome: state.get('data').get('byOutcome').set(outcomeId, competitorsByOutcome),
              byProduct: state.get('data').get('byProduct').set(productId, competitorsByProduct),
            },
          })
        );
      })();
    case types.COMPETITOR_FETCH:
    case types.COMPETITOR_CREATE:
    case types.COMPETITOR_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.COMPETITOR_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byOutcome: state
              .get('data')
              .get('byOutcome')
              .set(
                action.payload.outcome.toString(),
                state
                  .get('data')
                  .get('byOutcome')
                  .get(action.payload.outcome.toString(), [])
                  .filter(competitor => competitor.id !== action.payload.id)
              )
              .sort(byId),
            byProduct: {
              ...state.get('data').get('byProduct').toJS(),
              [action.payload.product]: state
                .get('data')
                .get('byProduct')
                .get(action.payload.product.toString(), new List())
                .filter(competitor => competitor.id !== action.payload.id)
                .sort(byId),
            },
          },
        })
      );
    case types.COMPETITOR_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.COMPETITOR_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.COMPETITOR_FETCH_FAILED:
    case types.COMPETITORS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
