const STEP_CLEAR_ERROR = 'bfb/tools/step/STEP_CLEAR_ERROR';
const STEPS_LIST = 'bfb/tools/step/STEPS_LIST';
const STEPS_LIST_SUCCEEDED = 'bfb/tools/step/STEPS_LIST_SUCCEEDED';
const STEPS_LIST_FAILED = 'bfb/tools/step/STEPS_LIST_FAILED';
const STEP_UPDATE = 'bfb/tools/step/STEP_UPDATE';
const STEP_UPDATE_SUCCEEDED = 'bfb/tools/step/STEP_UPDATE_SUCCEEDED';
const STEP_UPDATE_FAILED = 'bfb/tools/step/STEP_UPDATE_FAILED';
const STEP_FETCH = 'bfb/tools/step/STEPS_FETCH';
const STEP_FETCH_SUCCEEDED = 'bfb/tools/step/STEPS_FETCH_SUCCEEDED';
const STEP_FETCH_FAILED = 'bfb/tools/step/STEPS_FETCH_FAILED';

export default {
  STEPS_LIST,
  STEPS_LIST_SUCCEEDED,
  STEPS_LIST_FAILED,
  STEP_CLEAR_ERROR,
  STEP_UPDATE,
  STEP_UPDATE_SUCCEEDED,
  STEP_UPDATE_FAILED,
  STEP_FETCH,
  STEP_FETCH_SUCCEEDED,
  STEP_FETCH_FAILED,
};
