import { createSelector } from 'reselect';
import { List } from 'immutable';

const getState = state => state.financialProjection;

const getRawProjection = createSelector([getState], state => state.get('data'));

const getProjectionsByScenario = createSelector([getRawProjection], state =>
  state.get('byScenario')
);

const getScenarioProjections = createSelector(
  [getProjectionsByScenario],
  state => scenarioId => state.get(String(scenarioId), new List())
);
const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

const isFetching = createSelector([getState], state => state.get('isFetching'));

export default {
  getErrorMessage,
  getProjectionsByScenario,
  getScenarioProjections,
  isFetching,
};
