import { Record } from 'immutable';
import ContentType from '../ContentType';

const ScenarioRecord = Record({
  id: null,
  project: null,
  name: null,
  start_date: null,
  created: null,
  updated: null,
  comments: [],
  selected: null,
  no_fixed_assets: null,
  contentType: ContentType.SCENARIO,
});

class Scenario extends ScenarioRecord {
  static contentType = ContentType.SCENARIO;

  get getId() {
    return this.get('id');
  }
}

export default Scenario;
