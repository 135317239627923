import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ToolOutputSelect from '../ToolOutputSelect';

const RawToolOutputOpacity = props => {
  const { textAlign, ...rest } = props;
  return <ToolOutputSelect {...rest} />;
};

RawToolOutputOpacity.propTypes = {
  textAlign: PropTypes.string,
  showComments: PropTypes.bool,
  showDelete: PropTypes.bool,
  showSelect: PropTypes.bool,
};

RawToolOutputOpacity.defaultProps = {
  textAlign: 'center',
  showComments: false,
  showDelete: false,
  showSelect: false,
};

const ToolOutputOpacity = styled(RawToolOutputOpacity)`
  background-color: ${({ colorIndex, theme }) => `${theme.getToolColor(colorIndex)}1A`};
  border: ${({ colorIndex, theme }) => `2px solid ${theme.getToolColor(colorIndex)}`};
  justify-content: center;
  margin: 0;
  min-height: 35px;
  padding: 5px 10px;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  word-break: break-all;
`;

export default ToolOutputOpacity;
