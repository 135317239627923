// About you
export const INPUT_PROFILE_PICTURE = 'profile_picture';
export const INPUT_FIRST_NAME = 'first_name';
export const INPUT_LAST_NAME = 'last_name';
export const INPUT_GENDER = 'gender';
export const INPUT_BIRTHDAY = 'birthday';
export const INPUT_ORIGIN = 'origin';
export const INPUT_COUNTRY = 'country';
export const INPUT_CITY = 'city';
export const INPUT_LANGUAGES = 'languages';
export const INPUT_BIO = 'biography';
export const INPUT_LINKEDIN = 'linkedin';
export const INPUT_WEBSITE = 'website';
export const INPUT_PHONE_NUMBER = 'phone_number';

// Areas Of Expertise
export const INPUT_AREAS_EXPERTISE = 'areas_expertise';
export const INPUT_SECTORS = 'areas_innovation';

// Work Experience
export const INPUT_TITLE = 'title';
export const INPUT_COMPANY = 'company';
export const INPUT_YEARS = 'years';

// Education
export const INPUT_SCHOOL = 'institution';
export const INPUT_DEGREE = 'degree';
export const INPUT_FIELD = 'field';

// Motivation
export const INPUT_MENTORING_EXPERIENCE = 'mentoring_experience';
export const INPUT_MOTIVATION = 'motivation';
export const INPUT_SKILLS = 'why_me';
export const INPUT_EXPECTATIONS = 'expectations';
export const INPUT_ENTREPRENEURIAL_EXPERIENCE = 'entrepreneur_before';
export const INPUT_STARTUP_INVESTOR = 'startup_investor';

// Final checklist
export const INPUT_ALTERANTIVE_PROGRAM_PREFERENCE = 'participate_in_another_program_if_rejected';
export const INPUT_COMMITMENT = 'check_commitment';
export const INPUT_CONFIDENTIAL = 'check_confidential';
export const INPUT_ONLINE = 'check_online';
export const INPUT_RECOMMENDED = 'recommended_program';
export const INPUT_REFERRER_NAME = 'recommended_program_referrer';
export const INPUT_GDPR = 'GDPR';
export const INPUT_ANSWER_TO_ADDITIONAL_QUESTION = 'additional_answer';

// Form Sections
export const ABOUT_YOU = 'about_you';
export const AREAS_OF_EXPERTISE = 'expertise';
export const WORK_EXPERIENCE = 'work_experiences';
export const EDUCATION = 'education_experiences';
export const MOTIVATION = 'motivation';
export const FINAL_CHECKLIST = 'final_checklist';
