// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import { selectors as langSelectors } from 'store/modules/translations';

// Components
import { Col } from 'antd';
import { H2, P } from 'components/fontSystem';
import { Row } from 'components/layout';

// Local Components
import CreateProfile from '../../CreateProfile';

// Local Dependencies
import { getAdditionalInfo } from '../../static_variables';

const Container = styled.div`
  &&& {
    padding: 30px;
    .questions-list {
      margin: -10px 0;
      .questions-group {
        padding: 10px 0;
        .question-button {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          .bullet-point {
            display: inline-flex;
            color: ${({ theme: { primaryColor } }) => primaryColor} !important;
            font-size: 25px !important;
            font-weight: bold;
            padding: 5px 10px;
          }
          span {
            transition: all ease-in-out 150ms;
          }
          transition: all ease-in-out 350ms;
        }
        .question-button-active {
          background-color: ${({ theme: { secondaryColor } }) => secondaryColor}1A;
          span {
            font-weight: bold;
          }
        }
      }
    }
    .active-question {
      margin: 35px 0;
    }
    /* Profile button */
    .button-container {
      margin-top: 25px;
    }
  }
`;

export default function StaticQA(props) {
  const { t } = React.useContext(TranslationsContext);

  const { onCreateProfileClick } = props;

  const [activeQuestion, setActiveQuestion] = React.useState(getAdditionalInfo(t)[0].questions[0]);

  /**
   * Handles an on click event that sets the state of the active question.
   * @param {*} question - Question to be set as the active question.
   */
  function handleQuestionOnClick(question) {
    setActiveQuestion(question);
  }

  const selectedLanguage = useSelector(state => langSelectors.getSelectedLanguage(state));

  return (
    <Container data-test="component-static-qa">
      <Row gutter={30}>
        {/* Questions List */}
        <Col sm={12} xs={24}>
          <div className="questions-list">
            {getAdditionalInfo(t, selectedLanguage).map(data => {
              const { key: infoKey, title, questions } = data;
              return (
                <div key={infoKey} className="questions-group">
                  <H2 $mb="10">{t(title)}</H2>
                  {questions.map(questionData => {
                    const { key: questionKey, question } = questionData;
                    const isActive = activeQuestion.key === questionKey;
                    return (
                      <div
                        role="button"
                        key={questionKey}
                        onClick={() => handleQuestionOnClick(questionData)}
                        className={`question-button${isActive ? ' question-button-active' : ''}`}
                      >
                        <span className="bullet-point">&#8226;</span>
                        <span>{t(question)}</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Col>
        {/* Active Question */}
        <Col sm={12} xs={24}>
          <div className="active-question">
            <P $semibold>{activeQuestion.question}</P>
            <div>
              <span>{activeQuestion.answer}</span>{' '}
              {activeQuestion.hyperLink && activeQuestion.hyperlinkText && (
                <a
                  href={activeQuestion.hyperLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hyperlink"
                >
                  {t(activeQuestion.hyperlinkText)}
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <CreateProfile data-test="create-profile" onClick={onCreateProfileClick} />
    </Container>
  );
}

StaticQA.propTypes = {
  onCreateProfileClick: PropTypes.func.isRequired,
};
