// Libraires
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import ToolSelect from '../ToolSelect/index';
import { ToolDefaultInput } from '../index';
import Form from 'components/elements/Form';

const ACTIVE_INPUT_TEXTAREA = 'textarea';

const RawTypeTextInput = props => {
  const {
    className,
    form,
    name,
    onBlur,
    onBlurText,
    onBlurType,
    options,
    typeRequired,
    shouldNotDecorate,
    text,
    textInputName,
    textInputRules,
    textPlaceholder,
    type,
    noSelect,
    typeInputName,
    typePlaceholder,
    typeTextWidthRatio,
    showCount,
    ...rest
  } = props;

  const textFieldName = useMemo(() => textInputName || `${name}-text`, [textInputName, name]);
  const typeFieldName = useMemo(() => typeInputName || `${name}-type`, [typeInputName, name]);

  const [activeInput, setActiveInput] = useState(undefined);

  const [state, setState] = useState({
    [textFieldName]: text,
    [typeFieldName]: type,
  });

  const resetFieldsCallback = () => {
    if (shouldNotDecorate) {
      setState({
        [typeFieldName]: undefined,
        [textFieldName]: undefined,
      });
      form.resetFields([typeFieldName]);
      document.querySelectorAll(`textarea[name='${textFieldName}']`).forEach(input => {
        // eslint-disable-next-line
        input.value = null;
      });
    } else {
      // setTimeout will reset the input fields in an asynchronous manner
      setTimeout(() => form.resetFields([textFieldName, typeFieldName]), 0);
    }
  };

  const updateTextFieldsCallback = newState => {
    if (shouldNotDecorate) {
      document.querySelectorAll(`textarea[name='${textFieldName}']`).forEach(input => {
        // eslint-disable-next-line
        input.value = newState[textFieldName];
      });
    }
  };

  /**
   * Only used if `shouldNotDecorate` is `true`.
   */
  const checkStateValidity = (e, fn, updatedInput) => {
    const newState = {
      ...state,
      ...updatedInput,
    };
    // Validating state input fields/values.
    switch (typeRequired) {
      case true:
        if (
          newState[textFieldName] !== undefined &&
          newState[textFieldName] !== null &&
          newState[typeFieldName] !== undefined &&
          newState[typeFieldName] !== null &&
          fn
        ) {
          fn(e, [name, newState], resetFieldsCallback, () => updateTextFieldsCallback(newState));
        }
        break;
      case false:
      default:
        if (newState[textFieldName] !== undefined && newState[textFieldName] !== null && fn) {
          fn(e, [name, newState], resetFieldsCallback, () => updateTextFieldsCallback(newState));
        }
    }
    return newState;
  };

  const onBlurHandler = (e, fn) => {
    if (shouldNotDecorate) {
      const value = e && e.target && e.target.value;
      const inputName = e && e.target && e.target.name;
      if (inputName === textFieldName && value !== state[textFieldName]) {
        setState(checkStateValidity(e, fn, { [textFieldName]: value }));
      }
      if (inputName === typeFieldName && value !== state[typeFieldName]) {
        setState(checkStateValidity(e, fn, { [typeFieldName]: value }));
      }
    } else {
      form.validateFields([textFieldName, typeFieldName]).then(values => {
        if (fn) fn(e, [name, values], resetFieldsCallback);
      });
    }
  };

  const handleTypeOnChange = event => {
    if (onBlurType) {
      onBlurHandler(event, onBlurType);
    } else if (onBlur) {
      onBlur(event);
    }
  };

  const getTypeInitialValue = () => {
    if (noSelect) {
      return 0;
    }
    if (type !== null) {
      return type;
    }
    return undefined;
  };

  return (
    <Wrapper
      activeInput={activeInput}
      className={className}
      $empty={rest.empty}
      $noSelect={noSelect}
      typeTextWidthRatio={typeTextWidthRatio}
    >
      <Form.Item
        initialValue={getTypeInitialValue()}
        rules={[{ required: typeRequired }]}
        name={typeFieldName}
        className="select-wrapper"
      >
        <ToolSelect
          empty={type !== 0 && !type}
          {...rest}
          disabled={noSelect}
          dropdownMatchSelectWidth={false}
          name={typeFieldName}
          onChange={handleTypeOnChange}
          opacityBackground
          options={noSelect ? [{ value: 0, label: noSelect }] : options}
          placeholder={typePlaceholder}
          showComments={false}
          removeIcon
        />
      </Form.Item>

      <ToolDefaultInput
        empty={!text}
        {...rest}
        className="input"
        form={form}
        initialValue={text}
        name={textFieldName}
        onFocus={() => (noSelect ? undefined : setActiveInput(ACTIVE_INPUT_TEXTAREA))}
        onBlur={e => {
          setActiveInput(undefined);
          if (onBlurText) {
            onBlurHandler(e, onBlurText);
          } else if (onBlur) {
            onBlur(e);
          }
        }}
        rules={textInputRules}
        placeholder={textPlaceholder}
        shouldNotDecorate={shouldNotDecorate}
        showCount={showCount}
      />
    </Wrapper>
  );
};

const BORDER_RADIUS = 4;
const HEIGHT = 33;
const Wrapper = styled.div`
  &&& {
    display: flex;
    white-space: nowrap;
    & > div {
      transition: all 200ms ease-out;
    }
    .input {
      > div {
        border-radius: 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0;
      }
      textarea {
        transition: height 300ms ease-out 200ms;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .ant-select .ant-select-selector {
      border-radius: ${BORDER_RADIUS}px 0 0 ${BORDER_RADIUS}px;
      border-right: 0;
      padding: 9px 10px 10px;
      ${({ empty, noSelect, theme }) =>
        noSelect &&
        css`
          background-color: ${theme.backgroundGreyColor};
          .ant-select-selection-selected-value {
            color: ${empty ? theme.greyColor : 'black'};
          }
        `}

      .ant-select-arrow {
        display: ${({ noSelect }) => (noSelect ? 'none' : 'initial')};
      }
    }

    ${({ activeInput, typeTextWidthRatio }) => {
      const defaultTypeWidth = `${typeTextWidthRatio * 100}%`;
      const defaultTextWidth = `${(1 - typeTextWidthRatio) * 100}%`;
      switch (activeInput) {
        case ACTIVE_INPUT_TEXTAREA:
          return css`
            .prepend {
              border-radius: 4px 0 0 4px;
              border-right: none;
              width: ${defaultTypeWidth};
            }
            .select-wrapper {
              flex: 0;
              opacity: 0;
              padding: 0;
              width: 0;
            }
            .input {
              flex: 2;
              width: 100%;
              opacity: 1;
              > div {
                border-radius: ${BORDER_RADIUS}px;
              }
              textarea {
                white-space: normal;
              }
            }
          `;
        // DEFAULT CASE
        default:
          return css`
            .prepend {
              border-radius: 4px 0 0 4px;
              border-right: none;
              width: ${defaultTypeWidth};
            }
            .select-wrapper {
              flex: 1;
              width: ${defaultTypeWidth};
              opacity: 1;
            }
            .input {
              flex: 2;
              width: ${defaultTextWidth};
              opacity: 1;
              textarea {
                height: ${HEIGHT}px !important;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          `;
      }
    }}
  }
`;

RawTypeTextInput.propTypes = {
  className: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  noSelect: PropTypes.bool,
  onBlur: PropTypes.func,
  onBlurText: PropTypes.func,
  onBlurType: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  shouldNotDecorate: PropTypes.bool,
  showComments: PropTypes.bool,
  showDelete: PropTypes.bool,
  showSelect: PropTypes.bool,
  showCount: PropTypes.bool,
  text: PropTypes.string,
  textInputName: PropTypes.string,
  textInputRules: PropTypes.instanceOf(Array),
  textPlaceholder: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  typeInputName: PropTypes.string,
  typePlaceholder: PropTypes.string,
  typeRequired: PropTypes.bool,
  typeTextWidthRatio: PropTypes.number,
};

RawTypeTextInput.defaultProps = {
  noSelect: false,
  onBlur: null,
  onBlurText: null,
  onBlurType: null,
  options: null,
  typeRequired: true,
  shouldNotDecorate: false,
  showComments: false,
  showDelete: false,
  showSelect: false,
  showCount: false,
  text: '',
  textInputName: null,
  textInputRules: [],
  textPlaceholder: '',
  type: undefined,
  typeInputName: null,
  typePlaceholder: '',
  typeTextWidthRatio: 0.33,
};

const TypeTextInput = styled(RawTypeTextInput)`
  &&& > .select-wrapper .ant-select-selection {
    border-right-width: 0;
    height: auto;
    padding: 10px;
  }
`;

export default TypeTextInput;
