// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import countriesService from 'services/countries';
import APIMultiSelect from 'components/elements/MultiSelect/APIMultiSelect/';
import TranslationsContext from 'providers/translationsProvider';
import { CountryIcon } from 'components/elements';

class CountryMultiSelect extends APIMultiSelect {
  static contextType = TranslationsContext;
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    t: PropTypes.func,
    isPublic: PropTypes.bool,
  };

  static defaultProps = {
    t(text) {
      return text;
    },
    isPublic: false,
  };

  getResults = () => countriesService.getCountries();
  getKey = country => country[0];
  getValue = country => country[0];
  getText = country => this.props.t(country[0]);
  getIcon = country =>
    !this.props.noIcon && (
      <CountryIcon style={{ paddingLeft: '20px', paddingRight: '10px' }} countryCode={country[0]} />
    );
}

export default CountryMultiSelect;
