import api from '../api';

const createPayroll = ({ scenario, ...payroll }) =>
  api({
    method: 'post',
    url: 'api/payrolls/',
    data: {
      scenario,
      ...payroll,
    },
    params: {
      scenario,
    },
  });

const deletePayroll = ({ id }) => api.delete(`api/payrolls/${id}`);

const fetchPayroll = ({ id }) => api.get(`api/payrolls/${id}/`);

const listPayrolls = ({ scenario }) =>
  api.get('api/payrolls/', {
    params: {
      scenario,
    },
  });

const updatePayroll = ({ id, ...updatedPayroll }) =>
  api({
    method: 'patch',
    url: `api/payrolls/${id}/`,
    data: updatedPayroll,
  });

export default {
  createPayroll,
  deletePayroll,
  fetchPayroll,
  listPayrolls,
  updatePayroll,
};
