import { createSelector } from 'reselect';

const getState = state => state.pillsToolkit;

const getPillsToolkit = createSelector([getState], state => state.data);

const getPillsToolkitList = createSelector([getPillsToolkit], state => state.list);

const getPillsToolkitByStatus = createSelector([getPillsToolkit], state => state.byStatus);

export default {
  getPillsToolkit,
  getPillsToolkitByStatus,
  getPillsToolkitList,
};
