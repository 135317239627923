import { Record } from 'immutable';

const CREATED_COMMENT = 0;
const TOOL_COMPLETED = 1;
const TOOL_REVIEWED = 2;
const E_APP_SUBMITTED = 3;
const E_APP_APPROVED = 4;
const E_APP_REJECTED = 5;
const INVITE_SENT = 6;
const INVITE_ACCEPTED = 7;
const INVITE_REJECTED_BY_E = 8;
const INVITE_REJECTED_BY_M = 9;
const INVITE_SIGN_PENDING = 10;
const INVITE_MATCHED = 11;
const FINANCIAL_SCENARIO_UPLOAD = 12;

const NotificationsRecord = Record({
  id: null,
  user: {},
  verb: null,
  action_object: {},
  parent_object: {},
  seen_at: new Date(),
  created: new Date(),
  updated: new Date(),
});

class Notifications extends NotificationsRecord {
  static CREATED_COMMENT = CREATED_COMMENT;
  static TOOL_COMPLETED = TOOL_COMPLETED;
  static TOOL_REVIEWED = TOOL_REVIEWED;
  static E_APP_SUBMITTED = E_APP_SUBMITTED;
  static E_APP_APPROVED = E_APP_APPROVED;
  static E_APP_REJECTED = E_APP_REJECTED;
  static INVITE_SENT = INVITE_SENT;
  static INVITE_ACCEPTED = INVITE_ACCEPTED;
  static INVITE_REJECTED_BY_E = INVITE_REJECTED_BY_E;
  static INVITE_REJECTED_BY_M = INVITE_REJECTED_BY_M;
  static INVITE_SIGN_PENDING = INVITE_SIGN_PENDING;
  static INVITE_MATCHED = INVITE_MATCHED;
  static FINANCIAL_SCENARIO_UPLOAD = FINANCIAL_SCENARIO_UPLOAD;
}

export default Notifications;
