import types from './types';

const fetchDependencies = (payload, errorCallback) => ({
  type: types.FETCH_MY_PROGRAM_DEPENDENCIES,
  payload: { id: payload.id },
  errorCallback,
});

const fetchDependenciesFailed = () => ({
  type: types.FETCH_MY_PROGRAM_DEPENDENCIES_FAILED,
  payload: null,
});

const createApplicationAndFetchDependencies = ({ program, ...application }, callback) => ({
  type: types.CREATE_APPLICATION_AND_FETCH_DEPENDENCIES,
  payload: { program, ...application },
  callback,
});

const createApplicationAndFetchDependenciesFailed = () => ({
  type: types.CREATE_APPLICATION_AND_FETCH_DEPENDENCIES_FAILED,
  payload: null,
});

export default {
  fetchDependencies,
  fetchDependenciesFailed,
  createApplicationAndFetchDependencies,
  createApplicationAndFetchDependenciesFailed,
};
