// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import { Form, Input } from 'components/elements';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';

const TextAreaCounter = props => {
  const {
    description,
    max,
    rules,
    required,
    onChange,
    onBlur,
    name,
    size,
    displayBlock,
    initialValue,
    normalize,
    ...rest
  } = props;

  const { t } = useContext(TranslationsContext);

  const changeHandler = event => {
    if (onChange) onChange(event);
  };

  const blurHandler = event => {
    if (onBlur) onBlur(event);
  };

  const requiredRule = required && [{ required: true, message: t('This field is required') }];

  return (
    <Form.Item
      name={name}
      rules={rules || requiredRule || []}
      $displayBlock={displayBlock}
      size={size}
      initialValue={initialValue}
      infotext={description}
      normalize={normalize}
      {...rest}
    >
      <Input.TextArea
        name={name}
        onChange={changeHandler}
        onBlur={blurHandler}
        maxLength={max}
        autoSize
        showCount
        {...rest}
      />
    </Form.Item>
  );
};

const StyledTextAreaCounter = styled(TextAreaCounter)`
  &.ant-form-item .ant-form-item-explain {
    clear: none;
  }

  textarea.ant-input {
    height: auto;
    min-height: auto;
    padding: 0;
  }

  .ant-col {
    max-width: none;
  }

  &&& {
    .ant-input {
      border-radius: 0;
    }
  }
`;

TextAreaCounter.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  max: PropTypes.number.isRequired,
  size: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  rules: PropTypes.instanceOf(Array),
  required: PropTypes.bool,
  description: PropTypes.string,
  displayBlock: PropTypes.bool,
  initialValue: PropTypes.string,
  normalize: PropTypes.func,
};

TextAreaCounter.defaultProps = {
  size: 'default',
  onChange: null,
  onBlur: null,
  rules: null,
  required: false,
  description: null,
  displayBlock: false,
  initialValue: null,
  normalize: null,
};

export default StyledTextAreaCounter;
