import { fromJS, List, Map } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import FinancialProduct, {
  YEARLY_UNITS_SOLD,
  YEARLY_PRICE,
  YEARLY_COGS,
  YEARLY_UNITS_SOLD_TOTALS,
  YEARLY_PRICE_TOTALS,
  YEARLY_COGS_TOTALS,
} from 'models/FinancialProjections/FinancialProduct';
import MonthlyUnitsSold from 'models/FinancialProjections/MonthlyUnitsSold';
import MonthlyPrice from 'models/FinancialProjections/MonthlyPrice';
import MonthlyCogs from 'models/FinancialProjections/MonthlyCogs';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

const getMonthlyUpdateKeysVariables = actionType => {
  switch (actionType) {
    case types.MONTHLY_UNITS_SOLD_UPDATE_SUCCEEDED:
      return [YEARLY_UNITS_SOLD, YEARLY_UNITS_SOLD_TOTALS, MonthlyUnitsSold];
    case types.MONTHLY_PRICE_UPDATE_SUCCEEDED:
      return [YEARLY_PRICE, YEARLY_PRICE_TOTALS, MonthlyPrice];
    case types.MONTHLY_COGS_UPDATE_SUCCEEDED:
      return [YEARLY_COGS, YEARLY_COGS_TOTALS, MonthlyCogs];
    default:
      return ['', '', Object];
  }
};

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: { byId: {}, byScenario: {} },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FINANCIAL_PRODUCT_FETCH:
    case types.FINANCIAL_PRODUCT_CREATE:
    case types.FINANCIAL_PRODUCT_UPDATE:
    case types.FINANCIAL_PRODUCTS_LIST:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.FINANCIAL_PRODUCTS_LIST_SUCCEEDED:
      return (() => {
        const { scenario } = action.payload;
        let byScenario;
        if (scenario) {
          byScenario = state
            .get('data')
            .get('byScenario')
            .set(
              String(scenario),
              fromJS(
                action.payload.results
                  .map(financialProduct => new FinancialProduct(financialProduct))
                  .sort(byId)
              )
            );
        } else {
          byScenario = action.payload.results.reduce(
            (obj, financialProduct) =>
              obj.set(
                String(financialProduct.scenario),
                obj
                  .get(String(financialProduct.scenario), new List())
                  .push(new FinancialProduct(financialProduct))
              ),
            new Map()
          );
        }
        return state.merge(
          reduxHelper.applyReceiveInfo({
            data: {
              byId: action.payload.results.reduce(
                (prevData, financialProduct) =>
                  prevData
                    .set(String(financialProduct.id), new FinancialProduct(financialProduct))
                    .sort(byId),
                state.get('data').get('byId')
              ),
              byScenario,
            },
          })
        );
      })();
    case types.FINANCIAL_PRODUCT_FETCH_SUCCEEDED:
    case types.FINANCIAL_PRODUCT_CREATE_SUCCEEDED:
    case types.FINANCIAL_PRODUCT_UPDATE_SUCCEEDED:
      return (() => {
        const scenarioId = String(action.payload.scenario);
        const scenarioFinancialProducts = state
          .get('data')
          .get('byScenario')
          .get(scenarioId, new List())
          .filter(financialProduct => financialProduct.id !== action.payload.id)
          .push(new FinancialProduct(action.payload))
          .sort(byId);
        return state.merge(
          reduxHelper.applyReceiveInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(String(action.payload.id), new FinancialProduct(action.payload)),
              byScenario: state
                .get('data')
                .get('byScenario')
                .set(scenarioId, scenarioFinancialProducts),
            },
          })
        );
      })();
    case types.FINANCIAL_PRODUCT_DELETE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(String(action.payload.id)),
            byScenario: state
              .get('data')
              .get('byScenario')
              .set(
                String(action.payload.scenario),
                state
                  .get('data')
                  .get('byScenario')
                  .get(String(action.payload.scenario), new List())
                  .filter(financialProduct => financialProduct.id !== action.payload.id)
              ),
          },
        })
      );
    case types.MONTHLY_UNITS_SOLD_UPDATE_SUCCEEDED:
    case types.MONTHLY_PRICE_UPDATE_SUCCEEDED:
    case types.MONTHLY_COGS_UPDATE_SUCCEEDED:
      return (() => {
        const [yearlyKey, totalKey, Constructor] = getMonthlyUpdateKeysVariables(action.type);
        const {
          scenario: scenarioId,
          financial_product: financialProductId,
          ...updatedMonthlyFinancialProduct
        } = action.payload;
        // Fetching the financialProduct that will be updated.
        const updatedFinancialProduct = state
          .get('data')
          .get('byId')
          .get(String(financialProductId));
        // Fetching the amount of the old monthly properties.
        // Recalculating the new yearly total.
        // eslint-disable-next-line
        updatedFinancialProduct[totalKey][updatedMonthlyFinancialProduct.year][
          updatedMonthlyFinancialProduct.id
        ] = Number(updatedMonthlyFinancialProduct.amount);
        // Resetting the new monthly financialProducts.
        updatedFinancialProduct[yearlyKey][updatedMonthlyFinancialProduct.year] =
          updatedFinancialProduct[yearlyKey][updatedMonthlyFinancialProduct.year].set(
            String(updatedMonthlyFinancialProduct.id),
            new Constructor({
              id: Number(updatedMonthlyFinancialProduct.id),
              amount: Number(updatedMonthlyFinancialProduct.amount),
              year: Number(updatedMonthlyFinancialProduct.year),
              month: Number(updatedMonthlyFinancialProduct.month),
              financialProduct: Number(financialProductId),
            })
          );
        // Resetting the financialProducts by scenario.
        const scenarioFinancialProducts = state
          .get('data')
          .get('byScenario')
          .get(String(scenarioId), new List())
          .filter(financialProduct => String(financialProduct.id) !== String(financialProductId))
          .push(updatedFinancialProduct)
          .sort(byId);
        // Returning the new state.
        return state.merge(
          reduxHelper.applyReceiveInfo({
            updatePending: false,
            data: {
              byId: state
                .get('data')
                .get('byId')
                .set(String(action.payload.id), updatedFinancialProduct),
              byScenario: state
                .get('data')
                .get('byScenario')
                .set(String(scenarioId), scenarioFinancialProducts),
            },
          })
        );
      })();
    case types.MONTHLY_FINANCIAL_PRODUCT_UPDATE_FAILED:
    case types.FINANCIAL_PRODUCT_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.FINANCIAL_PRODUCT_FETCH_FAILED:
    case types.FINANCIAL_PRODUCTS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    case types.FINANCIAL_PRODUCT_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    default:
      return state;
  }
}
