import { createSelector } from 'reselect';

const getState = state => state.comment;

const getData = createSelector([getState], state => state.get('data'));

const getComments = createSelector([getData], state => state.get('comments'));

const getShowDrawer = createSelector([getData], state => state.get('showDrawer'));

const getIsFetching = createSelector([getState], state => state.get('isFetching'));

const getContentObject = createSelector([getData], state => state.get('contentObject'));

export default {
  getComments,
  getShowDrawer,
  getIsFetching,
  getContentObject,
};
