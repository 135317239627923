import { types } from 'store/modules/projectLink';

const listProjectLinks = query => ({
  type: types.PROJECT_LINKS_LIST,
  payload: query,
});

const listProjectLinksSucceeded = ({ projectLinks }) => ({
  type: types.PROJECT_LINKS_LIST_SUCCEEDED,
  payload: projectLinks,
});

const listProjectLinksFailed = ({ message }) => ({
  type: types.PROJECT_LINKS_LIST_FAILED,
  payload: message,
});

const updateProjectLinks = ({ id, links }) => ({
  type: types.PROJECT_LINK_UPDATE_REQUEST,
  payload: { id, links },
});

const updateProjectLinksSucceeded = response => ({
  type: types.PROJECT_LINK_UPDATE_SUCCEEDED,
  payload: response,
});

const updateProjectLinksFailed = ({ message }) => ({
  type: types.PROJECT_LINK_UPDATE_FAILED,
  payload: message,
});

export default {
  listProjectLinks,
  listProjectLinksSucceeded,
  listProjectLinksFailed,
  updateProjectLinks,
  updateProjectLinksSucceeded,
  updateProjectLinksFailed,
};
