const OBJECTIVE_CLEAR_ERROR = 'bfb/OBJECTIVE/OBJECTIVE_CLEAR_ERROR';
const OBJECTIVES_LIST = 'bfb/OBJECTIVE/OBJECTIVES_LIST';
const OBJECTIVES_LIST_SUCCEEDED = 'bfb/OBJECTIVE/OBJECTIVES_LIST_SUCCEEDED';
const OBJECTIVES_LIST_FAILED = 'bfb/OBJECTIVE/OBJECTIVES_LIST_FAILED';
const OBJECTIVE_CREATE = 'bfb/OBJECTIVE/OBJECTIVE_CREATE';
const OBJECTIVE_CREATE_SUCCEEDED = 'bfb/OBJECTIVE/OBJECTIVE_CREATE_SUCCEEDED';
const OBJECTIVE_CREATE_FAILED = 'bfb/OBJECTIVE/OBJECTIVE_CREATE_FAILED';
const OBJECTIVE_DELETE = 'bfb/OBJECTIVE/OBJECTIVE_DELETE';
const OBJECTIVE_DELETE_SUCCEEDED = 'bfb/OBJECTIVE/OBJECTIVE_DELETE_SUCCEEDED';
const OBJECTIVE_DELETE_FAILED = 'bfb/OBJECTIVE/OBJECTIVE_DELETE_FAILED';
const OBJECTIVE_FETCH = 'bfb/OBJECTIVE/OBJECTIVE_FETCH';
const OBJECTIVE_FETCH_SUCCEEDED = 'bfb/OBJECTIVE/OBJECTIVE_FETCH_SUCCEEDED';
const OBJECTIVE_FETCH_FAILED = 'bfb/OBJECTIVE/OBJECTIVE_FETCH_FAILED';
const OBJECTIVE_UPDATE = 'bfb/OBJECTIVE/OBJECTIVE_UPDATE';
const OBJECTIVE_UPDATE_SUCCEEDED = 'bfb/OBJECTIVE/OBJECTIVE_UPDATE_SUCCEEDED';
const OBJECTIVE_UPDATE_FAILED = 'bfb/OBJECTIVE/OBJECTIVE_UPDATE_FAILED';

export default {
  OBJECTIVES_LIST,
  OBJECTIVES_LIST_SUCCEEDED,
  OBJECTIVES_LIST_FAILED,
  OBJECTIVE_CLEAR_ERROR,
  OBJECTIVE_CREATE,
  OBJECTIVE_CREATE_SUCCEEDED,
  OBJECTIVE_CREATE_FAILED,
  OBJECTIVE_DELETE,
  OBJECTIVE_DELETE_SUCCEEDED,
  OBJECTIVE_DELETE_FAILED,
  OBJECTIVE_FETCH,
  OBJECTIVE_FETCH_SUCCEEDED,
  OBJECTIVE_FETCH_FAILED,
  OBJECTIVE_UPDATE,
  OBJECTIVE_UPDATE_SUCCEEDED,
  OBJECTIVE_UPDATE_FAILED,
};
