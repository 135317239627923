import types from './types';

const clearError = () => ({
  type: types.VALUE_CLEAR_ERROR,
});

const createValue = ({ ...value }) => ({
  type: types.VALUE_CREATE,
  payload: { ...value },
});

const createValueSucceeded = ({ ...value }) => ({
  type: types.VALUE_CREATE_SUCCEEDED,
  payload: { ...value },
});

const createValueFailed = ({ message }) => ({
  type: types.VALUE_CREATE_FAILED,
  payload: { message },
});

const deleteValue = ({ id }) => ({
  type: types.VALUE_DELETE,
  payload: { id },
});

const deleteValueSucceeded = ({ id }) => ({
  type: types.VALUE_DELETE_SUCCEEDED,
  payload: { id },
});

const deleteValueFailed = ({ message }) => ({
  type: types.VALUE_DELETE_FAILED,
  payload: message,
});

const fetchValue = ({ id }) => ({
  type: types.VALUE_FETCH,
  payload: { id },
});

const fetchValueSucceeded = ({ ...value }) => ({
  type: types.VALUE_FETCH_SUCCEEDED,
  payload: { ...value },
});

const fetchValueFailed = ({ message }) => ({
  type: types.VALUE_FETCH_FAILED,
  payload: { message },
});

const listValues = ({ project }) => ({
  type: types.VALUES_LIST,
  payload: { project },
});

const listValuesSucceeded = payload => ({
  type: types.VALUES_LIST_SUCCEEDED,
  payload,
});

const listValuesFailed = ({ message }) => ({
  type: types.VALUES_LIST_FAILED,
  payload: { message },
});

const updateValue = ({ id, ...updatedValue }) => ({
  type: types.VALUE_UPDATE,
  payload: { id, ...updatedValue },
});

const updateValueSucceeded = ({ ...value }) => ({
  type: types.VALUE_UPDATE_SUCCEEDED,
  payload: { ...value },
});

const updateValueFailed = ({ message }) => ({
  type: types.VALUE_UPDATE_FAILED,
  payload: { message },
});

export default {
  clearError,
  createValue,
  createValueFailed,
  createValueSucceeded,
  deleteValue,
  deleteValueFailed,
  deleteValueSucceeded,
  fetchValue,
  fetchValueFailed,
  fetchValueSucceeded,
  listValues,
  listValuesFailed,
  listValuesSucceeded,
  updateValue,
  updateValueFailed,
  updateValueSucceeded,
};
