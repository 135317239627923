import { fromJS } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import ProfilePicture from 'models/ProfilePicture';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    profilePictureData: [],
    defaultProfilePictureData: [],
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROFILE_PICTURE_REQUEST:
      return state.merge(
        reduxHelper.applyRequestInfo({
          errorMessage: '',
        })
      );
    case types.PROFILE_PICTURE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyReceiveInfo({
          profilePictureData: action.payload.profilePictureData.map(
            photo => new ProfilePicture(photo)
          ),
          defaultProfilePictureData: action.payload.defaultProfilePictureData.map(
            photo => new ProfilePicture(photo)
          ),
        })
      );
    case types.PROFILE_PICTURE_CREATE:
    case types.PROFILE_PICTURE_EDIT:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.PROFILE_PICTURE_EDIT_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
        })
      );
    case types.PROFILE_PICTURE_CREATE_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          profilePictureData: [
            new ProfilePicture(action.payload),
            ...state.get('profilePictureData'),
          ],
        })
      );
    case types.PROFILE_PICTURE_EDIT_FAILED:
    case types.PROFILE_PICTURE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
          updatePending: false,
        })
      );
    default:
      return state;
  }
}
