import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Dependencies
import TranslationsContext from 'providers/translationsProvider';
import bfbTheme from 'styles/b4b-theme';
import getCroppedImage from 'utils/image-crop';

// Components
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Flex, Upload, message } from 'antd';
import ImageCropWrapper from 'components/elements/ImageUpload/ImageCropWrapper';
import { P } from 'components/fontSystem';
import { getValueForSize } from 'styles/utils';

function ImageUpload({
  onFileChange,
  fileSize,
  onDelete,
  width = 'auto',
  height = 'auto',
  size = 'small',
  showExtraAdd = true,
  placeholderText = null,
  imageUrls = [],
  withCrop = false,
  imgCropAspect = 1,
  ...rest
}) {
  const { t, format } = React.useContext(TranslationsContext);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <P $mb0 className="ant-upload-text">
        {placeholderText ?? t('Upload')}
      </P>
    </div>
  );

  const uploadProps = {
    beforeUpload: file => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.warning(t('You can only upload JPG/PNG file.'));
      }
      const isLt2M = file.size < fileSize * 1000000;
      if (!isLt2M) {
        message.warning(
          format(
            t('Your file size is too big. Please, try a smaller file size. (%(fileSize)sMB max.)'),
            { fileSize }
          )
        );
      }
      if (isJpgOrPng && isLt2M) {
        getCroppedImage(file)
          .then(() => {
            onFileChange(file);
          })
          .catch(() => {});
      }
      return false;
    },
  };

  const onDeleteHandler = event => {
    event.stopPropagation();
    if (onDelete) onDelete();
  };

  return (
    <>
      {imageUrls.map(imageUrl => (
        <ImageCropWrapper withCrop={withCrop} aspect={imgCropAspect}>
          <StyledUpload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            size={size}
            width={width}
            height={height}
            isImageUrl
            {...uploadProps}
            {...rest}
          >
            <Flex vertical align="center">
              {imageUrl ? (
                <img style={{ maxWidth: '100%', maxHeight: height }} src={imageUrl} alt="avatar" />
              ) : (
                uploadButton
              )}
              {onDelete && imageUrl && <StyledIconDelete onClick={onDeleteHandler} size={size} />}
            </Flex>
          </StyledUpload>
        </ImageCropWrapper>
      ))}
      {showExtraAdd && (
        <ImageCropWrapper withCrop={withCrop} aspect={imgCropAspect}>
          <StyledUpload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            {...uploadProps}
            size={size}
            width={width}
            height={height}
            {...rest}
          >
            {uploadButton}
          </StyledUpload>
        </ImageCropWrapper>
      )}
    </>
  );
}

const SMALL_WIDTH = 104;
const MEDIUM_WIDTH = 500;
const LARGE_WIDTH = 700;

const SMALL_HEIGHT = 104;
const MEDIUM_HEIGHT = 210;
const LARGE_HEIGHT = 294;

const getWidth = props =>
  getValueForSize(props, {
    large: LARGE_WIDTH,
    default: MEDIUM_WIDTH,
    small: SMALL_WIDTH,
  });

const getHeight = props =>
  getValueForSize(props, {
    large: LARGE_HEIGHT,
    default: MEDIUM_HEIGHT,
    small: SMALL_HEIGHT,
  });

const StyledUpload = styled(Upload)`
  max-width: ${getWidth}px;
  max-height: ${getHeight}px;
  margin: 5px 0 20px;

  .ant-upload {
    max-width: ${getWidth}px;
    height: auto !important;
  }

  &.ant-upload-wrapper.ant-upload-picture-card-wrapper {
    display: initial;
  }

  &.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 100%;
  }

  span.ant-upload {
    border-radius: 4px;
    background: white;
    padding: 10px;

    .anticon.anticon-plus {
      color: ${bfbTheme.grey};
    }

    p.ant-upload-text {
      font-style: italic;
      color: ${bfbTheme.grey};
    }
  }
`;

const StyledIconDelete = styled(DeleteOutlined)`
  padding: 10px 0 2px 0;
  :hover {
    color: #00ffaf;
  }
`;

ImageUpload.propTypes = {
  imageUrls: PropTypes.instanceOf(Array),
  fileSize: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onFileChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showExtraAdd: PropTypes.bool,
  placeholderText: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,

  // ImgCrop props
  withCrop: PropTypes.bool,
  imgCropAspect: PropTypes.number,
};

export default ImageUpload;
