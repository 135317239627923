import styled from 'styled-components';

const StyledWrapper = styled.div`
  &&& {
    margin: 0 auto;
    max-width: 1200px;
    overflow: hidden;
    padding: 0 10px;
    width: 100%;

    @media screen and (min-width: ${props => props.theme.screenMdMin}) {
      padding: 0 40px;
    }
  }
`;

export default StyledWrapper;
