export const BAR_CHART_AVERAGE_LINE = '__bar-chart-average-line';
export const BAR_CHART_LINE = '__bar-chart-line';

/**
 * **COPIED FROM LODASH**
 * **THIS WILL LIMIT THE ABSOLUTELY POSITIONED ELEMENTS TO:**
 * - **A MINIMUM OF 0%.**
 * - **A MAXIMUM OF 100%.**
 *
 * The base implementation of `_.clamp` which doesn't coerce arguments.
 *
 * @private
 * @param {number} number The number to clamp.
 * @param {number} [lower] The lower bound.
 * @param {number} upper The upper bound.
 * @returns {number} Returns the clamped number.
 */
export const baseClamp = (number, lower, upper) => {
  if (typeof number === 'number') {
    if (upper !== undefined) {
      return number <= upper ? number : upper;
    }
    if (lower !== undefined) {
      return number >= lower ? number : lower;
    }
  }
  return number;
};

/**
 * Worker functon depending if the `dataValue` is an array (in case it's a stacked column).
 * Also evaluates if the value should affect the average.
 */
export const getValue = dataValue => {
  let averageDenominators = 0;
  const isValueArray = Array.isArray(dataValue);
  if (isValueArray) {
    const totalValue = dataValue.reduce((accumulator, dataVal) => {
      if (!dataVal.shouldNotAffectAverage) averageDenominators += 1;
      return accumulator + dataVal.value;
    }, 0);
    return [totalValue, averageDenominators];
  }
  return [dataValue.value, dataValue.shouldNotAffectAverage ? 0 : 1];
};

/**
 * Worker function that calculates the total average and highest value of the chart.
 */
export const calculateChartValues = data => {
  // Increasing performance by calculating the average and denominators from an upper scope:
  let highestValue = 0;
  let averageDenominators = 0;
  const avg =
    data.reduce((accumulator, dataValue) => {
      const [value, denominators] = getValue(dataValue);
      averageDenominators += denominators;
      // Storing the highest value
      if (!highestValue) {
        highestValue = Number(value) || 0;
      } else if (Number(value) > Number(highestValue)) {
        highestValue = Number(value);
      }
      // If there are no denominators, then we should NOT affect the the accumulator.
      return denominators ? Number(accumulator) + Number(value) : Number(accumulator);
    }, 0) / averageDenominators || 0;
  return [highestValue, avg];
};

// The chart lines and upper container have a top spacing distance
// of 25px to allow for indicators at the top of the chart bars.
export const TOP_INDICATOR_SPACING = '25px';
