// Libraries
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';

// Dependencies
import { HEADER_NOTIFICATION_BADGE } from 'containers/Tools/06_Financial_Forecast/TutorialTargetIDs.js';
import TranslationsContext from 'providers/translationsProvider';

// Hooks
import useInterval from 'hooks/useInterval';

// Actions and selectors
import {
  actions as notificationsActions,
  selectors as notificationsSelectors,
} from 'store/modules/notifications';
import { selectors as userSelectors } from 'store/modules/user';

// Assets
import { ReactComponent as ActiveNotificationIcon } from 'static/icons/00-general/00-1-icons/icon_notification-active.svg';
import { ReactComponent as EmptyNotificationIcon } from 'static/icons/00-general/00-1-icons/icon_notification-empty.svg';

// Components
import Icon from '@ant-design/icons';
import { Badge, Popover } from 'antd';
import NotificationsContent from 'components/elements/UserNotifications/NotificationsContent';

const OVERLAY_CLASSNAME = 'popOverOverlay';

const { listNotifications } = notificationsActions;
const unreadCountFilter = { seen_at__isnull: 1, unreadCount: true };

function UserNotifications({
  project,
  isMobile = false,
  toolHeader = false,
  ventureId = undefined,
  projectIds = [],
}) {
  const { t } = React.useContext(TranslationsContext);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(listNotifications(unreadCountFilter));
  }, [dispatch]);

  useInterval(() => dispatch(listNotifications(unreadCountFilter)), 300000);

  const unreadNotifications = useSelector(notificationsSelectors.getUnreadNotificationsCount);
  const isMember = useSelector(userSelectors.isMember);
  const isMentor = !isMember;

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibleChange = visible => {
    if (visible) {
      setIsVisible(true);
    }
    if (!visible) {
      setIsVisible(false);
    }
  };

  const projectId = project.get('id');

  const notificationsTitle = t('Notifications');

  return (
    <div>
      <PopoverGlobalStyle />
      <StyledBadge
        $toolHeader={toolHeader}
        $isMentor={isMentor}
        count={unreadNotifications}
        overflowCount={10}
        id={HEADER_NOTIFICATION_BADGE}
      >
        <Popover
          open={isVisible}
          onOpenChange={handleVisibleChange}
          arrow={{ pointAtCenter: true }}
          overlayClassName={OVERLAY_CLASSNAME}
          placement="bottomLeft"
          title={notificationsTitle}
          content={
            <NotificationsContent
              onNotificationClick={handleVisibleChange}
              isMember={isMember}
              isMentor={isMentor}
              isVisible={isVisible}
              projectId={projectId}
              ventureId={ventureId}
              mentorProjectIds={projectIds}
            />
          }
        >
          <Icon
            component={unreadNotifications ? ActiveNotificationIcon : EmptyNotificationIcon}
            style={
              isMobile
                ? {
                    fontSize: 22,
                    marginRight: 15,
                    marginLeft: 10,
                    verticalAlign: 'middle',
                    color: toolHeader ? 'white' : 'black',
                  }
                : {
                    fontSize: 27,
                    marginRight: 4,
                    marginLeft: 4,
                    verticalAlign: 'middle',
                    color: toolHeader ? 'white' : 'black',
                  }
            }
          />
        </Popover>
      </StyledBadge>
    </div>
  );
}

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    transform: translate(14%, -30%);
    min-width: 14px;
    height: 16px;
    padding: 0px 5px;
    background: #f25a5a;
    box-shadow: 0 0 0 1px ${props => (props.$toolHeader ? '#123DC6' : '#fff')};
    .ant-scroll-number-only {
      .ant-scroll-number-only-unit {
        color: white !important;
        font-size: 12px;
        line-height: ${props => (props.$isMentor ? '15px' : '15px')};
      }
    }
  }
  .ant-badge-multiple-words {
    transform: translate(30%, -26%);
    min-width: 8px;
    max-width: 24px;
    height: 13px;
    padding: 0px 4px;
    color: white !important;
    font-size: 12px;
    line-height: ${props => (props.isMentor ? '13px' : '12px')};
  }
  &.ant-badge .ant-scroll-number .ant-scroll-number-only {
    display: flex;
  }
`;

const TITLE_PADDING = '20px';

const PopoverGlobalStyle = createGlobalStyle`
  .${OVERLAY_CLASSNAME}{
    &&&{
      font-size: 1.3em;
      vertical-align: text-top;
      font-size: 27;
      margin-right: 5;
      vertical-align: 'middle';
    }

    .ant-popover-inner{
      max-width: 330px;
      width: 330px;
      .ant-popover-title{
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        padding: 4px 8px;
        color: ${({ theme }) => theme.primaryColor} !important;
        border-bottom: 0;
        ::after{
          content: "";
          display: block;
          height: 1px;
          margin: 10px -${TITLE_PADDING} 0;
          width: auto;
          background: ${({ theme }) =>
            `linear-gradient(to right, ${theme.primaryColor}, ${
              theme.secondaryColor || theme.primaryColor
            })`};
        }
      }
      .ant-spin-nested-loading{
        overflow: auto;
      }
    }
  }
`;

UserNotifications.propTypes = {
  project: PropTypes.instanceOf(Object).isRequired,
  isMobile: PropTypes.bool,
  toolHeader: PropTypes.bool,
  ventureId: PropTypes.number,
  projectIds: PropTypes.instanceOf(Array),
};

export default UserNotifications;
