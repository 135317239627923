import { Select, Tag } from 'antd';
import PropTypes from 'prop-types';
import value from 'services/GrowthPlan/value';
import styled from 'styled-components';

const TagSelector = ({
  color,
  filterOption,
  filterSort,
  id,
  isMultiSelect,
  name,
  onChange,
  options,
  placeholder,
  showSearch,
  ...rest
}) => {
  /* 
    If you plan to use onBlur or onChange, along with Form validation, passing `name` prop 
    will make it easier.
  */
  if (!options || options.length < 1) return null;

  const handleOnSelect = newValue => {
    if (onChange) {
      onChange({
        target: {
          value: isMultiSelect
            ? newValue.filter(element => element !== null && element !== undefined)
            : newValue[newValue.length - 1],
          name,
        },
      });
    }
  };

  const tagRender = props => {
    const { label, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    const shouldRender = label && (value || value === 0);

    return shouldRender ? (
      <StyledTag
        $mainColor={color}
        onMouseDown={onPreventMouseDown}
        onClose={onClose}
        bordered={false}
      >
        {label}
      </StyledTag>
    ) : (
      <Placeholder>{placeholder}</Placeholder>
    );
  };

  return (
    <StyledSelect
      filterOption={filterOption}
      filterSort={filterSort}
      id={id}
      mode="multiple"
      onChange={handleOnSelect}
      options={options}
      placeholder={placeholder}
      showSearch={showSearch}
      style={{ width: '100%' }}
      tagRender={propx => tagRender({ ...propx, color })}
      {...rest}
    />
  );
};

export default TagSelector;

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border: none !important;
    border-radius: 0;
    border-bottom: 1.25px solid #b3b3b3 !important;
    height: auto;
    padding: 0 0 5px;

    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.greyColor};
      font-style: italic;
      font-weight: 100;
      inset-inline-start: 0;
      inset-inline-end: 0;
    }

    &:hover,
    &:focus {
      border-color: ${props => props.theme.primaryColor} !important;
    }

    .ant-select-selection__choice__remove {
      color: white;
    }

    .ant-select-selection-overflow {
      gap: 8px 10px;
    }
  }
`;

const StyledTag = styled(Tag)`
  background-color: ${({ $mainColor }) => `${$mainColor}1A`};
  color: ${({ $mainColor }) => $mainColor};
  font-size: 15px;
  margin-inline-end: 0;
  padding: 2px 10px;

  display: flex;
  align-items: center;

  white-space: break-spaces;

  span.anticon-close {
    color: ${({ $mainColor }) => $mainColor};

    &:hover {
      color: ${({ $mainColor }) => `${$mainColor}80`};
    }
  }
`;

const Placeholder = styled.span`
  color: #999999;
  font-style: italic;
  font-weight: 100;
  inset-inline-start: 0;
  inset-inline-end: 0;
`;

TagSelector.propTypes = {
  name: PropTypes.string,
  isMultiSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  color: PropTypes.string,
  showSearch: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  id: PropTypes.string,
  maxCount: PropTypes.number,
  filterSort: PropTypes.func,
  filterOption: PropTypes.func,
};

TagSelector.defaultProps = {
  name: '',
  onChange: null,
  onClose: null,
  isMultiSelect: false,
  placeholder: '',
  options: [],
  color: '',
  showSearch: false,
  label: '',
  id: null,
  maxCount: null,
  filterSort: null,
  filterOption: null,
};
