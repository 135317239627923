// -------- Branding Colors -----------

export const SCREEN_XS = 480;
export const SCREEN_SM = 576;
export const SCREEN_MD = 768;
export const SCREEN_LG = 992;
export const SCREEN_XL = 1200;
export const SCREEN_XXL = 1600;

/* IDEA:
  We could have 2 main keys inside bfbTheme: variables and colors

  *Variables / Other name TBD*
  Define theme colors that can be used in the components.
  They change based on BrideforBillions, Company, E or M theme

  *Colors*
  Constant colors that we use in our components and are not subjected to theme modifications
*/

const bfbTheme = {
  mainColor: '#123DC6',
  mainColorHover: '#0f2e87',
  mainColorFade: 'rgba(18, 61, 198, 0.5)',
  mainColorBgFade: 'rgba(18, 61, 198, 0.1)',
  themeCompanyColorHover: '#0f2e87',
  themeCompanyColorFade: 'rgba(18, 61, 198, 0.5)',
  themeCompanyColorBgFad: 'rgba(18, 61, 198, 0.1)',
  memberGreen: '#37CC82',
  memberGreenHover: '#2c8d5c',
  memberGreenFade: 'rgba(55, 204, 130, 0.5)',
  memberGreenBgFade: 'rgba(55, 204, 130, 0.1)', // #37cc821a
  mentorBlue: '#5185e0',
  mentorBlueHover: '#3a5e9a',
  mentorBlueFade: 'rgba(81, 133, 224, 0.5)',
  mentorBlueBgFade: 'rgba(81, 133, 224, 0.1)', // #5185E01A
  parrotPink: '#E82E81',
  parrotPinkHover: '#870a3e',
  parrotPinkFade: 'rgba(232,46,129,0.5)',
  parrotPinkBgFade: 'rgba(232,46,129,0.1)',
  grey: '#999999',
  greyBgFade: '#9999991a',
  yellow: '#FDB833',
  yellowBgFade: '#fdb8331a',
  lightYellow: '#F7CD00',
  lightYellowBgFade: 'rgba(247, 205, 0, 0.1)',
  lightGrey: '#F4F4F4',
  lightGreyColor: '#F2F2F2',
  notSoLightGreyColor: '#E4E4E4',
  backgroundGreyColor: '#FAFAFA',
  placeholderGrey: '#B3B3B3',
  borderGrey: '#D9D9D9',
  hyperlink: '#0FC5D8',
  hyperlinkLigth: 'rgba(15, 197, 216, 0.4)',
  hyperlinkBgFade: 'rgba(15, 197, 216, 0.1)',
  error: '#F25A5A',
  errorHover: '#b42525',
  errorFade: 'rgba(250, 90, 90, 0.5)',
  errorBg: '#F25A5A1A',
  warning: '#F4D811',
  teal: '#00FFAF',
  tealFade: 'rgba(0, 255, 175, 0.3)',
  tealFade2: 'rgba(0, 255, 175, 0.2)',
  tealBgFade: 'rgba(0, 255, 175, 0.1)',
  primaryWaitlistColor: '#5C5CEA',
  primaryWaitlistColorBg: '#5C5CEA1A',
  secondaryWaitlistColor: '#227EA3',
  secondaryWaitlistColorBg: '#227EA31A',
  followUpColor: '#707D28',
  followUpColorBg: '#707D281A',
  formSelectColor: '#00FFAF',
  highlightFont: '#CCFFEF',
  lightbrown: '#B1740F',
  olivegreen: '#1A754E',
  lightOrange: '#FF915D',
  orange: '#f46300',
  black: '#000000',
  violet: '#8407E5',
  crimson: '#CA1551',
  crimsonBgFade: '#CA15511A',
  // Media queries breakpoints
  screenXs: `${SCREEN_XS}px`,
  screenSm: `${SCREEN_SM}px`,
  screenMd: `${SCREEN_MD}px`,
  screenLg: `${SCREEN_LG}px`,
  screenXl: `${SCREEN_XL}px`,
  screenXxl: `${SCREEN_XXL}px`,
  // Layout dimensions
  appBarHeaderHeight: '64px',
};

export default bfbTheme;
