import { fromJS, List } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Behavior from 'models/GrowthPlan/Behavior';
import { byId } from 'store/utils/sortFunctions';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyReceiveInfo({
    data: {
      byId: {},
      byProject: {},
    },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.BEHAVIORS_LIST_SUCCEEDED:
      return state.merge(
        reduxHelper.applyRequestInfo({
          data: {
            byId: action.payload.results.reduce(
              (prevData, behavior) =>
                prevData.set(behavior.id.toString(), new Behavior(behavior)).sort(byId),
              state.get('data').get('byId')
            ),
            byProject: state
              .get('data')
              .get('byProject')
              .set(
                String(action.payload.project),
                fromJS(action.payload.results.map(behavior => new Behavior(behavior))).sort(byId)
              ),
          },
        })
      );
    case types.BEHAVIOR_FETCH_SUCCEEDED:
    case types.BEHAVIOR_CREATE_SUCCEEDED:
    case types.BEHAVIOR_UPDATE_SUCCEEDED: {
      const projectId = String(action.payload.project);
      const projectBehaviors = state
        .get('data')
        .get('byProject')
        .get(projectId, new List())
        .filter(behavior => behavior.id !== action.payload.id)
        .push(new Behavior(action.payload))
        .sort(byId);
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state
              .get('data')
              .get('byId')
              .set(action.payload.id.toString(), new Behavior(action.payload)),
            byProject: state.get('data').get('byProject').set(projectId, projectBehaviors),
          },
        })
      );
    }
    case types.BEHAVIOR_FETCH:
    case types.BEHAVIOR_CREATE:
    case types.BEHAVIOR_UPDATE:
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: true,
        })
      );
    case types.BEHAVIOR_DELETE_SUCCEEDED: {
      const projectId = String(action.payload.project);
      const projectBehaviors = state
        .get('data')
        .get('byProject')
        .get(projectId, new List())
        .filter(behavior => behavior.id !== action.payload.id)
        .sort(byId);
      return state.merge(
        reduxHelper.applyRequestInfo({
          updatePending: false,
          data: {
            byId: state.get('data').get('byId').delete(action.payload.id.toString()),
            byProject: state.get('data').get('byProject').set(projectId, projectBehaviors),
          },
        })
      );
    }
    case types.BEHAVIOR_UPDATE_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    case types.BEHAVIOR_CLEAR_ERROR:
      return state.merge(reduxHelper.applyReceiveInfo());
    case types.BEHAVIOR_FETCH_FAILED:
    case types.BEHAVIORS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
