import api from '../api';

const listBusinessModels = ({ product }) =>
  api.get('/api/business-models/', {
    product,
  });

const fetchProductBusinessModel = ({ id }) => api.get(`/api/product-business-models/${id}/`);

const listProductBusinessModels = ({ product }) =>
  api.get('/api/product-business-models/', {
    params: {
      product,
    },
  });

const createProductBusinessModel = ({ product, businessModel, ...rest }) =>
  api.post(`/api/product-business-models/?product=${product}/`, {
    product,
    business_model: businessModel,
    ...rest,
  });

const updateProductBusinessModel = ({ product, ...businessModel }) =>
  api.patch(`/api/product-business-models/${businessModel.id}/`, {
    product,
    ...businessModel,
  });

export default {
  createProductBusinessModel,
  fetchProductBusinessModel,
  listBusinessModels,
  listProductBusinessModels,
  updateProductBusinessModel,
};
