import { fromJS, Map } from 'immutable';
import * as reduxHelper from 'store/utils/reduxHelper';
import Member from 'models/Member';
import types from './types';

export const initialState = fromJS({
  ...reduxHelper.applyRequestInfo({
    data: { admin: new Map() },
    errorMessage: '',
    updatePending: false,
  }),
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROGRAM_MEMBERS_LIST:
      return state.merge(
        reduxHelper.applyRequestInfo({
          isFetching: true,
        })
      );
    case types.PROGRAM_MEMBERS_LIST_SUCCEEDED: {
      const { count, programMembers } = action.payload;
      let newMembers = new Map();
      Object.values(programMembers).forEach(programMember => {
        newMembers = newMembers.set(programMember.id, new Member(programMember));
      });
      const data = state.get('data').set('admin', newMembers).set('count', count);
      return state.merge(reduxHelper.applyReceiveInfo({ data }));
    }
    case types.PROGRAM_MEMBERS_LIST_FAILED:
      return state.merge(
        reduxHelper.applyErrorInfo({
          updatePending: false,
          errorMessage: action.payload,
        })
      );
    default:
      return state;
  }
}
