import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions, types } from 'store/modules/programStep';
import { serviceStep } from 'services/Tools';

export function* listSteps(action) {
  const { program, project } = action.payload;
  try {
    const response = yield call(serviceStep.listSteps, { program, project });
    yield put(actions.listProgramStepsSucceeded({ results: response.data }));
  } catch (e) {
    yield put(actions.listProgramStepsFailed({ message: e.message }));
  }
}

function* toolSaga() {
  yield all([takeLatest(types.PROGRAM_STEPS_LIST, listSteps)]);
}

export default toolSaga;
