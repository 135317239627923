// Libraries
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

// Dependencies
import rootReducer from './reducers';
import { middleware } from './middleware';
import sagas from './sagas';

// Suggestion: add preloadedState

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: gDM => gDM({ serializableCheck: false }).concat(...middleware, sagaMiddleware),
  devTools: !window.prod,
});

sagaMiddleware.run(sagas);

export default store;
