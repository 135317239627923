import PropTypes from 'prop-types';

const CountryIcon = props => {
  const { countryCode, ...rest } = props;
  // Adding support for the kosovo flag,
  // which is different in our database and in the flag icons library "ko" vs "xk"
  // Check https://flagicons.lipis.dev
  const code = countryCode === 'KO' ? 'XK' : countryCode;

  const className = `flag-select fi fi-${code.toLowerCase()}`;

  return <span {...rest} className={className} />;
};

CountryIcon.propTypes = {
  countryCode: PropTypes.string.isRequired,
};

export default CountryIcon;
