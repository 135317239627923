import { createSelector } from 'reselect';

const getState = state => state.IPresources;

const getRawResources = createSelector([getState], state => state.get('data'));

const getResourcesById = createSelector([getRawResources], state => state.get('byId'));

const getResourcesBySystem = createSelector([getRawResources], state => state.get('bySystem'));

const getResources = createSelector([getResourcesById], state => state.valueSeq().toJS());

const getErrorMessage = createSelector([getState], state => state.get('errorMessage'));

export default {
  getResources,
  getResourcesById,
  getResourcesBySystem,
  getErrorMessage,
};
